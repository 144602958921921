import Lottie from 'lottie-react';
import React, { CSSProperties } from 'react';

import animationData from './jsonFiles/animated_ghost.json';

type Props = {
  style?: CSSProperties;
};

const AnimatedGhost = ({ style }: Props) => {
  return (
    <Lottie
      animationData={animationData}
      loop={true}
      style={{ height: 300, width: 300, ...style }}
    />
  );
};

export default AnimatedGhost;
