import React, { CSSProperties, ReactNode } from 'react';

import type { Props as IconProps } from 'components/Icon';
import type {
  BulmaColor,
  BulmaShadesOfGrey,
  BulmaSize,
} from 'components/types/bulma';

import classNames from 'helpers/classNames';

import { Icon } from 'components';

export type Props = {
  children?: ReactNode;
  size?: Exclude<BulmaSize, 'small' | 'tiny'>;
  color?: BulmaColor | BulmaShadesOfGrey;
  style?: CSSProperties;
  additionalClassName?: string;
  testClassName?: string;
  isRounded?: boolean;
  rightIcon?: IconProps;
};

/**
 * @deprecated Use <Tag> or <StatusTag> instead
 */
function DeprecatedTag({
  children,
  size,
  color,
  style = {},
  additionalClassName,
  testClassName,
  isRounded,
  rightIcon,
}: Props) {
  const colorClassName = classNames({
    'is-primary': color && color === 'primary',
    'is-info': color && color === 'info',
    'is-danger': color && color === 'danger',
    'is-warning': color && color === 'warning',
    'is-success': color && color === 'success',
    'is-light': color && color === 'light',
    'is-black': color && color === 'black',
    'is-white': color && color === 'white',
    'is-dark': color && color === 'dark',
  });

  const sizeClassName = classNames({
    'is-normal': size && size === 'normal',
    'is-medium': size && size === 'medium',
    'is-large': size && size === 'large',
  });

  const modifierClassName = classNames({ 'is-rounded': isRounded });

  const className = classNames(
    'tag',
    colorClassName,
    sizeClassName,
    modifierClassName,
    additionalClassName,
    testClassName
  );

  return (
    <span className={className} style={style}>
      {children}
      {rightIcon && <Icon style={{ marginLeft: 5 }} {...rightIcon} />}
    </span>
  );
}

DeprecatedTag.defaultProps = {
  size: 'normal',
};

export default DeprecatedTag;
