import React from 'react';

import { __ } from 'helpers/i18n';

import IntegrationSettingField from './IntegrationSettingField';

type Props = {
  settings: {
    apiKey: string;
  };
  onChange: (key: string, value: string) => void;
};

export function PayfitIntegrationSettings({ settings, onChange }: Props) {
  return (
    <IntegrationSettingField
      name="apiKey"
      label={__('API Key:')}
      value={settings.apiKey}
      cleanOnFocus
      onUpdate={value => onChange('apiKey', value)}
    />
  );
}

export const PayfitIntegrationDefaultSettings = {
  apiKey: '',
};
