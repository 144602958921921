import React, { Fragment } from 'react';

import type { ReviewCycle, UserReview } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n/index';
import invariant from 'helpers/invariant';

import { put } from 'redux/actions/api';

import { MenuItem, Toggleable } from 'components';

import DelegateModal from './DelegateModal';

type Props = {
  userReview: UserReview;
  reviewCycle: ReviewCycle;
  onAfterAction: () => Promise<void> | void;
};

const UpdateResponsibleAction = ({
  userReview,
  reviewCycle,
  onAfterAction,
}: Props) => {
  const dispatch = useAppDispatch();

  const updateResponsible = async (
    responsibleId: string | null,
    responsibleRole: string
  ) => {
    await dispatch(
      put(`user_reviews/${userReview.id}/delegate`, {
        responsibleId,
        responsibleRole,
      })
    );
    onAfterAction && (await onAfterAction());
  };
  return (
    <Toggleable
      render={(isToggled, toggle) => (
        <Fragment>
          <MenuItem onClick={toggle}>{__('Update responsible')}</MenuItem>
          {isToggled && (
            <DelegateModal
              isActive={isToggled}
              userReviewId={userReview.id}
              reviewCycle={reviewCycle}
              onSubmit={async ({ responsible, responsibleRole }) => {
                invariant(responsibleRole, 'Responsible role is required');
                await updateResponsible(
                  responsible ? responsible.id : null,
                  responsibleRole
                );
                toggle();
              }}
              onCancel={toggle}
            />
          )}
        </Fragment>
      )}
    />
  );
};

export default UpdateResponsibleAction;
