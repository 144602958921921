import React from 'react';

import { UserReview } from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';

import ProfessionalBackground from './ProfessionalBackground';
import TrainingSessions from './TrainingSessions';
import UserHistory from './UserHistory';

export const getEnabledTabs = (userReview: UserReview) => {
  const { id, user } = userReview;
  const tabs = [
    {
      key: 'userHistory' as const,
      enabled: can({
        perform: 'show_user_history',
        on: userReview,
      }),
      label: __('History'),
      content: <UserHistory user={user} currentReviewId={id} />,
    },
    {
      key: 'trainingSessions' as const,
      enabled: can({
        perform: 'show_training_sessions',
        on: userReview,
      }),
      label: __('Trainings'),
      content: <TrainingSessions userReview={userReview} />,
    },
    {
      key: 'professionalBackground' as const,
      enabled: can({
        perform: 'show_professional_background',
        on: userReview,
      }),
      label: __('Background'),
      content: <ProfessionalBackground user={userReview.user} />,
    },
  ];

  const enabledTabs = tabs.filter(tab => tab.enabled);

  return enabledTabs;
};
