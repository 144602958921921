import React from 'react';

import { type Props as ColumnProps } from 'components/layout/grid/Column';

import { DesignSystem, Title3, Title4 } from '../';
import { Column, Columns } from './grid';

type Props = ColumnProps & {
  children: React.ReactNode;
};

export const DeprecatedPageHeader = ({ children, style }: Props) => (
  <Columns style={style}>{children}</Columns>
);

export const DeprecatedPageHeaderTitle = ({
  children,
  ...columnProps
}: Props) => (
  <Column {...columnProps}>
    <DesignSystem
      render={version => {
        if (version === 1) {
          return <Title4>{children}</Title4>;
        }

        return <Title3>{children}</Title3>;
      }}
    />
  </Column>
);

export const DeprecatedPageHeaderActions = ({
  children,
  ...columnProps
}: Props) => (
  <Column isNarrow {...columnProps}>
    {children}
  </Column>
);
