import React, { ReactNode, useMemo, useState } from 'react';

import { n__ } from 'helpers/i18n';

import { Checkbox, Text, Tooltip } from 'components';

import SimpleTable, {
  type Props as BaseProps,
  ColumnDefinition,
} from './SimpleTable';

type Props<TRow> = BaseProps<TRow> & {
  rowDisabledReason?: (row: TRow) => string | null;
  renderBulkActions?: (
    selectedRowIds: Set<string>,
    resetSelectedRequestIds: () => void
  ) => ReactNode;
};

const SimpleBoxTable = <TRow,>({
  rowDisabledReason,
  renderBulkActions,
  columns,
  ...props
}: Props<TRow>) => {
  const [selectedRowIds, setSelectedRowIds] = useState(new Set<string>());
  const { rows, keyFn } = props;
  const selectableRows = rows.filter(
    row => !rowDisabledReason || !rowDisabledReason(row)
  );

  const isAllChecked = useMemo(
    () =>
      rows.length > 0 &&
      selectableRows.length > 0 &&
      selectableRows.every(row => selectedRowIds.has(keyFn(row))),
    [rows, selectableRows, keyFn, selectedRowIds]
  );

  const toggleRowSelection = (rowId: string) => {
    setSelectedRowIds(prevSelectedIds => {
      const newSelectedIds = new Set(prevSelectedIds);

      if (newSelectedIds.has(rowId)) {
        newSelectedIds.delete(rowId);
      } else {
        newSelectedIds.add(rowId);
      }

      return newSelectedIds;
    });
  };

  const toggleAllVisibleRows = () => {
    setSelectedRowIds(prevSelectedIds => {
      const newSelectedIds = new Set(prevSelectedIds);

      rows.forEach(row => {
        if (rowDisabledReason && !!rowDisabledReason(row)) return;

        if (isAllChecked) {
          newSelectedIds.delete(keyFn(row));
        } else {
          newSelectedIds.add(keyFn(row));
        }
      });

      return newSelectedIds;
    });
  };

  const headerCheckbox = (
    <Checkbox
      onChange={toggleAllVisibleRows}
      disabled={selectableRows.length === 0}
      isChecked={isAllChecked}
      size="small"
    />
  );

  const checkboxCell = (row: TRow) => {
    const isDisabled = rowDisabledReason && !!rowDisabledReason(row);
    const checkbox = (
      <Checkbox
        onChange={() => toggleRowSelection(keyFn(row))}
        isChecked={selectedRowIds.has(keyFn(row))}
        disabled={isDisabled}
        size="small"
      />
    );

    if (isDisabled) {
      return <Tooltip content={rowDisabledReason(row)}>{checkbox}</Tooltip>;
    }
    return checkbox;
  };

  const columnsWithCheckbox = [
    {
      header: headerCheckbox,
      cell: checkboxCell,
      headerKey: 'checkbox',
      isNarrow: true,
      withoutTooltipOnEllipsis: true,
    },
    ...columns,
  ] as Array<ColumnDefinition<TRow>>;

  return (
    <div>
      <div className="flex items-center h-8 mb-1 test-simple-table-bulk-actions-toolbar">
        <Text preset="14bs6" additionalClassName="mr-4">
          {n__('%1 selected', '%1 selected', selectedRowIds.size)}
        </Text>
        {selectedRowIds.size > 0 &&
          !!renderBulkActions &&
          renderBulkActions(selectedRowIds, () => setSelectedRowIds(new Set()))}
      </div>
      <SimpleTable {...props} columns={columnsWithCheckbox} />
    </div>
  );
};

export default SimpleBoxTable;
