import React from 'react';

import { ExportAction } from 'models';

import { useExportAction } from 'helpers/hooks/useExportAction';
import { __ } from 'helpers/i18n';
import { addToken } from 'helpers/url';

import { createUrl } from 'lib/api';

import { Button, Tooltip } from 'components';

type Props = {
  exportAction: ExportAction | undefined;
  exportOrganizationSyncReport: () => Promise<void>;
};

const ExportButton = ({
  exportAction,
  exportOrganizationSyncReport,
}: Props) => {
  const onExportActionCompleted = (exportActionId: string) => {
    const exportUrl = createUrl(`exports/${exportActionId}/download`);

    window.location.replace(addToken(exportUrl));
  };

  const { ongoingExportAction, exportTooltipText, pollerComponent } =
    useExportAction(exportAction, onExportActionCompleted, {
      downloadFile: true,
    });

  return (
    <Tooltip content={exportTooltipText}>
      {pollerComponent}
      <Button
        color="primary"
        onClick={exportOrganizationSyncReport}
        disabled={ongoingExportAction}
      >
        {__('Download summary')}
      </Button>
    </Tooltip>
  );
};

export default ExportButton;
