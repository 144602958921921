import colors from './colors';
import * as uiConstants from './uiConstants';

export * from './functions';

export const generalStyle = {
  '.container-elevo': {
    width: '100%',
    maxWidth: uiConstants.maxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  '.dropdown:focus': {
    outline: 'none',
  },
  '.control.has-icon .icon': {
    pointerEvents: 'auto',
    zIndex: 'initial',
  },
  '.icon': {
    verticalAlign: 'top',
  },
  '.tabs a': {
    color: colors.darkGrey,
  },
  '.tabs a:hover': {
    color: colors.darkGrey,
    borderBottomColor: colors.darkGrey,
  },
  '.table th': {
    outline: 'none',
  },
  '.large-margin-sides': {
    margin: `0 ${uiConstants.largeMargin}px`,
  },
  '.secret-padding-sides': {
    padding: `0 ${uiConstants.largePadding - 10}px`,
    borderLeft: `10px solid ${colors.mediumGrey}`,
  },
  'table.center th, table.center td': {
    verticalAlign: 'middle',
  },
  'table.feedback-box th, table.feedback-box td': {
    padding: '3px 6px',
  },
  '.section': {
    backgroundColor: 'transparent',
    padding: '0',
  },
  '.level:not(:last-child)': {
    marginBottom: 0,
  },
  '.Select-control': {
    backgroundColor: colors.lightestGrey,
  },
  '.react-datepicker__input-container': {
    display: 'inherit',
  },
  '.react-datepicker-wrapper': {
    display: 'inherit',
  },
};
