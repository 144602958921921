import React, { Fragment } from 'react';

import type { BudgetStats } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import { requestStarted, requestSucceeded } from 'lib/dataLoader/actions';
import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import { Label, Text } from 'components';

type Props = {
  periodSlug: string;
};

type AfterConnectProps = Props & {
  budgetStats: BudgetStats;
};

const cacheKeyId = (slug: string) =>
  compositeKey({ slug, viewId: 'budgetStats' });
const urlToTrainingPeriodBudgetStats = (slug: string) =>
  `training/periods/${slug}/budget_items/stats`;

export function refetchBudgetStats(periodSlug: string) {
  const requestId = cacheKeyId(periodSlug);

  return async function (dispatch: AppDispatch) {
    await dispatch(requestStarted(requestId));
    const response = await dispatch(
      get(urlToTrainingPeriodBudgetStats(periodSlug))
    );
    await dispatch(requestSucceeded(requestId, response.response.body));
  };
}

const BudgetSummary = ({ budgetStats }: AfterConnectProps) => {
  return (
    <Fragment>
      <Label>{__('Overall budget')}</Label>
      <Text style={{ display: 'block' }} color="info" weight="normal">
        {__('The overall budget is calculated based on funding sources')}
      </Text>
      <Text
        style={{ display: 'block' }}
        testClassName="test-training-global-budget-amount"
      >
        {budgetStats?.totalAmountCents > 0
          ? budgetStats?.totalAmountFormatted
          : `- ${budgetStats?.totalAmountCurrency || ''}`}
      </Text>
    </Fragment>
  );
};

export default newDataLoader({
  fetch: ({ periodSlug }: Props) =>
    get(`training/periods/${periodSlug}/budget_items/stats`),
  hydrate: {
    budgetStats: {},
  },
})(BudgetSummary) as React.ComponentType<Props>;
