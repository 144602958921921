import React, { ReactNode } from 'react';

import type { ExportAction } from 'models';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { get } from 'redux/actions/api';

import { Poller, Text } from 'components';

import { useAppDispatch } from './reactReduxHooks';

type Options = {
  downloadFile: boolean;
};

export const useExportAction = (
  lastPendingExportAction?: ExportAction | null,
  onExportActionCompleted?: (id: string) => void,
  options?: Options
): {
  ongoingExportAction: boolean;
  exportTooltipText: ReactNode;
  pollerComponent: ReactNode;
} => {
  const dispatch = useAppDispatch();
  const activeUser = useActiveUser();

  const ongoingExportAction =
    lastPendingExportAction?.status === 'in_progress' ||
    lastPendingExportAction?.status === 'queued';

  const exportTooltipText =
    ongoingExportAction &&
    (activeUser.id === lastPendingExportAction?.requestedById ? (
      <Text>
        {__('Export is not possible until your previous export is completed.')}
        <br />
        {!options?.downloadFile &&
          __('It will be delivered in your mailbox in a few minutes.')}
      </Text>
    ) : (
      <Text>
        {__('Another administrator has already launched an export.')}
        <br />
        {__(
          'Export action will be possible in a few minutes once the previous will be ended.'
        )}
      </Text>
    ));

  const pollerComponent = !!lastPendingExportAction && ongoingExportAction && (
    <Poller
      poll={async () => {
        const exportAction = await dispatch(
          get(`exports/${lastPendingExportAction.id}`)
        );

        if (!onExportActionCompleted) return;

        const { status } = exportAction.response.body.data.attributes;
        const { id } = exportAction.response.body.data;

        if (status === 'completed') {
          onExportActionCompleted(id);
        }
      }}
      everyMs={3000}
    />
  );

  return { ongoingExportAction, exportTooltipText, pollerComponent };
};
