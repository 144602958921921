import React from 'react';

import type { PeerFeedback } from '../../../models';
import type { User } from 'models';

import {
  Box,
  BoxList,
  BreadcrumbAnchor,
  Column,
  Columns,
  Date as DateComponent,
  Divider,
  EmptyState,
  FetchContainer,
  SearchBar,
  Text,
  ValueManager,
} from 'components';

import { __ } from '../../../helpers/i18n';
import { groupPeerFeedbacksByDates } from '../../../helpers/models/peerFeedback';
import ReviewEmptyState from '../../components/userReview/ListEmptyState';
import SharedPeerFeedbackListItem from './SharedPeerFeedbackListItem';

type SharedPeerFeedbackListGroupedByDatesProps = {
  sharedPeerFeedbacks: Array<PeerFeedback>;
  activeUser: User;
  user: User;
};

type Props = {
  isFetching: boolean;
  hasError: boolean;
  sharedPeerFeedbacks: Array<PeerFeedback>;
  activeUser: User;
  user: User;
};

function SharedPeerFeedbackListGroupedByDates({
  sharedPeerFeedbacks,
  activeUser,
  user,
}: SharedPeerFeedbackListGroupedByDatesProps) {
  const peerFeedbacksGroupedByDates =
    groupPeerFeedbacksByDates(sharedPeerFeedbacks);

  if (sharedPeerFeedbacks.length === 0)
    return <EmptyState title={__('No peer feedback matches your search.')} />;

  return (
    <div>
      <BreadcrumbAnchor name="user_review_list" />
      {peerFeedbacksGroupedByDates.sortedDividerDates.map(
        (dividerDate, index, dividerDates) => (
          <div key={'divider-' + index}>
            <Divider
              childrenPosition="center"
              style={{ margin: '1.1rem 0px 0.4rem 0' }}
            >
              <Columns style={{ margin: '0px' }}>
                <Column isVerticallyCentered>
                  <Text size={5}>
                    <DateComponent
                      value={dividerDate.toUTCString()}
                      format={
                        dividerDate.getFullYear() ===
                        dividerDates[0].getFullYear()
                          ? 'MMMM YYYY'
                          : 'YYYY'
                      }
                    />
                  </Text>
                </Column>
              </Columns>
            </Divider>
            <Box>
              <BoxList>
                {peerFeedbacksGroupedByDates[index].map(sharedPeerFeedback => (
                  <SharedPeerFeedbackListItem
                    key={sharedPeerFeedback.id}
                    sharedPeerFeedback={sharedPeerFeedback}
                    withLink={activeUser.id === user.id}
                  />
                ))}
              </BoxList>
            </Box>
          </div>
        )
      )}
    </div>
  );
}

export default function SharedPeerFeedbackList({
  isFetching,
  hasError,
  sharedPeerFeedbacks,
  user,
  activeUser,
}: Props) {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() =>
        sharedPeerFeedbacks.length > 0 ? (
          <ValueManager
            initialValue=""
            render={(onSearchTermChange, searchTerm) => (
              <div style={{ padding: '1.25rem' }}>
                <Columns>
                  <Column size="half">
                    <SearchBar
                      value={searchTerm}
                      placeholder={__('Search users')}
                      onChange={onSearchTermChange}
                    />
                  </Column>
                </Columns>
                <SharedPeerFeedbackListGroupedByDates
                  activeUser={activeUser}
                  user={user}
                  sharedPeerFeedbacks={sharedPeerFeedbacks.filter(
                    peerFeedback =>
                      peerFeedback.reviewee.fullName
                        .toLowerCase()
                        .includes(searchTerm ? searchTerm.toLowerCase() : '') ||
                      (peerFeedback.reviewee.email &&
                        peerFeedback.reviewee.email.includes(
                          searchTerm ? searchTerm.toLowerCase() : ''
                        ))
                  )}
                />
              </div>
            )}
          />
        ) : (
          <ReviewEmptyState
            title={__('This user has not yet shared any feedback as a peer.')}
          />
        )
      }
    />
  );
}
