import React from 'react';

import type {
  PeerValidationConstraints,
  ThreeSixtyReviewCycle,
  User,
} from 'models';
import type { ReactNode } from 'react';

import { __, n__ } from 'helpers/i18n';
import { defaultNominatePeersInstruction } from 'helpers/models/reviewCycle';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Avatar,
  Button,
  Column,
  Columns,
  Date,
  FetchContainer,
  Helper,
  Label,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Notification,
  Testable,
  Text,
  Title5,
} from 'components';

import PeersList from 'scenes/components/userReview/PeersList';

import PeerSolicitationList from './PeerSolicitationList';

type Props = {
  user: User;
  isActive: boolean;
  onClose: () => void;
  reviewCycle: ThreeSixtyReviewCycle;
  userReviewId: string;
  peers?: Array<User>;
  onSubmit: (selectedUsers: Array<User>) => Promise<void>;
  title: string;
  subText?: string;
  submitButtonLabel: (peersCount: number) => ReactNode;
  shouldValidatePeerConstraints?: boolean;
};

type AfterDataLoaderProps = {
  peerValidationConstraints: PeerValidationConstraints;
} & Props &
  DataLoaderProvidedProps;

type State = {
  selectedUsers: Array<User>;
  hasError: boolean;
  isDirty: boolean;
  error: string | undefined | null;
};

class ManagePeersModal extends React.Component<AfterDataLoaderProps, State> {
  defaultState = {
    selectedUsers: [],
    hasError: false,
    isDirty: false,
    error: null,
  };

  state = { ...this.defaultState };

  componentWillReceiveProps(props: AfterDataLoaderProps) {
    if (!this.state.isDirty && props.peers) {
      this.setState({
        selectedUsers: props.peers,
      });
    }
  }

  onUsersChange = (users: Array<User>) => {
    this.setState({
      isDirty: true,
      selectedUsers: users,
    });
  };

  anyPeerSelected = () => this.state.selectedUsers.length > 0;

  peersValidationConstraintsFulfilled = (
    minPeers: number | null | undefined
  ) => {
    if (!!minPeers && minPeers > 0) {
      return this.state.selectedUsers.length >= minPeers;
    }

    return true;
  };

  shouldValidateButtonBeDisabled = (
    shouldValidatePeerConstraints: boolean,
    minPeers: number | null | undefined
  ) => {
    if (shouldValidatePeerConstraints) {
      return !this.peersValidationConstraintsFulfilled(minPeers);
    }

    return !this.anyPeerSelected();
  };

  submit = async () => {
    const { onSubmit } = this.props;

    this.setState({
      hasError: false,
    });
    try {
      await onSubmit(this.state.selectedUsers);
      if (this.props.isActive) this.props.onClose();
      this.setState(this.defaultState);
    } catch (e: any) {
      const errors = e && e.response && e.response.body;

      this.setState({
        hasError: true,
        error:
          errors && errors.length
            ? `Oops, something went wrong: ${errors[0]}`
            : 'Oops, something went wrong. Please try again in a few minutes or contact our support.',
      });
    }
  };

  render() {
    const {
      isActive,
      onClose,
      reviewCycle,
      userReviewId,
      user,
      title,
      subText,
      submitButtonLabel,
      shouldValidatePeerConstraints,
      peerValidationConstraints,
      isFetching,
      hasError,
    } = this.props;
    const nbPeersSelected = this.state.selectedUsers.length;
    const minPeers =
      peerValidationConstraints && peerValidationConstraints.minPeers;
    const constraintExplanation =
      peerValidationConstraints && peerValidationConstraints.explanation;

    return (
      <ModalCard
        isLarge
        isActive={isActive}
        onClose={onClose}
        refreshContentOnOpening
        testClassName="test-manage-peers-modal"
      >
        <ModalCardHead onClose={onClose}>
          <ModalCardTitle>
            <Columns isMobile>
              <Column isVerticallyCentered isNarrow>
                <Avatar url={user.avatarUrl} />
              </Column>
              <Column isVerticallyCentered>{title}</Column>
            </Columns>
          </ModalCardTitle>
        </ModalCardHead>
        <ModalCardBody>
          <FetchContainer
            isFetching={isFetching}
            hasError={hasError}
            loadingStyle="overlay"
            render={() => (
              <React.Fragment>
                <div style={{ marginBottom: 20 }}>
                  <Title5>{reviewCycle.name}</Title5>
                  <Text color="info">
                    {__(
                      'Ends on %1',
                      <Date value={reviewCycle.dateReviewRevieweeEnd} />
                    )}
                  </Text>
                </div>
                {this.state.hasError && (
                  <div style={{ marginBottom: 20 }}>
                    <Notification kind="danger">
                      {this.state.error}
                    </Notification>
                  </div>
                )}
                <div style={{ marginBottom: 20 }}>
                  <Text preserveLinebreaks={true}>
                    {reviewCycle.nominatePeersInstruction ||
                      defaultNominatePeersInstruction()}
                  </Text>
                </div>
                {subText && (
                  <div style={{ marginBottom: 20 }}>
                    <Text>{subText}</Text>
                  </div>
                )}
                {!!constraintExplanation && (
                  <Testable name="test-peer-validation-constraint-explanation">
                    <Helper style={{ alignItems: 'center' }}>
                      {constraintExplanation}
                    </Helper>
                  </Testable>
                )}
                <div style={{ marginBottom: 20 }}>
                  <p>
                    <Label>
                      {nbPeersSelected === 0
                        ? __('No peer selected yet')
                        : n__(
                            'One peer selected:',
                            '%1 peers selected:',
                            nbPeersSelected,
                            nbPeersSelected
                          )}
                    </Label>
                  </p>
                  <div>
                    <PeersList
                      editable
                      peers={this.state.selectedUsers}
                      reviewee={user}
                      onChange={users => this.onUsersChange(users)}
                    />
                  </div>
                  {this.anyPeerSelected() && (
                    <PeerSolicitationList
                      userReviewId={userReviewId}
                      selectedUsers={this.state.selectedUsers}
                    />
                  )}
                </div>
              </React.Fragment>
            )}
          />
        </ModalCardBody>
        <ModalCardFooter>
          <Button
            testClassName="test-manage-peers-submit-button"
            onClick={this.submit}
            color="primary"
            isLoading={isFetching}
            disabled={this.shouldValidateButtonBeDisabled(
              !!shouldValidatePeerConstraints,
              minPeers
            )}
          >
            {submitButtonLabel(this.state.selectedUsers.length)}
          </Button>
          <Button color="secondary" onClick={onClose}>
            {__('Cancel')}
          </Button>
        </ModalCardFooter>
      </ModalCard>
    );
  }
}

export default newDataLoader({
  fetch: ({ userReviewId }: Props) =>
    get(`user_reviews/${userReviewId}/peer_validation_constraints`),
  hydrate: {
    peerValidationConstraints: {},
  },
})(ManagePeersModal) as React.ComponentType<Props>;
