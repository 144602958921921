import React from 'react';

import ReviewCyclesList from './ReviewCyclesList';

type Props = {
  type: string;
};

const OtherActions = ({ type }: Props) => <ReviewCyclesList type={type} />;

export default OtherActions;
