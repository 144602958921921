import { map } from 'lodash';

import type {
  Relationships,
  RelationshipsForRecord,
  Resource,
} from '../../helpers/data/types/NormalizedJsonApiResponse';
import type { DataLoaderAction } from '../../lib/dataLoader/action_types';
import type { DataByResourceState, DataState } from './DataStateType';

import invariant from 'helpers/invariant';

import {
  DELETE_ALL_RESOURCES_FROM_KEY,
  RECEIVED_RESOURCES,
} from '../../lib/dataLoader/action_types';

type Records = {
  [id: string]: Resource;
};

const resourceInitialState: DataByResourceState = {
  records: {},
  relationships: {},
};

const SINGLE_RESOURCE_ACTION = 'RECEIVED_RESOURCE';

type SingleResourceAction = {
  type: typeof SINGLE_RESOURCE_ACTION;
  resources: { [id: string]: Resource };
  relationships: Relationships;
};

// A single Resource reducer
const resourceReducer = (
  state: DataByResourceState = resourceInitialState,
  action: SingleResourceAction
): DataByResourceState => {
  switch (action.type) {
    case SINGLE_RESOURCE_ACTION: {
      let newState = { ...state };
      newState = mergeRecords(newState, action.resources);
      newState = mergeRelationships(newState, action.relationships);
      return newState;
    }
    default:
      return state;
  }
};

// Reducer for all resources
const reducer = (
  state: DataState = {},
  action: DataLoaderAction
): DataState => {
  if (action.type === RECEIVED_RESOURCES) {
    const newState = { ...state };
    const { resources, relationships } = action;

    for (const resourcesByType in resources) {
      newState[resourcesByType] = resourceReducer(newState[resourcesByType], {
        type: SINGLE_RESOURCE_ACTION,
        resources: resources[resourcesByType],
        relationships: relationships[resourcesByType],
      });
    }

    return {
      ...newState,
      // @ts-expect-error: See comment in DataStateType
      updatedAt: Date.now(),
    };
  }

  if (action.type === DELETE_ALL_RESOURCES_FROM_KEY) {
    const { [action.keyId]: _resources, ...newState } = state;
    // @ts-expect-error: See comment in DataStateType
    newState.updatedAt = Date.now();
    return newState;
  }

  return state;
};

export default reducer;

const mergeRecords = (
  state: DataByResourceState,
  newRecords: Records
): DataByResourceState => {
  return {
    ...state,
    records: {
      ...state.records,
      ...map(newRecords, (resource: Resource, id: string) => {
        return {
          [id]: {
            ...(state.records[id] || {}),
            ...resource,
          },
        };
      }).reduce((acc, value) => Object.assign(acc, value), {}),
    },
  };
};

const mergeRelationships = (
  state: DataByResourceState,
  newRelationships: Relationships
): DataByResourceState => {
  return {
    ...state,
    relationships: {
      ...state.relationships,
      ...map(
        newRelationships,
        (recordRelationships: RelationshipsForRecord, id: string) => {
          return {
            [id]: {
              ...(state.relationships[id] || {}),
              ...recordRelationships,
            } as RelationshipsForRecord,
          };
        }
      ).reduce((acc, value) => Object.assign(acc, value), {}),
    },
  };
};

export const getResourceById = (
  state: DataState,
  resourceType: string,
  id: string
): Resource | undefined | null => {
  // if (!id) throw new Error("getResourceById(): id can't be null");
  if (!id) {
    console.warn(
      'getResourceById(): id should not be null. This will soon be turned in an error'
    );
    return null;
  }

  const stringId: string = id.toString();
  return state[resourceType] && stringId in state[resourceType].records
    ? {
        ...state[resourceType].records[stringId],
      }
    : null;
};

export const getResourceByIds = (
  state: DataState,
  resourceType: string,
  ids?: Array<string>
): Array<Resource> => {
  if (!ids) {
    throw new Error("getResourceByIds(): ids can't be null");
  }
  if (!state[resourceType]) return [];

  const stringIds: Array<string> = ids.map((id: string) => id.toString());
  return stringIds.flatMap((id: string) =>
    id in state[resourceType].records
      ? [
          {
            ...state[resourceType].records[id],
          } as Resource,
        ]
      : []
  );
};

export const getRelatedResourcesForRelationship = (
  state: DataState,
  resourceType: string,
  id: string,
  relationshipName: string
): Array<Resource> | Resource | undefined | null => {
  if (!state[resourceType] || !id || !relationshipName) return null;

  const stringId: string = id.toString();

  if (state[resourceType].relationships[stringId] === undefined) {
    throw new Error(
      `No relationship found for ${resourceType} ${stringId}: ${JSON.stringify(
        state[resourceType].records[stringId]
      )}`
    );
  }

  const relationshipsOfResource = state[resourceType].relationships[stringId];
  invariant(relationshipsOfResource, 'relationship should be defined');
  const resourcesMetadata = relationshipsOfResource[relationshipName];

  if (resourcesMetadata) {
    if (Array.isArray(resourcesMetadata)) {
      return resourcesMetadata.length > 0
        ? getResourceByIds(
            state,
            resourcesMetadata[0].type,
            resourcesMetadata.map(res => res.id)
          )
        : [];
    } else {
      return getResourceById(
        state,
        resourcesMetadata.type,
        resourcesMetadata.id
      );
    }
  } else {
    return null;
  }
};
