import React from 'react';

import { Match, pathToTemplatePreview } from 'helpers/paths';

import { Redirect } from 'components';

type Props = {
  match: Match<{
    id: string;
  }>;
};

const OldTemplatePreview = ({ match }: Props) => {
  return <Redirect to={pathToTemplatePreview(match.params.id, 'reviewee')} />;
};

export default OldTemplatePreview;
