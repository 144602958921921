import React from 'react';

import type { Objective } from 'models';

import { __ } from 'helpers/i18n';

import {
  Box,
  Checkbox,
  Column,
  Columns,
  Flex,
  Icon,
  Number,
  Text,
} from 'components';

type Props = {
  objective: Objective;
  onCheckboxClick: (objectiveId: string) => void;
  selected: boolean;
};

const ObjectiveListItem = ({ objective, selected, onCheckboxClick }: Props) => {
  return (
    <Box
      onClick={() => onCheckboxClick(objective.id)}
      additionalClassName="with-border"
      testClassName={`test-objective-item-${objective.id}`}
      size="small"
    >
      <Columns isMobile>
        <Column isNarrow isVerticallyCentered>
          <Checkbox
            isChecked={selected}
            onChange={() => onCheckboxClick(objective.id)}
          />
        </Column>
        <Column style={{ paddingLeft: 0 }}>
          <Columns contentVerticallyAligned>
            <Column isVerticallyCentered>
              <Text size={5}>{objective.title}</Text>
              &nbsp;
              {!objective.published && (
                <Text size={6} color="light">
                  {__('(draft)')}
                </Text>
              )}
            </Column>

            <Column isNarrow>
              <Flex style={{ alignSelf: 'center' }}>
                <Text color="dark-grey" style={{ marginRight: 16 }} size={5}>
                  <Icon
                    additionalClassName="is-size-2"
                    style={{ marginBottom: 2, marginRight: 2 }}
                    name="playlist_add_check"
                  />
                  {objective.completedKeyResultsCount}/
                  {objective.totalKeyResultsCount}
                </Text>

                <Text
                  color="primary"
                  size={3}
                  align="right"
                  style={{ minWidth: 50 }}
                >
                  <Number
                    value={objective.completion && objective.completion}
                    isPercentage
                  />
                </Text>
              </Flex>
            </Column>
          </Columns>
        </Column>
      </Columns>
    </Box>
  );
};

export default ObjectiveListItem;
