import React, { useContext } from 'react';

import { Entity } from 'models/Entity';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';

import EntityPicker from 'scenes/components/EntityPicker';

import { DataContext } from '../..';
import { EditableFieldProps } from '../EditableField';
import SyncedField from '../SyncedField';

const EntityField = ({
  fieldSynced,
  value,
  onChange,
  syncedFieldOptions,
}: EditableFieldProps) => {
  const { user } = useContext(DataContext);

  return fieldSynced ? (
    <SyncedField
      lockedFieldValue={!!value ? (user.entity as Entity).name : null}
      {...syncedFieldOptions}
    />
  ) : (
    <EntityPicker
      selectedEntityId={value}
      onChange={onChange}
      placeholder={__('Select an entity')}
      isClearable={can({
        perform: 'remove_user_from_entity',
        on: user,
      })}
    />
  );
};

export default EntityField;
