import { sortBy } from 'lodash';
import React from 'react';

import type {
  FeedbackableItem,
  ObjectiveCollection,
  PersonalObjective,
} from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';

import { Can } from 'components';
import { DragAndDropContainer, DragAndDropItem } from 'components';

import { ObjectiveCreatorWithSvg } from 'scenes/components/objectives';
import EmptyState from 'scenes/components/objectives/EmptyState';

import FeedbackableObjective from './FeedbackableObjective';

type Props = {
  feedbackableObjectives: Array<FeedbackableItem<PersonalObjective>>;
  objectiveCollection: ObjectiveCollection;
  onCreate: () => Promise<void>;
  onPositionUpdate: (objectiveId: string, position: number) => Promise<void>;
  afterUpdate?: () => Promise<void>;
  afterDestroy?: () => Promise<unknown>;
  isDisabled?: boolean;
  definesObjectives: boolean;
  revieweeFullName: string;
};

export default function FeedbackableObjectiveList({
  feedbackableObjectives,
  objectiveCollection,
  onCreate,
  onPositionUpdate,
  afterUpdate,
  afterDestroy,
  isDisabled,
  definesObjectives,
  revieweeFullName,
}: Props) {
  const canUpdateObjective = can({
    perform: 'update_objective',
    on: objectiveCollection,
  });

  if (feedbackableObjectives.length === 0) {
    return (
      <Can
        perform="create_objective"
        on={objectiveCollection}
        alternativeChildren={<EmptyState title={__('No objective defined')} />}
      >
        <ObjectiveCreatorWithSvg
          onCreate={onCreate}
          style={{ padding: 24, backgroundColor: 'white' }}
          description={
            definesObjectives
              ? __('Create an objective and start measuring progress')
              : __(
                  'Create one or several objectives to be evaluated and complete their progression'
                )
          }
          actionText={
            definesObjectives
              ? __('Create new objective')
              : __('Create an objective to be evaluated')
          }
          isDisabled={isDisabled}
        />
      </Can>
    );
  }

  return (
    <DragAndDropContainer onChangePosition={onPositionUpdate}>
      {sortBy(feedbackableObjectives, fo => fo.item.position).map(
        feedbackableObjective => {
          const { item } = feedbackableObjective;

          return (
            <DragAndDropItem
              key={item.id}
              itemId={item.id}
              position={item.position}
              style={{ marginBottom: 16 }}
              disabled={!canUpdateObjective}
              handlePosition="top"
              alwaysDisplayHandle
            >
              <FeedbackableObjective
                key={feedbackableObjective.id}
                feedbackableObjective={feedbackableObjective}
                objectiveCollection={objectiveCollection}
                afterUpdate={afterUpdate}
                afterDestroy={afterDestroy}
                revieweeFullName={revieweeFullName}
              />
            </DragAndDropItem>
          );
        }
      )}
    </DragAndDropContainer>
  );
}
