import type { PlannedActionStatus } from 'models';

import { __ } from 'helpers/i18n';

export function plannedActionStatusColor(status: PlannedActionStatus) {
  switch (status) {
    case 'to_do':
      return 'info';
    case 'in_progress':
      return 'primary';
    case 'done':
      return 'success';
    default:
      return 'info';
  }
}

export function plannedActionStatusLabel(status: PlannedActionStatus) {
  switch (status) {
    case 'to_do':
      return __('to do');
    case 'in_progress':
      return __('in progress');
    case 'done':
      return __('done');
    default:
      return status;
  }
}
