import React from 'react';

import type {
  ReviewBlockUpdateParams,
  SignatureBlock,
} from 'models/ReviewStructure';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import {
  Box,
  BoxSeparator,
  Title6,
  WithSavingStatusRecorder,
} from 'components';

import BlockContent from '../components/BlockContent';
import FeedbackOptionsSection from './FeedbackOptionsSection';

type Props = {
  block: SignatureBlock;
  hasSelfEvaluationEnabled: boolean;
  onChange: (params: ReviewBlockUpdateParams) => Promise<void>;
};

const SignatureBlockComponent = ({
  block,
  hasSelfEvaluationEnabled,
  onChange,
}: Props) => {
  return (
    <Box testClassName="test-signature-module-block">
      <Title6 transformation="uppercase">{__('Signature')}</Title6>

      <BoxSeparator style={{ margin: '16px 0' }} />

      <WithSavingStatusRecorder
        fieldUid={compositeKey({
          blockId: block.id,
          field: 'signature_block_content',
        })}
        onChange={onChange}
        render={autoSavingOnchange => (
          <BlockContent
            onChange={autoSavingOnchange}
            content={block.content}
            withRichText={block.richTextEnabled}
          />
        )}
      />

      <BoxSeparator style={{ margin: '16px 0' }} />

      <WithSavingStatusRecorder
        fieldUid={compositeKey({
          blockId: block.id,
          field: 'signature_block_content_feedback_options',
        })}
        onChange={onChange}
        render={_autoSavingOnchange => (
          <FeedbackOptionsSection
            hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
            feedbackOption={block.feedbackOption}
          />
        )}
      />
    </Box>
  );
};

export default SignatureBlockComponent;
