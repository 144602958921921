import Lottie from 'lottie-react';
import React, { CSSProperties } from 'react';

import animationData from './jsonFiles/confettis.json';

type Props = {
  style?: CSSProperties;
};

const Confettis = ({ style }: Props) => {
  return (
    <Lottie
      animationData={animationData}
      loop={true}
      style={{ height: '100vh', width: '100%', ...style }}
    />
  );
};

export default Confettis;
