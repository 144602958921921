import React from 'react';

import type { UserReview } from 'models';

import { date } from 'helpers/i18n';
import { __ } from 'helpers/i18n';
import { pathToReviewResults } from 'helpers/paths';

import { Box, Flex, Icon, Link, Text } from 'components';

type Props = {
  reviews: Array<UserReview>;
};

const RelatedReviewsSection = ({ reviews }: Props) => {
  return (
    <Box>
      <Text preset="16bs5.5">{__('Related reviews')}</Text>
      {reviews.map(review => (
        <Flex additionalClassName="mt-3" verticalAlign key={review.id}>
          <div className="w-1/3 pr-2">
            <Text color="grey">{date(review.createdAt, 'MMMM YYYY')}</Text>
          </div>
          <div className="w-2/3">
            <Link key={review.id} to={pathToReviewResults(review)}>
              <Flex verticalAlign additionalClassName="justify-between">
                <Text>{review.name}</Text>
                <Icon name="open_in_new" />
              </Flex>
            </Link>
          </div>
        </Flex>
      ))}
    </Box>
  );
};

export default RelatedReviewsSection;
