import React from 'react';

import type { Entity } from 'models';

import { __ } from 'helpers/i18n';

import {
  BoxListItem,
  Flex,
  HamburgerMenu,
  MenuItem,
  MenuList,
  Text,
} from 'components';

type Props = {
  entity: Entity;
  onDelete?: (id: string) => void;
  updateName: (id: string, name: string) => void;
  persisted: boolean;
};

const EntityItem = ({ entity, onDelete, updateName, persisted }: Props) => {
  const { id, name } = entity;

  return (
    <BoxListItem>
      <Flex style={{ justifyContent: 'space-between' }} verticalAlign>
        <Text
          isEditable
          editableOptions={{
            type: 'singlelineText',
            onChange: newName => updateName(id, newName),
            value: name,
            placeholder: __('Add a name to this entity'),
            autoFocus: !persisted,
            onBlur: () => updateName(id, name),
          }}
        >
          {entity.name}
        </Text>

        <Flex verticalAlign>
          <div style={{ marginRight: 20 }}>
            <Text style={{ marginRight: 5 }} color="info">
              {__('Users count:')}
            </Text>
            {entity.usersCount}
          </div>

          <HamburgerMenu align="right">
            <MenuList>
              <MenuItem
                key="delete"
                onClick={persisted && onDelete ? () => onDelete(id) : undefined}
                disabled={!persisted}
              >
                <Text color="danger">{__('Delete')}</Text>
              </MenuItem>
            </MenuList>
          </HamburgerMenu>
        </Flex>
      </Flex>
    </BoxListItem>
  );
};

export default EntityItem;
