import React from 'react';

type OnChange<T> = (value: T | undefined | null) => void;

type Props<T> = {
  initialValue: T | undefined | null;
  render: (
    onChange: OnChange<T>,
    value: T | null | undefined
  ) => React.ReactNode;
};

type State<T> = {
  value: T | undefined | null;
};

export default class ValueManager<T> extends React.Component<
  Props<T>,
  State<T>
> {
  constructor(props: Props<T>) {
    super(props);

    const { initialValue } = props;

    this.state = {
      value: initialValue,
    };
  }

  onChange: OnChange<T> = value => {
    this.setState({
      value,
    });
  };

  render() {
    return this.props.render(this.onChange, this.state.value);
  }
}
