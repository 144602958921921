import React, { CSSProperties, ReactNode } from 'react';

import classNames from 'helpers/classNames';

export type Props = {
  children: ReactNode;

  // https://bulma.io/documentation/form/general/#form-addons
  // Useful to attach controls together.
  hasAddons?: boolean;

  // https://bulma.io/documentation/form/general/#form-group
  // Useful to group sub controls
  isGrouped?: boolean;

  additionalClassName?: string;
  style?: CSSProperties;
};

export default function Field({
  style,
  children,
  isGrouped,
  hasAddons,
  additionalClassName,
}: Props) {
  return (
    <div
      style={style}
      className={classNames('field', additionalClassName, {
        'is-grouped': isGrouped,
        'has-addons': hasAddons,
      })}
    >
      {children}
    </div>
  );
}
