import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import type { ReviewCycle } from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToReviewCycle, pathToReviewCycleTemplate } from 'helpers/paths';

import {
  Button,
  DesignSystem,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Icon,
  PullRight,
  Redirect,
  Route,
} from 'components';

import Template from './Template';

type Props = {
  reviewCycle: ReviewCycle;
  userIsImpersonated: boolean;
};

const ReviewCycleTemplates = ({ reviewCycle, userIsImpersonated }: Props) => {
  const indexMatch = useRouteMatch();

  if (!userIsImpersonated) {
    return <Redirect to={pathToReviewCycle(reviewCycle.id)} />;
  }

  const hasTemplateIdAtEndOfPath =
    !!window.location.pathname.split('templates/')[1];
  if (!hasTemplateIdAtEndOfPath) {
    return (
      <Redirect
        to={pathToReviewCycleTemplate(
          reviewCycle.id,
          reviewCycle.defaultTemplate.id
        )}
      />
    );
  }

  return (
    <Route
      path={`${indexMatch.path}/:templateId`}
      render={({ match }) => {
        const { defaultTemplate, templates } = reviewCycle;
        invariant(templates, 'Templates must be passed here');

        const currentTemplateId = match.params.templateId;
        invariant(
          currentTemplateId,
          'Current template id should be defined at this point'
        );
        invariant(
          templates,
          'Current template id should be defined at this point'
        );

        const currentTemplate = templates.find(
          template => template.id === currentTemplateId
        );

        invariant(
          currentTemplate,
          `The template ${currentTemplateId} that you are searching does not belong to the cycle ${reviewCycle.id}`
        );

        return (
          <DesignSystem version={2}>
            <div className="review-cycle-templates">
              {templates.length > 1 && (
                <PullRight>
                  <Dropdown align="right">
                    <DropdownTrigger
                      additionalClassName="templates-dropdown-trigger"
                      render={dropdownIsOpened => (
                        <Button color="info" isOutlined={!dropdownIsOpened}>
                          <span>{currentTemplate.name}</span>
                          <Icon name="keyboard_arrow_down" />
                        </Button>
                      )}
                    />
                    <DropdownMenu>
                      {templates.map(template => (
                        <DropdownMenuItem
                          to={pathToReviewCycleTemplate(
                            reviewCycle.id,
                            template.id
                          )}
                          key={template.id}
                        >
                          {template.id === defaultTemplate.id
                            ? __('[DEFAULT]')
                            : ''}{' '}
                          {template.name}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </PullRight>
              )}

              <Template template={currentTemplate} />
            </div>
          </DesignSystem>
        );
      }}
    />
  );
};

export default ReviewCycleTemplates;
