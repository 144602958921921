import React, { MouseEventHandler, ReactNode } from 'react';

import classNames from 'helpers/classNames';

import { ComponentUIDProvider } from 'components';

import BulmaRadio, { type Props as BaseProps } from '../bulmaElements/Radio';

type Props = BaseProps & {
  label: ReactNode;
  children?: ReactNode;
  onChange?: () => any;
  testClassName?: string;
  dataTestValue?: string;
  name?: string;
  containerClickable?: boolean;
};

type State = {
  isSubmitting: boolean;
};

export default class Radio extends React.Component<Props, State> {
  state = {
    isSubmitting: false,
  };

  handleClick: MouseEventHandler<HTMLLabelElement | HTMLDivElement> =
    async e => {
      // Don't trigger the input's onChange on click
      e.preventDefault();

      const { onChange, disabled } = this.props;
      const { isSubmitting } = this.state;

      if (!onChange || disabled || isSubmitting) return;

      try {
        this.setState({ isSubmitting: true });

        await onChange();
      } finally {
        this.setState({ isSubmitting: false });
      }
    };

  render() {
    const {
      style,
      label,
      children,
      disabled,
      onChange,
      additionalClassName,
      testClassName,
      dataTestValue,
      name,
      containerClickable,
      ...otherProps
    } = this.props;

    return (
      <ComponentUIDProvider
        render={uid => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            style={style}
            className={classNames('radio', 'test-radio', additionalClassName)}
            data-test-value={dataTestValue}
            onClick={e => containerClickable && this.handleClick(e)}
          >
            <BulmaRadio
              {...otherProps}
              id={uid}
              disabled={disabled || this.state.isSubmitting}
              name={name}
              onChange={onChange}
            />
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <label
              htmlFor={uid}
              onClick={this.handleClick}
              className={testClassName}
            >
              <span style={{ marginLeft: label ? 8 : 0 }}>{label}</span>
            </label>
            <div>{otherProps.isChecked && children}</div>
          </div>
        )}
      />
    );
  }
}
