import { Entity } from 'models';

import { __ } from 'helpers/i18n';

const fakeAllUserEntity = (): Entity => ({
  id: '_all_users',
  name: __('All users'),
  type: 'entity' as const,
  usersCount: 0,
  createdAt: '',
});

export default fakeAllUserEntity;
