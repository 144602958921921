import classNames from 'classnames';
import React, { Fragment } from 'react';

import { SurveyAnswerSentimentCategory } from 'models/surveys';

import { Icon, MaterialIconName } from 'components';

type Props = {
  sentimentCategory: SurveyAnswerSentimentCategory;
  sizeClassName?: string;
};

const getIconProps = (sentimentCategory: SurveyAnswerSentimentCategory) => {
  switch (sentimentCategory) {
    case 'negative':
      return {
        name: 'sentiment_dissatisfied' as MaterialIconName,
        colorClass: 'text-red-fuckup',
      };
    case 'neutral':
      return {
        name: 'sentiment_neutral' as MaterialIconName,
        colorClass: 'text-orange-warning',
      };
    case 'positive':
      return {
        name: 'sentiment_satisfied' as MaterialIconName,
        colorClass: 'text-green-success',
      };
    default:
      throw new Error(`Unexpected sentiment category: ${sentimentCategory}`);
  }
};

const Sentiment = ({ sentimentCategory, sizeClassName }: Props) => {
  if (sentimentCategory === null || sentimentCategory === undefined)
    return <Fragment>-</Fragment>;
  if (sentimentCategory === 'none') return <Fragment />;

  const iconProps = getIconProps(sentimentCategory);

  return (
    <Icon
      name={iconProps.name}
      additionalClassName={classNames(
        iconProps.colorClass,
        sizeClassName || 'text-4xl'
      )}
      testClassName="test-survey-question-sentiment"
    />
  );
};

export default Sentiment;
