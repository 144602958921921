import React, { useState } from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

import Legend from './Legend';
import { RoleStats } from './QuestionPieChart';
import ChartTooltip from './Tooltip';

const colors = [
  '#81c1fd',
  '#fea58e',
  '#ea90b1',
  '#f5c78e',
  '#bda9ea',
  '#51d3d9',
  '#a2d28f',
  '#9784c2',
];

export const noAnswerColor = '#D3DADC';

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = props => {
  const { cx, cy, midAngle, innerRadius, outerRadius, payload } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const text = !!payload.percentage ? `${payload.percentage}%` : 'n/a';

  return (
    <text
      x={x}
      y={y}
      fill="white"
      fontSize="12px"
      fontWeight="600"
      fontFamily="ProximaNova"
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {text}
    </text>
  );
};

// TODO: TSFIXME: ideally this should be Array<PieChartValue> but making the values nullable break a lot of type elsewhere
type CustomPieChartValue = {
  label: string | null;
  value: number;
  percentage: number | null;
  realPercentage?: number | null;
  color?: string;
};

type Props = {
  title: string;
  data: RoleStats;
  onCellClick: (label: string | null) => void;
};

const SimplePieChart = ({ title, data: { values }, onCellClick }: Props) => {
  const [hiddenValues, setHiddenValues] = useState<string[]>([]);
  const valuesWithColor = values.map((item, index) => ({
    ...item,
    color: colors[index % colors.length],
  }));
  const filteredValues = valuesWithColor.filter(
    ({ label, percentage }) =>
      !hiddenValues.includes(label || 'null') && percentage > 0
  );
  const totalPercentageFromFilteredValues = filteredValues.reduce(
    (acc, curr) => acc + curr.value,
    0
  );
  const formattedValues: Array<CustomPieChartValue> = filteredValues.map(
    item => ({
      ...item,
      percentage: Math.round(
        (item.value / totalPercentageFromFilteredValues) * 100
      ),
    })
  );

  if (totalPercentageFromFilteredValues === 0)
    formattedValues.push({
      label: null,
      value: 1,
      percentage: 0,
      realPercentage: 0,
      color: noAnswerColor,
    });

  const handleLegendClick = (label: string | null) => {
    const labelKey = !!label ? label : 'null';

    if (hiddenValues.includes(labelKey)) {
      setHiddenValues(hiddenValues.filter(value => value !== labelKey));
    } else {
      setHiddenValues([...hiddenValues, labelKey]);
    }
  };

  return (
    <div>
      <PieChart width={164} height={164} style={{ margin: 'auto' }}>
        <Pie
          data={formattedValues}
          cx={80}
          cy={80}
          labelLine={false}
          label={renderCustomizedLabel}
          isAnimationActive={false}
          startAngle={90}
          endAngle={450}
          innerRadius={55}
          outerRadius={80}
          fill="#D3DADC"
          paddingAngle={1}
          dataKey="value"
          nameKey="label"
        >
          {formattedValues.map(
            entry =>
              !!entry.percentage && (
                <Cell
                  key={entry.label}
                  fill={entry.label === null ? noAnswerColor : entry.color}
                  onClick={() => onCellClick(entry.label)}
                />
              )
          )}
        </Pie>
        <Tooltip
          isAnimationActive={false}
          content={props => <ChartTooltip {...props} />}
          cursor={false}
        />
        <text
          x={84}
          y={86}
          fill="black"
          fontSize="14px"
          fontWeight="600"
          fontFamily="ProximaNova"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {title}
        </text>
      </PieChart>

      <Legend
        data={valuesWithColor}
        style={{ marginTop: 16 }}
        onLegendItemClick={handleLegendClick}
      />
    </div>
  );
};

export default SimplePieChart;
