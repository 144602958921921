import React, { Fragment } from 'react';

import { SurveyCampaignUpdatableAttributes } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n/index';
import { navigate, pathToSurveyCampaignEdit } from 'helpers/navigation';

import { post } from 'redux/actions/api';

import { PageHeader, PageTitle } from 'components';

import Stepper from 'scenes/components/Stepper';

import StepSettings from './Edit/steps/StepSettings';
import getSetupStepInfoList from './helpers/steps';

const NewCampaign = () => {
  const dispatch = useAppDispatch();

  const createCampaignAndRedirect = async (
    params: SurveyCampaignUpdatableAttributes
  ) => {
    const { response } = await dispatch(post('survey/campaigns', params));
    const campaignId: string = response.body.data.id;
    navigate(pathToSurveyCampaignEdit(campaignId));
  };

  return (
    <Fragment>
      <PageHeader title={__('New survey campaign')} withBackButton />
      <PageTitle title={[__('New survey campaign'), __('Admin')]} />

      <Stepper
        currentStepIndexFromElement={0}
        stepInfoList={getSetupStepInfoList(null)}
        renderChildren={() => (
          <StepSettings onContinueClick={createCampaignAndRedirect} />
        )}
      />
    </Fragment>
  );
};

export default NewCampaign;
