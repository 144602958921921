import React, { Fragment } from 'react';

import type { Organization, User } from 'models';

import can from 'helpers/can';

import { Box } from 'components';

import EditableFields from './EditableFields';
import Notifications from './Notifications';
import ReadOnlyFields from './ReadOnlyFields';

type Props = {
  user: User;
  refetchUser: () => Promise<any>;
  organization: Organization;
};

const UserInformation = ({ user, refetchUser }: Props) => {
  return (
    <Fragment>
      <Notifications user={user} />

      <Box>
        {can({ perform: 'update_profile_info', on: user }) ? (
          <EditableFields user={user} refetchUser={refetchUser} />
        ) : (
          <ReadOnlyFields user={user} />
        )}
      </Box>
    </Fragment>
  );
};

export default UserInformation;
