import React, { Fragment, ReactNode } from 'react';

import type { ReviewCycle } from 'models';

import { __ } from 'helpers/i18n';

import { Text } from 'components';

import ParticipantsChooserComponent from 'scenes/admin/reviewCycles/components/ParticipantsChooser';

type Props = {
  reviewCycle: ReviewCycle;
  renderAfter?: (refetch: () => Promise<void>) => ReactNode;
};

export default function ParticipantsChooser({
  reviewCycle,
  renderAfter,
}: Props) {
  return (
    <Fragment>
      <Text preset="24bs2">{__('Manual selection of participants')}</Text>
      <p style={{ marginBottom: 16 }}>
        {__('Select participants that will be reviewed in this campaign')}
      </p>

      <ParticipantsChooserComponent
        reviewCycle={reviewCycle}
        showBulkActions
        participantListStyle={{ height: 270 }}
        renderAfter={renderAfter}
      />
    </Fragment>
  );
}
