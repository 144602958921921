import React from 'react';

import type { User } from 'models';
import type { CSSProperties, ReactNode } from 'react';

import { pathToUser } from 'helpers/paths';

import { Avatar, Link, Text } from 'components';

type TitleProps = {
  children: ReactNode;
};

const DirectReportUserDetailsTitle = ({ children }: TitleProps) => {
  return (
    <div className="flex items-center justify-center">
      <Text preset="18bs5">{children}</Text>
    </div>
  );
};

const DirectReportUserDetailsSubtitle = ({ children }: TitleProps) => {
  return (
    <div className="flex items-center justify-center">
      <Text preset="13s7">{children}</Text>
    </div>
  );
};

type Props = {
  report: User;
  style?: CSSProperties;
};

const DirectReportUserDetails = ({ report, style }: Props) => {
  return (
    <div style={style}>
      <Avatar
        url={report.avatarUrl}
        size="normal"
        style={{
          margin: '0 auto',
          marginBottom: 10,
          justifyContent: 'center',
        }}
      />
      <DirectReportUserDetailsTitle>
        <Link to={pathToUser(report.id)}>{report.fullName}</Link>
      </DirectReportUserDetailsTitle>
      {report.jobTitle ? (
        <DirectReportUserDetailsSubtitle>
          {report.jobTitle}
        </DirectReportUserDetailsSubtitle>
      ) : (
        <div style={{ height: '12px' }} />
      )}
    </div>
  );
};

export default DirectReportUserDetails;
