import { sortBy } from 'lodash';
import * as React from 'react';

import type { UserReview } from 'models';

import invariant from 'helpers/invariant';

import { DesignSystem, Testable } from 'components';

import {
  OneOnOneReviewCycleItem,
  ThreeSixtyReviewCycleItem,
} from 'scenes/components/reviewCycle/ListItem';

import OneOnOneReviewCycle from './OneOnOneReviewCycle';
import ReviewEmptyState from './ReviewEmptyState';
import ThreeSixtyReviewCycle from './ThreeSixtyReviewCycle';

type Props = {
  userReviews: Array<UserReview>;
};

export default function MyReviewCycles({ userReviews }: Props) {
  if (userReviews.length === 0) return <ReviewEmptyState />;

  const sortedUserReviews = sortBy(userReviews, ({ reviewCycle }) => {
    invariant(reviewCycle, 'reviewCycle exists here');
    return reviewCycle.dateReviewRevieweeEnd;
  });

  return (
    <DesignSystem version={2}>
      <Testable name="test-my-reviews">
        {sortedUserReviews.map(userReview => {
          if (userReview.interactionType === 'one_on_one') {
            const { reviewCycle } = userReview;
            invariant(reviewCycle, 'reviewCycle exists here');

            return (
              <OneOnOneReviewCycleItem
                key={reviewCycle.id}
                reviewCycle={reviewCycle}
                userReview={userReview}
                displayDeadlines={false}
                hasManagerAnalytics={false}
              >
                <OneOnOneReviewCycle userReview={userReview} />
              </OneOnOneReviewCycleItem>
            );
          } else {
            const { reviewCycle } = userReview;
            invariant(reviewCycle, 'reviewCycle exists here');

            return (
              <ThreeSixtyReviewCycleItem
                key={reviewCycle.id}
                reviewCycle={reviewCycle}
                hasManagerAnalytics={false}
              >
                <ThreeSixtyReviewCycle
                  userReview={userReview}
                  reviewCycle={reviewCycle}
                />
              </ThreeSixtyReviewCycleItem>
            );
          }
        })}
      </Testable>
    </DesignSystem>
  );
}
