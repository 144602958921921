import React from 'react';

import { __ } from 'helpers/i18n';

import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Input,
} from 'components';

type Props = {
  value: number | null | undefined;
  onChange?: (newDurationInMinutes: number) => any;
  disabled?: boolean;
  placeholder?: string;
  name?: string;
};

const DEFAULT_AVAILABLE_DURATIONS_IN_MINUTES = [30, 45, 60, 90, 120];

const durationToHumanReadable = duration => {
  if (!duration) return '';

  return {
    '30': __('30 min'),
    '45': __('45 min'),
    '60': __('1 hour'),
    '90': __('1 hour 30 min'),
    '120': __('2 hours'),
  }[duration];
};

function DurationPicker({
  value,
  onChange,
  disabled,
  placeholder,
  name,
}: Props) {
  return (
    <Dropdown disabled={disabled}>
      <DropdownTrigger>
        <Input
          value={!!value ? durationToHumanReadable(value) : ''}
          placeholder={placeholder}
          disabled={disabled}
          name={name || 'duration'}
        />
      </DropdownTrigger>
      <DropdownMenu>
        {DEFAULT_AVAILABLE_DURATIONS_IN_MINUTES.map(duration => (
          <DropdownMenuItem
            key={duration}
            onClick={() => !!onChange && onChange(duration)}
            isActive={duration === value}
          >
            {durationToHumanReadable(duration)}
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export default DurationPicker;
