import type { Locale } from 'models';

const resolveLocaleLabel = (locale: Locale): string => {
  switch (locale) {
    case 'fr':
      return 'Français';
    case 'en':
      return 'English';
    case 'it':
      return 'Italiano';
    case 'de':
      return 'Deutsch';
    case 'nl':
      return 'Nederlands';
    case 'es':
      return 'Español';
    default:
      return 'English';
  }
};

export default resolveLocaleLabel;
