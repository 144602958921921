import React, { ComponentProps, ReactNode, useState } from 'react';
import { ErrorsType, ImageType } from 'react-images-uploading';

import { __ } from 'helpers/i18n';

import {
  Button,
  Flex,
  ImagePicker,
  ImageResizer,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';
import { ErrorSVG } from 'components/errors';

type Props = {
  onUpload: (base64Image: string) => Promise<void>;
  onDelete?: () => Promise<void>;
  modalTitle: string;
  modalSubtitle?: string;
  cropShape?: ComponentProps<typeof ImageResizer>['cropShape'];
  cropShapeAspect?: number;
  uploadErrorMessage?: string;
  minZoom?: number;
  imageUploaderRender: (
    error: any,
    onImageUpload: () => Promise<void>
  ) => ReactNode;
  children?: ReactNode;
  cropBackgroundColor?: string;
};

const ImageUploader = ({
  onUpload,
  modalTitle,
  modalSubtitle,
  cropShape,
  cropShapeAspect,
  uploadErrorMessage,
  imageUploaderRender,
  minZoom,
  children,
  cropBackgroundColor,
}: Props) => {
  const [image, setImage] = useState<ImageType | null>(null);
  const [resizedImage, setResizedImage] = useState<string | null>(null);
  const [uploadErrors, setUploadErrors] = useState<ErrorsType | null>(null);
  const [displayResizeModal, setDisplayResizeModal] = useState(false);

  return (
    <React.Fragment>
      <ImagePicker
        image={image}
        onChange={newImage => {
          setImage(newImage);
          setDisplayResizeModal(true);
        }}
        onError={errors => setUploadErrors(errors)}
        render={({ errors, onImageUpload }) => {
          return imageUploaderRender(errors, onImageUpload);
        }}
      />

      {image?.dataURL && (
        <ModalCard
          isActive={displayResizeModal}
          refreshContentOnOpening
          onClose={() => setDisplayResizeModal(false)}
          additionalClassName="image-resize-modal"
        >
          <ModalCardHead onClose={() => setDisplayResizeModal(false)}>
            <React.Fragment>
              <ModalCardTitle>{modalTitle}</ModalCardTitle>
            </React.Fragment>
          </ModalCardHead>
          <ModalCardBody style={{ minHeight: 300, position: 'relative' }}>
            {children}
            {!!modalSubtitle && (
              <div className="mb-4">
                <Text style={{ whiteSpace: 'pre-wrap' }}>{modalSubtitle}</Text>
              </div>
            )}
            <ImageResizer
              cropShape={cropShape}
              aspect={cropShapeAspect}
              src={image.dataURL}
              fileType={image.file?.type}
              onChange={setResizedImage}
              minZoom={minZoom}
              backgroundColor={cropBackgroundColor}
            />
          </ModalCardBody>
          <ModalCardFooter>
            <Button
              color="primary"
              disabled={!resizedImage}
              onClick={
                resizedImage
                  ? async () => {
                      await onUpload(resizedImage);
                      setDisplayResizeModal(false);
                    }
                  : undefined
              }
            >
              {__('Save')}
            </Button>
            <Button
              color="secondary"
              onClick={() => setDisplayResizeModal(false)}
            >
              {__('Cancel')}
            </Button>
          </ModalCardFooter>
        </ModalCard>
      )}

      {uploadErrors && (
        <ModalCard
          isActive
          refreshContentOnOpening
          onClose={() => setUploadErrors(null)}
          additionalClassName="image-upload-error-modal"
        >
          <ModalCardHead onClose={() => setUploadErrors(null)}>
            <ModalCardTitle>{__('Issue with your image')}</ModalCardTitle>
          </ModalCardHead>
          <ModalCardBody>
            <Flex additionalClassName="image-upload-error" direction="column">
              <ErrorSVG />
              <Text preset="16bs5.5" additionalClassName="mb-2">
                {__('Oups, something went wrong during the import')}
              </Text>
              {uploadErrorMessage ? (
                <Text>{uploadErrorMessage}</Text>
              ) : (
                <Text preset="13s7">
                  {__(
                    'The image must be in one of the following formats %1 .png .jpeg or .gif %1 with a maximal size of 5Mo',
                    <br />
                  )}
                </Text>
              )}
            </Flex>
          </ModalCardBody>
          <ModalCardFooter>
            <Button color="secondary" onClick={() => setUploadErrors(null)}>
              {__('Cancel')}
            </Button>
            <ImagePicker
              image={image}
              onChange={newImage => {
                setImage(newImage);
                setUploadErrors(null);
                setDisplayResizeModal(true);
              }}
              onError={errors => setUploadErrors(errors)}
              render={({ onImageUpload }) => {
                return (
                  <Button color="primary" onClick={onImageUpload}>
                    {__('Import another image')}
                  </Button>
                );
              }}
            />
          </ModalCardFooter>
        </ModalCard>
      )}
    </React.Fragment>
  );
};

export default ImageUploader;
