import React, { CSSProperties, ReactNode } from 'react';

import classNames from 'helpers/classNames';

import { Flex } from 'components';

type SeparatorColor =
  | 'primary'
  | 'info'
  | 'danger'
  | 'warning'
  | 'success'
  | 'normal'
  | 'light';

type Props = {
  children?: ReactNode;
  childrenPosition: 'left' | 'center' | 'right';
  color: SeparatorColor;
  additionalClassName?: string;
  style?: CSSProperties;
};

function Divider({
  children,
  color,
  childrenPosition,
  additionalClassName,
  style,
}: Props) {
  const className = classNames('divider', additionalClassName);

  if (!children)
    return <Hr color={color} additionalClassName={className} style={style} />;

  switch (childrenPosition) {
    case 'left':
      return (
        <Flex additionalClassName={className} style={style}>
          <div style={{ marginRight: 15 }}>{children}</div>
          <Hr color={color} style={{ flexGrow: 2 }} />
        </Flex>
      );
    case 'center':
      return (
        <Flex additionalClassName={className} style={style}>
          <Hr color={color} style={{ flexGrow: 2 }} />
          <div style={{ margin: '0px 15px' }}>{children}</div>
          <Hr color={color} style={{ flexGrow: 2 }} />
        </Flex>
      );
    case 'right':
      return (
        <Flex additionalClassName={className} style={style}>
          <Hr color={color} style={{ flexGrow: 2 }} />
          <div style={{ marginLeft: 15 }}>{children}</div>
        </Flex>
      );
    default:
      return null;
  }
}

Divider.defaultProps = {
  childrenPosition: 'center',
  color: 'info',
};

export default Divider;

type HRProps = {
  color: SeparatorColor;
  style?: CSSProperties;
  additionalClassName?: string;
};

function Hr({ color, style, additionalClassName }: HRProps) {
  return (
    <hr
      style={style}
      className={classNames(additionalClassName, {
        'is-primary': color && color === 'primary',
        'is-info': color && color === 'info',
        'is-danger': color && color === 'danger',
        'is-warning': color && color === 'warning',
        'is-success': color && color === 'success',
        'is-dark': color && color === 'normal',
        'is-grey-light': color && color === 'light',
      })}
    />
  );
}
