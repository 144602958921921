import React from 'react';

import type { ReactNode } from 'react';

import { Flex, Percentage, Text } from 'components';

type Props = {
  label?: ReactNode;
  completion: number | undefined | null;
};

const ObjectiveCompletionText = ({ label, completion }: Props) => {
  return (
    <Flex verticalAlign style={{ whiteSpace: 'nowrap' }}>
      {label && (
        <Text size={6} style={{ marginRight: '6px' }}>
          {label}
        </Text>
      )}

      <Text color="info" size={5}>
        <Percentage value={completion} />
      </Text>
    </Flex>
  );
};

export default ObjectiveCompletionText;
