import { isEmpty, size } from 'lodash';
import React from 'react';

import type { FileScan } from 'models';
import type { ReduxStore } from 'redux/reducers';

import { useAppSelector } from 'helpers/hooks';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { deleteAllResourcesFromKey } from 'lib/dataLoader/actions';

import {
  Button,
  Helper,
  Icon,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';
import FilesUploader, {
  type Document,
  type Documents,
  DragAndDropFileInput,
  type FilesUploaderProps,
} from 'components/behaviors/FilesUploader';

import { MAX_FILES_COUNT } from '../AttachFilesSection';
import DocumentListItem from './DocumentListItem';

type Props = {
  isActive: boolean;
  maxUploadsCount: number;
  onClose: () => void;
  handleAttachments: (props: Array<string>) => void;
};

type FileScans = {
  [id: string]: FileScan;
};

const ACCEPTED_FILE_TYPES = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const AttachFilesModal = ({
  isActive,
  maxUploadsCount,
  onClose,
  handleAttachments,
}: Props) => {
  const dispatch = useAppDispatch();
  const fileScans = useAppSelector(
    (state: ReduxStore) => state.data.fileScan?.records
  ) as unknown as FileScans;

  const getFileScan = (fileSignedId: string): FileScan | undefined => {
    if (!fileScans) return;

    return Object.values(fileScans).find(
      fileScan => fileScan.fileSignedId === fileSignedId
    );
  };

  const isDocumentSafe = (document: Document) =>
    getFileScan(document.signedId)?.status === 'safe';

  const isButtonDisabled = (documents: Documents) =>
    isEmpty(documents) ||
    size(documents) > maxUploadsCount ||
    Object.values(documents).some(document => !isDocumentSafe(document));

  const handleOnClose = () => {
    onClose();
    if (!isEmpty(fileScans)) {
      dispatch(deleteAllResourcesFromKey('fileScan'));
    }
  };

  return (
    <ModalCard onClose={handleOnClose} isActive={isActive} isLarge>
      <ModalCardHead>
        <ModalCardTitle>{__('Attach documents to the review')}</ModalCardTitle>
      </ModalCardHead>
      <FilesUploader>
        {({ onFilesDrop, documents, removeDocument }: FilesUploaderProps) => (
          <React.Fragment>
            <ModalCardBody
              style={{
                paddingBottom: 16,
                gap: 16,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <DragAndDropFileInput
                onFilesDrop={onFilesDrop}
                acceptedFileTypes={ACCEPTED_FILE_TYPES}
                maxFileSize={5}
                maxFilesCount={10}
              />
              {!isEmpty(documents) &&
                Object.values(documents).map(document => (
                  <DocumentListItem
                    key={document.id}
                    document={document}
                    removeDocument={() => {
                      removeDocument(document.id);
                    }}
                  />
                ))}
              <Helper>
                <p>
                  {__(
                    'Documents added here will be visible once you click Add & Share, even if you did not share your feedback.'
                  )}
                </p>
              </Helper>
            </ModalCardBody>
            <ModalCardFooter>
              <Button onClick={handleOnClose} color="secondary">
                {__('Cancel')}
              </Button>
              <Button
                disabled={isButtonDisabled(documents)}
                // @ts-ignore TSFIXME: Fix strictNullChecks error
                disabledExplanation={
                  size(documents) > maxUploadsCount &&
                  __('Maximum %1 files can be attached', MAX_FILES_COUNT)
                }
                onClick={() =>
                  handleAttachments(
                    Object.values(documents).map(document => document.signedId)
                  )
                }
                color="primary"
              >
                <Icon style={{ marginRight: 8 }} name="send" />
                {__('Add & Share')}
              </Button>
            </ModalCardFooter>
          </React.Fragment>
        )}
      </FilesUploader>
    </ModalCard>
  );
};

export default AttachFilesModal;
