import React from 'react';

import type { PeopleUserReviewStatus } from 'models/PeopleUserReview';

import { __ } from 'helpers/i18n';

import { DeprecatedTag } from 'components';

type Props = {
  status: PeopleUserReviewStatus;
  isLocked?: boolean;
};

const PeopleUserReviewStatusTag = ({ status, isLocked }: Props) => {
  let text = __('unknown');
  let props: React.ComponentProps<typeof DeprecatedTag> = {
    isRounded: true,
    style: {
      fontSize: '12px',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      color: 'white',
      maxWidth: '100%',
      backgroundColor: '',
    },
    color: 'primary',
    rightIcon: undefined,
  };

  if (isLocked) {
    props.rightIcon = { name: 'lock' };
    props.style = {
      ...props.style,
      backgroundColor: '#32325D',
    };

    return <DeprecatedTag {...props}>{__('Locked')}</DeprecatedTag>;
  }

  switch (status) {
    case 'to_do':
      props.color = 'light';
      text = __('To complete');
      props.style = { ...props.style };
      props.rightIcon = { name: 'radio_button_unchecked' };
      break;
    case 'completed':
      text = __('Completed');
      props.rightIcon = { name: 'check_circle' };
      break;
    case 'validated':
      props.color = 'success';
      text = __('Validated');
      props.rightIcon = { name: 'check_circle', filled: true };
      break;
    default:
      props.color = 'warning';
      break;
  }

  return (
    <DeprecatedTag {...props}>
      <span className="is-text-overflow-ellipsis">{text}</span>
    </DeprecatedTag>
  );
};

export default PeopleUserReviewStatusTag;
