import React from 'react';

import { somethingWentWrong } from 'helpers/commonPhrases';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { post, put } from 'redux/actions/api';
import { errorNotice } from 'redux/actions/application';

import { hasShape, unknownType } from 'types/predicates/WithShape';

import { Icon } from 'components';

const useUpdateCampaignStatus = (campaignId: string) => {
  const dispatch = useAppDispatch();

  const confirmLaunch = () => {
    return new Promise(resolve => {
      confirmAsync(
        __('Launch the campaign'),
        __(
          'After clicking on "Launch the campaign", participants will receive a launch email and will be able to participate in the survey.'
        ),
        {
          confirmLabel: __('Launch the campaign'),
          inlineButtonIcon: <Icon name="send" additionalClassName="mr-1" />,
          onConfirm: async () => {
            resolve(true);
          },
          onCancel: async () => {
            resolve(false);
          },
        }
      );
    });
  };

  const launchCampaign = () => {
    return dispatch(
      post(`survey/campaigns/${campaignId}/launch`, undefined, {
        withDefaultErrorHandling: false,
      })
    );
  };

  const scheduleCampaign = () => {
    return dispatch(
      post(`survey/campaigns/${campaignId}/schedule`, undefined, {
        withDefaultErrorHandling: false,
      })
    );
  };

  const handleSubmitErrors = async (error: unknown) => {
    if (
      hasShape(error, {
        response: { body: { errors: { form: [unknownType] } } },
      })
    ) {
      const form = error.response.body.errors.form;
      await dispatch(
        put(`survey/campaigns/${campaignId}`, {
          setupStepIndex: 1,
        })
      );
      dispatch(
        errorNotice(
          __(
            "The campaign is invalid: %1 Go to the 'Form' step to fix it.",
            form.join(' ')
          )
        )
      );
    } else if (
      hasShape(error, {
        response: { body: { errors: { nextPeriodAt: [unknownType] } } },
      })
    ) {
      const nextPeriodAt = error.response.body.errors.nextPeriodAt;
      await dispatch(
        put(`survey/campaigns/${campaignId}`, {
          setupStepIndex: 3,
          nextPeriodAt: null,
        })
      );
      dispatch(
        errorNotice(
          __(
            "The campaign is invalid: %1 Go to the 'Frequency' step to fix it.",
            nextPeriodAt.join(' ')
          )
        )
      );
    } else {
      dispatch(errorNotice(somethingWentWrong()));
    }
  };

  return {
    confirmLaunch,
    launchCampaign,
    scheduleCampaign,
    handleSubmitErrors,
  };
};

export default useUpdateCampaignStatus;
