import React, { Fragment } from 'react';

import type { Answer } from 'models';

import { __ } from 'helpers/i18n';

import { Avatar, Text } from 'components';

type Props = {
  closedAnswers: Answer[];
  isOverallRating: boolean;
};

const ClosedAnswerAvatarsOnMobile = ({
  closedAnswers,
  isOverallRating,
}: Props) => {
  const translateNoneRating = (authorFullName: string) =>
    isOverallRating
      ? __('%1 did not rate the domain overall assessment', authorFullName)
      : __('%1 did not evaluate this skill', authorFullName);

  return (
    <Fragment>
      {closedAnswers.map(answer => {
        return (
          <div key={answer.id} className="flex mb-2">
            <Avatar
              url={answer.author.avatarUrl}
              size="smaller"
              additionalClassName="mr-4"
            />
            <Text
              preset="14s6"
              color="soften"
              additionalClassName="self-center"
            >
              {answer.rating === 'none' || answer.rating === null
                ? translateNoneRating(answer.author.fullName)
                : answer.rating}
            </Text>
          </div>
        );
      })}
    </Fragment>
  );
};

export default ClosedAnswerAvatarsOnMobile;
