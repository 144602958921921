import { __ } from 'helpers/i18n';

const reviewTemplateVariables = (): Array<{
  name: string;
  tag: string;
}> => {
  return [
    {
      name: __("Reviewee's first name"),
      tag: '[[reviewee.firstName]]',
    },
    {
      name: __("Reviewee's last name"),
      tag: '[[reviewee.lastName]]',
    },
    {
      name: __("Reviewee's initials"),
      tag: '[[reviewee.initials]]',
    },
    {
      name: __("Reviewee's full name"),
      tag: '[[reviewee.fullName]]',
    },
    {
      name: __("Reviewee's team name"),
      tag: '[[reviewee.teamName]]',
    },
    {
      name: __("Reviewee's work start date"),
      tag: '[[reviewee.workStartDate]]',
    },
    {
      name: __("Reviewee's email"),
      tag: '[[reviewee.email]]',
    },
    {
      name: __("Reviewee's job title"),
      tag: '[[reviewee.jobTitle]]',
    },
    {
      name: __("Reviewee's registration number"),
      tag: '[[reviewee.registrationNumber]]',
    },
    {
      name: __("Reviewee's level"),
      tag: '[[reviewee.level]]',
    },
    {
      name: __("Reviewee's service"),
      tag: '[[reviewee.service]]',
    },
    {
      name: __("Reviewee's department"),
      tag: '[[reviewee.department]]',
    },
    {
      name: __("Reviewee's region"),
      tag: '[[reviewee.region]]',
    },
    {
      name: __(
        "Link to HRIS reviewee's profile (only for BambooHR and Lucca integrations)"
      ),
      tag: '[[reviewee.externalEmployeeUrl]]',
    },
  ];
};

export default reviewTemplateVariables;
