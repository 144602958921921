import React from 'react';

import can from 'helpers/can';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import {
  Button,
  DeprecatedContainerElevo,
  DeprecatedPageHeader,
  DeprecatedPageHeaderTitle,
  Icon,
} from 'components';

import TrainingPeriodCreationModal from 'scenes/components/TrainingPeriodCreationModal';

import CreationInformationBox from './CreationInformationBox';
import EmptyState from './EmptyState';

const PeriodCreator = () => {
  const organization = useOrganization();
  const [isCreationModalActive, setIsCreationModalActive] =
    React.useState(false);
  const canCreatePeriod = can({
    perform: 'create_training_period',
    on: organization,
  });

  return (
    <DeprecatedContainerElevo style={{ marginTop: '20px' }}>
      <DeprecatedPageHeader>
        <DeprecatedPageHeaderTitle>{__('Trainings')}</DeprecatedPageHeaderTitle>
      </DeprecatedPageHeader>

      <Button
        color="primary"
        onClick={() => setIsCreationModalActive(true)}
        disabled={!canCreatePeriod}
      >
        <Icon name="add" />
        <span>{__('Create a new period')}</span>
      </Button>

      {canCreatePeriod ? (
        <CreationInformationBox
          onTextClick={() => setIsCreationModalActive(true)}
        />
      ) : (
        <EmptyState />
      )}

      {isCreationModalActive && (
        <TrainingPeriodCreationModal
          onClose={() => setIsCreationModalActive(false)}
        />
      )}
    </DeprecatedContainerElevo>
  );
};

export default PeriodCreator;
