import { uniq } from 'lodash';
import React, { useState } from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { Occupation, User } from 'models';

import can from 'helpers/can';
import {
  dateWithoutTimeFromDateString,
  formatDateToLocaleMonthAndYear,
  timeSinceDate,
} from 'helpers/date';
import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  Button,
  DesignSystem,
  FetchContainer,
  Icon,
  Text,
} from 'components';

import EmptyState from './EmptyState';
import OccupationHistory from './OccupationHistory';
import OccupationManagementModal from './OccupationManagementModal';

type Props = {
  user: User;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    occupations: Array<Occupation>;
  };

const ProfessionalBackground = ({
  user,
  occupations,
  hasError,
  isFetching,
  refetchData,
}: AfterDataLoaderProps) => {
  const [blurSalary, setBlurSalary] = useState(true);
  const [
    isOccupationManagementModalActive,
    setIsOccupationManagementModalActive,
  ] = useState(false);

  const showBlurButton =
    !!occupations &&
    occupations.some(
      occupation =>
        !!occupation.fixedRemunerationCents ||
        !!occupation.variableRemunerationCents
    );
  const canManageProfessionalBackground = can({
    perform: 'manage_professional_background',
    on: user,
  });

  return (
    <DesignSystem version={2}>
      <Box>
        <div className="flex items-center">
          {!!user.workStartDate && (
            <div>
              <Text preset="16bs5.5" additionalClassName="block mb-0">
                {__('Seniority')}
              </Text>
              <Text color="light" transformation="capitalized">
                {formatDateToLocaleMonthAndYear(
                  dateWithoutTimeFromDateString(user.workStartDate)
                )}
                {' • '}
                {timeSinceDate(
                  dateWithoutTimeFromDateString(user.workStartDate)
                )}
              </Text>
            </div>
          )}
          <div className="ml-auto flex gap-2 h-min">
            {showBlurButton && (
              <Button
                onClick={() => setBlurSalary(!blurSalary)}
                color="secondary"
                hasIconOnly
              >
                <Icon name={blurSalary ? 'visibility_off' : 'visibility'} />
              </Button>
            )}
            {canManageProfessionalBackground && (
              <Button
                onClick={() => setIsOccupationManagementModalActive(true)}
                color="primary"
              >
                <Icon name="add" additionalClassName="mr-1" />
                {__('Add a job')}
              </Button>
            )}
          </div>
        </div>

        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          render={() =>
            occupations.length === 0 ? (
              <EmptyState user={user} />
            ) : (
              <OccupationHistory
                user={user}
                occupations={occupations}
                blurSalary={blurSalary}
                refetchOccupations={refetchData}
              />
            )
          }
        />
      </Box>
      {isOccupationManagementModalActive && (
        <OccupationManagementModal
          user={user}
          existingOccupationTitles={uniq(
            occupations.map(occupation => occupation.title)
          ).sort()}
          onClose={() => setIsOccupationManagementModalActive(false)}
          refetchOccupations={refetchData}
        />
      )}
    </DesignSystem>
  );
};

export default newDataLoader({
  fetch: ({ user }: Props) => get(`users/${user.id}/occupations`),
  hydrate: {
    occupations: {},
  },
})(ProfessionalBackground) as React.ComponentType<Props>;
