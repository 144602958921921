import * as React from 'react';

import { User } from 'models';

import { n__, toSentence } from 'helpers/i18n';

import { Helper } from 'components';

type Props = {
  isScheduled: boolean;
  participantsWithEmail: Array<User>;
  participantsWithoutEmail: Array<User>;
};

export default function ScheduleMeetingModalHelper({
  isScheduled,
  participantsWithEmail,
  participantsWithoutEmail,
}: Props) {
  let translation = null;

  const participantsWithEmailLength = participantsWithEmail.length;
  const anyEmailLessParticipant = participantsWithoutEmail.length > 0;
  const withEmailFullNames =
    participantsWithEmail.length > 0
      ? toSentence(participantsWithEmail.map(({ fullName }) => fullName))
      : '';
  const withEmailLessFullNames = anyEmailLessParticipant
    ? toSentence(participantsWithoutEmail.map(({ fullName }) => fullName))
    : '';

  if (isScheduled) {
    // @ts-ignore TSFIXME: Fix strictNullChecks error
    translation = anyEmailLessParticipant
      ? n__(
          'A new calendar invitation will be automatically sent to %2 but not %3.',
          'A new calendar invitation will be automatically sent to %2 but not %3.',
          participantsWithEmailLength,
          withEmailFullNames,
          withEmailLessFullNames
        )
      : n__(
          'A new calendar invitation will be automatically sent to %2.',
          'A new calendar invitation will be automatically sent to %2.',
          participantsWithEmailLength,
          withEmailFullNames
        );
  } else {
    // @ts-ignore TSFIXME: Fix strictNullChecks error
    translation = anyEmailLessParticipant
      ? n__(
          'A calendar invitation will be automatically sent to %2 but not %3.',
          'A calendar invitation will be automatically sent to %2 but not %3.',
          participantsWithEmailLength,
          withEmailFullNames,
          withEmailLessFullNames
        )
      : n__(
          'A calendar invitation will be automatically sent to %2.',
          'A calendar invitation will be automatically sent to %2.',
          participantsWithEmailLength,
          withEmailFullNames
        );
  }
  return (
    <Helper>
      <p>{translation}</p>
    </Helper>
  );
}
