import React from 'react';

import { Locale } from 'models';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useOrganization } from 'helpers/hooks';
import { languageForLocale, localeSelectOptions } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { Select } from 'components';

import { EditableFieldProps } from '../EditableField';
import SyncedField from '../SyncedField';

type LocaleOption = {
  label: string;
  value: Locale;
};

const LocaleField = ({
  value,
  onChange,
  fieldSynced,
  syncedFieldOptions,
}: EditableFieldProps) => {
  const organization = useOrganization();

  const onLocaleChange = (
    option: LocaleOption | LocaleOption[] | null | void
  ): void => {
    invariant(option && !Array.isArray(option), 'Locale cannot be empty');

    onChange(option.value);
  };

  return fieldSynced ? (
    <SyncedField
      lockedFieldValue={languageForLocale(value)}
      {...syncedFieldOptions}
    />
  ) : (
    <Select
      value={{
        value: value,
        label: languageForLocale(value),
      }}
      options={localeSelectOptions(organization)}
      onChange={onLocaleChange}
      isClearable={false}
    />
  );
};

export default LocaleField;
