import numeral from 'numeral';
import React, { Fragment } from 'react';

numeral.nullFormat('-');

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger#Polyfill
function isInteger(value) {
  return (
    typeof value === 'number' &&
    window.isFinite(value) &&
    Math.floor(value) === value
  );
}

type NumberProps = {
  value: undefined | null | string | number;
  format?: string;
  suffix?: string;
  isPercentage?: boolean;
};

export const Number = (props: NumberProps) => {
  const { isPercentage, suffix } = props;
  let { format, value } = props;

  let numberValue =
    value !== null && typeof value !== 'undefined'
      ? window.Number(value)
      : (value as null | undefined);

  if (!format) {
    if (isInteger(numberValue)) {
      format = '0,0';
    } else {
      format = '0.0';
    }

    if (isPercentage) {
      format += '%';
    }
  }

  // Required until we upgrade
  // C.f. https://github.com/adamwdraper/Numeral-js/issues/559
  if (
    numberValue !== null &&
    typeof numberValue !== 'undefined' &&
    isPercentage
  ) {
    numberValue = numberValue / 100;
  }

  let formattedValue = numeral(numberValue).format(format);

  if (numberValue !== null && !!suffix) {
    formattedValue += suffix;
  }

  return <Fragment>{formattedValue}</Fragment>;
};

type PercentageProps = {
  value: number | null | undefined;
};

export const Percentage = ({ value }: PercentageProps) => {
  if (!value && value !== 0) return <Fragment>--%</Fragment>;

  const formatted = numeral(window.Number(value / 100)).format('0%');

  return <Fragment>{formatted.replace('%', '')}%</Fragment>;
};
