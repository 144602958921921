import React from 'react';

const sidebarClassNames = 'w-6 flex items-center justify-center mr-4';
const SideBarRound = () => (
  <div className={sidebarClassNames}>
    <div className="w-2 h-2 rounded-lg bg-text-lightest" />
  </div>
);

const SideBarLine = () => (
  <div className={sidebarClassNames}>
    <div
      className="w-px bg-text-lightest mt-2 mb-1"
      style={{ height: 'calc(100% - 12px)' }}
    />
  </div>
);

export { SideBarRound, SideBarLine };
