import moment from 'moment';

import { __ } from './__';

export type DateValue = string | number | null | undefined;

export const date = (value: DateValue | Date, format?: string): string => {
  if (!value) return '-';
  return moment(value).format(format || 'L');
};

export const dateRelative = (value: DateValue): string => {
  return moment(value).fromNow();
};

type DateCalendarOptions = {
  hours?: boolean;
};

export const dateCalendar = (
  value: DateValue,
  options?: DateCalendarOptions
): string => {
  if (options?.hours === false) {
    return moment(value).calendar({
      sameDay: `[${__('Today')}]`,
      nextDay: `[${__('Tomorrow')}]`,
      lastDay: `[${__('Yesterday')}]`,
      nextWeek: 'L',
      lastWeek: 'L',
      sameElse: 'L',
    });
  } else {
    return moment(value).calendar();
  }
};
