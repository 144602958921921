// @flow

// Export Environment stages
export type Stage = 'development' | 'staging' | 'production' | 'test';

const allowedStages: Array<Stage> = [
  'development',
  'staging',
  'production',
  'test',
];

export function getStage(): Stage {
  const stageFromEnv = process.env.REACT_APP_HEROKU_ENV;

  if (process.env.STORYBOOK_ENV) return 'production';

  if (!stageFromEnv && process.env.NODE_ENV === 'development') {
    return 'development';
  }

  if (allowedStages.includes(stageFromEnv)) {
    return stageFromEnv;
  }

  throw new Error(`Not a valid environment: ${stageFromEnv || 'undefined'}`);
}

export const isDevelopment: () => boolean = () => getStage() === 'development';
export const isStaging: () => boolean = () => getStage() === 'staging';
export const isProduction: () => boolean = () => getStage() === 'production';
export const isTest: () => boolean = () => getStage() === 'test';
