import * as React from 'react';

import type { TrainingPeriod, User } from 'models';

import { __ } from 'helpers/i18n';
import { pathToUserTrainings } from 'helpers/paths';

import { Button, Centered, Helper, Icon, Link, Title } from 'components';

import TrainingRequestModal from 'scenes/components/TrainingRequestModal';

type Props = {
  currentTrainingPeriod: TrainingPeriod;
  reviewee: User;
  onConfirm: () => void;
};

export default function TrainingRequestCreationSection({
  currentTrainingPeriod,
  reviewee,
  onConfirm,
}: Props) {
  const [modalIsActive, setModalIsActive] = React.useState(false);

  return (
    <div className="gtm-training-request-creation-section">
      <Title additionalClassName="side-bar-title" size={5} weight="bold">
        {__('Training requests')}
      </Title>

      <div style={{ marginBottom: 16 }}>
        <Helper style={{ marginBottom: 0 }}>
          <p>
            {__(
              'The created training requests will be visible in %1’s %2.',
              reviewee.firstName,
              <Link to={pathToUserTrainings(reviewee.id)}>{__('profile')}</Link>
            )}
          </p>
        </Helper>
      </div>

      <Centered>
        <Button color="secondary" onClick={() => setModalIsActive(true)}>
          <Icon style={{ marginRight: 5 }} name="add" />
          {__('Make a request')}
        </Button>
      </Centered>

      {modalIsActive && (
        <TrainingRequestModal
          currentTrainingPeriodSlug={currentTrainingPeriod.slug}
          concernedUser={reviewee}
          isActive
          createdFromReview
          onClose={() => setModalIsActive(false)}
          onConfirmationClose={() => {
            setModalIsActive(false);
            onConfirm();
          }}
        />
      )}
    </div>
  );
}
