import React from 'react';

import type { ThreeSixtyUserReview } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n/index';

import { sendReminder } from 'redux/actions/resources';

import { MenuItem } from 'components';

type Props = {
  userReview: ThreeSixtyUserReview;
};

const RemindPeersNominationAction = ({ userReview }: Props) => {
  const dispatch = useAppDispatch();
  const sendNominatePeersReminder = () => {
    dispatch(
      sendReminder(
        userReview.id,
        'three_sixty_reviewee_reminder_to_nominate_peers'
      )
    );
  };
  return (
    <MenuItem onClick={sendNominatePeersReminder}>
      {__('Remind the reviewee to nominate their peers')}
    </MenuItem>
  );
};

export default RemindPeersNominationAction;
