// @flow
import { merge } from 'lodash';
import * as React from 'react';

import type { StatePaginationProps } from './types';
import type { PaginationSearchParams } from 'lib/dataLoader/pagination/types';

import { type UserFilterSegment } from 'models';

import { getDisplayName } from 'helpers/hoc';

import { DEFAULT_PAGINATION } from './paginationParams';

type State<T: {}> = {|
  paginationSearchParams: ?PaginationSearchParams,
  pageDependencies: ?($Values<T>[]),
|};

type FactoryArgs<T: {}> = {|
  defaultPaginationParams?: (props: T) => ?$Shape<PaginationSearchParams>,
  resetPageFor?: (props: T) => $Values<T>[],
|};

export default function withStatePagination<T: {}>({
  defaultPaginationParams: defaultPaginationParamsFromProps,
  resetPageFor,
}: FactoryArgs<T>) {
  function hoc(WrappedComponent: React.ComponentType<*>) {
    class StatePaginator extends React.Component<any, State<T>> {
      state = {
        paginationSearchParams: null,
        pageDependencies: resetPageFor && resetPageFor(this.props),
      };

      componentDidMount() {
        const paginationParamsFromProps =
          defaultPaginationParamsFromProps || ((props: T) => {});
        const paginationSearchParams = merge(
          {},
          DEFAULT_PAGINATION,
          paginationParamsFromProps(this.props)
        );
        if (
          paginationSearchParams.filter.reviewee ||
          paginationSearchParams.filter.reviewer
        )
          delete paginationSearchParams.filter.all;

        this.setState({ paginationSearchParams });
      }

      componentDidUpdate() {
        const { paginationSearchParams } = this.state;

        if (
          resetPageFor &&
          paginationSearchParams &&
          this.state.pageDependencies
        ) {
          const prevDeps = this.state.pageDependencies;
          const newDeps = resetPageFor(this.props);

          if (prevDeps.some((dep, i) => dep !== newDeps[i])) {
            this.setState({
              pageDependencies: newDeps,
              paginationSearchParams: {
                ...paginationSearchParams,
                page: 1,
              },
            });
          }
        }
      }

      updatePaginationParams(
        partialPaginationParams: $Shape<PaginationSearchParams>
      ) {
        const { paginationSearchParams } = this.state;

        this.setState({
          paginationSearchParams: {
            ...paginationSearchParams,
            ...partialPaginationParams,
          },
        });
      }

      onSearchChange = (search: string) => {
        this.updatePaginationParams({
          search,
          page: 1,
        });
      };

      onSortChange = (sortName: string) => {
        const currentSortValue =
          this.state.paginationSearchParams?.sort[sortName];

        const defaultSortParam = defaultPaginationParamsFromProps.sort;
        const defaultIsDesc =
          defaultSortParam && defaultSortParam[sortName] === 'desc';

        // if default sort is desc, then the only other possible sort value is asc
        if (defaultIsDesc && currentSortValue !== 'asc') {
          return this.updatePaginationParams({ sort: { [sortName]: 'asc' } });
        }

        switch (currentSortValue) {
          case null || undefined:
            return this.updatePaginationParams({ sort: { [sortName]: 'asc' } });
          case 'asc':
            return this.updatePaginationParams({
              sort: { [sortName]: 'desc' },
            });
          case 'desc':
            return this.updatePaginationParams({ sort: {} });
          default:
            return this.updatePaginationParams({ sort: {} });
        }
      };

      mergeFilters = (currentFilters: {} = {}, newFilter: {}) => {
        const nestedFilters = {};
        for (let key in currentFilters) {
          if (typeof currentFilters[key] === 'object')
            nestedFilters[key] = currentFilters[key];
        }
        return { ...nestedFilters, ...newFilter };
      };

      onFilterChange = (newFilter: {}) => {
        this.updatePaginationParams({
          filter: this.mergeFilters(
            this.state.paginationSearchParams?.filter,
            newFilter
          ),
          page: 1,
        });
      };

      onUserFilterChange = (userFilter: ?UserFilterSegment) => {
        this.updatePaginationParams({
          userFilter,
          page: 1,
        });
      };

      currentPage = () => {
        const { paginationSearchParams } = this.state;

        if (!paginationSearchParams) return null;

        return paginationSearchParams.page;
      };

      getPreviousPage = () => {
        const page = this.currentPage();

        if (page === null) return;

        this.updatePaginationParams({
          page: page - 1,
        });
      };

      getNextPage = () => {
        const page = this.currentPage();

        if (page === null) return;

        this.updatePaginationParams({
          page: page + 1,
        });
      };

      render() {
        const { paginationSearchParams } = this.state;

        if (paginationSearchParams === null) return null;

        const paginationProps: StatePaginationProps = ({
          getNextPage: this.getNextPage,
          getPreviousPage: this.getPreviousPage,
          onSearchChange: this.onSearchChange,
          onFilterChange: this.onFilterChange,
          onSortChange: this.onSortChange,
          onUserFilterChange: this.onUserFilterChange,
          ...paginationSearchParams,
        }: any);

        return <WrappedComponent {...paginationProps} {...this.props} />;
      }
    }

    StatePaginator.displayName = `withStatePagination(${getDisplayName(
      WrappedComponent
    )})`;

    return StatePaginator;
  }

  return hoc;
}
