import React from 'react';

import {
  Button,
  Divider,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Icon,
  Text,
} from 'components';

import {
  StepContentStyleKey,
  mapStepContentStyles,
} from 'scenes/surveys/Campaign/helpers/steps';

type Props = {
  onClick: (questionType: StepContentStyleKey) => void;
};

const AddStepButton = ({ onClick }: Props) => {
  return (
    <Divider color="primary" style={{ alignItems: 'center' }}>
      <Dropdown align="right" style={{ display: 'block' }}>
        <DropdownTrigger testClassName="test-survey-edition-add-step-button">
          <Button color="secondary" isRounded hasIconOnly>
            <Icon name="add" />
          </Button>
        </DropdownTrigger>
        <DropdownMenu>
          {mapStepContentStyles((stepContentType, stepContentStyle) => {
            const { iconName, label } = stepContentStyle;
            return (
              <DropdownMenuItem
                key={stepContentType}
                additionalClassName="text-text-main"
                onClick={() => onClick(stepContentType)}
              >
                <Icon name={iconName} additionalClassName="mr-2" />
                <Text
                  preset="14s6"
                  testClassName={`test-survey-edition-add-${stepContentType}-step`}
                >
                  {label}
                </Text>
              </DropdownMenuItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </Divider>
  );
};

export default AddStepButton;
