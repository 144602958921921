import React, { useState } from 'react';

import { useAppDispatch } from 'helpers/hooks';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { del, get, put } from 'redux/actions/api';

import { Button, Can, DirectUploader, Label, Text } from 'components';
import ImageUploader from 'components/ImageUploader';

import BackgroundImageColorPicker from './components/BackgroundImageColorPicker';
import Banner from './components/Banner';

const HomeBanner = () => {
  const dispatch = useAppDispatch();
  const organization = useOrganization();
  const defaultBackgroundColor = organization.theme.bannerBackgroundColor;
  const [selectedColor, setSelectedColor] = useState(defaultBackgroundColor);

  const onUploadSuccess = async () => {
    if (selectedColor !== defaultBackgroundColor) {
      return dispatch(
        put(`organization_themes/${organization.theme.id}`, {
          organizationThemes: {
            bannerBackgroundColor: selectedColor,
          },
        })
      );
    } else {
      return dispatch(get(`organization_themes/${organization.theme.id}`));
    }
  };

  const onDeleteBanner = () =>
    dispatch(del(`organization_themes/${organization.theme.id}/home_banner`));

  const imageUploaderActions = (
    _errors: unknown,
    onImageUpload: () => void
  ) => {
    return (
      <div className="banner-actions">
        {!!organization.theme.homeBannerUrl && (
          <Button color="danger" onClick={onDeleteBanner}>
            {__('Remove banner')}
          </Button>
        )}
        <Button onClick={onImageUpload}>{__('Edit')}</Button>
      </div>
    );
  };

  return (
    <Banner
      imgSrc={organization.theme.homeBannerUrl}
      defaultBackgroundColor={organization.theme.bannerBackgroundColor}
    >
      <Can perform="update" on={organization.theme}>
        <DirectUploader
          resourcePath={`organization_themes/${organization.theme.id}`}
          method="PUT"
          attribute="home_banner"
          model="organization_themes"
          onSuccess={onUploadSuccess}
          render={({ uploadFromDataURL }) => (
            <ImageUploader
              onUpload={uploadFromDataURL}
              onDelete={onDeleteBanner}
              cropShape="rect"
              cropBackgroundColor={selectedColor}
              cropShapeAspect={6}
              minZoom={0.2}
              modalTitle={__('Configuration of the banner')}
              uploadErrorMessage={__(
                'The banner must be in .png .jpeg or .gif format with a maximum size of 5Mo.'
              )}
              imageUploaderRender={imageUploaderActions}
            >
              <BackgroundImageColorPicker
                color={selectedColor}
                onChange={value => setSelectedColor(value)}
              />
              <div className="mb-4">
                <Label>{__('Import a banner')}</Label>
                <Text style={{ whiteSpace: 'pre-wrap' }} preset="13s7">
                  {__(
                    'For an optimal quality the banner must respect the following dimensions: 2384 x 400 pixels.\n' +
                      'Accepted formats: .png .jpeg or .gif. 5 Mo maximum.'
                  )}
                </Text>
              </div>
            </ImageUploader>
          )}
        />
      </Can>
    </Banner>
  );
};

export default HomeBanner;
