const sentSvg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="80" height="80" viewBox="0 0 80 80">
  <defs>
    <clipPath id="clip-path">
      <circle id="Ellipse_248" data-name="Ellipse 248" cx="39.707" cy="39.707" r="39.707" fill="none"/>
    </clipPath>
    <clipPath id="clip-Sent">
      <rect width="80" height="80"/>
    </clipPath>
  </defs>
  <g id="Sent" clip-path="url(#clip-Sent)">
    <rect width="80" height="80" fill="#fff"/>
    <g id="Group_632" data-name="Group 632" transform="translate(0.363)">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="39.707" cy="39.707" r="39.707" transform="translate(-12.558 60.909) rotate(-66.704)" fill="rgba(76,167,251,0.2)"/>
      <g id="Group_1" data-name="Group 1" transform="translate(-0.092 0.435)">
        <g id="Group_630" data-name="Group 630">
          <g id="Group_629" data-name="Group 629" clip-path="url(#clip-path)">
            <path id="Path_1707" data-name="Path 1707" d="M68.238,186.648c-24.7-5-17.629-36.447-.591-29.758a4.069,4.069,0,0,1,2.407,2.9,4.389,4.389,0,0,1-.814,3.666,6.164,6.164,0,0,1-7.3,1.731c-11.229-4.809-1.993-25.573,12.4-26.163" transform="translate(-40.089 -104.78)" fill="none" stroke="#4ca7fb" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" stroke-dasharray="2 3"/>
          </g>
        </g>
        <g id="Group_631" data-name="Group 631" transform="translate(30.773 21.284)">
          <path id="Path_1708" data-name="Path 1708" d="M71.285,127.829a1.116,1.116,0,0,1,.919-1.857l36.719,2.915a1.116,1.116,0,0,1,.554,2.025l-30.11,21.216A1.116,1.116,0,0,1,77.63,151l2.511-12.623a1.116,1.116,0,0,0-.264-.963Z" transform="translate(-71 -125.968)" fill="#fff" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
          <path id="Path_1709" data-name="Path 1709" d="M80.19,138.4l-2.511,12.623a1.117,1.117,0,0,0,1.738,1.131l30.109-21.217a1.115,1.115,0,0,0,.434-1.206L80.171,137.89A1.119,1.119,0,0,1,80.19,138.4Z" transform="translate(-71.049 -125.996)" fill="#daedf7" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        </g>
      </g>
    </g>
  </g>
</svg>
`;

export default sentSvg;
