import React, { Fragment, useState } from 'react';

import { __ } from 'helpers/i18n';

import { Button, ContentContainer, Flex, Icon, Text } from 'components';

import MatrixPaginatedList from '../../components/MatrixPaginatedList';
import Header from '../../components/SkillsHeader';
import CreationModal from './CreationModal';

export default function MatrixList() {
  const [createMatrixModalActive, setCreateMatrixModalActive] = useState(false);

  return (
    <Fragment>
      <Header />
      <ContentContainer>
        <Text>
          <p>
            {__(
              'Skills matrices can be viewed by all your employees. They can be used during a review to evaluate your employees on each skill, depending on their level.'
            )}
          </p>
          <p>
            {__(
              'To know more about skills matrices creation, you can consult %1.',
              <a href="https://docs.elevo.fr/">
                {__('our help center article')}
              </a>
            )}
          </p>
        </Text>

        <Flex
          style={{
            justifyContent: 'flex-end',
            marginBottom: 16,
            marginTop: 16,
          }}
        >
          <Button
            onClick={() => setCreateMatrixModalActive(true)}
            color="primary"
          >
            <Icon style={{ marginRight: 8 }} name="add" />
            {__('Create a skills matrix')}
          </Button>
        </Flex>

        <MatrixPaginatedList
          paginationType="url"
          emptyStateProps={{
            iconName: 'table_chart',
            title: __('No skills matrix has been created yet.'),
            description: __(
              'Create your first skills matrix by clicking on the "%1" button',
              __('Create a skills matrix')
            ),
          }}
        />

        {createMatrixModalActive && (
          <CreationModal onClose={() => setCreateMatrixModalActive(false)} />
        )}
      </ContentContainer>
    </Fragment>
  );
}
