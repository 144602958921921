import { sortBy } from 'lodash';
import React from 'react';

import type {
  OneOnOneReviewCycle,
  OneOnOneUserReview,
  ThreeSixtyReviewCycle,
  ThreeSixtyUserReview,
  UserReview,
} from 'models';

import store from 'config/store';

import classNames from 'helpers/classNames';
import { useAppDispatch } from 'helpers/hooks';
import { useShowMore } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import { wait } from 'helpers/time';

import { hydrateFromResponse, hydrateFromStore } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { BoxFooter, Icon } from 'components';

import OneOnOneUserReviewItem from '../components/UserReviewList/OneOnOneUserReviewListItem';
import ThreeSixtyUserReviewItem from '../components/UserReviewList/ThreeSixtyUserReviewListItem';

type Props = {
  reviewCycle: ThreeSixtyReviewCycle | OneOnOneReviewCycle;
  initialQueryMsDelay: number;
  reloadList: () => Promise<void>;
  endpoint: 'my_team_user_reviews' | 'other_user_reviews';
};

const userReviewHydration = {
  abilities: {},
  reviewer: {},
  user: {},
  evaluations: {
    reviewer: {},
  },
  additionalReviewer: {},
  meeting: {
    participants: {},
  },
};

const UserReviewsPaginatedList = ({
  reviewCycle,
  initialQueryMsDelay,
  reloadList,
  endpoint,
}: Props) => {
  const dispatch = useAppDispatch();
  const loadUserReviews = async (page: number) => {
    const { response } = await dispatch(
      get(`/review_cycles/${reviewCycle.id}/${endpoint}`, { page })
    );

    const hydrate = {
      userReviewCollection: {
        userReviews: userReviewHydration,
      },
      totalRecordCount: {},
    };

    return hydrateFromResponse(
      store.getState().data,
      response.body,
      hydrate,
      response.body.data.id
    );
  };

  React.useEffect(() => {
    wait(initialQueryMsDelay).then(() => {
      fetchUserReviews(paginationInfo.page);
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isFetching, paginationInfo, userReviewIds, fetchUserReviews] =
    useShowMore({
      loadItems: loadUserReviews,
      collectionItemName: 'userReviews',
    });

  const userReviews = sortBy(
    hydrateFromStore(
      store.getState().data,
      {
        resourceType: 'userReview',
        filter: (item: UserReview) => userReviewIds.includes(item.id),
      },
      {
        userReview: userReviewHydration,
      }
    ) as Array<UserReview>,
    'user.fullName'
  );

  return (
    <React.Fragment>
      {userReviews.map(userReview => (
        <div
          key={userReview.id}
          className={classNames(
            'test-user-review-manager-item',
            'test-user-review-manager-item-' + userReview.id
          )}
        >
          {reviewCycle.interactionType === '360' && (
            <ThreeSixtyUserReviewItem
              userReview={userReview as ThreeSixtyUserReview}
              reviewCycle={reviewCycle}
              onAfterDelegate={reloadList}
            />
          )}
          {reviewCycle.interactionType === 'one_on_one' && (
            <OneOnOneUserReviewItem
              userReview={userReview as OneOnOneUserReview}
              reviewCycle={reviewCycle}
              onAfterDelegate={reloadList}
            />
          )}
        </div>
      ))}

      {paginationInfo.hasNext && !isFetching && (
        <div style={{ position: 'relative' }}>
          <BoxFooter
            onClick={() => {
              fetchUserReviews(paginationInfo.page + 1);
            }}
            pagination={n__(
              '%1/%2 assessment',
              '%1/%2 assessments',
              userReviews.length,
              paginationInfo.totalRecordCount
            )}
          >
            <Icon
              name="keyboard_arrow_down"
              size="tiny"
              style={{ verticalAlign: 'text-bottom' }}
            />

            {__(
              'Show %1 more',
              Math.min(paginationInfo.totalRecordCount - userReviews.length, 10)
            )}
          </BoxFooter>
        </div>
      )}
    </React.Fragment>
  );
};

export default UserReviewsPaginatedList;
