import React from 'react';

import type { ReviewTemplateWithDuplicateAction } from 'models';

import { usePollingWithAsyncAction } from 'helpers/hooks/usePollingWithAsyncAction';
import { __, date } from 'helpers/i18n';
import { pathToReviewTemplate } from 'helpers/paths';

import {
  BoxListItem,
  Column,
  Columns,
  HamburgerMenu,
  Link,
  Loading,
  MenuItem,
  MenuList,
  Text,
} from 'components';

type Props = {
  reviewTemplate: ReviewTemplateWithDuplicateAction;
  duplicate: (templateId: string) => Promise<void>;
  delete: (templateId: string) => Promise<void>;
  refetchData: () => Promise<void>;
};

export default function TemplateItem({
  duplicate,
  delete: deleteTemplate,
  reviewTemplate,
  refetchData,
}: Props) {
  const {
    actionIsOngoing: duplicateActionIsOngoing,
    pollerComponent: duplicatePollerComponent,
  } = usePollingWithAsyncAction(
    reviewTemplate.lastPendingDuplicateAction,
    refetchData
  );

  return (
    <BoxListItem>
      {duplicatePollerComponent}
      <Columns contentVerticallyAligned>
        <Column>
          <Link to={pathToReviewTemplate(reviewTemplate.id)}>
            {reviewTemplate.name}
          </Link>
        </Column>
        {duplicateActionIsOngoing && (
          <Column isNarrow>
            <Loading size="tiny" />
          </Column>
        )}
        <Column isNarrow>
          <Text color="info">{__('Updated on:')}</Text>{' '}
          {date(reviewTemplate.updatedAt)}
        </Column>

        <Column isNarrow>
          <HamburgerMenu align="right">
            <MenuList>
              <MenuItem
                key="copy"
                onClick={() => duplicate(reviewTemplate.id)}
                disabled={duplicateActionIsOngoing}
              >
                {__('Make a copy')}
              </MenuItem>
              <MenuItem
                key="delete"
                onClick={() => deleteTemplate(reviewTemplate.id)}
              >
                <Text color="danger">{__('Delete')}</Text>
              </MenuItem>
            </MenuList>
          </HamburgerMenu>
        </Column>
      </Columns>
    </BoxListItem>
  );
}
