import React from 'react';

import type { TitleBlock } from 'models';

import { __ } from 'helpers/i18n';

import BlockDetails from 'scenes/components/review/BlockDetails';

type Props = {
  block: TitleBlock;
  withoutSectionSeparator?: boolean;
};

const TitleBlockResults = ({ block, withoutSectionSeparator }: Props) => {
  const { id, content } = block;
  const { title, description, richTextEnabled } = content;

  return (
    <BlockDetails
      id={id}
      section={__('Questions')}
      title={title}
      description={description}
      withoutSectionSeparator={withoutSectionSeparator}
      withRichText={richTextEnabled}
    />
  );
};

export default TitleBlockResults;
