import { omit } from 'lodash';
import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import type { GraphFormattedTimeSeriesDataPoint } from 'models';
import type { CurveType } from 'recharts/types/shape/Curve';

import { ChartTooltip } from 'components';

type LineChartData = GraphFormattedTimeSeriesDataPoint[];
type LineMetadata = {
  dataKey: string;
  name: string;
  color: string;
  curveType: CurveType;
};
type Props = { data: LineChartData; linesMetadata: LineMetadata[] };

const CustomLineChart = ({ data, linesMetadata }: Props) => {
  const availableSeriesNames = Object.keys(omit(data[0], 'date'));
  const availableLines = linesMetadata.filter(line =>
    availableSeriesNames.includes(line.dataKey)
  );

  return (
    <ResponsiveContainer height={200} width="100%">
      <LineChart data={data} margin={{ top: 20, left: -20, right: -15 }}>
        <CartesianGrid strokeDasharray="2 2" vertical={false} />
        <XAxis
          dataKey="date"
          tick={{ fontSize: 'small' }}
          padding={{ left: 30, right: 50 }}
        />
        <YAxis
          interval={0}
          tickLine={false}
          tick={{ fontSize: 'small' }}
          domain={[-100, 100]}
        />
        <Tooltip
          content={({ active, payload, label }) => {
            if (!active) return null;

            return (
              <ChartTooltip
                title={label}
                values={availableLines.map((line, index) => {
                  return {
                    color: line.color,
                    label: line.name,
                    // @ts-ignore TSFIXME: Fix strictNullChecks error
                    value: payload[index] ? payload[index].value : null,
                  };
                })}
              />
            );
          }}
        />

        {availableLines.map(line => (
          <Line
            type={line.curveType}
            dot
            dataKey={line.dataKey}
            name={line.name}
            stroke={line.color}
            strokeWidth={3}
            key={line.dataKey}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomLineChart;
