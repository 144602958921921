import type { AbilitiesBearer, AbilityId } from 'models';

type Props = {
  perform: AbilityId;
  on: AbilitiesBearer;
};

const can = ({ perform, on }: Props): boolean => {
  const { abilityIds } = on;

  if (!abilityIds) {
    throw new Error(
      // @ts-expect-error: `type` is on all resources, but is not typed in front to avoid using it in business code
      `No ability found checking for ${perform} on ${on.type}#${on.id}`
    );
  }

  // @ts-ignore: TSFIXME AbilitiesBearer is wrongly typed
  return abilityIds.includes(perform);
};

export default can;
