// @flow
import * as React from 'react';

import type { ContextRouter } from 'react-router-dom';

import { __ } from 'helpers/i18n';

import { Content, Icon, Link, SupportLink, Title } from 'components';

export default function ServerErrorPage(props: ContextRouter) {
  return (
    <div className="server-error-page">
      <figure className="image is-5by4" style={{ maxWidth: 250 }}>
        <img src="broken-robot-400x315.png" alt="broken robot" />
      </figure>

      <Content size="medium">
        <Title size={3} additionalClassName="title">
          {__('Snap! A little grain of sand stuck in the gears…')}
        </Title>

        <p>
          {__(
            'Elevo has encountered a problem and is not responding correctly. ' +
              'This should not last long though, we automatically track those errors and should already be working on it!'
          )}
        </p>

        <p>{__('We suggest that you:')}</p>

        <ol>
          <li>{__('Wait a few minutes')}</li>
          <li>
            {__(
              'Navigate back to %1',
              <Link to="/">
                <Icon style={{ verticalAlign: 'sub' }} name="home" />{' '}
                {__('the home page')}
              </Link>
            )}
          </li>
          <li>
            {__(
              '%1 if the problem persists',
              <SupportLink>{__('Contact us')}</SupportLink>
            )}
          </li>
        </ol>
      </Content>
    </div>
  );
}
