import React, { CSSProperties } from 'react';

import colors from 'styles/colors';

import { __ } from 'helpers/i18n';

import { CircularProgress, Icon, Testable } from 'components';
import DesignSystem from 'components/DesignSystem';
import Text from 'components/text/Text';
import { SavingStatusConnector } from 'components/withSavingStatus';

type FailedProps = {
  failedText: string;
};

function Failed({ failedText }: FailedProps) {
  return (
    <DesignSystem
      render={version => {
        if (version === 1) {
          return (
            <React.Fragment>
              <Icon
                color={colors.redFuckup}
                style={{ verticalAlign: 'middle' }}
                name="warning"
              />
              <Testable name="test-failed-label">
                <span
                  style={{ verticalAlign: 'middle', color: colors.redFuckup }}
                >
                  {failedText}
                </span>
              </Testable>
            </React.Fragment>
          );
        }

        return (
          <Text testClassName="test-failed-label" size={7} color="danger">
            <Icon
              style={{ fontSize: '1.23rem', margin: '2px 2px 0 0' }}
              name="warning"
            />{' '}
            {failedText}
          </Text>
        );
      }}
    />
  );
}

function Saved() {
  return (
    <DesignSystem
      render={version => {
        if (version === 1) {
          return (
            <React.Fragment>
              <Icon
                color={colors.greenSuccess}
                style={{ verticalAlign: 'middle' }}
                name="check"
              />
              <Testable name="test-saved-label">
                <span
                  style={{
                    verticalAlign: 'middle',
                    color: colors.greenSuccess,
                  }}
                >
                  {__('All changes saved')}
                </span>
              </Testable>
            </React.Fragment>
          );
        }

        return (
          <Text testClassName="test-saved-label" size={7} color="success">
            <Icon style={{ margin: '0 2px 2px 0' }} name="check_circle" />{' '}
            {__('All changes saved')}
          </Text>
        );
      }}
    />
  );
}

function Saving() {
  return (
    <DesignSystem
      render={version => {
        if (version === 1) {
          return (
            <React.Fragment>
              <CircularProgress
                size="small"
                style={{
                  marginBottom: 2,
                  marginRight: 4,
                  verticalAlign: 'middle',
                }}
              />
              <Testable name="test-saving-label">
                <span
                  style={{ verticalAlign: 'middle', color: colors.mediumGrey }}
                >
                  {__('Saving...')}
                </span>
              </Testable>
            </React.Fragment>
          );
        }

        return (
          <Text testClassName="test-saving-label" size={7} color="info">
            <CircularProgress
              size="tiny"
              borderWidth={1}
              style={{ marginRight: 8, verticalAlign: 'middle' }}
            />
            {__('Saving…')}
          </Text>
        );
      }}
    />
  );
}

type Props = {
  failedText: (failedFieldsCount: number) => string;
  style?: CSSProperties;
};

export default function SavingStatusLabel(props: Props) {
  const { failedText, style } = props;

  return (
    <div style={style}>
      <Testable name="test-save-label">
        <SavingStatusConnector
          debounceDelay={500}
          render={({ autoSaveStatus, failedFieldsCount }) => {
            switch (autoSaveStatus) {
              case 'saving':
              case 'retrying':
                return <Saving />;
              case 'saved':
                return <Saved />;
              case 'failed':
                return (
                  <Failed
                    failedText={
                      !!failedFieldsCount
                        ? failedText(failedFieldsCount)
                        : failedText(1)
                    }
                  />
                );
              default:
                throw new Error(
                  `Unhandled autoSaveStatus ${JSON.stringify(autoSaveStatus)}`
                );
            }
          }}
        />
      </Testable>
    </div>
  );
}
