import React from 'react';

import type { UpdateReviewCycle } from '../..';
import type { ReviewCycle } from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { defaultNominatePeersInstruction } from 'helpers/models/reviewCycle';

import { Control, Debouncer, Textarea } from 'components';

import OptionWrapper from './OptionWrapper';

type Props = {
  reviewCycle: ReviewCycle;
  updateReviewCycle: UpdateReviewCycle;
};

export default function PeerOptions({ updateReviewCycle, reviewCycle }: Props) {
  invariant(
    reviewCycle.interactionType === '360',
    'Peer nomination instruction options should appear only when review cycle is 360'
  );

  return (
    <OptionWrapper
      title={__('Peers nomination instructions')}
      subtitle={__(
        'This text will be displayed to the participants when they will select their peers for their 360°. You can customize it here or keep the default one.'
      )}
      additionalClassName="peers-option"
    >
      <Debouncer
        value={reviewCycle.overrideNominatePeersInstruction}
        onChange={value =>
          updateReviewCycle({ overrideNominatePeersInstruction: value })
        }
        render={(value, debouncing, onChange) => (
          <Control isLoading={debouncing}>
            <Textarea
              value={value}
              onChange={onChange}
              maxLength={500}
              rows={3}
              placeholder={defaultNominatePeersInstruction()}
            />
          </Control>
        )}
      />
    </OptionWrapper>
  );
}
