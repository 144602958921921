import React, { Fragment } from 'react';

import type { SkillsArea } from 'models';

import { __ } from 'helpers/i18n';
import { type Match } from 'helpers/paths';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { ContentContainer } from 'components';

import MatrixPaginatedList from '../../components/MatrixPaginatedList';
import Header from '../../components/SkillsAreaHeader';

type Props = {
  match: Match<{ id: string }>;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    area: SkillsArea;
  };

function AreaMatrixList({ match, area, isFetching }: AfterDataLoaderProps) {
  return (
    <Fragment>
      <Header areaId={match.params.id} />
      <ContentContainer>
        {!isFetching && (
          <MatrixPaginatedList
            areaId={area.id}
            paginationType="url"
            emptyStateProps={{
              iconName: 'table_chart',
              title: __(
                'No skills matrix has been associated to this skill yet.'
              ),
            }}
          />
        )}
      </ContentContainer>
    </Fragment>
  );
}

export default newDataLoader({
  fetch: ({ match }: Props) => get(`/skills/areas/${match.params.id}`),
  hydrate: { area: { expectations: {} } },
})(AreaMatrixList);
