import { useState } from 'react';

import { PeopleUserReviewPlannedAction } from 'models/PlannedAction';

import { handleFormErrors } from 'helpers/api';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { put } from 'redux/actions/api';

const useUpdatePlannedAction = (
  plannedAction: PeopleUserReviewPlannedAction,
  afterUpdate: () => any
): {
  handleSubmit: (
    editedPlannedAction: Partial<PeopleUserReviewPlannedAction>
  ) => Promise<void>;
  errors: Partial<PeopleUserReviewPlannedAction>;
} => {
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<Partial<PeopleUserReviewPlannedAction>>(
    {}
  );
  const handleSubmit = (
    editedPlannedAction: Partial<PeopleUserReviewPlannedAction>
  ) =>
    handleFormErrors(async () => {
      const { tags, ...attributes } = editedPlannedAction;
      const tagLabels = !!tags ? tags.map(tag => tag.label) : undefined;
      await dispatch(
        put(
          `planned/actions/${plannedAction.id}`,
          { ...attributes, tagLabels },
          {
            successMessage: __(
              '<b>Action for %1 modified</b></br>The action has been successfully modified',
              plannedAction.user.fullName
            ),
          }
        )
      );
      afterUpdate();
    }, setErrors);

  return { handleSubmit, errors };
};

export default useUpdatePlannedAction;
