import React from 'react';

import type { OverallProgressSeries } from 'models';

import colors from 'styles/colors';

import { __ } from 'helpers/i18n';
import { zipSeries } from 'helpers/timeSeries';

import ProgressChartBlock from '../components/ProgressChartBlock';

type Props = {
  series: OverallProgressSeries;
};

export default function OverallProgressChart({ series }: Props) {
  const { data, annotations } = zipSeries(series);

  const lines = [
    {
      dataKey: 'selfEvaluationSharedProgress',
      name: __('Self evaluation shared'),
      color: colors.lineChart.color1,
    },
    {
      dataKey: 'reviewerEvaluationSharedProgress',
      name: __('Reviewer shared'),
      color: colors.lineChart.color2,
    },
  ];

  if (series.selfEvaluationSignedProgress) {
    lines.push({
      dataKey: 'selfEvaluationSignedProgress',
      name: __('Self evaluation signed'),
      color: colors.lineChart.color3,
    });
  }

  if (series.reviewerEvaluationSignedProgress) {
    lines.push({
      dataKey: 'reviewerEvaluationSignedProgress',
      name: __('Reviewer signed'),
      color: colors.lineChart.color4,
    });
  }

  return (
    <ProgressChartBlock
      title={__('Overall progression')}
      data={data}
      ordinateParams={{
        domain: [0, 100],
        ticks: [0, 20, 40, 60, 80, 100],
        unit: '%',
      }}
      annotations={annotations}
      lines={lines}
    />
  );
}
