import { isEmpty } from 'lodash';
import React from 'react';

import type { Entity } from 'models';
import type { ReactNode } from 'react';

import { __ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Select } from 'components';

type Props = {
  selectedEntityId: string | undefined | null;
  onChange: (entityId: string | null | undefined) => void;
  placeholder?: string;
  isClearable?: boolean;
  emptyStateRender?: () => ReactNode;
};

type AfterDataLoaderProps = {
  entities: Array<Entity>;
} & Props &
  DataLoaderProvidedProps;

type Option = {
  label: string;
  value: string;
};

const entityToOption = ({ id, name }: Entity): Option => {
  return {
    label: name,
    value: id,
  };
};

const EntityPicker = ({
  selectedEntityId,
  isClearable,
  isFetching,
  hasError,
  entities,
  placeholder,
  onChange,
  emptyStateRender,
}: AfterDataLoaderProps) => {
  if (isFetching) {
    entities = [];
  }

  if (!isFetching && !hasError && isEmpty(entities) && !!emptyStateRender)
    return emptyStateRender();

  const selectedEntity = entities.find(
    entity => entity.id === selectedEntityId
  );

  return (
    <Select
      options={entities.map(entity => entityToOption(entity))}
      value={!!selectedEntity ? entityToOption(selectedEntity) : null}
      onChange={option =>
        !Array.isArray(option) && onChange(!!option ? option.value : null)
      }
      placeholder={placeholder || __('Select an administration entity')}
      noOptionsMessage={__('No entities have been created')}
      isClearable={isClearable}
      isDisabled={hasError}
      isLoading={isFetching}
    />
  );
};

export default newDataLoader({
  fetch: () => get('entities'),
  hydrate: { entities: {} },
})(EntityPicker) as React.ComponentType<Props>;
