import React from 'react';

import { PeopleReviewCycleShow } from 'models';

import { FormErrors, FormInputChangeHandler } from 'helpers/hooks/useForm';
import { __ } from 'helpers/i18n';

import {
  Divider,
  Field,
  FieldError,
  Input,
  Label,
  ModalCardBody,
  Text,
  Textarea,
} from 'components';

import PeopleGroupModalRules from './PeopleGroupModalRules';
import { PeopleGroupData } from './types';

type Props = {
  peopleGroup: PeopleGroupData;
  peopleReviewCycle: PeopleReviewCycleShow;
  errors: FormErrors;
  handleInputChange: FormInputChangeHandler<PeopleGroupData>;
};

const PeopleGroupModalBody = ({
  peopleGroup,
  peopleReviewCycle,
  errors,
  handleInputChange,
}: Props) => {
  return (
    <ModalCardBody>
      <Field>
        <Label>{__('Group title')}</Label>
        <Input
          type="text"
          value={peopleGroup.title}
          onChange={(title: string) => {
            handleInputChange({ value: title, name: 'title' });
          }}
        />
        <FieldError>{errors.title}</FieldError>
      </Field>
      <Field>
        <Label>{__('Group description')}</Label>
        <Textarea
          value={peopleGroup.description}
          richText
          richTextOptions={{
            toolbarAlwaysVisible: true,
          }}
          onChange={(description: string) => {
            handleInputChange({ value: description, name: 'description' });
          }}
        />
        <FieldError>{errors.description}</FieldError>
      </Field>

      <Divider additionalClassName="my-4" />

      <Label>{__('Group rules')}</Label>
      <Text preset="13s7">
        {__(
          'Employees whose people review assessment corresponds to the rules below will be added to this group.'
        )}
      </Text>

      <PeopleGroupModalRules
        peopleGroupRules={peopleGroup.peopleGroupRules}
        peopleReviewDimensions={peopleReviewCycle.peopleReviewDimensions}
        handleInputChange={handleInputChange}
      />
    </ModalCardBody>
  );
};

export default PeopleGroupModalBody;
