import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';

import type { Integration, UsersAttributeOverride } from 'models';

import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { ConfirmationModal, StrictlySanitizedHtml } from 'components';

type Props = {
  integration: Integration | undefined | null;
  onCancel: () => void;
  onConfirm: () => void;
};

type AfterDataLoaderProps = Props & {
  attributeOverrides: Array<UsersAttributeOverride>;
  isFetching: boolean;
  hasError: boolean;
};

function IntegrationConfirmationModal({
  attributeOverrides,
  onCancel,
  onConfirm,
  integration,
}: AfterDataLoaderProps) {
  const hasInactiveOverrides =
    !integration?.active && !isEmpty(attributeOverrides);

  return (
    <ConfirmationModal
      isActive
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelLabel={__('Cancel')}
      confirmLabel={__('Launch synchronization')}
      title={__('Confirm the update of your users')}
      refreshContentOnOpening={false}
    >
      {!hasInactiveOverrides && (
        <Fragment>
          <p>{__('You are about to update the users of your platform.')}</p>
          <p>
            {__(
              'Are you sure you want to launch a new synchronization of your users?'
            )}
          </p>
        </Fragment>
      )}
      {hasInactiveOverrides && (
        <Fragment>
          <p>
            {__('You are about to update the users of your platform.')}
            <br />
            <StrictlySanitizedHtml
              componentType="span"
              html={__(
                'Please note that <b>there are users with fields for which the %1 synchronization has been disabled.</b>',
                integration?.integrationType
              )}
            />
          </p>
          <br />
          <p>
            <StrictlySanitizedHtml
              componentType="span"
              html={__(
                'Launching the synchronization <b>will reset the value of these fields to the HRIS one</b> and the <b>overrides will be disabled.</b>'
              )}
            />
          </p>
          <br />
          <p>
            {__('If you wish to keep these overrides, you can:')}
            <ul>
              <li>{__('- Activate the integration for this organization')}</li>
              <li>{__('- Launch the manual synchronization')}</li>
              <li>{__('- Deactivate the integration')}</li>
            </ul>
          </p>
          <br />
          <p>
            {__(
              'That way, we will ignore the values sent by the synchronization for the desynchronized fields.'
            )}
          </p>
          <br />
          <p>
            {__(
              'Are you sure you want to launch a new synchronization of your users?'
            )}
          </p>
        </Fragment>
      )}
    </ConfirmationModal>
  );
}

export default newDataLoader({
  fetch: () => get('attribute_overrides'),
  hydrate: {
    attributeOverrides: {},
  },
})(IntegrationConfirmationModal) as React.ComponentType<Props>;
