import React from 'react';

import { Link } from 'components';

type Props = {
  children: React.ReactNode;
};

export default function RefreshLink({ children }: Props) {
  return <Link onClick={() => window.location.reload()}>{children}</Link>;
}
