import React from 'react';

type Props = {
  render: (multiSelect: {
    onSelect: (ItemId: string) => void;
    updateSelection: (itemIds: Array<string>) => void;
    selection: () => Array<string>;
  }) => React.ReactNode;
  defaultSelectedItemIds?: Array<string>;
};

type State = {
  selectedItemIds: Array<string>;
};

export default class MultiSelectable extends React.Component<Props, State> {
  state = {
    selectedItemIds: this.props.defaultSelectedItemIds || [],
  };

  onSelect = (itemId: string) => {
    let selectedItemIds = this.state.selectedItemIds.slice();
    if (this.state.selectedItemIds.includes(itemId)) {
      selectedItemIds.splice(selectedItemIds.indexOf(itemId), 1);
    } else {
      selectedItemIds.push(itemId);
    }
    this.setState({ selectedItemIds: selectedItemIds });
  };

  updateSelection = (itemIds: Array<string>) =>
    this.setState({ selectedItemIds: itemIds });

  render() {
    return this.props.render({
      onSelect: this.onSelect,
      updateSelection: this.updateSelection,
      selection: () => this.state.selectedItemIds,
    });
  }
}
