import { DebouncedFunc, debounce } from 'lodash';
import React from 'react';

type DebounceOptions = {
  wait: number;
  maxWait?: number;
  leading?: boolean;
  trailing?: boolean;
};

type Props<T> = {
  value: T | undefined | null;
  onChange: (newValue: T | null | undefined) => void;
  render: (
    stateValue: T | undefined | null,
    debouncing: boolean,
    onChange: (newValue: T | null | undefined) => void
  ) => React.ReactNode;
  options: DebounceOptions;
};

type State<T> = {
  value: T | undefined | null;
  isDirty: boolean;
  debouncing: boolean;
};

type DebouncedOnChange<T> = DebouncedFunc<
  (value: T | null | undefined) => void
>;

export default class Debouncer<T> extends React.Component<Props<T>, State<T>> {
  _isMounted = true;

  static defaultProps = {
    // Default options in doc: https://lodash.com/docs/4.17.15#debounce
    options: {
      wait: 300,
      maxWait: 1000,
      leading: false,
      trailing: true,
    },
  };

  static getDerivedStateFromProps<T>(
    props: Props<T>,
    state: State<T>
  ): State<T> {
    if (!state.isDirty && state.value !== props.value) {
      return { ...state, value: props.value };
    }

    if (state.isDirty && state.value === props.value) {
      return { ...state, isDirty: false };
    }

    return state;
  }

  state: State<T> = {
    value: this.props.value,
    isDirty: false,
    debouncing: false,
  };

  onChangeOnDebounce = async (value: T | null | undefined) => {
    this.setState({ debouncing: true });

    try {
      // TSFIXME: 'await' has no effect on the type of this expression.
      await this.props.onChange(value);

      if (this._isMounted) this.setState({ debouncing: false });
    } finally {
      if (this._isMounted) this.setState({ debouncing: false });
    }
  };

  debouncedOnChange: DebouncedOnChange<T> = debounce(
    this.onChangeOnDebounce,
    this.props.options.wait,
    this.props.options
  );

  onChange = (value: T | null | undefined) => {
    this.setState({ isDirty: true, value });
    this.debouncedOnChange(value);
  };

  componentWillUnmount() {
    this.debouncedOnChange.flush();
    this._isMounted = false;
  }

  render() {
    return this.props.render(
      this.state.value,
      this.state.debouncing,
      this.onChange
    );
  }
}
