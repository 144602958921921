import { __ } from 'helpers/i18n';

type Props = {
  fieldLabel: string;
  userFullName: string;
  integrationName: string;
};

const generateLockedModalTexts = ({
  fieldLabel,
  userFullName,
  integrationName,
}: Props) => {
  return {
    title: __(
      'Disable the synchronization of the field %1 for %2',
      fieldLabel,
      userFullName
    ),
    body1: __(
      'This action will disable the %1 synchronization of the field %2 for %3. By performing this action, <b>you will be able to overwrite the value sent by your HRIS through the active integration.</b>',
      integrationName,
      fieldLabel,
      userFullName
    ),
    body2: __(
      'The future values sent by your HRIS through the active integration (%1) will be ignored as long as the field %2 is desynchronized.',
      integrationName,
      fieldLabel
    ),
    body3: __('You will be able to resychronize this field later.'),
    checkboxLabel: __(
      'I want to disable the synchronization of the field %1 for %2 in order to manage it manually.',
      fieldLabel,
      userFullName
    ),
    submitLabel: __('Disable'),
  };
};

export default generateLockedModalTexts;
