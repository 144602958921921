import Item, { ItemConfig, defaultConfig } from './Item';
import { targetFrameTime } from './config';

export interface ItemCanvasConfig extends ItemConfig {
  itemCount: number;
}

export class ItemCanvas {
  private lastUpdate = Date.now();
  private items: Item[] = [];
  private config: ItemCanvasConfig;
  private ctx: CanvasRenderingContext2D | null = null;
  private canvas: HTMLCanvasElement;

  constructor(canvas: HTMLCanvasElement, config: Partial<ItemCanvasConfig>) {
    this.canvas = canvas;
    this.config = { itemCount: 150, ...defaultConfig, ...config };
    this.items = Item.createItems(canvas, config.itemCount || 150, config);
    this.ctx = canvas.getContext('2d');
    this.play();
  }

  updateConfig(config: Partial<ItemCanvasConfig>) {
    this.config = { ...this.config, ...config };

    const sizeDifference = this.config.itemCount - this.items.length;

    if (sizeDifference > 0) {
      this.items = [
        ...this.items,
        ...Item.createItems(this.canvas, sizeDifference, config),
      ];
    }

    if (sizeDifference < 0) {
      this.items = this.items.slice(0, this.config.itemCount);
    }

    for (const item of this.items) {
      item.updateConfig(this.config);
    }
  }

  private render(framesPassed = 1) {
    const { ctx, canvas, items } = this;

    if (!ctx || !canvas) return;

    const { offsetWidth, offsetHeight } = canvas;

    for (const item of items) {
      item.update(offsetWidth, offsetHeight, framesPassed);
    }

    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, offsetWidth, offsetHeight);

    if (this.config.images && this.config.images.length > 0) {
      for (const item of items) {
        item.drawImage(ctx);
      }
      return;
    }

    ctx.beginPath();
    for (const item of items) {
      item.drawCircle(ctx);
    }
    ctx.fillStyle = this.config.color!;
    ctx.fill();
  }

  private animationFrame: number | undefined;

  private loop() {
    const now = Date.now();
    const msPassed = Date.now() - this.lastUpdate;
    this.lastUpdate = now;

    const framesPassed = msPassed / targetFrameTime;

    this.render(framesPassed);

    this.animationFrame = requestAnimationFrame(() => this.loop());
  }

  play() {
    this.loop();
  }

  pause() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
      this.animationFrame = undefined;
    }
  }
}

export default ItemCanvas;
