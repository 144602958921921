import DOMPurify from 'dompurify';
import React, { CSSProperties } from 'react';

type Props = {
  componentType?: 'div' | 'span' | 'p';
  componentProps?: Object;
  html: string | null | undefined;
  style?: CSSProperties;
};

const StrictlySanitizedHtml = ({
  componentType = 'span',
  componentProps,
  html,
  style,
}: Props) => {
  // Restricted sanitization to only safe tags
  const allowedTags = ['b', 'br', 'ul', 'li'];

  return React.createElement(componentType, {
    style,
    dangerouslySetInnerHTML: {
      __html: DOMPurify.sanitize(html || '', {
        ALLOWED_TAGS: allowedTags,
      }),
    },
    className: componentType === 'span' ? 'content' : '',
    ...componentProps,
  });
};

export default StrictlySanitizedHtml;
