import React from 'react';

import type { FeedbackProgressionSeries } from 'models';

import colors from 'styles/colors';

import { __ } from 'helpers/i18n';
import { zipSeries } from 'helpers/timeSeries';

import ProgressChartBlock from '../components/ProgressChartBlock';

type Props = {
  series: FeedbackProgressionSeries;
};

export default function FeedbackProgressionChart({ series }: Props) {
  const { data, annotations } = zipSeries(series);

  return (
    <ProgressChartBlock
      title={__('Peer feedback progression')}
      data={data}
      annotations={annotations}
      lines={[
        {
          dataKey: 'requestedFeedbackProgress',
          name: __('Requested peer feedback'),
          color: colors.lineChart.color1,
        },
        {
          dataKey: 'completedFeedbackProgress',
          name: __('Shared peer feedback'),
          color: colors.lineChart.color2,
        },
      ]}
    />
  );
}
