import React from 'react';

import type { User, UserObjectivePeriod } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { PeriodPicker as ObjectivePeriodPickerComponent } from 'components';

type Props = {
  user: User;
  userObjectivePeriod: UserObjectivePeriod | undefined | null;
  isDisabled?: boolean;
  onChange: (userObjectivePeriod: UserObjectivePeriod) => void;
};

type AfterDataLoaderProps = {
  userObjectivePeriods: Array<UserObjectivePeriod>;
} & Props &
  DataLoaderProvidedProps;

const UserObjectivePeriodPicker = ({
  userObjectivePeriod,
  userObjectivePeriods,
  isDisabled,
  isFetching,
  hasError,
  onChange,
}: AfterDataLoaderProps) => {
  if (isFetching) {
    return (
      <ObjectivePeriodPickerComponent
        label={__('Select an objective period')}
        period={null}
        periods={[]}
        isLoading
        onChange={() => {}}
      />
    );
  }

  if (hasError) {
    return (
      <p className="has-text-danger">
        {__('An error has occurred while fetching objective periods.')}
      </p>
    );
  }

  const handleChange = (id: string) => {
    const period = userObjectivePeriods.find(period => id === period.id);

    invariant(period, 'Selected period must exists');

    return onChange(period);
  };

  return (
    <ObjectivePeriodPickerComponent
      label={__('Select an objective period')}
      period={
        !!userObjectivePeriod
          ? {
              id: userObjectivePeriod.id,
              name: userObjectivePeriod.name,
            }
          : null
      }
      periods={userObjectivePeriods.map(period => ({
        id: period.id,
        name: period.name,
      }))}
      isDisabled={isDisabled}
      onChange={handleChange}
    />
  );
};

export default newDataLoader({
  fetch: ({ user }: Props) => get(`users/${user.id}/objective_periods`),
  cacheKey: ({ user }: Props) =>
    compositeKey({ view: 'objective_periods_picker', userId: user.id }),
  hydrate: {
    userObjectivePeriods: {
      abilities: {},
      user: {},
    },
  },
})(UserObjectivePeriodPicker) as React.ComponentType<Props>;
