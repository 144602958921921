import type {
  Resource as JsonApiResource,
  JsonApiResponse,
} from './types/JsonApiResponse';
import type {
  NormalizedJsonApiResponse,
  Relationships as NormalizedRelationships,
  Resources as NormalizedResources,
} from './types/NormalizedJsonApiResponse';

export default function normalizeJsonApiResponse({
  data,
  included,
}: JsonApiResponse): NormalizedJsonApiResponse {
  const records = [
    ...(Array.isArray(data) ? data : [data]),
    ...(included ? included : []),
  ];

  let extractedResources: NormalizedResources = {};
  let extractedRelationships: NormalizedRelationships = {};

  for (const record of records) {
    extractedResources = mergeResources(extractedResources, record);

    if (record.relationships) {
      extractedRelationships = mergeRelationships(
        extractedRelationships,
        record
      );
    }
  }

  return {
    relationships: extractedRelationships,
    resources: extractedResources,
  };
}

const mergeResources = (
  originalExtractedResources: NormalizedResources,
  record: JsonApiResource
): NormalizedResources => {
  const extractedResources: NormalizedResources = {
    ...originalExtractedResources,
  };
  const { type, id, attributes } = record;

  extractedResources[type] = extractedResources[type] || {};
  // @ts-ignore TSFIXME: Fix strictNullChecks error
  extractedResources[type][id] = { ...attributes, id, type };
  return extractedResources;
};

const mergeRelationships = (
  originalExtractedRelationships: NormalizedRelationships,
  record: JsonApiResource
): NormalizedRelationships => {
  const extractedRelationships: NormalizedRelationships = {
    ...originalExtractedRelationships,
  } as const;
  const { type, id, relationships } = record;

  extractedRelationships[type] = extractedRelationships[type] || {};
  // @ts-ignore TSFIXME: Fix strictNullChecks error
  extractedRelationships[type][id] = extractedRelationships[type][id] || {};

  for (const relationshipName in relationships) {
    // @ts-ignore TSFIXME: Fix strictNullChecks error
    const relatedRecords = relationships[relationshipName].data;

    // @ts-ignore TSFIXME: Fix strictNullChecks error
    extractedRelationships[type][id][relationshipName] = relatedRecords;
  }

  return extractedRelationships;
};
