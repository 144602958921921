import React, { ReactNode } from 'react';

import { Avatar, Tooltip } from 'components';

type Props = {
  count: number;
  tooltipContent?: ReactNode;
  children?: ReactNode;
};

export default function PlusXLeftAvatar({
  count,
  tooltipContent,
  children,
}: Props) {
  const label = `+${count}`;
  const url = `https://eu.ui-avatars.com/api/?name=${encodeURIComponent(
    label
  )}&length=${label.length}`;
  const proxiedUrl = `https://xc.elevo.fr/?u=${btoa(url)}`;
  return (
    <Tooltip content={tooltipContent}>
      <Avatar url={proxiedUrl}>{children}</Avatar>
    </Tooltip>
  );
}
