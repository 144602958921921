import React from 'react';

import { __ } from 'helpers/i18n';

import { Button, Control, Field } from 'components';

type Props = {
  onCancel: () => void;
  onSave: () => Promise<any>;
};

export default function CancelAndSaveButtons({ onSave, onCancel }: Props) {
  return (
    <Field isGrouped>
      <Control>
        <Button onClick={onCancel}>{__('Cancel')}</Button>
      </Control>

      <Control>
        <Button
          color="primary"
          onClick={onSave}
          testClassName="test-profile-save-button"
        >
          {__('Update')}
        </Button>
      </Control>
    </Field>
  );
}
