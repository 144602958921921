const clapSvg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="80" height="80" viewBox="0 0 80 80">
  <defs>
    <clipPath id="clip-path">
      <circle id="Ellipse_40" data-name="Ellipse 40" cx="40" cy="40" r="40" transform="translate(42 160)" fill="none"/>
    </clipPath>
    <clipPath id="clip-Yeah">
      <rect width="80" height="80"/>
    </clipPath>
  </defs>
  <g id="Yeah" clip-path="url(#clip-Yeah)">
    <rect width="80" height="80" fill="#fff"/>
    <g id="New_Symbol_2" data-name="New Symbol 2" transform="translate(-42 -160)">
      <path id="Path_1710" data-name="Path 1710" d="M40,0A40,40,0,1,1,0,40,40,40,0,0,1,40,0Z" transform="translate(42 160)" fill="#dbedfe"/>
    </g>
    <g id="Group_122" data-name="Group 122" transform="translate(-42 -160)">
      <path id="Path_146" data-name="Path 146" d="M75.343,176.054a.6.6,0,0,1,.476.835L73.641,182a.6.6,0,0,1-1.157-.185l-.476-5.465a.6.6,0,0,1,.681-.65Z" fill="#fccb44" stroke="#1E2938" stroke-linejoin="round" stroke-width="1"/>
      <path id="Path_147" data-name="Path 147" d="M63.038,177.011a.6.6,0,0,0-.341.9l2.942,4.715a.6.6,0,0,0,1.115-.361l-.375-5.473a.6.6,0,0,0-.773-.537Z" fill="#fccb44" stroke="#1E2938" stroke-linejoin="round" stroke-width="1"/>
      <path id="Path_148" data-name="Path 148" d="M54.243,183.453a.6.6,0,0,0,.272.922l5.195,1.973a.6.6,0,0,0,.669-.961l-3.609-4.131a.6.6,0,0,0-.941.04Z" fill="#fccb44" stroke="#1E2938" stroke-linejoin="round" stroke-width="1"/>
      <path id="Path_149" data-name="Path 149" d="M114.144,196.7a.6.6,0,0,1-.495.824l-5.525.6a.6.6,0,0,1-.406-1.1l4.533-3.09a.6.6,0,0,1,.9.275Z" fill="#fccb44" stroke="#1E2938" stroke-linejoin="round" stroke-width="1"/>
      <path id="Path_150" data-name="Path 150" d="M107.285,186.439a.6.6,0,0,0-.95.142l-2.671,4.874a.6.6,0,0,0,.861.795l4.588-3.006a.6.6,0,0,0,.09-.937Z" fill="#fccb44" stroke="#1E2938" stroke-linejoin="round" stroke-width="1"/>
      <path id="Path_151" data-name="Path 151" d="M97.363,181.923a.6.6,0,0,0-.671.689l.822,5.5a.6.6,0,0,0,1.166.113l1.835-5.17a.6.6,0,0,0-.5-.8Z" fill="#fccb44" stroke="#1E2938" stroke-linejoin="round" stroke-width="1"/>
      <g id="Group_121" data-name="Group 121">
        <g id="Group_120" data-name="Group 120" clip-path="url(#clip-path)">
          <g id="Group_113" data-name="Group 113">
            <path id="Path_152" data-name="Path 152" d="M86.472,198.109a2.414,2.414,0,0,0-.745,3.332c1.527,2.526,2.333,3.906,2.883,4.879a.3.3,0,0,1-.478.36L81.7,200.172l-.855.854L68.038,213.832l2.134,2.134a29.938,29.938,0,0,0,10.936,6.965l1.621.589a7.329,7.329,0,0,1,2.681,1.707l1.41,1.41,9.818-9.817L94.821,215c-1.561-1.561,1.291-5.981-.65-9.268L89.8,198.854A2.413,2.413,0,0,0,86.472,198.109Z" fill="#ffb59e" stroke="#45413c" stroke-linejoin="round" stroke-width="1"/>
            <g id="Group_108" data-name="Group 108">
              <rect id="Rectangle_51" data-name="Rectangle 51" width="13.259" height="18.714" rx="1.207" transform="translate(96.953 240.378) rotate(-135)" fill="#4ca7fb" stroke="#45413c" stroke-linejoin="round" stroke-width="1"/>
              <path id="Path_153" data-name="Path 153" d="M99.956,218.623a1.209,1.209,0,0,1,1.708,0l7.668,7.668a1.209,1.209,0,0,1,0,1.708l-1.281,1.28L98.676,219.9Z" fill="#00b8f0"/>
              <rect id="Rectangle_52" data-name="Rectangle 52" width="13.259" height="18.714" rx="1.207" transform="translate(96.953 240.378) rotate(-135)" fill="none" stroke="#1E2938" stroke-linejoin="round" stroke-width="1"/>
              <path id="Rectangle_53" data-name="Rectangle 53" d="M1.207,0H3.622a0,0,0,0,1,0,0V16.3a0,0,0,0,1,0,0H1.207A1.207,1.207,0,0,1,0,15.092V1.207A1.207,1.207,0,0,1,1.207,0Z" transform="translate(97.395 216.062) rotate(45)" fill="#fff" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            </g>
            <path id="Path_154" data-name="Path 154" d="M86.472,198.109a2.414,2.414,0,0,0-.745,3.332c1.527,2.526,2.333,3.906,2.883,4.879a.3.3,0,0,1-.478.36L81.7,200.172l-.855.854L68.038,213.832l2.134,2.134a29.938,29.938,0,0,0,10.936,6.965l1.621.589a7.329,7.329,0,0,1,2.681,1.707l1.41,1.41,9.818-9.817L94.821,215c-1.561-1.561,1.291-5.981-.65-9.268L89.8,198.854A2.413,2.413,0,0,0,86.472,198.109Z" fill="none" stroke="#1E2938" stroke-linejoin="round" stroke-width="1"/>
            <g id="Group_109" data-name="Group 109">
              <path id="Path_155" data-name="Path 155" d="M79.99,205.294l-9.817-9.817a2.414,2.414,0,0,1,3.414-3.415L83.4,201.88" fill="#ffb59e" stroke="#1E2938" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
            </g>
            <g id="Group_110" data-name="Group 110">
              <path id="Path_156" data-name="Path 156" d="M69.746,215.539l-7.684-7.683a2.415,2.415,0,0,1,3.415-3.415l7.684,7.683" fill="#ffb59e" stroke="#1E2938" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
            </g>
            <g id="Group_111" data-name="Group 111">
              <path id="Path_157" data-name="Path 157" d="M73.161,212.124l-11.1-11.1a2.415,2.415,0,0,1,3.415-3.415l11.1,11.1" fill="#ffb59e" stroke="#1E2938" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
            </g>
            <g id="Group_112" data-name="Group 112">
              <path id="Path_158" data-name="Path 158" d="M76.575,208.709,64.2,196.33a2.414,2.414,0,0,1,3.414-3.414L79.99,205.294" fill="#ffb59e" stroke="#1E2938" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
            </g>
          </g>
          <g id="Group_119" data-name="Group 119">
            <path id="Path_159" data-name="Path 159" d="M95.074,197.052a2.416,2.416,0,0,0-.745,3.333c1.527,2.526,2.333,3.9,2.883,4.878a.3.3,0,0,1-.478.361L90.3,199.116l-.854.854-12.806,12.8,2.135,2.135a29.937,29.937,0,0,0,10.935,6.964l1.621.589a7.345,7.345,0,0,1,2.681,1.708l1.41,1.41,9.818-9.818-1.817-1.816c-1.561-1.561,1.291-5.982-.649-9.269l-4.367-6.88A2.415,2.415,0,0,0,95.074,197.052Z" fill="#ffcebf" stroke="#45413c" stroke-linejoin="round" stroke-width="1"/>
            <g id="Group_114" data-name="Group 114">
              <path id="Path_1711" data-name="Path 1711" d="M1.207,0H12.052a1.207,1.207,0,0,1,1.207,1.207v16.3a1.207,1.207,0,0,1-1.207,1.207H1.207A1.207,1.207,0,0,1,0,17.506V1.207A1.207,1.207,0,0,1,1.207,0Z" transform="translate(105.555 239.321) rotate(-135)" fill="#4ca7fb" stroke="#45413c" stroke-width="1"/>
              <path id="Path_160" data-name="Path 160" d="M108.559,217.567a1.206,1.206,0,0,1,1.707,0l7.668,7.668a1.206,1.206,0,0,1,0,1.707l-1.28,1.281-9.376-9.376Z" fill="#7fc1fd"/>
              <rect id="Rectangle_55" data-name="Rectangle 55" width="13.259" height="18.714" rx="1.207" transform="translate(105.555 239.321) rotate(-135)" fill="none" stroke="#1E2938" stroke-linejoin="round" stroke-width="1"/>
              <path id="Rectangle_56" data-name="Rectangle 56" d="M1.207,0H3.622a0,0,0,0,1,0,0V16.3a0,0,0,0,1,0,0H1.207A1.207,1.207,0,0,1,0,15.092V1.207A1.207,1.207,0,0,1,1.207,0Z" transform="translate(105.998 215.006) rotate(45)" fill="#fff" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            </g>
            <path id="Path_161" data-name="Path 161" d="M96.986,216.04a25.848,25.848,0,0,1-8.138,5.494c.287.114.572.235.862.34l1.621.589a7.345,7.345,0,0,1,2.681,1.708l1.41,1.41,9.818-9.818-1.817-1.816c-1.384-1.384.695-5.016-.168-8.114A25.816,25.816,0,0,1,96.986,216.04Z" fill="#ffb59e"/>
            <path id="Path_162" data-name="Path 162" d="M95.074,197.052a2.416,2.416,0,0,0-.745,3.333c1.527,2.526,2.333,3.9,2.883,4.878a.3.3,0,0,1-.478.361L90.3,199.116l-.854.854-12.806,12.8,2.135,2.135a29.937,29.937,0,0,0,10.935,6.964l1.621.589a7.345,7.345,0,0,1,2.681,1.708l1.41,1.41,9.818-9.818-1.817-1.816c-1.561-1.561,1.291-5.982-.649-9.269l-4.367-6.88A2.415,2.415,0,0,0,95.074,197.052Z" fill="none" stroke="#1E2938" stroke-linejoin="round" stroke-width="1"/>
            <g id="Group_115" data-name="Group 115">
              <path id="Path_163" data-name="Path 163" d="M88.593,204.238l-9.818-9.818a2.415,2.415,0,1,1,3.415-3.415l9.817,9.818" fill="#ffcebf" stroke="#1E2938" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
            </g>
            <g id="Group_116" data-name="Group 116">
              <path id="Path_164" data-name="Path 164" d="M78.348,214.483,70.665,206.8a2.414,2.414,0,1,1,3.414-3.415l7.684,7.684" fill="#ffcebf" stroke="#1E2938" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
            </g>
            <g id="Group_117" data-name="Group 117">
              <path id="Path_165" data-name="Path 165" d="M81.763,211.068l-11.1-11.1a2.414,2.414,0,0,1,3.414-3.414l11.1,11.1" fill="#ffcebf" stroke="#1E2938" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
            </g>
            <g id="Group_118" data-name="Group 118">
              <path id="Path_166" data-name="Path 166" d="M85.178,207.653,72.8,195.274a2.415,2.415,0,0,1,3.415-3.415l12.379,12.379" fill="#ffcebf" stroke="#1E2938" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
`;

export default clapSvg;
