import React, { useEffect } from 'react';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { navigate } from 'helpers/navigation';
import { pathToOrganizationObjectivePeriod } from 'helpers/paths';

import { post } from 'redux/actions/api';
import { getAvailablePeriods as getAvailablePeriodsAction } from 'redux/actions/resources';

import { ConfirmationModal, Field, Select, Text } from 'components';

type Props = {
  onClose: () => void;
};

const CreatePeriodModal = ({ onClose }: Props) => {
  const dispatch = useAppDispatch();

  const [availablePeriods, setAvailablePeriods] = React.useState<Array<string>>(
    []
  );
  const [selectedPeriodName, setSelectedPeriodName] = React.useState<
    string | null
  >(null);

  useEffect(() => {
    let ignore = false;
    dispatch(getAvailablePeriodsAction()).then(response => {
      if (ignore) return;
      setAvailablePeriods(response.response.body);
      setSelectedPeriodName(response.response.body[0]);
    });
    return () => {
      ignore = true;
    };
  }, [dispatch]);

  const createObjectivePeriod = async () => {
    if (!selectedPeriodName) return;

    try {
      const { response } = await dispatch(
        post(
          'organization_objective_periods',
          { name: selectedPeriodName },
          {
            successMessage: __(
              'Objective period <b>%1</b> has been created. You can now add teams to it and create objectives.',
              selectedPeriodName
            ),
          }
        )
      );

      const objectivePeriods = response.body.included;
      navigate(
        pathToOrganizationObjectivePeriod(
          objectivePeriods[objectivePeriods.length - 1].attributes.slug
        )
      );
      onClose();
    } finally {
      setSelectedPeriodName(null);
    }
  };

  return (
    <ConfirmationModal
      isActive
      onCancel={onClose}
      refreshContentOnOpening={false}
      title={__('Create a new period')}
      confirmDisabled={!selectedPeriodName}
      onConfirm={createObjectivePeriod}
      confirmLabel={__('Create period')}
    >
      <div>
        <p style={{ marginBottom: 20 }}>
          <Text>
            {__(
              'This new period will be common for all teams. ' +
                'Once the new period has been created, each team can add ' +
                'its own objectives for this period.'
            )}
          </Text>
        </p>
        <Field>
          <Select
            options={availablePeriods.map(periodName => ({
              value: periodName,
              label: periodName,
            }))}
            value={{
              value: selectedPeriodName || '',
              label: selectedPeriodName || '',
            }}
            onChange={option =>
              setSelectedPeriodName(
                option && !Array.isArray(option) ? option.value : null
              )
            }
            styles={{
              // @ts-expect-error: menuPortal doesn't exist
              menuPortal: styles => ({
                ...styles,
                zIndex: 802,
              }),
            }}
          />
        </Field>
      </div>
    </ConfirmationModal>
  );
};

export default CreatePeriodModal;
