import { pickBy } from 'lodash';

import type {
  GraphFormattedTimeSeriesDataPoint,
  SeriesBearer,
  TimeSeriesAnnotation,
} from 'models';

type GraphFormattedDataAndAnnotations = {
  data: Array<GraphFormattedTimeSeriesDataPoint>;
  annotations: Array<TimeSeriesAnnotation>;
};

export function zipSeries(
  seriesBearer: SeriesBearer
): GraphFormattedDataAndAnnotations {
  // Make a DataPointByDate Hash for each series
  // Iterate on the first one, and associate data from the other ones thanks to the hash(es)
  const allSeries = pickBy(seriesBearer, value => !!value && !!value.data);

  const dates: Set<string> = new Set();
  const dataPointByDate: Record<string, any> = {};
  let annotations = [];

  const seriesNames = Object.keys(allSeries);

  seriesNames.forEach(seriesName => {
    // Map dataPoints of each series in an object with date as key
    const series = allSeries[seriesName];
    dataPointByDate[seriesName] = {};

    series.data.forEach(dataPoint => {
      dataPointByDate[seriesName][dataPoint.date] = dataPoint;
      dates.add(dataPoint.date);
    });

    // Extract annotations of each series in an array
    if (!!series.annotations) {
      annotations = annotations.concat(
        // @ts-ignore TSFIXME: Fix strictNullChecks error
        series.annotations.map(annotation => ({
          date: annotation.date,
        }))
      );
    }
  });

  let data = [];
  dates.forEach(date => {
    let dataPoint = { date: date };

    seriesNames.forEach(seriesName => {
      if (!!dataPointByDate[seriesName][date]) {
        dataPoint = {
          ...dataPoint,
          [seriesName]: dataPointByDate[seriesName][date].value,
        };
      }
    });

    // @ts-ignore TSFIXME: Fix strictNullChecks error
    data.push(dataPoint);
  });

  return { data, annotations };
}
