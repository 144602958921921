import React from 'react';

import { SkillsDomain } from 'models/skills';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { htmlSuccessNotice } from 'redux/actions';
import { del } from 'redux/actions/api';

import ConfirmationModalWithSideEffects from 'components/modals/ConfirmationModalWithSideEffects';

type Props = {
  domain: SkillsDomain;
  onClose: () => void;
};

const DeletionModal = ({ domain, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const deleteDomain = async () => {
    await dispatch(del(`skills/domains/${domain.id}`));
    dispatch(
      htmlSuccessNotice(
        __(
          '<b>The skills domain %1 has been successfully deleted.</b><br/>The skills domain and associated skills have been removed from the matrices.<br/>The skills associated with the domain have not been deleted from the skills library.',
          domain.title
        )
      )
    );
    onClose();
  };

  return (
    <ConfirmationModalWithSideEffects
      onConfirm={deleteDomain}
      onCancel={onClose}
      confirmLabel={__('Delete the domain')}
      sideEffectsLabel={__('Are you sure you want to delete this domain?')}
      cancelLabel={__('Cancel')}
      isActive
      sideEffects={[
        __('Delete the domain and remove it from associated matrices'),
      ]}
      renderMessage={() => (
        <div className="mb-4">
          <p>
            {__(
              'Deleting the %1 domain will result in its removal, as well as the removal of the skills associated with it, from all the matrices to which it has been added.',
              <b>{domain.title}</b>
            )}
          </p>
          <p>
            <b>
              {__(
                'It will no longer be used in unshared and upcoming evaluations.'
              )}
            </b>
          </p>
        </div>
      )}
      title={__('Delete the domain')}
    />
  );
};

export default DeletionModal;
