import React from 'react';

import { PeopleReviewSuccessor } from 'models';

import { useAppDispatch } from 'helpers/hooks';

import { put } from 'redux/actions/api/methods';

import { DragAndDropContainer, DragAndDropItem } from 'components/dragAndDrop';

import SuccessorListItem from './SuccessorListItem';

type Props = {
  successors: Array<PeopleReviewSuccessor>;
  reviewId: string;
  isClosed: boolean;
};

const SuccessorList = ({ successors, reviewId, isClosed }: Props) => {
  const dispatch = useAppDispatch();
  const onChangeSuccessorPosition = (
    successorId: string,
    successorPosition: number
  ) =>
    dispatch(
      put(`people_user_reviews/${reviewId}/successors/${successorId}`, {
        position: successorPosition,
      })
    );

  if (isClosed) {
    return (
      <div>
        {successors.map(successor => (
          <SuccessorListItem
            key={successor.id}
            successor={successor}
            isClosed
            reviewId={reviewId}
          />
        ))}
      </div>
    );
  }

  return (
    <DragAndDropContainer
      onChangePosition={(successorId, newPosition) =>
        onChangeSuccessorPosition(successorId, newPosition)
      }
      style={{ marginLeft: 18 }}
    >
      {successors.map(successor => (
        <DragAndDropItem
          key={successor.id}
          itemId={successor.id}
          position={successor.position}
          handlePosition="left"
          alwaysDisplayHandle
          disabled={isClosed}
        >
          <SuccessorListItem
            successor={successor}
            isClosed={false}
            reviewId={reviewId}
          />
        </DragAndDropItem>
      ))}
    </DragAndDropContainer>
  );
};

export default SuccessorList;
