import React from 'react';

import type {
  FeedbackableItem,
  FeedbackableRatingCriterion,
  MultipleScaleQuestionBlock,
} from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n';

import OpenAnswer from '../../components/FeedbackZone/OpenAnswer';
import RequiredQuestionWarning from '../../components/FeedbackZone/RequiredQuestionWarning';
import RatingCriteria from './RatingCriteria';

const getInitialNotCompletedRatingIds = (
  criteria: Array<FeedbackableItem<FeedbackableRatingCriterion>>
) => {
  return criteria
    .filter(criterion =>
      criterion.answers.some(
        answer => answer.status === 'opened' && !answer.isAnswered
      )
    )
    .map(criterion => criterion.item.id);
};

type Props = {
  feedbackableCriteria: Array<FeedbackableItem<FeedbackableRatingCriterion>>;
  feedbackableBlock: FeedbackableItem<MultipleScaleQuestionBlock>;
  persistAction: (
    evaluationId: string | undefined | null,
    feedbackableId: string | undefined | null,
    feedbackableType: string | undefined | null,
    text: string,
    rating: string | undefined | null
  ) => (dispatch: AppDispatch) => Promise<void>;
};

export default function OpenAnswersSection({
  feedbackableCriteria,
  feedbackableBlock,
  persistAction,
}: Props) {
  const [notCompletedRatingIds, setNotCompletedRatingIds] = React.useState(
    getInitialNotCompletedRatingIds(feedbackableCriteria)
  );

  const openFeedbackableBlockAnswer = feedbackableBlock.answers.find(
    answer => answer.status === 'opened'
  );
  const shouldDisplayRequiredTag =
    openFeedbackableBlockAnswer?.textMandatory ||
    openFeedbackableBlockAnswer?.ratingMandatory;

  const persistRating = (
    evaluationId: string | undefined | null,
    feedbackableId: string | undefined | null,
    feedbackableType: string | undefined | null,
    text: string,
    rating: string | undefined | null
  ) => {
    if (feedbackableId) {
      rating
        ? setNotCompletedRatingIds(
            notCompletedRatingIds.filter(id => id !== feedbackableId)
          )
        : setNotCompletedRatingIds([...notCompletedRatingIds, feedbackableId]);
    }

    return persistAction(
      evaluationId,
      feedbackableId,
      feedbackableType,
      text,
      rating
    );
  };

  return (
    <div className="open-answers-section">
      {shouldDisplayRequiredTag && (
        <RequiredQuestionWarning
          warningText={
            openFeedbackableBlockAnswer?.ratingMandatory &&
            notCompletedRatingIds.length
              ? __('Select 1 answer by row')
              : ''
          }
          style={{ marginTop: 16 }}
        />
      )}

      <RatingCriteria
        feedbackableCriteria={feedbackableCriteria}
        persistAction={persistRating}
      />

      {!!openFeedbackableBlockAnswer && (
        <div className="feedback-zone" style={{ marginTop: 16 }}>
          <OpenAnswer
            itemId={feedbackableBlock.item.id}
            itemType={feedbackableBlock.item.type}
            answer={openFeedbackableBlockAnswer}
            blockId={feedbackableBlock.blockId}
            persistAction={persistAction}
          />
        </div>
      )}
    </div>
  );
}
