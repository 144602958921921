export const selectSessions = state => state.data.session;
export const selectUsers = state => state.data.user;
export const selectOrganizations = state => state.data.organization;
export const selectOrganizationPlans = state => state.data.organizationPlan;
export const selectOrganizationSettings = state =>
  state.data.organizationSettings;
export const selectOrganizationThemes = state => state.data.organizationTheme;
export const selectUserFilterableFields = state => {
  return state.data.userFilterableField;
};
export const selectSurveyUserFilterableFields = state => {
  return state.data.surveyUserFilterableField;
};
