export type ChartColor = 'blue' | 'orange' | 'green' | 'pink';

export const COLOR_SET: Array<ChartColor> = ['blue', 'orange', 'green', 'pink'];

// From lest to most opaque,
// ie left most color is displayed when very few votes, right most when a lot of votes
export const COLOR_HEXA: {
  // eslint-disable-next-line no-unused-vars
  [k in ChartColor]: string;
} = {
  blue: '#7DE0FF',
  orange: '#FACC87',
  green: '#B4EB7B',
  pink: '#FCA3DA',
};
