import React from 'react';

import { ExportAction } from 'models';

import { useExportAction } from 'helpers/hooks/useExportAction';
import { __ } from 'helpers/i18n';

import { Button, Tooltip } from 'components';

type Props = {
  lastPendingExportAction: ExportAction | null;
  exportSurveyCampaign: () => Promise<void>;
};

const ExportButton = ({
  lastPendingExportAction,
  exportSurveyCampaign,
}: Props) => {
  const { ongoingExportAction, exportTooltipText, pollerComponent } =
    useExportAction(lastPendingExportAction);

  return (
    <Tooltip content={exportTooltipText}>
      {pollerComponent}
      <Button
        color="secondary"
        onClick={exportSurveyCampaign}
        disabled={ongoingExportAction}
      >
        {__('Export')}
      </Button>
    </Tooltip>
  );
};

export default ExportButton;
