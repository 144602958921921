import React, { useState } from 'react';

import type { ManagementBoxProps } from './';

import { __ } from 'helpers/i18n';

import CareerLevelManagementModal from 'scenes/components/skills/CareerLevelManagementModal';

import CareerLevelManagementBoxContent from './components/CareerLevelManagementBoxContent';

type Props = ManagementBoxProps;

const NextCareerLevelManagementBox = ({
  careerLevel,
  userFullName,
  canUpdate,
  isActiveUser,
  performUpdate,
}: Props) => {
  const [modalIsActive, setModalIsActive] = useState(false);

  return (
    <React.Fragment>
      <CareerLevelManagementBoxContent
        title={__('Upcoming matrix and level')}
        emptyCareerLevelMessage={
          isActiveUser
            ? __('You do not have an associated upcoming skills matrix.')
            : __(
                '%1 does not have an associated upcoming skills matrix.',
                userFullName
              )
        }
        careerLevel={careerLevel}
        canUpdate={canUpdate}
        onLevelTitleClick={() => setModalIsActive(true)}
      />
      {canUpdate && (
        <CareerLevelManagementModal
          isActive={modalIsActive}
          onClose={() => setModalIsActive(false)}
          title={__('Update the next level of %1', userFullName)}
          description={__(
            'Plan the career progression of %1 by selecting the skills matrix and the target level.',
            userFullName
          )}
          levelSelectionLabel={__('Select the next level')}
          submitLabel={__('Update the next level')}
          onLevelSelect={async (levelId: string | null) => {
            await performUpdate({ levelType: 'next', levelId });
            setModalIsActive(false);
          }}
          currentSkillsLevel={careerLevel?.skillsLevel}
        />
      )}
    </React.Fragment>
  );
};

export default NextCareerLevelManagementBox;
