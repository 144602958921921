import { sortBy } from 'lodash';
import React from 'react';

import type { PeopleUserReview } from 'models';

import can from 'helpers/can';
import invariant from 'helpers/invariant';

import ActionPlanBlock from './blocks/ActionPlanBlock';
import FinalCommentBlock from './blocks/FinalCommentBlock';
import ReviewedDimensionBlock from './blocks/ReviewedDimensionBlock';
import StatusUpdateBlock from './blocks/StatusUpdateBlock';
import SuccessorBlock from './blocks/SuccessorBlock';

type Props = {
  review: PeopleUserReview;
  refetchReview: () => void;
};

const Content = ({ review, refetchReview }: Props) => {
  const {
    reviewedDimensionBlocks,
    finalCommentBlock,
    successorBlock,
    isClosed,
  } = review;

  invariant(
    reviewedDimensionBlocks,
    'people review needs dimensions to be reviewed'
  );

  const canFillContent = can({ perform: 'fill_content', on: review });

  return (
    <div className="form-content">
      {sortBy(reviewedDimensionBlocks, rdb => rdb.position).map(
        reviewedDimensionBlock => (
          <ReviewedDimensionBlock
            key={reviewedDimensionBlock.id}
            reviewId={review.id}
            reviewedDimensionBlock={reviewedDimensionBlock}
            canFillContent={canFillContent}
            isClosed={isClosed}
          />
        )
      )}

      {successorBlock && (
        <SuccessorBlock
          reviewId={review.id}
          revieweeFullName={review.user.fullName}
          successorBlock={successorBlock}
          canFillContent={canFillContent}
          isClosed={isClosed}
        />
      )}

      {finalCommentBlock && (
        <FinalCommentBlock
          reviewId={review.id}
          finalCommentBlock={finalCommentBlock}
          canFillContent={canFillContent}
          isClosed={isClosed}
        />
      )}
      <ActionPlanBlock review={review} paginationType="state" />
      <StatusUpdateBlock review={review} refetchReview={refetchReview} />
    </div>
  );
};

export default Content;
