import React from 'react';

import type { UserReview } from 'models';

import { trackAction } from 'helpers/tracking';

function trackReviewSeen(
  userReview: UserReview,
  additionalProperties: unknown
) {
  const reviewee = userReview.user;

  trackAction('review_seen', {
    revieweeId: reviewee.id,
    reviewType: userReview.interactionType,
    // @ts-ignore: FIXME: type additionalProperties
    ...additionalProperties,
  });
}

type Props = {
  userReview: UserReview | undefined | null;
  additionalProperties: unknown;
};

export default class ReviewSeenTracker extends React.Component<Props> {
  handleUpdate(prevProps: Props, nextProps: Props) {
    const { userReview, additionalProperties } = nextProps;

    if (!userReview) return;

    const previousUserReviewId =
      prevProps.userReview && prevProps.userReview.id;

    if (previousUserReviewId !== userReview.id) {
      trackReviewSeen(userReview, additionalProperties);
    }
  }

  componentDidMount() {
    this.handleUpdate(
      {
        userReview: null,
        additionalProperties: null,
      },
      this.props
    );
  }
  componentDidUpdate(prevProps: Props) {
    this.handleUpdate(prevProps, this.props);
  }

  render() {
    return null;
  }
}
