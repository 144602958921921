import React, { useContext } from 'react';

import invariant from 'helpers/invariant';

import { Control, Field, FieldError, Label } from 'components';

import { DataContext } from '..';
import useFieldMetadata from '../hooks/useFieldMetadata';
import { UpdatableKeys } from '../hooks/useUserToEditableAttributes';
import StandardField from './editableFieldTypes/StandardField';

type Props = {
  field: UpdatableKeys;
};

export type EditableFieldProps = {
  field: UpdatableKeys;
  slug: string;
  value: any;
  syncedFieldOptions: { fieldLabel: string; fieldSlug: string };
  fieldSynced: boolean;
  onChange: (value: any) => void;
};

const EditableField = ({ field }: Props) => {
  const metadata = useFieldMetadata(field);
  const Component = metadata?.componentClass || StandardField;
  const errorField = metadata?.errorFetchingKey || field;

  const { errors, isFieldSynced, onAttributeChange, attributes } =
    useContext(DataContext);

  invariant(!!metadata, 'Field metadata not found');

  const fieldSlug = metadata.syncOptions?.syncedFieldSlug || metadata.slug;
  const fieldSynced = isFieldSynced(fieldSlug);

  const props = {
    field,
    slug: metadata.slug,
    value: attributes[field],
    fieldSynced,
    syncedFieldOptions: {
      fieldLabel: metadata.label,
      fieldSlug,
    },
    onChange: (value: any) => onAttributeChange(field, value),
  };

  return (
    <Field>
      <Label color="info">{metadata.label}</Label>

      <Control
        isClearable={
          metadata.isClearableFromControl && !fieldSynced && !!attributes[field]
        }
        onClear={() => onAttributeChange(field, null)}
      >
        <Component {...props} />
      </Control>

      {!!errors[errorField] && (
        <FieldError>
          {Array.isArray(errors[errorField])
            ? errors[errorField]?.[0]
            : errors[errorField]}
        </FieldError>
      )}
    </Field>
  );
};

export default EditableField;
