import React from 'react';

import ItemFall from './config/ItemFall';
import { type Params, type Period, getDefaultConfig } from './defaultConfig';

type Props = {
  periodType?: Period;
  params?: Params;
};

const FallingItems = ({ periodType, params: paramsFromProps }: Props) => {
  if (!periodType && !paramsFromProps?.images) return null;

  let params = { ...paramsFromProps };
  if (periodType) {
    const defaultParams = getDefaultConfig(periodType);
    params = { ...params, ...defaultParams };
  }
  return <ItemFall {...params} />;
};

export default FallingItems;
