import React from 'react';

import type { ThreeSixtyUserReview } from 'models';

import { __ } from 'helpers/i18n';
import {
  pathToCurrentUserEvaluation,
  pathToReviewResults,
} from 'helpers/navigation';

import { Button, Flex, Testable } from 'components';

type Props = {
  userReview: ThreeSixtyUserReview;
};

const MainAction = ({ userReview }: Props) => {
  let buttonLabel = '',
    testClass = '',
    isPrimary = true;

  const revieweeStatus = userReview.revieweeStatus;

  if (revieweeStatus === null) return null;

  let reviewPath = pathToReviewResults(userReview);
  let evaluationPath = pathToCurrentUserEvaluation(userReview);
  let path = reviewPath;

  switch (revieweeStatus) {
    case 'not_started':
      testClass = 'test-start-self-assessment-button';
      buttonLabel = __('Start self-assessment');
      path = evaluationPath;
      break;
    case 'in_progress':
      testClass = 'test-continue-assessment-button';
      buttonLabel = __('Continue self-assessment');
      path = evaluationPath;
      break;
    case 'shared':
      isPrimary = false;
      if (userReview.isReleased) {
        testClass = 'test-view-results-button';
        buttonLabel = __('View results');
      } else {
        testClass = 'test-view-self-assessment-button';
        buttonLabel = __('View self-assessment');
        path = evaluationPath;
      }
      break;
    case 'signed':
    case 'pending_peer_validation':
    case 'blocked':
    case 'outdated':
      throw new Error(
        `ThreeSixtyUserReview#${userReview.id} with revieweeStatus ${revieweeStatus} (should not happen)`
      );
    default:
      buttonLabel = revieweeStatus as any as string;
  }
  return (
    <Flex verticalAlign>
      <Button color={isPrimary ? 'primary' : 'secondary'} to={path}>
        <Testable name={testClass}>{buttonLabel || null}</Testable>
      </Button>
    </Flex>
  );
};

export default MainAction;
