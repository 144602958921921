import React from 'react';

export default function ErrorSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
    >
      <defs>
        <clipPath id="clipPath">
          <circle
            id="Ellipse_248"
            data-name="Ellipse 248"
            cx="39.707"
            cy="39.707"
            r="39.707"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-Oops">
          <rect width="80" height="80" />
        </clipPath>
      </defs>
      <g id="Oops" clipPath="url(#clip-Oops)">
        <g id="Group_632" data-name="Group 632" transform="translate(80.363)">
          <circle
            id="Ellipse_1"
            data-name="Ellipse 1"
            cx="39.707"
            cy="39.707"
            r="39.707"
            transform="translate(-12.558 60.909) rotate(-66.704)"
            fill="rgba(76,167,251,0.2)"
          />
          <g
            id="Group_1"
            data-name="Group 1"
            transform="translate(-0.092 0.435)"
          >
            <g id="Group_630" data-name="Group 630">
              <g id="Group_629" data-name="Group 629" clipPath="url(#clipPath)">
                <path
                  id="Path_1707"
                  data-name="Path 1707"
                  d="M68.238,186.648c-24.7-5-17.629-36.447-.591-29.758a4.069,4.069,0,0,1,2.407,2.9,4.389,4.389,0,0,1-.814,3.666,6.164,6.164,0,0,1-7.3,1.731c-11.229-4.809-1.993-25.573,12.4-26.163"
                  transform="translate(-40.089 -104.78)"
                  fill="none"
                  stroke="#4ca7fb"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                  strokeDasharray="2 3"
                />
              </g>
            </g>
            <g
              id="Group_631"
              data-name="Group 631"
              transform="translate(30.773 21.284)"
            >
              <path
                id="Path_1708"
                data-name="Path 1708"
                d="M71.285,127.829a1.116,1.116,0,0,1,.919-1.857l36.719,2.915a1.116,1.116,0,0,1,.554,2.025l-30.11,21.216A1.116,1.116,0,0,1,77.63,151l2.511-12.623a1.116,1.116,0,0,0-.264-.963Z"
                transform="translate(-71 -125.968)"
                fill="#fff"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <path
                id="Path_1709"
                data-name="Path 1709"
                d="M80.19,138.4l-2.511,12.623a1.117,1.117,0,0,0,1.738,1.131l30.109-21.217a1.115,1.115,0,0,0,.434-1.206L80.171,137.89A1.119,1.119,0,0,1,80.19,138.4Z"
                transform="translate(-71.049 -125.996)"
                fill="#daedf7"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2063"
          data-name="Group 2063"
          transform="translate(4.028 3.089)"
        >
          <g id="Bike_BG" data-name="Bike BG" transform="translate(-3)">
            <g id="New_Symbol_64" data-name="New Symbol 64">
              <g
                id="Group_1113"
                data-name="Group 1113"
                transform="translate(3.762)"
              >
                <path
                  id="Path_2195"
                  data-name="Path 2195"
                  d="M231.36,1196.617c0-20.222-15.975-36.617-35.68-36.617S160,1176.394,160,1196.617a37.284,37.284,0,0,0,3.179,15.1h65A37.269,37.269,0,0,0,231.36,1196.617Z"
                  transform="translate(-160 -1160)"
                  fill="#dbedfe"
                />
                <path
                  id="Path_2196"
                  data-name="Path 2196"
                  d="M163.564,1216.5c5.576,13.033,17.949,22.1,32.328,22.1s26.752-9.069,32.328-22.1Z"
                  transform="translate(-160.212 -1164.779)"
                  fill="#fcefd9"
                />
              </g>
              <g
                id="Group_1114"
                data-name="Group 1114"
                transform="translate(0 52.138)"
              >
                <line
                  id="Line_686"
                  data-name="Line 686"
                  x2="69.479"
                  transform="translate(4.232)"
                  fill="none"
                  stroke="#1E2938"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <line
                  id="Line_687"
                  data-name="Line 687"
                  x2="2.351"
                  fill="none"
                  stroke="#1E2938"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <line
                  id="Line_688"
                  data-name="Line 688"
                  x2="2.351"
                  transform="translate(75.592)"
                  fill="none"
                  stroke="#1E2938"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </g>
            </g>
          </g>
          <g id="Bike" transform="translate(0.762 3.762)">
            <g
              id="Group_2062"
              data-name="Group 2062"
              transform="translate(17.87 25.068)"
            >
              <path
                id="Path_4782"
                data-name="Path 4782"
                d="M221.042,1225.911c0,.78-8.329,1.411-18.6,1.411s-18.6-.631-18.6-1.411,8.329-1.411,18.6-1.411S221.042,1225.132,221.042,1225.911Z"
                transform="translate(-179.288 -1192.667)"
                fill="#45413c"
                opacity="0.15"
              />
              <rect
                id="Rectangle_1080"
                data-name="Rectangle 1080"
                width="18"
                height="19"
                rx="5"
                transform="translate(0.437 13.993)"
                fill="#525252"
              />
              <path
                id="Path_4783"
                data-name="Path 4783"
                d="M193.108,1205.5H183.7a4.7,4.7,0,0,0-4.7,4.7v2.351a4.7,4.7,0,0,1,4.7-4.7h9.405a4.7,4.7,0,0,1,4.7,4.7V1210.2A4.7,4.7,0,0,0,193.108,1205.5Z"
                transform="translate(-179 -1191.537)"
                fill="#656769"
              />
              <rect
                id="Rectangle_1081"
                data-name="Rectangle 1081"
                width="18"
                height="19"
                rx="5"
                transform="translate(0.437 13.993)"
                fill="none"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <rect
                id="Rectangle_1082"
                data-name="Rectangle 1082"
                width="10.346"
                height="10.346"
                rx="2"
                transform="translate(4.232 18.195)"
                fill="#daedf7"
              />
              <path
                id="Path_4784"
                data-name="Path 4784"
                d="M191.965,1210h-6.584a1.881,1.881,0,0,0-1.881,1.881v1.411a1.881,1.881,0,0,1,1.881-1.881h6.584a1.882,1.882,0,0,1,1.881,1.881v-1.411A1.882,1.882,0,0,0,191.965,1210Z"
                transform="translate(-179.268 -1191.805)"
                fill="#c0dceb"
              />
              <path
                id="Path_4811"
                data-name="Path 4811"
                d="M2,0H8.346a2,2,0,0,1,2,2V8.346a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
                transform="translate(4.232 18.195)"
                fill="none"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeWidth="1"
              />
              <rect
                id="Rectangle_1084"
                data-name="Rectangle 1084"
                width="1.881"
                height="5.643"
                transform="translate(27.134 9.948) rotate(33.822)"
                fill="#daedf7"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <path
                id="Path_4785"
                data-name="Path 4785"
                d="M210.668,1201.858h1.411a1.881,1.881,0,0,0,1.881-1.881v-.941a1.881,1.881,0,0,0-1.881-1.881H211.2a1.946,1.946,0,0,0-.993.288,11.138,11.138,0,0,1-5.187,1.593,1.411,1.411,0,0,0,0,2.822Z"
                transform="translate(-180.465 -1191.04)"
                fill="#915e3a"
              />
              <path
                id="Path_4786"
                data-name="Path 4786"
                d="M212.078,1197.155H211.2a1.946,1.946,0,0,0-.993.288,11.138,11.138,0,0,1-5.187,1.593,1.41,1.41,0,0,0-1.051,2.351,1.4,1.4,0,0,1,1.051-.47,11.138,11.138,0,0,0,5.187-1.593,1.946,1.946,0,0,1,.993-.288h.874a1.881,1.881,0,0,1,1.822,1.411,1.9,1.9,0,0,0,.059-.47v-.941A1.881,1.881,0,0,0,212.078,1197.155Z"
                transform="translate(-180.465 -1191.04)"
                fill="#bf8256"
              />
              <path
                id="Path_4787"
                data-name="Path 4787"
                d="M210.668,1201.858h1.411a1.881,1.881,0,0,0,1.881-1.881v-.941a1.881,1.881,0,0,0-1.881-1.881H211.2a1.946,1.946,0,0,0-.993.288,11.138,11.138,0,0,1-5.187,1.593,1.411,1.411,0,0,0,0,2.822Z"
                transform="translate(-180.465 -1191.04)"
                fill="none"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <path
                id="Path_4788"
                data-name="Path 4788"
                d="M190,1213.971l4.987-21.4-1.832-.426-5.2,22.314.915.214A.941.941,0,0,0,190,1213.971Z"
                transform="translate(-179.533 -1190.742)"
                fill="#ff684a"
              />
              <path
                id="Path_4789"
                data-name="Path 4789"
                d="M193.055,1202l-.988,4.232H190.13l.988-4.232Z"
                transform="translate(-179.662 -1191.328)"
                fill="#e04122"
              />
              <path
                id="Path_4790"
                data-name="Path 4790"
                d="M195.268,1192.573l-.64,2.727H192.7l.733-3.151Z"
                transform="translate(-179.815 -1190.743)"
                fill="#e04122"
              />
              <path
                id="Path_4791"
                data-name="Path 4791"
                d="M190,1213.971l4.987-21.4-1.832-.426-5.2,22.314.915.214A.941.941,0,0,0,190,1213.971Z"
                transform="translate(-179.533 -1190.742)"
                fill="none"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <path
                id="Path_4792"
                data-name="Path 4792"
                d="M194.318,1202.222l8.583,4.693a4.73,4.73,0,0,1,.845.591l9.774,8.569,1.86-2.122-10.515-9.236a4.683,4.683,0,0,0-.929-.637l-8.968-4.675Z"
                transform="translate(-179.911 -1191.174)"
                fill="#ff684a"
              />
              <path
                id="Path_4793"
                data-name="Path 4793"
                d="M203.185,1205.37a4.726,4.726,0,0,1,.929.637l10.311,9.057.973-1.111-10.515-9.236a4.683,4.683,0,0,0-.929-.637l-8.968-4.675-.348,1.509Z"
                transform="translate(-179.931 -1191.174)"
                fill="#ff866e"
              />
              <path
                id="Path_4794"
                data-name="Path 4794"
                d="M194.318,1202.222l8.583,4.693a4.73,4.73,0,0,1,.845.591l9.774,8.569,1.86-2.122-10.515-9.236a4.683,4.683,0,0,0-.929-.637l-8.968-4.675Z"
                transform="translate(-179.911 -1191.174)"
                fill="none"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <circle
                id="Ellipse_637"
                data-name="Ellipse 637"
                cx="5.643"
                cy="5.643"
                r="5.643"
                transform="translate(31.977 21.632)"
                fill="#525252"
              />
              <path
                id="Path_4795"
                data-name="Path 4795"
                d="M218.643,1215.536a5.643,5.643,0,0,1,5.564,4.7A5.643,5.643,0,1,0,213,1219.3a5.754,5.754,0,0,0,.078.941A5.645,5.645,0,0,1,218.643,1215.536Z"
                transform="translate(-181.023 -1192.022)"
                fill="#656769"
              />
              <circle
                id="Ellipse_638"
                data-name="Ellipse 638"
                cx="5.643"
                cy="5.643"
                r="5.643"
                transform="translate(31.977 21.632)"
                fill="none"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <circle
                id="Ellipse_639"
                data-name="Ellipse 639"
                cx="3.292"
                cy="3.292"
                r="3.292"
                transform="translate(34.328 23.984)"
                fill="#daedf7"
              />
              <path
                id="Path_4796"
                data-name="Path 4796"
                d="M218.792,1218.036a3.293,3.293,0,0,1,3.155,2.351,3.292,3.292,0,1,0-6.311,0A3.294,3.294,0,0,1,218.792,1218.036Z"
                transform="translate(-181.172 -1192.171)"
                fill="#c0dceb"
              />
              <circle
                id="Ellipse_640"
                data-name="Ellipse 640"
                cx="3.292"
                cy="3.292"
                r="3.292"
                transform="translate(34.328 23.984)"
                fill="none"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <path
                id="Path_4797"
                data-name="Path 4797"
                d="M190.911,1198.229l-.976,4.232a.94.94,0,0,0,.916,1.152h2.11a.941.941,0,0,0,.917-.729l.976-4.232a.94.94,0,0,0-.916-1.152h-2.11A.941.941,0,0,0,190.911,1198.229Z"
                transform="translate(-179.649 -1191.061)"
                fill="#ff684a"
              />
              <g
                id="Group_2061"
                data-name="Group 2061"
                transform="translate(11.097 6.439)"
              >
                <path
                  id="Path_4798"
                  data-name="Path 4798"
                  d="M191.145,1198.911h3.7l.06-.259a.94.94,0,0,0-.916-1.152h-2.11a.939.939,0,0,0-.916.729l-.166.715A2.926,2.926,0,0,1,191.145,1198.911Z"
                  transform="translate(-190.799 -1197.5)"
                  fill="#ff866e"
                />
                <path
                  id="Path_4799"
                  data-name="Path 4799"
                  d="M191.145,1198.911h3.7l.06-.259a.94.94,0,0,0-.916-1.152h-2.11a.939.939,0,0,0-.916.729l-.166.715A2.926,2.926,0,0,1,191.145,1198.911Z"
                  transform="translate(-190.799 -1197.5)"
                  fill="#ff866e"
                />
              </g>
              <path
                id="Path_4800"
                data-name="Path 4800"
                d="M190.911,1198.229l-.976,4.232a.94.94,0,0,0,.916,1.152h2.11a.941.941,0,0,0,.917-.729l.976-4.232a.94.94,0,0,0-.916-1.152h-2.11A.941.941,0,0,0,190.911,1198.229Z"
                transform="translate(-179.649 -1191.061)"
                fill="none"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <path
                id="Path_4801"
                data-name="Path 4801"
                d="M187.2,1217.95a.94.94,0,0,1-1.186-1.46l2.189-1.779a.941.941,0,1,1,1.187,1.46Z"
                transform="translate(-179.397 -1192.072)"
                fill="#daedf7"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <rect
                id="Rectangle_1085"
                data-name="Rectangle 1085"
                width="4.703"
                height="2.351"
                rx="1"
                transform="translate(4.232 24.309)"
                fill="#ff684a"
              />
              <path
                id="Path_4802"
                data-name="Path 4802"
                d="M187.262,1216.5h-2.822a.941.941,0,0,0-.941.94v.47a.937.937,0,0,0,.126.47.94.94,0,0,1,.814-.47h2.822a.94.94,0,0,1,.814.47.937.937,0,0,0,.126-.47v-.47A.941.941,0,0,0,187.262,1216.5Z"
                transform="translate(-179.268 -1192.191)"
                fill="#ff866e"
              />
              <rect
                id="Rectangle_1086"
                data-name="Rectangle 1086"
                width="4.703"
                height="2.351"
                rx="1"
                transform="translate(4.232 24.309)"
                fill="none"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <circle
                id="Ellipse_641"
                data-name="Ellipse 641"
                cx="1.411"
                cy="1.411"
                r="1.411"
                transform="translate(36.209 25.865)"
                fill="#ff6242"
              />
              <path
                id="Path_4803"
                data-name="Path 4803"
                d="M218.911,1219.566a1.408,1.408,0,0,1,1.222.705,1.411,1.411,0,1,0-2.443,0A1.409,1.409,0,0,1,218.911,1219.566Z"
                transform="translate(-181.291 -1192.29)"
                fill="#ff866e"
              />
              <circle
                id="Ellipse_642"
                data-name="Ellipse 642"
                cx="1.411"
                cy="1.411"
                r="1.411"
                transform="translate(36.209 25.865)"
                fill="none"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <rect
                id="Rectangle_1087"
                data-name="Rectangle 1087"
                width="4.702"
                height="2.351"
                rx="1"
                transform="translate(12.475 0.31) rotate(14.576)"
                fill="#ff684a"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <path
                id="Rectangle_1088"
                data-name="Rectangle 1088"
                d="M0,0H2.351a0,0,0,0,1,0,0V5.788a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V0A0,0,0,0,1,0,0Z"
                transform="translate(17.028 3.996) rotate(-75.416)"
                fill="#bf8256"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <path
                id="Path_4804"
                data-name="Path 4804"
                d="M197.322,1195.2a.94.94,0,1,1-1.82-.474l.876-3.368a.941.941,0,0,1,1.821.473Z"
                transform="translate(-179.98 -1190.654)"
                fill="#f0f0f0"
                stroke="#1E2938"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <path
              id="Path_4805"
              data-name="Path 4805"
              d="M160,1167.18a3.179,3.179,0,0,1,3.179-3.18h27.029a3.18,3.18,0,0,1,3.18,3.18v17.378a3.179,3.179,0,0,1-3.18,3.179h-7.154v4.76a1.321,1.321,0,0,1-2.255.935l-5.694-5.695H163.179a3.179,3.179,0,0,1-3.179-3.179Z"
              transform="translate(-160 -1164)"
              fill="#fff"
              stroke="#1E2938"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <circle
              id="Ellipse_643"
              data-name="Ellipse 643"
              cx="9.684"
              cy="9.684"
              r="9.684"
              transform="translate(6.792 2.31)"
              fill="#fccb44"
              stroke="#1E2938"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <g
              id="Path_4806"
              data-name="Path 4806"
              transform="translate(-161.192 -1164.555)"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path
                d="M180.029,1174.26a.94.94,0,1,0,.94-.94A.94.94,0,0,0,180.029,1174.26Z"
                stroke="none"
              />
              <path
                d="M 180.9694976806641 1173.319946289062 C 181.4886474609375 1173.319946289062 181.9100036621094 1173.7412109375 181.9100036621094 1174.260375976562 C 181.9100036621094 1174.780639648438 181.4886474609375 1175.200927734375 180.9694976806641 1175.200927734375 C 180.4503479003906 1175.200927734375 180.0290069580078 1174.780639648438 180.0290069580078 1174.260375976562 C 180.0290069580078 1173.7412109375 180.4503479003906 1173.319946289062 180.9694976806641 1173.319946289062 Z"
                stroke="none"
                fill="#1E2938"
              />
            </g>
            <g
              id="Path_4807"
              data-name="Path 4807"
              transform="translate(-160.774 -1164.555)"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path
                d="M173.008,1174.26a.94.94,0,1,0,.941-.94A.94.94,0,0,0,173.008,1174.26Z"
                stroke="none"
              />
              <path
                d="M 173.9485015869141 1173.319946289062 C 174.4685974121094 1173.319946289062 174.8889923095703 1173.7412109375 174.8889923095703 1174.260375976562 C 174.8889923095703 1174.780639648438 174.4685974121094 1175.200927734375 173.9485015869141 1175.200927734375 C 173.4293365478516 1175.200927734375 173.0079956054688 1174.780639648438 173.0079956054688 1174.260375976562 C 173.0079956054688 1173.7412109375 173.4293365478516 1173.319946289062 173.9485015869141 1173.319946289062 Z"
                stroke="none"
                fill="#1E2938"
              />
            </g>
            <path
              id="Path_4808"
              data-name="Path 4808"
              d="M182.2,1177.072a.88.88,0,1,0,.88-.88A.88.88,0,0,0,182.2,1177.072Z"
              transform="translate(-161.321 -1164.725)"
              fill="#ffaa54"
            />
            <path
              id="Path_4809"
              data-name="Path 4809"
              d="M170.966,1177.072a.88.88,0,1,0,.88-.88A.88.88,0,0,0,170.966,1177.072Z"
              transform="translate(-160.652 -1164.725)"
              fill="#ffaa54"
            />
            <path
              id="Path_4810"
              data-name="Path 4810"
              d="M175.179,1180.455a3.97,3.97,0,0,1,4.4,0"
              transform="translate(-160.903 -1164.939)"
              fill="none"
              stroke="#1E2938"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
