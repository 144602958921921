import { keyBy } from 'lodash';
import React from 'react';

import type { PeerFeedbackStatuses } from 'models';

import colors from 'styles/colors';

import { __ } from 'helpers/i18n';

import SimpleStatusesBlock from '../components/SimpleStatusesBlock';

type Props = {
  statuses: PeerFeedbackStatuses;
};

export default function PeerFeedbackStatusChart(props: Props) {
  const statuses = keyBy(props.statuses.values, value => value.label);

  const formattedStatuses = [
    {
      ...statuses['not_started'],
      label: __('Not started'),
      color: colors.pieChart.red,
    },
    {
      ...statuses['in_progress'],
      label: __('In progress'),
      color: colors.pieChart.yellow,
    },
  ];
  if (Object.keys(statuses).includes('not_completed')) {
    formattedStatuses.push({
      ...statuses['not_completed'],
      label: __('Not completed'),
      color: colors.pieChart.red,
    });
  }
  formattedStatuses.push({
    ...statuses['completed'],
    label: __('Shared'),
    color: colors.pieChart.green,
  });

  const overallProgressPercentage = statuses['completed'].percentage;

  return (
    <SimpleStatusesBlock
      statuses={formattedStatuses}
      title={__('Peer feedback')}
      overallProgressPercentage={overallProgressPercentage}
      subtitle={__('%1% completed', overallProgressPercentage.toString())}
    />
  );
}
