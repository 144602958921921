import React from 'react';

import { __ } from 'helpers/i18n';

import { Select } from 'components';

type Props = {
  selectedRating: string | undefined | null;
  ratings: Array<string>;
  onChange: (newRating: string | null) => void;
  isDanger?: boolean;
  ratingLabels?: {
    [key: string]: string;
  };
};

type Option = {
  label: string;
  value: string;
};

function ratingToSelectOption(
  rating: string,
  ratingLabels?: {
    [key: string]: string;
  }
): Option {
  return {
    label: (ratingLabels && ratingLabels[rating]) || rating,
    value: rating,
  };
}

export default function RatingSelect({
  selectedRating,
  ratings,
  ratingLabels,
  onChange,
  isDanger,
}: Props) {
  return (
    <Select
      options={ratings.map(rating =>
        ratingToSelectOption(rating, ratingLabels)
      )}
      value={
        !!selectedRating
          ? ratingToSelectOption(selectedRating, ratingLabels)
          : null
      }
      onChange={option =>
        !Array.isArray(option) && onChange(!!option ? option.value : null)
      }
      placeholder={__('Select a rating...')}
      isSearchable={false}
      isClearable
      noOptionsMessage={__(
        "This template doesn't define rating options for this question"
      )}
      isDanger={isDanger}
    />
  );
}
