import { __ } from 'helpers/i18n';

const getTranslations = () => ({
  attachFiles: __('Attach Files'),
  bold: __('Bold'),
  bullets: __('Bullets'),
  byte: __('Byte'),
  bytes: __('Bytes'),
  captionPlaceholder: __('Add a caption…'),
  code: __('Code'),
  heading1: __('Heading'),
  indent: __('Increase Level'),
  italic: __('Italic'),
  link: __('Link'),
  numbers: __('Numbers'),
  outdent: __('Decrease Level'),
  quote: __('Quote'),
  redo: __('Redo'),
  remove: __('Remove'),
  strike: __('Strikethrough'),
  undo: __('Undo'),
  unlink: __('Unlink'),
  url: __('URL'),
  urlPlaceholder: __('Copy/Paste the link to insert'),
  GB: __('GB'),
  KB: __('KB'),
  MB: __('MB'),
  PB: __('PB'),
  TB: __('TB'),
});

export default getTranslations;
