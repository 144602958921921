import React from 'react';

import type { CSSProperties } from 'react';

import { __ } from 'helpers/i18n';

import { HorizontalEmptyStateWithSvg } from 'components';

const svg = `
<svg width="247px" height="105px" viewBox="0 0 247 105" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0.05">
        <path d="M248.077759,105.213979 L0,105.213979 C9.04811223,76.5215522 18.1322887,60.357435 27.2525294,56.7216272 C40.9328904,51.2679157 66.7969473,64.7559342 85.9089806,64.7559342 C105.021014,64.7559342 115.872337,21.1582986 139.580555,21.1582986 C163.288773,21.1582986 169.646094,48.2535479 198.168885,48.2535479 C218.619677,48.2535479 221.123624,17.4403666 231.140236,0 L248.077759,0 L248.077759,105.213979 Z" id="Path" fill="#000000"></path>
    </g>
</svg>
`;

type Props = {
  onCreate: () => Promise<void>;
  description: string;
  actionText?: string;
  style?: CSSProperties;
  isDisabled?: boolean;
};

const ObjectiveCreatorWithSvg = ({
  onCreate,
  description,
  actionText,
  style,
  isDisabled,
}: Props) => {
  return (
    <HorizontalEmptyStateWithSvg
      svg={svg}
      description={description}
      style={style}
      action={{
        text: actionText || __('Create new objective'),
        onTrigger: onCreate,
        testableName: 'test-create-objective-button',
      }}
      isDisabled={isDisabled}
    />
  );
};

export default ObjectiveCreatorWithSvg;
