import { maxBy } from 'lodash';
import React from 'react';
import {
  Cell,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';

import type { GroupedAnswers } from '../index';
import type { BlockAnswer, ReviewerTag, User } from 'models';

import colors from 'styles/colors';

import { __ } from 'helpers/i18n';

import RevieweeCell from './RevieweeCell';
import ChartTooltip from './Tooltip';
import XAxisTick from './XAxisTick';
import { COLOR_HEXA, ChartColor } from './colors';

export type DataPoint = {
  rating: string;
  ratingIndex: number;
  index: number;
  count: number;
  authors: Array<User>;
  authorTags: Array<ReviewerTag>;
  type: 'peer' | 'reviewee';
};

type Props = {
  ratings: Array<string>;
  answers: Array<BlockAnswer>;
  colorIterator: () => ChartColor;
  groupedAnswers: GroupedAnswers;
};

export default function RatingChart({
  answers,
  ratings,
  groupedAnswers,
  colorIterator,
}: Props) {
  const colorName = colorIterator();
  const color = COLOR_HEXA[colorName];

  const revieweeAnswer = answers.find(
    answer => answer.authorRole === 'reviewee'
  );
  const nonRevieweeAnswers = answers.filter(
    answer => answer.authorRole !== 'reviewee'
  );
  // We display the name of reviewers if they are not all anonymous
  const nonAnonymousAnswers = nonRevieweeAnswers.filter(
    answer => answer.author.id !== 'anonymous'
  );
  const showAuthorDetails = nonAnonymousAnswers.length > 0;
  const { rating: revieweeRating, author: reviewee } = revieweeAnswer || {};

  const participantCount = answers.length;
  const peerCount = !!revieweeRating ? participantCount - 1 : participantCount;

  const data: Array<DataPoint> = ratings.map(rating => {
    let { count, authors, authorTags } = groupedAnswers[rating];

    if (!!revieweeAnswer && revieweeAnswer.rating === rating) {
      count--;
      // @ts-ignore TSFIXME: Fix strictNullChecks error
      authors = authors.filter(author => author.id !== reviewee.id);
      authorTags = authorTags.filter(authorTag => authorTag !== 'reviewee');
    }

    return {
      rating,
      ratingIndex: ratings.indexOf(rating),
      index: 0,
      count,
      authors,
      authorTags,
      type: 'peer',
    };
  });

  const MAX_RADIUS = 10000; // Radius is maximum when all participants voted the same rating
  const MIN_RADIUS = 100;
  // @ts-ignore TSFIXME: Fix strictNullChecks error
  const maxLocalNbVotes = maxBy(data, 'count').count; // Maximum number of votes received for one rating
  const maxChartRadius =
    peerCount > 0
      ? Math.round(MAX_RADIUS * (maxLocalNbVotes / peerCount))
      : MIN_RADIUS; // Maximum radius reached for this chart

  let revieweeDataPoint;

  if (revieweeRating) {
    revieweeDataPoint = {
      rating: revieweeRating,
      ratingIndex: ratings.indexOf(revieweeRating),
      index: 0,
      count: 1,
      type: 'reviewee',
    };
  }

  return (
    <div className="rating-chart">
      <ResponsiveContainer height={250} width="100%">
        <ScatterChart>
          <XAxis
            type="number"
            dataKey="ratingIndex"
            name={__('Rating')}
            interval={0}
            axisLine={false}
            tickLine={false}
            height={100}
            tickCount={ratings.length}
            padding={{ left: 80, right: 80 }}
            tick={props => (
              <XAxisTick
                {...props}
                ratings={ratings}
                data={data}
                peerCount={peerCount}
              />
            )}
          />
          <YAxis
            hide={true}
            type="number"
            dataKey="index"
            domain={[-0.5, 0.5]}
          />
          <ZAxis
            type="number"
            dataKey="count"
            name={__('Number of answers')}
            range={[MIN_RADIUS, maxChartRadius]}
            scale="linear"
          />
          <Tooltip
            isAnimationActive={false}
            content={props => (
              <ChartTooltip
                active={!!props.active}
                payload={props.payload}
                showAuthorDetails={showAuthorDetails}
              />
            )}
            cursor={false}
          />

          <Scatter
            data={data}
            line={{ stroke: colors.lightestGrey, strokeWidth: 3 }}
            name="Peer ratings"
          >
            {data.map(({ count }, index) => (
              <Cell
                key={`cell-${index}`}
                fill={count > 0 ? color : colors.lightestGrey}
              />
            ))}
          </Scatter>

          {!!revieweeDataPoint && (
            <Scatter
              data={[revieweeDataPoint]}
              name="Reviewee rating"
              shape={props => (
                <RevieweeCell cx={props.cx || 0} cy={props.cy || 0} />
              )}
            />
          )}
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
}
