import DOMPurify from 'dompurify';
import React, { CSSProperties } from 'react';

type Props = {
  componentType?: 'div' | 'span' | 'p';
  componentProps?: Object;
  html: string | null | undefined;
  style?: CSSProperties;
};

export default function RawHtml({
  componentType = 'span',
  componentProps,
  html,
  style,
}: Props) {
  // @ts-ignore TSFIXME: Fix strictNullChecks error
  return React.createElement(componentType, {
    style,
    dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(html || '') }, // The sanitization we are doing here is a backup, complete sanitization should be done in the backend.
    className: componentType === 'span' ? 'content' : '',
    ...componentProps,
  });
}
