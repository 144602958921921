import React from 'react';

import colors from '../styles/colors';

type GetColorParams = {
  isSuccess?: boolean;
  isWarning?: boolean;
  isDanger?: boolean;
};

type IndicatorProps = GetColorParams & {
  bordercolor: string;
  isSmall?: boolean;
  style?: Object;
};

const getColor = ({
  isSuccess,
  isWarning,
  isDanger,
}: GetColorParams): string => {
  if (isSuccess) return colors.greenSuccess;
  if (isWarning) return colors.orangeWarning;
  if (isDanger) return colors.redFuckup;
  return colors.lightGrey;
};

export default function Indicator({
  isSuccess,
  isWarning,
  isDanger,
  isSmall,
  bordercolor,
  style: propStyle,
}: IndicatorProps) {
  const size = isSmall ? '1em' : '1.5em';
  const style = {
    margin: '0.4rem',
    height: size,
    width: size,
    borderRadius: '50%',
    backgroundColor: getColor({ isSuccess, isWarning, isDanger }),
    border: `1px solid ${bordercolor}`,
    display: 'inline-block',
    ...propStyle,
  };

  return <span style={style} />;
}

Indicator.defaultProps = {
  bordercolor: 'white',
};
