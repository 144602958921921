import moment from 'moment';
import React, { type CSSProperties } from 'react';

import type { AvatarSize } from 'components/Avatar';

import { User } from 'models';

import { __ } from 'helpers/i18n';

import { Text } from 'components';
import { AvatarSubTitle, AvatarTitle } from 'components/Avatar';
import Avatar from 'components/Avatar';
import { Props as TextProps } from 'components/text/Text';

type Props = {
  user:
    | {
        fullName: string;
        jobTitle?: string | null | undefined;
        workStartDate?: string | null | undefined;
        avatarUrl: string;
      }
    | User;
  withJobTitle?: boolean;
  withWorkStartDate?: boolean;
  link?: string;
  size?: AvatarSize;
  style?: CSSProperties;
  textProps?: TextProps;
  isDisabled?: boolean;
  canUpload?: boolean;
  onUpload?: (base64image: string) => Promise<void>;
  onDelete?: () => Promise<void>;
};

export default function UserAvatar({
  user,
  withJobTitle = false,
  withWorkStartDate = false,
  link,
  size,
  style,
  textProps,
  isDisabled,
  canUpload,
  onUpload,
  onDelete,
}: Props) {
  return (
    <Avatar
      url={user.avatarUrl}
      size={size}
      style={style}
      additionalClassName="user-avatar"
      isDisabled={isDisabled}
      canUpload={canUpload}
      onUpload={onUpload}
      onDelete={onDelete}
    >
      <AvatarTitle>
        <Text {...textProps} linkTo={link}>
          {user.fullName}
        </Text>
      </AvatarTitle>

      {withJobTitle && !!user.jobTitle && (
        <AvatarSubTitle additionalClassName="user-avatar-job-title">
          {user.jobTitle}
        </AvatarSubTitle>
      )}

      {withWorkStartDate && !!user.workStartDate && (
        <AvatarSubTitle additionalClassName="user-avatar-work-start-date">
          {__('Arrived on %1', moment(user.workStartDate).format('ll'))}
        </AvatarSubTitle>
      )}
    </Avatar>
  );
}
