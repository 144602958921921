// @flow
import deMessages from './de.json';
import enMessages from './en.json';
import esMessages from './es.json';
import frMessages from './fr.json';
import itMessages from './it.json';
import nlMessages from './nl.json';

export type SupportedLocale = 'en' | 'fr' | 'it' | 'de' | 'nl' | 'es';

const messages = {
  en: enMessages,
  fr: frMessages,
  it: itMessages,
  de: deMessages,
  nl: nlMessages,
  es: esMessages,
};
export const supportedLocales: Array<SupportedLocale> = Object.keys(messages);
export function getGettextMessagesForLocale(locale: SupportedLocale) {
  if (!messages[locale]) {
    return getGettextMessagesForLocale('en');
  }

  // Cloning seems to be needed, because `messages[locale]` has a required empty key
  // I tried to remove it, be warned! :)
  return { ...messages[locale] };
}
