import React from 'react';

import { DraftSurveyCampaign, SurveyCampaign } from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import { MenuItem, Text, Tooltip } from 'components';

type Props = {
  campaign: SurveyCampaign | DraftSurveyCampaign;
  onAfterDuplicate?: () => void;
};

const DuplicateMenuItem = ({ campaign, onAfterDuplicate }: Props) => {
  const dispatch = useAppDispatch();

  const canDuplicate = can({
    perform: 'duplicate',
    on: campaign,
  });

  const duplicateCampaign = async () => {
    await dispatch(post(`survey/campaigns/${campaign.id}/duplicate`));
    if (onAfterDuplicate) onAfterDuplicate();
  };

  return (
    <Tooltip
      enabled={!canDuplicate}
      content={__(
        'As an entity admin, you can only duplicate campaigns created by you'
      )}
    >
      <MenuItem
        key="duplicate"
        disabled={!canDuplicate}
        onClick={duplicateCampaign}
        testClassName="test-survey-campaign-duplicate-action"
      >
        <Text>{__('Duplicate')}</Text>
      </MenuItem>
    </Tooltip>
  );
};

export default DuplicateMenuItem;
