import React, { CSSProperties, useState } from 'react';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import { Column, Columns, Link } from 'components';

import { noAnswerColor } from './SimplePieChart';

export type LegendItemData = {
  color: string;
  label: string | null;
  value: number;
};

type Props = {
  data: Array<LegendItemData>;
  style?: CSSProperties;
  onLegendItemClick: (label: string | null) => void;
};

type LegendItemProps = LegendItemData & {
  onClick: (label: string | null) => void;
};

const LegendItem = ({ color, label, onClick }: LegendItemProps) => {
  const [isHidden, setIsHidden] = useState(false);
  const isNoAnswer = label === null;
  const handleOnClick = () => {
    onClick(label);
    setIsHidden(!isHidden);
  };

  return (
    <div
      className={classNames('legend-item', {
        'opacity-30': isHidden,
      })}
    >
      <div
        className="legend-dot"
        style={{
          backgroundColor: isNoAnswer ? noAnswerColor : color,
        }}
      />

      <Link onClick={handleOnClick} hasInheritedColor>
        {isNoAnswer ? __('No answer') : label}
      </Link>
    </div>
  );
};

const Legend = ({ data, style, onLegendItemClick }: Props) => {
  const halfCount = Math.ceil(data.length / 2);
  const firstColumn = data.slice(0, halfCount);
  const secondColumn = data.slice(halfCount, data.length);

  return (
    <Columns style={style} additionalClassName="legend">
      <Column size={6}>
        {firstColumn.map(entry => (
          <LegendItem
            color={entry.color}
            label={entry.label}
            value={entry.value}
            onClick={onLegendItemClick}
            key={entry.label}
          />
        ))}
      </Column>
      <Column size={6}>
        {secondColumn.map(entry => (
          <LegendItem
            color={entry.color}
            label={entry.label}
            value={entry.value}
            onClick={onLegendItemClick}
            key={entry.label}
          />
        ))}
      </Column>
    </Columns>
  );
};

export default Legend;
