import moment from 'moment';
import React from 'react';

import type { UpdateReviewCycle } from '../../..';
import type { ReviewCycle } from 'models';

import { dateToDefaultDateStringFormat } from 'helpers/date';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import {
  Checkbox,
  Control,
  DatePicker,
  Field,
  Icon,
  Label,
  Testable,
  Text,
  Tooltip,
} from 'components';

import AutoAddDeadlineInput from './AutoAddDeadlineInput';

type Props = {
  reviewCycle: ReviewCycle;
  updateReviewCycle: UpdateReviewCycle;
  autoAddParticipantsEnabled: boolean | null | undefined;
};

export default function OneOneOneDeadlinesOptions({
  reviewCycle,
  updateReviewCycle,
  autoAddParticipantsEnabled,
}: Props) {
  const {
    dateReviewRevieweeEnd,
    dateReviewReviewerEnd,
    reviewerDeadlineAfterDays,
    revieweeDeadlineAfterDays,
  } = reviewCycle;

  const revieweeDeadlineDisplayed =
    reviewCycle.defaultTemplate?.hasSelfEvaluationEnabled !== false;
  const organization = useOrganization();

  if (autoAddParticipantsEnabled) {
    return (
      <React.Fragment>
        {revieweeDeadlineDisplayed && (
          <Field>
            <Label>{__('For reviewee')}</Label>
            <AutoAddDeadlineInput
              // @ts-ignore TSFIXME
              name="revieweeDeadlineAfterDays"
              value={revieweeDeadlineAfterDays}
              min={1}
              max={reviewerDeadlineAfterDays || 365}
              onChange={(value: number | null | undefined) =>
                updateReviewCycle({ revieweeDeadlineAfterDays: value })
              }
              testClassName="test-auto-add-reviewee-deadline-input"
            />
          </Field>
        )}

        <Field>
          <Label>{__('For reviewer')}</Label>
          <AutoAddDeadlineInput
            // @ts-ignore TSFIXME
            name="reviewerDeadlineAfterDays"
            value={reviewerDeadlineAfterDays}
            min={revieweeDeadlineAfterDays || 1}
            max={365}
            onChange={(value: number | null | undefined) =>
              updateReviewCycle({ reviewerDeadlineAfterDays: value })
            }
            testClassName="test-auto-add-reviewer-deadline-input"
          />
        </Field>
      </React.Fragment>
    );
  }
  let revieweeLimits: {
    minDate: Date;
    maxDate?: Date;
  } = {
    minDate: moment().add(1, 'days').toDate(),
  };

  if (!!dateReviewReviewerEnd) {
    revieweeLimits = {
      ...revieweeLimits,
      maxDate: new Date(dateReviewReviewerEnd),
    };
  }

  return (
    <React.Fragment>
      {revieweeDeadlineDisplayed && (
        <Field>
          <Label>{__('Reviewee deadline:')}</Label>
          <Testable name="test-reviewee-deadline-picker">
            <Control
              isClearable={!!dateReviewRevieweeEnd}
              onClear={() => updateReviewCycle({ dateReviewRevieweeEnd: null })}
            >
              <DatePicker
                style={{ minWidth: 400 }}
                {...revieweeLimits}
                value={
                  !!dateReviewRevieweeEnd
                    ? new Date(dateReviewRevieweeEnd)
                    : null
                }
                onChange={date =>
                  updateReviewCycle({
                    // @ts-ignore TSFIXME: Fix strictNullChecks error
                    dateReviewRevieweeEnd: dateToDefaultDateStringFormat(date),
                  })
                }
              />
            </Control>
          </Testable>
        </Field>
      )}

      <Field>
        <Label>{__('Reviewer deadline:')}</Label>
        <Testable name="test-reviewer-deadline-picker">
          <Control
            isClearable={!!dateReviewReviewerEnd}
            onClear={() => updateReviewCycle({ dateReviewReviewerEnd: null })}
          >
            <DatePicker
              style={{ minWidth: 400 }}
              minDate={(dateReviewRevieweeEnd
                ? moment(dateReviewRevieweeEnd)
                : moment().add(1, 'days')
              ).toDate()}
              value={
                !!dateReviewReviewerEnd ? new Date(dateReviewReviewerEnd) : null
              }
              onChange={date =>
                updateReviewCycle({
                  // @ts-ignore TSFIXME: Fix strictNullChecks error
                  dateReviewReviewerEnd: dateToDefaultDateStringFormat(date),
                })
              }
            />
          </Control>
        </Testable>
      </Field>

      {organization.aiFeaturesEnabled && (
        <Field>
          <div className="mb-2">
            <Text preset="18bs5" additionalClassName="is-spaced">
              {__('Categorization with Elevo AI')}
              <Tooltip
                content={
                  <div>
                    <Text>
                      {__(
                        'Elevo AI seeks to identify interviews that are most likely to require your attention.'
                      )}
                    </Text>
                    <br />
                    <Text>
                      {__('Categorization is only visible to administrators.')}
                    </Text>
                  </div>
                }
              >
                <Icon size="small" additionalClassName="ml-2" name="info" />
              </Tooltip>
            </Text>
          </div>
          <Checkbox
            isChecked={reviewCycle.reviewCategorizationEnabled}
            size="small"
            onChange={isChecked =>
              updateReviewCycle({
                reviewCategorizationEnabled: isChecked,
              })
            }
            label={__('Enable review categorization')}
          />
        </Field>
      )}
    </React.Fragment>
  );
}
