import React from 'react';

import { __ } from 'helpers/i18n';

import { Text } from 'components';
import RichTextEditor from 'components/formElements/advancedElements/RichTextEditor';

type Props = {
  objectives: string;
  content: string;
};

const CourseContent = ({ objectives, content }: Props) => (
  <div>
    <Text preset="16bs5.5" additionalClassName="mb-4 block">
      {__('Training objectives')}
    </Text>
    <RichTextEditor value={objectives} disabled display />
    <hr />
    <Text preset="16bs5.5" additionalClassName="mb-4 block">
      {__('Training content')}
    </Text>
    <RichTextEditor value={content} disabled display />
  </div>
);

export default CourseContent;
