import type { AppDispatch } from 'redux/actions/types';

import { trackAction } from 'helpers/tracking';

export type RawHtmlMessage = {
  __html: string;
};
export type NoticeMessage = RawHtmlMessage | string;

export type Notice = {
  kind: 'error' | 'success' | 'warning';
  message: NoticeMessage;
};

export type ApplicationAction =
  | {
      type: 'SET_NOTICE';
      notice: Notice;
    }
  | {
      type: 'UNSET_NOTICE';
    }
  | {
      type: 'BLOCKING_MESSAGE';
      message: string;
    };

export const unsetNotice = () =>
  ({ type: 'UNSET_NOTICE' } as ApplicationAction);

export const errorNotice =
  (message: NoticeMessage) => async (dispatch: AppDispatch) => {
    trackAction('error_notice_dispatched', {
      message: typeof message === 'string' ? message : message.__html,
    });
    return dispatch({
      type: 'SET_NOTICE',
      notice: { kind: 'error', message },
    });
  };

export const htmlErrorNotice = (html: string) => errorNotice({ __html: html });

export const blockingMessage = (message: string) =>
  ({
    type: 'BLOCKING_MESSAGE',
    message,
  } as ApplicationAction);

const noticeWithoutActionTracking = (notice: Notice) =>
  ({
    type: 'SET_NOTICE',
    notice,
  } as ApplicationAction);

export const successNotice = (message: NoticeMessage) =>
  noticeWithoutActionTracking({ kind: 'success', message });
export const warningNotice = (message: NoticeMessage) =>
  noticeWithoutActionTracking({ kind: 'warning', message });

export const htmlSuccessNotice = (html: string) =>
  successNotice({ __html: html });

export const htmlWarningNotice = (html: string) =>
  warningNotice({ __html: html });
