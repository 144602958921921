import React from 'react';

import type { InlineEditableSharedProps } from './TextInput';

import EditorHowTo from './EditorHowTo';
import InlineEditableWrapper from './InlineEditableWrapper';
import TextareaRenderer, {
  type AvailableTextareaProps,
} from './editedRenderers/TextareaRenderer';

type Props = InlineEditableSharedProps<string> & {
  textareaProps?: AvailableTextareaProps;
  placeholder?: string;
};

export default function InlineEditableTextarea({
  value,
  onSubmit,
  renderNotEdited,
  withEditIcon,
  textareaProps,
  autoFocus,
  placeholder,
  hasError,
  hasWarning,
  isDisabled,
  onBlur,
  onFocus,
}: Props) {
  return (
    <InlineEditableWrapper
      value={value}
      isDisabled={isDisabled}
      onSubmit={onSubmit}
      renderEdited={props => (
        <div>
          <TextareaRenderer
            {...props}
            placeholder={placeholder}
            {...textareaProps}
          />
          {textareaProps && textareaProps.richText && <EditorHowTo />}
        </div>
      )}
      renderNotEdited={renderNotEdited}
      withEditIcon={!!withEditIcon}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      hasError={hasError}
      hasWarning={hasWarning}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
}
