import React from 'react';

import type {
  SkillsUserCareerLevel,
  User,
  UserCareer,
  UserCareerLevelType,
} from 'models';

import can from 'helpers/can';
import { useActiveUser, useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { htmlSuccessNotice } from 'redux/actions';
import { post, put } from 'redux/actions/api';

import CurrentCareerLevelManagementBox from './CurrentCareerLevelManagementBox';
import NextCareerLevelManagementBox from './NextCareerLevelManagementBox';

type Props = {
  user: User;
  userCareer: UserCareer;
  refetchData: () => Promise<void>;
};

type UpdateAttributes = {
  levelType: UserCareerLevelType;
  levelId: string | null;
};

export type ManagementBoxProps = {
  careerLevel: SkillsUserCareerLevel | null;
  userFullName: string;
  canUpdate: boolean;
  isActiveUser: boolean;
  performUpdate: (attributes: UpdateAttributes) => Promise<void>;
};

const successMessage = (levelType: UserCareerLevelType, user: User) =>
  levelType === 'current'
    ? __('The level of %1 has been successfully updated.', user.fullName)
    : __(
        'The career progression of %1 has been successfully updated.',
        user.fullName
      );

const LevelsManagementSection = ({ user, refetchData, userCareer }: Props) => {
  const activeUser = useActiveUser();
  const dispatch = useAppDispatch();
  const canUpdate = can({
    perform: 'update_career',
    on: user,
  });

  const performUpdate = async (attributes: UpdateAttributes): Promise<void> => {
    const careerLevel = userCareer[`${attributes.levelType}CareerLevel`];
    const params = {
      ...attributes,
      levelId: attributes.levelId || '',
    };

    await dispatch(
      !!careerLevel
        ? put(`skills/user_career_levels/${careerLevel.id}`, params)
        : post('skills/user_career_levels', { ...params, userId: user.id })
    );

    await refetchData();

    return dispatch(
      htmlSuccessNotice(successMessage(attributes.levelType, user))
    );
  };

  const commonProps = {
    userFullName: user.fullName,
    isActiveUser: activeUser.id === user.id,
    canUpdate,
    performUpdate,
  };

  // The idea is:
  // - I can always see my current level. I can modify it only when I'm admin or superior.
  // - I can see my next level only if I have a defined skills level, or if I'm an admin or superior.
  const showNextLevelManagementBox =
    !!userCareer.nextCareerLevel?.skillsLevel ||
    can({
      perform: 'update_career',
      on: user,
    });

  return (
    <React.Fragment>
      <CurrentCareerLevelManagementBox
        careerLevel={userCareer.currentCareerLevel}
        {...commonProps}
      />
      {showNextLevelManagementBox && (
        <NextCareerLevelManagementBox
          careerLevel={userCareer.nextCareerLevel}
          {...commonProps}
        />
      )}
    </React.Fragment>
  );
};

export default LevelsManagementSection;
