import React, { CSSProperties, ComponentProps, ReactNode } from 'react';
import ReactSelect, { components } from 'react-select';
import AsyncReactSelect from 'react-select/async';
import ReactSelectCreatable from 'react-select/creatable';

import { opacify } from 'styles';
import colors from 'styles/colors';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import { Icon, MaterialIconName } from 'components';

export interface IOption<T = unknown> {
  value: T;
  label: string;
  icon?: ReactNode;
}

type GroupedOption<Option> = {
  label: string;
  options: Option[];
};

type ActionTypes =
  | 'clear'
  | 'create-option'
  | 'deselect-option'
  | 'pop-value'
  | 'remove-value'
  | 'select-option'
  | 'set-value';
export type Action = { action: ActionTypes };

export type CurrentValue<Option extends IOption, IsMulti extends Boolean> =
  | (IsMulti extends true ? Option[] : Option)
  | null
  | undefined;

type Props<Option extends IOption, IsMulti extends boolean> = {
  value?: CurrentValue<Option, IsMulti>;
  options?: Option[] | GroupedOption<Option>[];
  onChange: (
    value: CurrentValue<Option, IsMulti>,
    action: Action
  ) => Promise<any> | any;
  onInputChange?: (value: string) => string;
  isClearable?: boolean;
  placeholder?: string | number;
  styles?: CSSProperties;
  isSearchable?: boolean;
  isAsync?: boolean;
  isDisabled?: boolean;
  captureMenuScroll?: boolean;
  closeMenuOnScroll?: () => boolean;
  maxMenuHeight?: number;
  isMulti?: IsMulti;
  autoFocus?: boolean;
  onBlur?: () => any;
  onFocus?: () => void;
  isCreatable?: boolean;
  formatCreateLabel?: (value: string) => string;
  isLoading?: boolean;
  loadingMessage?: () => string;
  inModal?: boolean;
  noOptionsMessage?: string;
  isDanger?: boolean;
  components?: any;
  controlIconName?: MaterialIconName;
  cacheOptions?: boolean;
  defaultOptions?: Array<Object> | boolean;
  loadOptions?: (
    search: string,
    callback: (options: any[]) => void
  ) => Promise<any> | void;
  onCreateOption?: (option: string) => any;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => string;
  useWidthFromLongestLabel?: boolean;
  additionalClassName?: string;
  testClassName?: string;
  hideSelectedOptions?: boolean;
  isOptionDisabled?: (option: Option, selectValue: Option[]) => boolean;
};

const styleOverrides: any = {
  menuPortal: menuPortalStyles => ({
    ...menuPortalStyles,
    zIndex: null, //prevent the portal from overriding /client/src/styles/components/_Select.sass
  }),
  control: (controlStyles, state) => ({
    ...controlStyles,
    ...(state.isFocused
      ? {
          boxShadow: `0 0 0 0.125em ${opacify(colors.primaryColor, 0.25)}`,
          borderColor: colors.primaryColor,
          ':hover': {
            boxShadow: `0 0 0 0.125em ${opacify(colors.primaryColor, 0.25)}`,
            borderColor: colors.primaryColor,
          },
        }
      : {}),
  }),
  option: (optionStyles, state) => ({
    ...optionStyles,
    ...(state.isSelected
      ? {
          backgroundColor: colors.primaryColor,
        }
      : state.isFocused
      ? {
          backgroundColor: opacify(colors.primaryColor, 0.25),
        }
      : {}),
  }),
};

type State = {
  isLoading: boolean;
};

const CustomOption = ({
  children,
  data,
  ...props
}: ComponentProps<typeof components.Option> & { data: { icon?: string } }) => {
  return (
    <components.Option {...props} data={data}>
      {data.icon && <span className="mr-2">{data.icon}</span>}
      {children}
    </components.Option>
  );
};

const CustomDropdownIndicator = (
  props: ComponentProps<typeof components.DropdownIndicator>
) => (
  <components.DropdownIndicator {...props}>
    <Icon name="keyboard_arrow_down" />
  </components.DropdownIndicator>
);
const CustomClearIndicator = (
  props: ComponentProps<typeof components.ClearIndicator>
) => (
  <components.ClearIndicator {...props}>
    <Icon name="close" />
  </components.ClearIndicator>
);

const Control = ({
  children,
  ...props
}: ComponentProps<typeof components.Control> & {
  selectProps: { controlIconName?: MaterialIconName };
}) => {
  const { controlIconName } = props.selectProps;

  return (
    <components.Control {...props}>
      {controlIconName && (
        <div className="flex ml-2 mr-0">
          <Icon name={controlIconName} />
        </div>
      )}
      {children}
    </components.Control>
  );
};

export class Select<
  Option extends IOption,
  IsMulti extends boolean = false
> extends React.Component<Props<Option, IsMulti>, State> {
  state = {
    isLoading: false,
  };

  handleChange = async (
    option: CurrentValue<Option, IsMulti>,
    action: Action
  ) => {
    const { onChange } = this.props;

    if (!option) return onChange(null, { action: 'clear' });

    this.setState({ isLoading: true });
    await onChange(option, action);
    this.setState({ isLoading: false });
  };

  isLoading = () => this.props.isLoading || this.state.isLoading;
  isDisabled = () => this.props.isDisabled || this.isLoading();

  render() {
    const {
      isClearable,
      isCreatable,
      isAsync,
      styles,
      inModal,
      noOptionsMessage,
      isDanger,
      value,
      components,
      useWidthFromLongestLabel,
      additionalClassName,
      testClassName,
      ...otherProps
    } = this.props;

    let ComponentType = ReactSelect;
    if (isCreatable) ComponentType = ReactSelectCreatable;
    if (isAsync) ComponentType = AsyncReactSelect;

    const className = classNames('Select', isDanger && 'is-danger');
    const classNamePrefix = classNames({
      SelectInModalSub: inModal,
      SelectSub: !inModal,
    });

    const icon = Array.isArray(value) ? null : value?.icon;

    const selectComponent = (
      <ComponentType
        {...otherProps}
        // @ts-ignore Used by Control
        icon={icon}
        value={value}
        components={{
          Control,
          Option: CustomOption,
          DropdownIndicator: CustomDropdownIndicator,
          ClearIndicator: CustomClearIndicator,
          ...components,
        }}
        isClearable={isClearable}
        isLoading={this.isLoading()}
        isDisabled={this.isDisabled()}
        escapeClearsValue={isClearable}
        backspaceRemovesValue={false}
        className={classNames(className, additionalClassName, testClassName)}
        classNamePrefix={classNamePrefix}
        menuPortalTarget={window.document.body}
        // @ts-ignore We should use `readonly Option[]`, but Array.isArray does not support it https://github.com/microsoft/TypeScript/issues/17002
        onChange={this.handleChange}
        noOptionsMessage={({ inputValue }: { inputValue: string }) =>
          noOptionsMessage || __('No result for "%1"', inputValue)
        }
        styles={{
          ...styleOverrides,
          ...styles,
        }}
      />
    );

    if (useWidthFromLongestLabel && otherProps.options) {
      const longestLabel = otherProps.options.sort((a, b) =>
        a.label.length > b.label.length ? -1 : 1
      )[0].label.length;

      return (
        <div style={{ width: `${longestLabel + 2}ch` }}>{selectComponent}</div>
      );
    }
    return selectComponent;
  }
}
