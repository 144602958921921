import { RecurrentSurveyCampaign } from 'models';

import { n__ } from 'helpers/i18n';

export const humanizeFrequency = (
  recurrenceType: RecurrentSurveyCampaign['recurrenceType'],
  recurrenceValue: RecurrentSurveyCampaign['recurrenceValue']
) => {
  if (recurrenceType === 'daily')
    return n__('Daily', 'Every %1 days', recurrenceValue);
  if (recurrenceType === 'weekly')
    return n__('Weekly', 'Every %1 weeks', recurrenceValue);
  if (recurrenceType === 'monthly')
    return n__('Monthly', 'Every %1 months', recurrenceValue);
};
