import React from 'react';

import type { UserObjectivePeriod } from 'models';

import { __ } from 'helpers/i18n';
import { pathToUserObjectiveHistory } from 'helpers/navigation';

import {
  BoxList,
  BoxListItem,
  Column,
  Columns,
  Date,
  FeatureFlagged,
  Text,
  Tooltip,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

type Props = {
  userObjectivePeriods: Array<UserObjectivePeriod>;
};

const UsersList = ({ userObjectivePeriods }: Props) => {
  return (
    <BoxList>
      <BoxListItem>
        <Columns isMobile>
          <Column size={3} ellipsisOnLineOverflow isVerticallyCentered>
            <Text preset="14bs6" color="light">
              {__('Employee')}
            </Text>
          </Column>
          <Column size={2} isVerticallyCentered>
            <Text preset="14bs6" color="light">
              {__('Average completion')}
            </Text>
          </Column>
          <Column size={2} isVerticallyCentered>
            <Text preset="14bs6" color="light">
              {__('Number of objectives')}
            </Text>
          </Column>
          <Column ellipsisOnLineOverflow isVerticallyCentered>
            <Text preset="14bs6" color="light">
              {__('Period')}
            </Text>
          </Column>
          <Column ellipsisOnLineOverflow isVerticallyCentered>
            <Text preset="14bs6" color="light">
              {__('Last update')}
            </Text>
          </Column>
        </Columns>
      </BoxListItem>

      {userObjectivePeriods.map(userObjectivePeriod => {
        const showMissingCompletionTooltip =
          userObjectivePeriod.averagePersonalCompletion === null &&
          userObjectivePeriod.objectivesCount !== 0;

        return (
          <BoxListItem key={userObjectivePeriod.id}>
            <Columns isMobile>
              <Column size={3} ellipsisOnLineOverflow isVerticallyCentered>
                <UserAvatar
                  user={userObjectivePeriod.user}
                  link={pathToUserObjectiveHistory(userObjectivePeriod.user.id)}
                  withJobTitle
                />
              </Column>
              <Column size={2} isVerticallyCentered>
                <FeatureFlagged
                  flag="draftObjectives"
                  render={draftObjectivesEnabled => (
                    <Tooltip
                      style={{ maxWidth: 400 }}
                      content={
                        draftObjectivesEnabled && showMissingCompletionTooltip
                          ? __(
                              'The average completion cannot be computed because all the objectives are not published or because their weights are invalid.'
                            )
                          : null
                      }
                    >
                      <Text>
                        {userObjectivePeriod.averagePersonalCompletion !== null
                          ? __(
                              '%1%',
                              userObjectivePeriod.averagePersonalCompletion
                            )
                          : '-'}
                      </Text>
                    </Tooltip>
                  )}
                />
              </Column>
              <Column size={2} isVerticallyCentered>
                <Text>{userObjectivePeriod.objectivesCount}</Text>
              </Column>
              <Column ellipsisOnLineOverflow isVerticallyCentered>
                <Text>{userObjectivePeriod.name}</Text>
              </Column>
              <Column ellipsisOnLineOverflow isVerticallyCentered>
                <Text>
                  <Date value={userObjectivePeriod.updatedAt} format="LL" />
                </Text>
              </Column>
            </Columns>
          </BoxListItem>
        );
      })}
    </BoxList>
  );
};

export default UsersList;
