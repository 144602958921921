import React from 'react';

import type { AvailableCriterionKey, ReviewCycle } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, put } from 'redux/actions/api';

import {
  FetchContainer,
  Helper,
  StrictlySanitizedHtml,
  Testable,
  Text,
  Title,
} from 'components';

import AssignmentCriteria from './AssignmentCriteria';
import useAttributeNamePicker from './useAttributeNamePicker';

type Props = {
  reviewCycle: ReviewCycle;
};

type AfterDataLoaderProps = Props & DataLoaderProvidedProps;

const DynamicTemplatePicker = ({
  reviewCycle,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();
  const updateTemplateAssignmentAttributeName = (
    templateAssignmentAttributeName: AvailableCriterionKey
  ) =>
    dispatch(
      put(
        `review_cycles/${reviewCycle.id}/template_assignment_attribute_name`,
        {
          reviewCycle: { templateAssignmentAttributeName },
        }
      )
    );

  const { attributesCount, attributeNamePicker } = useAttributeNamePicker();

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        const {
          defaultTemplateAssignmentCriterion,
          dynamicTemplateAssignmentCriteria,
          templateAssignmentAttributeName,
          defaultTemplateCompatibleTemplates,
          interactionTypeCompatibleTemplates,
        } = reviewCycle;

        invariant(
          defaultTemplateAssignmentCriterion &&
            dynamicTemplateAssignmentCriteria &&
            interactionTypeCompatibleTemplates &&
            defaultTemplateCompatibleTemplates,
          'All these relationships are defined on this view'
        );

        return (
          <Testable name="test-dynamic-template-picker">
            <div className="mb-8">
              <Title size={5}>{__('Participant properties')}</Title>
              <p className="mb-2">
                {__(
                  'Select one of the %1 participant’s properties you would like to use to assign question templates',
                  attributesCount
                )}
              </p>

              {attributeNamePicker({
                attributeName: templateAssignmentAttributeName,
                updateAttributeName: updateTemplateAssignmentAttributeName,
              })}
            </div>

            {!!templateAssignmentAttributeName && (
              <React.Fragment>
                <Title size={5}>{__('Templates')}</Title>
                <Text additionalClassName="mb-2">
                  {__(
                    'Select a template to assign for each variation of the property among the participants. All templates must share the same options to be selected.'
                  )}
                </Text>

                <Helper additionalClassName="mt-2 mb-4">
                  <ul>
                    {!reviewCycle.visibleForReviewee && (
                      <li>
                        <StrictlySanitizedHtml
                          html={__(
                            'The review is not visible by the employee. <b>Templates where self-assessment or objectives are enabled will not be selectable and will not be visible.</b>'
                          )}
                        />
                      </li>
                    )}
                    <li>
                      {__(
                        'When the attribute above is not defined on a participant, the default template is used.'
                      )}
                    </li>
                  </ul>
                </Helper>

                <AssignmentCriteria
                  reviewCycleId={reviewCycle.id}
                  defaultTemplateAssignmentCriterion={
                    defaultTemplateAssignmentCriterion
                  }
                  dynamicTemplateAssignmentCriteria={
                    dynamicTemplateAssignmentCriteria
                  }
                  interactionTypeCompatibleTemplates={
                    interactionTypeCompatibleTemplates
                  }
                  defaultTemplateCompatibleTemplates={
                    defaultTemplateCompatibleTemplates
                  }
                />

                <Helper additionalClassName="mt-8">
                  <ul>
                    <li>
                      {__(
                        'The review templates are assigned when the review campaign is launched. If a participant property changes while the campaign is running, the template will stay unchanged.'
                      )}
                    </li>
                    <li>
                      {__(
                        'To change the template of a participant once the campaign is launched, you will have to remove the participant from the campaign and add it back '
                      )}
                    </li>
                  </ul>
                </Helper>
              </React.Fragment>
            )}
          </Testable>
        );
      }}
    />
  );
};

export default newDataLoader({
  fetch: ({ reviewCycle }: Props) =>
    get(`review_cycles/${reviewCycle.id}/review_template_assignment_criteria`),
  hydrate: {
    reviewCycle: {
      interactionTypeCompatibleTemplates: {},
      defaultTemplateCompatibleTemplates: {},
      defaultTemplateAssignmentCriterion: { template: {}, segment: {} },
      dynamicTemplateAssignmentCriteria: { template: {}, segment: {} },
    },
  },
})(DynamicTemplatePicker) as React.ComponentType<Props>;
