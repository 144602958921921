import React, { useState } from 'react';

import { __ } from 'helpers/i18n';

import { Button, Text, Textarea } from 'components';

type Props = {
  onSubmit: (messageContent: string) => Promise<unknown>;
};

const ChatRoomInput = ({ onSubmit }: Props) => {
  const [messageContent, setMessageContent] = useState('');

  const submit = async () => {
    await onSubmit(messageContent);
    setMessageContent('');
  };

  return (
    <div className="flex flex-col gap-4 w-full items-center">
      <div className="w-full">
        <Text preset="16bs5.5">{__('Answer')}</Text>
        <div className="my-4">
          <Textarea
            placeholder={__('Answer...')}
            onChange={messageContent => setMessageContent(messageContent)}
            value={messageContent}
          />
        </div>
      </div>
      <Button
        color={'primary'}
        onClick={submit}
        testClassName="test-confirm-button"
        disabled={!messageContent}
      >
        {__('Send')}
      </Button>
    </div>
  );
};

export default ChatRoomInput;
