import { union } from 'lodash';
import React from 'react';

import type { Answer, FeedbackableItem, LegacyQuestionBlock } from 'models';

import invariant from 'helpers/invariant';

import RatingResults from './RatingResults';

function getDefaultRatingsFromAnswers(answers: Array<Answer>): Array<string> {
  const answerWithRatings = answers.find(answer => !!answer.ratings);

  invariant(
    answerWithRatings && answerWithRatings.ratings,
    'Ratings must exists at this point'
  );

  return answerWithRatings.ratings;
}

type Props = {
  feedbackableItem: FeedbackableItem<LegacyQuestionBlock>;
};

export default function RatingGraph({ feedbackableItem }: Props) {
  const { answers } = feedbackableItem;

  const answersWithRating = answers.filter(answer => !!answer.rating);
  const userRatings = answersWithRating.map(
    answer => answer.rating
  ) as string[];

  // If the template is modified, some rating options may not be included in the default list.
  const defaultRatings = getDefaultRatingsFromAnswers(answers);

  const ratings: Array<string> = union(defaultRatings, userRatings);

  return (
    <RatingResults
      ratings={ratings}
      answers={answers.map(answer => ({
        rating: answer.rating,
        author: answer.author,
        authorTag: answer.authorTag,
        authorRole: answer.authorRole,
      }))}
    />
  );
}
