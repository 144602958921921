import React from 'react';

import store from 'config/store';

import { __ } from 'helpers/i18n';
import {
  pathToMatrixEditor,
  pathToMatrixEmployeesList,
  pathToMatrixList,
} from 'helpers/paths';

import { hydrateFromStore } from 'lib/dataLoader';

import { PageHeader } from 'components';

type Props = {
  matrixId: string;
};

export default function MatrixHeader({ matrixId }: Props) {
  const matrix = hydrateFromStore(
    store.getState().data,
    { resourceType: 'skillsMatrix', id: matrixId },
    {}
  );

  return (
    <PageHeader
      title={__(matrix?.title || '')}
      withBackButton
      backButtonProps={{
        target: pathToMatrixList(),
        children: __('Back to skills matrices'),
      }}
      tabItems={[
        {
          to: pathToMatrixEditor(matrixId),
          label: __('Levels and expectations'),
          labelPreset: '16bs5.5',
        },
        {
          to: pathToMatrixEmployeesList(matrixId),
          label: __(
            'Associated employees (%1)',
            matrix?.associatedUsersCount || '-'
          ),
          labelPreset: '16bs5.5',
        },
      ]}
    />
  );
}
