import React from 'react';

import type { InteractionType, Organization } from 'models';

import { __ } from 'helpers/i18n';
import { withOrganization } from 'helpers/withSessionProps';

import { Control, Input, Text, Title5 } from 'components';

import ThreeSixtyFeaturePromptModal from 'scenes/admin/components/ThreeSixtyFeaturePromptModal';
import StepContainer from 'scenes/components/Stepper/StepContainer';

type Props = {
  name: string;
  interactionType: InteractionType | undefined | null;
  goToNextStep: () => void;
  onContinueClick: (params: {
    name: string;
    interactionType: InteractionType | undefined | null;
  }) => void;
};

type AfterConnectProps = Props & {
  organization: Organization;
};

type State = {
  name: string;
  interactionType: InteractionType | undefined | null;
  newlySetInteractionType: boolean;
};

class Step1Name extends React.Component<AfterConnectProps, State> {
  state = {
    name: this.props.name,
    interactionType: this.props.interactionType,
    newlySetInteractionType: false,
  };

  onNameChange = (name: string) => this.setState({ name });
  onInteractionTypeChange = (
    interactionType: InteractionType | null | undefined
  ) => {
    this.setState({
      interactionType: interactionType,
      newlySetInteractionType: true,
    });
  };

  onContinueClick = () => {
    this.setState({ newlySetInteractionType: false });

    return this.props.onContinueClick({
      name: this.state.name,
      interactionType: this.state.interactionType,
    });
  };

  render() {
    const { name, interactionType, newlySetInteractionType } = this.state;
    const { organization } = this.props;

    return (
      <StepContainer
        onContinueClick={this.onContinueClick}
        canPassStep={!!name}
        stepName="name"
      >
        {!!interactionType && (
          <div>
            <Title5>{__('Campaign name')}</Title5>
            <Text>
              {__(
                'The review campaign name will be visible to all participants. It will be displayed in emails and in review lists.'
              )}
            </Text>

            <Control style={{ marginTop: 8 }}>
              <Input
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={newlySetInteractionType}
                name="review_cycle_name"
                placeholder={__('Performance Review Q2')}
                value={name}
                onChange={value => this.onNameChange(value || '')}
              />
            </Control>
          </div>
        )}

        {!organization.plan.is360Enabled && (
          <ThreeSixtyFeaturePromptModal
            isActive={interactionType === '360'}
            onClose={() => this.onInteractionTypeChange(null)}
          />
        )}
      </StepContainer>
    );
  }
}

export default withOrganization(Step1Name) as React.ComponentType<Props>;
