import React from 'react';

import type { Answer } from 'models';

import classNames from 'helpers/classNames';
import { useIsLargeScreen } from 'helpers/hooks';

import RatingSliderResultsWithNoneRating from '../../components/RatingSliderResultsWithNoneRating';
import ClosedAnswerAvatarsOnMobile from './ClosedAnswersAvatarsOnMobile';

type Props = {
  ratingOptions: string[];
  closedAnswers: Answer[];
  isOverallRating?: boolean;
};

const ClosedAnswers = ({
  ratingOptions,
  closedAnswers,
  isOverallRating = false,
}: Props) => {
  const isLargeScreen = useIsLargeScreen();

  if (!isLargeScreen)
    return (
      <div
        className={classNames('mb-4', {
          'flex flex-col mx-2 mt-2 mb-0': isOverallRating,
        })}
      >
        <ClosedAnswerAvatarsOnMobile
          closedAnswers={closedAnswers}
          isOverallRating={isOverallRating}
        />
      </div>
    );

  return (
    <RatingSliderResultsWithNoneRating
      ratingOptions={ratingOptions}
      closedAnswers={closedAnswers}
      hasWhiteRail={isOverallRating}
      marginTopSlider={isOverallRating ? 16 : 8}
      marginBottomSlider={isOverallRating ? 16 : 32}
      testClassName={isOverallRating ? 'test-overall-slider-result' : undefined}
    />
  );
};

export default ClosedAnswers;
