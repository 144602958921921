import React from 'react';

import type { InteractionType } from 'models';

import transformProps from 'helpers/transformProps';

import Step1Name from '../components/Step1Name';

type Props = {
  cycleType: string | undefined | null;
  createReviewCycleAndRedirect: (data: {
    name: string;
    interactionType: InteractionType | undefined | null;
    setupStepIndex: number;
  }) => Promise<void>;
};

function propsTransformer({ createReviewCycleAndRedirect, cycleType }: Props) {
  return {
    interactionType: cycleType,
    onContinueClick: async (params: {
      name: string;
      interactionType: InteractionType | undefined | null;
    }) => {
      await createReviewCycleAndRedirect({ ...params, setupStepIndex: 1 });
    },
  };
}

export default transformProps(propsTransformer)(
  Step1Name
) as React.ComponentType<Props>;
