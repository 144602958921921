import React, { Fragment } from 'react';

import pumpkin from './images/halloween/pumpkin.png';
import bee from './images/spring/bee.png';
import giftBox from './images/winter/gift-box.png';

type Props = {
  blow: boolean;
  seasonalTheme: string | undefined;
};

const AnimatedBlowItems = ({ blow, seasonalTheme }: Props) => {
  const backgroundImage = () => {
    switch (seasonalTheme) {
      case 'halloween':
        return pumpkin;
      case 'winter':
        return giftBox;
      case 'spring':
        return bee;
      default:
        return null;
    }
  };

  return (
    <div className="absolute top-1/2 left-1/2">
      {blow && (
        <Fragment>
          {Array.from({ length: 10 }).map((_, index) => (
            <div
              key={index}
              className="absolute w-5 h-5"
              style={{
                opacity: '0',
                backgroundImage: `url('${backgroundImage()}')`,
                backgroundSize: 'cover',
                transform: `translate(-50%, -50%)`,
                animation: `blow ${
                  Math.random() * 0.1 + 0.5
                }s ease-out forwards`,
                animationDelay: `${index * 0.1}s`,
              }}
            />
          ))}
        </Fragment>
      )}

      <style>
        {`@keyframes blow {
                0% {
                  transform: rotate(${Math.random() * 360}deg) scale(0.5);
                  opacity: 0;
                }
                80% {
                  opacity: 1;
                }
                100% {
                  transform: rotate(${Math.random() * 10}deg) translate(${
          Math.random() * 200 - 100
        }px, ${Math.random() * 200 + 50}px) scale(1.5);
                  opacity: 0;
                }
              }`}
      </style>
    </div>
  );
};

export default AnimatedBlowItems;
