import React from 'react';

import type { RenderEditedParams } from '../InlineEditableWrapper.js';
import type { TextareaColor } from 'components/types/textarea';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import { Control, Textarea } from 'components';

export type AvailableTextareaProps = {
  mergeTags?: Array<{
    trigger: string;
    tags: Array<{ name: string; tag: string }>;
  }>;
  richText?: boolean;
  hideSaveButton?: boolean;
  rows?: number;
  color?: TextareaColor;
  placeholder?: string;
  lineBreakHelperStyle?: 'block' | 'float' | 'none';
  maxLength?: number;
  onMaxLengthReached?: () => any;
  displayErrorMessage?: boolean;
};

type Props = RenderEditedParams<string> & AvailableTextareaProps;

export default function TextareaRenderer({
  submitIfChanged,
  isSubmitting,
  hasError,
  hasWarning,
  toggle,
  onChange,
  value,
  targetPosition,
  lineBreakHelperStyle = 'block',
  displayErrorMessage,
  ...textareaProps
}: Props) {
  const { richText, hideSaveButton } = textareaProps;
  const baseHelperClassName = classNames('help', 'has-text-right', {
    float: lineBreakHelperStyle === 'float',
  });

  return (
    <div className="inline-editable-textarea-renderer">
      <Control>
        <Textarea
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onBlur={() => submitIfChanged(value)}
          disabled={isSubmitting}
          onEnterKeyPress={() => submitIfChanged(value)}
          onEscapeKeyPress={toggle}
          value={value}
          onChange={onChange}
          {...(hideSaveButton
            ? null
            : { onSaveButtonClick: () => submitIfChanged(value) })}
          {...textareaProps}
          rows={textareaProps.rows || 1}
        />
      </Control>

      {hasError && displayErrorMessage && (
        <p className={classNames(baseHelperClassName, 'is-danger')}>
          {__(
            'Oops, it looks like we had trouble saving your change. Please try again (or contact support@elevo.fr if it persists)'
          )}
        </p>
      )}
      {!hasError &&
        displayErrorMessage &&
        !richText &&
        lineBreakHelperStyle !== 'none' && (
          <p className={classNames(baseHelperClassName, 'is-info')}>
            {__('line break: shift + return')}
          </p>
        )}
    </div>
  );
}
