import { sortBy } from 'lodash/collection';
import React, { CSSProperties } from 'react';

import type { UserFilterableFieldType } from 'models';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import { Link, Text } from 'components';

type Props = {
  fields: Array<UserFilterableFieldType>;
  style?: CSSProperties;
  onChange: (field: UserFilterableFieldType) => void;
  displaySectionTitle?: boolean;
};

export default function FilterTypeList({
  fields,
  style,
  onChange,
  displaySectionTitle,
}: Props) {
  const sortedFields = sortBy(fields, field => field.label.toLowerCase());

  return (
    <div className="filter-type-list" style={style}>
      {displaySectionTitle && <Text weight="bold">{__('All filters')}</Text>}
      {sortedFields.map((field, index) => (
        <div key={index}>
          <Link
            className={classNames('filter-type-list-item', {
              'filter-type-list-item-default-field': !field.custom,
              'filter-type-list-item-custom-field': field.custom,
            })}
            onClick={() => onChange(field)}
          >
            {field.label}
          </Link>
        </div>
      ))}
    </div>
  );
}
