import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { UserReview } from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import transformProps from 'helpers/transformProps';

import { post } from 'redux/actions/api';
import { AppDispatch } from 'redux/actions/types';

import { SavingStatusConnector, Toggleable } from 'components';

import ReleaseReviewModal from './ReleaseReviewModal';

export type ReleaseActionInfo = {
  isShown: boolean;
  isEnabled?: boolean;
  isModalActive: boolean;
  disabledReasons?: Array<string>;
  trigger?: () => void;
  refreshShareability: () => Promise<void>;
};

type Props = {
  render: (releaseAction: ReleaseActionInfo) => React.ReactNode;
  userReview: UserReview;
  refreshReview: () => Promise<void>;
};

type AfterTransformerProps = Props & {
  canRelease: boolean;
  successMessage: string;
};

type AfterConnectProps = AfterTransformerProps & {
  onRelease: () => Promise<void>;
  refreshShareability: () => Promise<void>;
};

function ReleaseAction({
  render,
  canRelease,
  onRelease,
  userReview,
  refreshShareability,
}: AfterConnectProps) {
  return (
    <SavingStatusConnector
      render={({ autoSaveStatus }) => {
        return (
          <Toggleable
            render={(isToggled, toggle) => {
              const isShown = canRelease;
              const isEnabled = isShown && autoSaveStatus === 'saved';
              const trigger = isEnabled ? toggle : undefined;

              return (
                <React.Fragment>
                  <ReleaseReviewModal
                    isActive={isToggled}
                    onShare={onRelease}
                    onClose={toggle}
                    // @ts-ignore: FIXME: OneOnOneUserReview expected but got UserReview
                    userReview={userReview}
                  />
                  {render({
                    isShown,
                    isEnabled,
                    isModalActive: isToggled,
                    trigger,
                    refreshShareability,
                  })}
                </React.Fragment>
              );
            }}
          />
        );
      }}
    />
  );
}

function propsTransformer({
  userReview,
}: Props): Omit<AfterTransformerProps, keyof Props> {
  const revieweeName =
    userReview && userReview.user && userReview.user.fullName;
  return {
    canRelease:
      !!userReview &&
      (can({
        perform: 'show_action_to_release',
        on: userReview,
      }) ||
        can({
          perform: 'show_secondary_action_in_review_to_release',
          on: userReview,
        })),
    successMessage: __(
      'Your comments have been successfully shared with %1.',
      revieweeName || ''
    ),
  };
}

function mapDispatchToProps(
  dispatch: AppDispatch,
  { userReview, refreshReview }: AfterTransformerProps
) {
  return {
    onRelease: async () => {
      await dispatch(post(`user_reviews/${userReview.id}/release`));
      await refreshReview();
    },
    refreshShareability: () => {},
  };
}

export default compose(
  transformProps(propsTransformer),
  connect(null, mapDispatchToProps)
)(ReleaseAction) as React.ComponentType<Props>;
