import React from 'react';

import type {
  DataLoaderProvidedProps,
  UserFilterChangeMethod,
} from 'lib/dataLoader';
import type { ThreeSixtyReviewCycleStats, UserFilterSegment } from 'models';

import compositeKey from 'helpers/compositeKey';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Column, Columns } from 'components';

import StatsContainer from 'scenes/admin/reviewCycles/Manage/Analytics/components/StatsContainer';

import CompletedFeedbackStats from './CompletedFeedbackStats';
import FeedbackProgressionChart from './FeedbackProgressionChart';
import GlobalProgressionChart from './GlobalProgressionChart';
import GlobalStatusChart from './GlobalStatusChart';
import PeerAnswerStats from './PeerAnswerStats';
import PeerFeedbackStatusChart from './PeerFeedbackStatusChart';
import RequestedFeedbackStats from './RequestedFeedbackStats';

type Props = {
  reviewCycleId: string;
  filter: { user: { [key: string]: boolean } };
  onFilterChange: (filter: { user: { [key: string]: boolean } }) => void;
  userFilter: UserFilterSegment | null;
  onUserFilterChange: UserFilterChangeMethod;
};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    stats: ThreeSixtyReviewCycleStats;
  };

function ThreeSixtyReviewCycleAnalytics({
  stats,
  isFetching,
  hasError,
  filter,
  onFilterChange,
  userFilter,
  onUserFilterChange,
}: AfterConnectProps) {
  return (
    <StatsContainer
      isFetching={isFetching}
      hasError={hasError}
      filter={filter}
      onFilterChange={onFilterChange}
      userFilter={userFilter}
      onUserFilterChange={onUserFilterChange}
      hasStats={!!stats}
      render={() => {
        return (
          <React.Fragment>
            <Columns>
              <Column size={6}>
                <GlobalStatusChart globalStatuses={stats.globalStatuses} />
              </Column>

              <Column size={6}>
                <PeerFeedbackStatusChart
                  statuses={stats.peerFeedbackStatuses}
                />
              </Column>
            </Columns>

            <GlobalProgressionChart series={stats.globalProgressionSeries} />

            <Columns>
              <Column size={6}>
                <RequestedFeedbackStats stats={stats.requestedFeedbackStats} />
              </Column>
              <Column size={6}>
                <CompletedFeedbackStats stats={stats.completedFeedbackStats} />
              </Column>
            </Columns>

            <Columns>
              <Column size={6}>
                <PeerAnswerStats stats={stats.peerAnswerStats} />
              </Column>
            </Columns>

            <FeedbackProgressionChart
              series={stats.feedbackProgressionSeries}
            />
          </React.Fragment>
        );
      }}
    />
  );
}

export default newDataLoader({
  fetch: ({ reviewCycleId, userFilter, filter }: Props) =>
    get(`review_cycles/${reviewCycleId}/stats`, {
      userFilter,
      filter,
    }),
  hydrate: {
    stats: {
      requestedFeedbackStats: {},
      completedFeedbackStats: {},
      peerAnswerStats: {},
      globalStatuses: {},
      peerFeedbackStatuses: {},
      globalProgressionSeries: {
        peersSelectedProgress: {},
        peersCompletedProgress: {},
        peersNominatedProgress: {},
        peersValidatedProgress: {},
        reviewReleasedProgress: {},
      },
      feedbackProgressionSeries: {
        completedFeedbackProgress: {},
        requestedFeedbackProgress: {},
      },
    },
  },
  cacheKey: ({ reviewCycleId, userFilter, filter }: Props) =>
    compositeKey({ reviewCycleId, userFilter, filter }),
})(ThreeSixtyReviewCycleAnalytics);
