import React from 'react';

import { __ } from 'helpers/i18n';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  Text,
} from 'components';

import clapSvg from 'scenes/components/svg/clapSvg';

type Props = {
  onConfirm: () => void;
  onClose: () => void;
};

const SuccessModal = ({ onConfirm, onClose }: Props) => (
  <ModalCard isActive onClose={onClose}>
    <ModalCardHead />
    <ModalCardBody>
      <div className="flex flex-col items-center gap-4">
        <img
          alt="clap"
          className="h-40 w-40 rounded-full mb-4"
          src={`data:image/svg+xml;base64,${window.btoa(clapSvg)}`}
        />
        <Text preset="24bs2">{__('Done!')}</Text>
        <Text preset="14s6">{__('Thank you for completing your eNPS.')}</Text>
      </div>
    </ModalCardBody>
    <ModalCardFooter>
      <Button color="primary" onClick={onConfirm}>
        {__('Close')}
      </Button>
    </ModalCardFooter>
  </ModalCard>
);

export default SuccessModal;
