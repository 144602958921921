import { sortBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { compose } from 'redux';

import type { ReviewCycle, ReviewTemplate } from 'models';

import { __ } from 'helpers/i18n/index';
import invariant from 'helpers/invariant';
import { pathToTemplateEdition, pathToTemplatePreview } from 'helpers/paths';

import { type DataLoaderProvidedProps } from 'lib/dataLoader';
import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer, Field, Link, Select, Testable } from 'components';

type Props = {
  onTemplateUpdate: (templateId: string) => Promise<void>;
  currentTemplateId: string | undefined | null;
  reviewCycle: ReviewCycle;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    reviewTemplates: Array<ReviewTemplate>;
  };

class TemplatePicker extends React.Component<AfterDataLoaderProps> {
  handleOnChange = option => {
    invariant(option && !Array.isArray(option), 'Template cannot be empty');
    if (option && option.value) {
      this.props.onTemplateUpdate(option.value);
    }
  };

  getOptions = () => {
    const { reviewTemplates } = this.props;

    return sortBy(reviewTemplates, rct => moment(rct.createdAt))
      .reverse()
      .map(rct => ({
        value: rct.id,
        label: rct.name,
      }));
  };

  getSelectedTemplate = () => {
    const { reviewTemplates, currentTemplateId } = this.props;
    return (
      reviewTemplates &&
      reviewTemplates.find(template => template.id === currentTemplateId)
    );
  };

  render() {
    const { isFetching, hasError, currentTemplateId, reviewTemplates } =
      this.props;

    return (
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => {
          const currentTemplate = reviewTemplates.find(
            template => template.id === currentTemplateId
          );

          return (
            <React.Fragment>
              <Field style={{ marginBottom: 8, marginTop: 8 }}>
                <div className="control">
                  <Select
                    value={
                      currentTemplate
                        ? {
                            value: currentTemplateId || '',
                            label: currentTemplate.name,
                          }
                        : null
                    }
                    onChange={this.handleOnChange}
                    options={this.getOptions()}
                    placeholder={__('Select a template')}
                    isClearable={false}
                    noOptionsMessage={__('No template available')}
                  />
                </div>
              </Field>
              <p>
                {currentTemplateId && (
                  <React.Fragment>
                    <Link
                      to={pathToTemplateEdition(currentTemplateId)}
                      openInNewTab
                    >
                      {__('Edit')}
                    </Link>

                    <Testable name="test-preview-link">
                      <Link
                        to={pathToTemplatePreview(
                          currentTemplateId,
                          'reviewee'
                        )}
                        openInNewTab
                        style={{ marginLeft: '8px' }}
                      >
                        {__('Preview')}
                      </Link>
                    </Testable>
                  </React.Fragment>
                )}
              </p>
            </React.Fragment>
          );
        }}
      />
    );
  }
}

export default compose(
  newDataLoader({
    fetch: ({ reviewCycle }: Props) => {
      return get(`review_cycles/${reviewCycle.id}/eligible_templates`);
    },
    hydrate: {
      reviewTemplates: {},
    },
  })
)(TemplatePicker) as React.ComponentType<Props>;
