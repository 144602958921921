import React, { useState } from 'react';

import type { OneOnOneUserReview, User } from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import {
  BulletList,
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';

import UserPicker from 'scenes/components/UserPicker';

type Props = {
  userReview: OneOnOneUserReview;
  onSubmit: (additionalReviewer: User | null | undefined) => Promise<void>;
  onClose: () => void;
  isActive: boolean;
};

const UpdateModal = ({ userReview, isActive, onSubmit, onClose }: Props) => {
  const [additionalReviewer, setAdditionalReviewer] = useState<
    User | undefined | null
  >(userReview.additionalReviewer);

  const disabledUserIds = () => {
    let ids: Array<string> = [];

    if (userReview.hasSelfEvaluationEnabled) {
      ids.push(userReview.user.id);
    }

    if (userReview.responsible) {
      ids.push(userReview.responsible.id);
    }

    return ids;
  };

  const editingAdditionalReviewer = !!userReview.additionalReviewer;

  return (
    <ModalCard
      isActive={isActive}
      isLarge
      onClose={onClose}
      refreshContentOnOpening={false}
    >
      <ModalCardHead onClose={onClose}>
        <ModalCardTitle>
          {editingAdditionalReviewer
            ? __('Edit or delete additional reviewer')
            : __('Add an additional reviewer')}
        </ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <Text>
          {__(
            'For an even more complete evaluation you can add an additional reviewer who will have the right to:'
          )}
        </Text>

        <BulletList
          items={[
            __('answer all the questions in the review'),
            __('sign the review'),
          ]}
          style={{ marginLeft: '24px', marginBottom: '24px' }}
        />

        {userReview.visibleForReviewee && (
          <Text weight="bold">
            {__(
              'Prior to sharing, the additional reviewer’s evaluation will be visible only to you. It will be shared with the reviewee when you share yours.'
            )}
          </Text>
        )}

        <UserPicker
          value={additionalReviewer}
          onChange={user => {
            invariant(
              !Array.isArray(user),
              "There shouldn't be more than one selected user."
            );

            setAdditionalReviewer(user);
          }}
          disabledUserIds={disabledUserIds()}
          style={{ margin: 8 }}
          isClearable={editingAdditionalReviewer}
          inModal
        />

        {editingAdditionalReviewer && (
          <div style={{ marginTop: 16 }}>
            <Text color="danger">
              {__(
                'If you delete or change the additional reviewer, their evaluation will no longer be visible in the review. It will not be transferred to the new additional reviewer.'
              )}
            </Text>
          </div>
        )}
      </ModalCardBody>
      <ModalCardFooter>
        <Button
          color={editingAdditionalReviewer ? 'danger' : 'primary'}
          disabled={editingAdditionalReviewer ? false : !additionalReviewer}
          onClick={() => onSubmit(additionalReviewer)}
          testClassName="test-confirm-button"
        >
          {editingAdditionalReviewer
            ? __('Edit the additional reviewer')
            : __('Add an additional reviewer')}
        </Button>

        <Button color="secondary" onClick={onClose}>
          {__('Cancel')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default UpdateModal;
