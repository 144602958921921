import { sortBy } from 'lodash';
import moment from 'moment';
import React from 'react';

import type { ImportedReview, User } from 'models';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Box, BoxList, DesignSystem, FetchContainer } from 'components';

import ImportedReviewItem from 'scenes/UserProfile/ImportedReviews/ImportedReviewItem';

type Props = {
  user: User;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    importedReviews: Array<ImportedReview>;
  };

function ImportedReviews({
  importedReviews,
  hasError,
  isFetching,
  user,
}: AfterDataLoaderProps) {
  const sortedImportedReviews = sortBy(importedReviews, importedReview =>
    moment(importedReview.reviewDate)
  ).reverse();

  return (
    <DesignSystem version={2}>
      <Box>
        <BoxList>
          <FetchContainer
            isFetching={isFetching}
            hasError={hasError}
            render={() =>
              sortedImportedReviews.map(importedReview => (
                <ImportedReviewItem
                  key={importedReview.id}
                  importedReview={importedReview}
                  user={user}
                />
              ))
            }
          />
        </BoxList>
      </Box>
    </DesignSystem>
  );
}

export default newDataLoader({
  fetch: ({ user }) => get(`/users/${user.id}/imported_reviews`),
  hydrate: {
    importedReviews: {
      abilities: {},
    },
  },
})(ImportedReviews) as React.ComponentType<Props>;
