import React, { Fragment } from 'react';

import { Match } from 'helpers/paths';

import { ContentContainer } from 'components';

import MatrixHeader from '../../components/SkillsMatrixHeader';
import EmployeesPaginatedList from './EmployeesPaginatedList';

type Props = {
  match: Match<{ id: string }>;
};

export default function MatrixEmployeesList({ match }: Props) {
  const matrixId = match.params.id;
  return (
    <Fragment>
      <MatrixHeader matrixId={matrixId} />
      <ContentContainer>
        <EmployeesPaginatedList matrixId={matrixId} paginationType="url" />
      </ContentContainer>
    </Fragment>
  );
}
