import React from 'react';

type Props = {
  children: React.ReactNode;
  delay: number;
};

type State = {
  shouldRender: boolean;
};

export default class DelayedRenderer extends React.Component<Props, State> {
  state = {
    shouldRender: false,
  };

  timeoutRef: ReturnType<typeof setTimeout> | null = null;

  componentDidMount() {
    this.timeoutRef = setTimeout(
      () => this.setState({ shouldRender: true }),
      this.props.delay
    );
  }

  componentWillUnmount() {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
  }

  render() {
    if (!this.state.shouldRender) return null;

    return this.props.children;
  }
}
