import React, { CSSProperties } from 'react';

import type { Organization } from 'models';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';
import { withOrganization } from 'helpers/withSessionProps';

import { Button, ClickAwayListener } from 'components';
import TrixEditor from 'components/TrixEditor';
import { Editor } from 'components/TrixEditor/TrixEditor';

type Props = {
  value: string | null | undefined;
  disabled?: boolean;
  display?: boolean;
  onChange?: (html: string, text: string) => void;
  onEditorReady?: (editor: Editor) => void;
  autoFocus?: boolean;
  placeholder?: string;
  allowImageUpload?: boolean;
  mergeTags?: Array<{
    trigger: string;
    tags: Array<{ name: string; tag: string }>;
  }>;
  onBlur?: () => void;
  onEscapeKeyPress?: () => any;
  onSaveButtonClick?: () => any;
  style?: CSSProperties;
  additionalClassName?: string;
  toolbarAlwaysVisible?: boolean;
  fixedTagSelector?: boolean;
};

type AfterConnectProps = Props & {
  organization: Organization;
};

type State = {
  isManagingFocus: boolean;
};

class RichTextEditor extends React.Component<AfterConnectProps, State> {
  state = { isManagingFocus: false };

  handleClickOutside = (_event: Event) => {
    const { onBlur } = this.props;
    if (this.state.isManagingFocus) {
      this.setState({
        isManagingFocus: false,
      });
      onBlur && onBlur();
    }
  };

  handleKeyDown = (e: KeyboardEvent) => {
    const { onEscapeKeyPress } = this.props;
    onEscapeKeyPress && e.keyCode === 27 && onEscapeKeyPress();
  };

  handleFocus = () => {
    if (!this.state.isManagingFocus) {
      this.setState({
        isManagingFocus: true,
      });
    }
  };

  render() {
    const {
      value,
      disabled,
      display,
      autoFocus,
      placeholder,
      allowImageUpload,
      mergeTags,
      onEditorReady,
      onChange,
      style,
      additionalClassName,
      onSaveButtonClick,
      toolbarAlwaysVisible,
      fixedTagSelector,
      organization,
    } = this.props;
    const featureFlagEnabled =
      organization.featureFlags.includes('richTextImages');

    return (
      <ClickAwayListener onClickAway={this.handleClickOutside}>
        <div
          className={classNames('trix-content content', additionalClassName, {
            'trix-disabled': disabled,
            'trix-focused': this.state.isManagingFocus,
            'trix-hide-toolbar':
              !toolbarAlwaysVisible && !this.state.isManagingFocus,
            'trix-display': display,
          })}
          onFocus={this.handleFocus}
          style={style}
        >
          <TrixEditor
            value={value === null ? undefined : value}
            onChange={(html, text) => onChange && onChange(html, text)}
            placeholder={placeholder}
            allowImageUpload={featureFlagEnabled && allowImageUpload}
            onEditorReady={editor => {
              if (disabled) {
                editor.element.contentEditable = 'false';
              } else {
                if (autoFocus) editor.element.focus();
                editor.element.addEventListener('keydown', this.handleKeyDown);
              }
              if (onEditorReady) {
                onEditorReady(editor);
              }
            }}
            mergeTags={mergeTags}
            fixedTagSelector={fixedTagSelector}
          />

          {this.state.isManagingFocus && onSaveButtonClick && (
            <Button
              color="primary"
              size="small"
              onClick={() => onSaveButtonClick()}
              additionalClassName="save-button no-link-icon"
            >
              {__('Save')}
            </Button>
          )}
        </div>
      </ClickAwayListener>
    );
  }
}

export default withOrganization(RichTextEditor);
