import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { Text } from 'components';
import Icon, { MaterialIconName } from 'components/Icon';

type TagIconProps = {
  name: MaterialIconName;
  onClick?: () => void;
  additionalClassName?: string;
};

const TagIcon = ({ name, onClick, additionalClassName }: TagIconProps) => {
  if (onClick) {
    return (
      <button onClick={onClick} className={additionalClassName}>
        <Icon name={name} />
      </button>
    );
  } else {
    return (
      <Icon
        name={name}
        onClick={onClick}
        additionalClassName={additionalClassName}
      />
    );
  }
};

type Props = {
  children: ReactNode;
  leftIcon?: TagIconProps;
  rightIcon?: TagIconProps;
  additionalClassName?: string;
  testClassName?: string;
};

const Tag = ({
  children,
  leftIcon,
  rightIcon,
  additionalClassName,
  testClassName,
}: Props) => {
  return (
    <Text
      preset="13s7"
      additionalClassName={classNames(
        'tag-elevo',
        additionalClassName,
        testClassName
      )}
    >
      {leftIcon && <TagIcon {...leftIcon} additionalClassName="mr-1" />}
      {children}
      {rightIcon && <TagIcon {...rightIcon} additionalClassName="ml-1" />}
    </Text>
  );
};

export default Tag;
