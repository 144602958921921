import React, { ReactNode } from 'react';

import { Icon, Text, Tooltip } from 'components';

type Props = {
  children: ReactNode;
};

export default function InfoTooltip({ children }: Props) {
  return (
    <Tooltip
      content={
        <Text size={7} additionalClassName="info-tooltip-content">
          {children}
        </Text>
      }
    >
      <Icon
        additionalClassName="info-tooltip-icon"
        size="tiny"
        style={{ verticalAlign: 'middle' }}
        name="help"
      />
    </Tooltip>
  );
}
