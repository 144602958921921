import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import type { ObjectiveCompletionDistribution } from 'models/ReviewCycleStats';

import { __ } from 'helpers/i18n';

import { Box, ChartTooltip, Title } from 'components';

const pinkOrange = '#faad94';

type Props = {
  distribution: ObjectiveCompletionDistribution;
};

export default function ObjectiveCompletionDistributionChart({
  distribution,
}: Props) {
  const data = distribution.data.map(dataPoint => ({
    ...dataPoint,
    rangeDataKey: dataPoint.range.max
      ? `${dataPoint.range.min}-${dataPoint.range.max}`
      : `${dataPoint.range.min}+`,
  }));

  return (
    <Box>
      <Title size={5}>{__('Average completion of reviewed objectives')}</Title>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={data} margin={{ left: -15, top: 20 }}>
          <CartesianGrid vertical={false} strokeDasharray="2 2" />
          <XAxis dataKey="rangeDataKey" unit="%" tick={{ fontSize: 'small' }} />
          <YAxis
            dataKey="value"
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 'small' }}
          >
            <Label
              value={__('Participants')}
              angle={-90}
              position="insideLeft"
              dx={15}
              style={{ textAnchor: 'middle', fontSize: 'small' }}
            />
          </YAxis>
          <Tooltip
            cursor={false}
            content={({ active, payload, label }) => {
              if (!active) return null;

              return (
                <ChartTooltip
                  values={[
                    {
                      color: pinkOrange,
                      label: label + '%',
                      value: payload?.[0]?.value,
                    },
                  ]}
                />
              );
            }}
          />
          <Bar dataKey="value" barSize={25} fill={pinkOrange} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
