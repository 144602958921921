import React from 'react';

type Props = {
  children?: React.ReactNode;
  fullWidth?: boolean;
  style?: React.CSSProperties;
};

export default function PullRight({ children, fullWidth, style = {} }: Props) {
  if (fullWidth) {
    style = {
      ...style,
      width: '100%',
    };
  }

  return (
    <div className="is-clearfix" style={style}>
      <div className="is-pulled-right">{children}</div>
    </div>
  );
}
