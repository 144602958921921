import React from 'react';

import { Box } from 'components';

import SimpleTable, { type Props } from './SimpleTable';

const SimpleBoxTable = <TRow,>({
  additionalClassName,
  ...props
}: Props<TRow>) => {
  return (
    <Box additionalClassName={additionalClassName}>
      <SimpleTable additionalClassName="-mx-4 -my-4" {...props} />
    </Box>
  );
};

export default SimpleBoxTable;
