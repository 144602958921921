const aiLogo = `<svg width="64" height="16" viewBox="0 0 84 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.7442 12.0559C49.7442 8.09289 47.8219 5.99279 44.4142 5.99279C41.0216 5.99279 39.0842 8.1021 39.0842 12.0559C39.0842 16.0147 41.0268 18.143 44.4142 18.143C47.8168 18.143 49.7442 16.0239 49.7442 12.0559ZM47.5736 12.0559C47.5736 14.8607 46.5172 16.1338 44.415 16.1338C42.3127 16.1338 41.2563 14.8607 41.2563 12.0559C41.2563 9.28711 42.3227 8.00197 44.415 8.00197C46.5073 8.00197 47.5736 9.28711 47.5736 12.0559Z" fill="url(#paint0_linear_5081_5986)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.6276 15.1573C24.7278 15.8338 24.0367 16.1263 22.9433 16.1263C20.9885 16.1263 19.7607 14.8836 19.6494 12.8372H26.3148C26.8015 12.8372 27.1534 12.6508 27.3573 12.3022C27.5082 12.0443 27.5646 11.7385 27.5767 11.3138C27.5793 11.2227 27.5798 11.1506 27.5798 10.9935C27.5798 9.9049 27.2941 8.76738 26.6764 7.86912C25.8607 6.68286 24.5445 6.00923 22.7041 6.00923C19.3952 6.00923 17.4698 8.24094 17.4698 12.0724C17.4698 16.0978 19.4279 18.1595 22.9433 18.1595C23.9452 18.1595 24.8341 17.9533 25.6023 17.5941C26.155 17.3357 26.6008 17.0179 26.8917 16.7263C27.1325 16.4848 27.2451 16.2803 27.2451 15.9562C27.2451 15.4017 26.7961 14.9516 26.2431 14.9516C26.002 14.9516 25.8534 15.0058 25.6276 15.1573ZM19.6718 10.8759C19.8988 9.03997 20.9535 8.01838 22.7042 8.01838C24.5532 8.01838 25.3696 9.04691 25.4498 10.8759H19.6718Z" fill="url(#paint1_linear_5081_5986)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.0244 0.279413C14.6186 0.279413 15.0982 0.770655 15.0982 1.35593V15.0452C15.0982 15.8374 15.2874 16.1023 15.7461 16.1023C16.3059 16.1023 16.772 16.5591 16.772 17.1309C16.772 17.7027 16.3059 18.1595 15.7461 18.1595C13.9529 18.1595 12.9507 17.0407 12.9507 15.0452V1.35593C12.9507 0.777522 13.4475 0.279413 14.0244 0.279413Z" fill="url(#paint2_linear_5081_5986)"/>
<path d="M3.16447 16.0544H9.98166C10.5586 16.0544 11.0315 16.5285 11.0315 17.1069C11.0315 17.6853 10.5586 18.1595 9.98166 18.1595H2.04293C1.4182 18.1595 0.921387 17.6614 0.921387 17.035V2.53067C0.921387 1.90431 1.4182 1.4062 2.04293 1.4062H9.71863C10.2955 1.4062 10.7684 1.88034 10.7684 2.45874C10.7684 3.03715 10.2955 3.51128 9.71863 3.51128H3.16447V8.67036H8.11654C8.69344 8.67036 9.16634 9.14449 9.16634 9.7229C9.16634 10.3013 8.69344 10.7754 8.11654 10.7754H3.16447V16.0544Z" fill="url(#paint3_linear_5081_5986)"/>
<path d="M55.0198 18.1341L59.458 6.00427H61.4218L65.7298 18.1341H63.8258L62.8058 15.2271H57.9438L56.9238 18.1341H55.0198ZM58.4538 13.7991H62.2958L60.3748 8.37606L58.4538 13.7991Z" fill="url(#paint4_linear_5081_5986)"/>
<path d="M67.3893 18.1341V6.01721H69.1913V18.1341H67.3893Z" fill="url(#paint5_linear_5081_5986)"/>
<path d="M72.2424 2.16541C72.8802 1.5665 73.3304 0.207062 73.3304 0.207062C73.3304 0.207062 73.779 1.56823 74.4184 2.16541C75.0085 2.71663 76.2679 3.09018 76.2679 3.09018C76.2679 3.09018 74.8683 3.52817 74.2552 4.17815C73.7141 4.75183 73.3304 5.97331 73.3304 5.97331C73.3304 5.97331 72.9231 4.70766 72.3512 4.12376C71.7593 3.51931 70.4473 3.09018 70.4473 3.09018C70.4473 3.09018 71.6676 2.70525 72.2424 2.16541Z" fill="url(#paint6_linear_5081_5986)"/>
<path d="M77.7848 6.13343C78.6908 5.28592 79.3303 3.36218 79.3303 3.36218C79.3303 3.36218 79.9676 5.28837 80.8759 6.13343C81.7142 6.91346 83.5033 7.44207 83.5033 7.44207C83.5033 7.44207 81.515 8.06187 80.644 8.98166C79.8754 9.79346 79.3303 11.522 79.3303 11.522C79.3303 11.522 78.7517 9.73095 77.9394 8.90468C77.0984 8.04932 75.2347 7.44207 75.2347 7.44207C75.2347 7.44207 76.9682 6.89736 77.7848 6.13343Z" fill="url(#paint7_linear_5081_5986)"/>
<path d="M73.7469 10.8877C74.0688 10.5826 74.296 9.89001 74.296 9.89001C74.296 9.89001 74.5224 10.5834 74.8451 10.8877C75.1429 11.1685 75.7785 11.3588 75.7785 11.3588C75.7785 11.3588 75.0721 11.5819 74.7627 11.913C74.4896 12.2053 74.296 12.8275 74.296 12.8275C74.296 12.8275 74.0904 12.1828 73.8019 11.8853C73.5031 11.5774 72.841 11.3588 72.841 11.3588C72.841 11.3588 73.4568 11.1627 73.7469 10.8877Z" fill="url(#paint8_linear_5081_5986)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M33.2285 14.4486L36.3658 6.75228C36.538 6.32056 36.8673 6.04256 37.3866 6.04256C37.9976 6.04256 38.4785 6.52472 38.4785 7.13729C38.4785 7.27271 38.4574 7.38335 38.4183 7.49756C38.4113 7.51802 38.3916 7.57151 38.3895 7.57809L34.2472 17.45C34.0483 17.8825 33.6732 18.1302 33.2285 18.1302C32.7839 18.1302 32.4087 17.8825 32.2141 17.4599L28.0714 7.58952C28.0654 7.57151 28.0457 7.51802 28.0387 7.49756C27.9996 7.38335 27.9785 7.27271 27.9785 7.13729C27.9785 6.52472 28.4594 6.04256 29.0704 6.04256C29.5897 6.04256 29.919 6.32056 30.0903 6.7501L33.2285 14.4486Z" fill="url(#paint9_linear_5081_5986)"/>
<defs>
<linearGradient id="paint0_linear_5081_5986" x1="2.86996" y1="-5.31335" x2="45.8716" y2="-40.57" gradientUnits="userSpaceOnUse">
<stop stop-color="#4C76F8"/>
<stop offset="0.44" stop-color="#4C76F8"/>
<stop offset="0.555" stop-color="#D94FD5"/>
<stop offset="0.915" stop-color="#EDE315"/>
</linearGradient>
<linearGradient id="paint1_linear_5081_5986" x1="2.86996" y1="-5.31335" x2="45.8716" y2="-40.57" gradientUnits="userSpaceOnUse">
<stop stop-color="#4C76F8"/>
<stop offset="0.44" stop-color="#4C76F8"/>
<stop offset="0.555" stop-color="#D94FD5"/>
<stop offset="0.915" stop-color="#EDE315"/>
</linearGradient>
<linearGradient id="paint2_linear_5081_5986" x1="2.86996" y1="-5.31335" x2="45.8716" y2="-40.57" gradientUnits="userSpaceOnUse">
<stop stop-color="#4C76F8"/>
<stop offset="0.44" stop-color="#4C76F8"/>
<stop offset="0.555" stop-color="#D94FD5"/>
<stop offset="0.915" stop-color="#EDE315"/>
</linearGradient>
<linearGradient id="paint3_linear_5081_5986" x1="2.86996" y1="-5.31335" x2="45.8716" y2="-40.57" gradientUnits="userSpaceOnUse">
<stop stop-color="#4C76F8"/>
<stop offset="0.44" stop-color="#4C76F8"/>
<stop offset="0.555" stop-color="#D94FD5"/>
<stop offset="0.915" stop-color="#EDE315"/>
</linearGradient>
<linearGradient id="paint4_linear_5081_5986" x1="2.86996" y1="-5.31335" x2="45.8716" y2="-40.57" gradientUnits="userSpaceOnUse">
<stop stop-color="#4C76F8"/>
<stop offset="0.44" stop-color="#4C76F8"/>
<stop offset="0.555" stop-color="#D94FD5"/>
<stop offset="0.915" stop-color="#EDE315"/>
</linearGradient>
<linearGradient id="paint5_linear_5081_5986" x1="2.86996" y1="-5.31335" x2="45.8716" y2="-40.57" gradientUnits="userSpaceOnUse">
<stop stop-color="#4C76F8"/>
<stop offset="0.44" stop-color="#4C76F8"/>
<stop offset="0.555" stop-color="#D94FD5"/>
<stop offset="0.915" stop-color="#EDE315"/>
</linearGradient>
<linearGradient id="paint6_linear_5081_5986" x1="65.7015" y1="4.67231" x2="67.0615" y2="-1.74044" gradientUnits="userSpaceOnUse">
<stop offset="0.085" stop-color="#EDE315"/>
<stop offset="0.688288" stop-color="#D94FD5"/>
<stop offset="1" stop-color="#5C24FF"/>
</linearGradient>
<linearGradient id="paint7_linear_5081_5986" x1="67.1248" y1="10.1356" x2="71.5186" y2="0.116521" gradientUnits="userSpaceOnUse">
<stop offset="0.0269946" stop-color="#EDE315"/>
<stop offset="0.612662" stop-color="#D94FD5"/>
<stop offset="1" stop-color="#5C24FF"/>
</linearGradient>
<linearGradient id="paint8_linear_5081_5986" x1="72.607" y1="9.92945" x2="75.614" y2="8.8459" gradientUnits="userSpaceOnUse">
<stop offset="0.085" stop-color="#EDE315"/>
<stop offset="0.612662" stop-color="#D94FD5"/>
<stop offset="1" stop-color="#5C24FF"/>
</linearGradient>
<linearGradient id="paint9_linear_5081_5986" x1="2.26738" y1="-5.71" x2="45.9965" y2="-41.5631" gradientUnits="userSpaceOnUse">
<stop stop-color="#4C76F8"/>
<stop offset="0.44" stop-color="#4C76F8"/>
<stop offset="0.555" stop-color="#D94FD5"/>
<stop offset="0.915" stop-color="#EDE315"/>
</linearGradient>
</defs>
</svg>
`;
export default aiLogo;
