import React from 'react';

type Props = {
  title: string;
};

const RatingOptionsPlaceholder = ({ title }: Props) => {
  return <div className="rating-options-placeholder">{title}</div>;
};

export default RatingOptionsPlaceholder;
