import React from 'react';

import type { ThreeSixtyUserReview } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n/index';

import { sendReminder } from 'redux/actions/resources';

import { MenuItem } from 'components';

type Props = {
  userReview: ThreeSixtyUserReview;
};

const RemindSelfAssessmentAction = ({ userReview }: Props) => {
  const dispatch = useAppDispatch();
  const sendSelfAssessmentReminder = () => {
    dispatch(
      sendReminder(
        userReview.id,
        'three_sixty_reviewee_reminder_to_fill_self_assessment'
      )
    );
  };

  return (
    <MenuItem onClick={sendSelfAssessmentReminder}>
      {__('Remind the reviewee to fill their self-assessment')}
    </MenuItem>
  );
};

export default RemindSelfAssessmentAction;
