import React from 'react';

import { __, n__ } from 'helpers/i18n';

import StatsGridItem from 'scenes/components/StatsGridItem';

type StatCol = {
  value: number;
  name: string;
  total: number;
};

const statusColor = {
  late: 'danger',
  nearingDeadline: 'warning',
  upToDate: 'success',
};

const AuditStatByStatus = ({ value, name, total }: StatCol) => {
  const statNameLabel = (statName: string) => {
    switch (statName) {
      case 'late':
        return __('Late');
      case 'nearingDeadline':
        return __('Nearing deadline');
      case 'upToDate':
        return __('Up to date');
      default:
        return '';
    }
  };

  const statTooltipLabel = (statName: string) => {
    switch (statName) {
      case 'late':
        return __('Employees whose compliance deadline has passed.');
      case 'nearingDeadline':
        return __(
          'Employees who need to have a review within less than 2 months, otherwise they will be considered as <b>Late</b>.'
        );
      case 'upToDate':
        return __(
          'Employees who have recently joined the company or are up to date with their reviews. <b>Attention</b>, if a collaborator is included in a corresponding review campaign but has not completed their review, they will be considered <b>Up to date</b>.'
        );
      default:
        return '';
    }
  };
  return (
    <React.Fragment>
      <StatsGridItem
        color={statusColor[name]}
        mainPercentage={(value * 100) / total}
        title={statNameLabel(name)}
        tooltipTitle={statTooltipLabel(name)}
        tooltipIsLight
        description={n__(
          'i.e. <b>%1</b> employee',
          'i.e. <b>%1</b> employees',
          value
        )}
      />
    </React.Fragment>
  );
};

export default AuditStatByStatus;
