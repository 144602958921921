import React from 'react';

import { handleFormErrors } from 'helpers/api';
import * as commonPhrases from 'helpers/commonPhrases';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { errorNotice } from 'redux/actions';

import { FieldWarning, Flex, Icon, Label, Text } from 'components';

type Props = {
  weight: number | null | undefined;
  weightWarning: string | null | undefined;
  onChange: (value: number | null | undefined) => Promise<unknown>;
  testClassName?: string;
  additionalClassName?: string;
  disabled: boolean;
};

export default function Weight({
  weight,
  weightWarning,
  disabled,
  onChange,
  testClassName,
  additionalClassName,
}: Props) {
  const dispatch = useAppDispatch();
  const handleOnChange = value =>
    handleFormErrors(
      () => onChange(value),
      errors => {
        const errorMessage = errors.weight
          ? errors.weight[0]
          : commonPhrases.somethingWentWrong();

        return dispatch(errorNotice(errorMessage));
      }
    );

  return (
    <Flex additionalClassName={additionalClassName} verticalAlign>
      <Icon color="info" name="fitness_center" />

      <Label
        textSize={6}
        color="info"
        weight="semibold"
        style={{ margin: '0 8px', flexShrink: 0 }}
      >
        {__('Weight:')}
      </Label>

      <Flex additionalClassName="weight-field" testClassName={testClassName}>
        <Text
          isEditable={!disabled}
          size={6}
          style={{ marginRight: 8 }}
          editableOptions={{
            type: 'percentage',
            onChange: handleOnChange,
            value: weight,
            withEditIcon: false,
            hasError: !!weightWarning,
          }}
        />

        {!!weightWarning && !disabled && (
          <FieldWarning color="danger" warning={weightWarning} />
        )}
      </Flex>
    </Flex>
  );
}
