import React from 'react';

import { Link, Text } from 'components';

export type HistorySectionItem = {
  id: string;
  title: string;
  subTitle?: string;
  date: string;
  linkTo?: string;
};

type Props = {
  historySectionItem: HistorySectionItem;
};

export default function HistorySectionItemComponent({
  historySectionItem,
}: Props) {
  const { title, subTitle, date, linkTo } = historySectionItem;

  return (
    <div className="history-item">
      <div className="history-item-title">
        {linkTo ? (
          <Link to={linkTo} openInNewTab>
            {title}
          </Link>
        ) : (
          <Text>{title}</Text>
        )}
      </div>
      <div className="history-item-sub-title">
        {subTitle && <Text color="info">{subTitle + ' - '}</Text>}
        <Text color="info" transformation="capitalized">
          {date}
        </Text>
      </div>
    </div>
  );
}
