import React, { ReactNode, useState } from 'react';

import type { SkillsLevel } from 'models';

import { __ } from 'helpers/i18n';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';

import SkillsLevelPicker from './SkillsLevelPicker';

type Props = {
  isActive: boolean;
  title: string;
  submitLabel: string;
  description: ReactNode;
  levelSelectionLabel: string;
  currentSkillsLevel: SkillsLevel | null | undefined;
  onClose: () => void;
  onLevelSelect: (levelId: string | null) => void;
  disabled?: boolean;
  helperBox?: ReactNode;
};

const CareerLevelManagementModal = ({
  isActive,
  currentSkillsLevel,
  title,
  submitLabel,
  description,
  levelSelectionLabel,
  onClose,
  onLevelSelect,
  disabled = false,
  helperBox,
}: Props) => {
  const [selectedLevel, setSelectedLevel] = useState(currentSkillsLevel);

  return (
    <ModalCard
      isActive={isActive}
      onClose={onClose}
      isLarge
      refreshContentOnOpening
    >
      <ModalCardHead>
        <ModalCardTitle>{title}</ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody>
        <div className="mt-4">
          <Text>{description}</Text>
        </div>
        <div className="mt-8 mb-2">
          <SkillsLevelPicker
            levelSelectionLabel={levelSelectionLabel}
            setSelectedLevel={setSelectedLevel}
            selectedLevel={selectedLevel}
            disabled={disabled}
          />
          {helperBox}
        </div>
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Cancel')}
        </Button>
        <Button
          color="primary"
          disabled={(!currentSkillsLevel && !selectedLevel) || disabled}
          onClick={() => onLevelSelect(selectedLevel?.id || null)}
        >
          {submitLabel}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default CareerLevelManagementModal;
