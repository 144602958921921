import React from 'react';

import { User } from 'models';

import can from 'helpers/can';
import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

type Props = {
  user: User;
};

const EmptyState = ({ user }: Props) => {
  const activeUser = useActiveUser();
  const canManageProfessionalBackground = can({
    perform: 'manage_professional_background',
    on: user,
  });

  const getTitle = () =>
    activeUser.id === user.id
      ? __('No job added to your professional background')
      : __("No job added to %1's professional background", user.fullName);
  const getDescription = () => {
    if (!canManageProfessionalBackground || activeUser.id === user.id)
      return null;

    return __(
      "Add jobs to document %1's professional background.",
      user.fullName
    );
  };

  return (
    <EmptyStateWithIcon
      iconName="history_edu"
      title={getTitle()}
      description={getDescription()}
      inBox={false}
    />
  );
};

export default EmptyState;
