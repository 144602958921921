import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import type { ButtonColor, ButtonSize } from 'components/types/button';
import type { TextSize } from 'components/types/text';
import type { MouseEvent } from 'react';

import classNames from 'helpers/classNames';
import { getPathForAbsoluteHref } from 'helpers/url';

import { Icon, Text, Tooltip } from 'components';

export type StyleProps = {
  color?: ButtonColor;
  size?: ButtonSize;
  isRounded?: boolean;
  isOutlined?: boolean;
  isInverted?: boolean;
  isText?: boolean;
  textSize?: TextSize;
  isExpanded?: boolean;
};

export type TooltipProps = {
  isLight?: boolean;
  style?: CSSProperties;
  triggerAdditionalClassName?: string;
};

export type Props = {
  onClick?: (() => void | Promise<any>) | undefined;
  onFocus?: () => void | Promise<void>;
  onError?: (error: unknown) => void;
  onMouseLeave?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  onTouchStart?: React.TouchEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  isHovered?: boolean;
  isFocused?: boolean;
  isActive?: boolean;
  isLoading?: boolean;
  isStatic?: boolean;
  disabled?: boolean;
  hasIconOnly?: boolean;
  disabledExplanation?: string;
  tooltipProps?: TooltipProps;
  to?: string;
  openInNewTab?: boolean;
  type?: 'button' | 'reset' | 'submit' | undefined;
  children?: React.ReactNode;
  successLabel?: React.ReactNode;
  style?: CSSProperties;
  additionalClassName?: string;
  testClassName?: string;
  title?: string;
} & StyleProps;

type State = {
  isLoading: boolean;
  isSuccess: boolean;
};

const SuccessText = ({
  text,
  size,
}: {
  text: React.ReactNode;
  size: ButtonSize;
}) => {
  let textSize: TextSize | undefined;

  switch (size) {
    case 'small':
      textSize = 7;
      break;
    case 'normal':
      textSize = 6;
      break;
    case 'medium':
      textSize = 5;
      break;
    case 'large':
      textSize = 4;
      break;
    default:
      textSize = 6;
  }

  return (
    <Text color="success" size={textSize}>
      <Icon size={size} style={{ verticalAlign: 'middle' }} name="check" />{' '}
      <span>{text}</span>
    </Text>
  );
};

export default class Button extends React.Component<Props, State> {
  _isMounted = false;

  state = {
    isLoading: false,
    isSuccess: false,
  };

  isSuccessTimeoutId: ReturnType<typeof setTimeout> | null = null;

  clearIsSuccessTimeoutId = () => {
    if (this.isSuccessTimeoutId !== null) {
      clearTimeout(this.isSuccessTimeoutId);
      this.isSuccessTimeoutId = null;
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }

  handleClick = async (
    e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    const { onClick, onError, disabled } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (onClick && !disabled) {
      this.setState({ isLoading: true });
      let isSuccess: boolean;
      try {
        await onClick();
        isSuccess = true;
      } catch (e) {
        isSuccess = false;
        console.error(e);
        if (onError) onError(e);
      }

      if (this._isMounted) {
        this.setState({ isLoading: false, isSuccess });

        this.isSuccessTimeoutId = setTimeout(() => {
          if (this._isMounted) {
            this.setState({ isSuccess: false });
            this.clearIsSuccessTimeoutId();
          }
        }, 2500);
      }
    }
  };

  componentWillUnmount() {
    this.clearIsSuccessTimeoutId();
    this._isMounted = false;
  }

  isLoading = () => this.props.isLoading || this.state.isLoading;

  getClassName = (overrides: Partial<Props> = {}) => {
    const overridenProps = { ...this.props, ...overrides };

    const {
      size,
      textSize,
      color,

      isHovered,
      isFocused,
      isActive,
      isStatic,
      disabled,

      hasIconOnly,

      isOutlined,
      isRounded,
      isInverted,
      isExpanded,
      isText,
      additionalClassName,
      testClassName,
    } = overridenProps;

    const colorClassName = classNames(color && `is-${color}`);

    const stateClassName = classNames({
      'is-hovered': !!isHovered,
      'is-focused': !!isFocused,
      'is-active': !!isActive,
      'is-loading': this.isLoading(),
      'is-static': !!isStatic,
      'is-disabled': !!disabled,
    });

    const sizeClassName = classNames(size && `is-${size}`);

    return classNames(
      'button',
      additionalClassName,
      testClassName,
      colorClassName,
      stateClassName,
      sizeClassName,
      {
        [`is-size-${textSize || ''}`]: textSize,
        'is-outlined': !!isOutlined,
        'is-inverted': !!isInverted,
        'is-rounded': !!isRounded,
        'is-text': !!isText,
        'is-expanded': !!isExpanded,
        'has-icon-only': !!hasIconOnly,
      }
    );
  };

  render() {
    const {
      disabled,
      disabledExplanation,
      tooltipProps,
      to,
      openInNewTab,
      onClick,
      onMouseLeave,
      onMouseEnter,
      onTouchStart,
      style,
      successLabel,
      children,
      size,
      onFocus,
      title,
      type,
    } = this.props;

    let content = children;

    const onKeyDown: React.KeyboardEventHandler<
      HTMLAnchorElement | HTMLButtonElement
    > = e => {
      onClick && e.key === 'Enter' && !disabled && onClick();
    };

    const sharedProps = {
      title: title || (typeof content === 'string' ? content : undefined),
      onMouseLeave,
      onMouseEnter,
      onTouchStart,
      onKeyDown,
      className: this.getClassName(),
      disabled: this.isLoading() || disabled,
      style,
      tabIndex: 0,
    };

    if (!!successLabel && this.state.isSuccess) {
      return <SuccessText text={successLabel} size={size || 'normal'} />;
    }

    let clickableContent: React.ReactNode = null;

    // Button is actually a link with relative URL styled as a button
    if (to) {
      if (!disabled) {
        // We don't use our Link component because of CSS incompatibilities and didn't take the time to fix them
        clickableContent = (
          <Link
            {...sharedProps}
            to={getPathForAbsoluteHref(to)}
            onClick={onClick}
            target={openInNewTab ? '_blank' : ''}
          >
            {content}
          </Link>
        );
      } else {
        clickableContent = <a {...sharedProps}>{content}</a>;
      }
    }

    // Button is a button with an onClick handled
    if (!to) {
      clickableContent = (
        <button
          type={type || 'button'}
          onFocus={onFocus}
          {...sharedProps}
          onClickCapture={this.handleClick}
        >
          {content}
        </button>
      );
    }

    return disabledExplanation && disabled ? (
      <Tooltip content={disabledExplanation} {...tooltipProps}>
        {clickableContent}
      </Tooltip>
    ) : (
      clickableContent
    );
  }
}
