import React, { useState } from 'react';
import { connect } from 'react-redux';

import type { PeopleUserReview } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToPeopleUserReview } from 'helpers/navigation';
import confirmAsync from 'helpers/react/confirmAsync';

import { del } from 'redux/actions/api';

import {
  BoxListItem,
  Column,
  Columns,
  HamburgerMenu,
  MenuItem,
  MenuList,
  PullRight,
  Text,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';
import PeopleUserReviewStatusTag from 'scenes/components/peopleUserReview/PeopleUserReviewStatusTag';

import PeopleGroupTagList from '../components/PeopleGroupTagList';
import ChangeReviewerModal from './ChangeReviewerModal';

type Props = {
  peopleUserReview: PeopleUserReview;
  refetchData: () => Promise<void>;
  peopleReviewCycleName: string;
};

type AfterConnectProps = Props & {
  deletePeopleUserReview: () => Promise<void>;
};

function PeopleUserReviewListItem({
  peopleUserReview,
  deletePeopleUserReview,
  refetchData,
  peopleReviewCycleName,
}: AfterConnectProps) {
  const { status, user, reviewer } = peopleUserReview;
  invariant(reviewer, 'There should be a reviewer for this people review.');
  const [changeReviewerModalActive, setChangeReviewerModalActive] =
    useState<boolean>(false);
  const handleDelete = async () => {
    await confirmAsync(
      __('Remove %1 from cycle?', user.firstName),
      __(
        'If you remove %1 from the cycle their people review comments will be removed.',
        user.fullName
      ),
      {
        sideEffectsLabel: __('Please confirm the action:'),
        sideEffects: [__('Remove comments from people review')],
        confirmLabel: __('Remove %1 from cycle', user.firstName),
        onConfirm: deletePeopleUserReview,
      }
    );
  };

  const canDestroy = can({
    perform: 'destroy',
    on: peopleUserReview,
  });
  const canUpdateReviewer = can({
    perform: 'update_reviewer',
    on: peopleUserReview,
  });
  const canPerformSomeActions = [canDestroy, canUpdateReviewer].includes(true);

  return (
    <BoxListItem
      testClassName={`test-people-user-review-item test-people-user-review-item-${peopleUserReview.id}`}
    >
      <Columns contentVerticallyAligned>
        <Column size={3}>
          <UserAvatar
            user={user}
            link={pathToPeopleUserReview(peopleUserReview.id)}
            withJobTitle
          />
        </Column>
        <Column size={3}>
          <UserAvatar user={reviewer} withJobTitle />
        </Column>
        <Column size={3}>
          {peopleUserReview.status === 'to_do' ? (
            <Text color="grey">{'-'}</Text>
          ) : (
            <PeopleGroupTagList peopleGroups={peopleUserReview.peopleGroups} />
          )}
        </Column>
        <Column size={2}>
          <PeopleUserReviewStatusTag
            isLocked={peopleUserReview.isLocked}
            status={status}
          />
        </Column>
        {canPerformSomeActions && (
          <Column size={1}>
            <PullRight>
              <HamburgerMenu>
                <MenuList>
                  {canUpdateReviewer && (
                    <MenuItem
                      onClick={() => setChangeReviewerModalActive(true)}
                    >
                      <Text>{__('Change reviewer')}</Text>
                    </MenuItem>
                  )}
                  {canDestroy && (
                    <MenuItem key="delete" isDanger onClick={handleDelete}>
                      <Text>{__('Remove from cycle')}</Text>
                    </MenuItem>
                  )}
                </MenuList>
              </HamburgerMenu>
            </PullRight>
          </Column>
        )}
      </Columns>
      {canUpdateReviewer && changeReviewerModalActive && (
        <ChangeReviewerModal
          peopleUserReviewId={peopleUserReview.id}
          reviewee={user}
          reviewer={reviewer}
          onClose={() => setChangeReviewerModalActive(false)}
          refetchData={refetchData}
          peopleReviewCycleName={peopleReviewCycleName}
        />
      )}
    </BoxListItem>
  );
}

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { peopleUserReview, refetchData }: Props
) => {
  return {
    deletePeopleUserReview: async () => {
      await dispatch(del(`people_user_reviews/${peopleUserReview.id}`));

      await refetchData();
    },
  };
};

export default connect(null, mapDispatchToProps)(PeopleUserReviewListItem);
