import React from 'react';

import type { BudgetItem, FundingSource, Organization } from 'models';

import { withOrganization } from 'helpers/withSessionProps';

import { Column, Columns } from 'components';

import BudgetItemAmountInput from './BudgetItemAmountInput';
import BudgetItemDeletionButton from './BudgetItemDeletionButton';
import FundingSourceSelect from './FundingSourceSelect';

type Props = {
  budgetItem?: BudgetItem;
  fundingSources: Array<FundingSource>;
  availableFundingSources: Array<FundingSource>;
  onBudgetItemDestroy: (budgetItem: BudgetItem) => Promise<void>;
  onBudgetItemUpdate: (attributes: Partial<BudgetItem>) => Promise<void>;
  onFundingSourceCreate: (attributes: Partial<FundingSource>) => Promise<void>;
};

type AfterConnectProps = Props & {
  organization: Organization;
};

const BudgetListItem = ({
  budgetItem,
  fundingSources,
  availableFundingSources,
  onBudgetItemDestroy,
  onBudgetItemUpdate,
  onFundingSourceCreate,
  organization,
}: AfterConnectProps) => {
  const fundingSource = budgetItem
    ? fundingSources.find(source => source.id === budgetItem.fundingSourceId)
    : null;

  const handleFundingSourceUpdate = (fundingSourceId: string) =>
    onBudgetItemUpdate({ ...budgetItem, fundingSourceId: fundingSourceId });

  const handleFundingSourceCreation = (title: string) =>
    onFundingSourceCreate({ title });

  const handleBudgetItemUpdate = (attributes: Partial<BudgetItem>) =>
    onBudgetItemUpdate({ ...budgetItem, ...attributes });

  return (
    <Columns additionalClassName="mb-0">
      <Column size="two-thirds">
        <FundingSourceSelect
          fundingSource={fundingSource}
          fundingSources={availableFundingSources}
          onUpdate={handleFundingSourceUpdate}
          onCreate={handleFundingSourceCreation}
        />
      </Column>
      <Column size="one-quarter">
        {fundingSource && (
          <BudgetItemAmountInput
            budgetItem={budgetItem}
            defaultCurrency={organization.settings.defaultCurrency}
            onUpdate={handleBudgetItemUpdate}
          />
        )}
      </Column>
      {budgetItem && (
        <Column>
          <BudgetItemDeletionButton
            budgetItem={budgetItem}
            onDestroy={onBudgetItemDestroy}
          />
        </Column>
      )}
    </Columns>
  );
};

export default withOrganization(BudgetListItem);
