import React from 'react';

import { AdminUser } from 'models';

import { n__ } from 'helpers/i18n';

import { HamburgerMenu, MenuItem, MenuList, PullRight, Text } from 'components';

import RolesManagementModal from '../../RolesManagementModal';
import uniqueRolesCount from '../../helpers/uniqueRolesCount';
import useRevokeRolesAction from './actions/useRevokeRolesAction';

type Props = { user: AdminUser; refetchData: () => void };

const ItemMenu = ({ user, refetchData }: Props) => {
  const { revokeRolesAction } = useRevokeRolesAction(user, refetchData);
  const [updateRolesModalActive, setUpdateRolesModalActive] =
    React.useState(false);

  return (
    <PullRight>
      <HamburgerMenu>
        <MenuList>
          <MenuItem onClick={() => setUpdateRolesModalActive(true)}>
            <Text>
              {n__(
                "Update %2's role",
                "Update %2's roles",
                uniqueRolesCount(user.roles),
                user.fullName
              )}
            </Text>
          </MenuItem>
          <MenuItem onClick={() => revokeRolesAction()}>
            <Text color="danger">
              {n__(
                "Revoke %2's role",
                "Revoke all %2's roles",
                uniqueRolesCount(user.roles),
                user.fullName
              )}
            </Text>
          </MenuItem>
        </MenuList>
      </HamburgerMenu>
      {updateRolesModalActive && (
        <RolesManagementModal
          onClose={() => setUpdateRolesModalActive(false)}
          user={user}
        />
      )}
    </PullRight>
  );
};

export default ItemMenu;
