import React from 'react';

import { Text } from 'components';

export type Props = {
  label: string;
  children: React.ReactNode;
  testClassName?: string;
  hidden?: boolean;
};

const SummaryItem = ({ label, children, testClassName, hidden }: Props) => {
  if (hidden) return null;

  return (
    <div className={`flex summary-item ${testClassName}`}>
      <div className="flex-1 self-top text-end p-4">
        <Text color="soften">{label}</Text>
      </div>
      <div className="flex-1 test-summary-item-value p-4">{children}</div>
    </div>
  );
};

export default SummaryItem;
