import React from 'react';

import classNames from 'helpers/classNames';
import useIsMobile from 'helpers/hooks/useIsMobile';

import { Button, Icon } from 'components';

type Props = {
  showMobileSlidingSidebar: boolean;
  toggleSidebar: () => void;
  children?: React.ReactNode;
  additionalClassName?: string;
};

const MobileSlidingSidebar = ({
  showMobileSlidingSidebar,
  toggleSidebar,
  children,
  additionalClassName,
}: Props) => {
  const isMobile = useIsMobile();

  if (!isMobile) return null;

  return (
    <div
      className={classNames(
        `fixed top-0 mt-16 h-full w-full z-10 bg-white ease-in-out duration-300
        ${
          showMobileSlidingSidebar
            ? 'left-0 translate-x-0'
            : 'right-0 translate-x-full'
        }`,
        additionalClassName
      )}
    >
      <Button
        onClick={() => toggleSidebar()}
        hasIconOnly
        additionalClassName="absolute top-2 right-2 border-none"
      >
        <Icon name="close" />
      </Button>
      {children}
    </div>
  );
};

export default MobileSlidingSidebar;
