import React, { CSSProperties, ReactNode } from 'react';

import classNames from 'helpers/classNames';

// only use Form component if you want to trigger onSubmit action with enter
type Props = {
  children: ReactNode;
  onSubmit?: () => Promise<void>;
  style?: CSSProperties;
  isHorizontal?: boolean;
};

export default function Form({
  isHorizontal,
  onSubmit,
  children,
  style,
}: Props) {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form
      style={style}
      className={classNames({ 'is-horizontal': isHorizontal })}
      onSubmit={e => {
        e.preventDefault();
        onSubmit && onSubmit();
      }}
      onKeyDown={e => {
        if (onSubmit && e.keyCode === 13) {
          e.preventDefault();
          onSubmit();
        }
      }}
    >
      {children}
    </form>
  );
}
