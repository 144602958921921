import React from 'react';

import type { ThreeSixtyReviewCycle } from 'models';

import { __ } from 'helpers/i18n';
import { pathToManagerReviewCycleAnalytics } from 'helpers/paths';

import { Box, Button, Column, Columns, Date, Text, Title } from 'components';

import InsightsIcon from './InsightsIcon';

type Props = {
  reviewCycle: ThreeSixtyReviewCycle;
  hasManagerAnalytics: boolean;
  children: React.ReactNode;
};

const ThreeSixtyReviewCycleItem = ({
  reviewCycle,
  hasManagerAnalytics,
  children,
}: Props) => {
  return (
    <Box testClassName={`test-review-cycle-item-${reviewCycle.id}`}>
      <Columns contentVerticallyAligned style={{ marginBottom: 16 }}>
        <Column>
          <Title size={5} testClassName="test-review-cycle-name">
            {reviewCycle.name}
          </Title>
        </Column>
        {hasManagerAnalytics && (
          <Column isNarrow>
            <Button
              color="secondary"
              size="small"
              to={pathToManagerReviewCycleAnalytics(reviewCycle.id)}
              testClassName="test-analytics-button"
            >
              <InsightsIcon />
              <span style={{ marginLeft: 8 }}>{__('Follow-up')}</span>
            </Button>
          </Column>
        )}
      </Columns>

      <Columns isMobile isGapLess style={{ marginBottom: 16 }}>
        <Column size="full">
          <Text color="soften">
            {__(
              'Ends on: %1',
              <Date value={reviewCycle.dateReviewRevieweeEnd} />
            )}
          </Text>
        </Column>
      </Columns>

      {children}
    </Box>
  );
};

export default ThreeSixtyReviewCycleItem;
