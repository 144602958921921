import React, { CSSProperties } from 'react';

import { __ } from 'helpers/i18n';

import { Button } from 'components';

type Props = {
  redirectTo: string;
  style: CSSProperties;
};

export default function SSOButton({ redirectTo, style }: Props) {
  return (
    <Button
      style={style}
      color="primary"
      onClick={() => {
        window.location.href = redirectTo;
      }}
    >
      {__('Sign in with SSO')}
    </Button>
  );
}
