import { Accordion } from '@radix-ui/react-accordion';
import React from 'react';

import classNames from 'helpers/classNames';

type Props = React.ComponentProps<typeof Accordion>;

export default function ElevoAccordion(props: Props) {
  const editedProps = {
    ...props,
    className: classNames('elevo-accordion', props.className),
  };
  return <Accordion {...editedProps}></Accordion>;
}
