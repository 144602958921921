import React, { ReactNode } from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { Evaluation } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import compositeKey from 'helpers/compositeKey';
import invariant from 'helpers/invariant';

import { newDataLoader } from 'lib/dataLoader';
import { requestStarted, requestSucceeded } from 'lib/dataLoader/actions';
import { get } from 'redux/actions/api';

type Props = {
  evaluationId: string;
  render: (params: {
    isShareable: boolean;
    shareDisabledReasons: Array<string>;
  }) => ReactNode;
};

type AfterDataloaderProps = Props &
  DataLoaderProvidedProps & {
    evaluation: Evaluation;
  };

function getCacheKey(evaluationId: string) {
  return compositeKey({ id: evaluationId });
}

export const refreshEvaluationShareability = (evaluationId: string) => {
  return async function (dispatch: AppDispatch) {
    await dispatch(requestStarted(getCacheKey(evaluationId)));
    const response = await dispatch(
      get(`evaluations/${evaluationId}/validate_content`)
    );
    await dispatch(
      requestSucceeded(getCacheKey(evaluationId), response.response.body)
    );
  };
};

function EvaluationShareability({
  evaluation,
  render,
  isFetching,
  hasError,
}: AfterDataloaderProps) {
  if ((isFetching && !evaluation) || hasError) {
    return render({ isShareable: true, shareDisabledReasons: [] });
  }

  invariant(
    typeof evaluation.isShareable !== 'undefined',
    'isShareable should be defined'
  );
  invariant(
    typeof evaluation.shareLockedReasons !== 'undefined',
    'shareLockedReasons should be defined'
  );

  return render({
    isShareable: evaluation.isShareable,
    shareDisabledReasons: evaluation.shareLockedReasons,
  });
}

export default newDataLoader({
  fetch: (props: Props) =>
    get(`evaluations/${props.evaluationId}/validate_content`),
  hydrate: {
    evaluation: {},
  },
  cacheKey: ({ evaluationId }: Props) => getCacheKey(evaluationId),
})(EvaluationShareability) as React.ComponentType<Props>;
