// @flow
import { isEmpty, isEqual } from 'lodash';
import * as React from 'react';
import { withRouter } from 'react-router-dom';

import type { UrlPaginationProps } from './types';
import type { PaginationSearchParams } from 'lib/dataLoader/pagination/types';
import type { UserFilterSegment } from 'models/index';
import type { ContextRouter } from 'react-router-dom';

import { getDisplayName } from 'helpers/hoc';
import invariant from 'helpers/invariant';
import queryString from 'helpers/queryString';

import { DEFAULT_PAGINATION, parsePaginationParams } from './paginationParams';

type State = {
  queryParams: PaginationSearchParams,
};

type FactoryArgs = {|
  defaultPaginationParams: (props: {}) => ?$Shape<PaginationSearchParams>,
|};

export default function UrlPaginationFactory({
  defaultPaginationParams,
}: FactoryArgs) {
  function withUrlPagination(WrappedComponent: React.ComponentType<*>) {
    class URLPaginator extends React.Component<ContextRouter, State> {
      state = {
        queryParams: DEFAULT_PAGINATION,
      };

      static getDerivedStateFromProps(props: {}, state: State) {
        const { location }: ContextRouter = window;
        const basePaginationParams = {
          ...DEFAULT_PAGINATION,
          ...(defaultPaginationParams(props) || {}),
        };

        let nextState = state;

        const queryParamsFromProps = parsePaginationParams(
          queryString.parse(location.search)
        );

        if (
          !isEqual(state.queryParams, queryParamsFromProps) ||
          isEmpty(state.queryParams)
        ) {
          nextState = {
            queryParams: {
              ...basePaginationParams,
              ...queryParamsFromProps,
            },
          };
        }

        return nextState;
      }

      buildFullUrl = (newSearchParams: Object): string =>
        `${this.props.location.pathname}?${queryString.stringify(
          newSearchParams
        )}`;

      replaceSearchParams = (newSearchParams: Object) => {
        this.props.history.replace(this.buildFullUrl(newSearchParams));
      };

      componentDidMount() {
        this.replaceSearchParams({
          ...this.state.queryParams,
        });
      }

      onSearchChange = (newSearch: string) => {
        invariant(
          this.state.queryParams &&
            typeof this.state.queryParams.search !== 'undefined',
          'The search params "search" should be defined'
        );

        const newSearchParams = {
          ...this.state.queryParams,
          search: newSearch,
          page: 1,
        };

        this.replaceSearchParams(newSearchParams);
      };

      onSortChange = (sortName: string) => {
        const newSortParams = {
          ...this.state.queryParams,
          sort: this.getNextSortValue(sortName),
        };

        this.replaceSearchParams(newSortParams);
      };

      getNextSortValue = (sortName: string) => {
        const currentSortValue = this.state.queryParams?.sort[sortName];
        const defaultSortParam = defaultPaginationParams({}).sort;
        const defaultIsDesc =
          defaultSortParam && defaultSortParam[sortName] === 'desc';

        // if default sort is desc, then the only other possible sort value is asc
        if (defaultIsDesc && currentSortValue !== 'asc') {
          return { [sortName]: 'asc' };
        }

        switch (currentSortValue) {
          case null || undefined:
            return { [sortName]: 'asc' };
          case 'asc':
            return { [sortName]: 'desc' };
          case 'desc':
            return {};
          default:
            return {};
        }
      };

      mergeFilters = (currentFilters: {} = {}, newFilter: {}) => {
        const nestedFilters = {};
        for (let key in currentFilters) {
          if (typeof currentFilters[key] === 'object')
            nestedFilters[key] = currentFilters[key];
        }
        return { ...nestedFilters, ...newFilter };
      };

      onFilterChange = (newFilter: {}) => {
        const newSearchParams = {
          ...this.state.queryParams,
          filter: this.mergeFilters(this.state.queryParams.filter, newFilter),
          page: 1,
        };

        this.replaceSearchParams(newSearchParams);
      };

      onUserFilterChange = (userFilter: ?UserFilterSegment) => {
        const newSearchParams = {
          ...this.state.queryParams,
          userFilter,
          page: 1,
        };

        this.replaceSearchParams(newSearchParams);
      };

      onQueryParamsChange = (newQueryParams: {}) => {
        const newSearchParams = {
          ...this.state.queryParams,
          query: newQueryParams,
          page: 1,
        };

        this.replaceSearchParams(newSearchParams);
      };

      getPreviousPageLink = () => {
        invariant(
          this.state.queryParams && this.state.queryParams.page,
          'The search params "page" should be defined'
        );

        return this.buildFullUrl({
          ...this.state.queryParams,
          page: (this.state.queryParams.page - 1).toString(),
        });
      };

      getNextPageLink = () => {
        invariant(
          this.state.queryParams && this.state.queryParams.page,
          'The search params "page" should be defined'
        );

        return this.buildFullUrl({
          ...this.state.queryParams,
          page: (this.state.queryParams.page + 1).toString(),
        });
      };

      render() {
        const { location, history, match, ...otherProps } = this.props;

        const paginationProps: UrlPaginationProps = {
          nextPageLink: this.getNextPageLink(),
          previousPageLink: this.getPreviousPageLink(),
          paginationType: 'url',
          onSearchChange: this.onSearchChange,
          onSortChange: this.onSortChange,
          onQueryParamsChange: this.onQueryParamsChange,
          onFilterChange: this.onFilterChange,
          onUserFilterChange: this.onUserFilterChange,
          ...this.state.queryParams,
        };

        return <WrappedComponent {...otherProps} {...paginationProps} />;
      }
    }

    URLPaginator.displayName = `withUrlPagination(${getDisplayName(
      WrappedComponent
    )})`;

    return withRouter(URLPaginator);
  }

  return withUrlPagination;
}
