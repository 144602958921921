import React from 'react';

import type { AvailableDatePickerProps } from 'components/formElements/inlineEditables/editedRenderers/DatePickerRenderer';
import type {
  AvailableInputProps,
  AvailableNumberInputProps,
} from 'components/formElements/inlineEditables/editedRenderers/TextInputRenderer';
import type { AvailableTextareaProps } from 'components/formElements/inlineEditables/editedRenderers/TextareaRenderer';

import { date } from 'helpers/i18n';

import { Percentage, Text } from 'components';
import RichTextEditor from 'components/formElements/advancedElements/RichTextEditor';

type Props =
  | {
      type: 'multilineText';
      value: string | undefined | null;
      placeholder?: string;
      fieldProps?: AvailableTextareaProps;
    }
  | {
      type: 'singlelineText';
      value: string | undefined | null;
      placeholder?: string;
      fieldProps?: AvailableInputProps;
    }
  | {
      type: 'number';
      value: number | undefined | null;
      placeholder?: string;
      fieldProps?: AvailableNumberInputProps;
    }
  | {
      type: 'date';
      value: string | undefined | null;
      placeholder?: string;
      fieldProps?: AvailableDatePickerProps;
    }
  | {
      type: 'percentage';
      value: number | undefined | null;
      placeholder?: string;
      fieldProps?: AvailableInputProps;
    };

export default function NotEdited({ placeholder, ...typedProps }: Props) {
  let formattedPlaceholder = placeholder;
  let content;

  switch (typedProps.type) {
    case 'singlelineText':
      content = typedProps.value || '-';
      break;
    case 'multilineText':
      content =
        typedProps.fieldProps && typedProps.fieldProps.richText ? (
          <RichTextEditor disabled display value={typedProps.value} />
        ) : (
          <Text preserveLinebreaks>{typedProps.value}</Text>
        );
      break;
    case 'date':
      content = date(typedProps.value);
      break;
    case 'percentage':
      content = <Percentage value={typedProps.value} />;
      formattedPlaceholder = formattedPlaceholder || content; // If a placeholder is provided, use it
      break;
    case 'number':
      content = typedProps.value || '0';
      break;
    default:
      throw new Error('Unknown type');
  }

  if (!typedProps.value && typedProps.value !== 0)
    return <Text color="info">{formattedPlaceholder}</Text>;

  return content;
}
