import React from 'react';

import { __ } from 'helpers/i18n';

import { Placeholder, Title } from 'components';

type Props = {
  actualTitle: string | null | undefined;
  title: string;
  disabled: boolean;
  autoFocus: boolean;
  onChange: (value: string) => Promise<void>;
};

export default function ObjectiveTitle({
  disabled,
  actualTitle,
  title,
  autoFocus,
  onChange,
}: Props) {
  return (
    <Title
      size={5}
      isEditable={!disabled}
      editableOptions={{
        type: 'multilineText',
        value: actualTitle,
        disabledValue: title,
        onChange,
        placeholder: __('Add a title to this objective…'),
        withEditIcon: false,
        autoFocus: autoFocus,
        fieldProps: {
          lineBreakHelperStyle: 'none',
        },
      }}
      testClassName="test-objective-title"
    />
  );
}

export function TitlePlaceholder() {
  return (
    <Title size={5}>
      <Placeholder width="80%" />
    </Title>
  );
}
