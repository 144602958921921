import React, { Fragment } from 'react';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import { CheckboxSwitch, Text, Title5 } from 'components';
import { Level, LevelLeft, LevelRight } from 'components/layout';
import WithSavingStatusRecorder from 'components/withSavingStatus/Recorder';

type Props = {
  hasReleaseStepEnabled: boolean;
  onChange: (attributes: { hasReleaseStepEnabled: boolean }) => Promise<void>;
  disabled: boolean;
  templateId: string;
};

const BlockReleaseStep = ({
  hasReleaseStepEnabled,
  onChange,
  disabled,
  templateId,
}: Props) => {
  const blockReleaseStep = !hasReleaseStepEnabled;
  const handleOnChange = (blockReleaseStep: boolean) =>
    onChange({ hasReleaseStepEnabled: !blockReleaseStep });

  return (
    <Fragment>
      <Level>
        <LevelLeft style={{ maxWidth: '80%' }}>
          <Title5 style={{ lineHeight: '22px' }}>
            {__('Restricted access to results')}
          </Title5>
        </LevelLeft>
        <LevelRight>
          <WithSavingStatusRecorder
            fieldUid={compositeKey({
              templateId: templateId,
              field: 'has_release_step_enabled',
            })}
            onChange={(value: boolean) => handleOnChange(value)}
            render={autoSavingOnchange => (
              <CheckboxSwitch
                disabled={disabled}
                value={blockReleaseStep}
                onChange={newValue => autoSavingOnchange(newValue)}
                size="small"
              />
            )}
          />
        </LevelRight>
      </Level>

      <div style={{ marginTop: 8 }}>
        <Text color="grey">
          {__(
            'Reviewees will not see the results. Only the responsibles and administrators will have access to results'
          )}
        </Text>
      </div>
    </Fragment>
  );
};

export default BlockReleaseStep;
