import leaf2 from './images/halloween/leaf2.png';
import leaf from './images/halloween/leaf.png';
import flower2 from './images/spring/flower2.png';
import flower from './images/spring/flower.png';
import snowflake from './images/winter/snowflake.png';

export type Period = 'halloween' | 'winter' | 'spring';
export type Params = {
  images?: HTMLImageElement[];
  radius?: [number, number];
  itemCount?: number;
  speed?: [number, number];
  wind?: [number, number];
};

const getDefaultImages = periodType => {
  const image = document.createElement('img');
  const image2 = document.createElement('img');
  switch (periodType) {
    case 'halloween':
      image.src = leaf2;
      image2.src = leaf;
      return [image, image2];
    case 'spring':
      image.src = flower;
      image2.src = flower2;
      return [image, image2];
    case 'winter':
      image.src = snowflake;
      return [image];
    default:
      return [];
  }
};

export const getDefaultConfig = (periodType: Period): Params => {
  const images = getDefaultImages(periodType);
  const radius = [10, 20] as [number, number];
  const itemCount = 50;
  const speed = [0, 1] as [number, number];
  const wind = [-0.5, 0.5] as [number, number];

  return { images, radius, itemCount, speed, wind };
};
