import { assert } from 'helpers/invariant';

const htmlElement = () => {
  return assert(document.documentElement, 'html tag must exist');
};

export const makeDocumentNonScrollable = () => {
  htmlElement().classList.add('is-clipped');
};

export const makeDocumentScrollable = () => {
  htmlElement().classList.remove('is-clipped');
};
