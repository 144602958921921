import React from 'react';
import posed from 'react-pose';

type Props = {
  expanded: boolean;
  initiallyExpanded: boolean;
  children: React.ReactNode;
  duration: number;
  initialHeight: number;
  expandedHeight: number | string;
};

const Expandable = posed.div({
  default: {
    height: ({ initialHeight }) => initialHeight,
    transition: ({ duration }) => ({
      duration,
    }),
    applyAtStart: { overflow: 'hidden' },
  },
  expanded: {
    height: ({ expandedHeight }) => expandedHeight,
    transition: ({ duration }) => ({
      duration,
    }),
    applyAtEnd: { overflow: 'visible' },
  },
});

function WithHeightExtension({
  expanded,
  initiallyExpanded,
  children,
  duration,
  initialHeight,
  expandedHeight,
}: Props) {
  return (
    <Expandable
      pose={expanded ? 'expanded' : 'default'}
      initialHeight={initialHeight}
      expandedHeight={expandedHeight}
      duration={duration}
      style={{ overflow: initiallyExpanded ? 'visible' : 'hidden' }}
    >
      {React.Children.only(children)}
    </Expandable>
  );
}

WithHeightExtension.defaultProps = {
  initialHeight: 0,
  expandedHeight: 'auto',
  duration: 300,
};

export default WithHeightExtension;
