import React, { CSSProperties } from 'react';

import classNames from 'helpers/classNames';

import invariant from '../../helpers/invariant';
import { Testable } from '../behaviors';

export type Props = {
  additionalClassName?: string;
  children?: React.ReactElement<{ dropdownIsOpened: boolean }>;
  onClick?: () => void;
  dropdownIsOpened?: boolean;
  render?: (dropdownIsOpened: boolean) => React.ReactNode;
  disabled?: boolean;
  popperRef?: React.Ref<HTMLDivElement>;
  uid?: string;
  testClassName?: string;
  style?: CSSProperties;
  // used to override restricted width added by adding a span in <Testable />
  testableStyle?: CSSProperties;
};

export default function DropdownTrigger(props: Props) {
  let child: React.ReactNode;

  if (typeof props.render === 'undefined') {
    invariant(
      props.children,
      'children or render must be defined on DropdownTrigger'
    );
    child = React.cloneElement(props.children, {
      dropdownIsOpened: props.dropdownIsOpened,
    });
  } else {
    child = props.render(!!props.dropdownIsOpened);
  }
  return (
    <Testable
      name="test-dropdown-trigger"
      uid={props.uid}
      style={props.testableStyle}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames(
          'dropdown-trigger',
          props.additionalClassName,
          props.testClassName
        )}
        onClickCapture={props.disabled ? undefined : props.onClick}
        tabIndex={props.disabled ? undefined : 0}
        ref={props.popperRef}
        onKeyDown={e => {
          !props.disabled &&
            props.onClick &&
            e.key === 'Enter' &&
            props.onClick();
        }}
        style={props.style}
      >
        {child}
      </div>
    </Testable>
  );
}
