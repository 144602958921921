import React from 'react';

import type { RouteComponentProps } from 'react-router-dom';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToTrainings, pathToValidatedTrainingPlan } from 'helpers/paths';

import { DesignSystem, PageTitle, Redirect, Route, Switch } from 'components';

import SimbelTrainingRoutes from 'scenes/components/simbel/SimbelTrainingRoutes';

import Landing from './Landing';
import PeriodCreator from './PeriodCreator';
import PeriodRedirector from './PeriodRedirector';
import PeriodRequests from './PeriodRequests';
import Plan from './Plan';
import Session from './Session';

const TrainingRoutes = ({ match }: RouteComponentProps) => {
  const { path } = match;
  const organization = useCurrentOrganization();
  const trainingPlansEnabled =
    organization.featureFlags.includes('trainingPlans');

  return (
    <DesignSystem version={2}>
      <PageTitle title={[__('Trainings'), __('Admin')]} />

      <Switch>
        <Route path={`${path}/sessions/:id`} component={Session} />

        <Route>
          <Switch>
            <Route exact path={`${path}`} component={PeriodRedirector} />

            <Route path={`${path}/periods/new`} component={PeriodCreator} />
            <Route path={`${path}/landing`} component={Landing} />
            <Route
              path={`${path}/:periodSlug/requests`}
              component={PeriodRequests}
            />

            {trainingPlansEnabled && (
              <Route
                path={`${path}/:periodSlug/forecast-plan`}
                render={props => <Plan isValidatedPlan={false} {...props} />}
              />
            )}
            {trainingPlansEnabled && (
              <Route
                path={`${path}/:periodSlug/validated-plan`}
                render={props => <Plan isValidatedPlan {...props} />}
              />
            )}
            {trainingPlansEnabled ? (
              <Route
                path={`${path}/:periodSlug/sessions`}
                render={({ match }) => {
                  const periodSlug = match.params.periodSlug;
                  return (
                    <Switch>
                      <Redirect to={pathToValidatedTrainingPlan(periodSlug)} />
                    </Switch>
                  );
                }}
              />
            ) : (
              <Route
                path={`${path}/:periodSlug/sessions`}
                render={props => <Plan isValidatedPlan {...props} />}
              />
            )}

            <Redirect to={pathToTrainings()} />
          </Switch>
        </Route>
      </Switch>
    </DesignSystem>
  );
};

export default function Routes(props: RouteComponentProps) {
  const organization = useCurrentOrganization();

  if (organization.isSimbelIntegrationActive) {
    return <SimbelTrainingRoutes {...props} />;
  } else {
    return <TrainingRoutes {...props} />;
  }
}
