import React, { Fragment } from 'react';

import type { OrganizationSync } from 'models';

import { __ } from 'helpers/i18n';
import {
  displayedStatus,
  statusColor,
  statusLabel,
} from 'helpers/integrationStatusText';
import { pathToSyncDetails } from 'helpers/paths';

import { Text } from 'components';

type Props = {
  sync: OrganizationSync | undefined | null;
};

function buildStatusText(sync: OrganizationSync) {
  const status = displayedStatus(sync);
  const color = statusColor(status);
  const label = statusLabel(status);

  return (
    <Fragment>
      <Text weight="bold" color={color} title={label}>
        {label}
      </Text>{' '}
      (<Text linkTo={pathToSyncDetails(sync.id)}>{__('Check details')}</Text>)
    </Fragment>
  );
}

export default function IntegrationSyncStatusText({ sync }: Props) {
  return (
    <Fragment>
      {!!sync && buildStatusText(sync)}
      {!sync && <Text>{__('Never launched')}</Text>}
    </Fragment>
  );
}
