import React from 'react';

import type { UserReview } from 'models';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer } from 'components';

import CardPlaceholder from 'scenes/reviews/components/CardPlaceholder';

import MyReviewCycles from './MyReviewCycles';

type Props = {
  type: string;
};

type AfterConnectProps = Props & {
  userReviews: Array<UserReview>;
  isFetching: boolean;
  hasError: boolean;
};

function MyReviewCyclesWrapper({
  isFetching,
  hasError,
  userReviews,
}: AfterConnectProps) {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      renderFetching={() => (
        <React.Fragment>
          <CardPlaceholder variant="one_one_self" />
          <CardPlaceholder variant="360_self" />
        </React.Fragment>
      )}
      render={() => <MyReviewCycles userReviews={userReviews} />}
    />
  );
}

export default newDataLoader({
  fetch: ({ type }) =>
    get('user_reviews/mine', {
      type,
    }),
  hydrate: {
    userReviews: {
      abilities: {},
      reviewCycle: {},
      reviewer: {},
      additionalReviewer: {},
      user: {
        manager: {},
      },
      evaluations: {
        reviewer: {},
      },
      meeting: {
        participants: {},
      },
    },
  },
  cacheKey: ({ type }: Props) => `my-${type}-reviews`,
})(MyReviewCyclesWrapper);
