export const castHashValuesToBoolean = (hash: Object) => {
  let newHash = {};

  for (let hashKey in hash) {
    newHash[hashKey] =
      typeof hash[hashKey] === 'object'
        ? castHashValuesToBoolean(hash[hashKey])
        : Boolean(hash[hashKey]);
  }

  return newHash;
};
