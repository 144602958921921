import React, { CSSProperties } from 'react';

import type { Team } from '../../models';
import type { Action } from 'components/formElements/advancedElements/Select';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, post } from 'redux/actions/api/methods';

import { FieldError, Select } from 'components';

type Props = {
  value: string | undefined | null;
  onChange: (teamId: string | null, team: Team | null) => void;
  error?: string | null;
  autoFocus?: boolean;
  onBlur?: () => void;
  style?: CSSProperties;
  isClearable?: boolean;
  disabled?: boolean;
  disabledTeamIds?: Array<string>;
  inModal?: boolean;
};

type AfterDataLoaderProps = {
  teams: Array<Team>;
} & Props &
  DataLoaderProvidedProps;

type TeamOption = {
  label: string;
  value: string;
  team: Team;
  isDisabled?: boolean;
};

const TeamPicker = ({
  value,
  error,
  isFetching,
  hasError,
  onBlur,
  autoFocus,
  style,
  teams,
  isClearable = true,
  inModal,
  refetchData,
  onChange,
  disabledTeamIds,
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();
  const createTeam = (name: string) =>
    dispatch(
      post(
        'teams',
        { name },
        {
          errorMessage: __('A team with the same name already exists.'),
        }
      )
    );

  const handleOnChange = async (
    option: TeamOption | Array<TeamOption> | null | void,
    { action }: Action
  ) => {
    invariant(!Array.isArray(option), 'Team option cannot be an array');

    if (option && option.value && action === 'create-option') {
      const { response } = await createTeam(option.value);
      await refetchData();
      const team = {
        id: response.body.data.id,
        name: response.body.data.attributes.name,
        slug: response.body.data.attributes.slug,
      };
      onChange(team.id, team);
    } else {
      option ? onChange(option.value, option.team) : onChange(null, null);
    }
  };

  const isTeamDisabled = (team: Team) =>
    !!(disabledTeamIds && disabledTeamIds.includes(team.id));

  const currentTeam =
    !isFetching && !hasError && !!teams
      ? teams.find((team: Team) => team.id === value)
      : null;

  const teamOptions: Array<TeamOption> =
    !isFetching && teams
      ? teams.map(
          (team: Team): TeamOption => ({
            value: team.id,
            label: team.name,
            team: team,
            isDisabled: isTeamDisabled(team),
          })
        )
      : [];

  return (
    <div style={style}>
      <Select
        onBlur={onBlur}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        value={
          currentTeam
            ? {
                value: currentTeam.id,
                label: currentTeam.name,
                team: currentTeam,
              }
            : null
        }
        options={teamOptions}
        onChange={handleOnChange}
        isLoading={isFetching}
        isCreatable
        formatCreateLabel={value => __('Create team %1', value)}
        placeholder={__('Select or create a team')}
        noOptionsMessage={__('There is no team yet')}
        isClearable={isClearable}
        inModal={inModal}
      />
      {error ? <FieldError>{error}</FieldError> : null}
    </div>
  );
};

export default newDataLoader({
  fetch: () => get('teams'),
  hydrate: {
    teams: {},
  },
})(TeamPicker) as React.ComponentType<Props>;
