import React from 'react';

import { getDisplayName } from 'helpers/hoc';
import { usePaginatedQuery } from 'helpers/hooks';

const withPagination = (WrappedComponent: React.ComponentType<any>) => {
  const WithPagination = props => {
    const paginatedQuery = usePaginatedQuery(props);

    return <WrappedComponent {...props} {...paginatedQuery} />;
  };

  WithPagination.displayName = `withPagination(${getDisplayName(
    WrappedComponent
  )})`;

  return WithPagination;
};

export default withPagination;
