// @ts-nocheck
// Taken and modified from https://github.com/guillaumepotier/gettext.js/blob/master/lib/gettext.js
//
// Changes:
//   - ES6 syntax
//   - Added an optional postTransformation function applied after each translation
//   - Supports React elements as interpolation values
import * as React from 'react';
import * as reactStringReplace from 'react-string-replace';

// default values that could be overriden using setters
const defaults = {
  domain: 'messages',
  locale:
    (document ? document.documentElement.getAttribute('lang') : false) || 'en',
  plural_func: function (n) {
    return { nplurals: 2, plural: n !== 1 ? 1 : 0 };
  },
  ctxt_delimiter: String.fromCharCode(4), // \u0004
} as const;

// handy mixins taken from underscode.js
const _ = {
  isObject: function (obj) {
    const type = typeof obj;
    return type === 'function' || (type === 'object' && !!obj);
  },
  isArray: function (obj) {
    return toString.call(obj) === '[object Array]';
  },
} as const;

let _plural_funcs: Record<string, any> = {},
  _locale = defaults.locale,
  _domain = defaults.domain,
  _dictionary: Record<string, any> = {},
  _plural_forms: Record<string, any> = {},
  _ctxt_delimiter = defaults.ctxt_delimiter,
  _postTransformation = translation => translation;

// sprintf equivalent, takes a string and some arguments to make a computed string
// eg: strfmt("%1 dogs are in %2", 7, "the kitchen"); => "7 dogs are in the kitchen"
// eg: strfmt("I like %1, bananas and %1", "apples"); => "I like apples, bananas and apples"
export const strfmt = function (fmt) {
  const args = arguments;

  let anyArgumentIsReactElement = false;

  for (let arg of Array.from(args)) {
    anyArgumentIsReactElement =
      anyArgumentIsReactElement || React.isValidElement(arg);
  }

  let translation;
  if (anyArgumentIsReactElement) {
    translation = reactStringReplace(fmt, /%(\d+)/g, function (match, i) {
      const replacement = args[match];

      if (!React.isValidElement(replacement)) {
        return replacement;
      }

      return React.cloneElement(replacement, {
        key: i,
      });
    });
  } else {
    translation = fmt.replace(/%(\d+)/g, function (str, p1) {
      return args[p1];
    });
  }

  return _postTransformation(translation);
};

const getPluralFunc = function (plural_form) {
  // Plural form string regexp
  // taken from https://github.com/Orange-OpenSource/gettext.js/blob/master/lib.gettext.js
  // plural forms list available here http://localization-guide.readthedocs.org/en/latest/l10n/pluralforms.html
  const pf_re = new RegExp(
    '^\\s*nplurals\\s*=\\s*[0-9]+\\s*;\\s*plural\\s*=\\s*(?:\\s|[-\\?\\|&=!<>+*/%:;n0-9_()])+'
  );

  if (!pf_re.test(plural_form))
    throw new Error(strfmt('The plural form "%1" is not valid', plural_form));

  // Careful here, this is a hidden eval() equivalent..
  // Risk should be reasonable though since we test the plural_form through regex before
  // taken from https://github.com/Orange-OpenSource/gettext.js/blob/master/lib.gettext.js
  // TODO: should test if https://github.com/soney/jsep present and use it if so
  // eslint-disable-next-line
  const pluralFunc = new Function(
    'n',
    `var plural, nplurals; ${plural_form} return { nplurals: nplurals, plural: (plural === true ? 1 : (plural ? plural : 0)) };`
  );
  return pluralFunc;
};

// Proper translation function that handle plurals and directives
// Contains juicy parts of https://github.com/Orange-OpenSource/gettext.js/blob/master/lib.gettext.js
const t = function (messages, n, options /* ,extra */) {
  // Singular is very easy, just pass dictionnary message through strfmt
  if (1 === messages.length)
    return strfmt.apply(
      this,
      [messages[0]].concat(Array.prototype.slice.call(arguments, 3))
    );

  let plural;

  // if a plural func is given, use that one
  if (options.plural_func) {
    plural = options.plural_func(n);

    // if plural form never interpreted before, do it now and store it
  } else if (!_plural_funcs[_locale]) {
    _plural_funcs[_locale] = getPluralFunc(_plural_forms[_locale]);
    plural = _plural_funcs[_locale](n);

    // we have the plural function, compute the plural result
  } else {
    plural = _plural_funcs[_locale](n);
  }

  // If there is a problem with plurals, fallback to singular one
  if (
    'undefined' === typeof plural.plural ||
    plural.plural > plural.nplurals ||
    messages.length <= plural.plural
  )
    plural.plural = 0;

  return strfmt.apply(
    this,
    [messages[plural.plural], n].concat(
      Array.prototype.slice.call(arguments, 3)
    )
  );
};

export const __ = function () {
  return this.gettext.apply(this, arguments);
};
export const _n = function () {
  return this.ngettext.apply(this, arguments);
};
export const _p = function () {
  return this.pgettext.apply(this, arguments);
};

export const setMessages = function (domain, locale, messages, plural_forms) {
  if (!domain || !locale || !messages)
    throw new Error('You must provide a domain, a locale and messages');

  if (
    'string' !== typeof domain ||
    'string' !== typeof locale ||
    !_.isObject(messages)
  )
    throw new Error('Invalid arguments');

  if (plural_forms) _plural_forms[locale] = plural_forms;

  if (!_dictionary[domain]) _dictionary[domain] = {};

  _dictionary[domain][locale] = messages;

  return this;
};

export const loadJSON = function (jsonData, domain?: string) {
  if (!_.isObject(jsonData)) jsonData = JSON.parse(jsonData);

  if (
    !jsonData[''] ||
    !jsonData['']['language'] ||
    !jsonData['']['plural-forms']
  )
    throw new Error(
      'Wrong JSON, it must have an empty key ("") with "language" and "plural-forms" information'
    );

  const headers = jsonData[''];
  delete jsonData[''];

  return setMessages(
    domain || defaults.domain,
    headers['language'],
    jsonData,
    headers['plural-forms']
  );
};

export const setLocale = function (locale) {
  _locale = locale;
  return this;
};

export const getLocale = function () {
  return _locale;
};

export const textdomain = function (domain) {
  if (!domain) return _domain;
  _domain = domain;
  return this;
};

export const gettext = function (msgid /* , extra */) {
  return this.dcnpgettext.apply(
    this,
    [undefined, undefined, msgid, undefined, undefined].concat(
      Array.prototype.slice.call(arguments, 1)
    )
  );
};
export const ngettext = function (msgid, msgid_plural, n /* , extra */) {
  return this.dcnpgettext.apply(
    this,
    [undefined, undefined, msgid, msgid_plural, n].concat(
      Array.prototype.slice.call(arguments, 3)
    )
  );
};
export const pgettext = function (msgctxt, msgid /* , extra */) {
  return this.dcnpgettext.apply(
    this,
    [undefined, msgctxt, msgid, undefined, undefined].concat(
      Array.prototype.slice.call(arguments, 2)
    )
  );
};

export const dcnpgettext = function (
  domain,
  msgctxt,
  msgid,
  msgid_plural,
  n /* , extra */
) {
  domain = domain || _domain;

  if ('string' !== typeof msgid)
    throw new Error(
      this.strfmt('Msgid "%1" is not a valid translatable string', msgid)
    );

  let translation,
    options: Record<string, any> = {},
    key = msgctxt ? msgctxt + _ctxt_delimiter + msgid : msgid,
    exist =
      _dictionary[domain] &&
      _dictionary[domain][_locale] &&
      _dictionary[domain][_locale][key];

  // because it's not possible to define both a singular and a plural form of the same msgid,
  // we need to check that the stored form is the same as the expected one.
  // if not, we'll just ignore the translation and consider it as not translated.
  if (msgid_plural) {
    exist = exist && 'string' !== typeof _dictionary[domain][_locale][key];
  } else {
    exist = exist && 'string' === typeof _dictionary[domain][_locale][key];
  }

  if (!exist) {
    translation = msgid;
    options.plural_func = defaults.plural_func;
  } else {
    translation = _dictionary[domain][_locale][key];
  }

  // Singular form
  if (!msgid_plural)
    return t.apply(
      this,
      [[translation], n, options].concat(
        Array.prototype.slice.call(arguments, 5)
      )
    );

  // Plural one
  return t.apply(
    this,
    [exist ? translation : [msgid, msgid_plural], n, options].concat(
      Array.prototype.slice.call(arguments, 5)
    )
  );
};

export const setPostTransformation = postTransformation =>
  (_postTransformation = postTransformation);
