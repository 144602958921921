import React from 'react';

import { __ } from 'helpers/i18n';

import { IOption, Select } from 'components';

import { EditableFieldProps } from '../EditableField';

const SSOField = ({ value, onChange }: EditableFieldProps) => {
  const options: Array<IOption> = [
    {
      label: __('Yes'),
      value: true,
    },
    {
      label: __('No'),
      value: false,
    },
  ];

  return (
    <Select
      value={options.find(opt => opt.value === value) || options[1]}
      options={options}
      onChange={choice => onChange(choice?.value)}
      isClearable={false}
    />
  );
};

export default SSOField;
