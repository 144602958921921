import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useActiveUser } from 'helpers/hooks';

import { ContentContainer, DesignSystem, Route, Switch } from 'components';

import ENPSSettings from './ENPSSettings';
import LegacySettings from './LegacySettings';
import ObjectivesSettings from './ObjectivesSettings';
import ReviewsSettings from './ReviewsSettings';
import ThreeSixtySettings from './ThreeSixtySettings';
import TrainingSettings from './TrainingSettings';

const SettingsRoutes = () => {
  const { path } = useRouteMatch();
  const user = useActiveUser();

  if (!user.isOrganizationAdmin) return null;

  return (
    <DesignSystem version={2}>
      <Switch>
        <ContentContainer>
          <Route exact path={path} component={LegacySettings} />
          <Route path={`${path}/reviews`} component={ReviewsSettings} />
          <Route path={`${path}/objectives`} component={ObjectivesSettings} />
          <Route path={`${path}/360`} component={ThreeSixtySettings} />
          <Route path={`${path}/training`} component={TrainingSettings} />
          <Route path={`${path}/enps`} component={ENPSSettings} />
        </ContentContainer>
      </Switch>
    </DesignSystem>
  );
};

export default SettingsRoutes;
