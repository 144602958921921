import React from 'react';

import type { User } from 'models';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  BoxList,
  Column,
  Columns,
  FetchContainer,
  viewPortAware,
} from 'components';

import UsersList from 'scenes/components/user/UsersList';

import TabletReportCard from './TabletReportCard';

export const DIRECT_REPORTS_REQUEST_ID = 'direct_reports';

type Props = {
  activeUser: User;
};

type AfterConnectProps = Props & {
  directReports: Array<User>;
  isFetching: boolean;
  hasError: boolean;
};

const DirectReportsMobile = ({
  directReports,
  isFetching,
  hasError,
}: AfterConnectProps) => {
  return (
    <Box>
      <BoxList>
        <UsersList
          users={directReports}
          isFetching={isFetching}
          hasError={hasError}
          displayActions={false}
        />
      </BoxList>
    </Box>
  );
};

const DirectReportsTablet = ({
  directReports,
  isFetching,
  hasError,
}: AfterConnectProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => (
        <Columns isMultiline>
          {directReports.map(report => (
            <Column
              desktopSize={4}
              tabletSize={6}
              isVerticallyCentered
              key={report.id}
            >
              <TabletReportCard report={report} />
            </Column>
          ))}
        </Columns>
      )}
    />
  );
};

const DirectReports = viewPortAware({
  mobile: DirectReportsMobile,
  tablet: DirectReportsTablet,
});

export default newDataLoader({
  fetch: ({ activeUser }) => get(`users/${activeUser.id}/direct_reports`),
  hydrate: {
    directReports: {
      quickLinks: {},
    },
  },
  cacheKey: () => DIRECT_REPORTS_REQUEST_ID,
})(DirectReports) as React.ComponentType<Props>;
