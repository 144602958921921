import React, { useEffect, useRef, useState } from 'react';

import { useIsVisible } from 'helpers/hooks';

type OptionProps<TProps> = {
  delay: number;
  mockClass: React.ComponentType<TProps>;
};

const withLoadingOnViewportVisibility = <T,>(options: OptionProps<T>) => {
  return (OriginalComponent: React.ComponentType<T>) => {
    const NewComponent: React.ComponentType<T> = (originalProps: T) => {
      const { ref, isVisible } = useIsVisible();
      const [canDisplay, setCanDisplay] = useState(false);
      const isVisibleRef = useRef(false);
      const timeout = useRef(null);

      useEffect(() => {
        if (canDisplay) {
          return;
        }

        const launchTimer = () => {
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          timeout.current = setTimeout(() => {
            setCanDisplay(isVisibleRef.current);

            timeout.current = null;
          }, options.delay);
        };

        isVisibleRef.current = isVisible;

        if (!timeout.current && isVisible) {
          launchTimer();
        }
      }, [isVisible, canDisplay]);

      const Component = canDisplay ? OriginalComponent : options.mockClass;

      return (
        <div ref={ref}>
          {/** @ts-ignore TSFIXME: Fix strictNullChecks error **/}
          <Component {...originalProps} />
        </div>
      );
    };

    return NewComponent;
  };
};

export default withLoadingOnViewportVisibility;
