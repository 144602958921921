import React from 'react';

import { Text } from 'components';

type Props = { colors: string[]; colorThresholds: number[] };

const Legend = ({ colors, colorThresholds }: Props) => {
  const getIntervalText = (index: number) => {
    if (index === 0) return '0%';

    const lowerThreshold = colorThresholds[index - 1] + 1;
    const upperThreshold = colorThresholds[index];
    if (lowerThreshold === upperThreshold) return `${upperThreshold}%`;
    return `${lowerThreshold}% - ${upperThreshold}%`;
  };

  return (
    <div className="heatmap__legend">
      {colors.map((color, index) => (
        <Text preset="14s6" key={index}>
          <span
            className="heatmap__legend-color-sample"
            style={{
              backgroundColor: color,
            }}
          ></span>
          {getIntervalText(index)}
        </Text>
      ))}
    </div>
  );
};

export default Legend;
