import DOMPurify from 'dompurify';
import React, { CSSProperties } from 'react';

type Props = {
  componentType?: 'div' | 'span' | 'p';
  componentProps?: Object;
  html: string | null | undefined;
  style?: CSSProperties;
};

export default function UnsafeRawHtml({
  componentType = 'span',
  componentProps,
  html,
  style,
}: Props) {
  // The sanitization we are doing here is a backup, complete sanitization is done in the backend.
  // We had to add the tag iframe to deal with some client when they have template
  // without rich text and soft sanitization enabled.
  // We choose to not pass return the column `soft_sanitization_enabled` from backend and less passing this info
  // throw all component to avoid lot of code lines and mainly because it's a backup.

  const sanitizedHTML = DOMPurify.sanitize(html || '', {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['frameborder', 'target'],
  });

  // @ts-ignore TSFIXME: Fix strictNullChecks error
  return React.createElement(componentType, {
    style,
    dangerouslySetInnerHTML: { __html: sanitizedHTML },
    className: componentType === 'span' ? 'content' : '',
    ...componentProps,
  });
}
