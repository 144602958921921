import React from 'react';

import type { FormErrors } from 'helpers/api';

import { __ } from 'helpers/i18n';

import { DatePicker, Field, FieldError, Label } from 'components';

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (startDate: Date | null) => void;
  setEndDate: (endDate: Date | null) => void;
  errors: FormErrors;
};

const DateInputs = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  errors,
}: Props) => {
  const maxDateForStartDate = () => {
    if (!endDate) {
      return undefined;
    }
    const maxDate = new Date(endDate);
    maxDate.setDate(maxDate.getDate() - 1);
    return maxDate;
  };

  const minDateForEndDate = () => {
    if (!startDate) {
      return undefined;
    }
    const minDate = new Date(startDate);
    minDate.setDate(minDate.getDate() + 1);
    return minDate;
  };

  return (
    <div className="flex w-100 gap-4">
      <Field additionalClassName="flex-1">
        <Label>{__('Start date')}</Label>
        <DatePicker
          value={startDate}
          maxDate={maxDateForStartDate()}
          onChange={startDate => setStartDate(startDate || null)}
          placeholder={__('Choose a date')}
          isFullWidth
        />
        <FieldError>{errors?.startDate}</FieldError>
      </Field>
      <Field additionalClassName="flex-1">
        <Label>{__('End date')}</Label>
        <DatePicker
          value={endDate}
          onChange={endDate => setEndDate(endDate || null)}
          minDate={minDateForEndDate()}
          placeholder={__('Choose a date')}
          isFullWidth
        />
        <FieldError>{errors?.endDate}</FieldError>
      </Field>
    </div>
  );
};

export default DateInputs;
