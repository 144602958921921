import React from 'react';

import type { ReviewCycle } from 'models';

import { __ } from 'helpers/i18n';

import {
  Box,
  DesignSystem,
  EmptyState as EmptyStateComponent,
} from 'components';

import cycleLaunchedSvg from 'scenes/admin/components/svg/cycleLaunchedSvg';

type Props = {
  reviewCycle: ReviewCycle;
};

export default function LaunchedEmptyState({ reviewCycle }: Props) {
  let description = __(
    'Once a participant will be added to your campaign it will appear in this page.'
  );

  if (reviewCycle.autoAddParticipantsEnabled) {
    description += '\n' + __('We will check eligible employees each morning.');
  }

  return (
    <DesignSystem version={2}>
      <Box style={{ marginTop: 40 }}>
        <EmptyStateComponent
          title={__('Your campaign has been launched!')}
          description={description}
          src={`data:image/svg+xml;base64,${window.btoa(cycleLaunchedSvg)}`}
        />
      </Box>
    </DesignSystem>
  );
}
