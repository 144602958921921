import { SkillsLevel } from 'models/skills';

import { useAppSelector } from 'helpers/hooks';

import { hydrateFromStore } from 'lib/dataLoader';

const useReviewedSkillsLevel = (id: string): SkillsLevel => {
  const getCareerLevel = state =>
    hydrateFromStore(
      state.data,
      { resourceType: 'skillsCareerLevel', id },
      { item: { level: {} } }
    );

  const careerLevel = useAppSelector(getCareerLevel);

  return careerLevel.level;
};

export default useReviewedSkillsLevel;
