import React, { useEffect, useRef, useState } from 'react';

import type { PeopleReviewCycleShow, UserFilterSegment } from 'models';

import { history } from 'config/history';

import can from 'helpers/can';
import { useMultiSelect } from 'helpers/hooks';
import queryString from 'helpers/queryString';

import { parsePaginationParams } from 'lib/dataLoader/pagination/paginationParams';

import UserFilter from 'scenes/components/UserFilter';

import PeopleGroupList from './PeopleGroupList';
import PeopleUserReviewListWithPagination from './PeopleUserReviewListWithPagination';

type Props = {
  peopleReviewCycle: PeopleReviewCycleShow;
};

const Participants = ({ peopleReviewCycle }: Props) => {
  const urlQueryParams = () =>
    parsePaginationParams(queryString.parse(window.location.search));
  const [userFilter, setUserFilter] = useState<UserFilterSegment | null>(
    urlQueryParams().userFilter || null
  );
  const [selectedPeopleGroupIds, togglePeopleGroupSelection] =
    useMultiSelect<string>(urlQueryParams().query?.peopleGroupIds || []);

  const onUserFilterChange = (newUserFilter: UserFilterSegment | null) => {
    setUserFilter(newUserFilter);
    const params = { ...urlQueryParams(), userFilter: newUserFilter, page: 1 };
    history.replace(
      history.location.pathname + '?' + queryString.stringify(params)
    );
  };

  const [shouldRefetchReviews, setShouldRefetchReviews] = useState(false);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const params = {
      ...urlQueryParams(),
      query: { peopleGroupIds: selectedPeopleGroupIds },
      page: 1,
    };
    history.replace(
      history.location.pathname + '?' + queryString.stringify(params)
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeopleGroupIds.join(',')]);

  const canEditPeopleGroups = can({
    perform: 'edit_groups',
    on: peopleReviewCycle,
  });

  return (
    <React.Fragment>
      <UserFilter
        segment={userFilter}
        onChange={onUserFilterChange}
        additionalClassName="mb-6"
      />

      <PeopleGroupList
        cycleId={peopleReviewCycle.id}
        selectedPeopleGroupIds={selectedPeopleGroupIds}
        togglePeopleGroupSelection={togglePeopleGroupSelection}
        userFilterSegment={userFilter}
        canEditPeopleGroups={canEditPeopleGroups}
        setShouldRefetchReviews={setShouldRefetchReviews}
      />

      <PeopleUserReviewListWithPagination
        paginationType="url"
        defaultPaginationParams={{ filter: { all: true } }}
        peopleReviewCycle={peopleReviewCycle}
        shouldRefetchReviews={shouldRefetchReviews}
        setShouldRefetchReviews={setShouldRefetchReviews}
      />
    </React.Fragment>
  );
};

export default Participants;
