import React from 'react';

import type { TrainingRequest } from 'models';

import { approvalStatusLabelWithIcon } from 'helpers/models/trainingRequest';

import {
  BoxListItem,
  Column,
  Columns,
  Date,
  Number,
  TooltipOnEllipsis,
} from 'components';

type Props = {
  trainingRequest: TrainingRequest;
};

export default function TrainingRequestItem({ trainingRequest }: Props) {
  return (
    <BoxListItem>
      <Columns
        style={{
          alignItems: 'center',
        }}
      >
        <Column size={4}>
          <TooltipOnEllipsis style={{ maxWidth: 400 }}>
            {trainingRequest.title}
          </TooltipOnEllipsis>
        </Column>
        <Column size={2}>
          <Date value={trainingRequest.session?.startDate} />
        </Column>
        <Column size={2}>
          <Date value={trainingRequest.session?.endDate} />
        </Column>
        <Column size={1}>
          <Number
            value={trainingRequest.session?.durationInHours || null}
            suffix="h"
          />
        </Column>
        <Column size={3}>
          {approvalStatusLabelWithIcon(trainingRequest.approvalStatus)}
        </Column>
      </Columns>
    </BoxListItem>
  );
}
