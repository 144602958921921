import React from 'react';

import { Title } from 'components';

export type Props = {
  title: string;
  description: string;
  imgSrc: string;
};

const AdvantageCard = ({ title, description, imgSrc }: Props) => {
  return (
    <div>
      <Title size={3} weight="semibold" color="primary">
        {title}
      </Title>

      <p style={{ marginBottom: 24 }}>{description}</p>
      <figure className="image">
        <img
          src={imgSrc}
          alt={title}
          style={{
            width: 'auto',
            margin: '0 auto',
          }}
        />
      </figure>
    </div>
  );
};

export default AdvantageCard;
