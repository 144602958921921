import tailwindTheme from 'config/tailwindTheme';

const DEFAULT_COLORS: { [key: number]: string } = {
  0: tailwindTheme.colors.indigo[500],
  1: tailwindTheme.colors.pink[500],
  2: tailwindTheme.colors.green[500],
  3: tailwindTheme.colors.red[500],
  4: tailwindTheme.colors.purple[500],
  5: tailwindTheme.colors.yellow[400],
  6: tailwindTheme.colors.indigo[500],
  7: tailwindTheme.colors.basic[500],
  8: tailwindTheme.colors.indigo[700],
  9: tailwindTheme.colors.pink[700],
  10: tailwindTheme.colors.green[700],
  11: tailwindTheme.colors.purple[700],
  12: tailwindTheme.colors.yellow[600],
  13: tailwindTheme.colors.basic[700],
  14: tailwindTheme.colors.indigo[300],
  15: tailwindTheme.colors.pink[300],
  16: tailwindTheme.colors.green[300],
  17: tailwindTheme.colors.red[300],
  18: tailwindTheme.colors.purple[300],
  19: tailwindTheme.colors.yellow[300],
};

export const formatData = data => {
  const maxDataValue = Math.max(...data.map(item => item.percent));
  const barWidthMultiplier = maxDataValue === 0 ? 0 : 100 / maxDataValue;
  const sortedData = data.sort((a, b) => b.percent - a.percent);

  return sortedData.reduce((acc, item, index) => {
    return [
      ...acc,
      {
        ...item,
        color:
          item.color ||
          DEFAULT_COLORS[index % Object.keys(DEFAULT_COLORS).length],
        width: `${item.percent * barWidthMultiplier}%`,
      },
    ];
  }, []);
};
