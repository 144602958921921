import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import type { Organization, User } from '../../models';

import { withActiveUserAndOrganization } from '../../helpers/withSessionProps';

type RouteAliasProps = {
  from: string;
  to: string;
};

export function RouteAlias({ from, to }: RouteAliasProps) {
  return <Route path={from} exact render={() => <Redirect to={to} />} />;
}

type OnlyLoggedOutRouteProps = {
  component: React.ComponentType<any>;
  path: string;
  activeUser: User | null;
  organization: Organization | null;
};

const _OnlyLoggedOutRoute = ({
  activeUser,
  organization,
  component: Component,
  ...rest
}: OnlyLoggedOutRouteProps) => (
  <Route
    {...rest}
    render={props =>
      !(activeUser && organization) ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export const OnlyLoggedOutRoute =
  withActiveUserAndOrganization(_OnlyLoggedOutRoute);
