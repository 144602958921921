import * as url from 'helpers/url';

export type BreadcrumbAnchorName = 'user_review_list';

class Breadcrumb {
  anchors: Partial<Record<BreadcrumbAnchorName, string>>;
  defaultAnchors: Partial<Record<BreadcrumbAnchorName, string>>;

  constructor() {
    this.anchors = {};
    this.defaultAnchors = {};
  }

  setAnchor(anchorName: BreadcrumbAnchorName, href: string) {
    let typeQuery = '';
    const type = new URL(href).searchParams.get('type');
    // @ts-ignore TSFIXME: Fix strictNullChecks error
    typeQuery = ['360', 'one_on_one'].includes(type)
      ? `?type=${type || ''}`
      : '';
    this.anchors[anchorName] = url
      .getPathForAbsoluteHref(href)
      .concat(typeQuery);
  }

  setDefaultAnchor(anchorName: BreadcrumbAnchorName, href: string) {
    this.defaultAnchors[anchorName] = url.getPathForAbsoluteHref(href);
  }

  getAnchor(anchorName: BreadcrumbAnchorName) {
    return this.anchors[anchorName] || this.defaultAnchors[anchorName];
  }
}

const breadcrumb = new Breadcrumb();

export default breadcrumb;
