import { sortBy as _sortBy } from 'lodash';
import React from 'react';

import type { ThreeSixtyReviewCycle, ThreeSixtyUserReview } from 'models';

import { __ } from 'helpers/i18n';

import {
  BoxList,
  BoxListItem,
  Column,
  Columns,
  FetchContainer,
  Icon,
  Text,
} from 'components';

import UserReviewItem from './UserReviewItem';

type Props = {
  userReviews: Array<ThreeSixtyUserReview>;
  reviewCycle: ThreeSixtyReviewCycle;
  refetchData: () => Promise<void>;
  isFetching: boolean;
  hasError: boolean;
};

export default function UserReviewList({
  userReviews,
  reviewCycle,
  refetchData,
  isFetching,
  hasError,
}: Props) {
  return (
    <BoxList>
      <BoxListItem isSmall>
        <Columns>
          <Column isNarrow>
            <Icon isInfo name="do_not_disturb_on" />
          </Column>
          <Column size={3}>
            <Text preset="14bs6" color="light">
              <b>{__('Reviewee')}</b>
            </Text>
          </Column>
          <Column size={3}>
            <Text preset="14bs6" color="light">
              <b>{__('Peers')}</b>
            </Text>
          </Column>
          <Column size={2}>
            <Text preset="14bs6" color="light">
              <b>{__('Responsible')}</b>
            </Text>
          </Column>
          <Column size={2}>
            <Text preset="14bs6" color="light">
              <b>{__('Status')}</b>
            </Text>
          </Column>
          <Column isNarrow>
            <Icon
              style={{ visibility: 'hidden', width: '36px' }}
              name="do_not_disturb_on"
            />
          </Column>
        </Columns>
      </BoxListItem>

      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        loadingStyle="overlay"
        render={() =>
          _sortBy(userReviews, userReview => userReview.user.fullName).map(
            userReview => {
              return (
                <UserReviewItem
                  key={userReview.id}
                  userReview={userReview}
                  reviewCycle={reviewCycle}
                  refetchData={refetchData}
                />
              );
            }
          )
        }
      />
    </BoxList>
  );
}
