import React from 'react';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { Select } from 'components';

type Props = {
  objectivesUpdatableByReviewee: boolean;
  onChange: (value: boolean) => Promise<any>;
};

type Option = {
  label: string;
  value: string;
};

const getOptions = (): Array<Option> => [
  {
    label: __('Reviewer and reviewee'),
    value: 'true',
  },
  {
    label: __('Reviewer only'),
    value: 'false',
  },
];

const ObjectivesDefinitionRightsPicker = ({
  objectivesUpdatableByReviewee,
  onChange,
}: Props) => {
  const selectedOption = getOptions().find(
    option => option.value === objectivesUpdatableByReviewee.toString()
  );

  invariant(selectedOption, 'RestrictedToManager is either true or false');

  return (
    <Select
      value={selectedOption}
      onChange={option =>
        option && !Array.isArray(option) && onChange(option.value === 'true')
      }
      options={getOptions()}
      isClearable={false}
      isSearchable={false}
    />
  );
};

export default ObjectivesDefinitionRightsPicker;
