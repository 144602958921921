import React, { UIEvent } from 'react';

import type { StyleProps as ButtonStyleProps } from 'components/buttons/Button';

import colors from 'styles/colors';

import classNames from 'helpers/classNames';

import {
  Button,
  DesignSystem,
  Dropdown,
  DropdownTrigger,
  Icon,
  Tooltip,
} from 'components';

import { MenuList } from './index';

type Props = ButtonStyleProps & {
  disabled?: boolean;
  disabledMessage?: string;
  align?: 'right' | 'left';
  additionalClassName?: string;
  testClassName?: string;
  children?: React.ReactElement<React.ComponentProps<typeof MenuList>>;
  onClose?: (e: KeyboardEvent | UIEvent | undefined) => void;
};

export default function HamburgerMenu({
  children,
  disabled,
  disabledMessage,
  align,
  additionalClassName,
  testClassName,
  onClose,
  ...buttonStyleProps
}: Props) {
  const child = !!children ? React.Children.only(children) : children;

  return (
    <Dropdown onClose={onClose} align={align || 'right'} disabled={disabled}>
      <DropdownTrigger>
        <Tooltip content={disabled && disabledMessage}>
          <HamburgerMenuButton
            {...buttonStyleProps}
            disabled={disabled}
            additionalClassName={additionalClassName}
            testClassName={testClassName}
          />
        </Tooltip>
      </DropdownTrigger>
      {child}
    </Dropdown>
  );
}

type HamburgerMenuButtonProps = ButtonStyleProps & {
  disabled?: boolean;
  dropdownIsOpened?: boolean;
  additionalClassName?: string;
  testClassName?: string;
};

const HamburgerMenuButton = ({
  dropdownIsOpened,
  disabled,
  size,
  additionalClassName,
  testClassName,
  ...buttonStyleProps
}: HamburgerMenuButtonProps) => {
  let color: undefined | 'white';

  if (disabled || !dropdownIsOpened) {
    color = 'white';
  }

  return (
    <DesignSystem
      render={version => {
        if (version === 1) {
          return (
            <Button
              {...buttonStyleProps}
              color={buttonStyleProps.color || color}
              disabled={disabled}
              size={size}
              additionalClassName={classNames(
                'hamburger-menu-button',
                additionalClassName,
                testClassName
              )}
            >
              <Icon size={size} color={colors.mediumGrey} name="more_vert" />
            </Button>
          );
        }

        return (
          <Button
            color="secondary"
            hasIconOnly
            disabled={disabled}
            additionalClassName={additionalClassName}
            testClassName={testClassName}
          >
            <Icon color={colors.primaryColor} name="more_vert" />
          </Button>
        );
      }}
    />
  );
};
