export type WithShape<Source, Shape> = Source & Shape;

/**
 * Helps typing unidentified objects, like errors thrown that have type unknown
 * by default.
 *
 * # Basic usage
 *
 * This type predicate helps typing the object shape correctly:
 *
 * ```ts
 * if (
 *   hasShape(error, {
 *     response: {
 *       body: stringType
 *     }
 *   })
 * ) {}
 * ```
 *
 * Intead of checking manually:
 *
 * ```js
 * if (
 *   typeof error === 'object' &&
 *   error !== null &&
 *   'response' in error &&
 *   typeof error.response === 'object' &&
 *   ...
 * ) {}
 * ```
 *
 * # Advanced usage
 *
 * Primitives: The shape can also contain primitive types. These types can be
 * imported from the module.
 *
 * Arrays: Wrap the type within an array. The array must strictly have one item.
 *
 * ```ts
 * import {
 *   hasShape,
 *   stringType,
 *   numberType,
 *   booleanType,
 *   unknownType,
 *   undefinedType,
 *   nullType
 * } from 'types/predicates/WithShape';
 *
 * hasShape(obj, {
 *   name: stringType,
 *   age: numberType,
 *   alive: booleanType,
 *   gender: undefinedType,
 *   wings: nullType,
 *   mysteriousProp: unknownType,
 *   skills: [stringType]
 * })
 * ```
 *
 * @param source Object to check against the Shape
 * @param shape An object representing the expected Source shape
 * @returns
 */
export function hasShape<Source, Shape>(
  source: Source,
  shape: Shape
): source is WithShape<Source, Shape> {
  if (expectPrimitiveType(shape)) {
    return isPrimitive(source, shape);
  } else if (expectObjectShape(shape)) {
    return hasObjectShape(source, shape);
  } else if (expectArrayShape(shape)) {
    return hasArrayShape(source, shape);
  } else {
    throw new InvalidShapeError('This shape is not handled');
  }
}

export const stringType: string = '';
export const numberType: number = 0;
export const booleanType: boolean = false;
export const unknownType: unknown = {};
export const undefinedType: undefined = undefined;
export const nullType: null = null;

export class InvalidShapeError extends Error {
  constructor(reason: string) {
    super(`The provided shape is invalid: ${reason}`);
  }
}

function expectPrimitiveType<Shape>(shape: Shape): boolean {
  return [
    stringType,
    numberType,
    booleanType,
    unknownType,
    nullType,
    undefinedType,
  ].includes(shape);
}

function isPrimitive<Source, Shape>(
  source: Source,
  shape: Shape
): source is WithShape<Source, Shape> {
  if (shape === unknownType) {
    return true;
  } else if (shape === nullType) {
    return source === null;
  } else {
    return typeof source === typeof shape;
  }
}

function expectObjectShape<Shape>(shape: Shape): boolean {
  return typeof shape === 'object' && shape !== null;
}

function hasObjectShape<Source, Shape>(
  source: Source,
  shape: Shape
): source is WithShape<Source, Shape> {
  if (typeof source !== 'object' || source === null) return false;
  for (const key in shape) {
    if (hasKey(source, key)) {
      if (!hasShape(source[key], shape[key])) return false;
    } else return false;
  }
  return true;
}

function hasKey<Obj extends object, Key extends string>(
  obj: Obj,
  key: Key
  // eslint-disable-next-line no-unused-vars
): obj is Obj & { [key in Key]: unknown } {
  return key in obj;
}

function expectArrayShape<Shape>(shape: Shape): shape is Shape & unknown[] {
  return Array.isArray(shape);
}

function hasArrayShape<Shape extends unknown[], Source>(
  source: Source,
  shape: Shape
): source is WithShape<Source, Shape> {
  if (shape.length !== 1) {
    throw new InvalidShapeError('Array shapes must have strictly one item');
  }
  if (!Array.isArray(source)) return false;
  const shapeItemType = shape[0];
  for (const i in source) {
    if (!hasShape(source[i], shapeItemType)) return false;
  }
  return true;
}
