import React from 'react';

import type { TrainingRequest } from 'models';

import { BoxList, BoxListItem, Link } from 'components';
import TrainingRequestEmptyState from 'components/TrainingRequestEmptyState';

import TrainingRequestListItem from './TrainingRequestListItem';

type Props = {
  emptyStateTitle: string;
  trainingRequests: Array<TrainingRequest>;
  link?: {
    pathTo: string;
    label: string;
  } | null;
};

export default function TrainingRequestList({
  trainingRequests,
  link,
  emptyStateTitle,
}: Props) {
  if (trainingRequests.length === 0) {
    return <TrainingRequestEmptyState title={emptyStateTitle} />;
  }
  return (
    <BoxList style={{ margin: 0 }}>
      {trainingRequests.map((trainingRequest, index) => (
        <TrainingRequestListItem
          key={`training-request-item-${index}`}
          trainingRequest={trainingRequest}
        />
      ))}

      {!!link && (
        <BoxListItem key="training-request-history-link">
          <Link openInNewTab to={link.pathTo}>
            {link.label}
          </Link>
        </BoxListItem>
      )}
    </BoxList>
  );
}
