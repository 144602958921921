import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { Control, FeatureFlagged, Icon, Input, Tooltip } from 'components';

type Props = {
  value: string | null | undefined;
};

function LockedField({ value }: Props) {
  const organization = useOrganization();

  return (
    <Control additionalClassName="has-icons-right">
      <Input value={value || ''} disabled />

      <FeatureFlagged flag="overrideSyncedFields" inverseDefaultBehavior>
        <Tooltip
          content={__(
            'Synchronized using %1',
            organization.syncIntegrationInfo.name
          )}
        >
          <Icon size="small" additionalClassName="is-right" name="lock" />
        </Tooltip>
      </FeatureFlagged>
    </Control>
  );
}

export default LockedField;
