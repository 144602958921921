// @flow
import * as React from 'react';
import { compose } from 'redux';

import type { ReviewCycle, User } from 'models';

import { __ } from 'helpers/i18n';
import { withActiveUser } from 'helpers/withSessionProps';

import { ContentContainer } from 'components';

import Results from 'scenes/admin/reviewCycles/Manage/Results';

type Props = {|
  reviewCycle: ReviewCycle,
|};

type AfterConnectProps = {|
  ...Props,
  activeUser: User,
|};

function ManagerCycleResults({ reviewCycle, activeUser }: AfterConnectProps) {
  const { fullName, id } = activeUser;

  return (
    <ContentContainer>
      <Results
        reviewCycle={reviewCycle}
        defaultUserFilter={{
          filterLabel: __('Direct reports of'),
          filterType: 'direct_reports',
          label: fullName,
          value: id,
        }}
      />
    </ContentContainer>
  );
}

export default compose(withActiveUser)(ManagerCycleResults);
