import React from 'react';

import type { Organization, PeopleUserReview } from 'models';

import { pathToUser } from 'helpers/navigation';
import { withOrganization } from 'helpers/withSessionProps';

import { Can, Divider } from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

import ImportedReviewHistory from './sections/ImportedReviewHistory';
import OneOnOneReviewHistory from './sections/OneOnOneReviewHistory';
import PeopleReviewHistory from './sections/PeopleReviewHistory';
import ThreeSixtyReviewHistory from './sections/ThreeSixtyReviewHistory';
import TrainingRequestHistory from './sections/TrainingRequestHistory';

type Props = {
  review: PeopleUserReview;
};

type AfterConnectProps = Props & {
  organization: Organization;
};

function Sidebar({ review, organization }: AfterConnectProps) {
  const { user } = review;

  return (
    <React.Fragment>
      <UserAvatar
        user={user}
        link={pathToUser(user.id)}
        style={{ flexDirection: 'column', alignItems: 'flex-start' }}
        size="large"
        withJobTitle
        withWorkStartDate
      />
      <Divider />

      <PeopleReviewHistory user={user} skipIds={[review.id]} />
      <Divider />

      <OneOnOneReviewHistory user={user} />
      <Divider />

      <Can perform="show_imported_reviews" on={user}>
        <ImportedReviewHistory user={user} />
        <Divider />
      </Can>

      {organization.plan.is360Enabled && (
        <React.Fragment>
          <ThreeSixtyReviewHistory user={user} />
          <Divider />
        </React.Fragment>
      )}

      {organization.plan.trainingModuleEnabled && (
        <Can perform="show_training_requests" on={user}>
          <TrainingRequestHistory user={user} />
        </Can>
      )}
    </React.Fragment>
  );
}

export default withOrganization(Sidebar);
