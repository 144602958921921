import React from 'react';

import type { ReviewTemplate } from 'models';

import invariant from 'helpers/invariant';

import { Select } from 'components';

// FIXME: Rather use a custom Object and return reviewTemplateId
type Props = {
  reviewTemplate: ReviewTemplate | undefined | null;
  reviewTemplates: Array<ReviewTemplate>;
  placeholder?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  inModal?: boolean;
  onChange: (reviewTemplate: ReviewTemplate | null) => void;
};

type Option = {
  key: string;
  label: string;
  value: ReviewTemplate;
};

const formatOption = (reviewTemplate: ReviewTemplate): Option => {
  const { id, name } = reviewTemplate;

  return {
    key: id,
    label: name,
    value: reviewTemplate,
  };
};

const ReviewTemplatePicker = ({
  reviewTemplate,
  reviewTemplates,
  placeholder,
  isDisabled,
  isClearable,
  isLoading,
  inModal,
  onChange,
}: Props) => {
  const value = !!reviewTemplate ? formatOption(reviewTemplate) : null;
  const options = isLoading
    ? ([] as Array<Option>)
    : reviewTemplates.map(template => formatOption(template));

  const handleChange = option => {
    invariant(
      !Array.isArray(option),
      'ReviewTemplatePicker does not support multi select'
    );

    return onChange((!!option && option.value) || null);
  };

  return (
    <Select
      value={value}
      options={options}
      placeholder={placeholder}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      inModal={inModal}
      onChange={handleChange}
    />
  );
};

export default ReviewTemplatePicker;
