import { throttle } from 'lodash';
import React from 'react';

import type { ActiveUser } from 'models';

import { identify, page, reset, track } from 'config/services/gtm';

import { currentPath } from './navigation';

const debugTracking = process.env.REACT_APP_DEBUG_TRACKING;

function debug(...args: any) {
  if (!debugTracking) return;

  console.log.apply(console, args);
}

debug('--> Tracking debugging enabled');

function isEnabled() {
  // Previously there was condition such as organization should not be a demo org
  // Now it seems we want to track under any circumstances

  return true;
}

type NonUserTraits = {
  environment: string;
  organizationShortName: string;
  organizationName: string;
  organizationIsDemo: boolean;
  organizationCreatedAt: string;
  organizationStripeId?: string | null | undefined;
  planName: string;
  planLegacy: boolean;
  planSupportLevel: string;
  planIs360Enabled: boolean;
  planDynamicTemplateEnabled: boolean;
  planMultiLevelAdminEnabled: boolean;
  planAutoAddParticipantsEnabled: boolean;
  planTrainingModuleEnabled: boolean;
  planPeopleReviewEnabled: boolean;
  planEnpsEnabled: boolean;
  isImpersonated?: boolean;
};

export const identifyUser = (
  user: ActiveUser,
  nonUserTraits?: NonUserTraits
) => {
  if (isEnabled()) {
    identify({
      userId: user.id,
      email: user.email,
      fullName: user.fullName,
      firstName: user.firstName,
      lastName: user.lastName,
      isAdmin: user.isAdmin,
      isOrganizationAdmin: user.isOrganizationAdmin,
      isManager: user.isManager,
      organizationId: user.organizationId,
      entityId: user.entityId,
      helpscoutSignature: user.helpscoutSignature,
      jimoToken: user.jimoToken,
      firstSignInAt: user.firstSignInAt,
      locale: user.locale,
      ...user.adminRolesForJimo,
      ...nonUserTraits,
    });

    debug('--> User identity for tracking set:', user.email);
    debug(nonUserTraits);
  }
};
export const resetUserIdentification = () => {
  if (isEnabled()) {
    reset();
    debug('--> User identity for tracking reset');
  }
};

function anonymizedPath() {
  return currentPath().replace(/\d+/, 'x');
}

function onServiceRoute() {
  return /^\/service\//.test(currentPath());
}

export const trackPage = throttle(
  function () {
    if (onServiceRoute()) return;

    if (isEnabled()) {
      page({ path: anonymizedPath() });
      debug('--> Page view tracked', anonymizedPath());
    }
  },
  500,
  { leading: false }
);

export function trackAction(event: string, properties?: Record<any, any>) {
  if (isEnabled()) {
    track(event, properties || {});

    debug('--> Event tracked', event);
    properties && debug(properties);
  }
}

export class TrackView extends React.Component<{
  event: string;
  properties?: Record<any, any>;
}> {
  componentDidMount() {
    trackAction(this.props.event, this.props.properties);
  }

  render() {
    return null;
  }
}
