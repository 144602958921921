import React from 'react';

import type { ReviewTemplate, ReviewTemplateAssignmentCriterion } from 'models';

import { __ } from 'helpers/i18n';

import {
  Control,
  Icon,
  Level,
  LevelLeft,
  LevelRight,
  Testable,
  Text,
} from 'components';

import ReviewTemplatePicker from 'scenes/components/ReviewTemplatePicker';

type Props = {
  assignmentCriterion: ReviewTemplateAssignmentCriterion;
  disabled: boolean;
  isClearable?: boolean;
  compatibleTemplates: Array<ReviewTemplate>;
  onTemplateUpdate: (templateId: string | null | undefined) => Promise<void>;
};

export default function AssignmentCriterion({
  assignmentCriterion,
  disabled,
  isClearable,
  compatibleTemplates,
  onTemplateUpdate,
}: Props) {
  const { template, segment } = assignmentCriterion;
  const label = assignmentCriterion.isDefault
    ? __('Default')
    : (segment && segment.label) || '';
  const onChange = (template: ReviewTemplate | null) =>
    onTemplateUpdate((!!template && template.id) || null);

  return (
    <Testable name="test-assignment-criterion">
      <Level style={{ marginBottom: 8 }}>
        <LevelLeft
          style={{
            width: '50%',
            paddingRight: 8,
            justifyContent: 'space-between',
          }}
        >
          <Text weight="bold">
            <Testable name="test-label">{label}</Testable>
          </Text>
          <Text color="grey">
            <Testable name="test-segment-count">
              {segment && segment.count}{' '}
            </Testable>

            <Icon
              size="small"
              style={{ verticalAlign: 'text-bottom' }}
              name="person"
            />
          </Text>
        </LevelLeft>

        <LevelRight style={{ width: '50%' }}>
          <Control style={{ width: '100%' }}>
            <ReviewTemplatePicker
              reviewTemplate={template}
              reviewTemplates={compatibleTemplates}
              placeholder={__('Default template')}
              isDisabled={disabled}
              isClearable={isClearable}
              onChange={onChange}
            />
          </Control>
        </LevelRight>
      </Level>
    </Testable>
  );
}
