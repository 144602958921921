import React from 'react';

import type { MatrixStatus, SkillsMatrix } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import {
  pathToMatrix,
  pathToMatrixEditor,
  pathToMatrixEmployeesList,
} from 'helpers/paths';
import confirmAsync from 'helpers/react/confirmAsync';

import { htmlSuccessNotice } from 'redux/actions';
import { del } from 'redux/actions/api';

import {
  BoxListItem,
  Button,
  Column,
  Columns,
  DeprecatedTag,
  Icon,
  PullRight,
  Text,
  Tooltip,
} from 'components';

type Props = {
  matrix: SkillsMatrix;
  fromCareerExplorer?: boolean;
  refetchData: () => Promise<void>;
};

type StatusTagProps = { status: MatrixStatus };
const StatusTag = ({ status }: StatusTagProps) => {
  switch (status) {
    case 'unpublished':
      return (
        <DeprecatedTag
          isRounded
          style={{ textTransform: 'none' }}
          rightIcon={{ name: 'visibility_off' }}
        >
          {__('Not published')}
        </DeprecatedTag>
      );
    case 'published':
      return (
        <DeprecatedTag
          isRounded
          style={{ textTransform: 'none' }}
          rightIcon={{ name: 'check_circle' }}
          color="success"
        >
          {__('Published')}
        </DeprecatedTag>
      );
    default:
      return null;
  }
};

export default function MatrixListItem({
  matrix,
  fromCareerExplorer,
  refetchData,
}: Props) {
  const dispatch = useAppDispatch();

  const editionMode = !fromCareerExplorer;
  const successMessage = __(
    'The skills matrix <b>%1</b> has been successfully deleted',
    matrix.title
  );

  const removeMatrix = async (matrixId: string) => {
    await dispatch(del(`/skills/matrices/${matrixId}`));
    refetchData();
    dispatch(htmlSuccessNotice(successMessage));
  };

  const confirmRemoveMatrix = (matrix: SkillsMatrix) => {
    return confirmAsync(
      __('Delete the skills matrix %1', matrix.title),
      <React.Fragment>
        <p>
          {__(
            'This action will delete the skills matrix %1.',
            <b>{matrix.title}</b>
          )}
        </p>
        <p>
          {__(
            'Employees associated with this skills matrix will no longer see the skills matrix in their profile and will no longer be attached to it.'
          )}
        </p>
        <br />
      </React.Fragment>,
      {
        confirmLabel: __('Delete the skills matrix'),
        isDanger: true,
        sideEffectsLabel: __(
          'Are you sure you want to delete this skills matrix?'
        ),
        sideEffects: [__('Detach all employees', matrix.title)],
        onConfirm: () => removeMatrix(matrix.id),
      }
    );
  };

  return (
    <BoxListItem>
      <Columns contentVerticallyAligned isMobile>
        <Column size={4}>
          <Text
            linkTo={
              editionMode
                ? pathToMatrixEditor(matrix.id)
                : pathToMatrix(matrix.id)
            }
          >
            {matrix.title}
          </Text>
        </Column>
        {editionMode && (
          <React.Fragment>
            <Column size={3}>
              <Text linkTo={pathToMatrixEmployeesList(matrix.id)}>
                {matrix.associatedUsersCount}
              </Text>
            </Column>

            <Column size={3}>
              <StatusTag status={matrix.status} />
            </Column>

            <Column size={2}>
              <PullRight>
                <Tooltip content={__('Delete skills matrix')}>
                  <Button
                    hasIconOnly
                    additionalClassName="border-none bg-transparent"
                    onClick={() => confirmRemoveMatrix(matrix)}
                  >
                    <Icon size="large" name="delete" />
                  </Button>
                </Tooltip>
              </PullRight>
            </Column>
          </React.Fragment>
        )}
      </Columns>
    </BoxListItem>
  );
}
