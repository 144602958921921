import moment from 'moment';
import React from 'react';

import type { UpdateReviewCycle } from '../../..';
import type { ReviewCycle } from 'models';

import { dateToDefaultDateStringFormat } from 'helpers/date';
import { __ } from 'helpers/i18n';

import { Control, DatePicker, Field, Label, Testable } from 'components';

type Props = {
  reviewCycle: ReviewCycle;
  updateReviewCycle: UpdateReviewCycle;
};

export default function ThreeSixtyDeadlinesOptions({
  reviewCycle,
  updateReviewCycle,
}: Props) {
  const { dateReviewRevieweeEnd, dateReviewReviewerEnd } = reviewCycle;

  return (
    <Field>
      <Label>{__('Final deadline:')}</Label>
      <Testable name="test-reviewee-deadline-picker">
        <Control
          isClearable={!!dateReviewReviewerEnd || !!dateReviewRevieweeEnd}
          onClear={() => {
            updateReviewCycle({
              dateReviewRevieweeEnd: null,
              dateReviewReviewerEnd: null,
            });
          }}
        >
          <DatePicker
            style={{ minWidth: 400 }}
            minDate={moment().add(1, 'days').toDate()}
            value={
              !!dateReviewRevieweeEnd ? new Date(dateReviewRevieweeEnd) : null
            }
            onChange={date =>
              updateReviewCycle({
                // @ts-ignore TSFIXME
                dateReviewRevieweeEnd: dateToDefaultDateStringFormat(date),
                // @ts-ignore TSFIXME
                dateReviewReviewerEnd: dateToDefaultDateStringFormat(date),
              })
            }
          />
        </Control>
      </Testable>
    </Field>
  );
}
