import React, { Fragment } from 'react';

import type { UserObjectivePeriod } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import invariant, { assert } from 'helpers/invariant';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Checkbox, FetchContainer, Helper } from 'components';

import ObjectiveItem from './ObjectiveItem';

type Props = {
  userObjectivePeriod: UserObjectivePeriod;
  onObjectiveCheckboxClick: (id: string) => void;
  selectedObjectiveIds: Array<string>;
  shouldReinitializeCompletion: boolean;
  onReinitializeCompletionCheckboxClick: (resetCompletion: boolean) => void;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    fetchedUserObjectivePeriod: UserObjectivePeriod;
  };

const ObjectiveList = ({
  fetchedUserObjectivePeriod,
  selectedObjectiveIds,
  onObjectiveCheckboxClick,
  shouldReinitializeCompletion,
  onReinitializeCompletionCheckboxClick,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        const user = assert(
          fetchedUserObjectivePeriod.user,
          'User should be hydrated on userObjectivePeriod'
        );

        const collection = fetchedUserObjectivePeriod.objectiveCollection;
        invariant(
          collection.publishedObjectives && collection.draftObjectives,
          'Objective collection and its objectives should be hydrated on userObjectivePeriod'
        );

        const objectives = collection.publishedObjectives.concat(
          collection.draftObjectives
        );

        if (objectives.length === 0) {
          return (
            <Helper style={{ marginBottom: 16 }}>
              <p>
                {__(
                  '%1 have no objective defined in period "%2".',
                  user.fullName,
                  fetchedUserObjectivePeriod.name
                )}
              </p>
            </Helper>
          );
        }

        return (
          <Fragment>
            {objectives.map(objective => (
              <ObjectiveItem
                key={objective.id}
                objective={objective}
                onCheckboxClick={onObjectiveCheckboxClick}
                selected={selectedObjectiveIds.includes(objective.id)}
              />
            ))}
            <Checkbox
              isChecked={shouldReinitializeCompletion}
              onChange={() =>
                onReinitializeCompletionCheckboxClick(
                  !shouldReinitializeCompletion
                )
              }
              label={__('Reinitialize completion of imported objectives')}
            />
          </Fragment>
        );
      }}
    />
  );
};

export default newDataLoader({
  fetch: ({ userObjectivePeriod }: Props) =>
    get(`user_objective_periods/${userObjectivePeriod.id}`),
  hydrate: {
    fetchedUserObjectivePeriod: {
      user: {},
      objectiveCollection: {
        publishedObjectives: {},
        draftObjectives: {},
      },
    },
  },
  cacheKey: ({ userObjectivePeriod }) =>
    compositeKey({
      view: 'import_objectives',
      userObjectivePeriod: userObjectivePeriod.id,
    }),
})(ObjectiveList) as React.ComponentType<Props>;
