import React from 'react';

import { Box } from 'components';

type Props = {
  svg: string;
  children: React.ReactNode;
  backgroundPositionX: string;
  style?: React.CSSProperties;
};

function BoxWithSvgBackground({
  svg,
  children,
  backgroundPositionX,
  style,
}: Props) {
  return (
    <Box
      style={{
        backgroundImage: `url(data:image/svg+xml;base64,${window.btoa(svg)})`,
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: backgroundPositionX,
        backgroundSize: 'contain',
        backgroundOrigin: 'border-box',
        ...style,
      }}
    >
      {children}
    </Box>
  );
}

BoxWithSvgBackground.defaultProps = {
  backgroundPositionX: 'right',
};

export default BoxWithSvgBackground;
