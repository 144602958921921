import useActiveUser from './useActiveUser';
import useOrganization from './useOrganization';

const useSeasonalTheme = () => {
  const activeUser = useActiveUser();
  const organization = useOrganization();

  return {
    displaySeasonalTheme:
      !!organization.activeSeasonalTheme && activeUser.seasonalThemeEnabled,
    seasonalTheme: organization.activeSeasonalTheme,
  };
};

export default useSeasonalTheme;
