import React from 'react';

import type { InlineEditableSharedProps } from './TextInput';
import type { AvailableNumberInputProps } from './editedRenderers/TextInputRenderer';

import InlineEditableWrapper from './InlineEditableWrapper';
import { NumberInputRenderer } from './editedRenderers';

type Props = InlineEditableSharedProps<number> & {
  inputProps?: AvailableNumberInputProps;
};

export default function InlineEditableNumberInput({
  value,
  onSubmit,
  renderNotEdited,
  withEditIcon,
  inputProps,
  autoFocus,
  hasError,
  hasWarning,
  isClearable,
  isDisabled,
  onBlur,
  onFocus,
}: Props) {
  return (
    <InlineEditableWrapper
      value={value}
      onSubmit={onSubmit}
      isDisabled={isDisabled}
      renderEdited={params => (
        <NumberInputRenderer
          {...params}
          isClearable={isClearable}
          {...inputProps}
        />
      )}
      renderNotEdited={renderNotEdited}
      withEditIcon={!!withEditIcon}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      hasError={hasError}
      hasWarning={hasWarning}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
}
