import React from 'react';

import colors from 'styles/colors';

type Props = {
  cx: number;
  cy: number;
};

export default function RevieweeCell({ cx, cy }: Props) {
  return (
    <svg width="66" height="66" viewBox="0 0 50 50" x={cx - 12} y={cy - 12}>
      <circle cx="9" cy="9" r="8" fill="white" />
      <path
        d="M9 1C4.58 1 1 4.58 1 9s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 2.75c1.24 0 2.25 1.01 2.25 2.25S10.24 8.25 9 8.25 6.75 7.24 6.75 6 7.76 3.75 9 3.75zM9 14.5c-1.86 0-3.49-.92-4.49-2.33C4.62 10.72 7.53 10 9 10c1.47 0 4.38.72 4.49 2.17-1 1.41-2.63 2.33-4.49 2.33z"
        fill={colors.darkestGrey}
      />
    </svg>
  );
}
