import React from 'react';

import type { HeaderMessageBlockContent } from 'models';

import { __ } from 'helpers/i18n';

import BlockDetails from 'scenes/components/review/BlockDetails';

type Props = {
  content: HeaderMessageBlockContent;
  withoutSectionSeparator?: boolean;
};

export default function HeaderMessageBlockContentComponent({
  content,
  withoutSectionSeparator,
}: Props) {
  const { id, title, description, richTextEnabled } = content;
  return (
    <BlockDetails
      id={id}
      section={__('Introduction')}
      title={title}
      description={description}
      testClassName="test-header-message-block"
      withoutSectionSeparator={withoutSectionSeparator}
      withRichText={richTextEnabled}
    />
  );
}
