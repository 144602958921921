import React from 'react';

import type {
  ReviewBlockUpdateParams,
  ReviewedObjectivesModuleBlock,
} from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import {
  Box,
  BoxSeparator,
  Flex,
  Title6,
  WithSavingStatusRecorder,
} from 'components';

import BlockActions from './components/BlockActions';
import BlockContent from './components/BlockContent';
import FeedbackOptionsSection from './components/FeedbackOptionsSection';
import LegacyRatingsSection from './components/LegacyRatingsSection';

type Props = {
  block: ReviewedObjectivesModuleBlock;
  onChange: (params: ReviewBlockUpdateParams) => Promise<void>;
  hasSelfEvaluationEnabled: boolean;
  onDelete?: () => Promise<void>;
  onMoveUp: () => Promise<void>;
  onMoveDown: () => Promise<void>;
};

const ReviewedObjectivesModuleBlockComponent = ({
  block,
  hasSelfEvaluationEnabled,
  onChange,
  ...actionProps
}: Props) => {
  const {
    revieweeFeedbackOptions,
    reviewerFeedbackOptions,
    revieweeRatingOptions,
    reviewerRatingOptions,
    content,
    richTextEnabled,
  } = block;

  return (
    <Box testClassName="test-reviewed-objectives-module-block">
      <Flex style={{ justifyContent: 'space-between' }} verticalAlign>
        <Title6 transformation="uppercase">
          {__('Assess objectives section')}
        </Title6>
        <BlockActions
          canMoveUp={block.canMoveUp}
          canMoveDown={block.canMoveDown}
          canDuplicate={block.canDuplicate}
          {...actionProps}
        />
      </Flex>

      <BoxSeparator style={{ margin: '16px 0' }} />

      <WithSavingStatusRecorder
        fieldUid={compositeKey({
          blockId: block.id,
          field: 'reviewed_objectives_module_block_content',
        })}
        onChange={onChange}
        render={autoSavingOnchange => (
          <BlockContent
            onChange={autoSavingOnchange}
            content={content}
            withRichText={richTextEnabled}
          />
        )}
      />

      <LegacyRatingsSection
        reviewBlockId={block.id}
        canDeleteRevieweeRatingOption={revieweeRatingOptions.length > 2}
        canDeleteReviewerRatingOption={reviewerRatingOptions.length > 2}
        canUpdateWithEmptyLabel={false}
        revieweeRatingOptionsEnabled={
          !!revieweeFeedbackOptions && revieweeFeedbackOptions.ratingEnabled
        }
        revieweeRatingOptions={revieweeRatingOptions}
        reviewerRatingOptionsEnabled={reviewerFeedbackOptions.ratingEnabled}
        reviewerRatingOptions={reviewerRatingOptions}
        hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
        interactionType="one_on_one"
      />

      <BoxSeparator style={{ margin: '16px 0' }} />

      <FeedbackOptionsSection
        revieweeFeedbackOptions={revieweeFeedbackOptions}
        reviewerFeedbackOptions={reviewerFeedbackOptions}
        hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
        interactionType="one_on_one"
        blockType={block.blockType}
      />
    </Box>
  );
};

export default ReviewedObjectivesModuleBlockComponent;
