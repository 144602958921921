import React, { useState } from 'react';

import type { Answer, User } from 'models';

import classNames from 'helpers/classNames';
import { useIsLargeScreen } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { AutoSave } from 'components';

import RatingSelect from '../../../components/FeedbackZone/RatingSelect';
import RatingSliderWithCheckbox from '../../components/RatingSliderWithCheckbox';
import ClosedAnswerAvatarsOnMobile from './ClosedAnswersAvatarsOnMobile';

type Props = {
  autoSaveUid: string;
  ratingOptions: string[];
  rating: string | null;
  persistFeedback: (rating: string | null) => Promise<void>;
  closedAnswers: Answer[];
  renderTooltipContent?: (user: User) => string;
  isOverallRating?: boolean;
};

const OpenAnswer = ({
  autoSaveUid,
  ratingOptions,
  rating: ratingFromProps,
  persistFeedback,
  closedAnswers,
  renderTooltipContent,
  isOverallRating = false,
}: Props) => {
  const [rating, setRating] = useState(ratingFromProps);
  const isLargeScreen = useIsLargeScreen();

  return (
    <AutoSave
      fieldUid={autoSaveUid}
      onChange={(newValue: string | null) => setRating(newValue)}
      doPersist={() => persistFeedback(rating)}
      render={autoSavingOnChange => {
        if (!isLargeScreen)
          return (
            <div
              className={classNames('pb-4', {
                'mx-2 mt-2': isOverallRating,
              })}
            >
              <ClosedAnswerAvatarsOnMobile
                closedAnswers={closedAnswers}
                isOverallRating={isOverallRating}
              />
              <RatingSelect
                ratings={[...ratingOptions, 'none']}
                ratingLabels={{
                  none: isOverallRating
                    ? __("I don't know / can't evaluate this domain")
                    : __("I don't know / can't evaluate this skill"),
                }}
                selectedRating={rating}
                onChange={autoSavingOnChange}
              />
            </div>
          );

        return (
          <RatingSliderWithCheckbox
            ratingOptions={ratingOptions}
            rating={rating}
            setRating={autoSavingOnChange}
            closedAnswers={closedAnswers}
            renderTooltipContent={renderTooltipContent}
            hasWhiteRail={isOverallRating}
            marginTopSlider={isOverallRating ? 16 : 8}
            marginBottomSlider={isOverallRating ? 16 : 32}
            testClassName={isOverallRating ? 'test-overall-slider' : undefined}
          />
        );
      }}
    />
  );
};

export default OpenAnswer;
