import React from 'react';

import type { CreatableReviewBlockType } from 'models';

import { __ } from 'helpers/i18n';

import {
  Button,
  Divider,
  Dropdown,
  DropdownDivider,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  FeatureFlagged,
  Icon,
  Text,
  WithHoverableFadeinFadeoutAnimation,
} from 'components';
import WithSavingStatusRecorder from 'components/withSavingStatus/Recorder';

type Props = {
  withHoverableFadeInFadeOut?: boolean;
  onCreate: (blockType: CreatableReviewBlockType) => Promise<void>;
};

const ReviewBlockCreator = ({
  onCreate,
  withHoverableFadeInFadeOut,
}: Props) => {
  const dropdownMenu = (
    <DropdownMenu>
      <WithSavingStatusRecorder
        onChange={() => onCreate('title')}
        render={autoSavingOnchange => (
          <DropdownMenuItem onClick={autoSavingOnchange}>
            <Icon name="title" />
            <Text>{__('Section title')}</Text>
          </DropdownMenuItem>
        )}
      />

      <WithSavingStatusRecorder
        onChange={() => onCreate('instructions')}
        render={autoSavingOnchange => (
          <DropdownMenuItem onClick={autoSavingOnchange}>
            <Icon name="info" />
            <Text>{__('Instructions')}</Text>
          </DropdownMenuItem>
        )}
      />

      <DropdownDivider />

      <FeatureFlagged flag="removeLegacyQuestion" inverseDefaultBehavior>
        <WithSavingStatusRecorder
          onChange={() => onCreate('question')}
          render={autoSavingOnchange => (
            <DropdownMenuItem onClick={autoSavingOnchange}>
              <Icon name="arrow_drop_down_circle" />
              <Text>{__('Question')}</Text>
            </DropdownMenuItem>
          )}
        />
      </FeatureFlagged>

      <WithSavingStatusRecorder
        onChange={() => onCreate('dropdownQuestion')}
        render={autoSavingOnchange => (
          <DropdownMenuItem onClick={autoSavingOnchange}>
            <Icon name="arrow_drop_down_circle" />
            <Text>{__('Dropdown list')}</Text>
          </DropdownMenuItem>
        )}
      />

      <WithSavingStatusRecorder
        onChange={() => onCreate('multipleScaleQuestion')}
        render={autoSavingOnchange => (
          <DropdownMenuItem onClick={autoSavingOnchange}>
            <Icon name="apps" />
            <Text>{__('Multiple Scale')}</Text>
          </DropdownMenuItem>
        )}
      />

      <WithSavingStatusRecorder
        onChange={() => onCreate('textQuestion')}
        render={autoSavingOnchange => (
          <DropdownMenuItem onClick={autoSavingOnchange}>
            <Icon name="subject" />
            <Text>{__('Text')}</Text>
          </DropdownMenuItem>
        )}
      />
    </DropdownMenu>
  );

  if (withHoverableFadeInFadeOut) {
    return (
      <WithHoverableFadeinFadeoutAnimation>
        <Divider childrenPosition="center" color="primary">
          <Dropdown style={{ marginTop: 11 }}>
            <DropdownTrigger
              render={_dropdownIsOpened => (
                <Button color="primary" isOutlined size="small">
                  <span>{__('Add')}</span>
                  <Icon name="keyboard_arrow_down" />
                </Button>
              )}
            />
            {dropdownMenu}
          </Dropdown>
        </Divider>
      </WithHoverableFadeinFadeoutAnimation>
    );
  }

  return (
    <Dropdown>
      <DropdownTrigger
        render={_dropdownIsOpened => (
          <Button color="primary" isOutlined>
            <span>{__('Add a block')}</span>
            <Icon name="keyboard_arrow_down" />
          </Button>
        )}
      />
      {dropdownMenu}
    </Dropdown>
  );
};

export default React.memo(ReviewBlockCreator) as React.ComponentType<Props>;
