import React, { CSSProperties, ReactNode } from 'react';

import classNames from 'helpers/classNames';

import { Flex, Text } from 'components';

type Props = {
  children: ReactNode;
  testClassName?: string;
  additionalClassName?: string;
  style?: CSSProperties;
};

export default function Helper({
  children,
  style,
  testClassName,
  additionalClassName,
}: Props) {
  return (
    <Flex
      additionalClassName={classNames(
        'notification is-helper',
        testClassName,
        additionalClassName
      )}
      style={style}
    >
      <span
        className="light-bulb"
        role="img"
        aria-label="light-bulb"
        style={{ marginRight: 8 }}
      >
        💡
      </span>
      <Text additionalClassName="helper-content" size={7}>
        {children}
      </Text>
    </Flex>
  );
}
