// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useOrganization } from 'helpers/hooks';

const useGetAssignationCriterionLabel = () => {
  const organization = useOrganization();
  const {
    settings: { skillsMatrixAssignationCriterion },
  } = organization;

  if (!skillsMatrixAssignationCriterion) {
    return null;
  }

  return organization.userFilterableFields.find(
    field => field.slug === skillsMatrixAssignationCriterion
  )?.label;
};

export default useGetAssignationCriterionLabel;
