import { type TrainingType } from 'models';

import { __ } from 'helpers/i18n';

const trainingSessionTypes = (
  newTrainingSessionFieldsEnabled: boolean
): Partial<Record<TrainingType, string>> => {
  if (!newTrainingSessionFieldsEnabled) {
    return {
      intra: __('Intra'),
      inter: __('Inter'),
    };
  }

  return {
    external_intra_company: __('External Intra-company'),
    external_inter_company: __('External Inter-company'),
    internal_intra_company: __('Internal Intra-company'),
    internal_inter_company: __('Internal Inter-company'),
  };
};

export default trainingSessionTypes;
