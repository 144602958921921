import React from 'react';

import type { RenderEditedParams } from '../InlineEditableWrapper.js';
import type { BulmaColor } from 'components/types/bulma';

import { __ } from 'helpers/i18n';

import { Control, Input } from 'components';

export type AvailableInputProps = {
  color?: BulmaColor;
  displayErrorMessage?: boolean;
  placeholder?: string;
  isClearable?: boolean;
};

export type AvailableNumberInputProps = AvailableInputProps & {
  min?: number;
  max?: number;
};

type Props = RenderEditedParams<string> & AvailableInputProps;

export default function InputRenderer({
  submitIfChanged,
  isSubmitting,
  hasError,
  hasWarning,
  toggle,
  onChange,
  value,
  targetPosition,
  isClearable,
  displayErrorMessage,

  ...inputProps
}: Props) {
  return (
    <div>
      <Control
        isClearable={!!value && isClearable}
        onClear={() => onChange('')}
      >
        <Input
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onBlur={() => submitIfChanged(value)}
          disabled={isSubmitting}
          onEnterKeyPress={() => submitIfChanged(value)}
          onEscapeKeyPress={toggle}
          value={value === null ? undefined : value}
          onChange={onChange}
          {...inputProps}
        />
      </Control>

      {hasError && displayErrorMessage && (
        <p className="help is-danger">
          {__(
            'Oops, it looks like we had trouble saving your change. Please try again (or contact support@elevo.fr if it persists)'
          )}
        </p>
      )}
    </div>
  );
}
