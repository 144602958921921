import React from 'react';
import { compose } from 'redux';

import type { SurveyCampaign, SurveyParticipation } from 'models';

import { __, date, n__ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToMySurvey } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  Button,
  Column,
  Columns,
  EmptyStateWithIcon,
  FetchContainer,
  Icon,
  StrictlySanitizedHtml,
  Text,
} from 'components';

import campaignNameWithPeriod from '../helpers/campaignNameWithPeriod';

type AfterDataLoaderProps = DataLoaderProvidedProps & {
  campaigns: Array<SurveyCampaign>;
};

const formattedDescription = (isAnonymous: boolean) => {
  const anonymousWording = isAnonymous
    ? __('Your response to this survey <b>is anonymous</b>')
    : __('Your response to this survey <b>is not anonymous</b>');

  return (
    <div>
      <Icon
        name={isAnonymous ? 'visibility_off' : 'visibility'}
        additionalClassName="mr-1"
      />
      <Text preset="14s6" additionalClassName="font-normal">
        <StrictlySanitizedHtml html={anonymousWording} />
      </Text>
    </div>
  );
};

const buttonText = (status: SurveyParticipation['status']) => {
  switch (status) {
    case 'not_started':
      return __('Answer');
    case 'in_progress':
      return __('Continue');
  }
};

const SurveyList = ({
  campaigns,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        if (campaigns.length === 0) {
          return (
            <EmptyStateWithIcon
              iconName={'rule'}
              title={__('No surveys in progress')}
            />
          );
        }

        return campaigns.map(campaign => {
          const { currentPeriod } = campaign;
          invariant(currentPeriod, 'currentPeriod must be defined');
          const { currentUserParticipation } = currentPeriod;
          invariant(currentUserParticipation, 'currentPeriod must be defined');

          const boxTitle = campaignNameWithPeriod(
            campaign.name,
            currentPeriod.name
          );

          return (
            <Box key={campaign.id}>
              <Text preset="18bs5">{boxTitle}</Text>
              <Columns additionalClassName="mt-5 flex-wrap">
                <Column size={5}>
                  <Text preset="18bs5">
                    {formattedDescription(campaign.anonymous)}
                  </Text>
                </Column>
                <Column size={5}>
                  <Text>
                    {n__(
                      'Deadline for the survey: %2 (%1 day)',
                      'Deadline for the survey: %2 (%1 days)',
                      currentPeriod.daysUntilTheEnd,
                      date(campaign.lastPeriodEndDate, 'LL')
                    )}
                  </Text>
                </Column>
                <Column isNarrow contentIsPulledRight size={2}>
                  <Button color="primary" to={pathToMySurvey(campaign.id)}>
                    {buttonText(currentUserParticipation.status)}
                  </Button>
                </Column>
              </Columns>
            </Box>
          );
        });
      }}
    />
  );
};

export default compose(
  newDataLoader({
    fetch: () => get('survey/campaigns/my_ongoing_surveys'),
    hydrate: {
      campaigns: {
        currentPeriod: {
          currentUserParticipation: {},
        },
      },
    },
  })
)(SurveyList);
