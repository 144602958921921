import React from 'react';

import type { FundingSourceStats } from 'models';

import { __ } from 'helpers/i18n';

import { CompletionProgressBar, Flex, Text } from 'components';

type Props = {
  fundingSourcesStat: FundingSourceStats;
};

const FundingSourceDetails = ({ fundingSourcesStat }: Props) => {
  const { budgetItem, formattedAmountSpent, amountSpentCents, title } =
    fundingSourcesStat;

  const { provisionedAmountCents, formattedProvisionedAmount } = budgetItem;

  const completion =
    provisionedAmountCents &&
    amountSpentCents &&
    (amountSpentCents * 100) / provisionedAmountCents;

  const renderBudget = () => {
    if (provisionedAmountCents) {
      return (
        <Flex verticalAlign additionalClassName="no-shrink">
          <Text
            size={6}
            weight="bold"
            testClassName="test-budget-item-spent-amount"
          >
            {formattedAmountSpent}
          </Text>

          <Text
            size={7}
            weight="light"
            color="light"
            testClassName="test-budget-item-amount"
          >
            &nbsp;
            {__('of')}
            &nbsp;
            {formattedProvisionedAmount}
          </Text>
        </Flex>
      );
    }

    return (
      <Flex verticalAlign additionalClassName="no-shrink">
        <Text weight="light" color="light" additionalClassName="no-shrink">
          {__('Budget spent:')}
          &nbsp;
        </Text>
        <Text
          size={7}
          weight="semibold"
          testClassName="test-budget-item-spent-amount"
        >
          {formattedAmountSpent}
        </Text>
      </Flex>
    );
  };

  return (
    <div className="budget-details-modal-funding-source test-budget-item">
      <Flex verticalAlign additionalClassName="funding-source-details">
        <Text
          size={7}
          weight="semibold"
          additionalClassName="funding-source-title"
        >
          {title}
        </Text>

        {renderBudget()}
      </Flex>

      {provisionedAmountCents && (
        <CompletionProgressBar completion={completion} />
      )}
    </div>
  );
};

export default FundingSourceDetails;
