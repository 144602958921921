import React, { Fragment } from 'react';

import type { UserFilterChangeMethod } from 'lib/dataLoader/pagination/types';

import { SurveyCampaign, SurveyQuestionStats, UserFilterSegment } from 'models';

import compositeKey from 'helpers/compositeKey';
import { navigate } from 'helpers/navigation';
import { pathToSurveyCampaignResults } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FeatureFlagged, FetchContainer } from 'components';

import { surveyIsRecurrent } from 'scenes/surveys/helpers/survey.helpers';

import NotEnoughParticipantsEmptyState from '../components/NotEnoughParticipantEmptyState';
import AnswersList from './AnswersList';
import Header from './Header';
import MessagingChatRoomsList from './MessagingChatRoomsList';
import QuestionSlider from './QuestionSlider';
import QuestionStats from './QuestionStats';

type Props = {
  campaign: SurveyCampaign;
  selectedPeriodId: string;
  userFilter: UserFilterSegment | null;
  onUserFilterChange: UserFilterChangeMethod;
  questionId?: string | null;
  correlationSlug?: string | null;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    questionStats: SurveyQuestionStats;
  };

const QuestionPicker = ({
  campaign,
  selectedPeriodId,
  userFilter,
  onUserFilterChange,
  questionId,
  correlationSlug,
  questionStats,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  const selectedPeriod = campaign.pollingPeriods.find(
    period => period.id === selectedPeriodId
  );

  const handleSelectedPeriodChange = periodId => {
    const {
      question: { correlationSlug },
    } = questionStats;
    navigate(
      pathToSurveyCampaignResults(
        campaign.id,
        correlationSlug ? null : questionId,
        periodId,
        correlationSlug
      )
    );
  };
  const surveyRecurrentWithPeriod =
    surveyIsRecurrent(campaign) && !!selectedPeriod;
  const displayMissingParticipants =
    campaign.anonymous && questionStats?.participantsCount < 3;

  return (
    <Fragment>
      <Header
        campaign={campaign}
        selectedPeriod={selectedPeriod}
        handleSelectedPeriodChange={handleSelectedPeriodChange}
        questionStats={questionStats}
        userFilter={userFilter}
        onUserFilterChange={onUserFilterChange}
      />
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => (
          <Fragment>
            <QuestionSlider
              questionStats={questionStats}
              campaignId={campaign.id}
              selectedPeriodId={selectedPeriodId}
            />
            {!!questionStats && (
              <Fragment>
                {displayMissingParticipants && (
                  <NotEnoughParticipantsEmptyState />
                )}
                {!displayMissingParticipants && (
                  <Fragment>
                    <QuestionStats
                      questionStats={questionStats}
                      campaignId={campaign.id}
                      withEvolution={surveyRecurrentWithPeriod}
                      selectedPeriodId={selectedPeriodId}
                      segment={userFilter}
                    />
                    {questionStats.commentEnabled && (
                      <Fragment>
                        <AnswersList
                          campaign={campaign}
                          selectedPeriodId={selectedPeriodId}
                          segment={userFilter}
                          questionStats={questionStats}
                          question={questionStats.question}
                          correlationSlug={correlationSlug}
                          paginationType="state"
                        />
                        <FeatureFlagged flag="surveyMessagingChatRooms">
                          <MessagingChatRoomsList
                            questionStats={questionStats}
                            campaign={campaign}
                            paginationType="state"
                            periodId={selectedPeriodId}
                          />
                        </FeatureFlagged>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default newDataLoader({
  fetch: ({
    campaign,
    questionId,
    selectedPeriodId,
    userFilter,
    correlationSlug,
  }: Props) =>
    get(`survey/campaigns/${campaign.id}/questions/question_stats`, {
      correlationSlug,
      questionId,
      userFilter,
      periodId: selectedPeriodId,
    }),
  hydrate: {
    questionStats: {
      question: {},
      correlatedPollingPeriods: { abilities: {} },
    },
  },
  cacheKey: ({
    campaign,
    questionId,
    selectedPeriodId,
    userFilter,
    correlationSlug,
  }: Props) =>
    compositeKey({
      view: 'stats',
      campaign,
      questionId,
      selectedPeriodId,
      userFilter,
      correlationSlug,
    }),
})(QuestionPicker) as React.ComponentType<Props>;
