import { useState } from 'react';

export function useMultiSelect<T>(
  initialState: Array<T>
): [Array<T>, (arg1: T) => void] {
  const [selectedElements, setSelectedElements] = useState<Set<T>>(
    new Set(initialState)
  );

  const toggleSelected = (newElement: T): void => {
    setSelectedElements(previousElements => {
      const newElements = new Set(previousElements);

      if (newElements.has(newElement)) {
        newElements.delete(newElement);
      } else {
        newElements.add(newElement);
      }

      return newElements;
    });
  };

  return [Array.from(selectedElements), toggleSelected];
}
