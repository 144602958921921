// @flow
import type { JsonApiStrippedResponse } from './JsonApiResponse';

export const DELETE_ALL_RESOURCES_FROM_KEY = 'DELETE_ALL_RESOURCES_FROM_KEY';
export const RECEIVED_RESOURCES = 'RECEIVED_RESOURCES';
export const REQUEST_STARTED = 'REQUEST_STARTED';
export const REQUEST_SUCCEEDED = 'REQUEST_SUCCEEDED';
export const REQUEST_FAILED = 'REQUEST_FAILED';

export type RequestStartedAction = {|
  type: typeof REQUEST_STARTED,
  requestId: string,
|};

export type RequestSucceededAction = {|
  type: typeof REQUEST_SUCCEEDED,
  requestId: string,
  value?: JsonApiStrippedResponse,
|};

export type RequestFailedAction = {|
  type: typeof REQUEST_FAILED,
  requestId: string,
|};

export type DeleteAllResourcesFromKey = {|
  type: typeof DELETE_ALL_RESOURCES_FROM_KEY,
  keyId: string,
|};

export type DataLoaderAction =
  | RequestStartedAction
  | RequestSucceededAction
  | RequestFailedAction;

export const requestStarted = (requestId: string): RequestStartedAction => ({
  type: REQUEST_STARTED,
  requestId,
});

export const requestSucceeded = (
  requestId: string,
  value: ?JsonApiStrippedResponse
): RequestSucceededAction => {
  const action: RequestSucceededAction = {
    type: REQUEST_SUCCEEDED,
    requestId,
  };
  if (!!value) action.value = value;
  return action;
};

export const requestFailed = (requestId: string): RequestFailedAction => ({
  type: REQUEST_FAILED,
  requestId,
});

export const deleteAllResourcesFromKey = (
  keyId: string
): DeleteAllResourcesFromKey => ({
  type: DELETE_ALL_RESOURCES_FROM_KEY,
  keyId,
});
