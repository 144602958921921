import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { __ } from 'helpers/i18n';

import { ConfirmationModal } from 'components';

type Props = {
  isDirty: boolean;
  onSubmit: () => void;
};

const UnsavedModificationsGuard = ({ isDirty, onSubmit }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [nextPath, setNextPath] = useState<string>('');
  const isNavigationBlocked = isDirty && !showModal;
  const history = useHistory();

  useEffect(() => {
    const handleBlockedNavigation = nextLocation => {
      if (isNavigationBlocked) {
        setShowModal(true);
        setNextPath(nextLocation.pathname);
        return !isNavigationBlocked;
      }
    };

    const unblock = history.block(handleBlockedNavigation);

    return unblock;
  }, [isDirty, history, isNavigationBlocked]);

  const handleConfirm = () => {
    setShowModal(false);
    onSubmit();
    history.push(nextPath);
  };

  const handleCancel = () => {
    setShowModal(false);
    history.push(nextPath);
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  if (!showModal) return null;

  return (
    <ConfirmationModal
      onConfirm={handleConfirm}
      confirmLabel={__('Save and exit')}
      onCancel={handleCancel}
      cancelLabel={__('Leave without saving')}
      title={__('Unsaved changes')}
      refreshContentOnOpening={false}
      isActive={showModal}
      onClose={onModalClose}
    >
      <p>
        {__(
          'You have unsaved changes. If you leave this page now, your changes will be lost.'
        )}
      </p>
      <p>{__('Are you sure you want to continue?')}</p>
    </ConfirmationModal>
  );
};

export default UnsavedModificationsGuard;
