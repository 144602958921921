import React from 'react';

import type { ENPSPeriod } from 'models';

import { __ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  DeprecatedPageHeader,
  DeprecatedPageHeaderTitle,
  EmptyStateWithIcon,
  FetchContainer,
  Icon,
  PageTitle,
  Text,
} from 'components';

import Answers from './Answers';
import Evolution from './Evolution';
import Stats from './Stats';

const NoPeriodState = () => (
  <EmptyStateWithIcon
    iconName="volunteer_activism"
    title={__('No eNPS in progress')}
    description={__(
      'The next eNPS will be launched on the first Monday of next month.'
    )}
  />
);

type AfterDataLoaderProps = DataLoaderProvidedProps & {
  enpsPeriods: ENPSPeriod[];
};

const Results = ({
  isFetching,
  hasError,
  enpsPeriods,
}: AfterDataLoaderProps) => {
  return (
    <div className="m-4 md:m-8">
      <div className="max-w-[1080px] mx-auto">
        <PageTitle title={__('eNPS results')} />
        <DeprecatedPageHeader style={{ margin: 0, marginBottom: 24 }}>
          <DeprecatedPageHeaderTitle style={{ padding: 0 }}>
            {__('eNPS results')}
          </DeprecatedPageHeaderTitle>
        </DeprecatedPageHeader>
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          render={() => {
            const period = enpsPeriods[0];

            if (!period) return <NoPeriodState />;

            return (
              <div>
                <div className="flex justify-end mb-4">
                  <Text preset="14s6" color="light" transformation="italic">
                    <Icon
                      style={{ marginBottom: 2, marginRight: 8 }}
                      name="timer"
                    />
                    {period.active
                      ? __(
                          'The evaluation of the eNPS is under way and will close on %1',
                          period.endDate
                        )
                      : __(
                          'The next eNPS will be launched on %1 for the period %2.',
                          period.nextPeriodExpectedStartDate,
                          period.nextPeriodExpectedName
                        )}
                  </Text>
                </div>
                <Stats enpsPeriod={period} />
                <Evolution enpsPeriod={period} />
                <Answers
                  paginationType="state"
                  defaultPaginationParams={{ filter: { all: true } }}
                  enpsPeriod={period}
                />
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default newDataLoader({
  fetch: () => get(`enps/periods`),
  hydrate: {
    enpsPeriods: {},
  },
})(Results);
