import React from 'react';

import { __ } from 'helpers/i18n';

import { FieldError, Text } from 'components';

type Props = {
  length: number;
  maxLength: number;
  error: string | null | undefined;
  additionalClassName?: string;
};

export default function EditorHelperText({
  length,
  maxLength,
  error,
  additionalClassName,
}: Props) {
  if (error) {
    return (
      <FieldError
        additionalClassName={
          additionalClassName ? `${additionalClassName}-error` : ''
        }
      >
        {error}
      </FieldError>
    );
  }

  return (
    <Text
      size={7}
      color="info"
      align="right"
      additionalClassName={
        additionalClassName ? `${additionalClassName}-counter` : ''
      }
    >
      {__('%1 / %2 characters', length, maxLength)}
    </Text>
  );
}
