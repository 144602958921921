import React from 'react';
import { connect } from 'react-redux';

import type { ReviewCycle, User } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { useActiveUser } from 'helpers/hooks';
import { useExportAction } from 'helpers/hooks/useExportAction';
import { __ } from 'helpers/i18n';
import { trackAction } from 'helpers/tracking';

import { post } from 'redux/actions/api';

import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Icon,
  Toggleable,
  Tooltip,
} from 'components';

import ExportModal from 'scenes/admin/components/ExportModal';

type ExportFileFormat = 'csv' | 'xlsx';

const formatToButtonPhrase = (
  format: ExportFileFormat,
  activeUser: User
): string => {
  const phrases = activeUser.isAdminButNotOrganizationAdmin
    ? {
        csv: __('CSV (administered users only)'),
        xlsx: __('Excel (administered users only)'),
      }
    : {
        csv: __('All data to CSV'),
        xlsx: __('All data to Excel'),
      };

  return phrases[format];
};

type Props = {
  reviewCycle: ReviewCycle;
  formats: Array<ExportFileFormat>;
};

type AfterConnectProps = Props & {
  prepareExport: (format: ExportFileFormat) => Promise<void>;
};

function ExportMenuButton({
  reviewCycle,
  formats,
  prepareExport,
}: AfterConnectProps) {
  const { lastPendingExportAction } = reviewCycle;
  const activeUser = useActiveUser();
  const { ongoingExportAction, exportTooltipText, pollerComponent } =
    useExportAction(lastPendingExportAction);

  const disableExportAction =
    ongoingExportAction || reviewCycle.userReviewsCount === 0;

  return (
    <React.Fragment>
      {pollerComponent}
      <Toggleable
        render={(isToggled, toggle) => (
          <React.Fragment>
            <Dropdown align="right" disabled={disableExportAction}>
              <DropdownTrigger
                render={() => (
                  <Tooltip content={exportTooltipText}>
                    <Button color="secondary" disabled={disableExportAction}>
                      <span>{__('Export')}</span>
                      <Icon name="keyboard_arrow_down" />
                    </Button>
                  </Tooltip>
                )}
              />
              <DropdownMenu>
                {formats.map(format => (
                  <DropdownMenuItem
                    key={format}
                    onClick={async () => {
                      await prepareExport(format);
                      trackAction(`${format} export downloaded`);
                      toggle();
                    }}
                  >
                    {formatToButtonPhrase(format, activeUser)}
                  </DropdownMenuItem>
                ))}
              </DropdownMenu>
            </Dropdown>

            <ExportModal isActive={isToggled} onClose={toggle} />
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
}

function mapDispatchToProps(dispatch: AppDispatch, { reviewCycle }: Props) {
  return {
    prepareExport: async (format: ExportFileFormat) =>
      dispatch(post(`review_cycles/${reviewCycle.id}/export`, { format })),
  };
}

export default connect(null, mapDispatchToProps)(ExportMenuButton);
