import React, { ReactNode } from 'react';

import type {
  Evaluation,
  ThreeSixtyReviewCycle,
  ThreeSixtyUserReview,
} from 'models';
import type { ThreeSixtyUserReviewAbilityId } from 'models/UserReview/ThreeSixtyUserReview';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import { assert } from 'helpers/invariant';
import { getAbilityIds } from 'helpers/models/abilities';
import { pathToReviewResults } from 'helpers/navigation';

import {
  Button,
  Flex,
  HamburgerMenu,
  MenuItem,
  MenuList,
  Testable,
} from 'components';

import {
  ManagePeersAction,
  RemindPeersFeedbackAction,
  RemindPeersNominationAction,
  RemindSelfAssessmentAction,
  ReopenPeersFeedbackAction,
  UnreleaseAction,
} from 'scenes/components/userReview/360/actions';
import CalendarButton from 'scenes/components/userReview/CalendarButton';
import { UpdateResponsibleAction } from 'scenes/components/userReview/actions';

type Props = {
  userReview: ThreeSixtyUserReview;
  reviewCycle: ThreeSixtyReviewCycle;
  revieweeEvaluation: Evaluation | undefined | null;
  peersEvaluationsNotShared: Array<Evaluation> | undefined | null;
  onAfterDelegate: () => Promise<void>;
};

function getMainActionAndButton(
  abilityIds: Array<ThreeSixtyUserReviewAbilityId>,
  userReview: ThreeSixtyUserReview,
  reviewCycle: ThreeSixtyReviewCycle
): {
  mainAction: ThreeSixtyUserReviewAbilityId | undefined | null;
  mainButton: ReactNode;
} {
  const nominatePeersStepEnabled = !!reviewCycle.nominatePeersStepEnabled;

  if (abilityIds.includes('choose_peers')) {
    const mainButton = (
      <ManagePeersAction
        userReview={userReview}
        reviewCycle={reviewCycle}
        renderAction={onClick => (
          <Testable name="test-choose-peers-button">
            <Button
              onClick={onClick}
              color={!nominatePeersStepEnabled ? 'primary' : 'secondary'}
            >
              {__('Choose peers')}
            </Button>
          </Testable>
        )}
      />
    );

    return { mainAction: 'choose_peers', mainButton };
  }

  if (abilityIds.includes('validate_peers')) {
    const mainButton = (
      <ManagePeersAction
        userReview={userReview}
        reviewCycle={reviewCycle}
        renderAction={onClick => (
          <Testable name="test-validate-peers-button">
            <Button onClick={onClick} color="primary">
              {__('Validate peers')}
            </Button>
          </Testable>
        )}
      />
    );

    return { mainAction: 'validate_peers', mainButton };
  }

  if (abilityIds.includes('show_action_to_release')) {
    const mainButton = (
      <Testable name="test-view-and-share-button">
        <Button to={pathToReviewResults(userReview)} color="primary">
          {__('View and share')}
        </Button>
      </Testable>
    );

    return { mainAction: 'show_action_to_release', mainButton };
  }

  if (abilityIds.includes('view_results')) {
    const mainButton = (
      <Testable name="test-view-button">
        <Button to={pathToReviewResults(userReview)} color="secondary">
          {__('View')}
        </Button>
      </Testable>
    );

    return { mainAction: 'view_results', mainButton };
  }

  return { mainAction: null, mainButton: null };
}

export default function ButtonsGroup({
  userReview,
  reviewCycle,
  onAfterDelegate,
}: Props) {
  const abilityIds = getAbilityIds(userReview);

  const { mainAction, mainButton } = getMainActionAndButton(
    abilityIds,
    userReview,
    reviewCycle
  );

  const secondaryActions = abilityIds.filter(action => action !== mainAction);
  const secondaryActionItems: Array<ReactNode> = [];

  if (secondaryActions.includes('view_results')) {
    secondaryActionItems.push(
      <MenuItem to={pathToReviewResults(userReview)} key="viewResults">
        {__('View feedback')}
      </MenuItem>
    );
  }

  if (secondaryActions.includes('change_peers_after_validation')) {
    secondaryActionItems.push(
      <ManagePeersAction
        userReview={userReview}
        reviewCycle={reviewCycle}
        key="managePeers"
      />
    );
  }

  if (secondaryActions.includes('reopen_peers_feedback')) {
    secondaryActionItems.push(
      <ReopenPeersFeedbackAction
        userReview={userReview}
        key="reopenPeersFeedback"
      />
    );
  }

  if (secondaryActions.includes('unrelease')) {
    secondaryActionItems.push(
      <UnreleaseAction userReview={userReview} key="unrelease" />
    );
  }

  if (secondaryActions.includes('remind_peers_nomination')) {
    secondaryActionItems.push(
      <RemindPeersNominationAction
        key="remindPeersNomination"
        userReview={userReview}
      />
    );
  }
  if (secondaryActions.includes('remind_peers_feedback')) {
    secondaryActionItems.push(
      <RemindPeersFeedbackAction
        key="remindPeersFeedback"
        userReview={userReview}
      />
    );
  }
  if (secondaryActions.includes('remind_self_assessment')) {
    secondaryActionItems.push(
      <RemindSelfAssessmentAction
        userReview={userReview}
        key="remindSelfAssessment"
      />
    );
  }

  if (secondaryActions.includes('update_responsible')) {
    secondaryActionItems.push(
      <UpdateResponsibleAction
        userReview={userReview}
        reviewCycle={reviewCycle}
        onAfterAction={onAfterDelegate}
        key="updateResponsible"
      />
    );
  }
  return (
    <Flex verticalAlign>
      {mainButton}

      <div style={{ marginLeft: 10 }}>
        <CalendarButton
          userReviewId={userReview.id}
          meeting={assert(
            userReview.meeting,
            'Meeting should be available on userReview on this page'
          )}
          disabled={!can({ perform: 'schedule_meeting', on: userReview })}
        />
      </div>

      <div style={{ marginLeft: 10 }}>
        {secondaryActionItems.length ? (
          <HamburgerMenu align="right">
            <MenuList>{secondaryActionItems}</MenuList>
          </HamburgerMenu>
        ) : (
          <HamburgerMenu align="right" disabled />
        )}
      </div>
    </Flex>
  );
}
