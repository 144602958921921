import React, { useContext } from 'react';

import { useAppDispatch } from 'helpers/hooks';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { del, post } from 'redux/actions/api';

import {
  Button,
  Checkbox,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  StrictlySanitizedHtml,
  Text,
} from 'components';

import { DataContext } from '../..';
import generateModalTexts from './modalTexts/generateModalTexts';

type Props = {
  fieldSlug: string;
  locked: boolean;
  fieldLabel: string;
  lastSyncedFormattedValue: string | undefined;
  onClose: () => void;
};

export default function ActionModal({
  locked,
  onClose,
  fieldSlug,
  ...textGenerationProps
}: Props) {
  const { user, refetchUser } = useContext(DataContext);

  const organization = useOrganization();
  const [updatingSyncStatus, setUpdatingSyncStatus] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const dispatch = useAppDispatch();

  const onAttributeChangeSyncStatus = async ({ sync }: { sync: boolean }) => {
    try {
      setUpdatingSyncStatus(true);
      const method = sync ? post : del;
      await dispatch(
        method(`users/${user.id}/attribute_overrides`, { type: fieldSlug })
      );
      onClose();

      await refetchUser();
    } finally {
      setUpdatingSyncStatus(false);
    }
  };

  const modalTexts = generateModalTexts(locked, {
    ...textGenerationProps,
    userFullName: user.fullName,
    integrationName: organization.syncIntegrationInfo.name,
  });

  const submitAction = async () => {
    onAttributeChangeSyncStatus({ sync: locked });
  };

  return (
    <ModalCard isActive onClose={onClose} refreshContentOnOpening={false}>
      <ModalCardHead>
        <ModalCardTitle>{modalTexts.title}</ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody>
        <Text>
          <StrictlySanitizedHtml componentType="span" html={modalTexts.body1} />
        </Text>
        <br />
        <br />
        <Text additionalClassName="font-bold">{modalTexts.body2}</Text>
        <br />
        <br />
        <Text additionalClassName="mb-2 block">{modalTexts.body3}</Text>

        <Checkbox
          isChecked={checked}
          onChange={setChecked}
          label={modalTexts.checkboxLabel}
        />
      </ModalCardBody>

      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Cancel')}
        </Button>
        <Button
          disabled={!checked || updatingSyncStatus}
          isLoading={updatingSyncStatus}
          color="primary"
          onClick={submitAction}
        >
          {modalTexts.submitLabel}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
}
