import React from 'react';
import { connect } from 'react-redux';

import type { FormContent, User } from 'models';
import type { AppDispatch } from 'redux/actions/types';
import type { ReleaseActionInfo } from 'scenes/review/Review/ReleaseAction';

import { get } from 'redux/actions/api';

import ReviewMessages from './ReviewMessages';
import DropdownQuestionBlockContent from './blocks/DropdownQuestionBlockContent';
import FinalCommentBlockContent from './blocks/FinalCommentBlockContent';
import HeaderMessageBlockContent from './blocks/HeaderMessageBlockContent';
import InstructionsBlockContent from './blocks/InstructionsBlockContent';
import LegacyQuestionBlockContent from './blocks/LegacyQuestionBlockContent';
import MultipleScaleQuestionBlockContent from './blocks/MultipleScaleQuestionBlockContent';
import ObjectivesModuleBlockContent from './blocks/ObjectivesModuleBlockContent';
import ShareBlockContent from './blocks/ShareBlockContent';
import SignatureBlockContent from './blocks/SignatureBlockContent';
import SkillsCareerLevelBlockContent from './blocks/SkillsCareerLevelBlockContent';
import TextQuestionBlockContent from './blocks/TextQuestionBlockContent';
import TitleBlockContent from './blocks/TitleBlockContent';
import TrainingRequestHistoryBlockContent from './blocks/TrainingRequestHistoryBlockContent';

type Props = {
  content: FormContent;
  shareAction: ReleaseActionInfo;
  reviewee: User;
  userReviewId: string;
  isPreview?: boolean;
};

type AfterConnectProps = Props & {
  refreshBlock: (blockId: string) => Promise<void>;
};

function FormContentComponent({
  content,
  shareAction,
  refreshBlock,
  reviewee,
  userReviewId,
  isPreview = false,
}: AfterConnectProps) {
  const { blocks, messages } = content;

  const { refreshShareability } = shareAction;
  return (
    <div className="form-content">
      <ReviewMessages messages={messages} />
      {blocks.map((block, i) => {
        const isFirstBlock = i === 0;
        const blockType = block.blockType;
        switch (blockType) {
          case 'header_message':
            return (
              <HeaderMessageBlockContent
                key={block.id}
                content={block}
                withoutSectionSeparator={isFirstBlock}
              />
            );
          case 'title':
            return (
              <TitleBlockContent
                key={block.id}
                content={block}
                withoutSectionSeparator={isFirstBlock}
              />
            );
          case 'question':
            return (
              <LegacyQuestionBlockContent
                key={block.id}
                content={block}
                refreshShareability={refreshShareability}
                isPreview={isPreview}
              />
            );
          case 'dropdownQuestion':
            return (
              <DropdownQuestionBlockContent
                key={block.id}
                content={block}
                refreshShareability={refreshShareability}
                isPreview={isPreview}
              />
            );
          case 'text_question':
            return (
              <TextQuestionBlockContent
                key={block.id}
                content={block}
                refreshShareability={refreshShareability}
                isPreview={isPreview}
              />
            );
          case 'multipleScaleQuestion':
            return (
              <MultipleScaleQuestionBlockContent
                key={block.id}
                content={block}
                refreshShareability={refreshShareability}
                isPreview={isPreview}
              />
            );
          case 'objectives_module':
            return (
              <ObjectivesModuleBlockContent
                key={block.id}
                content={block}
                refreshBlock={refreshBlock}
                refreshShareability={refreshShareability}
                withoutSectionSeparator={isFirstBlock}
                isPreview={isPreview}
                revieweeFullName={reviewee.fullName}
              />
            );
          case 'final_comment':
            return (
              <FinalCommentBlockContent
                key={block.id}
                content={block}
                withoutSectionSeparator={isFirstBlock}
              />
            );
          case 'training_request_history':
            return (
              <TrainingRequestHistoryBlockContent
                key={block.id}
                content={block}
                reviewee={reviewee}
                userReviewId={userReviewId}
                withoutSectionSeparator={isFirstBlock}
              />
            );
          case 'release_review':
          case 'share_evaluation':
            return (
              <ShareBlockContent
                key={block.id}
                content={block}
                shareAction={shareAction}
                withoutSectionSeparator={isFirstBlock}
                isPreview={isPreview}
              />
            );
          case 'signature':
            return (
              <SignatureBlockContent
                key={block.id}
                content={block}
                withoutSectionSeparator={isFirstBlock}
              />
            );
          case 'instructions':
            return <InstructionsBlockContent key={block.id} content={block} />;
          case 'skills_career_level':
            return (
              <SkillsCareerLevelBlockContent
                key={block.id}
                content={block}
                reviewee={reviewee}
                isPreview={isPreview}
                refreshShareability={refreshShareability}
                refreshBlock={refreshBlock}
                withoutSectionSeparator={isFirstBlock}
              />
            );
          default:
            throw new Error(`Unknown type ${blockType}`);
        }
      })}
    </div>
  );
}

function mapDispatchToProps(dispatch: AppDispatch, { content }: Props) {
  const formContentPath = content.path;

  return {
    refreshBlock: (reviewBlockId: string) =>
      dispatch(get(`${formContentPath}/${reviewBlockId}`)),
  };
}

export default connect(null, mapDispatchToProps)(FormContentComponent);
