import React, { ReactElement, ReactNode } from 'react';

import type { OneOnOneUserReview, ReviewCycle } from 'models';

import invariant from 'helpers/invariant';

import { FeatureFlagged, MenuDivider } from 'components';

import { UpdateResponsibleAction } from 'scenes/components/userReview/actions';
import RemoveParticipantAction from 'scenes/components/userReview/common/actions/RemoveParticipantAction';
import {
  SendReminder,
  SendSignatureReminder,
  ShareEvaluation,
  UnshareEvaluation,
  UpdateAdditionalReviewerAction,
} from 'scenes/components/userReview/oneOnOne/actions';

export default function getAvailableMenuItems(
  reviewCycle: ReviewCycle,
  userReview: OneOnOneUserReview,
  refetchData: () => Promise<any>
): ReactNode[] {
  const abilities = userReview.abilities;
  invariant(abilities, 'abilities must be defined on userReview');

  const itemGroups: {
    remind: ReactElement[];
    unshare: ReactElement[];
    other: ReactElement[];
    remove: ReactElement[];
  } = {
    remind: [],
    unshare: [],
    other: [],
    remove: [],
  };

  abilities.forEach(ability => {
    const abilityId = ability.id;
    switch (abilityId) {
      case 'show_review':
      case 'view_results':
      case 'export_pdf':
        break; // Handled with a link
      case 'schedule_meeting':
      case 'change_review_template':
        // This is handled in a separate button
        break;
      case 'update_additional_reviewer':
        itemGroups.other.push(
          <FeatureFlagged
            flag="reviewWithAdditionalReviewer"
            key="updateAdditionalReviewer"
          >
            <UpdateAdditionalReviewerAction
              userReview={userReview}
              key="updateAdditionalReviewer"
              onAfterAction={refetchData}
            />
          </FeatureFlagged>
        );
        break;
      case 'remind_reviewer_signature':
        itemGroups.remind.push(
          <SendSignatureReminder
            userReview={userReview}
            target="reviewer"
            key="sendSignatureReminderToReviewer"
          />
        );
        break;
      case 'remind_reviewee_signature':
        itemGroups.remind.push(
          <SendSignatureReminder
            userReview={userReview}
            target="reviewee"
            key="sendSignatureReminderToReviewee"
          />
        );
        break;
      case 'remind_reviewer_evaluation':
        itemGroups.remind.push(
          <SendReminder
            userReview={userReview}
            target="reviewer"
            key="sendReminderToReviewer"
          />
        );
        break;
      case 'remind_self_assessment':
        itemGroups.remind.push(
          <SendReminder
            userReview={userReview}
            target="reviewee"
            key="sendReminderToReviewee"
          />
        );
        break;
      case 'unshare_self_assessment':
        itemGroups.unshare.push(
          <UnshareEvaluation
            userReview={userReview}
            target="reviewee"
            key="unshareRevieweeEvaluation"
          />
        );
        break;
      case 'unshare_main_reviewer_evaluation':
        itemGroups.unshare.push(
          <UnshareEvaluation
            userReview={userReview}
            target="reviewer"
            onAfterAction={refetchData}
            key="unshareReviewerEvaluation"
          />
        );
        break;
      case 'unshare_additional_reviewer_evaluation':
        itemGroups.unshare.push(
          <UnshareEvaluation
            userReview={userReview}
            target="additional_reviewer"
            key="unshareAdditionalReviewerEvaluation"
          />
        );
        break;
      case 'unshare_reviewers_evaluations':
        itemGroups.unshare.push(
          <UnshareEvaluation
            userReview={userReview}
            target="reviewers"
            onAfterAction={refetchData}
            key="unshareAdditionalReviewersEvaluation"
          />
        );
        break;
      case 'share_main_reviewer_evaluation':
        itemGroups.other.push(
          <ShareEvaluation
            userReview={userReview}
            target="reviewer"
            onAfterAction={refetchData}
            key="shareReviewerEvaluation"
          />
        );
        break;
      case 'share_reviewers_evaluations':
        itemGroups.other.push(
          <ShareEvaluation
            userReview={userReview}
            target="reviewers"
            onAfterAction={refetchData}
            key="shareReviewersEvaluation"
          />
        );
        break;
      case 'share_self_assessment':
        itemGroups.other.push(
          <ShareEvaluation
            userReview={userReview}
            target="reviewee"
            key="shareRevieweeEvaluation"
          />
        );
        break;
      case 'update_responsible':
        itemGroups.other.push(
          <UpdateResponsibleAction
            userReview={userReview}
            reviewCycle={reviewCycle}
            onAfterAction={refetchData}
            key="updateResponsibleAction"
          />
        );
        break;
      case 'remove_participant':
        itemGroups.remove.push(
          <RemoveParticipantAction
            key="removeParticipantAction"
            reviewCycle={reviewCycle}
            userReview={userReview}
          />
        );
        break;
      case 'show_create_training_request_button':
        //not a menu action
        break;
      default:
        break;
    }
  });

  const elements = [];
  (Object.keys(itemGroups) as (keyof typeof itemGroups)[]).forEach(key => {
    const items = itemGroups[key];
    // @ts-ignore TSFIXME: Fix strictNullChecks error
    items.forEach(item => elements.push(item));
    // @ts-ignore TSFIXME: Fix strictNullChecks error
    if (items.length > 0) elements.push(<MenuDivider key={key} />);
  });

  elements.pop();

  return elements;
}
