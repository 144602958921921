import { AdminRoleName } from 'models/Role';

import { __ } from 'helpers/i18n';

const displayableRoleName = (roleName: AdminRoleName) => {
  switch (roleName) {
    case 'admin':
      return __('Global administrator');
    case 'performance_admin':
      return __('Performance administrator');
    case 'training_admin':
      return __('Training administrator');
    // Deprecation EA-7578: Remove campaigns_supervisor role (see MR !7641)
    case 'campaigns_supervisor':
    case 'campaigns_responsible':
      return __('Campaigns responsible');
    default:
      return roleName;
  }
};

export default displayableRoleName;
