import React, { Fragment } from 'react';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToUserReviews } from 'helpers/paths';

import {
  BreadcrumbAnchor,
  Button,
  Column,
  Columns,
  ContentContainer,
  PageHeader,
  PageTitle,
} from 'components';

import MyReviewCyclesWrapper from './MyReviewCyclesWrapper';

type Props = {
  type: string;
};

const MyReviews = ({ type }: Props) => {
  const activeUser = useActiveUser();

  return (
    <Fragment>
      <PageTitle title={__('My reviews')} />
      <BreadcrumbAnchor name="user_review_list" />
      <PageHeader
        title={type === '360' ? __('My 360 Feedback') : __('My Reviews')}
        actions={
          <Button
            color="secondary"
            to={pathToUserReviews(activeUser.id)}
            additionalClassName="previous-reviews-button"
          >
            {type === '360'
              ? __('Access to my previous 360 feedback')
              : __('Access to my previous reviews')}
          </Button>
        }
      />
      <ContentContainer>
        <Columns>
          <Column size={12}>
            <MyReviewCyclesWrapper type={type} />
          </Column>
        </Columns>
      </ContentContainer>
    </Fragment>
  );
};

export default MyReviews;
