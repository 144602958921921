import { Answer, User } from 'models';

const groupUsersByAnswerRating = (closedAnswers: Answer[]) => {
  const usersBySelectedLabel: { [rating: string]: User[] } = {};

  closedAnswers.forEach(answer => {
    if (answer.rating === null) return;

    const users = usersBySelectedLabel[answer.rating] || [];
    users.push(answer.author);
    return (usersBySelectedLabel[answer.rating] = users);
  });

  return usersBySelectedLabel;
};

export default groupUsersByAnswerRating;
