import React, { useState } from 'react';
import ActiveStorageProvider from 'react-activestorage-provider';

import type { ReactNode } from 'react';

import { assert } from 'helpers/invariant';
import { wait } from 'helpers/time';

import { targetApi } from 'lib/api/constants';
import { getCredentials } from 'lib/api/credentials';

import ProgressRenderer from './ProgressRenderer';

type Props = {
  resourcePath: string;
  method: string;
  attribute: string;
  model: string;
  onSuccess: () => Promise<void>;
  render: (callbacks: {
    uploadFromDataURL: (dataUrl: string) => Promise<void>;
  }) => ReactNode;
};

function fileExtensionFromMimeType(mimeType: string): string {
  // These are the file types that we currently want to handle in ImagePicker.
  // If we want to use this component for other types of upload, we will need
  // to use/create a library.
  switch (mimeType) {
    case 'image/jpeg':
      return 'jpeg';
    case 'image/png':
      return 'png';
    case 'image/gif':
      return 'gif';
    default:
      throw new Error(`Filetype ${mimeType} not handled for upload`);
  }
}

export default function DirectUploader({
  resourcePath,
  method,
  attribute,
  model,
  onSuccess,
  render,
}: Props) {
  const [modelUpdateError, setModelUpdateError] = useState<string | null>(null);

  const credentials = getCredentials();
  const authorizationHeader = `Bearer ${assert(
    credentials.token,
    'Credentials required for direct upload'
  )}`;

  return (
    // @ts-ignore
    <ActiveStorageProvider
      endpoint={{
        path: `/api/v1/${resourcePath}`,
        model,
        attribute,
        method,
        host: assert(targetApi.hostname, 'hostname must be defined'),
        port: targetApi.port || undefined,
        protocol: targetApi.httpsEnabled ? 'https' : 'http',
      }}
      directUploadsPath="/api/v1/direct_uploads"
      headers={{
        Authorization: authorizationHeader,
      }}
      onError={response => {
        setModelUpdateError(
          `Error while updating user: ${response.status} ${response.statusText}`
        );
      }}
      onSubmit={async () => {
        await onSuccess();
        setModelUpdateError(null);
      }}
      render={({ handleUpload, uploads, ready }) => {
        const progress = { isUploading: false };

        async function handleUploadFromDataURL(dataUrl: string) {
          const res = await fetch(dataUrl);
          const blob = await res.blob();
          const extension = fileExtensionFromMimeType(blob.type);
          const file = new File([blob], `${attribute}.${extension}`, {
            type: blob.type,
          });

          progress.isUploading = true;
          await handleUpload([file]);
          progress.isUploading = false;
        }

        async function uploadFromDataURL(dataUrl: string) {
          await handleUploadFromDataURL(dataUrl);
          while (progress.isUploading) {
            await wait(100);
          }
        }

        return (
          <ProgressRenderer
            modelUpdateError={modelUpdateError}
            uploads={uploads}
            ready={ready}
            render={({ isUploading }) => {
              progress.isUploading = isUploading;
              return render({ uploadFromDataURL });
            }}
          />
        );
      }}
    />
  );
}
