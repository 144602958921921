import React from 'react';

import type { InteractionType } from 'models';
import type {
  MultipleScaleQuestionBlock,
  ReviewBlockUpdateParams,
} from 'models/ReviewStructure';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import {
  Box,
  BoxSeparator,
  Flex,
  Icon,
  Title6,
  WithSavingStatusRecorder,
} from 'components';

import BlockActions from '../components/BlockActions';
import BlockContent from '../components/BlockContent';
import FeedbackOptionsSection from '../components/FeedbackOptionsSection';
import RatingsSection from './RatingsSection';

type Props = {
  block: MultipleScaleQuestionBlock;
  interactionType: InteractionType;
  justEnteredInList: boolean;
  hasSelfEvaluationEnabled: boolean;
  onChange: (params: ReviewBlockUpdateParams) => Promise<void>;
  onDelete?: () => Promise<void>;
  onMoveUp: () => Promise<void>;
  onMoveDown: () => Promise<void>;
  onDuplicate: () => Promise<void>;
};

const MultipleScaleQuestionBlockComponent = ({
  block,
  interactionType,
  justEnteredInList,
  hasSelfEvaluationEnabled,
  onChange,
  ...actionProps
}: Props) => {
  const { content, richTextEnabled } = block;
  return (
    <Box testClassName="test-multiple-scale-question-block">
      <Flex style={{ justifyContent: 'space-between' }} verticalAlign>
        <Flex>
          {/* line height is 22px to be aligned with text */}
          <Icon style={{ lineHeight: '22px', marginRight: 8 }} name="apps" />
          <Title6 transformation="uppercase">{__('Multiple Scale')}</Title6>
        </Flex>
        <BlockActions
          canMoveUp={block.canMoveUp}
          canMoveDown={block.canMoveDown}
          canDuplicate={block.canDuplicate}
          {...actionProps}
        />
      </Flex>

      <BoxSeparator style={{ margin: '16px 0' }} />

      <WithSavingStatusRecorder
        fieldUid={compositeKey({
          blockId: block.id,
          field: 'multiple_scale_question_block_content',
        })}
        onChange={onChange}
        render={autoSavingOnchange => (
          <BlockContent
            onChange={autoSavingOnchange}
            content={content}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={justEnteredInList}
            withRichText={richTextEnabled}
          />
        )}
      />

      <RatingsSection
        reviewBlock={block}
        ratingCriteria={block.ratingCriteria}
        ratingOptions={block.ratingOptions}
      />

      <BoxSeparator style={{ margin: '16px 0' }} />

      <FeedbackOptionsSection
        revieweeFeedbackOptions={block.revieweeFeedbackOptions}
        reviewerFeedbackOptions={block.reviewerFeedbackOptions}
        hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
        interactionType={interactionType}
        blockType={block.blockType}
      />
    </Box>
  );
};

export default MultipleScaleQuestionBlockComponent;
