import React from 'react';

import type { TeamObjectivePeriod } from 'models';

import { n__ } from 'helpers/i18n';

export const getDeleteTeamObjectivePeriodSideEffects = (
  teamObjectivePeriod: TeamObjectivePeriod
) => {
  const sideEffects =
    teamObjectivePeriod.objectives.length > 0
      ? [
          n__(
            'Delete 1 objective for team "%2" in period %3',
            'Delete %1 objectives for team "%2" in period %3',
            teamObjectivePeriod.objectives.length,
            <b>{teamObjectivePeriod.team.name}</b>,
            <b>{teamObjectivePeriod.name}</b>
          ),
        ]
      : [];

  return sideEffects;
};
