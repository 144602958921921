import React, { CSSProperties } from 'react';

import type { ResponsibleRole, User } from 'models';

import { __ } from 'helpers/i18n';

import {
  Checkbox,
  FeatureFlagged,
  Flex,
  Helper,
  Icon,
  Text,
  Title,
  Tooltip,
} from 'components';

import ResponsiblePicker from 'scenes/components/ResponsiblePicker';

type Props = {
  responsible: User | undefined | null;
  responsibleRole: ResponsibleRole | undefined | null;
  reviewInteractionType: '360' | 'one_on_one';
  visibleForSuperiors: boolean;
  visibleForReviewee: boolean;
  style?: CSSProperties;
  onChange: (params: {
    responsible: User | undefined | null;
    responsibleRole: ResponsibleRole | undefined | null;
  }) => Promise<void>;
  onReviewCycleVisibilityChange: (visibilityOptions: {
    visibleForReviewee?: boolean;
    visibleForSuperiors?: boolean;
  }) => Promise<void>;
};

type State = {
  responsible: User | undefined | null;
  responsibleRole: ResponsibleRole | undefined | null;
};

export default class _ResponsiblePicker extends React.Component<Props, State> {
  state = {
    responsible: null,
    responsibleRole: null,
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.responsible === null && state.responsibleRole === null) {
      return {
        responsible: props.responsible,
        responsibleRole: props.responsibleRole,
      };
    }

    return state;
  }

  handleChange = async ({
    responsible,
    responsibleRole,
  }: {
    responsible: User | undefined | null;
    responsibleRole: ResponsibleRole | undefined | null;
  }) => {
    if (!!responsible || responsibleRole === 'manager') {
      !!this.props.onChange &&
        this.props.onChange({ responsible, responsibleRole });
    }

    this.setState({ responsible, responsibleRole });
  };

  renderTooltipContent = (is360: boolean) => {
    return (
      <div className="restrict-manager-tooltip">
        <Title size={6} additionalClassName="tooltip-title">
          {is360 ? __('360° feedback visibility') : __('Review visibility')}
        </Title>
        <Text>
          {is360
            ? __('By default the 360° feedback will be visible by:')
            : __('By default the review will be visible by:')}
        </Text>

        <ul>
          <li>
            <Text>{__('The reviewee')}</Text>
          </li>
          <li>
            <Text>{__('The review responsible')}</Text>
          </li>
          <li>
            <Text>{__('The manager and supervisors')}</Text>
          </li>
          <li>
            <Text>{__('The admin')}</Text>
          </li>
        </ul>
      </div>
    );
  };

  renderHelperText = (is360: boolean) => {
    if (is360) {
      return (
        <span>
          {__(
            'The direct manager is selected as the responsible, so he will have visibility over the 360° feedback'
          )}
          <br />
          {__(
            'Indirect supervisors will not have access to the 360° feedback.'
          )}
        </span>
      );
    }

    return (
      <span>
        {__(
          'The direct manager is selected as the responsible, so he will have visibility over the review.'
        )}
        <br />
        {__('Indirect supervisors will not have access to the review.')}
      </span>
    );
  };

  render() {
    const {
      style,
      reviewInteractionType,
      onReviewCycleVisibilityChange,
      visibleForSuperiors,
      visibleForReviewee,
    } = this.props;
    const { responsible, responsibleRole } = this.state;
    const is360 = reviewInteractionType === '360';
    const showManagerVisibilityAlert =
      !visibleForSuperiors && responsibleRole === 'manager';

    return (
      <React.Fragment>
        <Text preset="24bs2">
          {is360
            ? __('Select the 360° feedback’s responsible')
            : __('Select the review’s responsible')}
        </Text>
        <p style={{ marginBottom: 16 }}>
          {is360
            ? __(
                'The role of the responsible is to evaluate and manage the progress of the 360° feedback. He also sees all feedback.'
              )
            : __(
                'The role of the responsible is to evaluate and manage the progress of the review. He also sees all feedback.'
              )}
        </p>
        <div style={style}>
          <ResponsiblePicker
            responsible={responsible}
            responsibleRole={responsibleRole}
            onChange={this.handleChange}
          />
        </div>
        <FeatureFlagged flag="restrictReviewsVisibility">
          <Flex verticalAlign style={{ marginBottom: 8 }}>
            <Text preset="24bs2">
              {is360
                ? __('The 360° feedback visibility')
                : __('The review visibility')}
            </Text>

            <Tooltip content={this.renderTooltipContent(is360)} isLight>
              <Icon style={{ marginLeft: 8 }} name="help" />
            </Tooltip>
          </Flex>

          <Flex verticalAlign additionalClassName="ml-3 gap-1">
            <Checkbox
              isChecked={visibleForSuperiors}
              size="small"
              onChange={value =>
                onReviewCycleVisibilityChange({ visibleForSuperiors: value })
              }
            />
            <Text>
              {is360
                ? __(
                    'The 360° feedback will be visible by the manager and supervisors'
                  )
                : __(
                    'The review will be visible by the manager and supervisors'
                  )}
            </Text>
          </Flex>

          <FeatureFlagged flag="visibleForReviewee">
            {!is360 && (
              <Flex verticalAlign additionalClassName="ml-3 gap-1">
                <Checkbox
                  isChecked={visibleForReviewee}
                  size="small"
                  onChange={value =>
                    onReviewCycleVisibilityChange({ visibleForReviewee: value })
                  }
                />
                <Text>{__('The review will be visible by the employee')}</Text>
              </Flex>
            )}
          </FeatureFlagged>

          {showManagerVisibilityAlert && (
            <Helper style={{ marginTop: 8 }}>
              {this.renderHelperText(is360)}
            </Helper>
          )}
        </FeatureFlagged>
      </React.Fragment>
    );
  }
}
