/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { MouseEvent } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import classNames from 'helpers/classNames';
import invariant from 'helpers/invariant';

import { Icon } from 'components';

import LinkWithPromise from './LinkWithPromise';

type Props = {
  to?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  openInNewTab?: boolean;
  withOpenInNewTabIcon?: boolean;
  disabled?: boolean;
  hasInheritedColor?: boolean;
  onClick?: (e: MouseEvent) => void;
  onMouseDown?: () => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLAnchorElement>;
  title?: string;
  className?: string | null;
  additionalClassName?: string;
  testClassName?: string;
  tabIndex?: number;
};

export default function Link({
  to,
  children,
  style,
  openInNewTab,
  title,
  disabled,
  hasInheritedColor,
  onClick,
  onMouseDown,
  onKeyDown,
  additionalClassName,
  testClassName,
  className: classNameProp,
  tabIndex,
  withOpenInNewTabIcon = true,
}: Props) {
  const actuallyDisabled = disabled || (!to && !onClick);
  const className =
    classNameProp === null
      ? ''
      : classNameProp ||
        classNames('reveal_link', 'link', additionalClassName, testClassName, {
          'is-disabled': actuallyDisabled,
          'has-inherited-color': hasInheritedColor,
        });

  if (actuallyDisabled) {
    return (
      <a className={className} style={style} tabIndex={tabIndex}>
        {children}
      </a>
    );
  }

  if (openInNewTab) {
    return (
      <a
        title={title}
        href={to}
        className={className}
        target="_blank"
        style={style}
        tabIndex={tabIndex}
        rel="noreferrer"
      >
        {children}
        {withOpenInNewTabIcon && ' ' && (
          <Icon
            size="tiny"
            style={{ verticalAlign: 'text-bottom' }}
            name="open_in_new"
          />
        )}
      </a>
    );
  }

  if (onClick || onMouseDown) {
    return (
      <LinkWithPromise
        onClick={onClick}
        onMouseDown={onMouseDown}
        className={className}
        style={style}
        onKeyDown={onKeyDown}
        tabIndex={tabIndex}
      >
        {children}
      </LinkWithPromise>
    );
  }

  invariant(to, 'to must be defined at this point');

  return (
    <ReactRouterLink
      style={style}
      className={className}
      to={to}
      tabIndex={tabIndex}
    >
      {children}
    </ReactRouterLink>
  );
}
