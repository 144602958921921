import React from 'react';

import { __ } from 'helpers/i18n';

import { DeprecatedContainerElevo, PageTitle, Title } from 'components';

import TeamObjectivesFeatureCard from 'scenes/admin/Settings/TeamObjectivesFeatureCard';

const LegacySettings = () => (
  <DeprecatedContainerElevo additionalClassName="mt-5">
    <PageTitle title={[__('Settings'), __('Admin')]} />
    <Title
      size={5}
      additionalClassName={'page-title'}
      style={{ marginBottom: 24 }}
    >
      {__('Features')}
    </Title>
    <TeamObjectivesFeatureCard />
  </DeprecatedContainerElevo>
);

export default LegacySettings;
