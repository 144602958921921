import React from 'react';

import { __ } from 'helpers/i18n';

import { InformationModal } from 'components';

type Props = {
  isActive: boolean;
  onOK: () => void;
};

export default function NotAllowedToCreateAutoAddRulesModal({
  isActive,
  onOK,
}: Props) {
  return (
    <InformationModal
      refreshContentOnOpening={false}
      isActive={isActive}
      onOK={onOK}
    >
      <p>
        {__(
          'This setting automatically adds participants regardless of their entity, therefore only organization admins can create campaigns with this setting'
        )}
      </p>
    </InformationModal>
  );
}
