import React from 'react';

import type { UserCareer as Career, User } from 'models';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Column,
  Columns,
  DesignSystem,
  FetchContainer,
  Flex,
} from 'components';

import CurrentMatrixVisualizerBox from './CurrentMatrixVisualizerBox';
import LevelsManagementSection from './LevelsManagementSection';
import RelatedReviewsSection from './RelatedReviewsSection';

type Props = {
  user: User;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    userCareer: Career;
  };

const UserCareer = ({
  user,
  isFetching,
  hasError,
  refetchData,
  userCareer,
}: AfterDataLoaderProps) => {
  return (
    <DesignSystem version={2}>
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        loadingStyle="alone"
        render={() => {
          return (
            <Columns>
              <Column size={7}>
                <CurrentMatrixVisualizerBox
                  user={user}
                  userCareer={userCareer}
                />
              </Column>
              <Column size={5}>
                <Flex direction="column">
                  <LevelsManagementSection
                    refetchData={refetchData}
                    user={user}
                    userCareer={userCareer}
                  />
                  {userCareer.relatedReviews.length > 0 && (
                    <RelatedReviewsSection
                      reviews={userCareer.relatedReviews}
                    />
                  )}
                </Flex>
              </Column>
            </Columns>
          );
        }}
      />
    </DesignSystem>
  );
};

export default newDataLoader({
  fetch: ({ user }: Props) => get(`users/${user.id}/career`),
  hydrate: {
    userCareer: {
      currentCareerLevel: { skillsLevel: {} },
      nextCareerLevel: { skillsLevel: {} },
      relatedReviews: { abilities: {} },
      currentExpectationMappings: { expectation: {} },
    },
  },
})(UserCareer);
