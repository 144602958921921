import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion';
import { uniqueId } from 'lodash';
import React, { type ReactNode } from 'react';

import classNames from 'helpers/classNames';

type Props = {
  showContent?: boolean;
  contentClassName?: string;
  className?: string;
  value?: string | number;
  title: ReactNode;
  content: ReactNode;
};

export default function ElevoAccordionItem({
  value,
  title,
  content,
  showContent,
  className,
  contentClassName,
}: Props) {
  const uniqueItemId = React.useRef(uniqueId()).current;

  return (
    <AccordionItem value={`${value || uniqueItemId}`} className={className}>
      <AccordionHeader>
        <AccordionTrigger className="bg-transparent border-none p-0 w-full">
          {title}
        </AccordionTrigger>
      </AccordionHeader>
      <AccordionContent
        className={classNames(contentClassName, 'accordion-content')}
        {...(showContent ? { forceMount: true } : {})}
      >
        {content}
      </AccordionContent>
    </AccordionItem>
  );
}
