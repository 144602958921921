import React from 'react';

import type { OneOnOneReviewCycle } from 'models';

import { __ } from 'helpers/i18n';

import BulkRemindersMenuButton from '../BulkRemindersMenuButton';

type Props = {
  reviewCycle: OneOnOneReviewCycle;
};

export default function OneOnOneBulkRemindersMenuButton({
  reviewCycle,
}: Props) {
  return (
    <BulkRemindersMenuButton
      reviewCycle={reviewCycle}
      reminders={{
        'auto evaluations': [
          {
            type: 'auto_evaluation_not_started',
            confirmation: __(
              'Send a reminder to all reviewees that did not start their review?'
            ),
            label: __('Self assessments not started'),
            condition: (reviewCycle: OneOnOneReviewCycle) =>
              reviewCycle.hasSelfEvaluationEnabled,
          },
          {
            type: 'auto_evaluation_not_shared',
            confirmation: __(
              'Send a reminder to all reviewees that did not share their review?'
            ),
            label: __('Self assessments not submitted'),
            condition: (reviewCycle: OneOnOneReviewCycle) =>
              reviewCycle.hasSelfEvaluationEnabled,
          },
        ],
        'manager evaluations': [
          {
            type: 'manager_evaluation_not_started',
            confirmation: __(
              'Send a reminder to all managers that did not start their review?'
            ),
            label: __('Manager assessments not started'),
          },
          {
            type: 'manager_evaluation_not_shared',
            confirmation: __(
              'Send a reminder to all managers that did not share their review?'
            ),
            label: __('Manager assessments not submitted'),
          },
        ],
        signatures: [
          {
            type: 'signature_missing',
            confirmation: __(
              'Send a reminder to all reviewees and managers who shared but did not sign their review?'
            ),
            label: __('Signatures missing'),
            condition: (reviewCycle: OneOnOneReviewCycle) =>
              reviewCycle.signatureModuleEnabled,
          },
        ],
      }}
    />
  );
}
