import React from 'react';

import { TargetedCampaignRole } from 'models';

import { __ } from 'helpers/i18n';

import { Select } from 'components';

import { displayableRoleName } from '../../helpers';
import Option from './Option';

type Props = {
  selectedRoleName: TargetedCampaignRole['name'];
  onChange: (roleName: TargetedCampaignRole['name']) => void;
};

const RoleNameSelect = ({ selectedRoleName, onChange }: Props) => {
  const roles = [
    'targeted_campaign_admin',
    'targeted_campaign_supervisor',
  ] as Array<TargetedCampaignRole['name']>;

  const options = roles.map(role => ({
    label: displayableRoleName(role),
    value: role,
  }));

  return (
    <div className="basis-1/2">
      <Select
        value={options.find(option => option.value === selectedRoleName)}
        placeholder={__('Select a role...')}
        options={options}
        onChange={option =>
          !!option ? onChange(option.value) : onChange(selectedRoleName)
        }
        components={{ Option: Option }}
        inModal
      />
    </div>
  );
};

export default RoleNameSelect;
