import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { DesignSystem, Redirect, Route, Switch } from 'components';

import Results from './Results';
import ENPS from './index';

export default function Routes() {
  const { path } = useRouteMatch();

  return (
    <DesignSystem version={2}>
      <Switch>
        <Route path={`${path}`} exact component={ENPS} />
        <Route path={`${path}/results`} exact component={Results} />

        <Redirect to={`${path}`} />
      </Switch>
    </DesignSystem>
  );
}
