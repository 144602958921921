import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { AuthenticationStrategy } from 'models';

import { largeMargin } from 'styles/uiConstants';

import { __ } from 'helpers/i18n';
import queryString from 'helpers/queryString';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Centered,
  FetchContainer,
  PageTitle,
  Splash,
  Title3,
} from 'components';

import EmailPasswordLoginForm from './EmailPasswordLoginForm';
import SSOButton from './SSOButton';

type Props = {};

type AfterDataLoaderProps = DataLoaderProvidedProps & {
  defaultAuthenticationStrategies: Array<AuthenticationStrategy>;
};

function SignIn({
  isFetching,
  hasError,
  defaultAuthenticationStrategies,
}: AfterDataLoaderProps) {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const loginFromQueryParams = searchParams.get('login') || '';

  const [login, setLogin] = useState(loginFromQueryParams);

  const forceRedirect =
    queryString.parse(location.search).force_redirect !== 'false';

  if (isFetching || hasError) {
    return (
      <Splash>
        <FetchContainer hasError={hasError} isFetching={isFetching} />
      </Splash>
    );
  }

  const passwordStrategy = defaultAuthenticationStrategies.find(
    authStrategy => authStrategy.strategy === 'password'
  );
  const samlStrategy = defaultAuthenticationStrategies.find(
    authStrategy => authStrategy.strategy === 'saml'
  );

  const showAccessCodeLink = passwordStrategy?.options?.accessCodesEnabled;

  if (!passwordStrategy && samlStrategy && forceRedirect) {
    // Nothing else than SSO, let's redirect directly
    window.location.href = samlStrategy.redirect;
  }

  return (
    <Splash>
      <PageTitle title={__('Welcome')} />

      <Centered>
        <Title3>{__('Welcome')}</Title3>

        {passwordStrategy && (
          <EmailPasswordLoginForm onLoginChange={setLogin} />
        )}
        {samlStrategy && (
          <SSOButton
            style={{ marginTop: largeMargin }}
            redirectTo={samlStrategy.redirect}
          />
        )}

        <div style={{ marginTop: largeMargin }}>
          <p className="has-text-centered">
            <Link
              to={`/forgot_password?login=${window.encodeURIComponent(login)}`}
            >
              {__('Forgot your password ?')}
            </Link>
          </p>
          {!!showAccessCodeLink ? (
            <p className="has-text-centered">
              <Link
                to={`/access_code?login=${window.encodeURIComponent(login)}`}
              >
                {__('Do you have an access code?')}
              </Link>
            </p>
          ) : (
            <p className="has-text-centered">
              <Link
                to={`/forgot_password?login=${window.encodeURIComponent(
                  login
                )}`}
              >
                {__('First login ? Set your password here')}
              </Link>
            </p>
          )}
        </div>
      </Centered>
    </Splash>
  );
}

export default newDataLoader({
  fetch: () => get('authentication_strategies/default'),
  hydrate: {
    defaultAuthenticationStrategies: {},
  },
})(SignIn) as React.ComponentType<Props>;
