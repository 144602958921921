import { Organization, SurveyQuestion } from 'models';

import { __ } from 'helpers/i18n';

export const getQuestionAnswerFilters = (
  organization: Organization,
  question: SurveyQuestion
) => {
  const allFilter = { param: 'all', label: __('All') };

  if (question.questionType === 'nps') {
    return [
      allFilter,
      { param: 'promoters', label: __('Promoters') },
      { param: 'passives', label: __('Passives') },
      { param: 'detractors', label: __('Detractors') },
    ];
  } else if (
    question.questionType === 'text' &&
    organization.aiFeaturesEnabled &&
    question.textSentimentEnabled
  ) {
    return [
      allFilter,
      { param: 'positive', label: __('Positive') },
      { param: 'neutral', label: __('Neutral') },
      { param: 'negative', label: __('Negative') },
      { param: 'no_sentiment', label: __('No sentiment') },
    ];
  }

  return undefined;
};
