// @flow
import React from 'react';

import { Overlay, Title4 } from 'components';

let version = null;
try {
  version = require('../../version');
} catch (e) {
  version = 'unknown';
}

export const Version = () => (
  <Overlay additionalClassName="service-version">
    <div style={{ textAlign: 'center' }}>
      <Title4>{version}</Title4>
    </div>
  </Overlay>
);
