import type { BulmaColor, BulmaSize } from 'components/types/bulma';

export const bulmaColorClassName = (color?: BulmaColor): string => {
  if (!color) return '';

  switch (color) {
    case 'primary':
      return 'is-primary';
    case 'info':
      return 'is-info';
    case 'danger':
      return 'is-danger';
    case 'warning':
      return 'is-warning';
    case 'success':
      return 'is-success';
    default:
      return '';
  }
};

export const bulmaSizeClassName = (size?: BulmaSize): string => {
  if (!size) return '';

  switch (size) {
    case 'tiny':
      return 'is-tiny';
    case 'small':
      return 'is-small';
    case 'normal':
      return '';
    case 'medium':
      return 'is-medium';
    case 'large':
      return 'is-large';
    default:
      return '';
  }
};
