import { sortBy } from 'lodash';

import type { Occupation } from 'models';

/**
 * @param occupations Array of occupations, will be ordered by start_date in the this helper
 * @returns Array of Object which contains: an occupations grouped by consecutive similar occupations ordered, min startDate, max endDate (assuming null is the maximum) and title.
 * If an occupation has the same name and is continuous with the previous one (based on start date), group them together in occupations key.
 * Ex: [{ occupations: [{title: "dev", startDate: "2020-01-01", endDate: "2020-02-02"}, {title: "dev", startDate: "2020-02-02", endDate: "2020-03-03"}, {title: "dev", startDate: "2021-12-12", endDate: "2022-00-00"}] }]
 * => [{ occupations: [[{title: "dev", startDate: "2020-01-01", endDate: "2020-02-02"}, {title: "dev", startDate: "2020-02-02", endDate: "2020-03-03"}] }, { occupations: [{title: "dev", startDate: "2021-12-12", endDate: "2022-00-00"}] }]
 */
type ObjectOccupations = {
  occupations: Array<Occupation>;
  title: string;
  startDate: string;
  endDate: string | null;
};

const groupConsecutiveSimilarOccupations = (occupations: Array<Occupation>) => {
  const groupedOccupations: Array<ObjectOccupations> = [];

  sortBy(occupations, 'startDate').forEach(occupation => {
    const currentGroup = groupedOccupations[groupedOccupations.length - 1];

    if (!!currentGroup && currentGroup.title === occupation.title) {
      currentGroup.occupations.unshift(occupation);

      if (!occupation.endDate) {
        // We consider that if the endDate is null, it's like an endDate to the infinite (a maximum possible)
        currentGroup.endDate = null;
      } else if (
        !!currentGroup.endDate &&
        currentGroup.endDate < occupation.endDate
      ) {
        currentGroup.endDate = occupation.endDate;
      }
    } else {
      groupedOccupations.push({
        occupations: [occupation],
        title: occupation.title,
        startDate: occupation.startDate,
        endDate: occupation.endDate,
      });
    }
  });

  return groupedOccupations.reverse();
};

export default groupConsecutiveSimilarOccupations;
