import React from 'react';

import type { UserSync } from 'models';

import { useActiveUser } from 'helpers/hooks';
import { __, dateCalendar } from 'helpers/i18n';
import { pathToSyncDetails } from 'helpers/paths';

import { Link, Text } from 'components';

type UserSyncProps = {
  userSync: UserSync;
};

const UserSyncSummary = ({ userSync }: UserSyncProps) => {
  switch (userSync.status) {
    case 'in_progress':
      return (
        <Text color="info">
          {__(
            'Synchronization ongoing, started %1',
            dateCalendar(userSync.startedAt).toLowerCase()
          )}
        </Text>
      );

    case 'completed':
      return userSync.syncWarnings && userSync.syncWarnings.length > 0 ? (
        <Text color="info">
          {__(
            'Warnings during last synchronization %1',
            dateCalendar(userSync.endedAt).toLowerCase()
          )}
        </Text>
      ) : (
        <Text color="info">
          {__('Synchronized %1', dateCalendar(userSync.endedAt).toLowerCase())}
        </Text>
      );

    case 'failed':
      return (
        <Text color="info">
          {__(
            'Last synchronization failed %1',
            dateCalendar(userSync.startedAt).toLowerCase()
          )}
        </Text>
      );

    default:
      return null;
  }
};

const UserSyncLink = ({ userSync }: UserSyncProps) => {
  return (
    <Text color="info">
      {' ('}
      <Link
        to={pathToSyncDetails(userSync.organizationSyncId)}
        style={{ textDecoration: 'underline' }}
      >
        {__('see details')}
      </Link>
      {')'}
    </Text>
  );
};

type Props = {
  userSync: UserSync | undefined | null;
};

const UserSyncState = ({ userSync }: Props) => {
  const activeUser = useActiveUser();

  if (!userSync) {
    return null;
  }

  return (
    <React.Fragment>
      <UserSyncSummary userSync={userSync} />
      {activeUser.isOrganizationAdmin && <UserSyncLink userSync={userSync} />}
    </React.Fragment>
  );
};

export default UserSyncState;
