import React from 'react';

import type { ReviewCycle } from 'models';
import type { UpdateReviewCycle } from 'scenes/admin/reviewCycles/Setup/Edit/index';

import { __ } from 'helpers/i18n';

import { FeatureFlagged } from 'components';

import StepContainer from 'scenes/components/Stepper/StepContainer';

import AddDirectReportsOption from './AddDirectReportsOption';
import DeadlinesOptions from './DeadlinesOptions';
import PeersOptions from './PeersOptions';
import TagsOption from './TagsOption';

type Props = {
  reviewCycle: ReviewCycle;
  updateReviewCycle: UpdateReviewCycle;
  goToNextStep: () => void;
};

type Option = 'deadlines' | 'objectivePeriods' | 'peers' | 'tags';

export const isOptionsStepCompleted = ({
  dateReviewRevieweeEnd,
  dateReviewReviewerEnd,
  revieweeDeadlineAfterDays,
  reviewerDeadlineAfterDays,
  autoAddParticipantsEnabled,
  defaultTemplate,
}: ReviewCycle): boolean => {
  const revieweeCompleted = !!(autoAddParticipantsEnabled
    ? revieweeDeadlineAfterDays
    : dateReviewRevieweeEnd);
  const reviewerCompleted = !!(autoAddParticipantsEnabled
    ? reviewerDeadlineAfterDays
    : dateReviewReviewerEnd);

  const revieweeDeadlineDisplayed =
    defaultTemplate?.hasSelfEvaluationEnabled !== false;

  return (revieweeCompleted || !revieweeDeadlineDisplayed) && reviewerCompleted;
};

const getOptions = (reviewCycle: ReviewCycle) => {
  const options: Option[] = ['deadlines'];

  if (
    reviewCycle.interactionType === '360' &&
    reviewCycle.computedNominatePeersStepEnabled
  )
    options.push('peers');

  options.push('tags');

  return options;
};

const Step4Dates = ({
  reviewCycle,
  updateReviewCycle,
  goToNextStep,
}: Props) => {
  const options = getOptions(reviewCycle);
  const canPassStep = isOptionsStepCompleted(reviewCycle);

  return (
    <StepContainer
      title={__('Options')}
      titlePreset="24bs2"
      onContinueClick={goToNextStep}
      canPassStep={canPassStep}
      stepName="options"
    >
      {reviewCycle.interactionType === '360' && (
        <FeatureFlagged flag="addDirectReportsOn360s">
          <AddDirectReportsOption
            reviewCycle={reviewCycle}
            updateReviewCycle={updateReviewCycle}
          />
        </FeatureFlagged>
      )}

      {options.includes('deadlines') && (
        <DeadlinesOptions
          reviewCycle={reviewCycle}
          updateReviewCycle={updateReviewCycle}
        />
      )}

      {options.includes('peers') && (
        <PeersOptions
          reviewCycle={reviewCycle}
          updateReviewCycle={updateReviewCycle}
        />
      )}

      {options.includes('tags') && (
        <TagsOption
          reviewCycle={reviewCycle}
          updateReviewCycle={updateReviewCycle}
        />
      )}
    </StepContainer>
  );
};

export default Step4Dates;
