import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { PieChartValue, UserFilterSegment } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import withLoadingOnViewportVisibility from 'helpers/withLoadingOnViewportVisibility';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Column, Columns, FetchContainer } from 'components';

import SimplePieChart from './SimplePieChart';

type Props = {
  reviewCycleId: string;
  blockId: string;
  userFilter: UserFilterSegment | undefined | null;
  onAnswerDetails: (
    filter: {
      [filter: string]: boolean;
    },
    label: string | null
  ) => void;
};

export type RoleStats = {
  id: string;
  type: string;
  values: Array<PieChartValue>;
};

type QuestionStats = {
  revieweeStats: RoleStats;
  reviewerStats: RoleStats;
};

type AfterDataloaderProps = Props &
  DataLoaderProvidedProps & {
    stats?: QuestionStats;
  };

const QuestionPieChart = ({
  stats,
  isFetching,
  hasError,
  onAnswerDetails,
}: AfterDataloaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      loadingStyle="overlay"
      render={() => (
        <div className="question-pie-chart">
          <Columns>
            {stats &&
              stats.revieweeStats &&
              stats.revieweeStats.values.length > 0 && (
                <Column
                  size={6}
                  offset={
                    !stats.reviewerStats ||
                    stats.reviewerStats.values.length === 0
                      ? 3
                      : undefined
                  }
                  additionalClassName="simple-pie-chart"
                >
                  <SimplePieChart
                    data={stats.revieweeStats}
                    title={__('Reviewee')}
                    onCellClick={label =>
                      onAnswerDetails({ reviewee: true }, label)
                    }
                  />
                </Column>
              )}
            {stats &&
              stats.reviewerStats &&
              stats.reviewerStats.values.length > 0 && (
                <Column
                  size={6}
                  offset={
                    !stats.revieweeStats ||
                    stats.revieweeStats.values.length === 0
                      ? 3
                      : undefined
                  }
                  additionalClassName="simple-pie-chart"
                >
                  <SimplePieChart
                    data={stats.reviewerStats}
                    title={__('Reviewer')}
                    onCellClick={label =>
                      onAnswerDetails({ reviewer: true }, label)
                    }
                  />
                </Column>
              )}
          </Columns>
        </div>
      )}
    />
  );
};

const MockRenderer = (originalProps: Props) => {
  return (
    <QuestionPieChart
      {...originalProps}
      isFetching
      hasError={false}
      noContent
      refetchData={() => Promise.resolve()}
    />
  );
};

export default compose<React.ComponentType<Props>>(
  withLoadingOnViewportVisibility<Props>({
    delay: 500,
    mockClass: MockRenderer,
  }),
  newDataLoader({
    fetch: ({ reviewCycleId, blockId, userFilter }) =>
      get(`review_cycles/${reviewCycleId}/review_blocks/${blockId}/stats`, {
        userFilter,
      }),
    hydrate: {
      stats: {
        revieweeStats: {},
        reviewerStats: {},
      },
    },
    cacheKey: ({ userFilter, blockId }: Props) =>
      compositeKey({
        userFilter,
        blockId,
        view: 'questionPieChart',
      }),
  })
)(QuestionPieChart);
