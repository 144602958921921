import React, { ReactNode, useEffect, useState } from 'react';

import type { AsyncAction, AsyncActionStatus } from 'models/AsyncAction';

import { useAppDispatch } from 'helpers/hooks';

import { get } from 'redux/actions/api';

import { Poller } from 'components';

const ONGOING_STATES: Array<AsyncActionStatus> = ['in_progress', 'queued'];

export const usePollingWithAsyncAction = (
  asyncAction: AsyncAction | null | undefined,
  callback?: () => Promise<any>
): {
  actionIsOngoing: boolean;
  pollerComponent: ReactNode;
} => {
  const dispatch = useAppDispatch();
  const isOngoing = status => ONGOING_STATES.includes(status);
  const [actionIsOngoing, setActionIsOngoing] = useState(false);

  useEffect(() => {
    setActionIsOngoing(isOngoing(asyncAction?.status));
  }, [asyncAction]);

  const fetchAction = async () => {
    const {
      response: {
        body: {
          data: {
            attributes: { status },
          },
        },
      },
      // @ts-ignore TSFIXME: Fix strictNullChecks error
    } = await dispatch(get(`async_actions/${asyncAction.id}`));

    if (isOngoing(status) && !actionIsOngoing) {
      setActionIsOngoing(true);
    } else if (actionIsOngoing && !isOngoing(status)) {
      setActionIsOngoing(false);
      !!callback && callback();
    }
  };

  const pollerComponent = actionIsOngoing && (
    <Poller poll={() => fetchAction()} everyMs={3000} />
  );

  return { actionIsOngoing, pollerComponent };
};
