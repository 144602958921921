import React, { ReactNode } from 'react';

import { __ } from 'helpers/i18n';

import Icon from 'components/Icon';
import { CircularProgress } from 'components/Loading';
import Text from 'components/text/Text';
import Connector from 'components/withSavingStatus/Connector';

type Props = {
  onRetryClick: () => Promise<void>;
  fieldUid: string;
};

type State = {
  displaySavedLabel: boolean;
};

export default class RetryLabel extends React.Component<Props, State> {
  state = {
    displaySavedLabel: false,
  };

  retry = async () => {
    await this.props.onRetryClick();
    this.setState({ displaySavedLabel: true });

    setTimeout(() => this.setState({ displaySavedLabel: false }), 3000);
  };

  render() {
    const { fieldUid } = this.props;
    const { displaySavedLabel } = this.state;

    let message: ReactNode = null;
    let link: ReactNode = null;

    return (
      <Connector
        fieldUid={fieldUid}
        render={({ autoSaveStatus: status }) => {
          if (displaySavedLabel) {
            message = (
              <Text color="success">
                <Icon name="check" />{' '}
                {__('Your comment was saved successfully.')}
              </Text>
            );
          } else if (status === 'retrying') {
            message = (
              <Text color="info">
                <CircularProgress
                  size="small"
                  style={{ marginRight: 4, verticalAlign: 'middle' }}
                />
                <span style={{ verticalAlign: 'middle' }}>
                  {__('Retrying...')}
                </span>
              </Text>
            );
          } else if (status === 'failed') {
            message = (
              <Text color="danger">
                {__('Something went wrong while saving your comment.')}
              </Text>
            );
            link = (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <span className="link text-[var(--primary)]" onClick={this.retry}>
                {__('Retry')}
              </span>
            );
          }

          if (message === null) return null;

          return (
            <span className="retry-label">
              <span className="retry-label-message">{message}</span>
              {link && <span className="retry-label-link">{link}</span>}
            </span>
          );
        }}
      />
    );
  }
}
