import { AdminResourceType, Entity } from 'models';

import { __, n__ } from 'helpers/i18n';

import deduceOrganizationAdminFromRoleResources from './deduceOrganizationAdminFromRoleResources';

const administeredEntitiesSummary = (resources: Array<AdminResourceType>) => {
  if (deduceOrganizationAdminFromRoleResources(resources)) {
    return __('All users');
  }

  const firstEntityName = (resources[0] as Entity).name;

  if (resources.length === 1) {
    return firstEntityName;
  }

  return n__(
    '%2 +%1 entity',
    '%2 +%1 entities',
    resources.length - 1,
    firstEntityName
  );
};

export default administeredEntitiesSummary;
