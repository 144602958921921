import React from 'react';

import { __ } from 'helpers/i18n';

import { Text } from 'components';

import FeaturePromptModal from 'scenes/components/FeaturePromptModal';

import pieChartSvg from './svg/piechartSvg';

type Props = {
  isActive: boolean;
  onClose: () => void;
};

const ResultViewFeaturePromptModal = ({ isActive, onClose }: Props) => {
  return (
    <FeaturePromptModal
      title={__('Enable analytics result view')}
      description={
        <div>
          <Text align="left">
            {__(
              'One click access to all the answers for this campaign. Elevo automatically generate graphics that summarize the results to provide you with the global picture'
            )}
          </Text>
          <ul style={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
            <li>
              <Text align="left">
                {__('Discover trends among the reviews')}
              </Text>
            </li>
            <li>
              <Text align="left">
                {__('Filter and segment results by user profiles')}
              </Text>
            </li>
            <li>
              <Text align="left">
                {__('Compare results between user segment')}
              </Text>
            </li>
          </ul>
        </div>
      }
      svg={pieChartSvg}
      prefilledSubjectToSupport={__('Learn more about analytics result view')}
      mainButtonText={__('Contact an Elevo expert to access this feature')}
      isActive={isActive}
      onClose={onClose}
    />
  );
};

export default ResultViewFeaturePromptModal;
