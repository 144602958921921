import * as React from 'react';

import { __ } from 'helpers/i18n';
import { pathToMyTeam } from 'helpers/paths';

import { Box, BoxFooter, DesignSystem, Text } from 'components';

export default function ReviewEmptyState() {
  return (
    <DesignSystem version={2}>
      <Box style={{ textAlign: 'center' }}>
        <Text color="light" style={{ fontSize: 16 }}>
          {__('There are no active review for your team')}
        </Text>

        <BoxFooter to={pathToMyTeam()}>{__('See my team')}</BoxFooter>
      </Box>
    </DesignSystem>
  );
}
