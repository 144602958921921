import React from 'react';

import { SubTitle, Title } from 'components';

type Props = {
  title: React.ReactNode;
  subtitle: string;
  children: React.ReactNode;
  additionalClassName?: string;
};

export default function OptionWrapper({
  title,
  subtitle,
  children,
  additionalClassName = '',
}: Props) {
  return (
    <div className={`option ${additionalClassName}`}>
      <Title size={5} additionalClassName="is-spaced">
        {title}
      </Title>
      <SubTitle size={6} style={{ marginBottom: 16 }}>
        {subtitle}
      </SubTitle>

      {children}
    </div>
  );
}
