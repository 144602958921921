import React from 'react';

import { __ } from 'helpers/i18n';

import { Box, Button, Flex, Text } from 'components';

import SavingsIcon from './svg/SavingsIcon';

type Props = {
  onClick: () => void;
};

const boxStyle = {
  textAlign: 'center' as const,
  padding: '28px 20px',
  minWidth: '230px',
};

const BudgetCreationCard = ({ onClick }: Props) => {
  return (
    <Box style={boxStyle}>
      <Flex>
        <SavingsIcon />
        <Text
          size={6}
          color="light"
          align="left"
          style={{ paddingLeft: 8, paddingRight: 8, flexGrow: 1 }}
        >
          {__('Define your budget and funding sources')}
        </Text>

        <Button
          testClassName="test-define-budget-button"
          color="secondary"
          onClick={onClick}
        >
          <span>{__('Define')}</span>
        </Button>
      </Flex>
    </Box>
  );
};

export default BudgetCreationCard;
