import { groupBy, sortBy, uniqBy } from 'lodash';
import React, { Fragment } from 'react';

import type { ThreeSixtyReviewCycle, ThreeSixtyUserReview } from 'models';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { getRevieweeEvaluation } from 'helpers/models/evaluation';
import { pathToCurrentUserEvaluation } from 'helpers/paths';

import { Button, Column, Columns } from 'components';

import { AvatarWithStatus } from 'scenes/components/AvatarWithStatus';
import { ThreeSixtyReviewCycleItem } from 'scenes/components/reviewCycle/ListItem';

import PeerStatuses from '../components/UserReviewList/ThreeSixtyUserReviewListItem/PeerStatuses';

type Props = {
  userReviews: Array<ThreeSixtyUserReview>;
};

const FeedbackRequestsList = ({ userReviews }: Props) => {
  const activeUser = useActiveUser();
  const reviewCycles = uniqBy(
    userReviews.map(({ reviewCycle }) => reviewCycle),
    'id'
  ) as Array<ThreeSixtyReviewCycle>;
  const sortedReviewCycles = sortBy(
    reviewCycles,
    (reviewCycle: ThreeSixtyReviewCycle) => reviewCycle.dateReviewRevieweeEnd
  );
  const userReviewsByReviewCycle = groupBy(userReviews, 'reviewCycleId');

  const getButtonTitle = (review: ThreeSixtyUserReview): string => {
    const currentUserEvaluation = review.evaluations.find(
      evaluation => evaluation.reviewerId === activeUser.id
    );

    invariant(currentUserEvaluation, 'currentUserEvaluation should be defined');
    const currentStatus = currentUserEvaluation.status;

    let btnTitle = __('Start assessment');
    if (currentStatus === 'in_progress') btnTitle = __('Continue assessment');

    return btnTitle;
  };

  return (
    <Fragment>
      {sortedReviewCycles.map(reviewCycle => {
        invariant(reviewCycle, 'reviewCycle must be defined');
        const userReviews = sortBy(
          userReviewsByReviewCycle[reviewCycle.id],
          userReview => userReview.user.fullName
        ) as Array<ThreeSixtyUserReview>;

        return (
          <ThreeSixtyReviewCycleItem
            key={reviewCycle.id}
            reviewCycle={reviewCycle}
            hasManagerAnalytics={false}
          >
            {userReviews.map(userReview => (
              <Columns contentVerticallyAligned key={userReview.id}>
                <Column size={4}>
                  <AvatarWithStatus
                    user={userReview.user}
                    status={getRevieweeEvaluation(userReview)?.status}
                    statusFallback={__('No self evaluation')}
                  />
                </Column>

                <Column size={4}>
                  <PeerStatuses
                    maxInlinePeers={2}
                    evaluations={userReview.evaluations}
                  />
                </Column>

                <Column contentIsPulledRight size={4}>
                  <Button
                    color="primary"
                    testClassName="test-start-assessment-button"
                    to={pathToCurrentUserEvaluation(userReview)}
                  >
                    {getButtonTitle(userReview)}
                  </Button>
                </Column>
              </Columns>
            ))}
          </ThreeSixtyReviewCycleItem>
        );
      })}
    </Fragment>
  );
};

export default FeedbackRequestsList;
