import React from 'react';

import type { BulmaColor, BulmaSize } from 'components/types/bulma';
import type { CheckboxSwitchStyle } from 'components/types/checkboxSwitch';

import { bulmaColorClassName, bulmaSizeClassName } from 'helpers/bulma';
import classNames from 'helpers/classNames';

export type Props = {
  value: boolean;
  id?: string;
  onChange: (value: boolean) => unknown;
  disabled?: boolean;

  size?: BulmaSize;
  color?: BulmaColor;
  switchStyle?: CheckboxSwitchStyle;
  isOutlined?: boolean;
};

function CheckboxSwitch({
  value,
  size,
  disabled,
  switchStyle,
  color,
  isOutlined,
  id,
  onChange,
}: Props) {
  const styleClassName = classNames({
    'is-rounded': switchStyle === 'rounded',
    'is-thin': switchStyle === 'thin',
    // 'square' style is the default for bulma
  });

  const className = classNames(
    'switch',
    bulmaColorClassName(color),
    bulmaSizeClassName(size),
    styleClassName,
    {
      'is-outlined': !!isOutlined,
    }
  );

  return (
    <input
      id={id}
      type="checkbox"
      name="switchRounded"
      disabled={disabled}
      className={className}
      checked={!!value}
      onChange={() => onChange(!value)}
    />
  );
}

CheckboxSwitch.defaultProps = {
  switchStyle: 'rounded',
  size: 'normal',
};

export default CheckboxSwitch;
