import React, { ReactElement } from 'react';

import type { PeerFeedback } from 'models';

import { __ } from 'helpers/i18n';
import { pathToTodo } from 'helpers/navigation';

import {
  BoxListItem,
  Column,
  Columns,
  Date,
  Link,
  Text,
  Tooltip,
} from 'components';

type Props = {
  sharedPeerFeedback: PeerFeedback;
  withLink: boolean;
};

export default function SharedPeerFeedbackListItem({
  sharedPeerFeedback,
  withLink,
}: Props) {
  let titleNode: ReactElement | null = null;

  if (withLink) {
    titleNode = (
      <Link style={{ margin: '0 15px' }} to={pathToTodo(sharedPeerFeedback)}>
        <Text>
          {__('Feedback given to %1', sharedPeerFeedback.reviewee.fullName)}
        </Text>
      </Link>
    );
  } else {
    titleNode = (
      <Tooltip
        content={__('The feedback content is only accessible to its author')}
      >
        <Text>
          {__('Feedback given to %1', sharedPeerFeedback.reviewee.fullName)}
        </Text>
      </Tooltip>
    );
  }

  return (
    <BoxListItem isSmall>
      <Columns>
        <Column size={6} isVerticallyCentered>
          {titleNode}
        </Column>
        <Column size={3} isVerticallyCentered>
          <Text>{sharedPeerFeedback.reviewCycleName}</Text>
        </Column>
        <Column size={3} isVerticallyCentered contentIsPulledRight>
          <Text>
            <Date value={sharedPeerFeedback.submittedAt} format="LL" />
          </Text>
        </Column>
      </Columns>
    </BoxListItem>
  );
}
