import type { ReviewCycle, ReviewTemplate } from 'models';

import { __ } from '../i18n';

export const defaultNominatePeersInstruction = () =>
  __(
    'Any employee can be selected as a peer. We recommend:\n  • select between 4 to 6 peers\n  • not select a peer who has been solicited at 5 times or more'
  );

export function anyTemplateWithSkillsInCycle(reviewCycle: ReviewCycle) {
  const templates = reviewCycle.templates as Array<ReviewTemplate>;
  return !!templates.find(template => template.skillsCareerLevelEnabled);
}
