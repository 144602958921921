import React from 'react';

import invariant from '../../helpers/invariant';

type Props = {
  children: React.ReactNode;
  name?: string;
  uid?: string;
  style?: React.CSSProperties;
};

// Testable **should not be used** anymore as it creates an undesirable span element.
// Use `testClassName` on your components to make it testable.
export default function Testable({ name, children, uid, style }: Props) {
  invariant(
    !name || name.indexOf('test-') === 0,
    'Testable className must start with `test-`'
  );

  return (
    <span data-testable-uid={uid} className={name} style={style}>
      {children}
    </span>
  );
}
