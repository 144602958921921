import React, { Fragment } from 'react';

import type { OneOnOneReviewTemplate } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import {
  CheckboxSwitch,
  Level,
  LevelLeft,
  LevelRight,
  Testable,
  Text,
  Title5,
  WithSavingStatusRecorder,
} from 'components';

export type ObjectiveParams = {
  review: boolean;
  definition: boolean;
};

type Props = {
  objectivesReviewEnabled: boolean;
  objectivesDefinitionEnabled: boolean;
  onChange: (attributes: Partial<OneOnOneReviewTemplate>) => Promise<void>;
  disabled: boolean;
  templateId: string;
};

const ObjectivesSettings = ({
  objectivesReviewEnabled,
  objectivesDefinitionEnabled,
  disabled,
  templateId,
  onChange,
}: Props) => {
  return (
    <Fragment>
      <Title5>{__('Objectives settings')}</Title5>
      <Level style={{ paddingTop: 8 }}>
        <LevelLeft>
          <Text>{__('Current objectives')}</Text>
        </LevelLeft>
        <LevelRight>
          <WithSavingStatusRecorder
            fieldUid={compositeKey({
              templateId: templateId,
              field: 'objectives_review',
            })}
            onChange={(newObjectivesReviewEnabled: boolean) =>
              onChange({
                objectivesReviewEnabled: newObjectivesReviewEnabled,
              })
            }
            render={autoSavingOnchange => (
              <Testable name="test-template-objectives-review-setting">
                <CheckboxSwitch
                  disabled={disabled}
                  value={objectivesReviewEnabled}
                  onChange={value => autoSavingOnchange(value)}
                  size="small"
                />
              </Testable>
            )}
          />
        </LevelRight>
      </Level>
      <div>
        <Text color="grey">
          {__('Allows for the evaluation of ongoing objectives')}
        </Text>
      </div>
      <Level style={{ marginTop: 8 }}>
        <LevelLeft>
          <Text>{__('Upcoming objectives')}</Text>
        </LevelLeft>
        <LevelRight>
          <WithSavingStatusRecorder
            fieldUid={compositeKey({
              templateId: templateId,
              field: 'objectives_definition',
            })}
            onChange={(newObjectivesDefinitionEnabled: boolean) =>
              onChange({
                objectivesDefinitionEnabled: newObjectivesDefinitionEnabled,
              })
            }
            render={autoSavingOnchange => (
              <Testable name="test-template-objectives-definition-setting">
                <CheckboxSwitch
                  disabled={disabled}
                  value={objectivesDefinitionEnabled}
                  onChange={value => autoSavingOnchange(value)}
                  size="small"
                />
              </Testable>
            )}
          />
        </LevelRight>
      </Level>
      <div>
        <Text color="grey">
          {__(
            'Allows for the evaluation of upcoming objectives. Note : at the end of the review, current objectives will be archived to make room for the new ones that have been defined'
          )}
        </Text>
      </div>
    </Fragment>
  );
};

export default ObjectivesSettings;
