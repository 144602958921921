import { sortBy } from 'lodash';
import React from 'react';

import type { BlockRatingOption, RatingOptionRole } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { del, post, put } from 'redux/actions/api';

import { TextList } from 'components';

import RatingOptionsPlaceholder from './RatingOptionsPlaceholder';

type Props = {
  ratingOptionRole: RatingOptionRole;
  reviewBlockId: string;
  ratingOptions: Array<BlockRatingOption>;
  isDisabled?: boolean;
  canDeleteRatingOption: boolean;
  canUpdateWithEmptyLabel: boolean;
};

const RatingOptions = ({
  ratingOptionRole,
  reviewBlockId,
  ratingOptions,
  isDisabled,
  canDeleteRatingOption,
  canUpdateWithEmptyLabel,
}: Props) => {
  const dispatch = useAppDispatch();
  const createRatingOption = (label: string) =>
    dispatch(
      post('rating_options', { label, role: ratingOptionRole, reviewBlockId })
    );
  const updateRatingOption = (
    ratingOptionId: string,
    options: Partial<BlockRatingOption>
  ) => dispatch(put(`rating_options/${ratingOptionId}`, options));
  const deleteRatingOption = (ratingOptionId: string) =>
    dispatch(del(`rating_options/${ratingOptionId}`));

  if (isDisabled) {
    let title = '';

    if (ratingOptionRole === 'reviewee')
      title = __('The question is not enabled for the reviewee');
    if (ratingOptionRole === 'reviewer')
      title = __('The question is not enabled for the reviewer');

    return <RatingOptionsPlaceholder title={title} />;
  }

  const handleUpdateRatingOption = async (ratingOptionId, options) => {
    if (canUpdateWithEmptyLabel) {
      updateRatingOption(ratingOptionId, options);
    }

    const { label } = options;

    if ((!canUpdateWithEmptyLabel && !!label) || canDeleteRatingOption) {
      updateRatingOption(ratingOptionId, options);
    }
  };

  return (
    <div className={`test-${ratingOptionRole}-rating-option-list`}>
      <TextList
        items={sortBy(ratingOptions, 'position')}
        additionalClassName="rating-options"
        itemTestClassName="test-rating-option"
        onCreate={createRatingOption}
        onUpdate={handleUpdateRatingOption}
        onDelete={
          canDeleteRatingOption
            ? ratingOptionId => deleteRatingOption(ratingOptionId)
            : undefined
        }
        creationPlaceholder={__('Add a rating option…')}
        emptyLabelPlaceholder={__('Option')}
        ordered
      />
    </div>
  );
};

export default RatingOptions;
