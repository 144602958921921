// @flow
import type { JsonApiStrippedResponse } from '../JsonApiResponse';
import type { DataState } from 'redux/reducers/DataStateType';

import hydrate from './hydrate';

export default function hydrateFromResponse(
  // $FlowFixMe: Migrate to TS
  dataState: DataState,
  response: ?JsonApiStrippedResponse,
  relationshipsTree: {},
  memoizeKey: string
) {
  const data = ((response ? response.data : null): any);
  const relationshipsSubtree = ((Object.values(
    relationshipsTree
  ): any): Array<{}>)[0];
  return hydrate(dataState, data, relationshipsSubtree, memoizeKey);
}
