import { DraftSurveyCampaign, SurveyCampaign } from 'models/surveys';

import { __, date } from 'helpers/i18n';

const pollingPeriodInfoByCampaignStatus = (
  campaign: SurveyCampaign | DraftSurveyCampaign
) => {
  const dateFormat = 'LL';
  if (campaign.status === 'ended' || campaign.status === 'archived') {
    if (!!campaign.lastPeriodEndedAt)
      return __('Ended on %1', date(campaign.lastPeriodEndedAt, dateFormat));
  } else if (campaign.status === 'inactive') {
    if (!!campaign.lastPeriodEndedAt)
      return __(
        'Last period closed on %1',
        date(campaign.lastPeriodEndedAt, dateFormat)
      );
  } else if (campaign.status === 'scheduled') {
    return __('Next launch on %1', date(campaign.nextPeriodAt, dateFormat));
  } else if (campaign.status === 'in_progress') {
    return __('Until %1', date(campaign.lastPeriodEndDate, dateFormat));
  }
};

export default pollingPeriodInfoByCampaignStatus;
