import * as React from 'react';

import classNames from 'helpers/classNames';

import { Text } from 'components/text';

type Props = {
  title: string;
  children: React.ReactNode;
  className?: string;
};

export default function NavSection({ title, className, children }: Props) {
  if (
    !children ||
    React.Children.toArray(children).filter(Boolean).length === 0
  )
    return null;

  return (
    <div className={classNames('!mb-2', className)}>
      <Text
        additionalClassName="text-nav-text uppercase mb-2 mt-2 !block pl-2"
        weight="semibold"
        preset="11s8"
      >
        {title}
      </Text>
      {children}
    </div>
  );
}
