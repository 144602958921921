import React, { useState } from 'react';

import type { ModalProps } from '../';
import type { SkillsDomain } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { htmlSuccessNotice } from 'redux/actions';
import { post } from 'redux/actions/api';

import {
  Button,
  Field,
  Label,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import DomainPicker from 'scenes/skills/components/DomainPicker';

const DomainImportModal = ({
  matrix,
  modalParams,
  setModalParams,
}: ModalProps) => {
  const [domain, setDomain] = useState<SkillsDomain | null>(null);

  const dispatch = useAppDispatch();

  const onSave = async () => {
    await dispatch(
      post(`skills/matrices/${matrix.id}/add_domain`, {
        position: modalParams?.position,
        domainId: domain?.id,
      })
    );

    dispatch(
      htmlSuccessNotice(
        __(
          '<b>The skills domain %1 has been successfully added to the matrix.</b><br/>All skills associated with this domain have been included in the matrix.',
          domain?.title
        )
      )
    );
    setModalParams(null);
  };

  return (
    <ModalCard
      isActive
      onClose={() => setModalParams(null)}
      isLarge
      refreshContentOnOpening
    >
      <ModalCardHead>
        <ModalCardTitle>{__('Add a skills domain')}</ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody>
        <Field>
          <Label>{__('Search for a skills domain')}</Label>

          <DomainPicker
            availableForMatrixId={matrix.id}
            value={domain}
            onChange={setDomain}
          />
        </Field>
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={() => setModalParams(null)}>
          {__('Cancel')}
        </Button>
        <Button color="primary" disabled={!domain} onClick={onSave}>
          {__('Add the domain')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default DomainImportModal;
