interface Data {
  value: number;
}

type Color = string;
type ColorStyle = {
  backgroundColor: string;
  color?: string;
};
type MatrixData<T extends Data> = T[][];
type ColorThreshold = number;

export const DEFAULT_COLORS: Color[] = [
  '#EEF2FB',
  '#C5CEF7',
  '#7B89E3',
  '#3043CF',
  '#1E2357',
];

export const getColorThresholds = <T extends Data>(
  matrixData: MatrixData<T>,
  colors: Color[] = DEFAULT_COLORS
): ColorThreshold[] => {
  const maxValue = Math.max(
    ...matrixData.flat().map(cellData => cellData.value)
  );
  const valueIntervalSize = Math.floor(maxValue / (colors.length - 1));

  return colors.map((_color, index) =>
    index === colors.length - 1 ? maxValue : index * valueIntervalSize
  );
};

export const colorStyleFromCellData = <T extends Data>(
  cellData: T,
  colorThresholds: ColorThreshold[],
  colors: Color[] = DEFAULT_COLORS
): ColorStyle => {
  const colorIndex = colorThresholds.findIndex(
    threshold => cellData.value <= threshold
  );
  const textColorProps = colorIndex > 1 ? { color: 'white' } : {};

  return {
    backgroundColor: colors[colorIndex],
    ...textColorProps,
  };
};
