import { __ } from './';

export function humanFileSize(bytes: number, decimals = 0) {
  if (Math.abs(bytes) < 1000) {
    return bytes + ' B';
  }

  const units = [__('KB'), __('MB'), __('GB'), __('TB'), __('PB')];
  let u = -1;
  const r = 10 ** decimals;

  do {
    bytes /= 1000;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= 1000 && u < units.length - 1);

  return bytes.toFixed(decimals) + ' ' + units[u];
}
