import React, { Fragment, useState } from 'react';

import type { ApprovalStatus } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { post } from 'redux/actions/api';

import {
  Button,
  Checkbox,
  Icon,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Select,
  StrictlySanitizedHtml,
  Text,
  Textarea,
} from 'components';

import iconForApprovalStatus from '../helpers/iconForApprovalStatus';
import requestApprovalOptions from '../helpers/requestApprovalOptions';

type Props = {
  periodSlug: string;
  requestIds: Array<string>;
  onClose: () => void;
  onAfterUpdate: () => Promise<void>;
};

const BulkUpdateModal = ({
  periodSlug,
  requestIds,
  onClose,
  onAfterUpdate,
}: Props) => {
  const dispatch = useAppDispatch();
  const bulkUpdateRequests = async () => {
    await dispatch(
      post(`training/periods/${periodSlug}/requests/bulk_update`, {
        requestIds,
        ...parameters,
      })
    );
    onClose();
    return onAfterUpdate();
  };
  const [parameters, setParameters] = useState<{
    approvalStatus: ApprovalStatus;
    refusalReason: string | null;
    withEmail: boolean;
  }>({
    approvalStatus: 'approved',
    refusalReason: null,
    withEmail: false,
  });

  const explanation = (() => {
    if (parameters.approvalStatus === 'approved')
      return n__(
        'This action <b>will approve the selected training request.</b>',
        'This action <b>will approve the %1 selected training requests.</b>',
        requestIds.length
      );
    if (parameters.approvalStatus === 'refused')
      return n__(
        'This action <b>will refuse the selected training request.</b>',
        'This action <b>will refuse the %1 selected training requests.</b>',
        requestIds.length
      );
    return n__(
      'This action <b>will put the selected training request on waiting for approval.</b>',
      'This action <b>will put the %1 selected training requests on waiting for approval.</b>',
      requestIds.length
    );
  })();

  const showNotificationCheckbox =
    parameters.approvalStatus !== 'waiting_for_approval';
  const notificationCheckboxExplanation = (() => {
    if (parameters.approvalStatus === 'waiting_for_approval') return null;

    return (
      <Text preset="14s6">
        <StrictlySanitizedHtml
          html={n__(
            'The employee and direct manager <b>will be informed by email</b>.',
            'Employees and direct managers <b>will be informed by email</b>.',
            requestIds.length
          )}
        />
        {parameters.approvalStatus === 'refused' && (
          <p className="mt-1">
            {__(
              'If you have left a comment below, it will be visible in the email'
            )}
          </p>
        )}
      </Text>
    );
  })();

  const buttonText = (() => {
    if (parameters.approvalStatus === 'approved')
      return n__(
        'Approve the request',
        'Approve the %1 requests',
        requestIds.length
      );
    if (parameters.approvalStatus === 'refused')
      return n__(
        'Refuse the request',
        'Refuse the %1 requests',
        requestIds.length
      );
    return __('Change to waiting for approval');
  })();

  const approvalOptions = requestApprovalOptions();

  return (
    <ModalCard isActive isLarge refreshContentOnOpening onClose={onClose}>
      <ModalCardHead>
        <ModalCardTitle>
          {n__(
            'Change the status of %1 training request',
            'Change the status of %1 training requests',
            requestIds.length
          )}
        </ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <Text preset="14bs6" additionalClassName="block mb-2 mt-4">
          {__('Select the new status')}
        </Text>
        <Select
          value={
            approvalOptions.find(
              option => option.value === parameters.approvalStatus
            ) || null
          }
          options={approvalOptions}
          onChange={option => {
            invariant(
              option && !Array.isArray(option),
              'Option must exist and cannot be an array'
            );
            setParameters(prevParams => ({
              ...prevParams,
              approvalStatus: option.value,
              withEmail:
                option.value === 'waiting_for_approval'
                  ? false
                  : prevParams.withEmail,
              refusalReason: option.value === 'refused' ? '' : null,
            }));
          }}
          isMulti={false}
          isClearable={false}
          inModal
        />

        <Text additionalClassName="block mt-4 mb-1">
          <StrictlySanitizedHtml html={explanation} />
        </Text>
        {showNotificationCheckbox && (
          <Checkbox
            size="small"
            isChecked={parameters.withEmail}
            onChange={isChecked =>
              setParameters(prevParams => ({
                ...prevParams,
                withEmail: isChecked,
              }))
            }
            label={notificationCheckboxExplanation}
          />
        )}
        {parameters.approvalStatus === 'refused' && (
          <Fragment>
            <Text preset="14bs6" additionalClassName="block mt-4 mb-2">
              {n__(
                'Comment on refusal (optional)',
                'Comment on refusal (optional)',
                requestIds.length
              )}
            </Text>
            <Textarea
              onChange={refusalReason =>
                setParameters(prevParams => ({ ...prevParams, refusalReason }))
              }
              value={parameters.refusalReason}
              placeholder={__('Add a refusal explanation')}
              maxLength={1500}
              rows={3}
              maxRows={7}
            />
          </Fragment>
        )}
      </ModalCardBody>
      <ModalCardFooter>
        <Button onClick={onClose} color="secondary">
          {__('Cancel')}
        </Button>
        <Button onClick={bulkUpdateRequests} color="primary">
          <Icon
            additionalClassName="mr-2"
            name={iconForApprovalStatus(parameters.approvalStatus)}
          />
          {buttonText}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default BulkUpdateModal;
