import React from 'react';

import { __ } from 'helpers/i18n';

import DatePicker, { type SharedWithTimePickerProps } from './DatePicker';

type Props = {
  timeIntervals: number;
} & SharedWithTimePickerProps;

function TimePicker({ placeholder, name, ...otherProps }: Props) {
  return (
    <DatePicker
      {...otherProps}
      showTimeSelect
      showTimeSelectOnly
      placeholder={placeholder || __('10:00')}
      timeCaption={__('Time')}
      name={name || 'elevo_time'}
    />
  );
}

TimePicker.defaultProps = {
  timeIntervals: 15,
};

export default TimePicker;
