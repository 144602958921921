import React, { useState } from 'react';

import type { IOption } from 'components';
import type { Action, CurrentValue } from 'components/formElements';
import type { SkillsAreaMapping, SkillsExpectation } from 'models';
import type { AppDispatch } from 'redux/actions';

import { somethingWentWrong } from 'helpers/commonPhrases';
import compositeKey from 'helpers/compositeKey';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import assetDefined from 'helpers/invariant';

import { hydrateFromResponse } from 'lib/dataLoader';
import { get, put } from 'redux/actions/api';
import { errorNotice } from 'redux/actions/application';
import { ReduxStore } from 'redux/reducers';

import { Flex, Text } from 'components';
import { Select } from 'components/formElements';
import { WithSavingStatusRecorder } from 'components/withSavingStatus';

type Props = {
  expectation: SkillsExpectation | null;
  areaMapping: SkillsAreaMapping;
  levelId: string;
};
const SkillExpectationPicker = ({
  expectation,
  areaMapping,
  levelId,
}: Props) => {
  const [selectableOptions, setSelectableOptions] = useState<IOption<string>[]>(
    []
  );
  const [areOptionsLoaded, setAreOptionsLoaded] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const deleteExpectationMapping = async (
    levelId: string,
    expectationId: string
  ) => {
    await dispatch(
      put(
        `/skills/levels/${levelId}/delete_expectation_mapping`,
        {
          expectationId,
        },
        {
          successMessage: __('The expectation has been deleted from the level'),
        }
      )
    );
  };

  const levelExpectationMappingSuccessMessage = __(
    'The levels for the <b>%1</b> skill have been successfully updated.',
    areaMapping.title
  );

  const updateExpectationMapping = async (
    levelId: string,
    areaMappingId: string,
    expectationId: string | null
  ) => {
    try {
      await dispatch(
        put(
          `/skills/levels/${levelId}/update_expectation_mapping`,
          {
            matrixAreaMappingId: areaMappingId,
            expectationId,
          },
          {
            successMessage: levelExpectationMappingSuccessMessage,
            withDefaultErrorHandling: false,
          }
        )
      );
    } catch (e: any) {
      const errorMessage =
        e.status === 422 && e.response?.body?.errors?.level
          ? e.response.body.errors.level[0]
          : somethingWentWrong();
      dispatch(errorNotice(errorMessage));
    }
  };

  const expectationToOption = (
    expectation: SkillsExpectation
  ): IOption<string> => ({
    label: expectation.title,
    value: expectation.id,
  });

  const fetchSkillAreaExpectations = async (): Promise<SkillsExpectation[]> =>
    dispatch(async (dispatch: AppDispatch, getState: () => ReduxStore) => {
      const { response } = await dispatch(
        get(`skills/areas/${areaMapping.areaId}`)
      );

      const { expectations } = hydrateFromResponse(
        getState().data,
        response.body,
        {
          area: { expectations: {} },
        },
        response.body.data.id
      );

      return expectations;
    });

  const selectOnFocus = async () => {
    if (!areOptionsLoaded) {
      const expectations = await fetchSkillAreaExpectations();
      setSelectableOptions(
        expectations.map(expectation => expectationToOption(expectation))
      );
      setAreOptionsLoaded(true);
    }
  };

  const handleChanges = (
    value: CurrentValue<IOption<string>, false>,
    action: Action
  ): Promise<void> => {
    if (action.action === 'clear') {
      assetDefined(
        expectation,
        'expectation must be defined in a clear action'
      );

      return deleteExpectationMapping(levelId, expectation.id);
    } else {
      assetDefined(value, 'value must be defined in a select-option action');

      return updateExpectationMapping(levelId, areaMapping.id, value.value);
    }
  };

  return (
    <WithSavingStatusRecorder
      fieldUid={compositeKey({
        level: levelId,
        type: 'expectation_mappings',
      })}
      onChange={handleChanges}
      render={autoSavingOnchange => (
        <Flex additionalClassName="" direction="column" verticalAlign>
          <Text additionalClassName="py-2" preset="14bs6">
            {__('Expected level')}
          </Text>
          <Select
            options={selectableOptions}
            placeholder={__('No level specified')}
            value={!!expectation ? expectationToOption(expectation) : null}
            onChange={autoSavingOnchange}
            onFocus={selectOnFocus}
            isClearable
            additionalClassName="mb-4"
          />
        </Flex>
      )}
    />
  );
};

export default SkillExpectationPicker;
