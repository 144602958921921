import type { Customization } from './types';

import buttonsCustomization from './buttons';
import checkboxesCustomization from './checkboxes';
import datepickerCustomization from './datepicker';
import dropdownCustomization from './dropdown';
import fieldsCustomization from './fields';

export default function customization({ primaryColor }: Customization) {
  return {
    '.tabs li.is-active a': {
      fontWeight: 600,
      color: primaryColor,
      borderBottomColor: primaryColor,
    },
    a: {
      color: primaryColor,
    },
    '.reveal_link': {
      color: 'inherit',
    },
    '.reveal_link:hover': {
      textDecoration: 'underline',
      color: `${primaryColor} !important`,
    },
    '.filter-bar .filter:not(.is-active)': {
      color: primaryColor,
    },
    '.filter:hover:not(.is-active)': {
      color: primaryColor,
    },
    'input[type=range].slider.is-primary::-webkit-slider-thumb': {
      borderColor: primaryColor,
      backgroundColor: primaryColor,
    },
    'input[type=range].slider.is-primary::-webkit-slider-runnable-track': {
      '--track-background': `linear-gradient(to right, transparent var(--low), ${primaryColor} calc(0%), ${primaryColor} var(--high), transparent calc(0%)) no-repeat 0 100%`,
      background: 'var(--track-background)',
    },
    'input[type=range].slider.is-primary::-moz-range-thumb': {
      borderColor: primaryColor,
    },
    'input[type=range].slider.is-primary::-moz-range-progress': {
      backgroundColor: primaryColor,
    },
    'input[type=range].slider.is-primary::-ms-thumb': {
      borderColor: primaryColor,
    },
    'input[type=range].slider.is-primary::-ms-fill-lower': {
      backgroundColor: primaryColor,
    },
    '.has-text-primary': {
      color: `${primaryColor} !important`,
    },
    'hr.is-primary': {
      backgroundColor: primaryColor,
    },
    ':focus': {
      outlineColor: primaryColor,
    },
    ...buttonsCustomization({ primaryColor }),
    ...checkboxesCustomization({ primaryColor }),
    ...fieldsCustomization({ primaryColor }),
    ...datepickerCustomization({ primaryColor }),
    ...dropdownCustomization({ primaryColor }),
  };
}
