import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import type { LoginAccessCodePair } from 'redux/actions/resources';

import { largeMargin } from 'styles/uiConstants';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { navigate } from 'helpers/navigation';
import { pathToSignin } from 'helpers/paths';

import { post } from 'redux/actions/api';
import { errorNotice } from 'redux/actions/application';

import {
  Button,
  Centered,
  Control,
  Field,
  FieldError,
  Form,
  Input,
  PageTitle,
  Splash,
  Text,
  Title3,
} from 'components';

function ValidateAccessCode() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const loginFromQueryParams = searchParams.get('login') || '';

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [credentials, setCredentials] = useState({
    login: loginFromQueryParams,
    accessCode: '',
  });
  const [errors, setErrors] = useState({ login: '', accessCode: '' });

  const isCustomDomain = window.location.host.split('.')[0] !== 'app';

  const handleCredentialsChange = (name: string, value: string | null) => {
    setCredentials({ ...credentials, [name]: value || '' });
    setErrors({ ...errors, [name]: null });
  };

  const handleError = error => {
    setIsSubmitting(false);

    if (error.response && error.response.statusCode === 401) {
      const message = error.response.body && error.response.body.error;
      setErrors({
        accessCode: message,
        login: ' ',
      });
    } else if (!error.isHandled) {
      window.logException(error);
      errorNotice(__('Unknown error: %1', error));
    }
  };

  const submitCredentials = async () => {
    setIsSubmitting(true);
    try {
      await validateAccessCode(credentials);
    } catch (error) {
      handleError(error);
    }
  };

  const validateAccessCode = async (credentials: LoginAccessCodePair) => {
    const result = await dispatch(
      post(
        'users/access_codes/validate',
        { user: credentials },
        { withDefaultErrorHandling: false }
      )
    );

    navigate(result.response.body.data.attributes.redirect);
  };

  if (!isCustomDomain) {
    window.location.replace('/signin');
    return null;
  } else {
    return (
      <Splash>
        <PageTitle title={__('Enter your access code')} />

        <Centered>
          <Title3>{__('Enter your access code')}</Title3>
          <Text
            align="centered"
            style={{ marginTop: '16px', marginBottom: '16px' }}
          >
            {__(
              'In order to activate your account or to reset your password, please enter below your username and the access code that was provided by your admin.'
            )}
          </Text>
          <Form style={{ width: '100%' }}>
            <Field>
              <Control leftIcon="person">
                <Input
                  name="elevo-login"
                  type="text"
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  value={credentials.login}
                  onChange={value => handleCredentialsChange('login', value)}
                  placeholder={__('Login')}
                />
              </Control>
            </Field>

            <Field>
              <Control leftIcon="lock">
                <Input
                  name="accessCode"
                  type="text"
                  value={credentials.accessCode}
                  onChange={value =>
                    handleCredentialsChange('accessCode', value)
                  }
                  placeholder={__('Access code')}
                />

                {!!errors.accessCode && (
                  <FieldError>{errors.accessCode}</FieldError>
                )}
              </Control>
            </Field>

            <Button
              color="primary"
              isLoading={isSubmitting}
              onClick={() => submitCredentials()}
              disabled={
                credentials.login === '' || credentials.accessCode === ''
              }
              isExpanded
            >
              {__('Continue')}
            </Button>
          </Form>
          <div style={{ marginTop: largeMargin }}>
            <p className="has-text-centered">
              <Link to={pathToSignin(credentials.login)}>
                {__('Back to sign-in')}
              </Link>
            </p>
          </div>
        </Centered>
      </Splash>
    );
  }
}

export default ValidateAccessCode;
