import React from 'react';

import { DraftSurveyCampaign, SurveyCampaign } from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { post } from 'redux/actions/api';

import { MenuItem, Text } from 'components';

type Props = {
  campaign: SurveyCampaign | DraftSurveyCampaign;
  redirectTo?: string;
  onAfterArchive?: () => void;
};

const ArchiveMenuItem = ({ campaign, redirectTo, onAfterArchive }: Props) => {
  const dispatch = useAppDispatch();

  const canArchive = can({
    perform: 'archive',
    on: campaign,
  });

  const archiveCampaign = async () => {
    await confirmAsync(
      __('Archive campaign?'),
      __(
        'Your survey campaign will be archived. If the survey is in progress, the period will be closed.'
      ),
      {
        confirmLabel: __('Archive campaign'),
        onConfirm: async () => {
          await dispatch(
            post(`survey/campaigns/${campaign.id}/archive`, undefined, {
              successMessage: __('Campaign archived! 📦'),
              redirectTo,
            })
          );
          if (onAfterArchive) onAfterArchive();
        },
      }
    );
  };

  return (
    <MenuItem key="archive" disabled={!canArchive} onClick={archiveCampaign}>
      <Text>{__('Archive campaign')}</Text>
    </MenuItem>
  );
};

export default ArchiveMenuItem;
