import type { AppDispatch } from '../types';
import type { ReviewCycle, UserReview } from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { viewerRole } from 'helpers/models/userReview';
import { trackAction } from 'helpers/tracking';

import { getActiveUser } from '../../reducers';
import { post } from '../api';

export type BulkReminderType =
  | 'auto_evaluation_not_started'
  | 'auto_evaluation_not_shared'
  | 'manager_evaluation_not_started'
  | 'manager_evaluation_not_shared'
  | 'signature_missing'
  | 'three_sixty_peer_validation_due'
  | 'three_sixty_peer_nomination_due'
  | 'three_sixty_peer_feedback_due'
  | 'three_sixty_self_assessment_due'
  | 'three_sixty_manager_releasing_due'
  | 'signature_missing';

export const removeParticipant =
  (reviewCycle: ReviewCycle, userReview: UserReview) =>
  async (dispatch: AppDispatch, getState: () => any): Promise<boolean> => {
    const activeUser = getActiveUser(getState());
    invariant(activeUser, 'activeUser is needed');

    trackAction('Participant removed from cycle', {
      activeUserRole: viewerRole(activeUser, userReview),
    });

    const successMessage =
      reviewCycle.interactionType === 'one_on_one'
        ? __(
            '<b>%1 has successfully been removed from campaign %2</b> 👍<br/>%1 and their reviewer have received an email letting them know.',

            userReview.user.fullName,
            reviewCycle.name || ''
          )
        : __(
            '<b>%1 has successfully been removed from campaign %2</b> 👍<br/>%1 and their reviewers have received an email letting them know.',

            userReview.user.fullName,
            reviewCycle.name || ''
          );

    try {
      await dispatch(
        post(
          `review_cycles/${userReview.reviewCycleId}/remove_participant`,
          {
            reviewCycle: {
              participantId: userReview.userId,
            },
          },
          {
            successMessage,
            errorMessage: __(
              'Oops, Elevo encountered a problem when removing participants 🙁.'
            ),
          }
        )
      );
      return true;
    } catch (error) {
      return false;
    }
  };
