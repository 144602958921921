import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { __, n__ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import {
  Card,
  Icon,
  MaterialIconName,
  Text,
  TooltipOnEllipsis,
} from 'components';

type TrainingAttributeProps<T> = {
  icon: MaterialIconName;
  data: NonNullable<T> | null;
  formatter: (data: NonNullable<T>) => ReactNode;
};

const TrainingAttribute = <T,>({
  icon,
  data,
  formatter,
}: TrainingAttributeProps<T>) => {
  return (
    <div className="flex items-top gap-2">
      <Icon name={icon} additionalClassName="text-text-light" />
      <Text preset="14s6">{data !== null ? formatter(data) : '-'}</Text>
    </div>
  );
};

type Props = {
  name: string;
  costPerParticipantCents: number | null;
  costPerParticipantCurrency: string | null;
  durationInHours: string | number | null;
  organism: {
    name: string;
    logoUrl: string;
  };
  url: string;
  additionalClassName?: string;
};

const TraningCard = ({
  name,
  costPerParticipantCents,
  costPerParticipantCurrency,
  durationInHours,
  organism,
  url,
  additionalClassName,
}: Props) => {
  const duration =
    typeof durationInHours === 'string'
      ? parseFloat(durationInHours)
      : durationInHours;

  return (
    <Card
      url={url}
      additionalClassName={classNames(
        'flex flex-col gap-2',
        additionalClassName
      )}
    >
      <div className="flex gap-2 flex-grow">
        <div className="flex flex-col flex-grow">
          <Text preset="14bs6" additionalClassName="training-card__name">
            <TooltipOnEllipsis lineClamp={2}>{name}</TooltipOnEllipsis>
          </Text>
          <Text preset="13s7" additionalClassName="mt-1 text-text-soften">
            <TooltipOnEllipsis lineClamp={1}>{organism.name}</TooltipOnEllipsis>
          </Text>
        </div>
        <div className="shrink-0">
          <img
            src={organism.logoUrl}
            alt={organism.name}
            className="rounded-full w-8 h-8"
          />
        </div>
      </div>
      <TrainingAttribute
        icon="savings"
        data={costPerParticipantCents}
        formatter={cost =>
          __(
            '%1 / participant (excl. tax)',
            formatMoney(cost, costPerParticipantCurrency || '', {
              currencyDisplay: 'symbol',
              hideEmptyDecimal: true,
            })
          )
        }
      />
      <TrainingAttribute
        icon="timer"
        data={duration}
        formatter={duration => n__('%1 hour', '%1 hours', duration)}
      />
    </Card>
  );
};

export default TraningCard;
