import React from 'react';

import ErrorBoundary from 'components/errors/ErrorBoundary';

type Props = {
  children: React.ReactNode;
};

const ContentContainer = ({ children }: Props) => (
  <div className="m-4 md:m-8">
    <ErrorBoundary>{children}</ErrorBoundary>
  </div>
);

export default ContentContainer;
