import React from 'react';

import { __ } from 'helpers/i18n';

import { Box, Button, SavingStatusLabel, Text } from 'components';
import { TextPreset } from 'components/text/Text';

type Props = {
  stepName: string;
  title?: string;
  titlePreset?: TextPreset;
  subtitle?: string;
  onContinueClick?: () => void | Promise<void>;
  canPassStep?: boolean;
  disabledExplanation?: string;
  children: React.ReactNode;
  continueLabel?: string;
  savingStatusLabel?: string;
};

const StepContainer = ({
  stepName,
  title,
  titlePreset,
  subtitle,
  canPassStep,
  disabledExplanation,
  children,
  onContinueClick,
  continueLabel,
  savingStatusLabel,
}: Props) => (
  <Box additionalClassName={`step-container step-${stepName}`}>
    <form
      onSubmit={e => {
        e.preventDefault();
        if (canPassStep && !!onContinueClick) onContinueClick();
      }}
    >
      {title && <Text preset={titlePreset || '18bs5'}>{title}</Text>}
      {subtitle && <p>{subtitle}</p>}
      <div className="step-content">{children}</div>

      <div className="flex justify-end items-center">
        {!!savingStatusLabel && (
          <SavingStatusLabel
            failedText={() => savingStatusLabel}
            style={{ marginRight: 16 }}
          />
        )}

        {!!onContinueClick && (
          <Button
            color="primary"
            onClick={onContinueClick}
            type="submit"
            disabled={!canPassStep}
            disabledExplanation={disabledExplanation}
            testClassName="test-continue-button"
          >
            {continueLabel || __('Continue')}
          </Button>
        )}
      </div>
    </form>
  </Box>
);

export default StepContainer;
