import React from 'react';

import type { TrainingRequest } from 'models';

import { startToEndDateLabel } from 'helpers/date';
import { approvalStatusIconWithTooltip } from 'helpers/models/trainingRequest';

import { BoxListItem, Column, Columns } from 'components';

type Props = {
  trainingRequest: TrainingRequest;
};

export default function TrainingRequestListItem({ trainingRequest }: Props) {
  return (
    <BoxListItem testClassName="test-training-request-list-item">
      <Columns>
        <Column
          key={`training-item-${trainingRequest.id}-title`}
          size={7}
          testClassName="test-training-request-list-item-title"
        >
          {trainingRequest.title}
        </Column>
        <Column key={`training-item-${trainingRequest.id}-status`} size={2}>
          {approvalStatusIconWithTooltip(trainingRequest.approvalStatus)}
        </Column>
        <Column key={`training-item-${trainingRequest.id}-dates`}>
          {startToEndDateLabel(
            trainingRequest.session?.startDate,
            trainingRequest.session?.endDate
          )}
        </Column>
      </Columns>
    </BoxListItem>
  );
}
