import React from 'react';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToUserReviews } from 'helpers/paths';

import { Box, BoxFooter, DesignSystem, Text } from 'components';

const ReviewEmptyState = () => {
  const activeUser = useActiveUser();

  return (
    <DesignSystem version={2}>
      <Box style={{ textAlign: 'center' }}>
        <Text color="light" style={{ fontSize: 16 }}>
          {__('You don’t have any active review')}
        </Text>

        <BoxFooter to={pathToUserReviews(activeUser.id)}>
          {__('See my archived reviews')}
        </BoxFooter>
      </Box>
    </DesignSystem>
  );
};

export default ReviewEmptyState;
