import { isEqual } from 'lodash';
import React from 'react';

import type { TextQuestionBlockContent } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import invariant from 'helpers/invariant';
import transformProps from 'helpers/transformProps';

import { post } from 'redux/actions/api';

import QuestionBlock from 'scenes/components/review/QuestionBlock';

import ReviewMessages from '../ReviewMessages';
import FeedbackZone from './components/FeedbackZone';

type Props = {
  content: TextQuestionBlockContent;
  refreshShareability: () => Promise<void>;
  isPreview?: boolean;
};

type AfterConnectProps = Props & {
  persistAction: (
    evaluationId: string | undefined | null,
    feedbackableId: string | undefined | null,
    feedbackableType: string | undefined | null,
    text: string
  ) => (dispatch: AppDispatch) => Promise<void>;
};

function TextQuestionBlockContentComponent({
  content,
  persistAction,
}: AfterConnectProps) {
  const { title, description, messages, feedbackableItems, richTextEnabled } =
    content;

  invariant(
    feedbackableItems.length === 1,
    'TextQuestionBlock should have only one feedbackable item'
  );

  const feedbackableItem = feedbackableItems[0];

  return (
    <QuestionBlock
      title={title}
      description={description}
      testClassName="test-text-question-block"
      withRichText={richTextEnabled}
    >
      <ReviewMessages messages={messages} />

      <FeedbackZone
        feedbackableItem={feedbackableItem}
        persistAction={persistAction}
      />
    </QuestionBlock>
  );
}

function propsTransformer({ refreshShareability, isPreview }: Props) {
  return {
    persistAction: (evaluationId, feedbackableId, feedbackableType, text) => {
      invariant(evaluationId, 'evaluationId must be defined');

      return async dispatch => {
        if (isPreview) {
          return null;
        }

        await dispatch(
          post('feedbacks', {
            feedback: {
              evaluationId,
              feedbackableId,
              feedbackableType,
              text,
            },
          })
        );

        await refreshShareability();
      };
    },
  };
}

export default React.memo(
  transformProps(propsTransformer)(TextQuestionBlockContentComponent),
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
) as React.ComponentType<Props>;
