import React, { Fragment } from 'react';

import { UserWithCareerLevelAndAssignationCriterion } from 'models/User';

import { __ } from 'helpers/i18n';
import { pathToUser } from 'helpers/paths';

import { BoxListItem, Column, Columns, Text } from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

import useAssignationEnabled from '../../helpers/userAssignation/useAssignationEnabled';

type Props = { user: UserWithCareerLevelAndAssignationCriterion };

function ListItem({ user }: Props) {
  const assignationEnabled = useAssignationEnabled();

  return (
    <BoxListItem>
      <Columns>
        <Column isVerticallyCentered>
          <UserAvatar user={user} link={pathToUser(user.id)} withJobTitle />
        </Column>
        <Column isVerticallyCentered>
          {!user.currentCareerLevel?.skillsLevel ? (
            <Text transformation="italic" color="grey">
              {__('No assigned matrix')}
            </Text>
          ) : (
            <Text>{`${user.currentCareerLevel.skillsLevel.matrixTitle} (${user.currentCareerLevel.skillsLevel.title})`}</Text>
          )}
        </Column>
        {assignationEnabled && (
          <Fragment>
            <Column isVerticallyCentered>
              {!!user.currentCareerLevel ? (
                <Text>
                  {!!user.currentCareerLevel.updatedById
                    ? __('Manual')
                    : __('Automatic')}
                </Text>
              ) : (
                <Text transformation="italic" color="grey">
                  {__('No assignation')}
                </Text>
              )}
            </Column>
            <Column isVerticallyCentered>
              {!!user.skillsAssignationCriterionValue ? (
                <Text>{user.skillsAssignationCriterionValue}</Text>
              ) : (
                <Text transformation="italic" color="grey">
                  {__('Empty field in the profile')}
                </Text>
              )}
            </Column>
          </Fragment>
        )}
      </Columns>
    </BoxListItem>
  );
}

export default ListItem;
