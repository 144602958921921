export type AutoSaveAction = {
  type: 'RESET' | 'SET_SAVING' | 'SET_SAVED' | 'SET_FAILED';
  fieldUid: string;
};

export function resetFieldStatus(fieldUid: string): AutoSaveAction {
  return {
    type: 'RESET',
    fieldUid,
  };
}

export function setFieldSaving(fieldUid: string): AutoSaveAction {
  return {
    type: 'SET_SAVING',
    fieldUid,
  };
}

export function setFieldSaved(fieldUid: string): AutoSaveAction {
  return {
    type: 'SET_SAVED',
    fieldUid,
  };
}

export function setFieldFailed(fieldUid: string): AutoSaveAction {
  return {
    type: 'SET_FAILED',
    fieldUid,
  };
}
