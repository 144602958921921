export type Classes =
  | string
  | Array<string>
  | {
      [className: string]: any;
    }
  | false
  | undefined
  | null;

export default function classNames(...args: Array<Classes>) {
  const classes = [];

  for (let arg of args) {
    if (!arg) continue;

    if (typeof arg === 'string' || typeof arg === 'number') {
      // @ts-ignore TSFIXME: Fix strictNullChecks error
      classes.push(arg);
    } else if (Array.isArray(arg) && arg.length) {
      for (let inner of arg) {
        // @ts-ignore TSFIXME: Fix strictNullChecks error
        classes.push(inner);
      }
    } else if (typeof arg === 'object' && !Array.isArray(arg)) {
      for (let key in arg) {
        if (!!arg[key]) {
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          classes.push(key);
        }
      }
    }
  }

  return classes.join(' ');
}
