import React from 'react';
import { OptionProps, components } from 'react-select';

import { UserFilterSegment } from 'models';

import { Icon, Text } from 'components';

type Props = OptionProps & {
  data: {
    segment: UserFilterSegment;
    disabled: boolean;
    disabledReason: string | null;
  };
};

export const Option = (props: Props) => {
  const {
    isDisabled,
    isSelected,
    isFocused,
    label,
    data: { disabledReason },
  } = props;
  return (
    <components.Option
      {...props}
      isSelected={!isDisabled && isSelected}
      isFocused={!isDisabled && isFocused}
    >
      <div className="inline-flex w-full items-center">
        {label}
        {!!disabledReason && (
          <Text
            preset="14s6"
            additionalClassName="ml-auto"
            style={{ minWidth: 'fit-content' }}
          >
            {disabledReason}
            <Icon additionalClassName="ml-2" name="block" />
          </Text>
        )}
      </div>
    </components.Option>
  );
};
