import { useParams } from 'react-router-dom';

import { UserReview } from 'models/UserReview';

import { useAppSelector } from 'helpers/hooks';

import { hydrateFromStore } from 'lib/dataLoader';

type EvaluationPageParams = {
  evaluationId: string;
};

type ReviewPageParams = {
  userReviewId: string;
};

const useUserReviewFromParams = (): UserReview | undefined => {
  const params = useParams<ReviewPageParams | EvaluationPageParams>();

  const getUserReview = state => {
    if ('userReviewId' in params) {
      return hydrateFromStore(
        state.data,
        { resourceType: 'userReview', id: params.userReviewId },
        {}
      );
    }

    if ('evaluationId' in params) {
      return hydrateFromStore(
        state.data,
        { resourceType: 'evaluation', id: params.evaluationId },
        { evaluation: { userReview: {} } }
      ).userReview;
    }
  };

  const userReview = useAppSelector(getUserReview);

  return userReview;
};

export default useUserReviewFromParams;
