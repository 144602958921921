import React, { useMemo } from 'react';

import { ScaleScore, ScaleSpecificStats, SurveyScaleQuestion } from 'models';

import { __ } from 'helpers/i18n';

import {
  Box,
  BoxVerticalSeparator,
  FlatPieChart,
  Flex,
  Text,
} from 'components';

import ComingSoon from '../../components/ComingSoon';
import QuestionScore from '../../components/QuestionScore';
import { statsToChartData } from '../helpers/scaleStatsHelper';

type Props = {
  question: SurveyScaleQuestion;
  score: ScaleScore;
  stats: ScaleSpecificStats;
  testClassName?: string;
};

const ScaleStats = ({ question, score, stats, testClassName }: Props) => {
  const data = useMemo(
    () => statsToChartData(question, stats),
    [question, stats]
  );

  return (
    <Box additionalClassName="mt-4" testClassName={testClassName}>
      <Flex>
        <div className="w-2/3">
          <Text preset="18bs5">{__('Average rating')}</Text>
          <Flex additionalClassName="mt-4">
            <QuestionScore score={score} question={question} />
            <div className="ml-16 grow">
              <FlatPieChart data={data} withoutPercentage />
            </div>
          </Flex>
        </div>

        <BoxVerticalSeparator />

        <div>
          <Text preset="18bs5">{__('Completion')}</Text>
          <ComingSoon additionalClassName="mt-4" />
        </div>
      </Flex>
    </Box>
  );
};

export default ScaleStats;
