import React, { ReactNode, useState } from 'react';

import invariant from 'helpers/invariant';

import { Tabs } from 'components';

type Tab<TabKey> = {
  key: TabKey;
  enabled: boolean;
  label: string;
  content: ReactNode;
};

/**
 * - If 0 tab, will throw an error (see invariant details)
 * - If 1 tab, will return `[null, ReactNode]`
 * - If 2 or more tabs, will return `[ReactNode, ReactNode]`
 * @returns `[tabs, tabContent]`
 */
const usePanelTabs = <TabKey extends string>(
  tabs: Array<Tab<TabKey>>
): [ReactNode, ReactNode] => {
  const tabsCount = tabs.length;

  const [currentTab, setCurrentTab] = useState(tabs[0]?.key);

  const showPanel = tabsCount > 0;
  const showTabs = tabsCount > 1;

  invariant(
    showPanel,
    "There's no tab to show, check shouldShowUserPanel before rendering UserPanel"
  );

  const tabContent = tabs.find(tab => tab.key === currentTab)!.content;

  if (!showTabs) return [null, tabContent];

  return [
    <div className="w-full overflow-y-auto">
      <Tabs
        tabsWidth="fit-content"
        items={tabs.map(tab => ({
          label: tab.label,
          onClick: () => setCurrentTab(tab.key),
          isActive: currentTab === tab.key,
        }))}
      />
    </div>,
    tabContent,
  ];
};

export default usePanelTabs;
