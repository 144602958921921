import { useState } from 'react';

type Section<T> = Array<T>;

const useSectionSearch = <T>(
  sections: Array<Section<T>>,
  select: (element: T) => string
): [Array<Section<T>>, (searchString: string) => void, string] => {
  const [filteredSections, setFilteredSections] = useState(sections);
  const [searchString, setSearchString] = useState('');

  const onSearchChange = (searchString: string) => {
    setSearchString(searchString);
    setFilteredSections(
      sections.map(section => {
        return section.filter(element => {
          const elementValue = select(element);

          return elementValue
            .toLowerCase()
            .includes(searchString.toLowerCase());
        });
      })
    );
  };

  return [filteredSections, onSearchChange, searchString];
};

export default useSectionSearch;
