import React from 'react';

import { EmptyState, Testable } from 'components';

import emptyStateImg from './reviews_emptystate.png';

type Props = {
  title: string;
  inBoxList?: boolean;
};

const ReviewEmptyState = ({ title, inBoxList }: Props) => {
  return (
    <Testable name="test-review-empty-state">
      <EmptyState inBoxList={inBoxList} src={emptyStateImg} title={title} />
    </Testable>
  );
};

export default ReviewEmptyState;
