import React from 'react';
import { connect } from 'react-redux';

import type { MaterialIconName } from 'components/Icon';
import type { PeopleUserReview, PeopleUserReviewAbilityId } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import { canGoBack, navigateBack } from 'helpers/navigation';
import { navigate, pathToPeopleReviewCycleReviews } from 'helpers/navigation';

import { put } from 'redux/actions/api';

import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Icon,
  SavingStatusConnector,
  Text,
  Tooltip,
} from 'components';

const getHumanizedStatus = (
  status: string
): {
  icon: MaterialIconName;
  label: string;
} => {
  switch (status) {
    case 'to_do':
    default:
      return { icon: 'radio_button_unchecked', label: __('To complete') };
    case 'completed':
      return { icon: 'check_circle', label: __('Completed') };
    case 'validated':
      return { icon: 'check_circle', label: __('Validated') };
  }
};

const tooltipContent = ({ isDisabled, canUpdate }) => {
  if (!isDisabled) return null;

  return !canUpdate ? (
    <Text>
      {__(
        'You have not been appointed to complete this people review, you have read-only access to it.'
      )}
    </Text>
  ) : (
    <Text>
      {__(
        'Once a people review has been validated it is no longer possible to edit it.'
      )}
      <br />
      {__('If needed, you can contact your administrator to re-open it.')}
    </Text>
  );
};

type MenuItem = {
  icon: MaterialIconName;
  label: string;
  onClick: () => Promise<void>;
};

type StatusWithAction = {
  status: string;
  action: PeopleUserReviewAbilityId;
};

type Props = {
  review: PeopleUserReview;
  refetchReview: () => void;
};

type AfterConnectProps = Props & {
  updateStatus: (status: string) => Promise<void>;
};

const Actions = ({
  review,
  updateStatus,
  refetchReview,
}: AfterConnectProps) => {
  const menuItems: Array<MenuItem> = [];
  const { icon: currentIcon, label: currentLabel } = getHumanizedStatus(
    review.status
  );
  const canUpdate = can({ perform: 'update', on: review });
  const isDisabled =
    !canUpdate ||
    (review.status === 'validated' &&
      !can({ perform: 'set_status_to_todo', on: review }));
  const tooltip = tooltipContent({ isDisabled, canUpdate });

  const handleUpdateStatus = async (status: string) => {
    const shouldReload =
      review.status === 'validated' ||
      (review.status === 'completed' && status === 'to_do');
    const goBack = () =>
      canGoBack()
        ? navigateBack()
        : navigate(pathToPeopleReviewCycleReviews(review.peopleReviewCycleId));

    await updateStatus(status);

    return shouldReload ? refetchReview() : goBack();
  };

  if (!isDisabled) {
    const statusesWithAction: Array<StatusWithAction> = [
      { status: 'to_do', action: 'set_status_to_todo' },
      { status: 'completed', action: 'set_status_to_completed' },
      { status: 'validated', action: 'set_status_to_validated' },
    ];

    statusesWithAction.forEach(({ status, action }) => {
      if (can({ perform: action, on: review })) {
        let { icon, label } = getHumanizedStatus(status);

        menuItems.push({
          icon,
          label,
          onClick: () => handleUpdateStatus(status),
        });
      }
    });
  }

  return (
    <SavingStatusConnector
      render={({ autoSaveStatus }) => {
        const isSaving = autoSaveStatus === 'saving';

        return (
          <Dropdown>
            <DropdownTrigger
              disabled={isDisabled || isSaving}
              render={() => (
                <Tooltip content={tooltip}>
                  <Button color="primary" disabled={isDisabled || isSaving}>
                    <Icon style={{ marginRight: 8 }} name={currentIcon} />
                    <span>{currentLabel}</span>
                    <Icon
                      style={{ marginLeft: 8 }}
                      name="keyboard_arrow_down"
                    />
                  </Button>
                </Tooltip>
              )}
            />
            <DropdownMenu>
              {menuItems.map(({ icon, label, onClick }: MenuItem, index) => (
                <DropdownMenuItem
                  key={index}
                  onClick={onClick}
                  testClassName="test-people-user-review-action"
                >
                  <Icon name={icon} />
                  <span>{label}</span>
                </DropdownMenuItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        );
      }}
    />
  );
};

function mapDispatchToProps(dispatch: AppDispatch, { review }: Props) {
  return {
    updateStatus: (status: string) =>
      dispatch(put(`people_user_reviews/${review.id}`, { status })),
  };
}

export default connect(null, mapDispatchToProps)(Actions);
