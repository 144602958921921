import type { EmailRecipient, InteractionType } from 'models';

import { __ } from 'helpers/i18n';

type ReviewVariable = {
  name: string;
  variable: string;
};

export default function reviewCycleVariables(
  role: EmailRecipient,
  interactionType: InteractionType
): Array<ReviewVariable> {
  if (role === 'reviewee' && interactionType === 'one_on_one') {
    return [
      {
        name: __('Reviewee last name'),
        variable: '[[reviewee.lastName]]',
      },
      {
        name: __('Reviewee first name'),
        variable: '[[reviewee.firstName]]',
      },
      {
        name: __('Responsible last name'),
        variable: '[[responsible.lastName]]',
      },
      {
        name: __('Responsible first name'),
        variable: '[[responsible.firstName]]',
      },
      {
        name: __('Cycle name'),
        variable: '[[reviewCycle.name]]',
      },
      {
        name: __('Due date for the reviewee'),
        variable: '[[reviewCycle.revieweeDueDate]]',
      },
      {
        name: __('Due date for the responsible'),
        variable: '[[reviewCycle.responsibleDueDate]]',
      },
      {
        name: __(
          'Number of days remaining before the due date of the reviewee'
        ),
        variable: '[[reviewCycle.revieweeRemainingDays]]',
      },
      {
        name: __(
          'Number of days remaining before the due date of the responsible'
        ),
        variable: '[[reviewCycle.responsibleRemainingDays]]',
      },
    ];
  }

  if (role === 'responsible' && interactionType === 'one_on_one') {
    return [
      {
        name: __('Responsible last name'),
        variable: '[[responsible.lastName]]',
      },
      {
        name: __('Responsible first name'),
        variable: '[[responsible.firstName]]',
      },
      {
        name: __('Cycle name'),
        variable: '[[reviewCycle.name]]',
      },
      {
        name: __('Due date for the reviewee'),
        variable: '[[reviewCycle.revieweeDueDate]]',
      },
      {
        name: __('Due date for the responsible'),
        variable: '[[reviewCycle.responsibleDueDate]]',
      },
      {
        name: __(
          'Number of days remaining before the due date of the reviewee'
        ),
        variable: '[[reviewCycle.revieweeRemainingDays]]',
      },
      {
        name: __(
          'Number of days remaining before the due date of the responsible'
        ),
        variable: '[[reviewCycle.responsibleRemainingDays]]',
      },
      {
        name: __('Number of reviews to complete'),
        variable: '[[responsibleUserReviews.count]]',
      },
    ];
  }

  if (role === 'reviewee' && interactionType === '360') {
    return [
      {
        name: __('Reviewee last name'),
        variable: '[[reviewee.lastName]]',
      },
      {
        name: __('Reviewee first name'),
        variable: '[[reviewee.firstName]]',
      },
      {
        name: __('Responsible last name'),
        variable: '[[responsible.lastName]]',
      },
      {
        name: __('Responsible first name'),
        variable: '[[responsible.firstName]]',
      },
      {
        name: __('Cycle name'),
        variable: '[[reviewCycle.name]]',
      },
      {
        name: __('Due date'),
        variable: '[[reviewCycle.dueDate]]',
      },
      {
        name: __('Remaining days before due date'),
        variable: '[[reviewCycle.remainingDays]]',
      },
    ];
  }

  if (role === 'responsible' && interactionType === '360') {
    return [
      {
        name: __('Responsible last name'),
        variable: '[[responsible.lastName]]',
      },
      {
        name: __('Responsible first name'),
        variable: '[[responsible.firstName]]',
      },
      {
        name: __('Cycle name'),
        variable: '[[reviewCycle.name]]',
      },
      {
        name: __('Due date'),
        variable: '[[reviewCycle.dueDate]]',
      },
      {
        name: __('Remaining days before due date'),
        variable: '[[reviewCycle.remainingDays]]',
      },
      {
        name: __('Number of  360° feedback to complete'),
        variable: '[[responsibleUserReviews.count]]',
      },
    ];
  }

  throw new Error(
    `Unsupported role:interactionType: ${role}:${interactionType}`
  );
}
