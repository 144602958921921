import React from 'react';

import type { User } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { del } from 'redux/actions/api';

import { ConfirmationModal } from 'components';

type Props = {
  isActive: boolean;
  onDismiss: () => void;
  user: User | undefined | null;
};

const SuspendUserModal = ({ isActive, onDismiss, user }: Props) => {
  const dispatch = useAppDispatch();
  const confirm = async () => {
    invariant(user, 'User should be defined');
    await dispatch(del(`users/${user.id}`));
    window.location.reload();
  };

  const displayName = user ? user.fullName : '';
  return (
    <ConfirmationModal
      isDanger
      isActive={isActive}
      onCancel={onDismiss}
      onConfirm={confirm}
      title={__('Suspend %1', displayName)}
      confirmLabel={__('Suspend %1', displayName)}
      refreshContentOnOpening={false}
    >
      <p>{__("This user won't be able to connect anymore.")}</p>
      <p>{__('Do you still want to proceed?')}</p>
    </ConfirmationModal>
  );
};

export default SuspendUserModal;
