import React from 'react';

import type { ObjectiveStatus } from 'models/Objective';

import colors from 'styles/colors';

import classNames from 'helpers/classNames';

type Props = {
  status: ObjectiveStatus;
  additionalClassName?: string;
};

const StatusDisplay = ({ status, additionalClassName }: Props) => {
  return (
    <div
      className={classNames('status', additionalClassName)}
      style={{
        position: 'absolute',
        left: '8px',
        top: '50%',
        transform: 'translateY(-50%)',
        marginRight: '4px',
        width: 10,
        height: 10,
        borderRadius: 2,
        backgroundColor: colors.objectiveStatus[status || 'default'],
      }}
    />
  );
};

export default StatusDisplay;
