import React from 'react';

import type {
  Evaluation,
  ThreeSixtyReviewCycle,
  ThreeSixtyUserReview,
} from 'models';

import { __ } from 'helpers/i18n';

import { Text } from 'components';

type Props = {
  userReview: ThreeSixtyUserReview;
  evaluations: Array<Evaluation>;
  reviewCycle: ThreeSixtyReviewCycle;
};

export default function StatusLabel({
  userReview,
  evaluations,
  reviewCycle,
}: Props) {
  const { isReleased, arePeersValidated } = userReview;
  const arePeersNominated = evaluations.length > 1;
  const nobodyStarted = evaluations.every(e => e.status === 'not_started');

  if (isReleased) {
    return <Text color="success">{__('Shared')}</Text>;
  }

  if (!arePeersValidated) {
    if (!arePeersNominated && !!reviewCycle.nominatePeersStepEnabled) {
      return <Text color="danger">{__('Pending peers nomination')}</Text>;
    }

    return <Text color="warning">{__('Pending peers validation')}</Text>;
  }

  if (nobodyStarted) {
    return <Text color="danger">{__('Not started')}</Text>;
  }

  return <Text color="info">{__('In Progress')}</Text>;
}
