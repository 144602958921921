import React from 'react';

import type { OneOnOneReviewCycle, OneOnOneUserReview } from 'models';

import can from 'helpers/can';
import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { assert } from 'helpers/invariant';
import {
  pathToCurrentUserEvaluation,
  pathToReviewResults,
} from 'helpers/navigation';

import { Button, Date, Flex, Text } from 'components';

import CalendarButton from 'scenes/components/userReview/CalendarButton';
import OneOnOneActionsHamburgerMenu from 'scenes/components/userReview/oneOnOne/actions/OneOnOneActionsHamburgerMenu';

type Props = {
  userReview: OneOnOneUserReview;
  reviewCycle: OneOnOneReviewCycle;
  onAfterDelegate: () => Promise<any>;
};

const ReviewerActionButton = ({ userReview, reviewCycle }: Props) => {
  const activeUser = useActiveUser();
  const getReviewPath = () => pathToReviewResults(userReview);
  const getEvaluationPath = () => pathToCurrentUserEvaluation(userReview);

  const activeUserEvaluation = userReview.evaluations.find(
    evaluation => evaluation.reviewerId === activeUser.id
  );

  let buttonLabel = __('View');
  let isPrimary = true;
  let path = getReviewPath();

  switch (activeUserEvaluation?.status) {
    case 'not_started':
      buttonLabel = __('Start assessment');
      path = getEvaluationPath();
      break;
    case 'in_progress':
      buttonLabel = __('Continue assessment');
      path = getEvaluationPath();
      break;
    case 'shared':
      if (
        reviewCycle.signatureModuleEnabled &&
        userReview.reviewerStatus === 'shared'
      ) {
        buttonLabel = __('Sign');
        isPrimary = true;
      } else {
        buttonLabel = __('View');
        isPrimary = false;
      }
      break;
    case 'signed':
      buttonLabel = __('View');
      isPrimary = false;
      break;
    default:
  }

  return (
    <Button
      color={isPrimary ? 'primary' : 'secondary'}
      to={path}
      testClassName="test-reviewer-action-button"
    >
      {buttonLabel}
    </Button>
  );
};

const ButtonsGroup = ({ userReview, reviewCycle, onAfterDelegate }: Props) => {
  return (
    <Flex style={{ alignItems: 'flex-start' }}>
      <div style={{ marginRight: 10, textAlign: 'end' }}>
        <ReviewerActionButton
          userReview={userReview}
          reviewCycle={reviewCycle}
          onAfterDelegate={onAfterDelegate}
        />

        {reviewCycle.autoAddParticipantsEnabled && (
          <Text color="soften" style={{ display: 'block' }}>
            {__('Deadline:')} <Date value={userReview.reviewerDeadline} />
          </Text>
        )}
      </div>
      <div style={{ marginRight: 10 }}>
        <CalendarButton
          userReviewId={userReview.id}
          meeting={assert(
            userReview.meeting,
            'Meeting should be available at this point'
          )}
          disabled={!can({ perform: 'schedule_meeting', on: userReview })}
        />
      </div>
      <OneOnOneActionsHamburgerMenu
        userReview={userReview}
        reviewCycle={reviewCycle}
        refetchData={onAfterDelegate}
      />
    </Flex>
  );
};

export default ButtonsGroup;
