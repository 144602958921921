import React from 'react';

import { useAppDispatch } from 'helpers/hooks';
import { useForm } from 'helpers/hooks/useForm';
import { __ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import {
  Button,
  Icon,
  ModalCard,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import usePeopleReviewCycleFromParams from '../usePeopleReviewCycleFromParams';
import PeopleGroupModalBody from './PeopleGroupModalBody';
import { PeopleGroupData } from './types';

type Props = {
  onClose: () => void;
  onSubmit: () => void;
};

const CreatePeopleGroupModal = ({ onClose, onSubmit }: Props) => {
  const peopleReviewCycle = usePeopleReviewCycleFromParams();

  const dispatch = useAppDispatch();
  const { values, errors, handleInputChange, handleSubmit } =
    useForm<PeopleGroupData>(
      {
        title: '',
        description: '',
        peopleGroupRules: [
          {
            id: '',
            peopleReviewDimension: peopleReviewCycle.peopleReviewDimensions[0],
            condition: '>=',
            value: '2',
          },
        ],
      },
      async values => {
        await dispatch(
          post(`people_groups`, {
            title: values.title,
            description: values.description,
            peopleGroupRulesAttributes: values.peopleGroupRules.map(rule => ({
              id: rule.id,
              peopleReviewDimensionId: rule.peopleReviewDimension.id,
              condition: rule.condition,
              value: rule.value,
            })),
            cycleId: peopleReviewCycle.id,
          })
        );
        onSubmit();
        onClose();
      }
    );

  const disableCreateButton = values.title === '';

  return (
    <ModalCard isActive isLarge onClose={onClose}>
      <ModalCardHead>
        <ModalCardTitle>{__('Create a group of employees')}</ModalCardTitle>
      </ModalCardHead>

      <PeopleGroupModalBody
        peopleGroup={values}
        peopleReviewCycle={peopleReviewCycle}
        errors={errors}
        handleInputChange={handleInputChange}
      />

      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Cancel')}
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={disableCreateButton}
        >
          <Icon name="edit" additionalClassName="mr-1" />
          {__('Create')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default CreatePeopleGroupModal;
