import { Organization } from 'models/index';

import { useAppSelector } from 'helpers/hooks';
import invariant from 'helpers/invariant';

import { getOrganization } from 'redux/reducers';

const useOrganization = (): Organization => {
  return useAppSelector(state => {
    const organization = getOrganization(state);

    invariant(organization, 'Active user should be defined.');

    return organization;
  });
};

export default useOrganization;
