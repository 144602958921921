import React from 'react';

import type { ENPSAnswer } from 'models';

import { __ } from 'helpers/i18n';

import { BoxListItem, Column, Columns, Text } from 'components';

type Props = {
  answer: ENPSAnswer;
};

export default function AnswersListItem({ answer }: Props) {
  return (
    <BoxListItem key={answer.id}>
      <Columns>
        <Column size={10}>
          {answer.comment ? (
            <Text preset="14s6">{answer.comment}</Text>
          ) : (
            <Text preset="14s6" color="light" transformation="italic">
              {__('Did not leave a comment')}
            </Text>
          )}
        </Column>
        <Column size={2}>{answer.score}</Column>
      </Columns>
    </BoxListItem>
  );
}
