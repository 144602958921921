import React, { ReactNode, useState } from 'react';

import invariant from 'helpers/invariant';

import { Column, Columns } from 'components';

import SideStepList from './SideStepList';

export type StepInfo<TStepName extends string> = {
  name: TStepName;
  title: string;
  isCompleted: boolean;
};

type Props<TStepName extends string> = {
  currentStepIndexFromElement: number;
  stepInfoList: StepInfo<TStepName>[];
  renderChildren: (
    currentStepInfo: StepInfo<TStepName>,
    goToStep: (stepName: TStepName) => void,
    goToNextStep: (params?: { requestAlreadySent?: boolean }) => void
  ) => ReactNode;
  updateElementStepIndex?: (newIndex: number) => Promise<void>;
};

const Stepper = <TStepName extends string>({
  renderChildren,
  stepInfoList,
  updateElementStepIndex,
  currentStepIndexFromElement,
}: Props<TStepName>) => {
  const [currentStepIndex, setStepIndex] = useState<number>(
    currentStepIndexFromElement
  );
  const currentStepInfo = stepInfoList[currentStepIndex];

  invariant(currentStepInfo, 'Current step must be defined');

  const goToNextStep = async (params?: { requestAlreadySent?: boolean }) => {
    const nextStepIndex = currentStepIndex + 1;

    if (
      nextStepIndex !== currentStepIndexFromElement &&
      !params?.requestAlreadySent &&
      updateElementStepIndex
    )
      await updateElementStepIndex(nextStepIndex);

    setStepIndex(nextStepIndex);
  };

  const goToStep = (name: TStepName) => {
    const stepInfoIndex = stepInfoList
      .map(stepInfo => stepInfo.name)
      .indexOf(name);

    invariant(stepInfoIndex, `Cannot find step '${name}' on stepInfoList`);

    return setStepIndex(stepInfoIndex);
  };

  return (
    <div className="mx-auto max-w-[1020px]">
      <div className="m-4 md:m-8">
        <Columns>
          <Column size={3}>
            <SideStepList
              currentStepIndex={currentStepIndex}
              stepInfoList={stepInfoList}
              onChange={(stepIndex: number) => setStepIndex(stepIndex)}
            />
          </Column>
          <Column size={9}>
            {renderChildren(currentStepInfo, goToStep, goToNextStep)}
          </Column>
        </Columns>
      </div>
    </div>
  );
};

export default Stepper;
