import React, { useEffect, useRef } from 'react';

import { defaultConfig } from './Item';
import { ItemCanvas, ItemCanvasConfig } from './ItemCanvas';
import { useComponentSize, useDeepMemo, useItemFallStyle } from './hooks';

export interface ItemProps extends Partial<ItemCanvasConfig> {
  style?: React.CSSProperties;
}

export const ItemFall = ({
  color = defaultConfig.color,
  changeFrequency = defaultConfig.changeFrequency,
  radius = defaultConfig.radius,
  speed = defaultConfig.speed,
  wind = defaultConfig.wind,
  rotationSpeed = defaultConfig.rotationSpeed,
  itemCount = 150,
  images,
  style,
}: ItemProps = {}) => {
  const mergedStyle = useItemFallStyle(style);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasSize = useComponentSize(canvasRef);

  const config = useDeepMemo<ItemCanvasConfig>({
    color,
    changeFrequency,
    radius,
    speed,
    wind,
    rotationSpeed,
    images,
    itemCount,
  });

  // A reference to the config used for creating the initial instance
  const configRef = useRef(config);

  const snowfallCanvasRef = useRef<ItemCanvas>();

  useEffect(() => {
    if (!snowfallCanvasRef.current && canvasRef.current) {
      snowfallCanvasRef.current = new ItemCanvas(
        canvasRef.current,
        configRef.current
      );
    }

    return () => {
      snowfallCanvasRef.current?.pause();
      snowfallCanvasRef.current = undefined;
    };
  }, []);

  useEffect(() => {
    if (snowfallCanvasRef.current) {
      snowfallCanvasRef.current.updateConfig(config);
    }
  }, [config]);

  return (
    <canvas
      ref={canvasRef}
      height={canvasSize.height}
      width={canvasSize.width}
      style={mergedStyle}
      data-testid="ItemCanvas"
    />
  );
};

export default ItemFall;
