import React, { Fragment } from 'react';

import {
  SurveyAnswerSentimentCategory,
  SurveyTextQuestion,
  TextScore,
  TextSpecificStats,
} from 'models';

import tailwindTheme from 'config/tailwindTheme';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { Box, BoxVerticalSeparator, FlatPieChart, Text } from 'components';

import AILogo from 'scenes/components/AILogo';

import ComingSoon from '../../components/ComingSoon';
import QuestionScore from '../../components/QuestionScore';

type Props = {
  stats: TextSpecificStats;
  score: TextScore;
  sentimentCategory: SurveyAnswerSentimentCategory;
  question: SurveyTextQuestion;
  testClassName?: string;
};

const TextStats = ({
  stats,
  score,
  sentimentCategory,
  question,
  testClassName,
}: Props) => {
  const organization = useOrganization();

  return (
    <Box additionalClassName="mt-4" testClassName={testClassName}>
      <div className="flex">
        {organization.aiFeaturesEnabled && stats && (
          <Fragment>
            <TextSentimentStats
              stats={stats}
              score={score}
              sentimentCategory={sentimentCategory}
              question={question}
            />
            <BoxVerticalSeparator />
          </Fragment>
        )}

        <div>
          <Text preset="18bs5">{__('Completion')}</Text>
          <ComingSoon additionalClassName="mt-4" />
        </div>
      </div>
    </Box>
  );
};

type PropsWithSentiment = {
  stats: NonNullable<TextSpecificStats>;
  score: TextScore;
  sentimentCategory: SurveyAnswerSentimentCategory;
  question: SurveyTextQuestion;
};

const TextSentimentStats = ({
  stats,
  score,
  sentimentCategory,
  question,
}: PropsWithSentiment) => {
  const categoriesData = [
    {
      label: __('Positive'),
      value: stats.positivePercent,
      color: tailwindTheme.colors['green-success'],
    },
    {
      label: __('Neutral'),
      value: stats.neutralPercent,
      color: tailwindTheme.colors['orange-warning'],
    },
    {
      label: __('Negative'),
      value: stats.negativePercent,
      color: tailwindTheme.colors['red-fuckup'],
    },
    {
      label: __('No sentiment expressed'),
      value: stats.nonePercent,
      color: tailwindTheme.colors['ui-grey'],
    },
  ];

  return (
    <div className="w-2/3">
      <div className="flex justify-between">
        <Text preset="18bs5">{__('Text question - Sentiment analysis')}</Text>
        <AILogo />
      </div>
      <div className="flex mt-4 justify-end">
        <QuestionScore
          score={score}
          sentimentCategory={sentimentCategory}
          question={question}
        />

        <div className="ml-16 grow">
          <FlatPieChart data={categoriesData} />
        </div>
      </div>
    </div>
  );
};

export default TextStats;
