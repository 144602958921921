import React from 'react';

import type { PeopleReviewCycle } from 'models';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import {
  pathToPeopleReviewCycleReviews,
  pathToPeopleReviewCycleReviewsWithUserFilter,
} from 'helpers/paths';

import { Box, Button, Column, Columns, Date, Text } from 'components';

import InsightsIcon from 'scenes/components/reviewCycle/ListItem/InsightsIcon';

type Props = {
  peopleReviewCycle: PeopleReviewCycle;
  children: React.ReactNode;
};

const PeopleReviewCycleListItem = ({ peopleReviewCycle, children }: Props) => {
  const activeUser = useActiveUser();

  return (
    <Box>
      <Columns additionalClassName="mb-1" contentVerticallyAligned>
        <Column>
          <Text preset="18bs5">{peopleReviewCycle.name}</Text>
        </Column>

        <Column isNarrow>
          <Button
            color="secondary"
            size="small"
            to={
              activeUser.isManager
                ? pathToPeopleReviewCycleReviewsWithUserFilter(
                    peopleReviewCycle.id,
                    {
                      filterLabel: __('My direct reports'),
                      filterType: 'direct_reports',
                      label: null,
                      value: activeUser.id,
                    }
                  )
                : pathToPeopleReviewCycleReviews(peopleReviewCycle.id)
            }
          >
            <InsightsIcon />
            <span className="ml-2">{__('Follow-up')}</span>
          </Button>
        </Column>
      </Columns>

      <Text color="soften" additionalClassName="mb-4 block">
        {__('Deadline:')} <Date value={peopleReviewCycle.dueDate} />
      </Text>

      <Columns additionalClassName="mb-1">
        <Column>
          <Text preset="14bs6" color="light">
            {__('Employee')}
          </Text>
        </Column>
        <Column isNarrow additionalClassName="w-[170px]">
          <Text preset="14bs6" color="light">
            {__('Status')}
          </Text>
        </Column>
      </Columns>

      {children}
    </Box>
  );
};

export default PeopleReviewCycleListItem;
