import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { pathToTemplates } from 'helpers/navigation';

import { Redirect, Route, Switch } from 'components';

import Index from './Index';
import Template from './Template';

const TemplateRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <Template />
      </Route>
      <Route exact>
        <Index />
      </Route>
      <Redirect to={pathToTemplates('one_on_one')} />
    </Switch>
  );
};

export default TemplateRoutes;
