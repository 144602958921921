import React from 'react';

import type { ChartColor } from './Chart/colors';
import type { ReviewerTag, User } from 'models';

import invariant from 'helpers/invariant';

import { Testable, viewPortAware } from 'components';

import RatingChartWithTableFallback from './RatingChartWithTableFallback';
import RatingTable from './Table';

export type GroupedAnswers = {
  [rating: string]: {
    count: number;
    authors: Array<User>;
    authorTags: Array<ReviewerTag>;
  };
};

type RequiredAnswerInfo = {
  rating: string | undefined | null;
  author: User;
  authorTag: ReviewerTag;
  authorRole: 'reviewee' | 'reviewer' | 'manager' | 'additional_reviewer';
};

type Props = {
  ratings: Array<string>;
  answers: Array<RequiredAnswerInfo>;
  colorIterator?: () => ChartColor;
};

const RatingResultsView = viewPortAware({
  mobile: RatingTable,
  tablet: RatingChartWithTableFallback,
});

function RatingResults(props: Props) {
  const { answers, ratings } = props;
  const groupedAnswers: GroupedAnswers = {};

  // Pre-set rating options
  ratings.forEach(rating => {
    groupedAnswers[rating] = {
      count: 0,
      authors: [],
      authorTags: [],
    };
  });

  answers.forEach(({ rating, author, authorTag }) => {
    if (!rating) return;

    invariant(groupedAnswers[rating], 'Answer must have a valid rating option');

    groupedAnswers[rating] = {
      count: groupedAnswers[rating].count + 1,
      authors: [...groupedAnswers[rating].authors, author],
      authorTags: [...groupedAnswers[rating].authorTags, authorTag],
    };
  });

  return (
    <div className="rating-results">
      <Testable name="test-rating-result">
        {/* @ts-ignore: TSFIXME: fix this */}
        <RatingResultsView {...props} groupedAnswers={groupedAnswers} />
      </Testable>
    </div>
  );
}

RatingResults.defaultProps = {
  colorIterator: () => 'blue',
};

export default RatingResults;
