import React from 'react';

import type { User } from 'models';

import { __ } from 'helpers/i18n';

import { DeprecatedContainerElevo, PageTitle } from 'components';

import PersonalObjectivesComponent from 'scenes/components/objectives/PersonalObjectives';

type Props = {
  user: User;
};

export default function PersonalObjectives({ user }: Props) {
  return (
    <div>
      <PageTitle title={__('My objectives')} />

      <DeprecatedContainerElevo additionalClassName="py-12 mt-5">
        <PersonalObjectivesComponent user={user} />
      </DeprecatedContainerElevo>
    </div>
  );
}
