import React from 'react';

import type { ReviewCycle } from 'models';

import FailedEmptyState from './FailedEmptyState';
import LaunchedEmptyState from './LaunchedEmptyState';

type Props = {
  reviewCycle: ReviewCycle;
};

export default function EmptyState({ reviewCycle }: Props) {
  if (reviewCycle.status === 'failed') {
    return <FailedEmptyState reviewCycleId={reviewCycle.id} />;
  }

  return <LaunchedEmptyState reviewCycle={reviewCycle} />;
}
