import React from 'react';

import type { Email, InteractionType, Organization } from 'models';

import { __, languageForLocale } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { withOrganization } from 'helpers/withSessionProps';

import { Flex, Helper, Icon, Link, Text, Title } from 'components';

import EmailEditor from './EmailEditor';
import EmailLocaleSelect from './EmailLocaleSelect';

type Props = {
  emails: Array<Email>;
  defaultTemplatePath: string;
  defaultTemplateName: string;
  interactionType: InteractionType;
  onEmailValidationChange: (value: boolean) => void;
};

type EmailValidById = {
  [emailId: string]: boolean;
};

type AfterConnectProps = Props & {
  organization: Organization;
};

function CycleEmails({
  emails,
  defaultTemplatePath,
  defaultTemplateName,
  interactionType,
  onEmailValidationChange,
  organization,
}: AfterConnectProps) {
  const [cycleEmails, setCycleEmails] = React.useState(emails);
  const [emailValidByIds, setEmailValidByIds] = React.useState<
    Partial<EmailValidById>
  >({});
  const revieweeEmails = cycleEmails.filter(
    email => email.recipientType === 'reviewee'
  );
  const responsibleEmails = cycleEmails.filter(
    email => email.recipientType === 'responsible'
  );

  const handleEmailValidationChange = React.useCallback(
    (id: string, valid: boolean) => {
      const emailsValidationStatus = { ...emailValidByIds, [id]: valid };
      setEmailValidByIds(emailsValidationStatus);

      const allEmailsValid = Object.values(emailsValidationStatus).every(
        Boolean
      );

      onEmailValidationChange(allEmailsValid);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getEmailSelectOptions = (emails: Email[]) =>
    emails
      // emails with default locale first
      .sort((a, _b) => (a.locale === organization.locale ? -1 : 1))
      .map(email => ({
        label: languageForLocale(email.locale, organization),
        value: email.locale,
        icon: <Icon color={email.modified ? 'success' : ''} name="check" />,
      }));
  const revieweeOptions = getEmailSelectOptions(revieweeEmails);
  const responsibleOptions = getEmailSelectOptions(responsibleEmails);

  const [revieweeEmailLocale, setRevieweeEmailLocale] = React.useState(
    revieweeOptions[0]
  );
  const [responsibleEmailLocale, setResponsibleEmailLocale] = React.useState(
    responsibleOptions[0]
  );

  const revieweeEmail = revieweeEmails.find(
    e => e.locale === revieweeEmailLocale.value
  );
  const responsibleEmail = responsibleEmails.find(
    e => e.locale === responsibleEmailLocale.value
  );

  const handleEmailUpdated = (email: Email) => {
    const emailIndex = cycleEmails.findIndex(e => e.id === email.id);
    const emails = [...cycleEmails];
    emails.splice(emailIndex, 1, email);
    setCycleEmails(emails);
  };

  invariant(responsibleEmail, 'responsibleEmail should be defined');

  return (
    <React.Fragment>
      <Flex verticalAlign style={{ justifyContent: 'space-between' }}>
        <Title size={5}>{__('Email to the reviewee')}</Title>

        {revieweeEmail && (
          <EmailLocaleSelect
            // @ts-ignore TSFIXME
            onChange={setRevieweeEmailLocale}
            value={revieweeEmailLocale.value}
            options={revieweeOptions}
          />
        )}
      </Flex>
      {!revieweeEmail ? (
        <Helper style={{ marginTop: 16 }}>
          <div>
            {__('Self evaluation is disabled in the template')}
            <Link to={defaultTemplatePath} openInNewTab>
              {' '}
              <Text style={{ maxWidth: 140 }} overflowEllipsis>
                {defaultTemplateName}
              </Text>
            </Link>
          </div>
        </Helper>
      ) : (
        <EmailEditor
          email={revieweeEmail}
          interactionType={interactionType}
          handleEmailValidationChange={handleEmailValidationChange}
          onEmailUpdated={handleEmailUpdated}
          testClassName="test-reviewee-emails"
        />
      )}
      <Flex
        verticalAlign
        style={{ justifyContent: 'space-between', marginTop: 32 }}
      >
        <Title size={5}>{__('Email to the responsible')}</Title>

        <EmailLocaleSelect
          // @ts-ignore TSFIXME
          onChange={setResponsibleEmailLocale}
          value={responsibleEmailLocale.value}
          options={responsibleOptions}
        />
      </Flex>

      <EmailEditor
        email={responsibleEmail}
        interactionType={interactionType}
        handleEmailValidationChange={handleEmailValidationChange}
        onEmailUpdated={handleEmailUpdated}
        testClassName="test-reviewer-emails"
      />
    </React.Fragment>
  );
}

export default withOrganization(CycleEmails);
