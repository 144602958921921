import React from 'react';

import { DesignSystem } from 'components';

import ImagePicker from './ImagePicker';

type AvatarImageProps = {
  url: string | undefined | null;
  canUpload?: boolean;
  onUpload?: (base64Image: string) => Promise<void>;
  onDelete?: () => Promise<void>;
};

export default function AvatarImage({
  url,
  canUpload,
  onUpload,
  onDelete,
}: AvatarImageProps) {
  return (
    <React.Fragment>
      <div
        className="avatar-image"
        style={url ? { backgroundImage: `url(${url})` } : {}}
      >
        {canUpload && onUpload && onDelete && (
          <DesignSystem
            version={2}
            style={{ height: '100%', position: 'relative' }}
          >
            <ImagePicker url={url} onUpload={onUpload} onDelete={onDelete} />
          </DesignSystem>
        )}
      </div>
    </React.Fragment>
  );
}
