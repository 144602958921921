// @flow
import superagent from 'superagent';
import superagentPromisePlugin from 'superagent-promise-plugin';

import type { Attributes, Method, Query } from './types';

import { createUrl } from './createUrl';
import { getCredentials } from './credentials';

const _underscoreTransform = text => {
  return text.replace(/([A-Z])/g, '_$&').toLowerCase();
};

const _transformKeysToUnderscore = camelCaseKeyObject => {
  const underscoreKeyObject = {};

  Object.keys(camelCaseKeyObject).forEach(key => {
    if (Array.isArray(camelCaseKeyObject[key])) {
      underscoreKeyObject[_underscoreTransform(key)] = camelCaseKeyObject[
        key
      ].map(arrayItem => {
        if (arrayItem !== null && typeof arrayItem === 'object') {
          return _transformKeysToUnderscore(arrayItem);
        } else {
          return arrayItem;
        }
      });
    } else if (
      camelCaseKeyObject[key] !== null &&
      typeof camelCaseKeyObject[key] === 'object'
    ) {
      underscoreKeyObject[_underscoreTransform(key)] =
        _transformKeysToUnderscore(camelCaseKeyObject[key]);
    } else {
      underscoreKeyObject[_underscoreTransform(key)] = camelCaseKeyObject[key];
    }
  });
  return underscoreKeyObject;
};

let version = null;
try {
  version = require('../../version');
} catch (e) {
  version = 'unknown-frontend';
}

type RequestOptions = {
  resource: string,
  method: string,
  addCredentials: boolean,
  query?: {} | string | null,
  data?: {} | string,
  file?: File,
};
const request = (requestOptions: RequestOptions) => {
  const { resource, query, method, data, file, addCredentials } =
    requestOptions;
  const sp = superagent(method, createUrl(resource))
    .use(superagentPromisePlugin)
    .accept('application/json');

  if (query) {
    sp.query(query);
  }

  if (file) {
    setFormData(sp, file, data);
  } else {
    setJSONData(sp, data);
  }

  sp.set('X-Frontend-Version', version);
  sp.set('Custom-Origin', window.location.origin);

  if (addCredentials && getCredentials()) {
    const credentials = getCredentials();

    if (credentials && credentials.token) {
      sp.set('Authorization', `Bearer ${credentials.token}`);
    }
  }

  return sp;
};

const setJSONData = (sp, data) => {
  if (data) sp.send(data);
  sp.type('application/json');
  return sp;
};

const setFormData = (sp, file, data) => {
  sp.attach('file', file);
  if (data && typeof data === 'object') {
    Object.entries(data).forEach(([key, value]) => {
      sp.field(key, value);
    });
  }
  return sp;
};

export type GenericFetchParams = {
  method: Method,
  url: string,
  attributes?: Attributes,
  query?: ?Query,
  file?: File,
};
export const genericFetch = (params: GenericFetchParams) => {
  const options: RequestOptions = {
    resource: params.url,
    method: params.method,
    addCredentials: true,
    query: params.query ? _transformKeysToUnderscore(params.query) : null,
    data: params.attributes
      ? _transformKeysToUnderscore(params.attributes)
      : undefined,
    file: params.file,
  };
  return request(options);
};
