import React from 'react';

import type { ThreeSixtyUserReview } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { sendReminder } from 'redux/actions/resources';

import { MenuItem } from 'components/navigation/Menus';

type Props = {
  userReview: ThreeSixtyUserReview;
};

const RemindPeersFeedbackAction = ({ userReview }: Props) => {
  const dispatch = useAppDispatch();
  const sendFeedbackReminderToPeers = () => {
    dispatch(
      sendReminder(userReview.id, 'three_sixty_reminder_for_peer_feedback')
    );
  };

  return (
    <MenuItem onClick={sendFeedbackReminderToPeers}>
      {__('Remind the peers to complete their feedback')}
    </MenuItem>
  );
};

export default RemindPeersFeedbackAction;
