import React from 'react';

type Props = {
  render: (isHovered: boolean) => React.ReactNode;
  componentType?: string;
};

type State = {
  isHovered: boolean;
};

export default class Hoverable extends React.Component<Props, State> {
  state = {
    isHovered: false,
  };
  render() {
    const Component = this.props.componentType || 'span';

    return (
      // @ts-ignore
      <Component
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
      >
        {this.props.render(this.state.isHovered)}
      </Component>
    );
  }
}
