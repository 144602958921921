import React from 'react';

import { __ } from 'helpers/i18n';

import RefreshLink from 'components/links/RefreshLink';
import SupportLink from 'components/links/SupportLink';

export default function DefaultErrorMessage() {
  return (
    <React.Fragment>
      <b>{__('Oops, something went wrong 🙁')}</b>
      <p>
        {__(
          'Please try to %1 or %2.',
          <RefreshLink>{__('refresh the page')}</RefreshLink>,
          <SupportLink>{__('contact us')}</SupportLink>
        )}
      </p>
    </React.Fragment>
  );
}
