import React from 'react';

import type { TitleBlockContent } from 'models';

import { __ } from 'helpers/i18n';

import BlockDetails from 'scenes/components/review/BlockDetails';

type Props = {
  content: TitleBlockContent;
  withoutSectionSeparator?: boolean;
};

export default function TitleBlockContentComponent({
  content,
  withoutSectionSeparator,
}: Props) {
  const { title, description, id, richTextEnabled } = content;

  return (
    <BlockDetails
      id={id}
      section={__('Questions')}
      title={title}
      description={description}
      withoutSectionSeparator={withoutSectionSeparator}
      withRichText={richTextEnabled}
    />
  );
}
