import { throttle } from 'lodash';
import React from 'react';

type Props<T> = {
  render: (onChange: (args: T) => unknown) => React.ReactNode;
  onChange: (args: T) => unknown;
  doPersist: () => Promise<void> | void;
  beforeThrottle?: () => void;
};

type State = {
  persisting: boolean;
};

export default class Throttler<T> extends React.Component<Props<T>, State> {
  state = {
    persisting: false,
  };

  doPersistTimeout: ReturnType<typeof setTimeout> | undefined | null = null;

  persist = async () => {
    if (this.state.persisting) {
      this.throttledPersist();
      return;
    }

    this.setState({ persisting: true });

    try {
      await this.props.doPersist();
    } finally {
      this.setState({ persisting: false });
    }
  };

  throttledPersist = throttle(this.persist, 2000, {
    leading: false,
  });

  afterEditTimeout = async () => {
    this.props.beforeThrottle && this.props.beforeThrottle();
    this.throttledPersist();
  };

  onChange = (args: T): void => {
    this.props.onChange.apply(null, [args]);

    if (this.doPersistTimeout) clearTimeout(this.doPersistTimeout);
    this.doPersistTimeout = setTimeout(this.afterEditTimeout, 500);
  };

  render() {
    return this.props.render(this.onChange);
  }
}
