import { flatten } from 'lodash';
import React, { ReactNode } from 'react';

import Dropdown from './Dropdown';
import DropdownDivider from './DropdownDivider';
import DropdownMenu from './DropdownMenu';
import DropdownTrigger from './DropdownTrigger';

export type GroupedMenuItems = {
  [groupName: string]: Array<React.ReactNode>;
};

type Props = {
  renderTrigger: (dropdownOpened: boolean) => React.ReactNode;
  menuItems: GroupedMenuItems;
};

export default function GroupedDropdown({ renderTrigger, menuItems }: Props) {
  const actionCount = flatten(Object.values(menuItems)).length;
  if (actionCount === 0) return null;

  const children: ReactNode[] = [];

  for (let groupName in menuItems) {
    const groupMenuItems = menuItems[groupName];

    if (groupMenuItems.length === 0) continue;

    const divider = <DropdownDivider key={`divider-${groupName}`} />;

    if (children.length !== 0) {
      children.push(divider);
    }

    for (let menuItem of groupMenuItems) {
      children.push(menuItem);
    }
  }

  return (
    <Dropdown align="right">
      <DropdownTrigger render={renderTrigger} />
      <DropdownMenu>{children}</DropdownMenu>
    </Dropdown>
  );
}
