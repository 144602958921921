import React from 'react';

import type { InstructionsBlockContent } from 'models';

import BlockDetails from 'scenes/components/review/BlockDetails';

type Props = {
  content: InstructionsBlockContent;
};

export default function InstructionsBlockContentComponent({ content }: Props) {
  const { description, id, richTextEnabled } = content;

  return (
    <div className="instructions-block">
      <BlockDetails
        id={id}
        description={description}
        withoutSectionSeparator
        withRichText={richTextEnabled}
      />
    </div>
  );
}
