import React from 'react';

import { __ } from 'helpers/i18n';

import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  FeatureFlagged,
  Icon,
} from 'components';

import { type AdditionType } from './';

type Props = {
  disabled: boolean;
  onClick: (type: AdditionType) => void;
};

const DropdownButton = ({ disabled, onClick }: Props) => {
  return (
    <Dropdown align="left" additionalClassName="add-area-section">
      <DropdownTrigger
        additionalClassName="templates-dropdown-trigger"
        disabled={disabled}
        render={dropdownIsOpened => (
          <Button
            isOutlined={!dropdownIsOpened}
            additionalClassName="builder-create-button add-area-button"
            isRounded
            hasIconOnly
            disabled={disabled}
            disabledExplanation={__(
              'This skills matrix can not contain any more skills'
            )}
            tooltipProps={{
              triggerAdditionalClassName: `add-area-button-tooltip`,
            }}
          >
            <Icon name="add" />
          </Button>
        )}
      />
      <DropdownMenu>
        <DropdownMenuItem onClick={() => onClick('import')}>
          {__('Add a skill')}
        </DropdownMenuItem>
        <FeatureFlagged flag="skillsDomains">
          <DropdownMenuItem onClick={() => onClick('import_domain')}>
            {__('Add a skills domain')}
          </DropdownMenuItem>
        </FeatureFlagged>
        <DropdownMenuItem onClick={() => onClick('create')}>
          {__('Create a skill')}
        </DropdownMenuItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropdownButton;
