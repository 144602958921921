import { TargetedCampaignRole } from 'models';

import { __ } from 'helpers/i18n';

export const displayableRoleName = (
  role: TargetedCampaignRole['name']
): string => {
  if (role === 'targeted_campaign_admin') {
    return __('Campaign administrator');
  } else if (role === 'targeted_campaign_supervisor') {
    return __('Campaign supervisor');
  }
  throw new Error('Unknown role');
};

export const roleDescription = (role: TargetedCampaignRole['name']): string => {
  if (role === 'targeted_campaign_admin') {
    return __('Manages the campaign and accesses the content of the reviews');
  } else if (role === 'targeted_campaign_supervisor') {
    return __(
      'Manages the campaign without accessing the content of the reviews'
    );
  }
  throw new Error('Unknown role');
};
