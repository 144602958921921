import React from 'react';

import { __ } from 'helpers/i18n';

import { Checkbox, WithSavingStatusRecorder } from 'components';

type Props = {
  value: boolean;
  isDisabled?: boolean;
  onChange: (value: boolean) => Promise<void>;
};

const MandatoryOption = ({ value, isDisabled, onChange }: Props) => {
  return (
    <WithSavingStatusRecorder
      onChange={() => onChange(!value)}
      render={autoSavingOnchange => (
        <Checkbox
          label={__('Required')}
          size="small"
          isChecked={!isDisabled && value}
          disabled={isDisabled}
          onChange={autoSavingOnchange}
        />
      )}
    />
  );
};

export default MandatoryOption;
