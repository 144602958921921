import React, { Fragment } from 'react';

import { ReviewCycle } from 'models/ReviewCycle';

import { Column, Icon } from 'components';

type Props = {
  reviewCycle: ReviewCycle;
};

const IconColumn = ({ reviewCycle }: Props) => {
  const shouldShowReviewCategorizationIcons =
    reviewCycle.reviewCategorizationEnabled;

  return (
    <Fragment>
      {shouldShowReviewCategorizationIcons ? (
        <Column
          isNarrow
          isVerticallyCentered
          additionalClassName="min-w-14"
        ></Column>
      ) : (
        <Column isNarrow additionalClassName="min-w-14 text-center">
          <Icon isInfo name="do_not_disturb_on" />
        </Column>
      )}
    </Fragment>
  );
};

export default IconColumn;
