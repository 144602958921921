import type {
  Evaluation,
  EvaluationStatus,
  OneOnOneUserReview,
  ThreeSixtyUserReview,
  User,
  UserReview,
} from 'models';

import invariant from 'helpers/invariant';

export const getPendingEvaluations = (
  evaluations: Array<Evaluation>
): Array<Evaluation> =>
  evaluations.filter(evaluation => evaluation.status !== 'shared');

export const evaluationSharedOrSigned = (evaluation: Evaluation) =>
  ['shared', 'signed'].includes(evaluation.status);

export const allEvaluationSharedOrSigned = (userReview: OneOnOneUserReview) => {
  const { evaluations, hasSelfEvaluationEnabled } = userReview;

  const revieweeEvaluation = evaluations.find(
    e => e.reviewerRole === 'reviewee'
  );
  invariant(revieweeEvaluation, 'revieweeEvaluation must be defined');

  const reviewerEvaluation = evaluations.find(
    e => e.reviewerRole === 'reviewer'
  );
  invariant(reviewerEvaluation, 'reviewerEvaluation must be defined');

  return (
    evaluationSharedOrSigned(reviewerEvaluation) &&
    (!hasSelfEvaluationEnabled || evaluationSharedOrSigned(revieweeEvaluation))
  );
};

export const getSelectedPeers = (evaluations: Array<Evaluation>): Array<User> =>
  evaluations
    .filter(evaluation => evaluation.reviewerRole !== 'reviewee')
    .map(evaluation => evaluation.reviewer);

export const getEvaluationsByStatus = (
  userReview: UserReview,
  status: EvaluationStatus
): Array<Evaluation> =>
  userReview.evaluations.filter(evaluation => evaluation.status === status);

export const getRevieweeEvaluation = (userReview: UserReview) => {
  let evaluations;
  if (userReview.evaluations) {
    evaluations = userReview.evaluations;
  } else {
    return;
  }
  return evaluations.find(evaluation => evaluation.reviewerRole === 'reviewee');
};

export const getPeersEvaluationsNotShared = (
  userReview: ThreeSixtyUserReview
): Array<Evaluation> | null | undefined => {
  let evaluations;
  if (userReview.evaluations) {
    evaluations = userReview.evaluations;
  } else {
    return null;
  }
  return evaluations.filter(
    evaluation =>
      evaluation.reviewerRole !== 'reviewee' && evaluation.status !== 'shared'
  );
};
