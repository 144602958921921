import React, { ReactNode } from 'react';

import colors from '../styles/colors';

type SelectTokenProps = {
  children: ReactNode;
  onClick?: () => void;
  style?: any;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  onDelete?: () => void;
};

export const SelectToken = ({
  onMouseEnter,
  onMouseLeave,
  onMouseDown,
  onMouseUp,
  onClick,
  onDelete,
  children,
  style,
}: SelectTokenProps) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <span
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onMouseDown={onMouseDown}
    onMouseUp={onMouseUp}
    style={{
      padding: 8,
      marginRight: 8,
      marginBottom: 8,
      borderRadius: 4,
      textTransform: 'none',
      backgroundColor: colors.lightestGrey,
      ...(style || {}),
    }}
  >
    {children}
    {onDelete && <button onClick={onDelete} className="delete" />}
  </span>
);

type SelectTokenActionProps = { onClick: () => void; children: ReactNode };
type SelectTokenActionState = { isHovered: boolean; isActive: boolean };
export class SelectTokenAction extends React.Component<
  SelectTokenActionProps,
  SelectTokenActionState
> {
  state = {
    isHovered: false,
    isActive: false,
  };

  getStyle() {
    const baseStyle = {
      background: 'transparent',
      cursor: 'pointer',
      lineHeight: '1.25',
    };

    if (this.state.isActive) {
      return {
        ...baseStyle,
        borderColor: '#cdcdcd',
        color: '#8e8e8e',
      };
    } else if (this.state.isHovered) {
      return {
        ...baseStyle,
        borderColor: '#dddddd',
        color: '#9e9e9e',
      };
    } else {
      return {
        ...baseStyle,
        borderColor: '#ededed',
        color: '#aeaeae',
      };
    }
  }

  render() {
    const { children, onClick } = this.props;

    return (
      <SelectToken
        onClick={onClick}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
        onMouseDown={() => this.setState({ isActive: true })}
        onMouseUp={() => this.setState({ isActive: false })}
        style={this.getStyle()}
      >
        {children}
      </SelectToken>
    );
  }
}
