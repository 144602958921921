import classNames from 'classnames';
import React from 'react';

import { Avatar, Text } from 'components';

type Props = {
  message: string;
  userAvatarUrl: string | null;
  userName: string;
  alignAvatar: 'left' | 'right';
};

const MessageBox = ({
  message,
  userAvatarUrl,
  userName,
  alignAvatar,
}: Props) => {
  const containerClass = alignAvatar === 'left' ? '' : 'flex-row-reverse';
  const usernameClass =
    alignAvatar === 'left'
      ? 'py-2 px-4 bg-primary rounded-2xl text-white font-bold'
      : 'font-bold';
  return (
    <div className={classNames('flex my-4 gap-4', containerClass)}>
      <Avatar url={userAvatarUrl} size="normal" style={{ alignItems: 'end' }} />

      <div className="bg-ui-feedback-bg rounded px-5 py-5 flex-grow">
        <Text preset="14s6" additionalClassName="mb-3 block">
          <span className={usernameClass}>{userName}</span>
        </Text>

        <Text preset="14s6">{message}</Text>
      </div>
    </div>
  );
};

export default MessageBox;
