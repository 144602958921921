import { useState } from 'react';

import { handleFormErrors } from 'helpers/api';

type FormValues = {
  [key: string]: any;
};

export type FormErrors = {
  [key: string]: string | Array<string>;
};

type FormSubmitHandler<TFormValues extends FormValues> = (
  values: TFormValues
) => Promise<void>;

export type FormInputChangeHandler<TFormValues extends FormValues> = <
  TName extends keyof TFormValues
>(params: {
  value: TFormValues[TName];
  name: TName;
}) => void;

export const useForm = <TFormValues extends FormValues>(
  initialValues: TFormValues,
  onSubmit: FormSubmitHandler<TFormValues>
) => {
  const [values, setValues] = useState<TFormValues>(initialValues);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [errors, setErrors] = useState<FormErrors>({});

  const handleInputChange: FormInputChangeHandler<TFormValues> = ({
    value,
    name,
  }) => {
    setValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
    if (values[name] !== value) {
      setIsDirty(true);
    }
  };

  const handleSubmit = () =>
    handleFormErrors(async () => {
      setIsDirty(false);
      await onSubmit(values);
      setErrors({});
    }, setErrors);

  return {
    values,
    errors,
    handleInputChange,
    handleSubmit,
    isDirty,
    setIsDirty,
  };
};
