import React from 'react';

import { Column, Columns, Flex, Radio } from 'components';

type Props = {
  label: string;
  name?: string;
  selectedValue: string | null | undefined;
  values: string[];
  onChange: (value: string | null) => void;
};

export default function RadioSelect({
  label,
  name,
  selectedValue,
  values,
  onChange,
}: Props) {
  return (
    <Columns isMobile>
      <Column testClassName="test-radio-select-label" size={4}>
        {label}
      </Column>
      <Column size={8}>
        <Flex style={{ justifyContent: 'space-around', paddingTop: 2 }}>
          {values.map(value => (
            <Radio
              key={value}
              label=""
              inputLabel={label + ' ' + value}
              name={name}
              isChecked={selectedValue === value}
              onChange={() => {
                if (value === selectedValue) onChange(null);
                else onChange(value);
              }}
              dataTestValue={value}
            />
          ))}
        </Flex>
      </Column>
    </Columns>
  );
}
