import React, { Fragment } from 'react';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import {
  CheckboxSwitch,
  Level,
  LevelLeft,
  LevelRight,
  Text,
  Title5,
  WithSavingStatusRecorder,
} from 'components';
import Testable from 'components/behaviors/Testable';

type Props = {
  signatureModuleEnabled: boolean;
  onChange: (value: boolean) => Promise<void>;
  disabled: boolean;
  templateId: string;
};

export default function SignatureModule({
  signatureModuleEnabled,
  onChange,
  disabled,
  templateId,
}: Props) {
  return (
    <Fragment>
      <Level>
        <LevelLeft>
          <Title5>{__('Signature')}</Title5>
        </LevelLeft>
        <LevelRight>
          <WithSavingStatusRecorder
            fieldUid={compositeKey({
              templateId: templateId,
              field: 'signature_module',
            })}
            onChange={(value: boolean) => onChange(value)}
            render={autoSavingOnchange => (
              <Testable name="test-template-signature-setting">
                <CheckboxSwitch
                  disabled={disabled}
                  value={signatureModuleEnabled}
                  onChange={newValue => autoSavingOnchange(newValue)}
                  size="small"
                />
              </Testable>
            )}
          />
        </LevelRight>
      </Level>
      <div style={{ marginTop: 8 }}>
        <Text color="grey">
          {__('Enables signature, only people who have shared can sign.')}
        </Text>
      </div>
    </Fragment>
  );
}
