import React, { ReactNode } from 'react';
import posed, { PoseGroup } from 'react-pose';

export const FadeinFadeoutListItem = posed.div({
  enter: {
    opacity: 1,
    transition: { duration: 300 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 300 },
  },
});

type Props = {
  children: React.ComponentProps<typeof PoseGroup>['children'] & ReactNode;
};

export default function FadeinFadeoutList({ children }: Props) {
  return <PoseGroup flipMove={false}>{children}</PoseGroup>;
}
