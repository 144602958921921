import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Redirect, Route, Switch } from 'components';

import Course from './Course';
import Courses from './Courses';

export default function Routes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact component={Courses} />
      <Route path={`${path}/:courseId`} component={Course} />

      <Redirect to={`${path}`} />
    </Switch>
  );
}
