import React from 'react';

import type { InteractionType } from 'models';
import type { UpdateReviewCycle } from 'scenes/admin/reviewCycles/Setup/Edit/index';

import transformProps from 'helpers/transformProps';

import Step1Name from '../../components/Step1Name';

type Props = {
  name: string;
  interactionType: InteractionType | undefined | null;
  updateReviewCycle: UpdateReviewCycle;
  goToNextStep: () => void;
};

function propsTransformer({ updateReviewCycle, goToNextStep }: Props) {
  return {
    onContinueClick: async (params: {
      name: string;
      interactionType: InteractionType | undefined | null;
    }) => {
      await updateReviewCycle(params);

      return goToNextStep();
    },
  };
}

export default transformProps(propsTransformer)(
  Step1Name
) as React.ComponentType<Props>;
