import React, { useState } from 'react';

import { User } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { put } from 'redux/actions/api';

import { ConfirmationModal, Text } from 'components';

import UserPicker from 'scenes/components/UserPicker';

type Props = {
  peopleUserReviewId: string;
  reviewee: User;
  reviewer: User;
  onClose: () => void;
  refetchData: () => void;
  peopleReviewCycleName: string;
};

const ChangeReviewerModal: React.FC<Props> = ({
  peopleUserReviewId,
  reviewer,
  reviewee,
  onClose,
  refetchData,
  peopleReviewCycleName,
}: Props) => {
  const [newReviewer, setNewReviewer] = useState<User>(reviewer);
  const dispatch = useAppDispatch();

  const updateReviewer = async () => {
    await dispatch(
      put(`/people_user_reviews/${peopleUserReviewId}`, {
        reviewerId: newReviewer.id,
      })
    );
    refetchData();
    onClose();
  };

  return (
    <ConfirmationModal
      isActive
      isLarge
      onCancel={onClose}
      onConfirm={updateReviewer}
      title={__('Update reviewer for %1’s people review', reviewee.firstName)}
      refreshContentOnOpening={false}
    >
      <Text>
        {__(
          'The reviewer can assess and review the entire people review %1 for %2.',
          peopleReviewCycleName,
          reviewee.firstName
        )}
      </Text>
      <UserPicker
        value={newReviewer}
        onChange={user => {
          invariant(
            !Array.isArray(user),
            "There shouldn't be more than one selected user."
          );

          !!user && setNewReviewer(user);
        }}
        disabledUserIds={[reviewee.id, reviewer.id]}
        style={{ margin: 8 }}
        inModal
      />
    </ConfirmationModal>
  );
};

export default ChangeReviewerModal;
