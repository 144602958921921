import React from 'react';

import type { TrainingOverallBudgetStats } from 'models';

import { __ } from 'helpers/i18n';

import { CompletionProgressBar, Divider, Text } from 'components';

type Props = {
  overallStats: TrainingOverallBudgetStats;
};

const OverallStats = ({ overallStats }: Props) => {
  const {
    provisionedBudgetCents,
    formattedProvisionedBudget,
    usedBudgetCents,
    formattedUsedBudget,
  } = overallStats;

  const completion =
    usedBudgetCents &&
    provisionedBudgetCents &&
    (usedBudgetCents * 100) / provisionedBudgetCents;

  return (
    <div>
      <Text size={7} weight="semibold">
        {__('Budget spent')}
      </Text>
      <div>
        <Text
          size={4}
          weight="semibold"
          color="primary"
          testClassName="test-total-budget-spent-amount"
        >
          {formattedUsedBudget}
        </Text>

        <Text
          size={7}
          weight="light"
          color="light"
          testClassName="test-total-budget-amount"
        >
          &nbsp;
          {__('of')}
          &nbsp;
          {formattedProvisionedBudget}
        </Text>

        {<CompletionProgressBar completion={completion} />}
      </div>

      <Divider />
    </div>
  );
};

export default OverallStats;
