import * as Sentry from '@sentry/react';

import type { AppDispatch } from '../types';
import type {
  GenericJSONAPIResponse,
  JSONAPIResource,
  JSONObject,
  SuperagentResponse,
} from 'lib/api/types';

import normalizeJsonApiResponse from 'helpers/data/normalizeJsonApiResponse';

import { RECEIVED_RESOURCES } from 'lib/dataLoader/actions';

export const receivedResourcesAndRelationships = (
  resources: {
    [type: string]: {
      [id: string]: {};
    };
  },
  relationships: {
    [type: string]: {
      [id: string]: {
        [relatedToType: string]: {
          id: string;
          type: string;
        };
      };
    };
  }
) => {
  return {
    type: RECEIVED_RESOURCES,
    resources,
    relationships,
  };
};

type Resource = {
  id: string;
  receivedAt: number;
  [key: string]: any;
};

// Helpers
const dataToResource = (data: JSONAPIResource<JSONObject>): Resource => {
  return {
    ...data.attributes,
    id: data.id,
    receivedAt: Date.now(),
  };
};

const resourceFromResponse = (
  response: SuperagentResponse<GenericJSONAPIResponse>
): Resource | Array<Resource> => {
  if (Array.isArray(response.body.data)) {
    return response.body.data.map((d: JSONAPIResource<JSONObject>) =>
      dataToResource(d)
    );
  }
  return dataToResource(response.body.data);
};

const includesFromResponse = (
  response: SuperagentResponse<GenericJSONAPIResponse>
) => {
  const includes = {};

  if (response.body.included) {
    response.body.included.forEach(data => {
      const res = dataToResource(data);
      if (!includes[data.type]) includes[data.type] = [];
      includes[data.type].push(res);
    });
  }

  return includes;
};

export const storeResourcesFromResponse = (
  response: SuperagentResponse<GenericJSONAPIResponse>,
  dispatch: AppDispatch
) => {
  if (response.statusCode === 204) {
    return { resource: null, includes: null };
  }

  if (!response.body) {
    Sentry.withScope(scope => {
      scope.setExtra('debug', false);
      Sentry.captureMessage(
        `No body for request with status ${response.statusCode}`
      );
    });
    return;
  }

  // @ts-expect-error: TSFIXME: Seems hard to fix so let's ignore it for now
  const { resources, relationships } = normalizeJsonApiResponse(response.body);

  // @ts-expect-error: TSFIXME: Seems hard to fix so let's ignore it for now
  dispatch(receivedResourcesAndRelationships(resources, relationships));

  const data = resourceFromResponse(response);
  const includes = includesFromResponse(response);
  return { resource: data, includes };
};
