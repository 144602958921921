import React from 'react';

import Icon from '../Icon';
import Tooltip from '../Tooltip';

type Props = {
  warning: string;
  color?: string;
};

export default function FieldWarning({ warning, color = 'warning' }: Props) {
  return (
    <Tooltip triggerAdditionalClassName="field-warning" content={warning}>
      <Icon
        style={{ verticalAlign: 'text-bottom' }}
        color={color}
        name="warning"
      />
    </Tooltip>
  );
}
