import React, { Fragment, useContext } from 'react';

import type { SkillsAreaMapping, SkillsDomain } from 'models';

import { DataContext } from 'scenes/skills/Index/MatrixVisualizer';

import BlockRenderer from './BlockRenderer';
import useAreaAction from './useAreaAction';

export type BlockType = {
  position: number;
  domain: SkillsDomain | null;
  mappings: Array<SkillsAreaMapping>;
};

const MatrixContent = () => {
  const { matrix, editionMode } = useContext(DataContext);

  const { renderDropdownButton, renderModal } = useAreaAction(matrix);

  const blocks: Array<BlockType> = [];

  const canDisplayMapping = (mapping: SkillsAreaMapping) => {
    if (editionMode) return true;

    return !!mapping.mappedExpectations?.some(
      expectation => expectation.id !== '0'
    );
  };

  matrix.areaMappings?.filter(canDisplayMapping).forEach(mapping => {
    if (!mapping.domain) {
      blocks.push({
        position: mapping.position,
        domain: null,
        mappings: [mapping],
      });
      return;
    }

    const domain = mapping.domain;
    const domainBlockIndex = blocks.findIndex(
      block => block.domain?.id === domain.id
    );

    if (domainBlockIndex !== -1) {
      blocks[domainBlockIndex].mappings.push(mapping);
    } else {
      blocks.push({ position: mapping.position, domain, mappings: [mapping] });
    }
  });
  return (
    <Fragment>
      {editionMode && renderDropdownButton({ position: 0 })}

      {blocks.map((block, index) => (
        <BlockRenderer
          key={block.position}
          block={block}
          nextBlock={blocks[index + 1]}
          renderDropdownButton={renderDropdownButton}
        />
      ))}

      {renderModal()}
    </Fragment>
  );
};

export default MatrixContent;
