import React, { useCallback, useEffect, useState } from 'react';

import type { AppDispatch } from 'redux/actions';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { navigate, pathToTrainingPeriodRequests } from 'helpers/navigation';

import { withGenericErrorHandling } from 'lib/api/errorHandler';
import { genericFetch } from 'lib/api/genericFetch';
import { post } from 'redux/actions/api';

import {
  Button,
  Field,
  Icon,
  Label,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Select,
  Text,
} from 'components';

import { refetchTrainingPeriods } from './TrainingPeriodPicker';

type Props = {
  onClose: () => void;
};

const TrainingPeriodCreationModal = ({ onClose }: Props) => {
  const dispatch = useAppDispatch();
  const createPeriod = async (name: string) => {
    await dispatch(post('training/periods', { name }));
    await dispatch(refetchTrainingPeriods);
    navigate(pathToTrainingPeriodRequests(name));
  };
  const getNextPeriodsName = useCallback(() => {
    const getData = () => (dispatch: AppDispatch) =>
      withGenericErrorHandling(
        genericFetch({ method: 'GET', url: 'training/periods/next' })
      )(dispatch).then(response => ({ response }));
    return dispatch(getData());
  }, [dispatch]);

  const [nextPeriodNames, setNextPeriodNames] = useState<Array<string>>([]);
  const [selectedPeriodName, setSelectedPeriodName] = useState<string | null>(
    null
  );

  useEffect(() => {
    const setInitialState = async () => {
      const { response } = await getNextPeriodsName();
      setNextPeriodNames(response.body);
      setSelectedPeriodName(response.body[0]);
    };

    setInitialState();
  }, [getNextPeriodsName]);

  const onCreate = () => {
    invariant(selectedPeriodName, 'Period name must exist at this point');
    createPeriod(selectedPeriodName);

    onClose();
  };

  return (
    <ModalCard refreshContentOnOpening isActive onClose={onClose}>
      <div className="training-period-creation-modal">
        <ModalCardHead onClose={onClose}>
          <ModalCardTitle>{__('Create a new period')}</ModalCardTitle>
        </ModalCardHead>
        <ModalCardBody overflowVisible>
          <Field>
            <Text>
              {__(
                'This new period will be shared with all administrators. Once created, managers and administrators will be able to create training requests by selecting this period.'
              )}
            </Text>
          </Field>
          <Field>
            <Label>{__('Choose a period')}</Label>

            <Select
              value={{
                label: selectedPeriodName || '',
                value: selectedPeriodName || '',
              }}
              options={nextPeriodNames.map(name => ({
                label: name,
                value: name,
              }))}
              onChange={option => {
                invariant(
                  option && !Array.isArray(option),
                  'Option must exist and cannot be an array'
                );
                setSelectedPeriodName(option.value);
              }}
              isMulti={false}
              isClearable={false}
              inModal
            />
          </Field>
        </ModalCardBody>
        <ModalCardFooter>
          <div style={{ marginTop: 16 }}>
            <Button onClick={onClose} color="secondary">
              {__('Cancel')}
            </Button>
            <Button
              onClick={onCreate}
              color="primary"
              disabled={!selectedPeriodName}
            >
              <Icon style={{ marginRight: 8 }} name="add" />
              {__('Create period')}
            </Button>
          </div>
        </ModalCardFooter>
      </div>
    </ModalCard>
  );
};

export default TrainingPeriodCreationModal;
