import React, { ReactNode } from 'react';

import InlineEditableWrapper from './InlineEditableWrapper';
import TextInputRenderer, {
  type AvailableInputProps,
} from './editedRenderers/TextInputRenderer';

export type InlineEditableSharedProps<T> = {
  value: T | null | undefined;
  onSubmit: (value: T | null | undefined) => Promise<unknown> | unknown;
  renderNotEdited: () => ReactNode;
  withEditIcon?: boolean;
  autoFocus?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  onBlur?: () => any;
  onFocus?: () => any;
  displayErrorMessage?: boolean;
};

type Props = InlineEditableSharedProps<string> & {
  placeholder?: string;
  inputProps?: AvailableInputProps;
};

export default function InlineEditableTextInput({
  value,
  onSubmit,
  renderNotEdited,
  withEditIcon,
  inputProps,
  autoFocus,
  placeholder,
  hasError,
  hasWarning,
  isClearable,
  isDisabled,
  displayErrorMessage,
  onBlur,
  onFocus,
}: Props) {
  return (
    <InlineEditableWrapper
      value={value}
      isDisabled={isDisabled}
      onSubmit={onSubmit}
      renderEdited={params => (
        <TextInputRenderer
          {...params}
          placeholder={placeholder}
          isClearable={isClearable}
          displayErrorMessage={displayErrorMessage}
          {...inputProps}
        />
      )}
      renderNotEdited={renderNotEdited}
      withEditIcon={!!withEditIcon}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      hasError={hasError}
      hasWarning={hasWarning}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
}
