import URL from 'core-js/features/url';
import { useCallback, useState } from 'react';

import { castHashValuesToBoolean } from 'helpers/hash';
import queryString from 'helpers/queryString';

const buildUrl = queryParams => {
  const protocol = window.location.protocol;
  const url = new URL(`${protocol}//${window.location.host}`);

  url.pathname = window.location.pathname;
  url.search = queryParams;

  return url.toString();
};

const setQueryParamsValue = (paramName, newParams) => {
  const currentQueryParams = queryString.parse(window.location.search);
  const newQueryParams = queryString.stringify({
    ...currentQueryParams,
    [paramName]: newParams,
  });
  const newURL = buildUrl(`?${newQueryParams}`);

  // This line allow to change the URL without reloading the page
  window.history.pushState({ path: newURL }, '', newURL);
};

const getQueryParamsValue = (
  paramName: string,
  castValuesToBoolean: boolean
) => {
  const queryParams = queryString.parse(window.location.search);
  return castValuesToBoolean && typeof queryParams[paramName] === 'object'
    ? // @ts-ignore TSFIXME: Fix strictNullChecks error
      castHashValuesToBoolean(queryParams[paramName])
    : queryParams[paramName];
};

export default function useUrlPersistedState<T>(
  paramName: string,
  initialValue: T,
  castValuesToBoolean?: boolean
): [T, (arg1: T) => void] {
  const [value, setValue] = useState<T>(
    // @ts-ignore
    getQueryParamsValue(paramName, !!castValuesToBoolean) || initialValue
  );
  const onSetValue = useCallback(
    (newParams: T) => {
      setQueryParamsValue(paramName, newParams);
      setValue(newParams);
    },
    [paramName, setValue]
  );

  return [value, onSetValue];
}
