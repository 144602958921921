import type { Customization } from './types';

import { opacify } from 'styles/index';

export default function fieldsCustomization({ primaryColor }: Customization) {
  return {
    '.text-area:focus': {
      borderColor: primaryColor,
      boxShadow: `0 0 0 0.125em ${opacify(primaryColor, 0.25)}`,
    },
    '.input:focus, .textarea:focus': {
      borderColor: primaryColor,
      boxShadow: `0 0 0 0.125em ${opacify(primaryColor, 0.25)}`,
    },
  };
}
