import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import queryString from 'helpers/queryString';

import { parsePaginationParams } from 'lib/dataLoader/pagination/paginationParams';

type BaseQueryParams = {
  search: string;
  filter?: { [key: string]: boolean | { [key: string]: boolean } };
};

type Props = {
  defaultFilter?: { [key: string]: boolean };
  additionalQueryParams?: Record<string, unknown> | null;
  withUrlChange?: boolean;
  countPerPage?: number;
};

type Params<QueryParams extends BaseQueryParams = BaseQueryParams> = {
  page: number;
  countPerPage: number;
  queryParams: QueryParams;
};

const defaultParams: Params = {
  page: 1,
  countPerPage: 10,
  queryParams: {
    filter: { all: true },
    search: '',
  },
};

const buildDefaultParams = <
  QueryParams extends BaseQueryParams & AdditionalQueryParams,
  AdditionalQueryParams extends Record<string, unknown>
>(
  filter?: Partial<BaseQueryParams> | undefined,
  additionalQueryParams?: AdditionalQueryParams | null | undefined,
  countPerPage?: number,
  withUrlChange?: boolean
): Params<QueryParams> => {
  const queryParamsFromUrl = withUrlChange
    ? parsePaginationParams(queryString.parse(window.location.search))
    : null;

  return {
    page: queryParamsFromUrl?.page || defaultParams.page,
    countPerPage:
      countPerPage ||
      queryParamsFromUrl?.countPerPage ||
      defaultParams.countPerPage,
    queryParams: {
      ...defaultParams.queryParams,
      filter: filter || defaultParams.queryParams.filter,
      ...queryParamsFromUrl?.queryParams,
      ...additionalQueryParams,
    },
  };
};

const usePaginatedQuery = <
  AdditionalQueryParams extends Record<string, unknown> = {}
>({
  defaultFilter,
  additionalQueryParams,
  withUrlChange = true,
  countPerPage,
}: Props) => {
  const history = useHistory();

  // Memoize params to avoid infinite re-rendering
  const params = useMemo(
    () =>
      buildDefaultParams(
        defaultFilter,
        additionalQueryParams,
        countPerPage,
        withUrlChange
      ),
    [defaultFilter, additionalQueryParams, countPerPage, withUrlChange]
  );

  const [currentParams, setParams] = useState(params);

  const buildFullUrl = (newParams: Params): string =>
    `${window.location.pathname}?${queryString.stringify(newParams)}`;

  // This allows to update currentParams when params from props change
  useEffect(() => {
    if (JSON.stringify(currentParams) !== JSON.stringify(params)) {
      setParams(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // This updates URL when `currentParams` changes
  useEffect(() => {
    if (withUrlChange) {
      const newUrl = `${window.location.pathname}?${queryString.stringify(
        currentParams
      )}`;
      history.replace(newUrl);
    }
  }, [withUrlChange, currentParams, history]);

  const setQueryParams = (
    newQueryParams: Partial<BaseQueryParams> | Partial<AdditionalQueryParams>
  ) => {
    setParams(prevState => ({
      ...prevState,
      queryParams: { ...prevState.queryParams, ...newQueryParams },
      page: 1,
    }));
  };

  const setNextPageParams = () => {
    setParams(prevState => ({
      ...prevState,
      page: prevState.page + 1,
    }));
  };

  const setPreviousPageParams = () => {
    setParams(prevState => ({
      ...prevState,
      page: prevState.page - 1,
    }));
  };

  const getPreviousPageUrl = () => {
    buildFullUrl({
      ...params,
      page: params.page - 1,
    });
  };

  const getNextPageUrl = () => {
    return buildFullUrl({
      ...params,
      page: params.page + 1,
    });
  };

  return {
    setQueryParams,
    setPreviousPageParams,
    setNextPageParams,
    getNextPageUrl,
    getPreviousPageUrl,
    ...currentParams,
  };
};

export default usePaginatedQuery;
