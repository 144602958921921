import { __ } from 'helpers/i18n';

type Variable = {
  name: string;
  variable: string;
};

const homeMessageVariables = (): Array<Variable> => {
  return [
    {
      name: __('Active user full name'),
      variable: '[[activeUser.fullName]]',
    },
    {
      name: __('Active user first name'),
      variable: '[[activeUser.firstName]]',
    },
    {
      name: __('Active user last name'),
      variable: '[[activeUser.lastName]]',
    },
  ];
};

export default homeMessageVariables;
