import type { Customization } from './types';

import { opacify, shadeColor } from 'styles/index';

export default function buttonsCustomization({ primaryColor }: Customization) {
  return {
    '.button.is-primary': {
      backgroundColor: primaryColor,
    },
    '.button.is-primary.is-outlined': {
      color: primaryColor,
      borderColor: primaryColor,
    },
    '.button.is-primary.is-outlined[disabled]': {
      color: primaryColor,
      borderColor: primaryColor,
    },
    '.button.is-primary.is-outlined[disabled]:hover': {
      color: primaryColor,
      borderColor: primaryColor,
      backgroundColor: 'transparent',
    },
    '.button.is-primary:hover, .button.is-primary.is-hovered': {
      backgroundColor: shadeColor(primaryColor, -0.04),
    },
    '.button.is-primary.is-active': {
      backgroundColor: shadeColor(primaryColor, -0.08),
    },
    '.button.is-primary.is-active:hover': {
      backgroundColor: shadeColor(primaryColor, -0.04),
    },
    '.button.is-primary.is-outlined:hover, .button.is-primary.is-outlined.is-hovered':
      {
        backgroundColor: shadeColor(primaryColor, -0.04),
        borderColor: primaryColor,
      },
    '.button.is-primary.is-outlined:focus, .button.is-primary.is-outlined.is-focused':
      {
        backgroundColor: shadeColor(primaryColor, -0.04),
        borderColor: primaryColor,
      },
    '.button.is-primary[disabled]': {
      backgroundColor: primaryColor,
    },
    '.button.is-text': {
      color: primaryColor,
    },
    '.button:focus, .button.is-focused': {
      borderColor: primaryColor,
    },
    '.button:focus:not(:active), .button.is-focused:not(:active)': {
      boxShadow: `0 0 0 0.125em ${opacify(primaryColor, 0.25)}`,
    },
  };
}
