import React, { CSSProperties, ComponentProps, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { getPathForAbsoluteHref } from 'helpers/url';

import { Button, Loading, SavingStatusConnector } from 'components';

const absoluteCenter: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

type Props = ComponentProps<typeof Button> &
  Parameters<ComponentProps<typeof SavingStatusConnector>['render']>[0] & {
    disabledOnFailed?: boolean;
  };

const AutoSaveButton = ({
  autoSaveStatus,
  delayedAction,
  setDelayedAction,
  disabled: disabledFromProps,
  disabledOnFailed,
  to,
  onClick,
  children,
  ...otherButtonProps
}: Props) => {
  const history = useHistory();

  // Ternary allows keeping the boolean value unchanged with other statuses
  const saveFailedRef = useRef(autoSaveStatus === 'failed');
  saveFailedRef.current = saveFailedRef.current
    ? autoSaveStatus !== 'saved'
    : autoSaveStatus === 'failed';

  const disabledByParentRef = useRef(!!disabledFromProps);
  disabledByParentRef.current = !!disabledFromProps;

  const forwardButtonClick = () => {
    if (disabledByParentRef.current) return;
    if (saveFailedRef.current && disabledOnFailed) return;

    if (onClick) onClick();
    if (to) history.replace(getPathForAbsoluteHref(to));
  };

  const catchButtonClick = () => {
    switch (autoSaveStatus) {
      case 'saved':
        forwardButtonClick();
        break;
      case 'saving':
      case 'retrying':
        setDelayedAction(forwardButtonClick);
        break;
      case 'failed':
        if (!disabledOnFailed) forwardButtonClick();
        break;
    }
  };

  return (
    <Button
      disabled={
        !!delayedAction ||
        disabledFromProps ||
        (saveFailedRef.current && disabledOnFailed)
      }
      onClick={catchButtonClick}
      {...otherButtonProps}
    >
      <span className="relative inline-flex items-center justify-center">
        <span style={{ visibility: delayedAction ? 'hidden' : 'visible' }}>
          {children}
        </span>
        {!!delayedAction && (
          <Loading
            delay={0}
            size="tiny"
            color="currentColor"
            containerStyle={absoluteCenter}
          />
        )}
      </span>
    </Button>
  );
};

export default AutoSaveButton;
