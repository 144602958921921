import type { Locale, Organization } from 'models';

import { __ } from './';

export const languageForLocale = (
  locale: Locale,
  organization?: Organization
) => {
  const languagesMap = {
    fr: __('French'),
    en: __('English'),
    it: __('Italian'),
    de: __('German'),
    nl: __('Dutch'),
    es: __('Spanish'),
  };

  if (organization && locale === organization.locale) {
    // @ts-ignore
    return languagesMap[locale].concat(' ', __('(Default language)'));
  }

  return languagesMap[locale];
};

type Option = {
  value: Locale;
  label: string;
};

export const localeSelectOptions = (
  organization: Organization,
  withDefaultLocaleLabel?: boolean
): Array<Option> =>
  organization.authorizedLanguages
    .sort((a, _b) => (a === organization.locale ? -1 : 1))
    .map(
      locale =>
        ({
          label: languageForLocale(
            locale,
            withDefaultLocaleLabel ? organization : undefined
          ),
          value: locale,
        } as Option)
    );
