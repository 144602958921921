import React, { ReactNode } from 'react';

import type { TrainingOverallBudgetStats } from 'models';

import { __ } from 'helpers/i18n';

import { Box, Flex, Text, Title } from 'components';

type Props = {
  stats: TrainingOverallBudgetStats;
  link: ReactNode;
};

const BudgetCardWithCompletion = ({ stats, link }: Props) => {
  return (
    <Box additionalClassName="budget-card budget-card-without-completion">
      <div className="budget-spent">
        <Text
          align="left"
          weight="bold"
          additionalClassName="budget-spent-title"
        >
          {__('Budget spent')}
        </Text>
        <Flex additionalClassName="budget-spent-details">
          <Title
            size={4}
            weight="bold"
            color="primary"
            additionalClassName="budget-spent-amount"
            testClassName="test-budget-spent-amount"
          >
            {stats.formattedUsedBudget}
          </Title>
          {link}
        </Flex>
      </div>
    </Box>
  );
};

export default BudgetCardWithCompletion;
