import React from 'react';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToUserSharedPeerFeedbacks } from 'helpers/paths';

import { Box, BoxFooter, DesignSystem, Text } from 'components';

const FeedbackEmptyState = () => {
  const activeUser = useActiveUser();

  return (
    <DesignSystem version={2}>
      <Box style={{ textAlign: 'center' }}>
        <Text color="light" style={{ fontSize: 16 }}>
          {__('No pending request')}
        </Text>

        <BoxFooter to={pathToUserSharedPeerFeedbacks(activeUser.id)}>
          {__('See previous feedback')}
        </BoxFooter>
      </Box>
    </DesignSystem>
  );
};

export default FeedbackEmptyState;
