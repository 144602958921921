import React, { Fragment } from 'react';

import {
  DraftSurveyCampaign,
  SurveyCampaignStepName as StepName,
  SurveyCampaignUpdateParams,
} from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import { Match } from 'helpers/navigation';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, put } from 'redux/actions/api';

import { FetchContainer, PageHeader, PageTitle } from 'components';

import Stepper from 'scenes/components/Stepper';

import getSetupStepInfoList from '../helpers/steps';
import CurrentStep from './CurrentStep';
import DestroyButton from './DestroyButton';

type Props = {
  match: Match<{
    campaignId: string;
  }>;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    surveyCampaign: DraftSurveyCampaign;
  };

const EditCampaign = ({
  isFetching,
  hasError,
  surveyCampaign,
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();

  const updateCampaign = (
    params: SurveyCampaignUpdateParams,
    options?: { withDefaultErrorHandling: boolean }
  ) => dispatch(put(`survey/campaigns/${surveyCampaign.id}`, params, options));

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        return (
          <Fragment>
            <PageHeader
              title={surveyCampaign.name}
              withBackButton
              actions={
                can({ perform: 'destroy', on: surveyCampaign }) && (
                  <DestroyButton
                    surveyCampaignId={surveyCampaign.id}
                    surveyCampaignName={surveyCampaign.name}
                  />
                )
              }
            ></PageHeader>
            <PageTitle title={surveyCampaign.name} />

            <Stepper<StepName>
              currentStepIndexFromElement={surveyCampaign.setupStepIndex}
              stepInfoList={getSetupStepInfoList(surveyCampaign)}
              renderChildren={(currentStepInfo, _goToStep, goToNextStep) => (
                <CurrentStep
                  surveyCampaign={surveyCampaign}
                  currentStepInfo={currentStepInfo}
                  onUpdate={updateCampaign}
                  goToNextStep={goToNextStep}
                />
              )}
            />
          </Fragment>
        );
      }}
    />
  );
};

export default newDataLoader({
  fetch: ({ match }: Props) =>
    get(`survey/campaigns/${match.params.campaignId}`),
  hydrate: {
    surveyCampaign: {
      abilities: {},
    },
  },
})(EditCampaign) as React.ComponentType<Props>;
