import type { UserReview } from 'models';

import { __ } from 'helpers/i18n';

export const getRemoveParticipantSideEffects = (userReview: UserReview) => {
  const sideEffects = [__('Delete all feedback on this review')];

  if (
    userReview.interactionType === 'one_on_one' &&
    userReview.definesObjectives
  ) {
    sideEffects.push(__('Delete draft objectives from next period objectives'));
  }

  return sideEffects;
};
