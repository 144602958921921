import React from 'react';

import type { GlobalProgressionSeries } from 'models';

import colors from 'styles/colors';

import { __ } from 'helpers/i18n';
import { zipSeries } from 'helpers/timeSeries';

import ProgressChartBlock, {
  LineParams,
} from '../components/ProgressChartBlock';

type Props = {
  series: GlobalProgressionSeries;
};

export default function GlobalProgressionChart({ series }: Props) {
  const { data, annotations } = zipSeries(series);
  const lines: LineParams[] = [];

  if (series.peersSelectedProgress) {
    lines.push({
      dataKey: 'peersSelectedProgress',
      name: __('Peers nominated'),
      color: colors.lineChart.color1,
    });
  }

  if (series.peersNominatedProgress) {
    lines.push({
      dataKey: 'peersNominatedProgress',
      name: __('Peers nominated'),
      color: colors.lineChart.color1,
    });
  }

  if (series.peersValidatedProgress) {
    lines.push({
      dataKey: 'peersValidatedProgress',
      name: __('Peers validated'),
      color: colors.lineChart.color2,
    });
  }

  lines.push({
    dataKey: 'peersCompletedProgress',
    name: __('Feedback shared to responsible'),
    color: colors.lineChart.color3,
  });

  if (series.reviewReleasedProgress) {
    lines.push({
      dataKey: 'reviewReleasedProgress',
      name: __('Feedback shared with reviewee'),
      color: colors.lineChart.color4,
    });
  }

  return (
    <ProgressChartBlock
      title={__('Overall progression')}
      data={data}
      ordinateParams={{
        domain: [0, 100],
        ticks: [0, 20, 40, 60, 80, 100],
        unit: '%',
      }}
      annotations={annotations}
      lines={lines}
    />
  );
}
