import React from 'react';
import { Link } from 'react-router-dom';

import type { NavigationItem } from './NavigationList';

import invariant from 'helpers/invariant';

import { Icon, TabletViewPort, Testable } from 'components';

type Props = {
  item: NavigationItem;
};

const BackNavigationItem = ({ item }: Props) => {
  const { to, label } = item;

  invariant(to, 'to props is required for Link component');

  return (
    <Link to={to}>
      <Testable name="test-link back-link">
        <Icon name="keyboard_arrow_left" />
        <TabletViewPort>
          <span>{label}</span>
        </TabletViewPort>
      </Testable>
    </Link>
  );
};

export default BackNavigationItem;
