import React from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { Evaluation } from 'models';

import can from 'helpers/can';
import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import invariant, { assert } from 'helpers/invariant';
import {
  pathToTemplateEdition,
  pathToTemplatePreview,
  pathToTemplates,
} from 'helpers/navigation';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  BackButton,
  Button,
  DesignSystem,
  FetchContainer,
  Flex,
  Icon,
} from 'components';

import Topbar from 'scenes/admin/reviewTemplates/Template/components/Topbar';

import FormContent from '../components/FormContent';
import ReviewLayout from '../components/ReviewLayout';
import Sidebar from '../components/Sidebar';

type Props = {
  templateId: string;
  role: 'reviewee' | 'reviewer';
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    evaluation: Evaluation;
  };

function PreviewComponent({
  templateId,
  evaluation,
  isFetching,
  hasError,
}: AfterDataLoaderProps) {
  const refreshShareability = () => Promise.resolve();
  const shareAction = {
    isShown: true,
    isModalActive: false,
    isEnabled: false,
    refreshShareability,
  };
  const canUpdateTemplate =
    !!evaluation &&
    can({ perform: 'show_back_to_template_edition', on: evaluation });

  return (
    <DesignSystem version={2}>
      <div className="template-preview">
        <ReviewLayout
          topbar={
            <Topbar
              title={evaluation && __('Template preview')}
              backButton={
                evaluation && (
                  <BackButton
                    target={pathToTemplates(
                      evaluation.userReview?.interactionType
                    )}
                  >
                    {__('Back to templates')}
                  </BackButton>
                )
              }
              actions={
                <Flex verticalAlign>
                  {evaluation && canUpdateTemplate && (
                    <Button
                      color="secondary"
                      to={pathToTemplateEdition(templateId)}
                    >
                      <Icon style={{ marginRight: 8 }} name="edit" />
                      {__('Back to edition')}
                    </Button>
                  )}
                </Flex>
              }
              tabItems={
                evaluation && [
                  {
                    label: __('Reviewee'),
                    to: pathToTemplatePreview(templateId, 'reviewee'),
                  },
                  {
                    label: __('Reviewer'),
                    to: pathToTemplatePreview(templateId, 'reviewer'),
                  },
                ]
              }
            />
          }
          sidebar={
            <FetchContainer
              hasError={hasError}
              isFetching={isFetching}
              render={() => {
                const { formContent, userReview } = evaluation;

                return (
                  <Sidebar
                    content={assert(
                      formContent,
                      'formContent must be defined once loaded'
                    )}
                    userReview={assert(
                      userReview,
                      'userReview must be available on evaluation'
                    )}
                    isPreview
                    shareAction={shareAction}
                  />
                );
              }}
            />
          }
          content={
            <FetchContainer
              hasError={hasError}
              isFetching={isFetching}
              render={() => {
                const { formContent, userReview } = evaluation;

                invariant(
                  userReview,
                  'userReview must be available on evaluation'
                );

                return (
                  <FormContent
                    content={assert(
                      formContent,
                      'formContent must be defined once loaded'
                    )}
                    shareAction={shareAction}
                    reviewee={userReview.user}
                    userReviewId={userReview.id}
                    isPreview
                  />
                );
              }}
            />
          }
        />
      </div>
    </DesignSystem>
  );
}

export default newDataLoader({
  fetch: ({ templateId, role }) =>
    get(`review_templates/${templateId}/preview`, { role }),
  hydrate: {
    evaluation: {
      abilities: {},
      userReview: {
        abilities: {},
        user: { abilities: {} },
        responsible: {},
        reviewer: {},
        reviewCycle: {},
        evaluations: {
          reviewer: {},
        },
        meeting: { participants: {} },
      },
      reviewer: {},
      formContent: {
        messages: {},
        blocks: {
          messages: {},
          feedbackableItems: {
            item: {},
            answers: {
              author: {},
            },
          },
        },
      },
    },
  },
  cacheKey: ({ templateId, role }: Props) => {
    return compositeKey({
      templateId,
      role,
    });
  },
})(PreviewComponent) as React.ComponentType<Props>;
