import React, { Fragment } from 'react';

import type { TrainingSession } from 'models';

import can from 'helpers/can';
import { guessTimeZone } from 'helpers/date';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import { Button, Icon, PullRight, Text } from 'components';

import cancelInvitationsModal from './cancelInvitationsModal';
import sendInvitationsModal from './sendInvitationsModal';

type Props = {
  session: TrainingSession;
};

const CalendarInvitationButton = ({ session }: Props) => {
  const dispatch = useAppDispatch();

  const sendCalendarInvitations = () =>
    dispatch(
      post(
        `training/sessions/${session.id}/send_invitations`,
        {
          timezone: guessTimeZone(),
        },
        {
          successMessage: __(
            'The calendar invitation has been successfully sent'
          ),
        }
      )
    );
  const cancelCalendarInvitations = () =>
    dispatch(
      post(`training/sessions/${session.id}/cancel_invitations`, undefined, {
        successMessage: __(
          'The calendar invitation has been successfully cancelled'
        ),
      })
    );

  return (
    <Fragment>
      <PullRight style={{ gap: 16 }}>
        {can({ perform: 'cancel_invitations', on: session }) && (
          <Button
            color="secondary"
            onClick={() =>
              cancelInvitationsModal(session, cancelCalendarInvitations)
            }
          >
            <Icon name="cancel" additionalClassName="mr-1" />
            {__('Cancel the calendar invitation')}
          </Button>
        )}
        {can({ perform: 'send_invitations', on: session }) && (
          <Button
            color="primary"
            onClick={() =>
              sendInvitationsModal(session, sendCalendarInvitations)
            }
            disabled={!session.endDate || !session.startDate}
            disabledExplanation={__(
              'Add the training dates in the session details to send the invitation'
            )}
            style={{ marginLeft: 16 }}
          >
            <Icon name="send" additionalClassName="mr-1" />
            {__('Send the calendar invitation')}
          </Button>
        )}
      </PullRight>
      {session.autoInviteNewParticipants && (
        <PullRight style={{ marginTop: 8 }}>
          <div className="flex gap-2 items-center">
            <Icon color="success" name="check_circle" />
            <Text preset="13s7" color="light">
              {__(
                'The new participants will be automatically invited to the session'
              )}
            </Text>
          </div>
        </PullRight>
      )}
    </Fragment>
  );
};

export default CalendarInvitationButton;
