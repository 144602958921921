import type { Entity } from 'models';

import { n__ } from 'helpers/i18n';

export const getDeleteEntitySideEffects = ({ usersCount }: Entity) => {
  const sideEffects: Array<string> = [];

  if (usersCount > 0) {
    sideEffects.push(
      n__(
        'Detach %1 user from this entity',
        'Detach %1 users from this entity',
        usersCount
      )
    );
  }

  return sideEffects;
};
