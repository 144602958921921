import React from 'react';

import type { ThreeSixtyReviewCycle, ThreeSixtyUserReview, User } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';

import { successNotice } from 'redux/actions';
import { nominatePeers } from 'redux/actions/resources';

import ManagePeersModal from './ManagePeersModal';

type Props = {
  user: User;
  isActive: boolean;
  onClose: () => void;
  reviewCycle: ThreeSixtyReviewCycle;
  userReview: ThreeSixtyUserReview;
  peers?: Array<User>;
};

const NominatePeersModal = ({
  user,
  isActive,
  onClose,
  reviewCycle,
  userReview,
  peers,
}: Props) => {
  const dispatch = useAppDispatch();
  const onSubmit = async (selectedUsers: Array<User>) => {
    if (!userReview) return;

    await dispatch(
      nominatePeers({
        userReview: userReview,
        users: selectedUsers,
      })
    );
    await dispatch(
      successNotice({
        __html: __(
          '<b>Thanks for nominating your peers!</b><br />Your manager will have to confirm them before they can complete their review.'
        ),
      })
    );
  };

  return (
    <ManagePeersModal
      user={user}
      isActive={isActive}
      onClose={onClose}
      reviewCycle={reviewCycle}
      userReviewId={userReview.id}
      peers={peers}
      onSubmit={onSubmit}
      title={__('Select my 360° peer reviewers')}
      submitButtonLabel={(peersCount: number) =>
        n__('Nominate one peer', 'Nominate %1 peers', peersCount, peersCount)
      }
      subText={__(
        'Once your peers have been nominated, the responsible for your 360 review will receive a notification to validate them. The validation will send the feedback requests to your peers.'
      )}
    />
  );
};

export default NominatePeersModal;
