import React from 'react';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import { DesignSystem, Divider, Icon, Link, Popover, Text } from 'components';

import PeriodList from './PeriodList';
import Trigger from './Trigger';

export type Period = {
  id?: string;
  name: string;
  disabled?: boolean;
  tooltipMessage?: string;
};

type Props = {
  label?: string;
  period: Period | undefined | null;
  periods: Array<Period>;
  isDisabled?: boolean;
  isLoading?: boolean;
  onChange: (id: string) => void;
  onPeriodCreationLinkClick?: (() => void) | null;
  periodCreationLinkClassName?: string;
};

const PeriodPicker = ({
  label,
  period,
  periods,
  isDisabled,
  isLoading,
  onChange,
  onPeriodCreationLinkClick,
  periodCreationLinkClassName,
}: Props) => (
  <DesignSystem version={2}>
    <Popover
      additionalClassName={classNames('period-picker', 'test-period-picker')}
      renderTrigger={(onTrigger, isOpen) => (
        <Trigger
          label={!!period ? period.name : label}
          isDisabled={isDisabled}
          isOpen={isOpen}
          isLoading={isLoading}
          onClick={onTrigger}
        />
      )}
      render={onClose => (
        <React.Fragment>
          {label && (
            <div style={{ marginBottom: 16 }}>
              <Text weight="bold">{label}</Text>
            </div>
          )}
          <div className="period-list">
            <PeriodList
              periods={periods}
              onChange={id => {
                onChange(id);
                onClose();
              }}
            />
          </div>
          {!!onPeriodCreationLinkClick && (
            <div>
              <Divider style={{ margin: 16 }} />
              <Link
                onClick={() => {
                  onClose();
                  onPeriodCreationLinkClick();
                }}
                style={{ verticalAlign: 'middle' }}
                additionalClassName={periodCreationLinkClassName}
              >
                <Icon style={{ marginRight: 8 }} name="add" />
                <Text>{__('Create a period')}</Text>
              </Link>
            </div>
          )}
        </React.Fragment>
      )}
    />
  </DesignSystem>
);

export default PeriodPicker;
