import moment from 'moment';
import React from 'react';

import type { HistorySectionItem } from '../components/HistorySectionItem';
import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { ImportedReview, User } from 'models';
import type { ComponentType } from 'react';

import { __ } from 'helpers/i18n';
import { pathToUserImportedReviews } from 'helpers/paths';
import { addToken } from 'helpers/url';

import { createUrl } from 'lib/api';
import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Icon } from 'components';

import HistorySection, {
  HISTORY_ITEM_LIMIT,
} from '../components/HistorySection';

const createImportedReviewDownloadUrl = (reviewId: string): string =>
  addToken(createUrl(`imported_reviews/${reviewId}/download`));
const mapImportedReviewToHistoryItem = (
  review: ImportedReview
): HistorySectionItem => {
  return {
    id: review.id,
    title: review.reviewTitle,
    date: moment(review.reviewDate).format('MMMM YYYY'),
    linkTo: createImportedReviewDownloadUrl(review.id),
  };
};

type Props = {
  user: User;
};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    importedReviews: Array<ImportedReview>;
  };

function ImportedReviewHistory({
  isFetching,
  hasError,
  user,
  importedReviews,
}: AfterConnectProps) {
  const historySectionItems = importedReviews?.map(review =>
    mapImportedReviewToHistoryItem(review)
  );

  return (
    <HistorySection
      icon={<Icon name="download" />}
      title={__('Imported Reviews')}
      historySectionItems={historySectionItems}
      emptyState={__('No review has been imported')}
      seeMorePath={pathToUserImportedReviews(user.id)}
      seeMoreLabel={__('See all imported reviews')}
      isFetching={isFetching}
      hasError={hasError}
    />
  );
}

export default newDataLoader({
  fetch: ({ user }) =>
    get(`users/${user.id}/imported_reviews`, {
      limit: HISTORY_ITEM_LIMIT + 1,
    }),
  hydrate: {
    importedReviews: {},
  },
})(ImportedReviewHistory) as ComponentType<Props>;
