// @flow
import colors from './colors';

export const maxWidth = 960;
export const menuBarHeight = 64;
// padding
export const largePadding = 50;
// margins
export const largeMargin = 50;
// borders
export const thinestBorder = `solid 1px ${colors.lightGrey}`;
