import React, { CSSProperties, ReactNode } from 'react';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';
import { canGoBack, navigate, navigateBack } from 'helpers/navigation';

import {
  Button,
  DeprecatedContainerElevo,
  DesignSystem,
  Icon,
  Link,
  Testable,
  Text,
} from 'components';

export type Props = {
  target?: string | undefined | null;
  fallbackTarget?: string | undefined | null;
  children?: ReactNode;
  style?: CSSProperties;
  render?: (
    to?: string,
    onClick?: () => void,
    isDisabled?: boolean
  ) => ReactNode;
  additionalClassName?: string;
};

export function BackButton({
  target,
  fallbackTarget,
  children,
  style = {},
  render,
  additionalClassName,
}: Props) {
  let url: string | undefined = undefined;
  let useHistory = false;
  if (target) {
    url = target;
  } else if (canGoBack()) {
    useHistory = true;
  } else if (fallbackTarget) {
    url = fallbackTarget;
  }

  return (
    <DesignSystem
      render={version => {
        if (version === 1) {
          return (
            <Button
              disabled={!useHistory && !url}
              color="light"
              style={{ marginBottom: '1.5rem', zIndex: 1, ...style }}
              onClick={() =>
                useHistory
                  ? navigateBack()
                  : navigate(url as NonNullable<typeof url>)
              }
            >
              <Testable name="test-back-button">
                <Icon name="keyboard_arrow_left" />
                <span>{children || __('Back')}</span>
              </Testable>
            </Button>
          );
        }

        const onClick = useHistory ? navigateBack : undefined;
        const disabled = !useHistory && !url;

        const defaultRenderer = (to, onClick, disabled) => (
          <Link
            to={to}
            additionalClassName={classNames(
              'back-button',
              'test-back-button',
              additionalClassName
            )}
            disabled={disabled}
            onClick={onClick}
          >
            <Text size={7}>
              <Icon
                style={{ marginRight: 8, marginBottom: 1 }}
                name="arrow_back"
              />
              <span>{children || __('Back')}</span>
            </Text>
          </Link>
        );

        return (render || defaultRenderer)(url, onClick, disabled);
      }}
    />
  );
}

type TargetProvider<T> = (props: T) => string | undefined | null;

const withBackButton =
  <TProps,>(targetOrTargetProvider?: string | TargetProvider<TProps>) =>
  (WrappedComponent: React.ComponentType<any>) => {
    return class extends React.Component<TProps> {
      render() {
        let target: string | undefined | null;

        if (
          typeof targetOrTargetProvider === 'string' ||
          typeof targetOrTargetProvider === 'undefined'
        ) {
          target = targetOrTargetProvider;
        } else {
          target = targetOrTargetProvider(this.props);
        }

        return (
          <DeprecatedContainerElevo>
            <BackButton target={target} />
            <WrappedComponent {...this.props} />
          </DeprecatedContainerElevo>
        );
      }
    };
  };

export default withBackButton;
