import React from 'react';

import { __ } from 'helpers/i18n';

import { Box, BoxSeparator, Text, Title6 } from 'components';
import Testable from 'components/behaviors/Testable';

import reviewTemplateVariables from 'scenes/admin/reviewTemplates/Template/Edit/Structure/reviewTemplateVariables';

type Props = {
  headerMessage: string | undefined | null;
  onChange: (headerMessage: string) => Promise<void>;
  withRichText?: boolean;
};

const HeaderMessage = ({ headerMessage, onChange, withRichText }: Props) => {
  const mergeTags = [{ trigger: '[', tags: reviewTemplateVariables() }];

  return (
    <div className="review-block">
      <Box
        additionalClassName="header-message"
        testClassName="test-header-message-panel"
      >
        <Title6 transformation="uppercase">{__('Header message')}</Title6>

        <BoxSeparator style={{ margin: '16px 0' }} />

        <Testable name="test-header-message-field">
          <Text
            size={6}
            isEditable
            editableOptions={{
              type: 'multilineText',
              onChange,
              value: headerMessage,
              placeholder: __('Add a header message'),
              fieldProps: {
                mergeTags: mergeTags,
                richText: withRichText,
                hideSaveButton: true,
              },
            }}
          />
        </Testable>
      </Box>
    </div>
  );
};

export default HeaderMessage;
