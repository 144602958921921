import React from 'react';

import { __ } from 'helpers/i18n';

import { Icon, Text } from 'components';

const EmptyState = () => {
  return (
    <div className="h-[300px] flex flex-col justify-center items-center">
      <div className="w-12 h-12 mb-4 bg-gray-200 rounded-full flex justify-center items-center">
        <Icon name="equalizer" />
      </div>

      <Text preset="14bs6">{__('No answer')}</Text>
    </div>
  );
};

export default EmptyState;
