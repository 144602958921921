import React, { Fragment } from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';

import { SurveyForm, UserFilterSegment } from 'models';

import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Title5 } from 'components';

import Question from './Question';

type Props = {
  campaignId: string;
  formId: string | null | undefined;
  periodId: string | null | undefined;
  segment: UserFilterSegment | null;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    form: SurveyForm;
  };

const FormStats = ({
  campaignId,
  periodId,
  segment,
  form,
}: AfterDataLoaderProps) => {
  const questions = !!form
    ? form.steps.filter(step => !!step.question).map(step => step.question)
    : [];

  return (
    <Fragment>
      <Title5 additionalClassName="mb-4">{__('Results overview')}</Title5>

      {questions.map((question, index) => (
        <Question
          key={question.id}
          questionNumber={index + 1}
          question={question}
          campaignId={campaignId}
          periodId={periodId}
          segment={segment}
        />
      ))}
    </Fragment>
  );
};

export default newDataLoader({
  fetch: ({ formId }: Props) => get(`survey/forms/${formId}`),
  hydrate: {
    form: {
      steps: {
        question: {},
        instruction: {},
      },
    },
  },
  cacheKey: ({ formId }: Props) => formId,
})(FormStats);
