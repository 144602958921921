// @flow
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from '../redux/reducers';
import { isDevelopment } from './environment';

function configureStore(initialState: {}) {
  const middlewares = [thunk];

  let composeEnhancers = compose;
  if (isDevelopment() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  return store;
}

export default configureStore({});
