import { mapKeys, snakeCase } from 'lodash';

import { addToken } from 'helpers/url';

import { createUrl } from 'lib/api';

type RedirectParams = {
  intent: 'catalog' | 'training_request_details' | 'training_plan';
  trainingRequestId?: string;
};

export function pathToSimbelRedirection(params: RedirectParams): string {
  return addToken(
    createUrl(
      'simbel/redirect',
      true,
      mapKeys(params, (_v, k) => snakeCase(k))
    )
  );
}
