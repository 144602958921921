import React, { Fragment, useState } from 'react';

import type { TrainingSession, TrainingSessionFundingItem } from 'models';

import { FormErrors, handleFormErrors } from 'helpers/api';
import can from 'helpers/can';
import compositeKey from 'helpers/compositeKey';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { put } from 'redux/actions/api';

import {
  Box,
  Notification,
  PullRight,
  SavingStatusLabel,
  StrictlySanitizedHtml,
  Text,
  WithSavingStatusRecorder,
} from 'components';

import EditableFields from './EditableFields';
import Participants from './Participants';
import SessionFundingSection from './SessionFundingSection';
import Stats from './Stats';
import incompleteFundingsWarningMessage from './helpers/incompleteFundingsWarningMessage';

type Props = { session: TrainingSession };

export type UpdatableAttributes = {
  costComputationType: TrainingSession['costComputationType'];
  sameFundingPerParticipant: boolean;
  totalBudgetCents: number | null;
  costPerParticipantCents: number | null;
  fundingItemsAttributes: Array<Partial<TrainingSessionFundingItem>>;
};

const Cost = ({ session }: Props) => {
  const [errors, setErrors] = useState<FormErrors>({});
  const [shouldRefetchParticipants, setShouldRefetchParticipants] = useState(0);
  const [shouldRefetchStats, setShouldRefetchStats] = useState(0);
  const dispatch = useAppDispatch();

  const persistSessionChanges = async (
    attributes: Partial<UpdatableAttributes>
  ) => {
    setErrors({});
    await handleFormErrors(
      () =>
        dispatch(
          put(`training/sessions/${session.id}`, {
            trainingSession: {
              ...attributes,
            },
          })
        ),
      setErrors,
      true
    );

    if (!Object.keys(attributes).includes('fundingItemsAttributes')) {
      setShouldRefetchStats(shouldRefetchStats + 1);
    }

    if (Object.keys(attributes).includes('totalBudgetCents')) {
      setShouldRefetchParticipants(shouldRefetchParticipants + 1);
    }
  };
  const cannotUpdateSession = !can({
    perform: 'update',
    on: session,
  });
  const warningMessage = incompleteFundingsWarningMessage(session);

  return (
    <Fragment>
      <Stats sessionId={session.id} shouldRefetchStats={shouldRefetchStats} />
      <Box>
        <PullRight style={{ marginBottom: 8 }}>
          <SavingStatusLabel
            failedText={() => __('The training session could not be updated')}
          />
        </PullRight>
        <WithSavingStatusRecorder
          fieldUid={compositeKey({
            trainingSessionId: session.id,
            type: 'training_session',
          })}
          onChange={persistSessionChanges}
          render={autoSavingOnChange => (
            <Fragment>
              <EditableFields
                session={session}
                onChange={autoSavingOnChange}
                errors={errors}
                disabled={cannotUpdateSession}
              />
              {!!warningMessage && (
                <Notification kind="warning" icon="warning">
                  <Text preset="13s7" additionalClassName="block">
                    <StrictlySanitizedHtml html={warningMessage} />
                  </Text>
                </Notification>
              )}
              {session.sameFundingPerParticipant ? (
                <SessionFundingSection
                  key={`funding-${session.costComputationType}`}
                  periodSlug={session.period.slug}
                  fundingItems={session.fundingItems || []}
                  errors={errors}
                  disabled={cannotUpdateSession}
                  onChange={fundingItems =>
                    autoSavingOnChange({
                      fundingItemsAttributes: fundingItems.map(item => ({
                        ...item,
                        fundingSourceId: item.fundingSource.id,
                        costComputationType: session.costComputationType,
                      })),
                    })
                  }
                />
              ) : (
                <Participants
                  sessionId={session.id}
                  sessionName={session.name || __('Untitled session')}
                  periodSlug={session.period.slug}
                  paginationType="state"
                  defaultPaginationParams={{
                    sort: { participant_name: 'asc' },
                  }}
                  shouldRefetchData={shouldRefetchParticipants}
                />
              )}
            </Fragment>
          )}
        />
      </Box>
    </Fragment>
  );
};

export default Cost;
