import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'helpers/classNames';
import invariant from 'helpers/invariant';

import { Icon } from 'components';

type Props = {
  nextPageLink?: string;
  getNextPage?: () => void;
  isFetching: boolean;
  hasNext: boolean;
  isPrimary: boolean;
};

export default function NextPageButton({
  nextPageLink,
  getNextPage,
  isFetching,
  hasNext,
  isPrimary,
}: Props) {
  invariant(
    nextPageLink || getNextPage,
    'Either getNextPage or nextPageLink props should be passed down to component'
  );

  if (nextPageLink) {
    return (
      <Link
        className={classNames('button is-small is-outlined', {
          'is-primary': isPrimary,
        })}
        // TSFIXME: Link (and anchor) doesn't have a disabled prop but we have
        // a CSS rule .button[disabled] that does the trick.
        // Ideally, we could use only use the CSS rule '.button.disabled' or
        // use our Link or Button component here
        // @ts-ignore
        disabled={isFetching || !hasNext}
        to={nextPageLink}
        onClick={e => (isFetching || !hasNext) && e.preventDefault()}
      >
        <Icon size="small" name="arrow_forward" />
      </Link>
    );
  }
  return (
    <button
      className={classNames('button is-small is-outlined', {
        'is-primary': isPrimary,
      })}
      disabled={isFetching || !hasNext}
      onClick={e => {
        e.preventDefault();
        if (isFetching || !hasNext) return;
        getNextPage && getNextPage();
      }}
    >
      <Icon size="small" name="arrow_forward" />
    </button>
  );
}
