import React, { useState } from 'react';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Icon,
  Text,
} from 'components';
import ImageUploader from 'components/ImageUploader';

type Props = {
  url: string | undefined | null;
  onUpload: (base64Image: string) => Promise<void>;
  onDelete: () => Promise<void>;
};

export default function AvatarImageUploader({
  url,
  onUpload,
  onDelete,
}: Props) {
  const [showDropdown, setShowDropdown] = useState(false);

  const imageUploaderActions = (_errors, onImageUpload) => {
    return url ? (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        className={classNames('avatar-upload-overlay', {
          show: showDropdown,
        })}
        onClick={() => {
          if (!showDropdown) {
            setShowDropdown(true);
          }
        }}
      >
        <Dropdown
          isOpened={showDropdown}
          onClose={() => setShowDropdown(false)}
        >
          <DropdownTrigger style={{ outline: 'none' }}>
            <Icon name="photo_camera" />
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownMenuItem key="update" onClick={onImageUpload}>
              {__('Change profile picture')}
            </DropdownMenuItem>
            <DropdownMenuItem key="delete" onClick={onDelete}>
              <Text color="danger">{__('Delete profile picture')}</Text>
            </DropdownMenuItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    ) : (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div className="avatar-upload-overlay" onClick={onImageUpload}>
        <Icon name="photo_camera" />
      </div>
    );
  };

  return (
    <ImageUploader
      onUpload={onUpload}
      onDelete={onDelete}
      cropShapeAspect={1}
      minZoom={0.2}
      modalTitle={__('Edit profile picture')}
      uploadErrorMessage={__(
        'Profile picture must be in one of the following formats %1 .png .jpeg or .gif %1 with a maximal size of 5Mo'
      )}
      imageUploaderRender={imageUploaderActions}
    />
  );
}
