import React from 'react';

import { __ } from 'helpers/i18n';

import { TextPreset } from 'components/text/Text';

import StepContainer from '../StepContainer';
import SummaryItem, { Props as ItemProps } from './SummaryItem';

type Props = {
  items: Array<ItemProps>;
  children: React.ReactNode;
  titlePreset?: TextPreset;
};

const SummaryStep = ({ items, children, titlePreset }: Props) => (
  <StepContainer
    title={__('Summary')}
    stepName="summary"
    titlePreset={titlePreset}
  >
    <div className="summary-list">
      {items.map(item => (
        <SummaryItem key={item.label} {...item} />
      ))}
    </div>

    {children}
  </StepContainer>
);

export default SummaryStep;
