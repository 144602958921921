import React from 'react';

import type { PeerAnswerStats as PeerAnswerStatsType } from 'models';

import { __ } from 'helpers/i18n';

import SimpleStatsBlock from '../components/SimpleStatsBlock';

type Props = {
  stats: PeerAnswerStatsType;
};

export default function PeerAnswerStats({ stats }: Props) {
  const formattedStats = [
    {
      label: __('Global'),
      value: stats.global,
      percentage: true,
      info: __('Answer rate for all peers'),
    },
    {
      label: __('Participants'),
      value: stats.participants,
      percentage: true,
      info: __(
        'Answer rate for peers also receiving feedback in this campaign'
      ),
    },
    {
      label: __('Non-participants'),
      value: stats.nonParticipants,
      percentage: true,
      info: __('Answer rate for peers not receiving feedback in this campaign'),
    },
  ];

  return (
    <SimpleStatsBlock title={__('Peers answer rate')} stats={formattedStats} />
  );
}
