import React, { useState } from 'react';

import type { Tag } from 'models';

import { handleFormErrors } from 'helpers/api';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';
import { post } from 'redux/actions/api';

import {
  Button,
  Centered,
  Field,
  FieldError,
  Helper,
  Icon,
  Input,
  Label,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Select,
  StrictlySanitizedHtml,
  Text,
} from 'components';

type AuditParams = {
  title: string;
  delayInMonths: number;
  tagLabels: Array<Tag>;
};

type Props = {
  onClose: () => void;
};

type AfterLoadProps = Props &
  DataLoaderProvidedProps & {
    tags: Array<Tag>;
  };

const NewAuditModal = ({ onClose, tags = [] }: AfterLoadProps) => {
  const [newAudit, setNewAudit] = useState<AuditParams>({
    title: '',
    // @ts-ignore TSFIXME: Fix strictNullChecks error
    delayInMonths: null,
    tagLabels: [],
  });
  const [errors, setErrors] = React.useState<any>({});

  const dispatch = useAppDispatch();
  const onSubmit = (newAudit: AuditParams) =>
    dispatch(post('audit_reports', { audit_report: newAudit }));

  const handleSubmit = () =>
    handleFormErrors(async () => {
      await onSubmit(newAudit);
      onClose();
    }, setErrors);

  return (
    <ModalCard isActive isLarge refreshContentOnOpening onClose={onClose}>
      <ModalCardHead>
        <ModalCardTitle>{__('Create an audit report')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <Field>
          <Label>{__('Report name')}</Label>
          <Input
            value={newAudit.title}
            onChange={title => setNewAudit({ ...newAudit, title })}
          />
          <FieldError>{errors.title}</FieldError>
        </Field>
        <Field>
          <Label>{__('Audit report rules')}</Label>
          <StrictlySanitizedHtml
            componentType="span"
            html={__(
              'Identify all employees <b>who have not had a review tagged with one of the following tags</b>:'
            )}
          />
          <Select
            isClearable
            isMulti
            inModal
            options={tags.map(tag => ({ value: tag.label, label: tag.label }))}
            onChange={selectedTags =>
              setNewAudit({
                ...newAudit,
                // @ts-ignore TSFIXME: Fix strictNullChecks error
                tagLabels: selectedTags.map((tag: any) => tag.label),
              })
            }
          />
          <FieldError>{errors.tags}</FieldError>
        </Field>
        <Field>
          <Text>{__('for more than')}</Text>
          <Input
            additionalClassName="mx-2"
            style={{ width: 40 }}
            type="number"
            size="small"
            value={newAudit.delayInMonths}
            onChange={delayInMonths =>
              setNewAudit({ ...newAudit, delayInMonths: Number(delayInMonths) })
            }
          />

          <Text>{__('months')}</Text>

          <FieldError>{errors.delayInMonths}</FieldError>
        </Field>
        <Helper>
          {__(
            'Employees included in this report, as well as their managers, will not be notified and will not have access to it.'
          )}
        </Helper>
      </ModalCardBody>
      <ModalCardFooter>
        <Centered>
          <div style={{ marginTop: 16 }}>
            <Button onClick={onClose} color="secondary">
              {__('Cancel')}
            </Button>
            <Button
              disabled={
                !newAudit.title ||
                newAudit.tagLabels.length === 0 ||
                !newAudit.delayInMonths
              }
              onClick={handleSubmit}
              color="primary"
            >
              <Icon style={{ marginRight: 8 }} name="add" />
              {__('Create')}
            </Button>
          </div>
        </Centered>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default newDataLoader({
  fetch: () =>
    get('tags', {
      context: 'review_cycle',
    }),
  hydrate: { tags: {} },
})(NewAuditModal);
