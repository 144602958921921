// @flow
import { isDevelopment, isTest } from '../../config/environment';

type ApiConfiguration = {
  apiRootPathname: string,
  hostname: ?string,
  port: ?string,
  httpsEnabled: boolean,
};

const commonApiConfiguration = {
  apiRootPathname: 'api/v1',
};

const developmentApi: ApiConfiguration = {
  ...commonApiConfiguration,
  hostname: 'localhost',
  port: '3001',
  httpsEnabled: false,
};

const herokuApi: ApiConfiguration = {
  ...commonApiConfiguration,
  hostname: process.env.REACT_APP_ELEVO_RAILS_HOST,
  port: process.env.REACT_APP_ELEVO_RAILS_PORT,
  httpsEnabled: !process.env.REACT_APP_ELEVO_RAILS_PORT,
};

export const targetApi: ApiConfiguration =
  isDevelopment() || isTest() ? developmentApi : herokuApi;
