import React, { Fragment, useState } from 'react';

import { AdminResourceType, AdminRole, AdminRoleName, AdminUser } from 'models';

import { n__ } from 'helpers/i18n';

import { Link, Text, Tooltip } from 'components';
import Icon from 'components/Icon';

import displayableRoleName from '../helpers/displayableRoleName';
import AdministeredEntitiesModal from './AdministeredEntitiesModal';
import administeredEntitiesSummary from './helpers/administeredEntitiesSummary';
import deduceOrganizationAdminFromRoleResources from './helpers/deduceOrganizationAdminFromRoleResources';

type Props = { user: AdminUser };

const groupRolesByName = (
  roles: Array<AdminRole>
): Partial<Record<AdminRoleName, Array<AdminResourceType>>> => {
  const groupedRoles = {};

  roles.forEach(role => {
    groupedRoles[role.name] = (groupedRoles[role.name] || []).concat(
      role.resource
    );
  });

  return groupedRoles;
};

const RolesOverview = ({ user }: Props) => {
  const [selectedRoleName, setSelectedRoleName] =
    useState<AdminRoleName | null>(null);
  const groupedRoles = groupRolesByName(user.roles);

  const rowContent = (
    roleName: AdminRoleName,
    resources: Array<AdminResourceType>
  ) => (
    <Fragment>
      <Text weight="bold">{displayableRoleName(roleName)}</Text>
      <Icon additionalClassName="mx-2" name="arrow_forward" />
      <Text>{administeredEntitiesSummary(resources)}</Text>
    </Fragment>
  );

  return (
    <Fragment>
      {(Object.keys(groupedRoles) as Array<AdminRoleName>).map(roleName => {
        const resources = groupedRoles[roleName] as Array<AdminResourceType>;
        const content = rowContent(roleName, resources);

        return (
          <div className="flex items-center" key={roleName}>
            {deduceOrganizationAdminFromRoleResources(resources) ? (
              content
            ) : (
              <Tooltip
                content={n__('See entity', 'See entities', resources.length)}
              >
                <Link onClick={() => setSelectedRoleName(roleName)}>
                  {content}
                </Link>
              </Tooltip>
            )}
          </div>
        );
      })}
      {selectedRoleName && (
        <AdministeredEntitiesModal
          roleName={selectedRoleName}
          adminId={user.id}
          adminFullName={user.fullName}
          onClose={() => setSelectedRoleName(null)}
        />
      )}
    </Fragment>
  );
};

export default RolesOverview;
