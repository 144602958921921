import React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

const MatrixUnpublishedEmptyState = () => (
  <EmptyStateWithIcon
    iconName="warning"
    title={__('Skills matrix currently being updated')}
    description={__(
      'This skills matrix is currently under review. It will be available again soon.'
    )}
  />
);

export default MatrixUnpublishedEmptyState;
