import React from 'react';

import type { GroupedAnswers } from './index';

import { Avatar, Column, Columns, Tooltip } from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

type Props = {
  groupedAnswers: GroupedAnswers;
  ratings: Array<string>;
};

export default function RatingTable({ groupedAnswers, ratings }: Props) {
  return (
    <div className="rating-table">
      {ratings.map(rating => {
        const { authors } = groupedAnswers[rating];

        return (
          <Columns
            contentVerticallyAligned
            isMobile
            additionalClassName="rating-table-row"
          >
            <Column size="half" additionalClassName="rating-table-row-label">
              {rating}
            </Column>
            <Column size="half" additionalClassName="rating-table-row-authors">
              {authors.map(author => (
                <Tooltip
                  triggerAdditionalClassName="rating-table-author"
                  content={[<UserAvatar key="1" user={author} />]}
                >
                  <Avatar url={author.avatarUrl} />
                </Tooltip>
              ))}
            </Column>
          </Columns>
        );
      })}
    </div>
  );
}
