import React, { ReactNode } from 'react';

import type { TrainingOverallBudgetStats } from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { Box, CompletionProgressBar, Flex, Text, Title } from 'components';

type Props = {
  stats: TrainingOverallBudgetStats;
  link: ReactNode;
};

const BudgetCardWithCompletion = ({ stats, link }: Props) => {
  invariant(
    stats.provisionedBudgetCents,
    'Budget stats must be defined to be displayed with the completion'
  );

  const rawCompletionPercentage =
    ((stats.usedBudgetCents || 0) / stats.provisionedBudgetCents) * 100;
  const roundedCompletionPercentage = parseFloat(
    rawCompletionPercentage.toFixed(1)
  );

  return (
    <Box additionalClassName="budget-card budget-card-with-completion">
      <div className="remaining-budget">
        <Flex>
          <Text
            align="left"
            weight="bold"
            additionalClassName="remaining-budget-title"
          >
            {__('Remaining budget')}
          </Text>
          <Title
            size={4}
            weight="bold"
            color="primary"
            additionalClassName="remaining-budget-amount"
            testClassName="test-remaining-budget-amount"
          >
            {stats.formattedRemainingBudget}
          </Title>
        </Flex>
        <CompletionProgressBar
          additionalClassName="remaining-budget-completion-bar"
          completion={roundedCompletionPercentage}
        />
        <Flex>
          <Text
            additionalClassName="remaining-budget-completion-text"
            align="left"
          >
            {__('%1% spent', roundedCompletionPercentage)}
          </Text>
          {link}
        </Flex>
      </div>
    </Box>
  );
};

export default BudgetCardWithCompletion;
