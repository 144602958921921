import type { DataState } from '../../redux/reducers/DataStateType';
import type { Resource } from './types/NormalizedJsonApiResponse';

import { getRelatedResourcesForRelationship } from '../../redux/reducers/data';

type RelationshipsTree = {
  [type: string]: RelationshipsTree;
};

export default function resolveRelationships<T extends Resource>(
  data: DataState,
  originalResource: T,
  subtree: RelationshipsTree = {}
): T {
  const resource = { ...originalResource } as const;

  if (!subtree || Object.keys(subtree).length === 0) {
    return resource;
  }

  const relationshipNames = Object.keys(subtree);

  for (const relationshipName of relationshipNames) {
    let relatedResourcesForRelationship = getRelatedResourcesForRelationship(
      data,
      resource.type,
      resource.id,
      relationshipName
    );

    if (Array.isArray(relatedResourcesForRelationship)) {
      relatedResourcesForRelationship = relatedResourcesForRelationship.map(
        resource =>
          resolveRelationships(data, resource, subtree[relationshipName])
      );
    } else if (!!relatedResourcesForRelationship) {
      relatedResourcesForRelationship = resolveRelationships(
        data,
        relatedResourcesForRelationship,
        subtree[relationshipName]
      );
    }

    resource[relationshipName] = relatedResourcesForRelationship;
  }

  return resource;
}
