import React, { Fragment, useCallback, useEffect, useState } from 'react';

import type { Editor } from 'components/TrixEditor/TrixEditor';
import type { Locale, Organization } from 'models';

import { handleFormErrors } from 'helpers/api';
import classNames from 'helpers/classNames';
import { useAppDispatch } from 'helpers/hooks';
import { __, languageForLocale, localeSelectOptions } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { del, get, post, put } from 'redux/actions/api';

import {
  Button,
  EditorHelperText,
  Helper,
  LoadingOverlay,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  PullRight,
  Select,
  Text,
  Textarea,
} from 'components';

import homeMessageVariables from './homeMessageVariables';

type Props = {
  organization: Organization;
  refetchHomeMessage: () => Promise<void>;
  onClose: () => void;
};

type State = {
  selectedLocale: Locale;
  message: string;
  existingHomeMessageId: string | undefined | null;
  defaultHomeMessageDefined: boolean | undefined | null;
  isLoading: boolean;
  errors: {
    message?: string;
  };
};

const getEditorTextLength = (editor: Editor | null) =>
  editor ? editor.getDocument().toString().length - 1 : 0;

const MAX_MESSAGE_LENGTH = 500;

const UpdateHomeMessageModal = ({
  organization,
  onClose,
  refetchHomeMessage,
}: Props) => {
  const dispatch = useAppDispatch();
  const [editor, setEditor] = React.useState<Editor | null>(null);

  const [state, setState] = useState<State>({
    selectedLocale: organization.locale,
    message: '',
    existingHomeMessageId: undefined,
    defaultHomeMessageDefined: undefined,
    isLoading: true,
    errors: {},
  });
  const {
    selectedLocale,
    message,
    existingHomeMessageId,
    defaultHomeMessageDefined,
    isLoading,
    errors,
  } = state;
  const languageOptions = localeSelectOptions(organization, true);
  const localeOption = languageOptions.find(
    opt => opt.value === selectedLocale
  );
  invariant(localeOption, 'The language option should be defined');

  const isDefaultLocaleSelected = selectedLocale === organization.locale;
  const hasErrors = Object.keys(errors).length > 0;
  const canUpdateMessage =
    !!message &&
    !hasErrors &&
    (isDefaultLocaleSelected || defaultHomeMessageDefined);
  const canDeleteMessage =
    !isDefaultLocaleSelected && !!existingHomeMessageId && !message;

  const getMergeTags = () => {
    const tags = homeMessageVariables().map(({ name, variable }) => ({
      name,
      tag: variable,
    }));

    return [{ trigger: '[', tags }];
  };

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      isLoading: true,
      errors: {},
    }));
    dispatch(get('home_messages', { locale: selectedLocale })).then(
      response => {
        const homeMessage = response.response?.body?.data?.[0];
        setState(prevState => ({
          ...prevState,
          message: homeMessage?.attributes?.message || '',
          existingHomeMessageId: homeMessage?.id,
          defaultHomeMessageDefined:
            prevState.defaultHomeMessageDefined ||
            (selectedLocale === organization.locale && !!homeMessage?.id),
          isLoading: false,
        }));
      }
    );
  }, [selectedLocale, dispatch, organization.locale]);

  const saveHomeMessage = () =>
    handleFormErrors(
      async () => {
        if (existingHomeMessageId) {
          await dispatch(
            put(`home_messages/${existingHomeMessageId}`, {
              home_message: { message: message },
            })
          );
          onClose();
        } else {
          await dispatch(
            post('home_messages', {
              locale: selectedLocale,
              home_message: { message: message },
            })
          );
          onClose();
          refetchHomeMessage();
        }
      },
      errors => setState(prevState => ({ ...prevState, errors }))
    );

  const deleteHomeMessage = useCallback(async () => {
    invariant(existingHomeMessageId, 'The home message id should be defined');
    await dispatch(del(`home_messages/${existingHomeMessageId}`));
    onClose();
    refetchHomeMessage();
  }, [dispatch, existingHomeMessageId, onClose, refetchHomeMessage]);

  const handleOnChange = useCallback(
    (newHtmlMessage: string, newTextMessage: string) => {
      const textLength = newTextMessage.length;

      if (selectedLocale === organization.locale && textLength === 0) {
        setState(prevState => ({
          ...prevState,
          errors: { message: __('The message cannot be empty') },
        }));
      } else if (textLength >= MAX_MESSAGE_LENGTH) {
        setState(prevState => ({
          ...prevState,
          errors: {
            message: __(
              'The message should not exceed %1 characters',
              `${MAX_MESSAGE_LENGTH}`
            ),
          },
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          message: newHtmlMessage,
          errors: {},
        }));
      }
    },
    [selectedLocale, organization.locale]
  );

  return (
    <ModalCard isActive onClose={onClose} refreshContentOnOpening>
      <ModalCardHead>
        <ModalCardTitle>{__('Message for your employees')}</ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody>
        <p className="!mb-4">
          {__(
            'This message will be visible by all employees. It can be configured in several languages.'
          )}
        </p>
        <Select
          onChange={newLocale => {
            invariant(
              newLocale && !Array.isArray(newLocale),
              'Locale must exist and cannot be an array'
            );
            setState(prevState => ({
              ...prevState,
              selectedLocale: newLocale.value,
            }));
          }}
          value={localeOption}
          options={languageOptions}
          components={{ IndicatorSeparator: null }}
          useWidthFromLongestLabel
        />
        {isLoading ? (
          <div style={{ position: 'relative' }}>
            <Textarea
              richText
              richTextOptions={{
                toolbarAlwaysVisible: true,
              }}
              value={message}
              additionalClassName="!mt-2"
              onChange={() => {}}
            />
            <LoadingOverlay />
          </div>
        ) : (
          <Fragment>
            {selectedLocale !== organization.locale && !message && (
              <Helper additionalClassName="mt-2 !mb-0">
                <Text preset="13bs7">
                  {__('No message set in this language')}
                </Text>
                <br />
                {__(
                  'The message configured for the default language will be displayed for users with the language: %1',
                  languageForLocale(selectedLocale, organization)
                )}
              </Helper>
            )}
            <Textarea
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              richText
              richTextOptions={{
                toolbarAlwaysVisible: true,
                fixedTagSelector: true,
                onEditorReady: setEditor,
              }}
              value={message}
              mergeTags={getMergeTags()}
              onChange={handleOnChange}
              additionalClassName={classNames('!mt-2 !mb-0', {
                'has-error': errors.message,
              })}
            />
            <PullRight>
              <EditorHelperText
                maxLength={MAX_MESSAGE_LENGTH}
                length={getEditorTextLength(editor)}
                error={errors.message}
              />
            </PullRight>
          </Fragment>
        )}
      </ModalCardBody>
      <ModalCardFooter>
        <Button
          color="primary"
          onClick={canUpdateMessage ? saveHomeMessage : deleteHomeMessage}
          disabled={!(canUpdateMessage || canDeleteMessage)}
          disabledExplanation={
            !isDefaultLocaleSelected && !defaultHomeMessageDefined
              ? __(
                  'Set the message to the default language\nin order to publish your welcome message'
                )
              : undefined
          }
          tooltipProps={{ style: { whiteSpace: 'pre' } }}
        >
          {__('Publish')}
        </Button>
        <Button color="secondary" onClick={onClose}>
          {__('Close')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default UpdateHomeMessageModal;
