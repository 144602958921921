import React from 'react';

import { useAppDispatch } from 'helpers/hooks';
import { useMultiSelect } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';

import SuccessorChooser from './SuccessorChooser';

type Props = {
  onClose: () => void;
  reviewId: string;
  revieweeFullName: string;
  successorIds: string[];
};

const SuccessorModal = ({
  onClose,
  reviewId,
  revieweeFullName,
  successorIds,
}: Props) => {
  const [selectedSuccessorIds, toggleSelectedSuccessor] =
    useMultiSelect<string>(successorIds);
  const dispatch = useAppDispatch();

  const saveSuccessor = async () => {
    await dispatch(
      post(`people_user_reviews/${reviewId}/successors`, {
        successorIds: selectedSuccessorIds,
      })
    );
    onClose();
  };

  return (
    <ModalCard onClose={onClose} isActive isBig>
      <ModalCardHead>
        <ModalCardTitle>{__('Select one or more successors')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <div className="mb-2">
          <Text additionalClassName="block mb-4">
            {__(
              'Select one or more successors for %1',
              <b>{revieweeFullName}</b>
            )}
          </Text>
          <SuccessorChooser
            reviewId={reviewId}
            selectedSuccessorIds={selectedSuccessorIds}
            toggleSelectedSuccessor={toggleSelectedSuccessor}
            revieweeFullName={revieweeFullName}
          />
        </div>
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Close')}
        </Button>
        <Button color="primary" onClick={saveSuccessor}>
          {n__(
            'Select %1 successor',
            'Select %1 successors',
            selectedSuccessorIds.length
          )}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default SuccessorModal;
