import { keyBy } from 'lodash';
import React from 'react';

import type { SelfEvaluationStatuses } from 'models';

import colors from 'styles/colors';

import { __ } from 'helpers/i18n';

import SimpleStatusesBlock from '../components/SimpleStatusesBlock';

type Props = {
  statuses: SelfEvaluationStatuses;
};

export default function SelfEvaluationStatusesChart(props: Props) {
  const statuses = keyBy(props.statuses.values, value => value.label);

  const formattedStatuses = [
    {
      ...statuses['not_started'],
      label: __('Not started'),
      color: colors.pieChart.red,
    },
    {
      ...statuses['in_progress'],
      label: __('In progress'),
      color: colors.pieChart.yellow,
    },
    {
      ...statuses['shared'],
      label: __('Shared'),
      color: colors.pieChart.green,
    },
  ];

  if (!!statuses['shared_and_signed']) {
    formattedStatuses.push({
      ...statuses['shared_and_signed'],
      label: __('Shared and signed'),
      color: colors.pieChart.green,
    });
  }

  if (!!statuses['outdated']) {
    formattedStatuses.push({
      ...statuses['outdated'],
      label: __('Outdated'),
      color: colors.pieChart.outdatedGrey,
    });
  }

  const overallProgressPercentage = !!statuses['shared_and_signed']
    ? statuses['shared'].percentage + statuses['shared_and_signed'].percentage
    : statuses['shared'].percentage;

  return (
    <SimpleStatusesBlock
      statuses={formattedStatuses}
      title={__('Self evaluation status')}
      overallProgressPercentage={overallProgressPercentage}
      subtitle={__('%1% shared', overallProgressPercentage.toString())}
    />
  );
}
