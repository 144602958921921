import React from 'react';

import { __ } from 'helpers/i18n';

import FeaturePromptModal from 'scenes/components/FeaturePromptModal';

import automaticAddSvg from '../../../components/svg/automaticAddSvg';

type Props = {
  isActive: boolean;
  onClose: () => void;
};

export default function AutoAddNotEnabledModal({ isActive, onClose }: Props) {
  return (
    <FeaturePromptModal
      title={__('Automatic addition of participants')}
      description={__(
        "Save time on your discovery reports or probationary period validations by using the automatic addition of participants based on the employee's start date.\n" +
          'Simply choose the number of days after which your employee should be added to your campaign and we will take care of the rest!'
      )}
      svg={automaticAddSvg}
      prefilledSubjectToSupport={__(
        'Learn more about automatic addition of participants to a campaign'
      )}
      mainButtonText={__('Contact an Elevo expert to access this feature')}
      isActive={isActive}
      onClose={onClose}
    />
  );
}
