import React from 'react';

import classNames from 'helpers/classNames';

type Props = {
  children: React.ReactNode;
  additionalClassName?: string;
};

export default function FieldError({ children, additionalClassName }: Props) {
  if (!children) return null;

  return (
    <p className={classNames('help is-danger', additionalClassName)}>
      {children}
    </p>
  );
}
