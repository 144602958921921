import React from 'react';

import type {
  ReviewBlockUpdateParams,
  TitleBlock,
} from 'models/ReviewStructure';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import {
  Box,
  BoxSeparator,
  Flex,
  Title6,
  WithSavingStatusRecorder,
} from 'components';

import BlockActions from './components/BlockActions';
import BlockContent from './components/BlockContent';

type Props = {
  block: TitleBlock;
  onChange: (params: ReviewBlockUpdateParams) => Promise<void>;
  justEnteredInList: boolean;
  onDelete?: () => Promise<void>;
  onMoveUp: () => Promise<void>;
  onMoveDown: () => Promise<void>;
};

const TitleBlockComponent = ({
  block,
  onChange,
  justEnteredInList,
  ...actionProps
}: Props) => {
  return (
    <Box testClassName="test-title-block">
      <Flex style={{ justifyContent: 'space-between' }} verticalAlign>
        <Title6 transformation="uppercase">{__('Title')}</Title6>
        <BlockActions
          canMoveUp={block.canMoveUp}
          canMoveDown={block.canMoveDown}
          canDuplicate={block.canDuplicate}
          {...actionProps}
        />
      </Flex>

      <BoxSeparator style={{ margin: '16px 0' }} />

      <WithSavingStatusRecorder
        fieldUid={compositeKey({
          blockId: block.id,
          field: 'title_block_content',
        })}
        onChange={onChange}
        render={autoSavingOnchange => (
          <BlockContent
            onChange={autoSavingOnchange}
            content={block.content}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={justEnteredInList}
            withRichText={block.richTextEnabled}
          />
        )}
      />
    </Box>
  );
};

export default TitleBlockComponent;
