import { useEffect } from 'react';

import store from 'config/store';

import compositeKey from 'helpers/compositeKey';
import { useAppDispatch } from 'helpers/hooks';

import { resetFieldStatus } from 'redux/actions/autoSave';

export function fieldUidForItem(type: string, position: number, field: string) {
  return compositeKey({ type, position, field });
}

// The idea here is that in the Visualizers of Matrix and Area, we will set different Recorder points to check the global element update status.
// We will track the matrix/area title, and each level/expectation title and/or description.
// The issue we have with these recorders is that they will be kept in the state when switching between matrices for examples, without resetting themselves.
// So, we end up with this kind of scenario:
//
// I click on matrix A and change its name. For X reason, the name is invalid. So the saving status label tells me I have 1 error in my matrix.
// I go back to the matrix list, and click on matrix B.
// I can still see that I have 1 error in my matrix.
//
// To avoid this issue, the idea is to set fixed fieldUid for all inputs in the visualizer, knowing they will be the same and will have a fixed maximum length (10 for instance).
// We will them reset them if they exist on the state at each component load.
type Props = {
  parentName: string;
  childName: string;
};
export const useFieldStatusResetWhenLoaded = ({
  parentName,
  childName,
}: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const resetFieldIfExistsInState = (
      type: string,
      position: number,
      field: string
    ) => {
      const uid = fieldUidForItem(type, position, field);

      if (!store.getState().autoSave?.fieldStatuses?.[uid]) return;

      dispatch(resetFieldStatus(uid));
    };

    const resetPreviousFieldStatuses = () => {
      for (let i = 0; i < 10; i++) {
        ['title', 'description'].forEach(field =>
          resetFieldIfExistsInState(childName, i, field)
        );
      }

      resetFieldIfExistsInState(parentName, 0, 'title');
    };

    resetPreviousFieldStatuses();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
