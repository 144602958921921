import React from 'react';

import type { ENPSPeriod } from 'models';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer } from 'components';

import ActivePeriod from './ActivePeriod';
import InactivePeriod from './InactivePeriod';

type AfterDataLoaderProps = DataLoaderProvidedProps & {
  enpsPeriod: ENPSPeriod;
};

const ENPS = ({ hasError, isFetching, enpsPeriod }: AfterDataLoaderProps) => {
  return (
    <FetchContainer
      hasError={hasError}
      isFetching={isFetching}
      render={() => {
        if (!enpsPeriod) return null;

        return enpsPeriod.active ? (
          <ActivePeriod enpsPeriod={enpsPeriod} />
        ) : (
          <InactivePeriod enpsPeriod={enpsPeriod} />
        );
      }}
    />
  );
};

export default newDataLoader({
  fetch: () => get('enps/periods/last'),
  hydrate: {
    enpsPeriod: {},
  },
})(ENPS);
