//
// When adding a locale, don't forget to import moment, numeral and date-fns locales!
// Use registerLocale for the date-fns ones.
//
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import nl from 'date-fns/locale/nl';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/nl';
import numeral from 'numeral';
import 'numeral/locales/de';
import 'numeral/locales/es.js';
import 'numeral/locales/fr';
import 'numeral/locales/it';
import 'numeral/locales/nl-nl';
import {
  registerLocale,
  setDefaultLocale as setDatePickerDefaultLocale,
} from 'react-datepicker';

import type { SupportedLocale } from 'locales';

import { getGettextMessagesForLocale } from 'locales';

import * as gettext from './vendor/gettext';

registerLocale('en', en);
registerLocale('fr', fr);
registerLocale('it', it);
registerLocale('de', de);
registerLocale('nl', nl);
registerLocale('es', es);

let currentLocale: SupportedLocale;
export default function setLocale(locale: SupportedLocale) {
  if (currentLocale === locale) return;

  currentLocale = locale;

  if (locale === 'nl') {
    numeral.locale('nl-nl');
  } else {
    numeral.locale(locale);
  }

  moment.locale(locale);
  gettext.loadJSON(getGettextMessagesForLocale(locale));
  gettext.setLocale(locale);
  setDatePickerDefaultLocale(locale);

  const { documentElement: htmlElement } = document;
  if (htmlElement) {
    htmlElement.lang = locale;
  }
}

export const getCurrentLocale = () => currentLocale;
