import Color from 'color';
import React, { type CSSProperties } from 'react';
import posed from 'react-pose';

import { Hoverable } from 'components';

type Props = {
  children: React.ReactNode;
  duration: number;
  color: string;
  withPoppedEffect: boolean;
  style?: CSSProperties;
};

function WithShadowAnimation({
  children,
  duration,
  color,
  style,
  withPoppedEffect,
}: Props) {
  const boxShadowValues = '0px 0px 10px 0px';
  const shadowColor = Color(color);

  const BoxShadow = posed.div({
    popped: {
      boxShadow: `${boxShadowValues} ${shadowColor.alpha(0.1).toString()}`,
      y: withPoppedEffect ? -1 : 0,
      transition: {
        duration,
        ease: 'easeOut',
      },
    },
    default: {
      boxShadow: `${boxShadowValues} ${shadowColor.alpha(0).toString()}`,
      y: 0,
      transition: {
        duration: duration / 2,
        ease: 'easeIn',
      },
    },
  });

  return (
    <Hoverable
      render={isHovered => (
        <BoxShadow
          pose={isHovered ? 'popped' : 'default'}
          color={color}
          style={style}
        >
          {React.Children.only(children)}
        </BoxShadow>
      )}
    />
  );
}

WithShadowAnimation.defaultProps = {
  duration: 400,
  color: '#0000A1',
  withPoppedEffect: false,
};

export default WithShadowAnimation;
