import React from 'react';

import {
  DEFAULT_COLORS,
  colorStyleFromCellData,
  getColorThresholds,
} from 'helpers/heatmap';
import invariant from 'helpers/invariant';

import Axis from './Axis';
import Legend from './Legend';

type AxisDataProps = { dimensionName: string; labels: string[] };

type Props<MatrixCellData> = {
  matrixData: MatrixCellData[][];
  renderCell: (
    position: { x: number; y: number },
    labels: { x: string; y: string },
    colorStyle: { backgroundColor: string; color?: string },
    cellData: MatrixCellData
  ) => React.ReactNode;
  axesData: { x: AxisDataProps; y: AxisDataProps };
};

const Heatmap = <T extends { value: number }>({
  matrixData,
  renderCell,
  axesData,
}: Props<T>) => {
  invariant(
    matrixData.length === axesData.y.labels.length &&
      matrixData[0].length === axesData.x.labels.length,
    "The matrixData dimensions doesn't match the axes dimension"
  );

  const colorThresholds = getColorThresholds(matrixData);

  return (
    <div className="heatmap">
      <Axis direction="x" {...axesData.x} />
      <Axis direction="y" {...axesData.y} />
      <div className="heatmap__chart">
        {matrixData.map((row, rowIndex) => (
          <div key={rowIndex} className="heatmap__row">
            {row.map((cellData, colIndex) => {
              const colorStyle = colorStyleFromCellData(
                cellData,
                colorThresholds
              );

              return renderCell(
                { x: colIndex, y: rowIndex },
                {
                  x: axesData.x.labels[colIndex],
                  y: axesData.y.labels[rowIndex],
                },
                colorStyle,
                cellData
              );
            })}
          </div>
        ))}
      </div>
      <Legend colors={DEFAULT_COLORS} colorThresholds={colorThresholds} />
    </div>
  );
};

export default Heatmap;
