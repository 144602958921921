import React from 'react';

import TeamPicker from 'scenes/components/TeamPicker';

import { EditableFieldProps } from '../EditableField';
import SyncedField from '../SyncedField';

const TeamField = ({
  value,
  fieldSynced,
  onChange,
  syncedFieldOptions,
}: EditableFieldProps) => {
  return fieldSynced ? (
    <SyncedField
      lockedFieldValue={!!value ? value.name : ''}
      {...syncedFieldOptions}
    />
  ) : (
    <TeamPicker
      value={value?.id}
      onChange={(_teamId, team) => onChange(team)}
    />
  );
};

export default TeamField;
