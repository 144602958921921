import React, { Fragment } from 'react';

import { AnimatedGhost, Confettis, FallingItems } from 'components';

import spiderweb from './images/spiderweb.png';

type Props = {
  period: 'winter' | 'spring' | 'halloween' | 'new_year' | undefined;
};

const SeasonalComponent = ({ period }: Props) => {
  switch (period) {
    case 'winter':
    case 'spring':
      return <FallingItems periodType={period} />;
    case 'new_year':
      return (
        <Confettis
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
          }}
        />
      );
    case 'halloween':
      return (
        <Fragment>
          <div className="fixed">
            <img src={spiderweb} alt="" />
          </div>
          <AnimatedGhost style={{ position: 'fixed', right: 20 }} />
        </Fragment>
      );
    default:
      return null;
  }
};

export default SeasonalComponent;
