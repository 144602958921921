import { clamp } from 'lodash';
import React from 'react';

import type { UpdateReviewCycle } from '../..';
import type { ReviewCycle } from 'models';

import { __ } from 'helpers/i18n';

import { Column, Columns, Helper, NumberInput, Title3 } from 'components';

type Props = {
  reviewCycle: ReviewCycle;
  updateReviewCycle: UpdateReviewCycle;
};

export default function LegacyAutoAddParticipantsConfiguration({
  reviewCycle,
  updateReviewCycle,
}: Props) {
  const [currentValue, setCurrentValue] = React.useState(
    reviewCycle.addParticipantsBasedOnWorkStartDateAfterDays
  );
  const onChange = (value: number | null | undefined) =>
    updateReviewCycle({
      addParticipantsBasedOnWorkStartDateAfterDays: value,
    });

  return (
    <React.Fragment>
      <Title3 weight="semibold">
        {__('Configuration of the automatic addition of participants')}
      </Title3>
      <p style={{ marginBottom: 16 }}>
        {__('Define when participants will be added to your campaign')}
      </p>
      <Columns isGapLess>
        <Column size={1} style={{ marginRight: 8 }}>
          <NumberInput
            // @ts-ignore TSFIXME
            value={currentValue}
            onChange={value => setCurrentValue(value)}
            min={1}
            max={365}
            onBlur={async () => {
              // @ts-ignore TSFIXME
              const clampedValue = clamp(currentValue, 1, 365);

              onChange(clampedValue);

              if (clampedValue !== currentValue) {
                setCurrentValue(clampedValue);
              }
            }}
            style={{ textAlign: 'center' }}
            testClassName="test-add-participants-input"
          />
        </Column>
        <Column isVerticallyCentered>
          {__('calendar days after addition of the employee in the cycle')}
        </Column>
      </Columns>
      <Helper style={{ marginBottom: 16 }}>
        {__(
          'Automatic addition will work only if you have set a start date in employee’s profile'
        )}
      </Helper>
    </React.Fragment>
  );
}
