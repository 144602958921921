import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { Link } from 'components';

type Props = {
  children: ReactNode;
  url?: string;
  additionalClassName?: string;
};

const Card = ({ children, url, additionalClassName }: Props) => {
  if (url)
    return (
      <Link to={url} className={classNames('elevo-card', additionalClassName)}>
        {children}
      </Link>
    );
  else {
    return (
      <div className={classNames('elevo-card', additionalClassName)}>
        {children}
      </div>
    );
  }
};

export default Card;
