import type { AutoSaveAction } from '../actions/autoSave';

export type AutoSaveStatus = 'saving' | 'saved' | 'failed' | 'retrying';

export type AutoSaveState = {
  fieldStatuses: {
    [fieldUid: string]: AutoSaveStatus;
  };
  overallStatus: AutoSaveStatus;
  fieldsCountPerStatus: Record<AutoSaveStatus, number>;
};

const initialState = {
  fieldStatuses: {},
  overallStatus: 'saved' as const,
  fieldsCountPerStatus: {
    saving: 0,
    saved: 0,
    failed: 0,
    retrying: 0,
  },
};

function setFieldStatus(
  newState: AutoSaveState,
  fieldUid: string,
  status?: AutoSaveStatus | null
) {
  const previousStatus = newState.fieldStatuses[fieldUid];

  // If we are saving just after a failure or a retry attempt, it means really
  // we are retrying a save attempt
  if (
    status === 'saving' &&
    (previousStatus === 'failed' || previousStatus === 'retrying')
  ) {
    status = 'retrying';
  }

  // Update (or reset) field status
  if (!!status) {
    newState.fieldStatuses[fieldUid] = status as AutoSaveStatus;
  } else {
    delete newState.fieldStatuses[fieldUid];
  }

  // Update fields counts
  if (previousStatus) newState.fieldsCountPerStatus[previousStatus]--;
  if (status) newState.fieldsCountPerStatus[status]++;

  // Compute overall status
  // Precedence is: "retrying", "saving", "failed", "saved"
  const { fieldsCountPerStatus } = newState;

  let newOverallStatus: AutoSaveStatus = 'saved';
  if (fieldsCountPerStatus.failed > 0) newOverallStatus = 'failed';
  if (fieldsCountPerStatus.saving > 0) newOverallStatus = 'saving';
  if (fieldsCountPerStatus.retrying > 0) newOverallStatus = 'retrying';

  newState.overallStatus = newOverallStatus;

  return newState;
}

export function getAutoSaveStatus(
  state: AutoSaveState,
  fieldUid?: string
): AutoSaveStatus {
  if (!fieldUid) {
    return state.overallStatus;
  }

  // By default fields are all saved when they are displayed
  return state.fieldStatuses[fieldUid] || 'saved';
}

export function getFailedFieldsCount(state: AutoSaveState): number {
  return state.fieldsCountPerStatus.failed;
}

export default function autoSave(
  state: AutoSaveState = initialState,
  action: AutoSaveAction
) {
  const { fieldUid, type } = action;
  switch (type) {
    case 'RESET':
      return setFieldStatus({ ...state }, fieldUid, null);
    case 'SET_SAVING':
      return setFieldStatus({ ...state }, fieldUid, 'saving');
    case 'SET_SAVED':
      return setFieldStatus({ ...state }, fieldUid, 'saved');
    case 'SET_FAILED':
      return setFieldStatus({ ...state }, fieldUid, 'failed');
    default:
      return state;
  }
}
