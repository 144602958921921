import { capitalize, uniq } from 'lodash';

import { AdminRole } from 'models';

import { n__ } from 'helpers/i18n';

import displayableRoleName from '../../../helpers/displayableRoleName';

const humanizeRoleNamesList = (
  roles: Array<AdminRole>,
  options: { capitalize: boolean }
) => {
  const roleNames = uniq(roles.map(role => displayableRoleName(role.name)));
  const lastRoleName = roleNames.pop();
  const commaSeparatedList = roleNames.join(', ');

  const text = n__(
    '%3 role',
    '%2 and %3 roles',
    roleNames.length + 1,
    commaSeparatedList,
    lastRoleName
  );

  if (options.capitalize) {
    return capitalize(text);
  }

  return text;
};

export default humanizeRoleNamesList;
