import React from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { Entity } from 'models';

import { __ } from 'helpers/i18n/index';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer, Select } from 'components';

import fakeAllUserEntity from './fakeAllUserEntity';

type Option = {
  value: string;
  label: string;
  entity: Entity;
};

type Props = {
  onChange: (entities: Entity[]) => void;
  selectedEntities: Entity[];
  disabled?: boolean;
};

type AfterDataLoaderProps = DataLoaderProvidedProps &
  Props & {
    entities: Entity[];
  };

const formatEntityToOption = (entity: Entity): Option => ({
  value: entity.id,
  label: entity.name,
  entity,
});

const EntitySelect = ({
  onChange,
  entities,
  selectedEntities,
  disabled,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  const handleChange = (option: Option | Array<Option> | null | void) => {
    if (!option || !Array.isArray(option)) return onChange([]);

    const isAllUsersOptionSelected = option.some(o => o.value === '_all_users');
    const wasAllUsersOptionAlreadySelected = selectedEntities.some(
      e => e.id === '_all_users'
    );
    if (isAllUsersOptionSelected && !wasAllUsersOptionAlreadySelected)
      return onChange([fakeAllUserEntity()]);

    onChange(option.filter(o => o.value !== '_all_users').map(o => o.entity));
  };

  return (
    <div className="basis-1/2">
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        loadingStyle="overlay"
        render={() => {
          const options = isFetching
            ? []
            : entities.map(entity => formatEntityToOption(entity));

          // Hack to add an "All users" option
          options.unshift(formatEntityToOption(fakeAllUserEntity()));

          return (
            <Select
              isDisabled={disabled}
              options={options}
              value={selectedEntities.map(entity =>
                formatEntityToOption(entity)
              )}
              onChange={handleChange}
              placeholder={__('Select one or more entities')}
              maxMenuHeight={200}
              captureMenuScroll
              isMulti
              inModal
              isClearable
            />
          );
        }}
      />
    </div>
  );
};

export default newDataLoader({
  fetch: () => get('entities'),
  hydrate: { entities: {} },
})(EntitySelect);
