import React from 'react';

import type { UserReview } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { post } from 'redux/actions/api';
import { successNotice } from 'redux/actions/application';

import { ConfirmationModal } from 'components';

type Props = {
  isActive: boolean;
  toggleModal: () => void;
  userReview: UserReview;
  onAfterAction?: () => Promise<void>;
};

const Unrelease360UserReviewModal = ({
  isActive,
  toggleModal,
  userReview,
  onAfterAction,
}: Props) => {
  const dispatch = useAppDispatch();
  const unreleaseUserReview = async () => {
    const reviewee = userReview.user;

    await dispatch(post(`user_reviews/${userReview.id}/unrelease`));

    if (onAfterAction) {
      await Promise.resolve(onAfterAction());
    }
    reviewee &&
      (await dispatch(
        successNotice(
          __('You re-opened the 360 feedback about %1', reviewee.fullName)
        )
      ));
  };

  return (
    <ConfirmationModal
      isActive={isActive}
      cancelLabel={__('Cancel')}
      confirmLabel={__('Re-open and send emails')}
      title={__('Re-open 360 feedback')}
      onConfirm={async () => {
        await unreleaseUserReview();
        toggleModal();
      }}
      onCancel={toggleModal}
      refreshContentOnOpening={false}
    >
      {__(
        'Re-opening this 360 feedback review will allow peers that did not have time to respond to complete and submit their feedback. These peers will be informed by email of the possibility to complete their feedback.'
      )}
    </ConfirmationModal>
  );
};

export default Unrelease360UserReviewModal;
