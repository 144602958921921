import React, {
  ChangeEventHandler,
  DragEventHandler,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { Icon, Text } from 'components';

type Props = {
  onFilesDrop: (files: File[]) => void;
  acceptedFileTypes?: string[];
  maxFileSize?: number;
  maxFilesCount?: number;
};

const MIME_TYPE_TO_EXTENSION = {
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'pptx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
  'text/csv': 'csv',
  'text/plain': 'txt',
};

const DragAndDropFileInput = ({
  onFilesDrop,
  acceptedFileTypes,
  maxFileSize,
  maxFilesCount,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [dragEnterCounter, setDragEnterCounter] = useState(0);

  const draggingOver = useMemo(() => dragEnterCounter > 0, [dragEnterCounter]);

  const onInputChange: ChangeEventHandler<HTMLInputElement> = event => {
    event.preventDefault();
    if (event.target.files) onFilesDrop(Array.from(event.target.files));
    event.target.value = '';
  };

  const onClick = () => fileInputRef.current?.click();

  const onDragEnter = () => setDragEnterCounter(dragEnterCounter + 1);
  const onDragLeave = () => setDragEnterCounter(dragEnterCounter - 1);

  const onDragOver: DragEventHandler<HTMLDivElement> = event => {
    event.preventDefault();
  };

  const onDrop: DragEventHandler<HTMLDivElement> = event => {
    event.preventDefault();
    setDragEnterCounter(0);
    const acceptedFiles = Array.from(event.dataTransfer.files).filter(
      file => !acceptedFileTypes || acceptedFileTypes.includes(file.type)
    );
    if (acceptedFiles.length > 0) {
      onFilesDrop(acceptedFiles);
    }
  };

  invariant(
    !acceptedFileTypes ||
      !acceptedFileTypes
        .map(type => MIME_TYPE_TO_EXTENSION[type])
        .includes(undefined),
    "Couldn't find file extension for this mime type. You may need to update the MIME_TYPE_TO_EXTENSION list."
  );

  const fileConstraints = [
    !!acceptedFileTypes &&
      __(
        'Supported files: %1.',
        acceptedFileTypes.map(type => MIME_TYPE_TO_EXTENSION[type]).join(', ')
      ),
    !!maxFileSize && __('Maximum file size: %1 MB.', maxFileSize),
    !!maxFilesCount && __('Maximum %1 files.', maxFilesCount),
  ]
    .filter(node => node)
    .join(' ');

  return (
    <div
      className={classNames(
        'box with-background-white with-border m-0 flex flex-col items-center gap-2.5 cursor-pointer',
        draggingOver ? 'border-solid' : 'border-dashed'
      )}
      style={
        draggingOver
          ? {
              borderColor: 'var(--primary)',
              backgroundColor: 'var(--primary--20--rgba)',
            }
          : {}
      }
      onClick={onClick}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      role="button"
      tabIndex={0}
      onKeyDown={event => {
        if (event.key === 'Enter' || event.key === ' ') {
          onClick();
        }
      }}
    >
      <Text preset="32bs1" color={draggingOver ? 'primary' : undefined}>
        <Icon
          style={{ fontSize: '24px', lineHeight: '24px' }}
          additionalClassName={classNames(
            'rounded-full p-2.5',
            draggingOver ? 'bg-white' : 'bg-highlight-gray'
          )}
          name="download"
        />
      </Text>
      <Text preset="13bs7">
        {__(
          'Drag and drop or %1 to upload',
          <Text color="primary">{__('browse')}</Text>
        )}
      </Text>
      <Text
        additionalClassName="inline-block text-center"
        style={{ minWidth: '550px' }}
      >
        {fileConstraints}
      </Text>
      <form className="hidden">
        <input
          type="file"
          name="reviewFile"
          accept={acceptedFileTypes && acceptedFileTypes.join(', ')}
          onChange={onInputChange}
          multiple={true}
          ref={fileInputRef}
        />
      </form>
    </div>
  );
};

export default DragAndDropFileInput;
