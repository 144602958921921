import React from 'react';

import { __ } from 'helpers/i18n';

import { Flex, Icon, Text, Tooltip } from 'components';

type Props = {
  additionalClassName?: string;
};

export default function EditorHowTo({ additionalClassName }: Props) {
  return (
    <Tooltip
      isLight
      style={{ maxWidth: 400 }}
      placement="bottom-start"
      content={
        <div>
          <Text size={6} weight="semibold">
            {__('Add variables')}
          </Text>
          <br />
          <Text size={7}>
            {__(
              'Variables allow you to customize texts with dynamic data such as the name of the reviewee, the name of the campaign, etc.'
            )}
          </Text>
          <br />
          <br />
          <Text size={7}>
            {__('Type [ to see the list of available variables and add them.')}
          </Text>
        </div>
      }
    >
      <Flex additionalClassName={additionalClassName}>
        <Icon color="primary" style={{ marginRight: 8 }} name="help" />
        <Text size={6} color="primary" style={{ textDecoration: 'underline' }}>
          {__('How to insert a variable?')}
        </Text>
      </Flex>
    </Tooltip>
  );
}
