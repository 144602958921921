import React from 'react';

type Props = {
  children: React.ReactNode;
};

const Section = ({ children }: Props) => (
  <div className="with-columns-margin">
    <div className="with-column-padding">{children}</div>
  </div>
);

export default Section;
