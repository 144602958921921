const delegateToAnyoneSvg = `<svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="80" cy="80" r="80" fill="#EFF1F6"/>
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="160" height="160">
<circle cx="80" cy="80" r="80" fill="#EFF1F6"/>
</mask>
<g mask="url(#mask0)">
<path d="M53 73.241C52.9954 72.6714 52.7936 72.121 52.429 71.6835C52.0643 71.2459 51.5594 70.9482 51 70.8409V69.241C51 66.0584 49.7357 63.0062 47.4852 60.7557C45.2348 58.5053 42.1826 57.241 39 57.241C35.8174 57.241 32.7652 58.5053 30.5148 60.7557C28.2643 63.0062 27 66.0584 27 69.241V70.8409C26.4156 70.9486 25.8904 71.2651 25.5222 71.7314C25.154 72.1978 24.9679 72.7821 24.9988 73.3755C25.0296 73.9689 25.2752 74.5309 25.6898 74.9565C26.1044 75.3822 26.6596 75.6425 27.252 75.689C27.8142 78.3889 29.2892 80.8127 31.4288 82.5527C33.5685 84.2927 36.2422 85.2427 39 85.2427C41.7578 85.2427 44.4315 84.2927 46.5712 82.5527C48.7108 80.8127 50.1858 78.3889 50.7481 75.689C51.3604 75.6334 51.9301 75.3519 52.3463 74.8994C52.7626 74.4469 52.9956 73.8558 53 73.241Z" fill="#FFCEBF" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M53 73.241C52.9954 72.6714 52.7936 72.121 52.429 71.6835C52.0643 71.2459 51.5594 70.9482 51 70.8409V69.241C51 66.0584 49.7357 63.0062 47.4852 60.7557C45.2348 58.5053 42.1826 57.241 39 57.241C35.8174 57.241 32.7652 58.5053 30.5148 60.7557C28.2643 63.0062 27 66.0584 27 69.241V70.8409C26.4156 70.9486 25.8904 71.2651 25.5222 71.7314C25.154 72.1978 24.9679 72.7821 24.9988 73.3755C25.0296 73.9689 25.2752 74.5309 25.6898 74.9565C26.1044 75.3822 26.6596 75.6425 27.252 75.689C27.8142 78.3889 29.2892 80.8127 31.4288 82.5527C33.5685 84.2927 36.2422 85.2427 39 85.2427C41.7578 85.2427 44.4315 84.2927 46.5712 82.5527C48.7108 80.8127 50.1858 78.3889 50.7481 75.689C51.3604 75.6334 51.9301 75.3519 52.3463 74.8994C52.7626 74.4469 52.9956 73.8558 53 73.241Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.5 71.241C34.7761 71.241 35 71.0171 35 70.741C35 70.4648 34.7761 70.241 34.5 70.241C34.2239 70.241 34 70.4648 34 70.741C34 71.0171 34.2239 71.241 34.5 71.241Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M43.5 71.241C43.7761 71.241 44 71.0171 44 70.741C44 70.4648 43.7761 70.241 43.5 70.241C43.2239 70.241 43 70.4648 43 70.741C43 71.0171 43.2239 71.241 43.5 71.241Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M32.5 76.241C33.3284 76.241 34 75.5694 34 74.741C34 73.9125 33.3284 73.241 32.5 73.241C31.6716 73.241 31 73.9125 31 74.741C31 75.5694 31.6716 76.241 32.5 76.241Z" fill="#FFB59E"/>
<path d="M45.5 76.241C46.3284 76.241 47 75.5694 47 74.741C47 73.9125 46.3284 73.241 45.5 73.241C44.6716 73.241 44 73.9125 44 74.741C44 75.5694 44.6716 76.241 45.5 76.241Z" fill="#FFB59E"/>
<path d="M36 78.333C36.8886 78.9248 37.9324 79.2406 39 79.2406C40.0676 79.2406 41.1114 78.9248 42 78.333" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M42.3719 53.241H30.142C29.7274 53.2408 29.3231 53.3694 28.9849 53.6091C28.6466 53.8487 28.3912 54.1876 28.2539 54.5787C28.1166 54.9699 28.1043 55.394 28.2186 55.7925C28.3329 56.191 28.5682 56.5441 28.892 56.803L29.928 57.631C28.7779 58.0518 27.7846 58.8153 27.0822 59.8185C26.3797 60.8217 26.002 62.0163 26 63.241V70.241H27C29.27 68.879 33 65.4009 33 62.8729C33.7319 62.8847 34.4423 63.1213 35.0352 63.5507C35.628 63.98 36.0745 64.5813 36.314 65.2729C36.3513 65.4224 36.4199 65.5624 36.5154 65.6833C36.6108 65.8043 36.7308 65.9036 36.8676 65.9747C37.0043 66.0458 37.1545 66.087 37.3083 66.0957C37.4622 66.1044 37.6162 66.0803 37.76 66.025C39.866 65.171 41.16 64.0509 41.288 62.8729C47.456 64.4149 44.78 70.0909 52 70.0909V62.8729C52.0005 61.6082 51.7519 60.3558 51.2683 59.1873C50.7847 58.0187 50.0755 56.9568 49.1814 56.0624C48.2873 55.1679 47.2258 54.4583 46.0574 53.9742C44.889 53.4901 43.6367 53.241 42.3719 53.241Z" fill="#FFC44D"/>
<path d="M42.3719 53.241H30.142C29.7274 53.2408 29.3231 53.3694 28.9849 53.6091C28.6466 53.8487 28.3912 54.1876 28.2539 54.5787C28.1166 54.9699 28.1043 55.394 28.2186 55.7925C28.3329 56.191 28.5682 56.5441 28.892 56.803L29.928 57.631C28.7779 58.0518 27.7846 58.8153 27.0822 59.8185C26.3797 60.8217 26.002 62.0163 26 63.241V69.241C26.004 68.018 26.3827 66.8256 27.085 65.8243C27.7873 64.8231 28.7795 64.0612 29.928 63.641L28.892 62.813C28.5682 62.5541 28.3329 62.201 28.2186 61.8025C28.1043 61.404 28.1166 60.9799 28.2539 60.5887C28.3912 60.1976 28.6466 59.8587 28.9849 59.619C29.3231 59.3794 29.7274 59.2508 30.142 59.251H42.3719C44.9237 59.251 47.3712 60.2639 49.1765 62.0674C50.9818 63.8708 51.9973 66.3172 52 68.869V62.869C52 60.3155 50.9857 57.8665 49.1801 56.0609C47.3745 54.2553 44.9255 53.241 42.3719 53.241Z" fill="#FFDB94"/>
<path d="M42.3719 53.241H30.142C29.7274 53.2408 29.3231 53.3694 28.9849 53.6091C28.6466 53.8487 28.3912 54.1876 28.2539 54.5787C28.1166 54.9699 28.1043 55.394 28.2186 55.7925C28.3329 56.191 28.5682 56.5441 28.892 56.803L29.928 57.631C28.7779 58.0518 27.7846 58.8153 27.0822 59.8185C26.3797 60.8217 26.002 62.0163 26 63.241V70.241H27C29.27 68.879 33 65.4009 33 62.8729C33.7319 62.8847 34.4423 63.1213 35.0352 63.5507C35.628 63.98 36.0745 64.5813 36.314 65.2729C36.3513 65.4224 36.4199 65.5624 36.5154 65.6833C36.6108 65.8043 36.7308 65.9036 36.8676 65.9747C37.0043 66.0458 37.1545 66.087 37.3083 66.0957C37.4622 66.1044 37.6162 66.0803 37.76 66.025C39.866 65.171 41.16 64.0509 41.288 62.8729C47.456 64.4149 44.78 70.0909 52 70.0909V62.8729C52.0005 61.6082 51.7519 60.3558 51.2683 59.1873C50.7847 58.0187 50.0755 56.9568 49.1814 56.0624C48.2873 55.1679 47.2258 54.4583 46.0574 53.9742C44.889 53.4901 43.6367 53.241 42.3719 53.241Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M135 73.241C134.995 72.6714 134.794 72.121 134.429 71.6835C134.064 71.2459 133.559 70.9482 133 70.8409V69.241C133 66.0584 131.736 63.0062 129.485 60.7557C127.235 58.5053 124.183 57.241 121 57.241C117.817 57.241 114.765 58.5053 112.515 60.7557C110.264 63.0062 109 66.0584 109 69.241V70.8409C108.416 70.9486 107.89 71.2651 107.522 71.7314C107.154 72.1978 106.968 72.7821 106.999 73.3755C107.03 73.9689 107.275 74.5309 107.69 74.9565C108.104 75.3822 108.66 75.6425 109.252 75.689C109.814 78.3889 111.289 80.8127 113.429 82.5527C115.568 84.2927 118.242 85.2427 121 85.2427C123.758 85.2427 126.432 84.2927 128.571 82.5527C130.711 80.8127 132.186 78.3889 132.748 75.689C133.36 75.6334 133.93 75.3519 134.346 74.8994C134.763 74.4469 134.996 73.8558 135 73.241Z" fill="#D18660" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M135 73.241C134.995 72.6714 134.794 72.121 134.429 71.6835C134.064 71.2459 133.559 70.9482 133 70.8409V69.241C133 66.0584 131.736 63.0062 129.485 60.7557C127.235 58.5053 124.183 57.241 121 57.241C117.817 57.241 114.765 58.5053 112.515 60.7557C110.264 63.0062 109 66.0584 109 69.241V70.8409C108.416 70.9486 107.89 71.2651 107.522 71.7314C107.154 72.1978 106.968 72.7821 106.999 73.3755C107.03 73.9689 107.275 74.5309 107.69 74.9565C108.104 75.3822 108.66 75.6425 109.252 75.689C109.814 78.3889 111.289 80.8127 113.429 82.5527C115.568 84.2927 118.242 85.2427 121 85.2427C123.758 85.2427 126.432 84.2927 128.571 82.5527C130.711 80.8127 132.186 78.3889 132.748 75.689C133.36 75.6334 133.93 75.3519 134.346 74.8994C134.763 74.4469 134.996 73.8558 135 73.241Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M116.5 71.241C116.776 71.241 117 71.0171 117 70.741C117 70.4648 116.776 70.241 116.5 70.241C116.224 70.241 116 70.4648 116 70.741C116 71.0171 116.224 71.241 116.5 71.241Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M125.5 71.241C125.776 71.241 126 71.0171 126 70.741C126 70.4648 125.776 70.241 125.5 70.241C125.224 70.241 125 70.4648 125 70.741C125 71.0171 125.224 71.241 125.5 71.241Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M114.5 76.241C115.328 76.241 116 75.5694 116 74.741C116 73.9125 115.328 73.241 114.5 73.241C113.672 73.241 113 73.9125 113 74.741C113 75.5694 113.672 76.241 114.5 76.241Z" fill="#BD704A"/>
<path d="M127.5 76.241C128.328 76.241 129 75.5694 129 74.741C129 73.9125 128.328 73.241 127.5 73.241C126.672 73.241 126 73.9125 126 74.741C126 75.5694 126.672 76.241 127.5 76.241Z" fill="#BD704A"/>
<path d="M118 78.333C118.889 78.9248 119.932 79.2406 121 79.2406C122.068 79.2406 123.111 78.9248 124 78.333" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M124.372 53.241H112.142C111.727 53.2408 111.323 53.3694 110.985 53.6091C110.647 53.8487 110.391 54.1876 110.254 54.5787C110.117 54.9699 110.104 55.394 110.219 55.7925C110.333 56.191 110.568 56.5441 110.892 56.803L111.928 57.631C110.778 58.0518 109.785 58.8153 109.082 59.8185C108.38 60.8217 108.002 62.0163 108 63.241V70.241H109C111.27 68.879 115 65.4009 115 62.8729C115.732 62.8847 116.442 63.1213 117.035 63.5507C117.628 63.98 118.074 64.5813 118.314 65.2729C118.351 65.4224 118.42 65.5624 118.515 65.6833C118.611 65.8043 118.731 65.9036 118.868 65.9747C119.004 66.0458 119.155 66.087 119.308 66.0957C119.462 66.1044 119.616 66.0803 119.76 66.025C121.866 65.171 123.16 64.0509 123.288 62.8729C129.456 64.4149 126.78 70.0909 134 70.0909V62.8729C134.001 61.6082 133.752 60.3558 133.268 59.1873C132.785 58.0187 132.075 56.9568 131.181 56.0624C130.287 55.1679 129.226 54.4583 128.057 53.9742C126.889 53.4901 125.637 53.241 124.372 53.241Z" fill="#724F3D"/>
<path d="M124.372 53.241H112.142C111.727 53.2408 111.323 53.3694 110.985 53.6091C110.647 53.8487 110.391 54.1876 110.254 54.5787C110.117 54.9699 110.104 55.394 110.219 55.7925C110.333 56.191 110.568 56.5441 110.892 56.803L111.928 57.631C110.778 58.0518 109.785 58.8153 109.082 59.8185C108.38 60.8217 108.002 62.0163 108 63.241V69.241C108.004 68.018 108.383 66.8256 109.085 65.8243C109.787 64.8231 110.779 64.0612 111.928 63.641L110.892 62.813C110.568 62.5541 110.333 62.201 110.219 61.8025C110.104 61.404 110.117 60.9799 110.254 60.5887C110.391 60.1976 110.647 59.8587 110.985 59.619C111.323 59.3794 111.727 59.2508 112.142 59.251H124.372C126.924 59.251 129.371 60.2639 131.177 62.0674C132.982 63.8708 133.997 66.3172 134 68.869V62.869C134 60.3155 132.986 57.8665 131.18 56.0609C129.374 54.2553 126.925 53.241 124.372 53.241Z" fill="#A86C4D"/>
<path d="M124.372 53.241H112.142C111.727 53.2408 111.323 53.3694 110.985 53.6091C110.647 53.8487 110.391 54.1876 110.254 54.5787C110.117 54.9699 110.104 55.394 110.219 55.7925C110.333 56.191 110.568 56.5441 110.892 56.803L111.928 57.631C110.778 58.0518 109.785 58.8153 109.082 59.8185C108.38 60.8217 108.002 62.0163 108 63.241V70.241H109C111.27 68.879 115 65.4009 115 62.8729C115.732 62.8847 116.442 63.1213 117.035 63.5507C117.628 63.98 118.074 64.5813 118.314 65.2729C118.351 65.4224 118.42 65.5624 118.515 65.6833C118.611 65.8043 118.731 65.9036 118.868 65.9747C119.004 66.0458 119.155 66.087 119.308 66.0957C119.462 66.1044 119.616 66.0803 119.76 66.025C121.866 65.171 123.16 64.0509 123.288 62.8729C129.456 64.4149 126.78 70.0909 134 70.0909V62.8729C134.001 61.6082 133.752 60.3558 133.268 59.1873C132.785 58.0187 132.075 56.9568 131.181 56.0624C130.287 55.1679 129.226 54.4583 128.057 53.9742C126.889 53.4901 125.637 53.241 124.372 53.241Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M99 51.907C99.0003 52.2136 98.9401 52.5172 98.8229 52.8005C98.7057 53.0838 98.5338 53.3411 98.317 53.5579C98.1002 53.7747 97.8427 53.9466 97.5594 54.0638C97.2762 54.181 96.9726 54.2412 96.666 54.241H66.334C66.0274 54.2412 65.7238 54.181 65.4406 54.0638C65.1573 53.9466 64.8998 53.7747 64.683 53.5579C64.4662 53.3411 64.2943 53.0838 64.1771 52.8005C64.0599 52.5172 63.9997 52.2136 64 51.907V14.575C63.9997 14.2684 64.0599 13.9648 64.1771 13.6815C64.2943 13.3982 64.4662 13.1408 64.683 12.924C64.8998 12.7072 65.1573 12.5353 65.4406 12.4181C65.7238 12.3009 66.0274 12.2407 66.334 12.241H88L99 23.241V51.907Z" fill="white" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M88 12.241V23.241H99L88 12.241Z" fill="#E8F4FA" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M70 24.241H82" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M70 31.241H93" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M70 38.241H93" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M70 45.241H93" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M130 157.241H131C132.053 157.238 133.088 157.514 134 158.041V126.241H108V158.041C108.912 157.514 109.947 157.238 111 157.241H112C113.021 157.241 114.026 157.501 114.918 157.998C115.811 158.495 116.562 159.211 117.1 160.079L117.79 138.351C117.819 137.519 118.17 136.731 118.769 136.153C119.368 135.575 120.168 135.252 121 135.252C121.832 135.252 122.632 135.575 123.231 136.153C123.83 136.731 124.181 137.519 124.21 138.351L124.9 160.081C125.438 159.213 126.189 158.496 127.081 157.999C127.974 157.502 128.978 157.241 130 157.241Z" fill="#00DBA8" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M117.8 138.241H108V143.241H117.634L117.8 138.241Z" fill="#00AD85"/>
<path d="M124.366 143.241H134V138.241H124.2L124.366 143.241Z" fill="#00AD85"/>
<path d="M130 157.241H131C132.053 157.238 133.088 157.514 134 158.041V126.241H108V158.041C108.912 157.514 109.947 157.238 111 157.241H112C113.021 157.241 114.026 157.501 114.918 157.998C115.811 158.495 116.562 159.211 117.1 160.079L117.79 138.351C117.819 137.519 118.17 136.731 118.769 136.153C119.368 135.575 120.168 135.252 121 135.252C121.832 135.252 122.632 135.575 123.231 136.153C123.83 136.731 124.181 137.519 124.21 138.351L124.9 160.081C125.438 159.213 126.189 158.496 127.081 157.999C127.974 157.502 128.978 157.241 130 157.241Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M121 89.241C115.43 89.241 110.089 91.4535 106.151 95.3917C102.212 99.33 100 104.671 100 110.241V113.241C100.003 115.545 100.8 117.779 102.257 119.564C103.714 121.349 105.743 122.577 108 123.041V138.241H114.526C115.127 137.039 116.05 136.028 117.193 135.322C118.336 134.615 119.653 134.241 120.997 134.241C122.341 134.241 123.658 134.615 124.801 135.322C125.944 136.028 126.867 137.039 127.468 138.241H134V123.041C136.257 122.577 138.285 121.349 139.743 119.563C141.2 117.778 141.997 115.545 142 113.241V110.241C142 104.671 139.788 99.33 135.849 95.3917C131.911 91.4535 126.57 89.241 121 89.241Z" fill="#00DBA8" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M121 89.241C115.43 89.241 110.089 91.4535 106.151 95.3917C102.212 99.33 100 104.671 100 110.241V113.241C99.9999 113.687 100.029 114.133 100.088 114.575C100.567 109.36 102.977 104.512 106.846 100.982C110.715 97.4523 115.763 95.4955 121 95.4955C126.237 95.4955 131.285 97.4523 135.154 100.982C139.023 104.512 141.433 109.36 141.912 114.575C141.971 114.133 142 113.687 142 113.241V110.241C142 104.671 139.788 99.33 135.849 95.3917C131.911 91.4535 126.57 89.241 121 89.241Z" fill="#00F5BC"/>
<path d="M121 89.241C115.43 89.241 110.089 91.4535 106.151 95.3917C102.212 99.33 100 104.671 100 110.241V113.241C100.003 115.545 100.8 117.779 102.257 119.564C103.714 121.349 105.743 122.577 108 123.041V138.241H114.526C115.127 137.039 116.05 136.028 117.193 135.322C118.336 134.615 119.653 134.241 120.997 134.241C122.341 134.241 123.658 134.615 124.801 135.322C125.944 136.028 126.867 137.039 127.468 138.241H134V123.041C136.257 122.577 138.285 121.349 139.743 119.563C141.2 117.778 141.997 115.545 142 113.241V110.241C142 104.671 139.788 99.33 135.849 95.3917C131.911 91.4535 126.57 89.241 121 89.241V89.241Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M121 114.241L129.316 90.953C126.689 89.8235 123.859 89.241 121 89.241C118.141 89.241 115.311 89.8235 112.684 90.953L121 114.241Z" fill="white" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M127.238 96.771L121 89.241L114.762 96.771" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M134.464 163.241C134.815 163.241 135.16 163.149 135.465 162.974C135.769 162.798 136.022 162.546 136.198 162.242C136.373 161.938 136.466 161.593 136.466 161.241C136.466 160.89 136.374 160.545 136.198 160.241C135.671 159.328 134.913 158.57 134 158.044C133.087 157.517 132.052 157.24 130.998 157.241H130C128.946 157.24 127.911 157.517 126.998 158.044C126.085 158.57 125.327 159.328 124.8 160.241C124.624 160.545 124.532 160.89 124.532 161.241C124.532 161.593 124.625 161.938 124.801 162.242C124.976 162.546 125.229 162.798 125.533 162.974C125.838 163.149 126.183 163.241 126.534 163.241H134.464Z" fill="#947746" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M115.464 163.241C115.815 163.241 116.16 163.149 116.465 162.974C116.769 162.798 117.022 162.546 117.198 162.242C117.373 161.938 117.466 161.593 117.466 161.241C117.466 160.89 117.374 160.545 117.198 160.241C116.671 159.328 115.913 158.57 115 158.044C114.087 157.517 113.052 157.24 111.998 157.241H110.998C109.944 157.24 108.909 157.517 107.996 158.044C107.083 158.57 106.325 159.328 105.798 160.241C105.622 160.545 105.53 160.89 105.53 161.241C105.53 161.593 105.623 161.938 105.798 162.242C105.974 162.546 106.227 162.798 106.531 162.974C106.836 163.149 107.181 163.241 107.532 163.241H115.464Z" fill="#947746" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M121 89.241L117.576 104.653L121 114.241L124.424 104.653L121 89.241Z" fill="#FF8A14" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M121 123.241V134.241" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M121 114.241V115.241" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M121 114.241L132 101.241L130 97.241H134L132.81 92.8789C131.709 92.1299 130.539 91.4871 129.316 90.959L121 114.241Z" fill="#00DBA8" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M121 114.241L112.686 90.959C111.462 91.4866 110.292 92.1294 109.19 92.8789L108 97.241H112L110 101.241L121 114.241Z" fill="#00DBA8" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M117 123.241H110C109.328 123.241 108.658 123.174 108 123.041" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M108 105.241V113.241C108 113.771 108.211 114.28 108.586 114.655C108.961 115.03 109.47 115.241 110 115.241H117" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M132 115.241C132.349 115.241 132.691 115.15 132.993 114.976C133.296 114.803 133.548 114.554 133.724 114.253C133.731 113.621 133.566 112.998 133.247 112.452C132.928 111.906 132.467 111.457 131.913 111.152C131.359 110.847 130.733 110.698 130.101 110.721C129.469 110.744 128.855 110.937 128.324 111.281L124 115.241H132Z" fill="#D18660" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M134 105.241V113.241C134 113.771 133.789 114.28 133.414 114.655C133.039 115.03 132.53 115.241 132 115.241H117C115.939 115.241 114.922 115.662 114.172 116.413C113.422 117.163 113 118.18 113 119.241C113 120.302 113.422 121.319 114.172 122.069C114.922 122.819 115.939 123.241 117 123.241H132C132.672 123.241 133.342 123.175 134 123.041" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M112.5 115.741C111.572 115.741 110.682 116.11 110.025 116.766C109.369 117.422 109 118.313 109 119.241C109 120.169 109.369 121.059 110.025 121.716C110.682 122.372 111.572 122.741 112.5 122.741H116V115.741H112.5Z" fill="#D18660" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M48 156.241H49C50.0532 156.238 51.0884 156.514 52 157.041V125.241H26V157.041C26.9118 156.514 27.9469 156.238 29 156.241H30C31.0214 156.241 32.0259 156.501 32.9183 156.998C33.8108 157.495 34.5617 158.211 35.1 159.079L35.79 137.351C35.8194 136.519 36.1704 135.731 36.7692 135.153C37.3679 134.575 38.1677 134.252 39 134.252C39.8323 134.252 40.6321 134.575 41.2308 135.153C41.8296 135.731 42.1806 136.519 42.21 137.351L42.9 159.079C43.4383 158.211 44.1892 157.495 45.0817 156.998C45.9741 156.501 46.9786 156.241 48 156.241Z" fill="#00B8F0"/>
<path d="M35.6 143.241L35.788 137.351C35.8173 136.519 36.1684 135.731 36.7672 135.153C37.366 134.575 38.1658 134.252 38.998 134.252C39.8303 134.252 40.63 134.575 41.2288 135.153C41.8275 135.731 42.1786 136.519 42.208 137.351L42.394 143.241H52V125.241H26V143.241H35.6Z" fill="#009FD9"/>
<path d="M48 156.241H49C50.0532 156.238 51.0884 156.514 52 157.041V125.241H26V157.041C26.9118 156.514 27.9469 156.238 29 156.241H30C31.0214 156.241 32.0259 156.501 32.9183 156.998C33.8108 157.495 34.5617 158.211 35.1 159.079L35.79 137.351C35.8194 136.519 36.1704 135.731 36.7692 135.153C37.3679 134.575 38.1677 134.252 39 134.252C39.8323 134.252 40.6321 134.575 41.2308 135.153C41.8296 135.731 42.1806 136.519 42.21 137.351L42.9 159.079C43.4383 158.211 44.1892 157.495 45.0817 156.998C45.9741 156.501 46.9786 156.241 48 156.241V156.241Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39 89.241C33.4305 89.241 28.089 91.4535 24.1508 95.3917C20.2125 99.33 18 104.671 18 110.241V113.241C18.0029 115.545 18.8002 117.778 20.2574 119.563C21.7147 121.349 23.7428 122.577 26 123.041V138.241H32.526C33.1267 137.039 34.0503 136.028 35.1932 135.322C36.3362 134.615 37.6532 134.241 38.9969 134.241C40.3407 134.241 41.6578 134.615 42.8008 135.322C43.9437 136.028 44.8674 137.039 45.468 138.241H52V123.041C54.2572 122.577 56.2853 121.349 57.7426 119.563C59.1998 117.778 59.9971 115.545 60 113.241V110.241C60 104.671 57.7875 99.33 53.8492 95.3917C49.911 91.4535 44.5695 89.241 39 89.241Z" fill="#00B8F0"/>
<path d="M39 89.241C33.4305 89.241 28.089 91.4535 24.1508 95.3917C20.2125 99.33 18 104.671 18 110.241V113.241C17.9999 113.687 18.0293 114.133 18.088 114.575C18.567 109.36 20.9773 104.512 24.8461 100.982C28.7149 97.4523 33.7629 95.4955 39 95.4955C44.2371 95.4955 49.2851 97.4523 53.1539 100.982C57.0227 104.512 59.433 109.36 59.912 114.575C59.9707 114.133 60.0001 113.687 60 113.241V110.241C60 104.671 57.7875 99.33 53.8492 95.3917C49.911 91.4535 44.5695 89.241 39 89.241Z" fill="#4ACFFF"/>
<path d="M39 89.241C33.4305 89.241 28.089 91.4535 24.1508 95.3917C20.2125 99.33 18 104.671 18 110.241V113.241C18.0025 115.545 18.7996 117.779 20.257 119.564C21.7143 121.349 23.7427 122.577 26 123.041V138.241H32.526C33.1267 137.039 34.0503 136.028 35.1932 135.322C36.3362 134.615 37.6532 134.241 38.9969 134.241C40.3407 134.241 41.6578 134.615 42.8008 135.322C43.9437 136.028 44.8674 137.039 45.468 138.241H52V123.041C54.2572 122.577 56.2853 121.349 57.7426 119.563C59.1998 117.778 59.9971 115.545 60 113.241V110.241C60 104.671 57.7875 99.33 53.8492 95.3917C49.911 91.4535 44.5695 89.241 39 89.241V89.241Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39 114.241L47.316 90.953C44.6891 89.8235 41.8595 89.241 39 89.241C36.1405 89.241 33.3109 89.8235 30.684 90.953L39 114.241Z" fill="white" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.238 96.771L39 89.241L32.762 96.771" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M52.464 163.241C52.8152 163.241 53.1604 163.149 53.4647 162.974C53.769 162.798 54.0217 162.546 54.1975 162.242C54.3733 161.938 54.4658 161.593 54.4659 161.241C54.466 160.89 54.3736 160.545 54.198 160.241C53.6711 159.328 52.913 158.57 52.0001 158.044C51.0873 157.517 50.0519 157.24 48.998 157.241H48C46.9461 157.24 45.9106 157.517 44.9978 158.044C44.085 158.57 43.327 159.328 42.8 160.241C42.6244 160.545 42.532 160.89 42.5321 161.241C42.5322 161.593 42.6248 161.938 42.8005 162.242C42.9763 162.546 43.229 162.798 43.5333 162.974C43.8376 163.149 44.1828 163.241 44.5341 163.241H52.464Z" fill="#947746" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M33.464 163.241C33.8152 163.241 34.1604 163.149 34.4647 162.974C34.769 162.798 35.0217 162.546 35.1975 162.242C35.3733 161.938 35.4659 161.593 35.4659 161.241C35.466 160.89 35.3736 160.545 35.198 160.241C34.6711 159.328 33.913 158.57 33.0001 158.044C32.0873 157.517 31.0519 157.24 29.998 157.241H28.998C27.9442 157.24 26.9087 157.517 25.9958 158.044C25.083 158.57 24.3249 159.328 23.798 160.241C23.6224 160.545 23.5299 160.89 23.53 161.241C23.5301 161.593 23.6227 161.938 23.7985 162.242C23.9742 162.546 24.2271 162.798 24.5314 162.974C24.8357 163.149 25.1807 163.241 25.532 163.241H33.464Z" fill="#947746" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39 89.241L35.576 104.653L39 114.241L42.424 104.653L39 89.241Z" fill="#FF6242" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39 123.241V134.255" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39 114.241V115.241" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39 114.241L50 101.241L48 97.241H52L50.8101 92.8789C49.7087 92.1299 48.5388 91.4871 47.316 90.959L39 114.241Z" fill="#00B8F0" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39 114.241L30.686 90.959C29.4624 91.4866 28.2917 92.1294 27.1899 92.8789L26 97.241H30L28 101.241L39 114.241Z" fill="#00B8F0" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M35 123.241H28C27.3284 123.241 26.6584 123.174 26 123.041" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M26 105.241V113.241C26 113.771 26.2107 114.28 26.5858 114.655C26.9609 115.03 27.4696 115.241 28 115.241H35" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M50 115.241C50.3485 115.241 50.691 115.15 50.9934 114.976C51.2958 114.803 51.5476 114.554 51.724 114.253C51.7306 113.621 51.5656 112.998 51.2467 112.452C50.9278 111.906 50.4668 111.457 49.9128 111.152C49.3589 110.847 48.7325 110.698 48.1006 110.721C47.4686 110.744 46.8547 110.937 46.324 111.281L42 115.241H50Z" fill="#FFCEBF" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M52 105.241V113.241C52 113.771 51.7893 114.28 51.4142 114.655C51.0391 115.03 50.5304 115.241 50 115.241H35C33.9391 115.241 32.9218 115.662 32.1716 116.413C31.4215 117.163 31 118.18 31 119.241C31 120.302 31.4215 121.319 32.1716 122.069C32.9218 122.819 33.9391 123.241 35 123.241H50C50.6717 123.241 51.3417 123.175 52 123.041" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.5 115.741C29.5717 115.741 28.6815 116.11 28.0251 116.766C27.3688 117.422 27 118.313 27 119.241C27 120.169 27.3688 121.059 28.0251 121.716C28.6815 122.372 29.5717 122.741 30.5 122.741H34V115.741H30.5Z" fill="#FFCEBF" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M100.698 86.741C100.778 86.7972 100.844 86.872 100.89 86.9591C100.935 87.0461 100.959 87.1428 100.959 87.241C100.959 87.3391 100.935 87.4358 100.89 87.5228C100.844 87.6099 100.778 87.6847 100.698 87.741L91.968 93.9149C91.8762 93.9797 91.7684 94.0181 91.6562 94.0259C91.5441 94.0336 91.4321 94.0104 91.3323 93.9588C91.2324 93.9073 91.1485 93.8293 91.09 93.7334C91.0314 93.6375 91.0003 93.5273 91 93.4149V81.067C91.0003 80.9546 91.0314 80.8444 91.09 80.7485C91.1485 80.6526 91.2324 80.5746 91.3323 80.5231C91.4321 80.4715 91.5441 80.4483 91.6562 80.456C91.7684 80.4638 91.8762 80.5022 91.968 80.567L100.698 86.741Z" fill="white" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M68 87.241H91" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M58.302 71.739C58.2227 71.6819 58.1581 71.6068 58.1135 71.5198C58.069 71.4329 58.0458 71.3366 58.0458 71.239C58.0458 71.1413 58.069 71.0449 58.1135 70.958C58.1581 70.871 58.2227 70.796 58.302 70.739L67.0341 64.5649C67.126 64.5 67.234 64.4617 67.3463 64.454C67.4586 64.4464 67.5707 64.4697 67.6706 64.5215C67.7706 64.5734 67.8543 64.6516 67.9127 64.7477C67.9711 64.8439 68.002 64.9544 68.0019 65.067V77.4149C68.0016 77.5272 67.9706 77.6374 67.912 77.7333C67.8534 77.8292 67.7696 77.9072 67.6698 77.9588C67.57 78.0104 67.4578 78.0336 67.3457 78.0258C67.2336 78.0181 67.1259 77.9797 67.0341 77.9149L58.302 71.739Z" fill="white" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M68 71.241H91" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
`;

export default delegateToAnyoneSvg;
