import React from 'react';

import type { ReviewCycle, UserFilterSegment } from 'models';

import { Box, DesignSystem } from 'components';

import DevelopmentNeeds from './DevelopmentNeeds';

type Props = {
  reviewCycle: ReviewCycle;
  defaultUserFilter?: UserFilterSegment;
};

const Skills = ({ reviewCycle, defaultUserFilter }: Props) => (
  <DesignSystem version={2}>
    <Box additionalClassName="mt-4">
      <DevelopmentNeeds
        reviewCycle={reviewCycle}
        defaultUserFilter={defaultUserFilter}
      />
    </Box>
  </DesignSystem>
);

export default Skills;
