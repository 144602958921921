import React from 'react';

import { Answer } from 'models';

import { useIsLargeScreen } from 'helpers/hooks';

import RatingSliderHeader from '../../SkillsCareerLevelBlockContent/components/RatingSliderHeader';
import RatingSliderResultsWithNoneRating from '../../SkillsCareerLevelBlockContent/components/RatingSliderResultsWithNoneRating';

type Props = {
  closedAnswers: Array<Answer>;
};

export default function DiscreteSliderAnswerResults({ closedAnswers }: Props) {
  const ratingOptions = closedAnswers[0].ratings; // all answers have the same rating base
  const isLargeScreen = useIsLargeScreen();

  if (!isLargeScreen) {
    return null;
  }

  return (
    <div className="mb-4 grid grid-cols-12">
      <RatingSliderHeader ratingOptions={ratingOptions} />
      <RatingSliderResultsWithNoneRating
        ratingOptions={ratingOptions}
        closedAnswers={closedAnswers}
      />
    </div>
  );
}
