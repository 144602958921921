import React from 'react';

import type { Evaluation, UserReview } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';

import { post } from 'redux/actions/api';
import { successNotice } from 'redux/actions/application';

import { Checkbox, ConfirmationModal, MultiSelectable } from 'components';

import { AvatarWithStatus } from 'scenes/components/AvatarWithStatus';

type Props = {
  isActive: boolean;
  toggleModal: () => void;
  userReview: UserReview;
  onAfterAction?: () => Promise<void>;
};

type EvaluationListProps = {
  evaluations: Array<Evaluation>;
  selectedEvaluationIds: Array<string>;
  onSelect: (evaluationId: string) => void;
};

type EvaluationListItemProps = {
  evaluation: Evaluation;
  onSelect: (evaluationId: string) => void;
  selected: boolean;
};

const EvaluationListItem = ({
  evaluation,
  onSelect,
  selected,
}: EvaluationListItemProps) => {
  return (
    <li style={{ marginTop: 16 }}>
      <Checkbox
        isChecked={selected}
        disabled={evaluation.status !== 'shared'}
        onChange={() => onSelect(evaluation.id)}
        label={
          <div style={{ marginTop: '-0.5rem', marginLeft: '0.5rem' }}>
            <AvatarWithStatus
              user={evaluation.reviewer}
              status={evaluation.status}
              tag={evaluation.reviewerTag}
            />
          </div>
        }
      />
    </li>
  );
};

const EvaluationList = ({
  evaluations,
  selectedEvaluationIds,
  onSelect,
}: EvaluationListProps) => (
  <ul>
    {evaluations.map(evaluation => (
      <EvaluationListItem
        key={evaluation.id}
        evaluation={evaluation}
        onSelect={onSelect}
        selected={selectedEvaluationIds.includes(evaluation.id)}
      />
    ))}
  </ul>
);

const ReOpenPeersFeedbackModal = ({
  isActive,
  toggleModal,
  userReview,
  onAfterAction,
}: Props) => {
  const dispatch = useAppDispatch();
  const reOpenPeersFeedback = async (selectedEvaluationIds: Array<string>) => {
    const reviewee = userReview.user;

    await dispatch(
      post(`user_reviews/${userReview.id}/evaluations/reopen`, {
        evaluationIds: selectedEvaluationIds,
      })
    );

    if (onAfterAction) {
      await Promise.resolve(onAfterAction());
    }

    reviewee &&
      (await dispatch(
        successNotice(
          __('You re-opened peers feedback about %1', reviewee.fullName)
        )
      ));
  };

  return (
    <MultiSelectable
      render={multiSelect => (
        <ConfirmationModal
          isActive={isActive}
          isLarge
          cancelLabel={__('Cancel')}
          title={__('Re-open peer feedback')}
          confirmLabel={
            multiSelect.selection().length > 0
              ? n__(
                  'Re-open one peer feedback',
                  'Re-open %1 peers feedback',
                  multiSelect.selection().length
                )
              : __('Select the shared peers feedback you want to re-open')
          }
          onConfirm={async () => {
            await reOpenPeersFeedback(multiSelect.selection());
            multiSelect.updateSelection([]);
            toggleModal();
          }}
          onCancel={() => {
            multiSelect.updateSelection([]);
            toggleModal();
          }}
          confirmDisabled={multiSelect.selection().length === 0}
          refreshContentOnOpening
        >
          <p>
            {__(
              'Select the shared peers feedback about %1 you want to re-open:',
              userReview.user.fullName
            )}
          </p>
          <EvaluationList
            evaluations={userReview.evaluations}
            onSelect={multiSelect.onSelect}
            selectedEvaluationIds={multiSelect.selection()}
          />
        </ConfirmationModal>
      )}
    />
  );
};

export default ReOpenPeersFeedbackModal;
