import React from 'react';

import type { RequestedFeedbackStats as RequestedFeedbackStatsType } from 'models';

import { __ } from 'helpers/i18n';

import SimpleStatsBlock from '../components/SimpleStatsBlock';

type Props = {
  stats: RequestedFeedbackStatsType;
};

export default function RequestedFeedbackStats({ stats }: Props) {
  const formattedStats = [
    {
      label: __('Minimum'),
      value: stats.minimum,
    },
    {
      label: __('Average'),
      value: stats.mean,
    },
    {
      label: __('Maximum'),
      value: stats.maximum,
    },
  ];
  return (
    <SimpleStatsBlock
      title={__('Requested peer feedback')}
      description={__('Number of peer feedback requested by participant.')}
      stats={formattedStats}
    />
  );
}
