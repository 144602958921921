import React from 'react';

import type { ThreeSixtyReviewCycle, ThreeSixtyUserReview } from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import { getAbilityIds } from 'helpers/models/abilities';

import { HamburgerMenu, MenuList, Tooltip } from 'components';

import {
  ManagePeersAction,
  ReleaseAction,
  RemindPeersFeedbackAction,
  RemindPeersNominationAction,
  RemindSelfAssessmentAction,
  ReopenPeersFeedbackAction,
  UnreleaseAction,
} from 'scenes/components/userReview/360/actions';
import { UpdateResponsibleAction } from 'scenes/components/userReview/actions';
import { RemoveParticipantAction } from 'scenes/components/userReview/common/actions';

type Props = {
  userReview: ThreeSixtyUserReview;
  reviewCycle: ThreeSixtyReviewCycle;
  refetchData: () => Promise<void>;
};

export default function ManageReviewActionMenu({
  userReview,
  reviewCycle,
  refetchData,
}: Props) {
  const abilityIds = getAbilityIds(userReview);
  const availableActions = abilityIds
    .map(abilityId => {
      switch (abilityId) {
        case 'unrelease':
          return (
            <UnreleaseAction
              userReview={userReview}
              key="unreleaseAction"
              onAfterAction={refetchData}
            />
          );
        case 'remind_peers_nomination':
          return (
            <RemindPeersNominationAction
              userReview={userReview}
              key="remindPeersNominationAction"
            />
          );
        case 'remind_self_assessment':
          return (
            <RemindSelfAssessmentAction
              userReview={userReview}
              key="remindSelfAssessmentAction"
            />
          );
        case 'remind_peers_feedback':
          return (
            <RemindPeersFeedbackAction
              userReview={userReview}
              key="remindPeersFeedbackAction"
            />
          );
        case 'update_responsible':
          return (
            <UpdateResponsibleAction
              userReview={userReview}
              reviewCycle={reviewCycle}
              onAfterAction={refetchData}
              key="updateResponsibleAction"
            />
          );
        case 'manage_peers':
        case 'change_peers_after_validation':
        case 'validate_peers':
          return (
            <ManagePeersAction
              key="managePeersAction"
              userReview={userReview}
              reviewCycle={reviewCycle}
              onAfterAction={refetchData}
            />
          );
        case 'show_action_to_release':
        case 'show_secondary_action_in_review_to_release':
          return (
            <ReleaseAction
              userReview={userReview}
              key="releaseAction"
              onAfterAction={refetchData}
            />
          );
        case 'remove_participant':
          return (
            <RemoveParticipantAction
              key="removeParticipantAction"
              userReview={userReview}
              reviewCycle={reviewCycle}
            />
          );
        case 'show_reopen_peers_feedback_action': {
          const canReopen = can({
            perform: 'reopen_peers_feedback',
            on: userReview,
          });
          const disabledReason =
            !canReopen && userReview.hasAnonymizedResults
              ? __(
                  'To guarantee anonymity, feedback cannot be reopened for anonymous campaigns'
                )
              : null;
          return (
            <Tooltip enabled={!!disabledReason} content={disabledReason}>
              <ReopenPeersFeedbackAction
                userReview={userReview}
                disabled={!!disabledReason}
                key="reopenPeersFeedbackAction"
              />
            </Tooltip>
          );
        }
        default:
          return null;
      }
    })
    .filter(action => !!action);

  return (
    <React.Fragment>
      <HamburgerMenu
        align="right"
        disabled={availableActions.length === 0}
        disabledMessage={__(
          'You must be responsible for the review to have access to the actions'
        )}
      >
        <MenuList>{availableActions}</MenuList>
      </HamburgerMenu>
    </React.Fragment>
  );
}
