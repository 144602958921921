import React from 'react';

import type { User } from 'models';

import { __ } from 'helpers/i18n';

import { Icon } from 'components';

import ReviewHistory from './ReviewHistory';

type Props = {
  user: User;
};

export default function OneOnOneReviewHistory({ user }: Props) {
  return (
    <ReviewHistory
      user={user}
      icon={<Icon name="history_edu" />}
      title={__('Reviews')}
      emptyState={__('No review has been done')}
      seeMoreLabel={__('See all reviews')}
      type="one_on_one"
    />
  );
}
