import { omit } from 'lodash';
import * as React from 'react';
import { compose } from 'redux';

import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/dataLoader/pagination/types';
import type { UserFilterSegment, UserObjectivePeriodsCollection } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n/index';

import { withDeprecatedPagination } from 'lib/dataLoader';
import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import { Box, DatatableWrapper, EmptyState } from 'components';

import UsersList from './UsersList';

type FetchParams = {
  permission?: string;
};

type Props = WithPaginationProps & {
  fetchPath: string;
  fetchParams?: FetchParams | undefined;
  userFilterSegment: UserFilterSegment | null;
};

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = AfterPaginateProps & {
  userObjectivePeriodsCollection: UserObjectivePeriodsCollection;
  isFetching: boolean;
  hasError: boolean;
};

function UserObjectivesListWithPagination({
  search,
  filter,
  page,
  countPerPage,
  userObjectivePeriodsCollection,
  isFetching,
  hasError,
  onSearchChange,
  onFilterChange,
  previousPageLink,
  nextPageLink,
  getPreviousPage,
  getNextPage,
  userFilterSegment,
}: AfterDataLoaderProps) {
  const userObjectivePeriods = userObjectivePeriodsCollection
    ? userObjectivePeriodsCollection.items
    : [];

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={
          userObjectivePeriodsCollection
            ? omit(userObjectivePeriodsCollection, 'items')
            : null
        }
        search={search}
        page={page}
        countPerPage={countPerPage}
        previousPageLink={previousPageLink}
        nextPageLink={nextPageLink}
        getPreviousPage={getPreviousPage}
        getNextPage={getNextPage}
        onSearchChange={onSearchChange}
        onFilterChange={onFilterChange}
        searchPlaceholder={__('Search employees')}
        totalCountRenderer={count =>
          n__('%1 employee', '%1 employees', count || 0)
        }
        isFetching={isFetching}
        hasError={hasError}
        filters={[
          { param: 'all', label: __('All') },
          { param: 'with_objectives', label: __('With objectives') },
          { param: 'without_objectives', label: __('Without objectives') },
        ]}
        filter={filter}
        userFilter={userFilterSegment}
        renderNoResult={() => (
          <EmptyState
            title={__('No employee matches your search.')}
            inBoxList
          />
        )}
      >
        <UsersList userObjectivePeriods={userObjectivePeriods} />
      </DatatableWrapper>
    </Box>
  );
}

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      search,
      filter,
      userFilterSegment,
      fetchPath,
      fetchParams,
    }: AfterPaginateProps) =>
      get(fetchPath, {
        page: page,
        countPerPage: countPerPage,
        search: search,
        filter: filter,
        userFilter: userFilterSegment,
        ...fetchParams,
      }),
    hydrate: {
      userObjectivePeriodsCollection: {
        items: {
          user: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      filter,
      userFilterSegment,
    }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
        search,
        filter,
        userFilterSegment,
      }),
  })
)(UserObjectivesListWithPagination);
