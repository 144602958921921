import React, { ReactNode } from 'react';

import { __ } from 'helpers/i18n';

import Notification from 'components/Notification';
import RefreshLink from 'components/links/RefreshLink';
import SupportLink from 'components/links/SupportLink';

type Props = {
  message?: ReactNode;
};

export default function DefaultErrorNotification({ message }: Props) {
  return (
    <Notification kind="danger">
      <b>{__('Oops, something went wrong 🙁')}</b>
      <p>
        {!!message
          ? message
          : __(
              'Something went wrong while loading or refreshing this page. Please try to %1 or %2.',
              <RefreshLink>{__('refresh the page')}</RefreshLink>,
              <SupportLink>{__('contact us')}</SupportLink>
            )}
      </p>
    </Notification>
  );
}
