import React from 'react';

import type { OneOnOneUserReview, ReviewCycle } from 'models';

import AutoAddUserReviewItemContent from './AutoAddUserReviewItemContent';
import StandardUserReviewItemContent from './StandardUserReviewItemContent';

type Props = {
  reviewCycle: ReviewCycle;
  userReview: OneOnOneUserReview;
  refetchData: () => Promise<void>;
  additionalReviewerEnabled: boolean;
};

export default function UserReviewItem({
  userReview,
  reviewCycle,
  refetchData,
  additionalReviewerEnabled,
}: Props) {
  const ContentClass = reviewCycle.autoAddParticipantsEnabled
    ? AutoAddUserReviewItemContent
    : StandardUserReviewItemContent;

  return (
    <ContentClass
      reviewCycle={reviewCycle}
      userReview={userReview}
      additionalReviewerEnabled={additionalReviewerEnabled}
      refetchData={refetchData}
    />
  );
}
