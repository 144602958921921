import React from 'react';
import {
  PolarAngleAxis,
  RadialBar,
  RadialBarChart,
  ResponsiveContainer,
} from 'recharts';

type Props = {
  value: number;
  color: string;
};

const SimpleRadialChart = ({ value, color }: Props) => {
  const data = [{ value, fill: color }];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadialBarChart
        startAngle={90}
        endAngle={-270}
        cx="50%"
        cy="50%"
        innerRadius="100%"
        outerRadius="100%"
        barSize={10}
        data={data}
      >
        <PolarAngleAxis
          type="number"
          domain={[0, 100]}
          angleAxisId={0}
          tick={false}
        />
        <RadialBar cornerRadius={10} background dataKey="value" />
        <text
          x={50}
          y={50}
          fill={color}
          fontSize="24px"
          fontWeight="600"
          fontFamily="ProximaNova"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}%
        </text>
      </RadialBarChart>
    </ResponsiveContainer>
  );
};

export default SimpleRadialChart;
