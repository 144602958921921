import React from 'react';

import type { ThreeSixtyReviewCycle, ThreeSixtyUserReview, User } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { sanitizeUnsafeHtmlInput } from 'helpers/htmlInputSanitizer';
import { __, n__ } from 'helpers/i18n';

import { successNotice } from 'redux/actions';
import { validatePeers } from 'redux/actions/resources';

import ManagePeersModal from './ManagePeersModal';

type Props = {
  user: User;
  isActive: boolean;
  onClose: () => void;
  reviewCycle: ThreeSixtyReviewCycle;
  userReview: ThreeSixtyUserReview;
  peers?: Array<User>;
  onAfterValidate?: () => Promise<void>;
};

const ValidatePeersModal = ({
  user,
  isActive,
  onClose,
  reviewCycle,
  userReview,
  peers,
  onAfterValidate,
}: Props) => {
  const dispatch = useAppDispatch();
  const onSubmit = async (selectedUsers: Array<User>) => {
    if (!userReview) return;

    await dispatch(
      validatePeers({
        userReview: userReview,
        users: selectedUsers,
      })
    );

    await dispatch(
      successNotice({
        __html: __(
          '<b>Peers for %1 have been validated!</b><br />An email has been sent to them, and they now can start completing their review.',
          sanitizeUnsafeHtmlInput(user.fullName)
        ),
      })
    );

    if (onAfterValidate) onAfterValidate();
  };
  return (
    <ManagePeersModal
      user={user}
      isActive={isActive}
      onClose={onClose}
      reviewCycle={reviewCycle}
      userReviewId={userReview.id}
      peers={peers}
      onSubmit={onSubmit}
      title={__("Select %1's 360° peer reviewers", user.fullName)}
      submitButtonLabel={(peersCount: number) =>
        n__('Validate one peer', 'Validate %1 peers', peersCount, peersCount)
      }
      shouldValidatePeerConstraints
    />
  );
};

export default ValidatePeersModal;
