import React from 'react';

import type { TitleSize } from '../text/titles';

import classNames from 'helpers/classNames';

import { Title } from 'components';

type Props = {
  color: string;
  height: number;
  children?: React.ReactNode;
  title: string;
  titleSize: TitleSize;
  style?: React.CSSProperties;
};

function Wireframe({
  children,
  color,
  height,
  title,
  titleSize,
  style,
}: Props) {
  return (
    <div>
      <Title size={titleSize}>{title}</Title>
      <div
        className={classNames('wireframe', `wireframe-${color}`)}
        style={{ ...style, padding: `${height / 2}px 5px` }}
      >
        {children}
      </div>
    </div>
  );
}

Wireframe.defaultProps = {
  color: 'default',
  height: 50,
  titleSize: 5,
};

export default Wireframe;
