import * as React from 'react';

import type { Props } from './index';

import { Flex, Level, LevelLeft, LevelRight, Tabs } from 'components';

export default function FullWidthSubmenuBar({
  tabItems,
  backButton,
  statusInfo,
  children,
  actions,
}: Props) {
  return (
    <div className="full-width-submenu-bar mt-14">
      <Level>
        <LevelLeft>
          <Flex style={{ justifyContent: 'space-between' }}>
            <div className="full-width-submenu-bar-back-button-container">
              {backButton}
            </div>
            <div className="full-width-submenu-bar-status-info">
              {statusInfo}
            </div>
          </Flex>
          {!!children && (
            <div className="full-width-submenu-bar-content mt-4">
              {children}
            </div>
          )}
        </LevelLeft>
        {actions && (
          <LevelRight>
            <div className="full-width-submenu-bar-actions">{actions}</div>
          </LevelRight>
        )}
      </Level>
      {tabItems && (
        <Tabs tabsPosition="center" tabsWidth="fit-content" items={tabItems} />
      )}
    </div>
  );
}
