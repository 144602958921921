import { capitalize, sortBy } from 'lodash';
import React from 'react';

import type { PeopleReviewRatingOption } from 'models';

import { __ } from 'helpers/i18n';

import { Select } from 'components';

type Option = {
  label: string;
  value: string;
};

const ratingToSelectOption = ({
  label,
  value,
}: PeopleReviewRatingOption): Option => ({
  label: capitalize(label),
  value,
});

type Props = {
  value: string | undefined | null;
  options: Array<PeopleReviewRatingOption>;
  onChange: (rating?: string | null) => void;
};

export default function RatingOptionSelect({
  value,
  options,
  onChange,
}: Props) {
  const selectedRatingOption =
    options.find(option => option.value === value) || null;

  return (
    <Select
      value={
        !!selectedRatingOption
          ? ratingToSelectOption(selectedRatingOption)
          : null
      }
      options={sortBy(options, 'position').map(ratingOption =>
        ratingToSelectOption(ratingOption)
      )}
      onChange={option =>
        !Array.isArray(option) && onChange(!!option ? option.value : null)
      }
      placeholder={__('Select an answer')}
      isClearable
    />
  );
}
