import React from 'react';

import { Placeholder } from 'components';

export default function HistoryItemPlaceholder() {
  return (
    <div className="history-item-placeholder">
      <Placeholder width={200} />
      <br />
      <Placeholder width={150} />
    </div>
  );
}
