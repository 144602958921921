import { SurveyMultipleChoiceQuestion, SurveyQuestion } from './';
import { SurveyInstruction } from './';

type SurveyFormStepBase = {
  id: string;
  position: number;
  questionMandatory: boolean;
  commentEnabled: boolean;
};

export type SurveyQuestionFormStep<
  QuestionType extends SurveyQuestion = SurveyQuestion
> = SurveyFormStepBase & {
  question: QuestionType;
  instruction: null;
};

export type SurveyInstructionFormStep = SurveyFormStepBase & {
  question: null;
  instruction: SurveyInstruction;
};

export type SurveyFormStep = SurveyQuestionFormStep | SurveyInstructionFormStep;

export const isQuestionFormStep = (
  step: SurveyFormStep
): step is SurveyQuestionFormStep => {
  return !!step.question;
};

export const isInstructionFormStep = (
  step: SurveyFormStep
): step is SurveyInstructionFormStep => {
  return !!step.instruction;
};

export const isMultipleChoiceQuestionFormStep = (
  step: SurveyFormStep
): step is SurveyQuestionFormStep<SurveyMultipleChoiceQuestion> => {
  return (
    isQuestionFormStep(step) && step.question.questionType === 'multiple_choice'
  );
};

export const isSingleChoiceQuestionFormStep = (
  step: SurveyFormStep
): step is SurveyQuestionFormStep<SurveyMultipleChoiceQuestion> => {
  return (
    isQuestionFormStep(step) && step.question.questionType === 'single_choice'
  );
};
