import { fill } from 'lodash';
import React from 'react';

import type { User } from 'models';

import colors from 'styles/colors';

import { __ } from 'helpers/i18n';

import {
  Column,
  Columns,
  EmptyState,
  LoadingOverlay,
  Overlay,
} from 'components';

import ReportCard from './ReportCard';

type Props = {
  indirectReports: Array<User>;
  isFetching: boolean;
  displayReportPlaceholder: boolean;
};

const minPageHeight = 360;

const IndirectReportList = ({
  indirectReports,
  isFetching,
  displayReportPlaceholder,
}: Props) => {
  let missingElementsCount = 9 - indirectReports.length;
  // next line is a hack: we share the hydrating with the mobile version that displays 10 elements
  // so it can happen that we have more than 9 indirect reports if user has resized his window
  // https://elevohq.atlassian.net/browse/ETR-1752
  missingElementsCount =
    missingElementsCount >= 0
      ? missingElementsCount
      : 3 - (indirectReports.length % 3);
  const indirectReportsWithPadding = [
    ...indirectReports,
    ...fill(Array(missingElementsCount), null),
  ];

  return (
    <div style={{ position: 'relative', minHeight: minPageHeight }}>
      {isFetching && (
        <LoadingOverlay
          backgroundColor="elevo-light-background"
          style={{
            zIndex: 5,
          }}
          color={colors.darkestGrey}
        />
      )}

      {!isFetching && indirectReports.length === 0 ? (
        <Overlay>
          <EmptyState title={__('No user matches your search')} />
        </Overlay>
      ) : (
        <Columns isMultiline>
          {indirectReportsWithPadding.map((report, index) => (
            <Column tabletSize={4} isVerticallyCentered key={index}>
              <ReportCard
                report={report}
                displayReportPlaceholder={displayReportPlaceholder}
              />
            </Column>
          ))}
        </Columns>
      )}
    </div>
  );
};

export default IndirectReportList;
