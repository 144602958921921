import React, { Fragment } from 'react';

import { __ } from 'helpers/i18n';

import { ContentContainer, Text } from 'components';
import { PageHeader } from 'components/appLayout';

import MatrixPaginatedList from 'scenes/skills/components/MatrixPaginatedList';

export default function MatrixExplorer() {
  return (
    <Fragment>
      <PageHeader
        title={__('Career explorer')}
        description={
          <Text additionalClassName="mb-4">
            {__(
              'Explore careers, discover how to progress and reach your goals. Find out about opportunities and possibilities for growth within the company.'
            )}
          </Text>
        }
      />

      <ContentContainer>
        <MatrixPaginatedList
          paginationType="url"
          displayTitleOnly
          fromCareerExplorer
          emptyStateProps={{
            iconName: 'tools_ladder',
            title: __(
              'Discover the analysis of careers available within the company'
            ),
            description: __(
              'No skills matrix has been published. Check back soon to discover opportunities and possibilities for growth within the company.'
            ),
          }}
        />
      </ContentContainer>
    </Fragment>
  );
}
