import { keyBy } from 'lodash';
import React from 'react';

import type { GlobalStatuses, SimpleStatuses } from 'models';

import colors from 'styles/colors';

import { __ } from 'helpers/i18n';

import SimpleStatusesBlock from '../components/SimpleStatusesBlock';

type Props = {
  globalStatuses: GlobalStatuses;
};

export default function GlobalStatusChart({ globalStatuses }: Props) {
  const statuses = keyBy(globalStatuses.values, value => value.label);
  const { overallProgressPercentage } = globalStatuses;
  const formattedStatuses: SimpleStatuses = [];

  if (!!statuses['waiting_for_peer_selection']) {
    formattedStatuses.push({
      ...statuses['waiting_for_peer_selection'],
      label: __('Waiting for peer nomination'),
      color: colors.pieChart.red,
    });
  }

  if (!!statuses['waiting_for_peer_nomination']) {
    formattedStatuses.push({
      ...statuses['waiting_for_peer_nomination'],
      label: __('Waiting for peer nomination'),
      color: colors.pieChart.red,
    });
  }

  if (!!statuses['waiting_for_peer_validation']) {
    formattedStatuses.push({
      ...statuses['waiting_for_peer_validation'],
      label: __('Waiting for peer validation'),
      color: colors.pieChart.yellow,
    });
  }

  formattedStatuses.push(
    {
      ...statuses['peer_feedback_in_progress'],
      label: __('Feedback in progress'),
      color: colors.pieChart.yellow,
    },
    {
      ...statuses['peer_feedback_completed'],
      label: __('Feedback shared to responsible'),
      color: colors.pieChart.green,
    }
  );

  if (!!statuses['peer_feedback_shared_with_reviewee']) {
    formattedStatuses.push({
      ...statuses['peer_feedback_shared_with_reviewee'],
      label: __('Feedback shared with reviewee'),
      color: colors.pieChart.green,
    });
  }

  return (
    <SimpleStatusesBlock
      statuses={formattedStatuses}
      title={__('Global status')}
      overallProgressPercentage={overallProgressPercentage}
      subtitle={__('%1% finished', overallProgressPercentage.toString())}
    />
  );
}
