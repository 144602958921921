declare const preval: (code: TemplateStringsArray) => any;

/**
 * Type is not available and may change over time.
 *
 * ## Colors
 *
 * Available colors can be found here: client/src/styles/v2/const/_color.sass
 *
 * @example
 * ```js
 * tailwindTheme.colors['green-success']
 * ```
 */
export default preval`
  const tailwindConfig = require('../../tailwind.config');
  module.exports = tailwindConfig.theme.extend;
`;
