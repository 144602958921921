import React from 'react';

import type { Answer } from 'models';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToEvaluation } from 'helpers/paths';

import { Helper, Link, Text, Tooltip } from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

type Props = {
  answers: Array<Answer>;
};

const NotSharedAnswersHelper = ({ answers }: Props) => {
  const activeUser = useActiveUser();
  const activeUserAnswer = answers.find(
    answer => answer.author.id === activeUser.id
  );

  const isReviewee =
    !!activeUserAnswer && activeUserAnswer.authorRole === 'reviewee';
  const revieweeAnswer = isReviewee
    ? activeUserAnswer
    : answers.find(answer => answer.authorRole === 'reviewee');

  const otherAnswers = answers.filter(
    answer =>
      answer.author.id !== activeUser.id &&
      ['reviewer', 'additional_reviewer'].includes(answer.authorRole)
  );

  const shouldCollapse = otherAnswers.length > 1;

  const getEvaluationPath = ({ evaluationId }: Answer) => {
    invariant(evaluationId, 'evaluationId must be defined');

    return pathToEvaluation(evaluationId);
  };

  return (
    <div className="feedback-zone-helper">
      {!isReviewee && revieweeAnswer && (
        <Helper testClassName="test-not-shared-helper test-reviewee-not-shared">
          {__(
            '%1 has not shared their comments yet',
            revieweeAnswer.author.fullName
          )}
        </Helper>
      )}

      {!!activeUserAnswer && (
        <Helper testClassName="test-not-shared-helper test-you-not-shared">
          <Text>
            {__('You haven’t shared your answers yet:')} &nbsp;
            <Link to={getEvaluationPath(activeUserAnswer)}>
              {__('continue my assessment')}
            </Link>
          </Text>
        </Helper>
      )}

      {!!otherAnswers.length && (
        <Tooltip
          content={
            shouldCollapse
              ? otherAnswers.map((answer, idx) => (
                  <UserAvatar
                    key={answer.author.id}
                    user={answer.author}
                    style={idx === 0 ? undefined : { marginTop: 8 }}
                  />
                ))
              : undefined
          }
        >
          {shouldCollapse ? (
            <Helper testClassName="test-not-shared-helper">
              {__(
                '%1 reviewers have not shared their answers yet',
                otherAnswers.length
              )}
            </Helper>
          ) : (
            <Helper testClassName="test-not-shared-helper test-reviewer-not-shared">
              {__(
                '%1 has not shared their comments yet',
                otherAnswers[0].author.fullName
              )}
            </Helper>
          )}
        </Tooltip>
      )}
    </div>
  );
};

export default NotSharedAnswersHelper;
