import React from 'react';
import { Link } from 'react-router-dom';

import type { InteractionType } from 'models';

import { __ } from 'helpers/i18n';
import { pathToTemplates } from 'helpers/paths';

import { StrictlySanitizedHtml } from 'components';
import Notification from 'components/Notification';
import SupportLink from 'components/links/SupportLink';

type Props = {
  interactionType: InteractionType;
};

const NotEditableInBuilderHeaderMessage = ({ interactionType }: Props) => {
  return (
    <Notification kind="primary">
      <StrictlySanitizedHtml
        componentType="p"
        html={__(
          '<b>This template cannot be edited</b> because it was created by the Elevo Support team. You can continue to access the preview and use the model as is in a new review campaign.'
        )}
      />
      <br />
      <p>
        {__(
          'You can %1 or %2 to edit this template',
          <Link to={pathToTemplates(interactionType)}>
            {__('create a new template now')}
          </Link>,
          <SupportLink>{__('contact the support')}</SupportLink>
        )}
      </p>
    </Notification>
  );
};

export default NotEditableInBuilderHeaderMessage;
