import type { Occupation } from 'models';

const computePercentChange = (
  value1: number | null | undefined,
  value2: number | null | undefined
): number | null =>
  !!value1 && !!value2 ? ((value2 - value1) / value1) * 100 : null;

const addProgressPercent = (
  occupations: Array<Occupation>
): Array<Occupation> => {
  return occupations.map((occupation, index) => {
    const previousOccupation =
      index === 0 ? occupation : occupations[index - 1];

    const fixedRemunerationPercentChange =
      previousOccupation.fixedRemunerationCurrency ===
      occupation.fixedRemunerationCurrency
        ? computePercentChange(
            previousOccupation.fixedRemunerationCents,
            occupation.fixedRemunerationCents
          )
        : null;

    const variableRemunerationPercentChange =
      previousOccupation.variableRemunerationCurrency ===
      occupation.variableRemunerationCurrency
        ? computePercentChange(
            previousOccupation.variableRemunerationCents,
            occupation.variableRemunerationCents
          )
        : null;

    return {
      ...occupation,
      fixedRemunerationPercentChange,
      variableRemunerationPercentChange,
    };
  });
};

export default addProgressPercent;
