// @flow
import type { DataFetcher } from 'lib/dataLoader/DataLoader';
import type { RequestStatus } from 'lib/dataLoader/reducer';
import type { ReduxStore } from 'redux/reducers/index';

import { dataLoader } from 'lib/dataLoader/DataLoader';
import hydrateFromResponse from 'lib/dataLoader/hydration/hydrateFromResponse';

type PropsToFetch<T> = (props: T) => DataFetcher;
type CacheKeyProvider<T> = (props: T) => string;
type HydrateDefinition = { [hydratedPropName: string]: {} };

type DataLoaderProps<T> = {|
  fetch: PropsToFetch<T>,
  hydrate: HydrateDefinition,
  cacheKey?: CacheKeyProvider<T>,
|};

function hydrator(hydrateDefinition: HydrateDefinition) {
  const hydratedPropName = Object.keys(hydrateDefinition)[0];

  // $FlowFixMe: Migrate to TS
  return (state: ReduxStore, requestStatus: RequestStatus) => {
    let value;

    if (!requestStatus.isFetching && !requestStatus.hasError) {
      value = hydrateFromResponse(
        state.data,
        requestStatus.value,
        hydrateDefinition,
        requestStatus.requestId
      );
    }

    return {
      [hydratedPropName]: value,
    };
  };
}

// FIXME: Actually type this thing..
export default function newDataLoader({
  fetch,
  cacheKey,
  hydrate,
}: DataLoaderProps<*>) {
  const connector = hydrator(hydrate);
  return dataLoader(props => ({
    fetch: fetch(props),
    connect: connector,
    cacheKey: cacheKey && cacheKey(props),
  }));
}
