import React, { Dispatch, Fragment, SetStateAction, useContext } from 'react';

import useSectionSearch from 'helpers/hooks/useSectionSearch';
import { __ } from 'helpers/i18n';

import { BoxSeparator, SearchBar } from 'components';

import { DataContext } from './DataContext';
import FilterText from './FilterText';
import Segments from './Segments';
import { ActiveFilters, FilterableField } from './types';

type Props = {
  selectedFilter: FilterableField | null;
  onFilterChange: (filter: FilterableField | null) => void;
  activeFilters: ActiveFilters;
  setActiveFilters: Dispatch<SetStateAction<ActiveFilters>>;
};

const FilterList = ({
  selectedFilter,
  onFilterChange,
  activeFilters,
  setActiveFilters,
}: Props) => {
  const { filterableFields, quickFilters } = useContext(DataContext);
  const [filteredSections, onSearchChange, filterSearch] = useSectionSearch(
    filterableFields,
    element => element.label
  );

  const removeActiveFilter = (slug: string) => {
    const { [slug]: _, ...rest } = activeFilters;
    setActiveFilters(rest);
  };

  const enabledQuickFilters = !!quickFilters
    ? quickFilters.filter(quickFilter => quickFilter.enabled)
    : [];

  if (selectedFilter) {
    return (
      <Segments
        activeSegment={activeFilters[selectedFilter.slug] || null}
        onSegmentSelect={segment => {
          if (!segment) {
            removeActiveFilter(selectedFilter.slug);
            return;
          }

          setActiveFilters({
            ...activeFilters,
            [selectedFilter.slug]: segment,
          });
        }}
        onFilterChange={onFilterChange}
        selectedFilter={selectedFilter}
      />
    );
  }

  return (
    <Fragment>
      <SearchBar
        value={filterSearch}
        placeholder={__('Filter by...')}
        onChange={onSearchChange}
        additionalClassName="mb-2"
      />

      <div className="flex flex-col">
        {enabledQuickFilters.length !== 0 && (
          <Fragment>
            {enabledQuickFilters.map(quickFilter => {
              const isActive =
                !!activeFilters[quickFilter.slug] &&
                activeFilters[quickFilter.slug].value ===
                  quickFilter.segmentValue;

              return (
                <FilterText
                  key={quickFilter.slug}
                  label={quickFilter.label}
                  isActive={isActive}
                  onLinkClick={() => quickFilter.onSelect(setActiveFilters)}
                  onRemoveClick={() => removeActiveFilter(quickFilter.slug)}
                />
              );
            })}

            <BoxSeparator additionalClassName="my-1 mx-0" />
          </Fragment>
        )}
        {filteredSections.map(section => {
          const isNotFirstSection = section !== filteredSections[0];

          return section.map(filter => {
            const isActive = !!activeFilters[filter.slug];
            const activeFilterLabel = activeFilters[filter.slug]?.label;
            const isFirstFilter = filter === section[0];

            return (
              <Fragment key={filter.slug}>
                {isNotFirstSection && isFirstFilter && (
                  <BoxSeparator additionalClassName="my-1 mx-0" />
                )}

                <FilterText
                  label={filter.label}
                  isActive={isActive}
                  activeFilterLabel={activeFilterLabel}
                  onLinkClick={() => onFilterChange(filter)}
                  onRemoveClick={() => removeActiveFilter(filter.slug)}
                />
              </Fragment>
            );
          });
        })}
      </div>
    </Fragment>
  );
};

export default FilterList;
