import React from 'react';

import { __ } from 'helpers/i18n';

import { HamburgerMenu, MenuItem, MenuList, Testable } from 'components';

type Props = {
  disabled: boolean;
  hasMeasure: boolean;
  onRemoveMeasure: () => any;
  onAddMeasures: () => any;
  onDestroy: () => Promise<unknown>;
};

export default function KeyResultActions({
  disabled,
  hasMeasure,
  onRemoveMeasure,
  onAddMeasures,
  onDestroy,
}: Props) {
  if (disabled) return null;

  return (
    <Testable name="test-key-result-actions">
      <HamburgerMenu
        disabled={disabled}
        size="small"
        color="default"
        align="right"
      >
        <MenuList style={{ textAlign: 'right' }}>
          {hasMeasure ? (
            <MenuItem key="remove_measure" onClick={onRemoveMeasure}>
              {__('Delete measures')}
            </MenuItem>
          ) : (
            <MenuItem key="add_measure" onClick={onAddMeasures}>
              {__('Measure this KPI')}
            </MenuItem>
          )}

          <MenuItem key="delete" onClick={onDestroy} isDanger>
            {__('Delete')}
          </MenuItem>
        </MenuList>
      </HamburgerMenu>
    </Testable>
  );
}
