import React from 'react';
import { connect } from 'react-redux';

import type { ParticipantAddition } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { __, n__ } from 'helpers/i18n';

import { get } from 'redux/actions/api';

import { LoadingOverlay, Text, Title4 } from 'components';
import DisabledLayer from 'components/DisabledLayer';
import { Poller } from 'components/behaviors';

type Props = {
  participantAddition: ParticipantAddition;
  reviewCycleId: string;
};

type AfterConnectProps = Props & {
  refreshReviewCycle: () => Promise<void>;
};

const PendingParticipantAddition = ({
  participantAddition,
  refreshReviewCycle,
}: AfterConnectProps) => {
  if (participantAddition.status === 'failed') {
    return <DisabledLayer active>Oops, something went wrong</DisabledLayer>;
  }
  return (
    <React.Fragment>
      <Poller
        poll={() => refreshReviewCycle()}
        initialDelayMs={2000}
        everyMs={3000}
      />
      <LoadingOverlay>
        <Title4>{__('We are adding participants to your campaign.')}</Title4>
        <p>
          <Text>
            {n__(
              '%1 participant is being added to your campaign and will receive an email shortly.',
              '%1 participants are being added to your campaign and will receive an email shortly.',
              participantAddition.participantCount
            )}
          </Text>
        </p>
        <p>
          <Text color="info">
            {__('Beyond 4,000 users it may take more than 10 minutes.')}
          </Text>
        </p>
        <p>
          <Text color="info">
            {__(
              'You can wait, or you can leave this page and come back later 🙂'
            )}
          </Text>
        </p>
      </LoadingOverlay>
    </React.Fragment>
  );
};

export default connect(null, (dispatch: AppDispatch, props: Props) => ({
  refreshReviewCycle: () =>
    dispatch(get(`review_cycles/${props.reviewCycleId}`)),
}))(PendingParticipantAddition);
