import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import type { Organization, User } from 'models';

import can from 'helpers/can';
import { type Match } from 'helpers/paths';
import { pathToHome } from 'helpers/paths';
import {
  withActiveUserAndOrganization,
  withOrganization,
} from 'helpers/withSessionProps';
import { withActiveUser } from 'helpers/withSessionProps';

import {
  BreadcrumbAnchor,
  OnlyLoggedOutRoute,
  Redirect,
  Route,
  Switch,
} from 'components';

import Homepage from 'scenes/Homepage';
import Callback from 'scenes/authentication/Callback';
import ForgotPassword from 'scenes/authentication/ForgotPassword';
import NotSignedInRedirect from 'scenes/authentication/NotSignedInRedirect';
import SignIn from 'scenes/authentication/SignIn';
import ValidateAccessCode from 'scenes/authentication/SignIn/ValidateAccessCode';
import AppLayout from 'scenes/components/AppLayout';
import ENPSRoutes from 'scenes/enps/Routes';
import ObjectiveDashboard from 'scenes/objectives/Dashboard';

import SkillsRoutes from '././skills/Routes';
import DeprecatedHomeRoutes from './DeprecatedHomeRoutes';
import EmailNotificationsDisabledModal from './EmailNotificationsDisabledModal';
import MyTeam from './MyTeam';
import RedirectToDownload from './RedirectToDownload';
import ServerErrorPage from './ServerErrorPage';
import UserProfile from './UserProfile';
import AdminRoutes from './admin/Routes';
import TemplatePreview from './admin/reviewTemplates/Template/Preview';
import AuditsRoutes from './audits/Routes';
import AcceptInvitation from './authentication/AcceptInvitation';
import ResetPassword from './authentication/ResetPassword';
import ManagerCycleIndex from './cycles';
import PersonalObjectives from './objectives/PersonalObjectives';
import TeamObjectivesRoutes from './objectives/team/Routes';
import PeopleReviewRoutes from './peopleReview/Routes';
import Evaluation from './review/Evaluation';
import ReviewsRoutes from './reviews/Routes';
import ServiceRoutes from './service/Routes';
import SurveyRoutes from './surveys/Routes';
import TrainingsRoutes from './trainings/Routes';

/*
  Routes displayed inside the main app, the app bar being displayed
*/
type AppRoutesProps = {
  match: Match<any>;
  organization: Organization;
  activeUser: User;
};

const _AppRoutes = ({ match, organization, activeUser }: AppRoutesProps) => {
  const canAccessToAuditReports = can({
    perform: 'show_audit_reports_menu',
    on: organization,
  });

  return (
    <AppLayout>
      <EmailNotificationsDisabledModal />
      <BreadcrumbAnchor name="user_review_list" defaultTo={pathToHome()} />
      <Switch>
        <Route path={`${match.path}/welcome`} component={Homepage} />
        {/* This is for backward compatibility with already sent e-mails */}
        <Route path={`${match.path}/home`} component={DeprecatedHomeRoutes} />
        <Route
          path="/o/:organizationShortName/redirect"
          component={RedirectToDownload}
        />
        <Route path={`${match.path}/reviews`} component={ReviewsRoutes} />
        <Route
          path={`${match.path}/objectives/dashboard`}
          render={() => <ObjectiveDashboard />}
        />
        <Route
          path={`${match.path}/objectives/personal`}
          render={() => <PersonalObjectives user={activeUser} />}
        />
        <Route path={`${match.path}/my-team`} component={MyTeam} />
        {organization.settings.areTeamObjectivesEnabled &&
          !activeUser.external && (
            <Route
              path={`${match.path}/objectives/teams`}
              component={TeamObjectivesRoutes}
            />
          )}
        {can({
          perform: 'show_people_reviews_menu',
          on: organization,
        }) && (
          <Route
            path={`${match.path}/people-review`}
            component={PeopleReviewRoutes}
          />
        )}
        {canAccessToAuditReports && (
          <Route path={`${match.path}/audits`} component={AuditsRoutes} />
        )}
        {/* For now we're gonna duplicate it in here */}
        <Route
          path={`${match.path}/admin/templates/:id/preview/v2/:role`}
          exact
        >
          <TemplatePreview />
        </Route>
        <Route path={`${match.path}/admin`} component={AdminRoutes} />
        <Route path={`${match.path}/user/:id`} component={UserProfile} />
        <Route
          path={`${match.path}/cycles/:id`}
          component={ManagerCycleIndex}
        />
        <Route path={`${match.path}/reviews`} component={ReviewsRoutes} />
        <Route
          path={`${match.path}/evaluations/:evaluationId`}
          component={Evaluation}
        />
        <Route path={`${match.path}/enps`} component={ENPSRoutes} />
        {organization.plan.skillsAndCareersEnabled && (
          <Route path={`${match.path}/skills`} component={SkillsRoutes} />
        )}
        <Route path={`${match.path}/surveys`} component={SurveyRoutes} />
        <Route path={`${match.path}/trainings`} component={TrainingsRoutes} />
        <Redirect to={pathToHome()} />
      </Switch>
    </AppLayout>
  );
};

const AppRoutes = compose<React.ComponentType<AppRoutesProps>>(
  withActiveUser,
  withOrganization
)(_AppRoutes);

/*
  Base level routes
*/
type RoutesProps = {
  activeUser: User;
  organization: Organization;
  location: {
    pathname: string;
    search: string;
    hash: string;
    state?: any;
    key?: string;
  };
};

const _Routes = ({ activeUser, organization, location }: RoutesProps) => {
  const loggedIn = activeUser && organization;

  return (
    <Switch>
      <Route path="/service">
        <ServiceRoutes />
      </Route>

      <OnlyLoggedOutRoute path="/signin" component={SignIn} />
      <OnlyLoggedOutRoute path="/access_code" component={ValidateAccessCode} />
      <Route path="/auth/callback" component={Callback} />
      <Route path="/auth/notsignedin" component={NotSignedInRedirect} />
      <OnlyLoggedOutRoute path="/forgot_password" component={ForgotPassword} />
      <Route
        path="/o/:organizationShortName/accept_invitation"
        component={AcceptInvitation}
      />
      <Route
        path="/o/:organizationShortName/reset_password"
        component={ResetPassword}
      />

      {loggedIn && (
        <Route path="/o/:organizationShortName" component={AppRoutes} />
      )}

      <Route path="/500" children={ServerErrorPage} />

      {loggedIn && <Redirect to={`/o/${organization.shortName}/welcome`} />}
      {!loggedIn && (
        <Redirect
          to={{
            pathname: '/auth/notsignedin',
            state: {
              redirectTo: location.pathname + location.search + location.hash,
            },
          }}
        />
      )}
    </Switch>
  );
};

export const Routes = compose<React.ComponentType<{}>>(
  withRouter,
  withActiveUserAndOrganization
)(_Routes);
