import React, { Fragment, useState } from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { FilterParam, WithPaginationProps } from 'lib/pagination/types';
import type { PaginatedCollection, TrainingRequest } from 'models';

import compositeKey from 'helpers/compositeKey';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useAppDispatch, useOrganization } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import { approvalStatusLabelWithIcon } from 'helpers/models/trainingRequest';
import { pathToTrainingSessionDetails } from 'helpers/navigation';
import confirmAsync from 'helpers/react/confirmAsync';

import { newDataLoader } from 'lib/dataLoader';
import withPagination from 'lib/pagination/withPagination';
import { del, get } from 'redux/actions/api';

import {
  Box,
  DatatableWrapper,
  EmptyState,
  FetchContainer,
  HamburgerMenu,
  Icon,
  Link,
  MenuItem,
  MenuList,
  SimpleSelectableTable,
  Text,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

import RequestManagementModal from '../components/RequestManagementModal';
import BulkActions from './BulkActions';
import NoRecordState from './NoRecordState';

type Props = {
  currentPeriodSlug: string;
  defaultFilter: FilterParam;
  onAfterAction?: () => Promise<void>;
};

type AfterPaginateProps = Props & WithPaginationProps;

type AfterDataLoaderProps = DataLoaderProvidedProps &
  AfterPaginateProps & {
    trainingRequestCollection: PaginatedCollection<TrainingRequest>;
  };

const TrainingRequestList = ({
  currentPeriodSlug,
  onAfterAction,
  page,
  countPerPage,
  queryParams,
  setPreviousPageParams,
  setNextPageParams,
  setQueryParams,
  trainingRequestCollection,
  isFetching,
  hasError,
  refetchData,
}: AfterDataLoaderProps) => {
  const [focusedTrainingRequest, setFocusedTrainingRequest] =
    useState<TrainingRequest | null>(null);
  const organization = useOrganization();
  const newTrainingSessionFieldsEnabled = organization.featureFlags.includes(
    'newTrainingSessionFields'
  );

  const { search, filter, userFilters } = queryParams;

  const dispatch = useAppDispatch();

  const deleteTrainingRequest = async (request: TrainingRequest) => {
    await dispatch(
      del(`training/periods/${currentPeriodSlug}/requests/${request.id}`)
    );

    await refetchData();
    !!onAfterAction && onAfterAction();
  };

  const confirmRequestDeletion = (request: TrainingRequest) =>
    confirmAsync(
      __('Confirm deletion'),
      <Fragment>
        <p>
          {__(
            "This action will remove the training request from %1's profile. The employee will be informed by email that the training request has been removed.",
            request.trainee.fullName
          )}
        </p>
        <p>{__('Are you sure you want to remove this training request ?')}</p>
        <div style={{ marginTop: 16 }}>
          <Text color="danger">
            <Icon style={{ marginRight: 8 }} name="warning" />
            {__('This action cannot be undone')}
          </Text>
        </div>
      </Fragment>,
      {
        confirmLabel: __('Remove request'),
        isDanger: true,
        onConfirm: async () => deleteTrainingRequest(request),
      }
    );

  return (
    <Fragment>
      <Box>
        <DatatableWrapper
          collectionInfo={trainingRequestCollection}
          search={search}
          page={page}
          countPerPage={countPerPage}
          getPreviousPage={setPreviousPageParams}
          getNextPage={setNextPageParams}
          onQueryParamsChange={setQueryParams}
          isFetching={isFetching}
          hasError={hasError}
          totalCountRenderer={(count?: number | null) =>
            n__('%1 training request', '%1 training requests', count || 0)
          }
          renderNoRecord={() => <NoRecordState />}
          renderNoResult={() => (
            <EmptyState title={__('No training request matches your search')} />
          )}
          userFilters={userFilters}
          filter={filter}
          filters={[
            {
              param: 'waiting_for_approval',
              label: __('Waiting for approval'),
            },
            { param: 'approved', label: __('Approved') },
            { param: 'refused', label: __('Refused') },
            { param: 'all', label: __('All') },
          ]}
          withSearch
          withUserMultiFilters
        >
          <FetchContainer
            isFetching={isFetching}
            hasError={hasError}
            loadingStyle="overlay"
            render={() => (
              <SimpleSelectableTable
                rows={
                  !!trainingRequestCollection
                    ? trainingRequestCollection.items
                    : []
                }
                keyFn={trainingRequest => trainingRequest.id}
                columns={[
                  {
                    header: __('Request summary'),
                    cell: trainingRequest => {
                      return (
                        <Link
                          hasInheritedColor
                          onClick={() =>
                            setFocusedTrainingRequest(trainingRequest)
                          }
                        >
                          {trainingRequest.title}
                        </Link>
                      );
                    },
                  },
                  {
                    header: __('Session name'),
                    cell: trainingRequest => {
                      if (trainingRequest.session)
                        return (
                          <Link
                            hasInheritedColor
                            to={pathToTrainingSessionDetails(
                              trainingRequest.session.id
                            )}
                          >
                            {trainingRequest.session?.name ||
                              __('Untitled session')}
                          </Link>
                        );

                      return (
                        <Text transformation="italic" color="light">
                          {__('Not linked to a session')}
                        </Text>
                      );
                    },
                  },
                  {
                    header: __('Employee'),
                    cell: request => (
                      <UserAvatar user={request.trainee} withJobTitle />
                    ),
                  },
                  {
                    header: __('Status'),
                    cell: request =>
                      approvalStatusLabelWithIcon(request.approvalStatus),
                  },
                  {
                    header: '',
                    cell: request => (
                      <HamburgerMenu>
                        <MenuList>
                          <MenuItem
                            onClick={() => confirmRequestDeletion(request)}
                            isDanger
                          >
                            {__('Delete')}
                          </MenuItem>
                        </MenuList>
                      </HamburgerMenu>
                    ),
                  },
                ]}
                additionalClassName="mb-2"
                rowClassName="test-training-request-row"
                rowDisabledReason={request => {
                  if (newTrainingSessionFieldsEnabled) return null;

                  if (!!request.session) {
                    return __(
                      'This request is already linked to the session: "%1"',
                      request.session.name || __('Untitled session')
                    );
                  }
                  if (request.approvalStatus === 'refused') {
                    return __('This request is refused');
                  }
                  return null;
                }}
                renderBulkActions={(
                  selectedRequestIds,
                  resetSelectedRequestIds
                ) => (
                  <BulkActions
                    selectedRequestIds={selectedRequestIds}
                    resetSelectedRequestIds={resetSelectedRequestIds}
                    refetchData={refetchData}
                    periodSlug={currentPeriodSlug}
                  />
                )}
              />
            )}
          />
        </DatatableWrapper>
      </Box>

      {focusedTrainingRequest && (
        <RequestManagementModal
          currentPeriodSlug={currentPeriodSlug}
          trainingRequest={focusedTrainingRequest}
          onClose={() => {
            setFocusedTrainingRequest(null);
            refetchData();
            !!onAfterAction && onAfterAction();
          }}
          linkedSessionName={
            focusedTrainingRequest.session
              ? focusedTrainingRequest.session.name || __('Untitled session')
              : null
          }
        />
      )}
    </Fragment>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      queryParams: { search, filter, userFilters },
      currentPeriodSlug,
    }: AfterPaginateProps) =>
      get(`training/periods/${currentPeriodSlug}/requests`, {
        page,
        countPerPage,
        search,
        userFilters,
        filter,
      }),
    hydrate: {
      trainingRequestCollection: {
        items: {
          creator: {},
          trainee: {
            manager: {},
          },
          trainingPeriod: {},
          session: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      queryParams: { search, filter, userFilters },
      currentPeriodSlug,
    }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
        search,
        userFilters,
        filter,
        currentPeriodSlug,
      }),
  })
)(TrainingRequestList);
