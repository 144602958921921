import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { DesignSystem, Route, Switch } from 'components';

import AuditReport from './AuditReport';
import Audits from './index';

const Routes = () => {
  const { path } = useRouteMatch();

  return (
    <DesignSystem version={2}>
      <Switch>
        <Route path={`${path}`} exact component={Audits} />
        <Route exact path={`${path}/:id`} component={AuditReport} />
      </Switch>
    </DesignSystem>
  );
};

export default Routes;
