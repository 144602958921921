import React, { Fragment } from 'react';

import type { OneOnOneUserReview } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n/index';

import { post } from 'redux/actions/api';

import { MenuItem, Toggleable } from 'components';

import ChangeReviewTemplateModal from './ChangeReviewTemplateModal';

type Props = {
  userReview: OneOnOneUserReview;
  onAfterAction?: () => Promise<void>;
};

const ChangeReviewTemplateAction = ({ userReview, onAfterAction }: Props) => {
  const dispatch = useAppDispatch();
  const changeReviewTemplate = async (templateId?: string | null) => {
    await dispatch(
      post(`user_reviews/${userReview.id}/change_review_template`, {
        templateId,
      })
    );

    !!onAfterAction && (await onAfterAction());
  };

  return (
    <Toggleable
      render={(isToggled, toggle) => (
        <Fragment>
          <MenuItem onClick={toggle}>{__('Change review template')}</MenuItem>

          {isToggled && (
            <ChangeReviewTemplateModal
              isActive={isToggled}
              userReview={userReview}
              onSubmit={async (templateId: string | null | undefined) => {
                await changeReviewTemplate(templateId);

                toggle();
              }}
              onCancel={toggle}
            />
          )}
        </Fragment>
      )}
    />
  );
};

export default ChangeReviewTemplateAction;
