import React from 'react';

import { Box, Column, Columns, DesignSystem } from 'components';

import logo from './logo_white_400x160.png';

const Logo = () => <img alt="presentation" src={logo} width="200" />;

const cardOffset = 120;
const imagePadding = 30;

const styles = {
  topImage: {
    background: 'linear-gradient(0deg, #4F6FF7, #3794F9)',
    paddingTop: imagePadding,
    paddingBottom: imagePadding + cardOffset,
  },
  bottomFrameContainer: {
    marginTop: -cardOffset,
    paddingTop: 0,
  },
  box: {
    padding: '2.5rem',
  },
};

type Props = {
  children: React.ReactNode;
};

const Splash = ({ children }: Props) => (
  <DesignSystem version={2}>
    <div>
      <div className="has-text-centered" style={styles.topImage}>
        <Logo />
      </div>
      <div
        className="section container-elevo"
        style={styles.bottomFrameContainer}
      >
        <Columns>
          <Column size={6} offset={3}>
            <Box style={styles.box}>{children}</Box>
          </Column>
        </Columns>
      </div>
    </div>
  </DesignSystem>
);

export default Splash;
