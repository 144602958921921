import type { OrganizationSettings } from 'models';

import { useAppSelector } from 'helpers/hooks';
import invariant from 'helpers/invariant';

import { getOrganizationSettings } from 'redux/reducers';

const useOrganizationSettings = (): OrganizationSettings => {
  return useAppSelector(state => {
    const organizationSettings = getOrganizationSettings(state);

    invariant(organizationSettings, 'Organization settings should be defined.');

    return organizationSettings;
  });
};

export default useOrganizationSettings;
