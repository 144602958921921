import { useParams } from 'react-router';

import { PeopleReviewCycleShow } from 'models';

import { useAppSelector } from 'helpers/hooks';
import invariant from 'helpers/invariant';

import { hydrateFromStore } from 'lib/dataLoader';

const usePeopleReviewCycleFromParams = (): PeopleReviewCycleShow => {
  const { cycleId } = useParams<{ cycleId?: string }>();
  invariant(
    cycleId,
    'People Review Cycle ID is expected to be defined in params.'
  );

  const peopleReviewCycle: PeopleReviewCycleShow = useAppSelector(({ data }) =>
    hydrateFromStore(
      data,
      {
        resourceType: 'peopleReviewCycle',
        id: cycleId,
      },
      {
        peopleReviewCycle: {
          peopleReviewDimensions: {
            peopleReviewRatingOptions: {},
          },
        },
      }
    )
  );

  return peopleReviewCycle;
};

export default usePeopleReviewCycleFromParams;
