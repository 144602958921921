import React from 'react';

import type { ReviewTemplate } from 'models';

import invariant from 'helpers/invariant';

import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer } from 'components';

import Structure from 'scenes/admin/reviewTemplates/Template/Edit/Structure';

type Props = {
  template: ReviewTemplate;
};

type AfterConnectProps = Props & {
  template: ReviewTemplate;
  isFetching: boolean;
  hasError: boolean;
};

const ReviewCycleTemplate = ({
  isFetching,
  hasError,
  template,
}: AfterConnectProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        const { reviewStructure, hasSelfEvaluationEnabled } = template;

        invariant(reviewStructure, 'Review structure should exist here');

        return (
          <div className="template-builder">
            <Structure
              templateId={template.id}
              structure={reviewStructure}
              hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
              canDeleteBlocks={false}
            />
          </div>
        );
      }}
    />
  );
};

export default newDataLoader({
  fetch: ({ template }: Props) => get(`review_templates/${template.id}`),
  hydrate: {
    template: {
      reviewStructure: {
        reviewBlocks: {
          feedbackOption: {},
          revieweeFeedbackOptions: {},
          reviewerFeedbackOptions: {},
          revieweeRatingOptions: {},
          reviewerRatingOptions: {},
          ratingOptions: {},
          ratingCriteria: {},
          abilities: {},
        },
      },
    },
  },
  cacheKey: ({ template }: Props) => template.id,
})(ReviewCycleTemplate) as React.ComponentType<Props>;
