// Patching function that hijacks XMLHttpRequest's `open` and `onreadystatechange` methods
// to call our `requestStart` and `requestStop` and maintain a list of pending requests.
// Once all known XHRs have completed, we wait a bit before declaring there is no pending XHR left.
// This is not intended to be injected in production, only development and test environments.

// Not flowtyped because it monkey patches native objects and it doesn't like that

// From observations, we never have more than 200ms between requests
// But for safety, we double that
const considerLoadedAfter = 400;

// By default we don't debug. This can be overridden by calling `xhrActivityDetectorPatch(debug = true)`
// in the index
let debug = false;
function log(message) {
  if (debug) {
    console.log(
      'xhrActivityDetector:',
      `t=${getTimestampSinceLoaded()}`,
      message
    );
  }
}

let allRequestsDone = false;
function setAllRequestsDone() {
  log('Done');
  allRequestsDone = true;
}

// We'll track all pending requests as strings "METHOD url://" in this set
const pendingRequests = new Set();
// We debounce the call to setAllRequestsDone manually (and clear the timeout anytime a request starts)
let setAllRequestsDoneTimeoutHandler = null;
function updateRequestStatus(requestId, done) {
  const requestWasPending = pendingRequests.has(requestId);

  if (!done && setAllRequestsDoneTimeoutHandler !== null) {
    clearTimeout(setAllRequestsDoneTimeoutHandler);
    setAllRequestsDoneTimeoutHandler = null;
  }

  if (done && !requestWasPending) {
    throw new Error(
      `Tried to mark request as done when not pending: ${requestId}`
    );
  }

  if (!done && !requestWasPending) {
    pendingRequests.add(requestId);

    if (allRequestsDone) {
      log('Not done');
    }

    allRequestsDone = false;
    return;
  }

  // requestWasPending === true
  if (done) {
    pendingRequests.delete(requestId);

    if (pendingRequests.size === 0) {
      // @ts-ignore TSFIXME: Fix strictNullChecks error
      setAllRequestsDoneTimeoutHandler = setTimeout(
        setAllRequestsDone,
        considerLoadedAfter
      );
    }

    return;
  }
}

function requestStart(requestId) {
  updateRequestStatus(requestId, false);
}

function requestStop(requestId) {
  updateRequestStatus(requestId, true);
}

// Helper function to get time since script has loaded
const loadTime = new Date().getTime();
function getTimestampSinceLoaded() {
  return new Date().getTime() - loadTime;
}

// The main entry point that actually patches XMLHttpRequest
type Params = {
  debug: boolean;
};
export default function xhrActivityDetectorPatch(
  params: Params = { debug: false }
) {
  debug = !!params.debug;
  log('Not done');

  const send = XMLHttpRequest.prototype.send;
  XMLHttpRequest.prototype.send = function (_data) {
    const originalOnReadyStateChange = this.onreadystatechange;
    if (originalOnReadyStateChange) {
      this.onreadystatechange = function () {
        log(`${this._requestId} changed: readyState ${this.readyState}`);
        if (this.readyState === 4) {
          requestStop(this._requestId);
        } else {
          requestStart(this._requestId);
        }

        // @ts-ignore
        return originalOnReadyStateChange.apply(this, arguments);
      };
    }
    // @ts-ignore
    return send.apply(this, arguments);
  };

  const open = XMLHttpRequest.prototype.open;
  XMLHttpRequest.prototype.open = function (method: string, url: string | URL) {
    const startedAt = getTimestampSinceLoaded();
    this._requestId = `${method}(started=${startedAt}) ${url}`;
    log(`${this._requestId} opened ${this.readyState}`);
    requestStart(this._requestId);
    // @ts-ignore
    return open.apply(this, arguments);
  };
}

// External accessor to be used by Selenium
window.hasPendingXHR = function () {
  return !allRequestsDone;
};
