import React from 'react';

import type { UpdateReviewCycle } from '../..';
import type { ReviewCycle } from 'models';

import { __ } from 'helpers/i18n';

import { Checkbox } from 'components';

import OptionWrapper from './OptionWrapper';

type Props = {
  reviewCycle: ReviewCycle;
  updateReviewCycle: UpdateReviewCycle;
};

function AddDirectReportsOption({ reviewCycle, updateReviewCycle }: Props) {
  return (
    <OptionWrapper
      title={__('Automatically add direct reports as peers')}
      subtitle={__(
        'Automatically select and validate the direct reports of the participants as peers - useful when launching a manager survey. The peer list will be editable if needed.'
      )}
      additionalClassName="direct-reports-option"
    >
      <Checkbox
        isChecked={reviewCycle.addDirectReportsAsPeersByDefault}
        onChange={isChecked =>
          updateReviewCycle({ addDirectReportsAsPeersByDefault: isChecked })
        }
        label={__('Automatically select direct reports as peers')}
      />
    </OptionWrapper>
  );
}

export default AddDirectReportsOption as React.ComponentType<Props>;
