import React, { Fragment } from 'react';

import type { QuickLink, User } from 'models';

import { __, n__ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToUserObjectiveHistory, pathToUserReviews } from 'helpers/paths';

import DirectReportQuickLink from './DirectReportQuickLink';

type Props = {
  report: User;
};

const DirectReportUserActions = ({ report }: Props) => {
  invariant(report.quickLinks, 'Direct report needs quick links');
  const quickLinks = report.quickLinks;
  const objectivesQuickLink: QuickLink | undefined | null = quickLinks.find(
    link => link.target === 'objectives'
  );
  const reviewsQuickLink: QuickLink | undefined | null = quickLinks.find(
    link => link.target === 'reviews'
  );
  invariant(reviewsQuickLink && objectivesQuickLink, 'Missing a quickLink');

  return (
    <Fragment>
      <DirectReportQuickLink
        title={n__(
          '%1 ongoing review',
          '%1 ongoing reviews',
          reviewsQuickLink.info.count
        )}
        subtitle={__('See reviews')}
        target={pathToUserReviews(report.id)}
        style={{ marginBottom: 4 }}
      />
      <DirectReportQuickLink
        title={n__(
          '%1 ongoing objective',
          '%1 ongoing objectives',
          objectivesQuickLink.info.count
        )}
        subtitle={__('See objectives')}
        target={pathToUserObjectiveHistory(report.id)}
      />
    </Fragment>
  );
};

export default DirectReportUserActions;
