import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { pathToMatrixList } from 'helpers/navigation';

import { DesignSystem, Redirect, Route, Switch } from 'components';

import IndexRoutes from './Index/Routes';

export default function SkillsRoutes() {
  const { path } = useRouteMatch();

  return (
    <DesignSystem version={2}>
      <Switch>
        <Route path={`${path}`} component={IndexRoutes} />

        <Redirect to={pathToMatrixList()} />
      </Switch>
    </DesignSystem>
  );
}
