import React from 'react';
import { connect } from 'react-redux';

import type { FinalCommentBlock } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { put } from 'redux/actions/api';

import QuestionBlock from 'scenes/components/review/QuestionBlock';

import FeedbackZone from '../FeedbackZone';

type Props = {
  reviewId: string;
  finalCommentBlock: FinalCommentBlock;
  canFillContent: boolean;
  isClosed: boolean;
};

type AfterConnectProps = Props & {
  persistAction: (
    ratingValue?: string | null,
    comment?: string | null
  ) => Promise<void>;
};

function FinalCommentBlockComponent({
  finalCommentBlock,
  persistAction,
  canFillContent,
  isClosed,
}: AfterConnectProps) {
  const { title, description, comment } = finalCommentBlock;

  return (
    <QuestionBlock title={title} description={description}>
      <FeedbackZone
        comment={comment}
        readOnly={isClosed || !canFillContent}
        persistAction={(_, comment) => persistAction(comment)}
      />
    </QuestionBlock>
  );
}

const mapDispatchToProps = (dispatch: AppDispatch, { reviewId }: Props) => ({
  persistAction: async (finalComment?: string | null) =>
    dispatch(put(`people_user_reviews/${reviewId}`, { finalComment })),
});

export default connect(
  null,
  mapDispatchToProps
)(FinalCommentBlockComponent) as React.ComponentType<Props>;
