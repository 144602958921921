import { useState } from 'react';

const useSearch = <T>(
  elements: Array<T>,
  select: (element: T) => string
): [Array<T>, (searchString: string) => void, string] => {
  const [shownElements, setShowElements] = useState<Array<T>>(elements);
  const [searchString, setSearchString] = useState('');

  const onSearchChange = (searchString: string) => {
    setSearchString(searchString);
    setShowElements(
      elements.filter(element => {
        const elementValue = select(element);

        return elementValue.toLowerCase().includes(searchString.toLowerCase());
      })
    );
  };

  return [shownElements, onSearchChange, searchString];
};

export default useSearch;
