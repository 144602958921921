import React from 'react';

import type { ApprovalStatus } from 'models/TrainingRequest';
import type { ReactElement } from 'react';

import { approvalStatusLabel } from 'helpers/models/trainingRequest';

import { Icon } from 'components';

import iconForApprovalStatus from './iconForApprovalStatus';

type Option = {
  value: ApprovalStatus;
  label: string;
  icon: ReactElement;
};

const requestApprovalOptions = (): Array<Option> => [
  {
    value: 'waiting_for_approval',
    label: approvalStatusLabel('waiting_for_approval'),
    icon: <Icon name={iconForApprovalStatus('waiting_for_approval')} />,
  },
  {
    value: 'approved',
    label: approvalStatusLabel('approved'),
    icon: <Icon color="success" name={iconForApprovalStatus('approved')} />,
  },
  {
    value: 'refused',
    label: approvalStatusLabel('refused'),
    icon: <Icon color="danger" name={iconForApprovalStatus('refused')} />,
  },
];

export default requestApprovalOptions;
