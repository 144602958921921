import React from 'react';

import type { Answer } from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { Icon, Text } from 'components';

type Props = {
  answer: Answer;
};

export default function Author({ answer }: Props) {
  const { reviewee, authorTag, author } = answer;

  invariant(reviewee, 'reviewee must be defined on answer on result view');

  return (
    <div className="author">
      <Icon name="person" />
      <Text size={7} weight="bold">
        {reviewee.fullName}
      </Text>{' '}
      —{' '}
      {answer.authorRole === 'reviewee'
        ? __('self-evaluation')
        : __(
            'comment by %1 (%2)',
            author.fullName,
            authorTag === 'reviewee' ? __('Reviewee') : __('Reviewer')
          )}
    </div>
  );
}
