import React, { CSSProperties } from 'react';

type Props = {
  minValue: number;
  maxValue: number;
  value: number;
  onChange: (value: number) => void;
  step?: number;
  disabled?: boolean;
  style?: CSSProperties;
};

export default function Slider({
  minValue,
  maxValue,
  value,
  step,
  onChange,
  disabled,
  style = {},
}: Props) {
  if (value === undefined) {
    throw new Error(
      'Slider component only support controlled input. Make sure you provide value props.'
    );
  }
  if (!onChange && !disabled) {
    throw new Error(
      'Slider component only support controlled input. Make sure you provide onChange props.'
    );
  }
  if (disabled) {
    return (
      <progress
        className="progress is-small is-info "
        value={value}
        max={maxValue}
        style={style}
      />
    );
  } else {
    return (
      <input
        type="range"
        min={minValue}
        max={maxValue}
        step={step || 1}
        value={value}
        disabled={disabled}
        onChange={e => onChange(parseInt(e.target.value, 10))}
        className="slider is-fullwidth"
        style={{ '--low': '0%', '--high': `${value}%`, ...style }}
      />
    );
  }
}
