import React from 'react';

import { useCurrentOrganization } from 'helpers/hooks';

import { CreateTrainingRequestFormObject } from '../types';
import OldTraineesChooser from './OldTraineesChooser';
import NewTraineesChooser from './TraineesChooser';

type Props = {
  setTrainingRequest: (
    trainingRequest: CreateTrainingRequestFormObject
  ) => void;
  trainingRequest: CreateTrainingRequestFormObject;
};

const TraineesChooser = (props: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const withUserMultiFilters = featureFlags.includes('userMultifilters');

  if (!withUserMultiFilters) {
    return <OldTraineesChooser {...props} />;
  }

  return (
    <NewTraineesChooser withUrlChange={false} countPerPage={6} {...props} />
  );
};

export default TraineesChooser;
