import { sortBy } from 'lodash';
import React from 'react';
import { compose } from 'redux';

import type { PeerFeedback, User } from 'models';

import transformProps from 'helpers/transformProps';
import { withActiveUser } from 'helpers/withSessionProps';

import { newDataLoader } from 'lib/dataLoader';
import { fetchPeerFeedbacksSharedByUser } from 'redux/actions/resources';

import SharedPeerFeedbackList from './SharedPeerFeedbackList';

type Props = {
  user: User;
};

export default compose(
  newDataLoader({
    fetch: ({ user }) => fetchPeerFeedbacksSharedByUser(user),
    hydrate: {
      sharedPeerFeedbacks: {
        reviewee: {},
      },
    },
  }),
  transformProps(({ sharedPeerFeedbacks }) => ({
    sharedPeerFeedbacks: sharedPeerFeedbacks
      ? sortBy(
          sharedPeerFeedbacks,
          ({ dueDate, submittedAt }: PeerFeedback) =>
            new Date(submittedAt || dueDate)
        ).reverse()
      : [],
  })),
  withActiveUser
)(SharedPeerFeedbackList) as React.ComponentType<Props>;
