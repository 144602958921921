import React from 'react';

import { __ } from 'helpers/i18n';

import { Box, EmptyState as EmptyStateComponent } from 'components';
import emptyStatePng from 'components/TrainingRequestEmptyState/emptyState.png';

const TrainingPeriodEmptyState = () => {
  return (
    <Box
      style={{ marginTop: 24 }}
      additionalClassName="training-period-empty-state"
    >
      <EmptyStateComponent
        title={__('No period has been created yet')}
        description={__(
          'Your Super Admin must create a period for the collection of training requests to be available.\n' +
            'Once created, you will be able to create and view the collected training requests from this screen.'
        )}
        src={emptyStatePng}
      />
    </Box>
  );
};

export default TrainingPeriodEmptyState;
