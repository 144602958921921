import React from 'react';

import { __ } from 'helpers/i18n';

import { Button, Flex, Text } from 'components';

import { DescriptionSection } from '.';

type Props = {
  onChange: (description: string) => Promise<void>;
  description: string;
  disabled: boolean;
  locked: boolean;
};

export default function ObjectiveDescription({
  disabled,
  onChange,
  description,
  locked,
}: Props) {
  const [editing, setEditing] = React.useState<boolean>(false);

  if (editing || description) {
    return (
      <DescriptionSection
        description={description}
        disabled={locked}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={editing}
        onChange={newDescription => onChange(newDescription)}
        onBlur={() => setEditing(false)}
      />
    );
  }

  return (
    <Flex style={{ flexWrap: 'wrap', marginTop: 16 }}>
      <Button
        disabled={disabled}
        onClick={() => setEditing(true)}
        size="small"
        color="white"
      >
        <Text color="info" weight="normal">
          {__('Add a description')}
        </Text>
      </Button>
    </Flex>
  );
}
