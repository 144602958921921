import React from 'react';

import type { BlockFeedbackOptions } from 'models';

import invariant from 'helpers/invariant';

import {
  CheckboxSwitch,
  Flex,
  Text,
  WithSavingStatusRecorder,
} from 'components';

import MandatoryOption from './MandatoryOption';

type Props = {
  feedbackOptions: BlockFeedbackOptions | undefined | null;
  label: string;
  testClassName?: string;
  feedbackType: 'rating' | 'text';
  isDisabled?: boolean;
  withMandatory?: boolean;
  mandatoryDisabled?: boolean;
  onChange: (
    feedbackOptionId: string,
    attributes: Partial<BlockFeedbackOptions>
  ) => Promise<void>;
};

const FeedbackOption = ({
  label,
  testClassName,
  isDisabled,
  onChange,
  mandatoryDisabled,
  feedbackOptions,
  withMandatory,
  feedbackType,
}: Props) => {
  const feedbackTypeEnabled = `${feedbackType}Enabled`;
  const feedbackTypeMandatory = `${feedbackType}Mandatory`;

  if (!feedbackOptions) return null;

  return (
    <div className="feedback-option">
      <Flex testClassName={testClassName}>
        <WithSavingStatusRecorder
          onChange={async (value: boolean) => {
            invariant(
              feedbackOptions,
              'feedbackOptions must be defined to be edited'
            );

            await onChange(feedbackOptions.id, {
              [feedbackTypeEnabled]: value,
            });
          }}
          render={autoSavingOnchange => (
            <CheckboxSwitch
              value={feedbackOptions[feedbackTypeEnabled]}
              size="small"
              disabled={isDisabled}
              onChange={autoSavingOnchange}
            />
          )}
        />
        <Text color={isDisabled ? 'grey' : undefined}>{label}</Text>
      </Flex>

      {withMandatory && (
        <MandatoryOption
          value={feedbackOptions[feedbackTypeMandatory]}
          isDisabled={mandatoryDisabled}
          onChange={async (value: boolean) => {
            invariant(
              feedbackOptions,
              'feedbackOptions must be defined to be edited'
            );

            await onChange(feedbackOptions.id, {
              [feedbackTypeMandatory]: value,
            });
          }}
        />
      )}
    </div>
  );
};

export default FeedbackOption;
