import React from 'react';
import { slugify } from 'transliteration';

import type { Signature } from 'models';
import type { ReduxStore } from 'redux/reducers';

import { useAppSelector } from 'helpers/hooks';
import { __, date } from 'helpers/i18n';

import { Button, Helper, Icon, StrictlySanitizedHtml, Text } from 'components';

import UserAvatar from 'scenes/components/UserAvatar';
import FinalCommentField from 'scenes/review/components/FormContent/blocks/SignatureBlockContent/FinalCommentField';
import SignatureField from 'scenes/review/components/SignatureField';

type Props = {
  signature: Signature;
  withFinalComment?: boolean;
  isDisabled?: boolean;
  onFinalCommentChange?: () => Promise<void>;
  onSignClick: () => Promise<void>;
};

export default function UserAvatarWithSignature({
  signature,
  withFinalComment,
  isDisabled,
  onFinalCommentChange,
  onSignClick,
}: Props) {
  const [signatureValue, onSignatureValueChange] = React.useState(
    signature.isSigned ? signature.author.fullName : ''
  );

  const { author, isSigned, signedAt } = signature;
  const renderFinalCommentField = withFinalComment && (!isDisabled || isSigned);
  const renderSignatureField = !isDisabled || isSigned;
  const renderHelper =
    !isDisabled &&
    slugify(signatureValue, { lowercase: true, separator: ' ' }) !==
      slugify(author.fullName, { lowercase: true, separator: ' ' });
  const isSaving = useAppSelector(
    (state: ReduxStore) => state.autoSave.overallStatus === 'saving'
  );
  const renderSubmitButton = !isDisabled && !isSigned && !renderHelper;

  return (
    <div className="signature-field-with-user-avatar">
      <UserAvatar user={author} size="normal" withJobTitle />

      <div style={{ marginLeft: 64 }}>
        {renderFinalCommentField && (
          <FinalCommentField
            signature={signature}
            persistFinalComment={onFinalCommentChange}
            style={{ marginTop: 8 }}
          />
        )}

        {renderSignatureField && (
          <SignatureField
            value={signatureValue || ''}
            style={{ margin: '8px 0' }}
            isSigned={isSigned}
            isDisabled={isDisabled}
            onChange={onSignatureValueChange}
          />
        )}

        {renderHelper && (
          <Helper style={{ margin: 0 }}>
            <StrictlySanitizedHtml
              html={
                !!withFinalComment
                  ? __(
                      '<b>Enter your full name “%1”</b> to sign and validate your final comment',
                      author.fullName
                    )
                  : __(
                      '<b>Enter your full name “%1”</b> to sign',
                      author.fullName
                    )
              }
            />
          </Helper>
        )}

        {renderSubmitButton && (
          <Button
            color="primary"
            onClick={onSignClick}
            testClassName="test-confirm-signature-button"
            disabled={isSaving}
            style={{ marginTop: 8, float: 'right' }}
          >
            <Icon size="small" name="assignment_turned_in" />
            <Text style={{ marginLeft: 8 }}>{__('Sign')}</Text>
          </Button>
        )}

        {isSigned && (
          <div style={{ marginTop: 8, opacity: 0.8 }}>
            <Text size={7} align="left">
              {__('Signed on %1', date(signedAt))}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}
