import React from 'react';

import classNames from 'helpers/classNames';
import { getCurrentLocale } from 'helpers/i18n';

import { Icon, Text } from 'components';

type Props = {
  percentChange: number;
};

const PercentChangeIndicator = ({ percentChange }: Props) => {
  const isIncreasing = percentChange > 0;

  return (
    <span
      className={classNames(
        'flex items-center ml-2 px-2 py-[2px] rounded-2xl',
        isIncreasing ? 'bg-green-success/20' : 'bg-red-fuckup/20'
      )}
    >
      <Icon
        name={isIncreasing ? 'arrow_upward' : 'arrow_downward'}
        additionalClassName={classNames(
          'text-[10px] mr-1',
          isIncreasing ? 'text-green-success' : 'text-red-fuckup'
        )}
      />
      <Text preset="11s8">
        {percentChange.toLocaleString(getCurrentLocale(), {
          maximumFractionDigits: 2,
        })}
        %
      </Text>
    </span>
  );
};

export default PercentChangeIndicator;
