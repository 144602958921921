import React from 'react';

import type { Email, ReviewCycle } from 'models';

import { __ } from 'helpers/i18n';
import { pathToTemplate } from 'helpers/paths';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer } from 'components';

import StepContainer from 'scenes/components/Stepper/StepContainer';

import CycleEmails from './CycleEmails';

type Props = {
  reviewCycle: ReviewCycle;
  goToNextStep: () => void;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    emails: Array<Email>;
  };

function Step5Emails({
  reviewCycle,
  goToNextStep,
  emails,
  isFetching,
  hasError,
}: AfterDataLoaderProps) {
  const [emailValid, setEmailValid] = React.useState(true);

  return (
    <StepContainer
      title={__('Emails at the launch of the campaign')}
      titlePreset="24bs2"
      subtitle={__(
        'Give your employees more information and context at the launch of your campaign by customizing the campaign launch email.'
      )}
      stepName="emails"
      onContinueClick={goToNextStep}
      canPassStep={emailValid}
      savingStatusLabel={__('The email could not be saved')}
    >
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => (
          <CycleEmails
            emails={emails}
            defaultTemplatePath={pathToTemplate(reviewCycle.defaultTemplate.id)}
            defaultTemplateName={reviewCycle.defaultTemplate.name}
            onEmailValidationChange={setEmailValid}
            interactionType={reviewCycle.interactionType}
          />
        )}
      />
    </StepContainer>
  );
}

export default newDataLoader({
  fetch: ({ reviewCycle }: Props) =>
    get(`review_cycles/${reviewCycle.id}/emails`),
  hydrate: {
    emails: {},
  },
})(Step5Emails) as React.ComponentType<Props>;
