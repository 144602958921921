import React, { useState } from 'react';

import { ReviewCycle, UserFilterSegment } from 'models';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { Flex, Text } from 'components';

import Filters from './Filters';
import List from './List';
import NoFilterEmptyState from './NoFilterEmptyState';

type Props = {
  reviewCycle: ReviewCycle;
  defaultUserFilter?: UserFilterSegment;
};

const DevelopmentNeeds = ({ reviewCycle, defaultUserFilter }: Props) => {
  const [userFilter, setUserFilter] = useState(defaultUserFilter);
  const [selectedMatrixId, setSelectedMatrixId] = useState<null | string>(null);
  const [selectedLevelId, setSelectedLevelId] = useState<null | string>(null);
  const organization = useOrganization();

  const displayRealData = organization.featureFlags.includes(
    'skillsAndCareersAnalytics'
  );
  const displayMockedData =
    !displayRealData &&
    organization.featureFlags.includes('mockDataForSkillsAndCareersAnalytics');

  const [totalCount, setTotalCount] = useState<number | null>(null);

  return (
    <React.Fragment>
      <Flex direction="column" additionalClassName="review-cycle-skills">
        <Text preset="18bs5" additionalClassName="mb-2">
          {__('Developement needs')}
        </Text>
        <Text additionalClassName="mb-2">
          {__('Visualize the skills that can be improved in a team.')}
        </Text>
        <Filters
          totalCount={totalCount}
          displayMockedData={displayMockedData}
          selectedMatrixId={selectedMatrixId}
          setSelectedMatrixId={setSelectedMatrixId}
          selectedLevelId={selectedLevelId}
          setSelectedLevelId={setSelectedLevelId}
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          userFilter={userFilter}
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          setUserFilter={setUserFilter}
          reviewCycle={reviewCycle}
        />
        {!selectedMatrixId ? (
          <NoFilterEmptyState />
        ) : (
          <List
            reviewCycle={reviewCycle}
            displayMockedData={displayMockedData}
            selectedMatrixId={selectedMatrixId}
            selectedLevelId={selectedLevelId}
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            userFilter={userFilter}
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            totalCount={totalCount}
            setTotalCount={setTotalCount}
          />
        )}
      </Flex>
    </React.Fragment>
  );
};

export default DevelopmentNeeds;
