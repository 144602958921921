import React from 'react';
import { ReactElement } from 'react';

import type { ThreeSixtyReviewCycle, ThreeSixtyUserReview } from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import { assert } from 'helpers/invariant';
import { pathToReviewResults } from 'helpers/navigation';

import { Button, Flex, Testable } from 'components';

import CalendarButton from 'scenes/components/userReview/CalendarButton';

import MainAction from './MainAction';

type Props = {
  onAddPeers: () => void;
  canAddPeers: boolean;
  userReview: ThreeSixtyUserReview;
  reviewCycle: ThreeSixtyReviewCycle;
};

const PeerManagerActions = ({
  userReview,
  reviewCycle,
  onAddPeers,
  canAddPeers,
}: Props) => {
  let reviewResultPath = pathToReviewResults(userReview);

  const asPrimaryAction = (action: ReactElement<any>) =>
    React.cloneElement(action, { color: 'primary' });
  const asSecondaryAction = (action: ReactElement<any>) =>
    React.cloneElement(action, {
      color: 'secondary',
      style: { marginLeft: 10 },
    });

  const viewResultsAction = (
    <Button to={reviewResultPath}>
      <Testable name="test-view-results-button">{__('View results')}</Testable>
    </Button>
  );
  const mainAction = <MainAction userReview={userReview} />;
  const addNewPeersAction = (
    <Button onClick={onAddPeers}>
      <Testable name="test-add-peers-button">
        {__('Select peer reviewers')}
      </Testable>
    </Button>
  );
  const editPeersAction = (
    <Button onClick={onAddPeers}>
      <Testable name="test-edit-peers-button">
        {__('Edit peer reviewers')}
      </Testable>
    </Button>
  );

  const scheduleMeetingAction = (
    <div style={{ marginLeft: 10 }}>
      <CalendarButton
        userReviewId={userReview.id}
        meeting={assert(
          userReview.meeting,
          'Meeting should be available on userReview on this page'
        )}
        disabled={!can({ perform: 'schedule_meeting', on: userReview })}
      />
    </div>
  );

  if (userReview.isReleased) {
    return (
      <Flex verticalAlign>
        {asSecondaryAction(viewResultsAction)}
        {scheduleMeetingAction}
      </Flex>
    );
  }

  if (!canAddPeers) return mainAction;

  if (
    !userReview.arePeersValidated &&
    !userReview.arePeersNominated &&
    reviewCycle.nominatePeersStepEnabled
  ) {
    return (
      <Flex verticalAlign>
        {asPrimaryAction(addNewPeersAction)}
        {scheduleMeetingAction}
      </Flex>
    );
  }

  return (
    <Flex verticalAlign>
      {mainAction}

      {reviewCycle.nominatePeersStepEnabled &&
        asSecondaryAction(editPeersAction)}

      {scheduleMeetingAction}
    </Flex>
  );
};

export default PeerManagerActions;
