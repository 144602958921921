import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { PeopleReviewDimensionShow } from 'models';

import { __ } from 'helpers/i18n';
import { Match } from 'helpers/paths';
import { pathToPeopleReviewDimensions } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  BlueInfoBox,
  Box,
  FetchContainer,
  PageHeader,
  PageTitle,
} from 'components';

import DimensionForm from './Form';

type RouterProps = RouteComponentProps & {
  match: Match<{
    campaignId: string;
  }>;
};

type Props = RouterProps;

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    peopleReviewDimension: PeopleReviewDimensionShow;
  };

const Dimension = ({
  peopleReviewDimension,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        const { usedInCycle, label } = peopleReviewDimension;
        return (
          <Fragment>
            <PageHeader
              title={label}
              withBackButton
              backButtonProps={{
                children: __('Back to dimensions'),
                target: pathToPeopleReviewDimensions(),
              }}
            />
            <PageTitle title={label} />
            <div className="m-4 md:m-8">
              <Box>
                {usedInCycle && (
                  <BlueInfoBox
                    text={__(
                      'These changes will be taken into account <b>in future</b> People Review campaigns. Current and completed campaigns will not be affected.'
                    )}
                    additionalClassName="mb-4"
                  />
                )}
                <DimensionForm peopleReviewDimension={peopleReviewDimension} />
              </Box>
            </div>
          </Fragment>
        );
      }}
    />
  );
};

export default newDataLoader({
  fetch: ({ match }: Props) =>
    get(`people_review_dimensions/${match.params.dimensionId}`),
  hydrate: {
    peopleReviewDimension: { peopleReviewRatingOptions: {} },
  },
})(Dimension);
