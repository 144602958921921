import React, { CSSProperties } from 'react';

import classNames from 'helpers/classNames';

import { Link, Testable } from 'components';

type FilterProps = {
  children: string;
  testClassName?: string;
  onSelect: () => void;
  disabled?: boolean;
  isActive?: boolean;
  style?: CSSProperties;
};

function Filter(props: FilterProps) {
  const { children, testClassName, onSelect, disabled, isActive, style } =
    props;
  return (
    <Link
      className={classNames('filter', { 'is-active': isActive }, testClassName)}
      onClick={onSelect}
      disabled={disabled}
      style={style}
    >
      {children}
    </Link>
  );
}

type FilterStyle = 'tag' | 'link';
type Props = {
  display: FilterStyle;
  leftText?: string;
  filters: { param: string; label: string }[];
  onFilterChange: (param: string) => void;
  activeFilter: string | null | undefined;
};

export default function FilterBar(props: Props) {
  const { filters, display, leftText, onFilterChange, activeFilter } = props;
  return (
    <div className="block">
      <Testable name="test-filter-bar">
        <div className={classNames('filter-bar', `is-${display}`)}>
          {!!leftText && leftText}
          {filters.map((filter, i) => (
            <Filter
              key={i}
              testClassName={`test-${filter.param}-filter`}
              isActive={activeFilter === filter.param}
              onSelect={() => onFilterChange(filter.param)}
            >
              {filter.label}
            </Filter>
          ))}
        </div>
      </Testable>
    </div>
  );
}
