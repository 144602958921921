import React from 'react';

import type { OneOnOneReviewCycle, OneOnOneUserReview } from 'models';

import { __ } from 'helpers/i18n';

import { Column, Columns } from 'components';

import { AvatarWithStatus } from 'scenes/components/AvatarWithStatus';

import Actions from './Actions';

type Props = {
  userReview: OneOnOneUserReview;
  reviewCycle: OneOnOneReviewCycle;
  onAfterDelegate: () => Promise<any>;
};

export default function UserReviewItem({
  userReview,
  reviewCycle,
  onAfterDelegate,
}: Props) {
  return (
    <Columns>
      <Column size={7} mobileSize={12}>
        <AvatarWithStatus
          user={userReview.user}
          status={userReview.revieweeStatus}
          statusFallback={__('No self evaluation')}
        />
      </Column>
      <Column size={5} contentIsPulledRight>
        <Actions
          userReview={userReview}
          reviewCycle={reviewCycle}
          onAfterDelegate={onAfterDelegate}
        />
      </Column>
    </Columns>
  );
}
