import {
  type DraftSurveyCampaign,
  SurveyCampaignStepName as StepName,
  SurveyFormStep,
  isQuestionFormStep,
} from 'models';

import { __ } from 'helpers/i18n';

import { StepInfo as BaseStepInfo } from 'scenes/components/Stepper';

import { canContinue as isCampaignFrequencyCompleted } from '../Edit/steps/StepFrequency';
import { canContinue as isCampaignParticipantsCompleted } from '../Edit/steps/StepParticipants';

export type StepInfo = BaseStepInfo<StepName>;

const getSetupStepInfoList = (
  campaign: DraftSurveyCampaign | null
): Array<StepInfo> => [
  {
    name: 'settings',
    title: __('Settings'),
    isCompleted:
      !!campaign && !!campaign.name && campaign.anonymous !== undefined,
  },
  {
    name: 'questions',
    title: __('Questions'),
    isCompleted: !!campaign && campaign.formIsValid,
  },
  {
    name: 'participants',
    title: __('Participants'),
    isCompleted:
      !!campaign &&
      isCampaignParticipantsCompleted(
        campaign.participantAdditionMode,
        campaign.includedParticipantsCount
      ) &&
      campaign.setupStepIndex > 2,
  },
  {
    name: 'frequency',
    title: __('Frequency'),
    isCompleted:
      !!campaign &&
      isCampaignFrequencyCompleted(
        campaign.recurrenceType,
        campaign.recurrenceValue,
        campaign.recurrenceOffset,
        campaign.nextPeriodAt,
        campaign.daysToComplete
      ) &&
      campaign.setupStepIndex > 3,
  },
  {
    name: 'summary',
    title: __('Summary'),
    isCompleted: false,
  },
];

export const getStepContentStyles = () => ({
  instruction: { label: __('Title'), iconName: 'title' as const },
  scale: { label: __('Scale question'), iconName: 'hotel_class' as const },
  nps: { label: __('Net Promoter Score'), iconName: 'speed' as const },
  text: { label: __('Text question'), iconName: 'notes' as const },
  single_choice: {
    label: __('Single-choice question'),
    iconName: 'arrow_drop_down_circle' as const,
  },
  multiple_choice: {
    label: __('Multiple-choice question'),
    iconName: 'rule' as const,
  },
});

export type StepContentStyle = ReturnType<typeof getStepContentStyles>;
export type StepContentStyleKey = keyof StepContentStyle;
export type StepContentStyleValue = StepContentStyle[StepContentStyleKey];

export const getContentStyle = (formStep: SurveyFormStep) =>
  getStepContentStyles()[
    isQuestionFormStep(formStep)
      ? formStep.question.questionType
      : 'instruction'
  ];

/**
 * Behaves like Array.map
 *
 * @example
 * mapStepContentStyles((stepContentType, stepContentStyle) => {
 *   // ...
 * });
 *
 * @note
 * Avoids the hassle to cast the result of Object.entries
 */
export const mapStepContentStyles = <T>(
  callback: (
    stepContentType: StepContentStyleKey,
    stepContentStyle: StepContentStyleValue
  ) => T
) =>
  (
    Object.entries(getStepContentStyles()) as [
      StepContentStyleKey,
      StepContentStyleValue
    ][]
  ).map(([stepContentType, stepContentStyle]) =>
    callback(stepContentType, stepContentStyle)
  );

export default getSetupStepInfoList;
