import React from 'react';

type Props = {
  poll: () => Promise<void>;
  everyMs: number;
  initialDelayMs?: number;
};

export default class Poller extends React.Component<Props> {
  enabled = true;

  async componentDidMount() {
    await this.wait(this.props.initialDelayMs || 0);
    this.poll();
  }

  componentWillUnmount() {
    this.enabled = false;
  }

  poll = async () => {
    await this.props.poll();
    await this.wait(this.props.everyMs);

    if (this.enabled) this.poll();
  };

  wait = async (ms: number) => {
    return await new Promise(resolve => setTimeout(resolve, ms));
  };

  render = () => null;
}
