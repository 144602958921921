import React from 'react';

import { Redirect } from 'components';

type Props = {
  location: {
    state?: {
      redirectTo?: string;
    };
  };
};

// This component persists the redirect state to the local storage
// so that forwarding is persisted across unloads, for instance in
// case of SSO.
export default function NotSignedInRedirect({ location }: Props) {
  let redirect = '/';
  if (location.state && location.state.redirectTo) {
    redirect = location.state.redirectTo;
    localStorage.setItem('redirect', redirect);
  }

  return <Redirect to="/signin" />;
}
