import { debounce as _debounce } from 'lodash';
import React from 'react';

import type { InputValue } from 'components/types/input';

import { WithWidthExtension } from 'components';

import BaseInput, {
  type Props as BulmaInputProps,
} from '../bulmaElements/Input';

type DebounceOptions = {
  wait: number;
  maxWait?: number;
  leading?: boolean;
  trailing?: boolean;
};

type ExpandWidthOnFocusOptions = {
  initialWidth: number;
  expandedWidth: number;
  duration?: number;
};

export type Props<T extends InputValue> = BulmaInputProps<T> & {
  debounce?: DebounceOptions;
  expandWidthOnFocus?: ExpandWidthOnFocusOptions;
};

type State<T extends InputValue> = {
  value: T | null | undefined;
  isDirty: boolean;
  focused: boolean;
};

class Input<T extends InputValue> extends React.Component<Props<T>, State<T>> {
  state: State<T> = {
    value: this.props.value,
    isDirty: false,
    focused: false,
  };

  input = React.createRef();

  static getDerivedStateFromProps<T extends InputValue>(
    props,
    state
  ): State<T> {
    if (!state.isDirty && state.value !== props.value) {
      return { ...state, value: props.value };
    }

    if (state.isDirty && state.value === props.value) {
      return { ...state, isDirty: false };
    }

    return state;
  }

  getRef = () => this.props.innerRef || this.input;

  debouncedOnChange = _debounce(
    this.props.onChange ? this.props.onChange : (_value: T) => {},
    this.props.debounce && this.props.debounce.wait,
    this.props.debounce
  );

  onChange = async value => {
    const { onChange, debounce } = this.props;

    this.setState({ isDirty: true });

    if (!onChange) return;

    if (!!debounce) {
      this.setState({ value });
      await this.debouncedOnChange(value);
    } else {
      await onChange(value);
    }
  };

  // Necessary to control focus from outside ref
  focus = () => {
    const ref = this.getRef();
    ref.current && ref.current.focus();
    this.setState({ focused: true });
  };

  onFocus = () => {
    this.setState({ focused: true });
    this.props.onFocus && this.props.onFocus();
  };

  onBlur = () => {
    this.setState({ focused: false });
    this.props.onBlur && this.props.onBlur();
  };

  render() {
    const {
      expandWidthOnFocus,

      // Subsequent properties are unused, but we want to filter them out from `otherProps`.
      // `otherProps` will be forwarded to <input>, so it must have only valid HTML attributes
      onChange,
      debounce,
      ...otherProps
    } = this.props;
    const { focused } = this.state;
    const value = !!debounce ? this.state.value : this.props.value;

    let content = (
      <BaseInput
        {...otherProps}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        value={value === null ? '' : value}
        onChange={this.onChange}
        ref={this.getRef()}
      />
    );

    return !!expandWidthOnFocus ? (
      <WithWidthExtension
        expanded={focused}
        initialWidth={expandWidthOnFocus.initialWidth}
        expandedWidth={expandWidthOnFocus.expandedWidth}
        duration={expandWidthOnFocus.duration}
      >
        {content}
      </WithWidthExtension>
    ) : (
      content
    );
  }
}

export default React.forwardRef(
  <T extends InputValue>(
    props: Props<T>,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => <Input innerRef={ref} {...props} />
);
