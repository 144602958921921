import { __ } from 'helpers/i18n';

type Props = {
  fieldLabel: string;
  userFullName: string;
  integrationName: string;
  lastSyncedFormattedValue: string | undefined;
};

const generateUnlockedModalTexts = ({
  fieldLabel,
  userFullName,
  integrationName,
  lastSyncedFormattedValue,
}: Props) => {
  return {
    title: __(
      'Enable the synchronization of the field %1 for %2',
      fieldLabel,
      userFullName
    ),
    body1: __(
      'This action will synchronize the field %1 for %2.',
      fieldLabel,
      userFullName,
      integrationName
    ),
    body2: __(
      'By performing this action, the value of the field %1 will be set by your active integration (%2) and won’t be editable anymore.',
      fieldLabel,
      integrationName
    ),
    body3: __(
      'We will reset the last value sent by your integration: “%1”.',
      lastSyncedFormattedValue || __('No value')
    ),
    checkboxLabel: __(
      'I want to enable the synchronization of the field %1 for %2.',
      fieldLabel,
      userFullName
    ),
    submitLabel: __('Enable'),
  };
};

export default generateUnlockedModalTexts;
