import React, { Fragment } from 'react';

import type { SkillsLevelExpectationMapping } from 'models';

import { __ } from 'helpers/i18n';

import { BoxList, FeatureFlagged, Text } from 'components';

import ExpectationWithoutDomain from './ExpectationWithoutDomain';
import ExpectationsWithDomain from './ExpectationsWithDomain';

type Props = {
  currentLevelTitle: string;
  expectationMappings: Array<SkillsLevelExpectationMapping>;
};

const ExpectationsSummary = ({
  currentLevelTitle,
  expectationMappings,
}: Props) => {
  const expectationMappingsGroupedByDomain: {
    [domainTitle: string]: SkillsLevelExpectationMapping[];
  } = expectationMappings.reduce((hash, expectationMapping) => {
    const domainTitle = expectationMapping.domainTitle;
    if (!!domainTitle) {
      hash[domainTitle] = hash[domainTitle] || [];
      hash[domainTitle].push(expectationMapping);
    }
    return hash;
  }, {});
  const displayedDomains = new Set();

  return (
    <div className="mt-6">
      <Text color="light" transformation="uppercase" preset="14bs6">
        {__('Expectations for the %1 level', currentLevelTitle)}
      </Text>

      <BoxList additionalClassName="m-0">
        <FeatureFlagged
          flag="skillsDomains"
          render={featureFlagged => (
            <Fragment>
              {expectationMappings.map(expectationMapping => {
                const domainTitle = expectationMapping.domainTitle;

                if (!featureFlagged || !domainTitle) {
                  return (
                    <ExpectationWithoutDomain
                      key={expectationMapping.id}
                      expectationMapping={expectationMapping}
                    />
                  );
                } else if (!displayedDomains.has(domainTitle)) {
                  displayedDomains.add(domainTitle);

                  return (
                    <ExpectationsWithDomain
                      key={domainTitle}
                      domainTitle={domainTitle}
                      expectationMappings={
                        expectationMappingsGroupedByDomain[domainTitle]
                      }
                    />
                  );
                }
                return null;
              })}
            </Fragment>
          )}
        />
      </BoxList>
    </div>
  );
};

export default ExpectationsSummary;
