import React from 'react';

import Notification from '../Notification';
import DefaultErrorMessage from './DefaultErrorMessage';

type Props = {
  errorRenderer?: () => React.ReactNode;
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
  };

  defaultErrorRenderer = () => (
    <Notification kind="danger">
      <DefaultErrorMessage />
    </Notification>
  );

  componentDidCatch(
    error: Error,
    errorInfo: {
      componentStack: string;
    }
  ) {
    this.setState({ hasError: true });
    window.logException(error, { extra: errorInfo });
  }

  render() {
    const { hasError } = this.state;
    const { errorRenderer, children } = this.props;

    if (hasError) {
      return errorRenderer ? errorRenderer() : this.defaultErrorRenderer();
    }

    return children;
  }
}
