import queryString from 'qs';

const alphabeticalSort = (a: string, b: string) => a.localeCompare(b);

interface ParsedQs {
  [key: string]: undefined | string | string[] | ParsedQs | ParsedQs[];
}

function parse(input: string): ParsedQs {
  return queryString.parse(input, {
    ignoreQueryPrefix: true,
    strictNullHandling: true,
  });
}

const stringify = (queryObject: Record<any, any>) =>
  queryString.stringify(queryObject, {
    sort: alphabeticalSort,
    encodeValuesOnly: true,
    strictNullHandling: true,
  });

const moduleExport = { ...queryString, parse, stringify } as const;
export default moduleExport;
