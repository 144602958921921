import React from 'react';

import { __ } from 'helpers/i18n';
import {
  pathToAreaList,
  pathToEmployeesOverview,
  pathToMatrixList,
} from 'helpers/paths';

import { PageHeader } from 'components';

export default function SkillsHeader() {
  return (
    <PageHeader
      title={__('Matrices and skills')}
      tabItems={[
        {
          to: pathToMatrixList(),
          label: __('Skills matrices'),
          labelPreset: '16bs5.5',
        },
        {
          to: pathToAreaList(),
          label: __('Skills library'),
          labelPreset: '16bs5.5',
        },
        {
          to: pathToEmployeesOverview(),
          label: __('Employees'),
          labelPreset: '16bs5.5',
        },
      ]}
    />
  );
}
