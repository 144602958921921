import React, { CSSProperties, ReactNode } from 'react';
import { connect } from 'react-redux';

import type { KeyResult, ObjectiveCollection, PersonalObjective } from 'models';
import type { UpdatableObjectiveFields } from 'redux/actions/resources';
import type { AppDispatch } from 'redux/actions/types';

import can from 'helpers/can';

import { del, post, put } from 'redux/actions/api';

import ObjectiveCard from 'scenes/components/objectives/ObjectiveCard';

type Props = {
  objective: PersonalObjective;
  collapsibleKeyResults?: boolean;
  objectiveCollection: ObjectiveCollection;
  children?: ReactNode;
  style?: CSSProperties;

  afterUpdate?: () => Promise<void>;
  afterUnpublish?: () => Promise<void>;
  afterDestroy?: () => Promise<unknown>;
  revieweeFullName: string;
  getFeedbackCount?: () => Promise<number>;
};

type AfterConnectProps = Props & {
  onUpdate: (attributes: Partial<UpdatableObjectiveFields>) => Promise<void>;
  onDestroy: () => Promise<void>;

  onAddKeyResult: () => Promise<void>;
  onUpdateKeyResult: (
    keyResultId: string,
    attributes: Partial<KeyResult>
  ) => Promise<void>;
  onDestroyKeyResult: (keyResultId: string) => Promise<void>;
};

function PersonalObjectiveCard({
  objectiveCollection,
  afterUpdate,
  afterUnpublish,
  afterDestroy,
  revieweeFullName,
  ...otherProps
}: AfterConnectProps) {
  const canUpdateObjective = can({
    perform: 'update_objective',
    on: objectiveCollection,
  });

  const canManageDraftObjectives = can({
    perform: 'manage_draft_objectives',
    on: objectiveCollection,
  });

  return (
    <ObjectiveCard
      {...otherProps}
      locked={!canUpdateObjective || objectiveCollection.isLocked}
      canManageDraftObjectives={canManageDraftObjectives}
      lockedReason={objectiveCollection.lockedReason}
      weighted={objectiveCollection.isWeighted}
      weightSumWarning={objectiveCollection.objectiveWeightSumWarning}
      revieweeFullName={revieweeFullName}
    />
  );
}

const mapDispatchProps = (
  dispatch: AppDispatch,
  {
    objective,
    objectiveCollection,
    afterUpdate,
    afterUnpublish,
    afterDestroy,
  }: Props
) => {
  const { collectionPath } = objectiveCollection || {
    collectionPath: 'temporary',
  }; // To make it work

  return {
    onUpdate: async (attributes: Partial<UpdatableObjectiveFields>) => {
      await dispatch(
        put(`${collectionPath}/${objective.id}`, {
          objective: attributes,
        })
      );

      if (
        Object.keys(attributes).includes('published') &&
        !attributes.published
      ) {
        !!afterUnpublish && afterUnpublish();
      }

      !!afterUpdate && (await afterUpdate());
    },
    onDestroy: async () => {
      await dispatch(del(`${collectionPath}/${objective.id}`));

      !!afterDestroy && (await afterDestroy());
    },
    onAddKeyResult: async () => {
      await dispatch(post(`${collectionPath}/${objective.id}/key_results`));

      !!afterUpdate && (await afterUpdate());
    },
    onUpdateKeyResult: async (
      keyResultId: string,
      attributes: Partial<KeyResult>
    ) => {
      await dispatch(
        put(`${collectionPath}/${objective.id}/key_results/${keyResultId}`, {
          keyResult: attributes,
        })
      );

      !!afterUpdate && (await afterUpdate());
    },
    onDestroyKeyResult: async (keyResultId: string) => {
      await dispatch(
        del(`${collectionPath}/${objective.id}/key_results/${keyResultId}`)
      );

      !!afterUpdate && (await afterUpdate());
    },
  };
};

export default connect(
  null,
  mapDispatchProps
)(PersonalObjectiveCard) as React.ComponentType<Props>;
