import React from 'react';

import type { Locale } from 'models';

import invariant from 'helpers/invariant';

import { Icon, Select } from 'components';

type Option = {
  label: string;
  value: Locale;
  icon?: React.ReactElement<typeof Icon>;
};

type Props = {
  onChange: (option: Option | null) => void;
  value: Locale;
  options: Array<Option>;
};

export default function EmailLocaleSelect({ onChange, value, options }: Props) {
  const longestLabel = options.sort((a, b) =>
    a.label.length > b.label.length ? -1 : 1
  )[0].label.length;

  const selectValue = options.find(opt => opt.value === value);

  invariant(selectValue, 'Select value should be defined');

  return (
    <div
      style={{ width: `${longestLabel + 2}ch` }} // make the select's width the same as it's longest label
      className="test-email-locale-selector"
    >
      <Select
        onChange={option => {
          onChange(!option ? null : option);
        }}
        value={selectValue}
        options={options}
        components={{ IndicatorSeparator: null }}
      />
    </div>
  );
}
