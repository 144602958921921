import React from 'react';

import type {
  ReviewBlockUpdateParams,
  TrainingRequestHistoryBlock,
} from 'models/ReviewStructure';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import {
  Box,
  BoxSeparator,
  Flex,
  Helper,
  Title,
  WithSavingStatusRecorder,
} from 'components';

import BlockActions from './components/BlockActions';
import BlockContent from './components/BlockContent';

type Props = {
  block: TrainingRequestHistoryBlock;
  onChange: (params: ReviewBlockUpdateParams) => Promise<void>;
  onDelete?: () => Promise<void>;
  onMoveUp: () => Promise<void>;
  onMoveDown: () => Promise<void>;
};

const TrainingRequestHistoryBlockComponent = ({
  block,
  onChange,
  ...actionProps
}: Props) => {
  return (
    <Box testClassName="test-training-request-history-block">
      <Flex style={{ justifyContent: 'space-between' }} verticalAlign>
        <Title size={6} transformation="uppercase">
          {__('History of training requests')}
        </Title>
        <BlockActions
          canMoveUp={block.canMoveUp}
          canMoveDown={block.canMoveDown}
          canDuplicate={block.canDuplicate}
          {...actionProps}
        />
      </Flex>

      <BoxSeparator style={{ margin: '16px 0' }} />

      <WithSavingStatusRecorder
        fieldUid={compositeKey({
          blockId: block.id,
          field: 'training_request_history_block_content',
        })}
        onChange={onChange}
        render={autoSavingOnchange => (
          <BlockContent
            onChange={autoSavingOnchange}
            content={block.content}
            withRichText={block.richTextEnabled}
          />
        )}
      />
      <Helper>
        {__(
          'The reviewee and the reviewer will see the last 5 training requests made for the reviewee (all statuses combined) as well as a link to all requests made for the reviewee.'
        )}
      </Helper>
    </Box>
  );
};

export default TrainingRequestHistoryBlockComponent;
