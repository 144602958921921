import { useEffect, useRef, useState } from 'react';

const OPTIONS = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1,
};

export default function useIsVisible() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(entries => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
      }, OPTIONS);
      observer.observe(ref.current);
    }
  }, [ref]);

  return { ref, isVisible };
}
