import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Redirect, Route, Switch } from 'components';

import TemplateEdit from './Edit';
import OldTemplatePreview from './OldPreview';
import TemplatePreview from './Preview';

const Routes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/edit`} component={TemplateEdit} />
      <Route path={`${path}/preview`} component={OldTemplatePreview} exact />
      <Route path={`${path}/preview/v2/:role`} exact>
        <TemplatePreview />
      </Route>

      <Redirect to={`${path}/edit`} />
    </Switch>
  );
};

export default Routes;
