import React, { ComponentProps, useEffect } from 'react';

import FundingSection from '../components/FundingSection';

type Props = ComponentProps<typeof FundingSection>;

const SessionFundingSection = ({
  periodSlug,
  fundingItems: sessionFundingItems,
  errors,
  onChange,
  disabled,
}: Props) => {
  const [fundingItems, setFundingItems] = React.useState(sessionFundingItems);

  useEffect(() => {
    setFundingItems(sessionFundingItems);
  }, [sessionFundingItems]);

  return (
    <FundingSection
      periodSlug={periodSlug}
      fundingItems={fundingItems}
      errors={errors}
      disabled={disabled}
      onChange={fundingItems => {
        setFundingItems(fundingItems);
        onChange(fundingItems);
      }}
    />
  );
};

export default SessionFundingSection;
