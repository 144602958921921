import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { connect } from 'react-redux';

import type { ReduxStore } from 'redux/reducers';

import GlobalErrorNotice from './GlobalErrorNotice';

type Props = {
  children: React.ReactNode;
};

type AfterConnectProps = Props & {
  message?: string;
};

function GlobalErrorBoundary({ children, message }: AfterConnectProps) {
  if (message) {
    return <GlobalErrorNotice message={message} />;
  }

  return (
    <ErrorBoundary fallback={<GlobalErrorNotice />}>{children}</ErrorBoundary>
  );
}

export default connect((state: ReduxStore) => ({
  message: state.application.blockingMessage,
}))(GlobalErrorBoundary);
