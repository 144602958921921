import React from 'react';

import type { TrainingRequest } from 'models';

import { Box, BoxList, BoxListItem } from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

type Props = {
  trainingRequests: Array<TrainingRequest>;
};

const TrainingRequestList = ({ trainingRequests }: Props) => {
  return (
    <Box isInset>
      <BoxList>
        {trainingRequests.map(trainingRequest => (
          <BoxListItem
            key={trainingRequest.id}
            testClassName="test-training-request-list-item"
          >
            <UserAvatar user={trainingRequest.trainee} withJobTitle />
          </BoxListItem>
        ))}
      </BoxList>
    </Box>
  );
};

export default TrainingRequestList;
