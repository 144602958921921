import React from 'react';

import type { TrainingParticipant } from 'models';

import { __ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import { Text } from 'components';

type Props = {
  participant: TrainingParticipant;
};

const FundingCell = ({ participant }: Props) => {
  if (participant.fundingItems.length === 0)
    return (
      <Text
        transformation="italic"
        color="light"
        testClassName="test-funding-cell"
      >
        {__('No funding')}
      </Text>
    );
  else
    return (
      <div className="test-funding-cell">
        {participant.fundingItems.map(item => (
          <div key={item.id}>
            <span>{item.fundingSource.title}</span>:{' '}
            <span>
              {!!item.amountCents
                ? formatMoney(item.amountCents, item.amountCurrency)
                : __('No amount')}
            </span>
          </div>
        ))}
      </div>
    );
};

export default FundingCell;
