import React, { ReactNode } from 'react';

import type { ReviewCycle } from 'models';
import type { BulkReminderType } from 'redux/actions/resources';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';
import { trackAction } from 'helpers/tracking';

import { post } from 'redux/actions/api';

import {
  Button,
  Dropdown,
  DropdownDivider,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Icon,
  Testable,
} from 'components';

type BulkReminderDefinition<TCycleType extends ReviewCycle> = {
  label: string;
  confirmation: string;
  type: BulkReminderType;
  condition?: (reviewCycle: TCycleType) => boolean;
};

type Props<TCycleType extends ReviewCycle> = {
  reviewCycle: TCycleType;
  reminders: {
    [reminderGroupLabel: string]: Array<BulkReminderDefinition<TCycleType>>;
  };
};

const ReminderMenu = <TCycleType extends ReviewCycle>({
  reviewCycle,
  reminders,
}: Props<TCycleType>) => {
  const dispatch = useAppDispatch();

  const sendBulkReminder = async (
    reminderType: BulkReminderType,
    confirmationMessage: string
  ) => {
    await confirmAsync(__('Send bulk reminder'), confirmationMessage, {
      confirmLabel: __('Send reminders'),
      onConfirm: async () => {
        trackAction('Bulk reminder sent', { reminderType });
        await dispatch(
          post(
            `review_cycles/${reviewCycle.id}/reminders/${reminderType}`,
            undefined,
            {
              successMessage: __('Emails are on their way! 📧'),
            }
          )
        );
      },
    });
  };

  const renderMenuContent = () => {
    const content: ReactNode[] = [];
    let reminderGroupIndex = 0;

    for (let reminderGroupLabel in reminders) {
      const remindersInGroup = reminders[reminderGroupLabel];

      if (reminderGroupIndex > 0) {
        content.push(<DropdownDivider key={`divider-${reminderGroupIndex}`} />);
      }
      reminderGroupIndex++;

      content.push(
        <React.Fragment key={`reminder-group-content-${reminderGroupIndex}`}>
          {remindersInGroup
            .filter(
              reminder => !reminder.condition || reminder.condition(reviewCycle)
            )
            .map((reminder, index) => (
              <DropdownMenuItem
                key={`dropdown-menu-${index}`}
                onClick={() =>
                  sendBulkReminder(reminder.type, reminder.confirmation)
                }
              >
                {reminder.label}
              </DropdownMenuItem>
            ))}
        </React.Fragment>
      );
    }
    return content;
  };

  const disabledReminder = reviewCycle.userReviewsCount === 0;

  return (
    <Testable name="test-bulk-reminders-button">
      <Dropdown disabled={disabledReminder}>
        <DropdownTrigger>
          <Button color="secondary" disabled={disabledReminder}>
            <span>{__('Reminders')}</span>
            <Icon name="keyboard_arrow_down" />
          </Button>
        </DropdownTrigger>
        <DropdownMenu>{renderMenuContent()}</DropdownMenu>
      </Dropdown>
    </Testable>
  );
};

export default ReminderMenu;
