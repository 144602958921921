import React, { CSSProperties, Fragment } from 'react';

import type { UserFilterSegment } from 'models';

import { __ } from 'helpers/i18n';

import { Icon, Link } from 'components';

type Props = {
  segment: UserFilterSegment | undefined | null;
  style?: CSSProperties;
  onClick: () => void;
  onClear: () => void;
  disabled?: boolean;
};

const Trigger = ({ segment, style, onClick, onClear, disabled }: Props) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="trigger" style={style} onClick={onClick}>
      {!!segment && !!segment.filterLabel ? (
        <Fragment>
          {!!segment.label ? (
            __(
              '%1: %2',
              <span className="filter-type">{segment.filterLabel}</span>,
              <div className="segment">
                <span>{segment.label}</span>
              </div>
            )
          ) : (
            <span className="filter-type">{segment.filterLabel}</span>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <Icon
            style={{ marginBottom: 2, marginRight: 4 }}
            name="filter_list"
          />
          <span>{__('Filter')}</span>
        </Fragment>
      )}

      {!!segment && !disabled && (
        <Link className={null} onClick={onClear} style={{ marginLeft: 4 }}>
          <Icon size="tiny" style={{ marginBottom: 1 }} name="close" />
        </Link>
      )}
    </div>
  );
};

export default Trigger;
