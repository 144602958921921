import React from 'react';

import type { ReviewCycle, UserFilterType } from 'models';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { ContentContainer } from 'components';

import Skills from 'scenes/components/reviewCycle/analytics/Skills';

type Props = {
  reviewCycle: ReviewCycle;
};

const ManagerCycleSkills = ({ reviewCycle }: Props) => {
  const activeUser = useActiveUser();
  const defaultUserFilter = {
    filterLabel: __('Direct reports of'),
    filterType: 'direct_reports' as UserFilterType,
    label: activeUser.fullName,
    value: activeUser.id,
  };
  return (
    <ContentContainer>
      <Skills reviewCycle={reviewCycle} defaultUserFilter={defaultUserFilter} />
    </ContentContainer>
  );
};

export default ManagerCycleSkills;
