import { UserReview } from 'models';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import SkillsSummaryBoardComponent from './SkillsSummaryBoardComponent';

type Props = {
  userReview: UserReview;
};

export default newDataLoader({
  fetch: ({ userReview }: Props) =>
    get('skills/stats/summary_board', { user_review_ids: userReview.id }),
  hydrate: {
    summaryData: {
      reviewers: {},
    },
  },
})(SkillsSummaryBoardComponent);
