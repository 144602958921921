import React from 'react';

import { Checkbox } from 'components';

type Props = {
  isCompleted: boolean;
  completion: number | null | undefined;
  disabled: boolean;
  onChange: (value: boolean) => Promise<void>;
};

export default function KeyResultIsCompletedCheckbox({
  isCompleted,
  completion,
  disabled,
  onChange,
}: Props) {
  const isOrganicallyCompleted = !!completion && completion > 100;

  return (
    <Checkbox
      color="primary"
      disabled={disabled}
      isChecked={isCompleted}
      onChange={!isOrganicallyCompleted ? onChange : undefined}
      additionalClassName="key-result-completed-checkbox"
      size="tiny"
    />
  );
}
