import React from 'react';

import type {
  SkillsMatrix,
  UserWithCareerLevelAndAssignationCriterion,
} from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToUser } from 'helpers/paths';
import confirmAsync from 'helpers/react/confirmAsync';

import { htmlSuccessNotice } from 'redux/actions';
import { put } from 'redux/actions/api';

import {
  BoxListItem,
  Button,
  Column,
  Columns,
  Icon,
  PullRight,
  Text,
  Tooltip,
} from 'components/index';

import UserAvatar from 'scenes/components/UserAvatar';

import useAssignationEnabled from '../../helpers/userAssignation/useAssignationEnabled';

type Props = {
  user: UserWithCareerLevelAndAssignationCriterion;
  refetchData: () => Promise<void>;
  matrix: SkillsMatrix;
};

export default function EmployeeListItem({ user, matrix, refetchData }: Props) {
  const dispatch = useAppDispatch();
  const assignationEnabled = useAssignationEnabled();

  const successMessage = __(
    'The employee has been successfully detached from the level <b>%1</b> of the skills matrix <b>%2</b>',
    user.currentCareerLevel.skillsLevel?.title,
    matrix.title
  );

  const detachEmployee = async () => {
    await dispatch(
      put(`skills/user_career_levels/${user.currentCareerLevel.id}`, {
        levelId: null,
      })
    );
    refetchData();
    dispatch(htmlSuccessNotice(successMessage));
  };

  const confirmDetachEmployee = () => {
    return confirmAsync(
      __('Detach the employee from the level?'),
      <React.Fragment>
        <p>
          {__(
            'This action will detach the employee %1 from the level %2.',
            <b>{user.fullName}</b>,
            <b>{user.currentCareerLevel.skillsLevel?.title}</b>
          )}
        </p>
        <p>
          {__(
            "Are you sure you want to detach %1's profile from the level %2?",
            <b>{user.fullName}</b>,
            <b>{user.currentCareerLevel.skillsLevel?.title}</b>
          )}
        </p>
      </React.Fragment>,

      {
        confirmLabel: __('Detach from level'),
        isDanger: true,
        onConfirm: () => detachEmployee(),
      }
    );
  };

  const managerColumnContent = () =>
    !!user.manager ? (
      <UserAvatar
        user={user.manager}
        link={pathToUser(user.manager.id)}
        withJobTitle
      />
    ) : (
      <Text>-</Text>
    );

  const actionsColumnContent = () => (
    <PullRight>
      <Tooltip content={__('Detach employee')}>
        <Button
          hasIconOnly
          additionalClassName="border-none bg-transparent"
          onClick={() => {
            confirmDetachEmployee();
          }}
        >
          <Icon size="large" name="delete" />
        </Button>
      </Tooltip>
    </PullRight>
  );

  const assignationColumnContent = () =>
    !!user.skillsAssignationCriterionValue ? (
      <Text>{user.skillsAssignationCriterionValue}</Text>
    ) : (
      <Text transformation="italic" color="grey">
        {__('Empty field in the profile')}
      </Text>
    );

  const levelColumnContent = () => {
    const levelText = <Text>{user.currentCareerLevel.skillsLevel?.title}</Text>;

    if (assignationEnabled) {
      return levelText;
    }

    return (
      <div className="flex justify-between">
        {levelText}
        {actionsColumnContent()}
      </div>
    );
  };

  return (
    <BoxListItem>
      <Columns>
        <Column isVerticallyCentered size={assignationEnabled ? 3 : 4}>
          <UserAvatar user={user} link={pathToUser(user.id)} withJobTitle />
        </Column>
        <Column isVerticallyCentered size={assignationEnabled ? 3 : 4}>
          {managerColumnContent()}
        </Column>
        <Column isVerticallyCentered size={assignationEnabled ? 2 : 4}>
          {levelColumnContent()}
        </Column>
        {assignationEnabled && (
          <Column isVerticallyCentered size={4}>
            <div className="flex justify-between">
              {assignationColumnContent()}
              {actionsColumnContent()}
            </div>
          </Column>
        )}
      </Columns>
    </BoxListItem>
  );
}
