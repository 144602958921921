import React from 'react';
import { connect } from 'react-redux';

import type { ImportedReview, User } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';
import { addToken } from 'helpers/url';

import { createUrl } from 'lib/api';
import { del } from 'redux/actions/api';

import {
  BoxListItem,
  Can,
  Column,
  Columns,
  Date,
  HamburgerMenu,
  Link,
  MenuItem,
  MenuList,
  Text,
} from 'components';

type Props = {
  importedReview: ImportedReview;
  user: User;
};

type AfterConnectProps = Props & {
  downloadImportedReview: (id: string) => void;
  deleteImportedReview: () => void;
};

function ImportedReviewItem({
  importedReview,
  downloadImportedReview,
  deleteImportedReview,
}: AfterConnectProps) {
  return (
    <BoxListItem>
      <Columns>
        <Column>
          <Link
            {...(importedReview.hasFile && {
              onClick: () => downloadImportedReview(importedReview.id),
            })}
          >
            {importedReview.reviewTitle}
          </Link>
        </Column>
        <Column isNarrow>
          <Date value={importedReview.reviewDate} />
        </Column>
        <Can perform="destroy" on={importedReview}>
          <Column isNarrow>
            <HamburgerMenu align="right">
              <MenuList>
                <MenuItem key="delete" onClick={deleteImportedReview}>
                  <Text color="danger">{__('Delete this review')}</Text>
                </MenuItem>
              </MenuList>
            </HamburgerMenu>
          </Column>
        </Can>
      </Columns>
    </BoxListItem>
  );
}

function mapDispatchToProps(dispatch: AppDispatch, ownProps: Props) {
  return {
    downloadImportedReview: (id: string) => {
      const url = addToken(createUrl(`imported_reviews/${id}/download`));
      window.open(url, '_blank');
    },
    deleteImportedReview: async () => {
      const { importedReview, user } = ownProps;
      const title = __('Remove Imported review');
      const description = __(
        "The imported review %1 will be permanently deleted from %2's profile.",
        <b>{importedReview.reviewTitle}</b>,
        user.fullName
      );
      const onConfirm = async () => {
        await dispatch(del(`imported_reviews/${importedReview.id}`));
        window.location.reload();
      };
      const confirmLabel = __('Delete the imported review');
      const sideEffects = [
        __('Delete the imported review from the employee’s profile'),
      ];
      const sideEffectsLabel = __('Please confirm the action:');

      await confirmAsync(title, description, {
        onConfirm,
        confirmLabel,
        sideEffects,
        sideEffectsLabel,
      });
    },
  };
}

export default connect(
  null,
  mapDispatchToProps
)(ImportedReviewItem) as React.ComponentType<Props>;
