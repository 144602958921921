import React, { Fragment } from 'react';

import type { ColumnSize } from 'components/layout';
import type { UserSync } from 'models';

import { __ } from 'helpers/i18n';

import { BoxListItem, Column, Columns, Link, Text } from 'components';

type Props = {
  userSync: UserSync;
  integrationType: 'BambooHR' | 'Lucca' | 'Eurecia';
  type: 'error' | 'warning';
};

export default function UserSyncListItem({
  userSync,
  integrationType,
  type,
}: Props) {
  return (
    <BoxListItem key={userSync.createdAt}>
      <Columns>
        <ListItemDataColumn
          size={4}
          label={__('User: ')}
          data={userSync.userIdentity}
        />
        {type === 'error' ? (
          <Column isVerticallyCentered>
            <SyncErrors syncErrors={userSync.syncErrors} />
          </Column>
        ) : (
          <Column isVerticallyCentered>
            <ul>
              {userSync.syncWarnings.map(warning => (
                <li key={warning}>{warning}</li>
              ))}
            </ul>
          </Column>
        )}
        <Column isVerticallyCentered isNarrow>
          <ExternalUserLink
            userSync={userSync}
            integrationType={integrationType}
          />
        </Column>
      </Columns>
    </BoxListItem>
  );
}

type SyncErrorProps = {
  syncErrors: Array<string>;
};

const SyncErrors = ({ syncErrors }: SyncErrorProps) => {
  if (syncErrors.length === 1) {
    return <Fragment>{syncErrors[0]}</Fragment>;
  }

  return (
    <ul>
      {syncErrors.map(error => (
        <li key={error}>{error}</li>
      ))}
    </ul>
  );
};

const ExternalUserLink = ({
  userSync,
  integrationType,
}: Omit<Props, 'type'>) => {
  let wording: string | null = null;
  switch (integrationType) {
    case 'BambooHR':
      wording = __('BambooHR profile');
      break;
    case 'Lucca':
      wording = __('Lucca profile');
      break;
    default:
  }

  if (!!wording) {
    return !!userSync.externalUrl ? (
      <Link to={userSync.externalUrl} openInNewTab>
        {wording}
      </Link>
    ) : (
      <Text color="info">{wording}</Text>
    );
  }
  return null;
};

type ListItemDataColumnProps = {
  label?: string;
  data: string;
  size?: ColumnSize;
  hideOnMobile?: boolean;
};

const ListItemDataColumn = ({
  label,
  data,
  size,
  hideOnMobile,
}: ListItemDataColumnProps) => (
  <Column
    size={size}
    isVerticallyCentered
    ellipsisOnLineOverflow
    hideOn={hideOnMobile ? 'mobile' : undefined}
  >
    {label && <Text color="info">{label}</Text>}
    <Text title={data}>{data}</Text>
  </Column>
);
