import React, { useState } from 'react';

import { useAppDispatch } from 'helpers/hooks';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { get, put } from 'redux/actions/api';

import { Button, Can, DirectUploader, Icon, Label, Text } from 'components';
import ImageUploader from 'components/ImageUploader';
import homeLogoSvg from 'components/Logo/homeLogoSvg.svg';

import BackgroundImageColorPicker from './components/BackgroundImageColorPicker';

const HomeLogo = () => {
  const organization = useOrganization();
  const dispatch = useAppDispatch();
  const imgSrc = organization.theme.logoUrl;
  const defaultBackgroundColor = organization.theme.logoBackgroundColor;
  const [selectedColor, setSelectedColor] = useState(defaultBackgroundColor);

  const onUploadSuccess = async () => {
    if (selectedColor !== defaultBackgroundColor) {
      return dispatch(
        put(`organization_themes/${organization.theme.id}`, {
          organizationThemes: {
            logoBackgroundColor: selectedColor,
          },
        })
      );
    } else {
      return dispatch(get(`organization_themes/${organization.theme.id}`));
    }
  };

  const imageUploaderActions = (
    _errors: unknown,
    onImageUpload: () => void
  ) => {
    return (
      <div className="absolute top-2 right-2 invisible group-hover:visible">
        <Button hasIconOnly isRounded onClick={onImageUpload}>
          <Icon name="edit" />
        </Button>
      </div>
    );
  };

  return (
    <div className="relative w-32 md:w-40 group -mt-16 md:-mt-20 md:flex md:items-end md:space-x-5">
      <img
        alt="logo"
        src={imgSrc || homeLogoSvg}
        className="h-32 w-32 rounded-2xl ring-1 ring-white md:h-40 md:w-40 object-contain"
        style={{
          background: defaultBackgroundColor,
        }}
      />
      <Can perform="update" on={organization.theme}>
        <DirectUploader
          resourcePath={`organization_themes/${organization.theme.id}`}
          method="PUT"
          attribute="logo"
          model="organization_themes"
          onSuccess={onUploadSuccess}
          render={({ uploadFromDataURL }) => (
            <ImageUploader
              onUpload={uploadFromDataURL}
              cropShape="rect"
              cropBackgroundColor={selectedColor}
              cropShapeAspect={1}
              minZoom={0.2}
              modalTitle={__('Configuration of the logo')}
              uploadErrorMessage={__(
                'The logo must be in .png .jpeg or .gif format with a maximum size of 5Mo.'
              )}
              imageUploaderRender={imageUploaderActions}
            >
              <BackgroundImageColorPicker
                color={selectedColor}
                onChange={value => setSelectedColor(value)}
              />
              <div className="mb-4">
                <Label>{__('Import a logo')}</Label>
                <Text style={{ whiteSpace: 'pre-wrap' }} preset="13s7">
                  {__(
                    'For an optimal quality the logo must respect the following dimensions: 320 x 320 pixels.\n' +
                      'Accepted formats: .png .jpeg or .gif. 5 Mo maximum.'
                  )}
                </Text>
              </div>
            </ImageUploader>
          )}
        />
      </Can>
    </div>
  );
};

export default HomeLogo;
