import { useMemo, useRef, useState } from 'react';

import type { Options } from 'redux/actions/api/methods';

import { highLevelErrorHandler } from 'lib/api';

import { booleanType, hasShape, stringType } from 'types/predicates/WithShape';

const RECORD_ERROR_SHAPE = { response: { body: { errors: {} } } };
const HANDLED_ERROR_SHAPE = {
  response: {
    body: {
      error: {
        message: stringType,
        html: stringType,
        display: booleanType,
        blocking: booleanType,
      },
    },
  },
};

type HandledError = typeof HANDLED_ERROR_SHAPE['response']['body']['error'];

type CallApiFunction<Args extends unknown[]> = (
  options: Options
) => (...args: Args) => unknown;

type RecordErrors = Record<string, string[] | undefined>;

/**
 * @example
 * const [performAction, { recordErrors, handledError }] = useCatchedAPIErrors(
 *   fetchOptions => () => put(endpoint, params, fetchOptions)
 * );
 *
 * return (
 *   <Button onClick={performAction}>Just do it!</Button>
 *   <FieldError>{recordErrors.field}</FieldError>
 *   <FieldError>{handledError?.message}</FieldError>
 * )
 * @param callApi Function using get/post/put/del to call the API
 */
const useCatchedAPIErrors = <FunctionArgs extends unknown[]>(
  callApi: CallApiFunction<FunctionArgs>
) => {
  type ReturnedErrors = {
    recordErrors?: RecordErrors;
    handledError?: HandledError;
  };

  const [recordErrors, setRecordErrors] = useState<RecordErrors>(
    {} as RecordErrors
  );
  const [handledError, setHandledError] = useState<HandledError | null>(null);

  const { errorsProxy, accessedRecordErrorsRef, accessedHandledErrorRef } =
    useProxifiedErrors(recordErrors, handledError);

  const getRecordErrorsNotAccessedByParent = (
    error: typeof RECORD_ERROR_SHAPE
  ) => {
    return Object.keys(error.response.body.errors).filter(
      key => !accessedRecordErrorsRef.current[key]
    );
  };

  const isHandledErrorAccessedByParent = (_: typeof HANDLED_ERROR_SHAPE) =>
    accessedHandledErrorRef.current;

  const clearErrors = () => {
    setRecordErrors({} as RecordErrors);
    setHandledError(null);
  };

  /**
   * @returns Depending on the API response:
   *  - `Promise<true>` if the call was successful
   *  - `Promise<false>` if the error is returned by the hook to be rendered
   *  - A rejected promise for every other errors
   */
  const handleSubmit = async (...args: FunctionArgs) => {
    clearErrors(); // Visual feedback by blinking if errors stay unchanged

    let errors: ReturnedErrors = {};
    let errorCatchedByHook: unknown | null = null;

    try {
      await callApi({
        customErrorHandler: (error, dispatch) => {
          if (hasShape(error, RECORD_ERROR_SHAPE)) {
            errors.recordErrors = error.response.body.errors as RecordErrors;
            const uncaughtErrors = getRecordErrorsNotAccessedByParent(error);
            if (uncaughtErrors.length === 0) errorCatchedByHook = error;
          } else if (hasShape(error, HANDLED_ERROR_SHAPE)) {
            errors.handledError = error.response.body.error;
            if (isHandledErrorAccessedByParent(error))
              errorCatchedByHook = error;
          }

          if (errorCatchedByHook) {
            error.isHandled = true;
            error.handledBy = 'useCatchedAPIErrors';
            throw error;
          } else highLevelErrorHandler(error, dispatch);
        },
      })(...args);
    } catch (error: unknown) {
      if (error !== errorCatchedByHook) throw error;
    } finally {
      setRecordErrors(errors.recordErrors || ({} as RecordErrors));
      setHandledError(errors.handledError || null);
    }

    const success = !errorCatchedByHook;
    return success;
  };

  return [handleSubmit, errorsProxy] as const;
};

/**
 * Errors returned to the parent component are observed to know if they are
 * being used.
 * If yes: The hook will trust the parent to handle the error.
 * If not: The hook will apply generic error handling.
 */
const useProxifiedErrors = (
  recordErrors: RecordErrors,
  handledError: HandledError | null
) => {
  const accessedRecordErrorsRef = useRef<Record<string, boolean>>({});
  const accessedHandledErrorRef = useRef<boolean>(false);

  const errorsProxy = useMemo(
    () => ({
      recordErrors: new Proxy(recordErrors, {
        get(target, prop, receiver) {
          accessedRecordErrorsRef.current[prop.toString()] = true;
          return Reflect.get(target, prop, receiver);
        },
      }),
      get handledError() {
        accessedHandledErrorRef.current = true;
        return handledError;
      },
    }),
    [recordErrors, handledError]
  );

  return {
    errorsProxy,
    accessedRecordErrorsRef,
    accessedHandledErrorRef,
  };
};

export default useCatchedAPIErrors;
