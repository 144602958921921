import React, { Fragment, ReactNode, useEffect, useState } from 'react';

import { useIsMobile, useSeasonalTheme } from 'helpers/hooks';
import {
  makeDocumentNonScrollable,
  makeDocumentScrollable,
} from 'helpers/scrollable';

import {
  Column,
  Columns,
  DesignSystem,
  ErrorBoundary,
  MobileShowPanelButton,
  MobileSlidingSidebar,
  SlidingSidebar,
} from 'components';
import AnimatedSpider from 'components/AnimatedComponents/AnimatedSpider';

export type Props = {
  sidebar: ReactNode;
  content: ReactNode;
  topbar: ReactNode;
  userPanel?: ReactNode;
  userAvatarUrl?: string;
};

const ReviewLayout: React.FC<Props> = ({
  sidebar,
  content,
  topbar,
  userPanel,
  userAvatarUrl,
}) => {
  const { displaySeasonalTheme, seasonalTheme } = useSeasonalTheme();
  useEffect(() => {
    makeDocumentNonScrollable();
    return () => {
      makeDocumentScrollable();
    };
  }, []);

  const [showMobileSlidingSidebar, setShowMobileSlidingSidebar] =
    useState<boolean>(false);
  const toggleMobileSlidingSidebar = () => {
    setShowMobileSlidingSidebar(!showMobileSlidingSidebar);
  };

  const isMobile = useIsMobile();

  return (
    <DesignSystem version={2}>
      <div className="review-content !h-screen">
        <Columns additionalClassName="h-full">
          <Column additionalClassName="p-0 flex flex-col h-full">
            {topbar}
            <div className="form-content-wrapper flex-1 relative overflow-y-auto">
              {displaySeasonalTheme && seasonalTheme === 'halloween' && (
                <AnimatedSpider
                  style={{ position: 'absolute', right: 20, top: 0 }}
                />
              )}
              {isMobile && userPanel && (
                <Fragment>
                  <div className="flex justify-end mb-2">
                    <MobileShowPanelButton
                      toggleSidebar={toggleMobileSlidingSidebar}
                      showMobileSlidingSidebar={showMobileSlidingSidebar}
                    />
                  </div>
                  <MobileSlidingSidebar
                    showMobileSlidingSidebar={showMobileSlidingSidebar}
                    toggleSidebar={toggleMobileSlidingSidebar}
                  >
                    {userPanel}
                  </MobileSlidingSidebar>
                </Fragment>
              )}
              <ErrorBoundary>{content}</ErrorBoundary>
            </div>
          </Column>
          <Column additionalClassName="review-sidebar border-y-0 border-r-0 border-l border-solid border-text-lightest/60">
            <ErrorBoundary>{sidebar}</ErrorBoundary>
            {!isMobile && userPanel && (
              <SlidingSidebar avatarUrl={userAvatarUrl}>
                {userPanel}
              </SlidingSidebar>
            )}
          </Column>
        </Columns>
      </div>
    </DesignSystem>
  );
};

export default ReviewLayout;
