import React from 'react';

import type { TrainingSession } from 'models';

import can from 'helpers/can';
import { startToEndDateLabel } from 'helpers/date';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { assertDefined } from 'helpers/invariant';
import { pathToTrainingSessionDetails } from 'helpers/navigation';

import { del } from 'redux/actions/api';

import {
  BoxListItem,
  Column,
  Columns,
  HamburgerMenu,
  Link,
  MenuItem,
  MenuList,
} from 'components';

import AvatarList from 'scenes/components/AvatarList';

import { openRemoveSessionModal } from './openRemoveSessionModal';

type Props = {
  session: TrainingSession;
  onAfterDestroy: () => Promise<void>;
};

const SessionListItem = ({ session, onAfterDestroy }: Props) => {
  const dispatch = useAppDispatch();
  const deleteSession = async () =>
    dispatch(del(`training/sessions/${session.id}`));

  const participants = assertDefined(
    session.participants,
    'Participants should be defined on session on this page'
  );

  return (
    <BoxListItem>
      <Columns
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        isMobile
      >
        <Column size={5}>
          <Link
            hasInheritedColor
            to={pathToTrainingSessionDetails(session.id)}
            {...(session.name
              ? { style: { fontWeight: '600' } }
              : { style: { fontWeight: '300', fontStyle: 'italic' } })}
          >
            {session.name || __('Untitled session')}
          </Link>
        </Column>
        <Column size={3}>
          <AvatarList
            users={participants.map(participant => participant.user)}
            maxInlineUsers={4}
            noUserLabel={__('No participant')}
          />
        </Column>
        <Column size={3}>
          {startToEndDateLabel(session.startDate, session.endDate, {
            fromToLabelsAlwaysVisible: true,
          })}
        </Column>
        <Column size={1} contentIsPulledRight>
          <HamburgerMenu
            disabled={!can({ perform: 'destroy', on: session })}
            disabledMessage={__(
              "You must be the creator to access the session's actions"
            )}
          >
            <MenuList>
              <MenuItem
                isDanger
                onClick={() => {
                  openRemoveSessionModal(
                    session,
                    deleteSession,
                    onAfterDestroy
                  );
                }}
              >
                {__('Delete session')}
              </MenuItem>
            </MenuList>
          </HamburgerMenu>
        </Column>
      </Columns>
    </BoxListItem>
  );
};

export default SessionListItem;
