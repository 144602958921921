import { SkillsUserCareerLevel } from 'models/skills';

import { useAppSelector } from 'helpers/hooks';

import { hydrateFromStore } from 'lib/dataLoader';

const useCareerLevelFromBlockContent = (
  contentBlockId: string
): SkillsUserCareerLevel | null => {
  const getContentBlockFromId = state =>
    hydrateFromStore(
      state.data,
      { resourceType: 'formContentBlock', id: contentBlockId },
      { item: { currentCareerLevel: { skillsLevel: {} } } }
    );

  const content = useAppSelector(getContentBlockFromId);

  return content.currentCareerLevel;
};

export default useCareerLevelFromBlockContent;
