import React from 'react';

import { __ } from 'helpers/i18n';

import { Text } from 'components';

import logo from './logo.svg';

function SmallLogo() {
  return (
    <img
      src={logo}
      alt="Simbel logo"
      style={{ height: '1.5em', paddingBottom: 2, verticalAlign: 'middle' }}
    />
  );
}

export default function PoweredBySimbel() {
  return <Text preset="13s7">{__('Training by %1.', <SmallLogo />)}</Text>;
}
