import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { DesignSystem, Redirect, Route, Switch } from 'components';

import Campaign from './Campaign';
import EditCampaign from './Campaign/Edit';
import NewCampaign from './Campaign/New';
import CampaignPreview from './Campaign/Preview';
import Campaigns from './Campaigns';
import ChatRooms from './ChatRooms';
import Survey from './Survey';
import ToComplete from './ToComplete';

export default function Routes() {
  const { path } = useRouteMatch();

  return (
    <DesignSystem version={2}>
      <Switch>
        <Route path={`${path}/to-complete`} exact component={ToComplete} />
        <Route path={`${path}/conversations`} exact component={ChatRooms} />
        <Route path={`${path}/campaigns`} exact component={Campaigns} />
        <Route path={`${path}/campaigns/new`} exact component={NewCampaign} />
        <Route
          path={`${path}/campaigns/:campaignId/edit`}
          exact
          component={EditCampaign}
        />
        <Route
          path={`${path}/campaigns/:campaignId/preview`}
          exact
          component={CampaignPreview}
        />
        <Route path={`${path}/campaigns/:campaignId`} component={Campaign} />
        <Route path={`${path}/:campaignId`} exact component={Survey} />

        <Redirect to={`${path}/to-complete`} />
      </Switch>
    </DesignSystem>
  );
}
