import { AdminRole } from 'models/Role';

import fakeAllUserEntity from './fakeAllUserEntity';

const groupRoles = (roles: AdminRole[]) => {
  const groupedRoles = {};

  roles.forEach(role => {
    groupedRoles[role.name] = groupedRoles[role.name] || [];
    groupedRoles[role.name].push(role.resource);

    if (groupedRoles[role.name].some(item => item.type === 'organization')) {
      groupedRoles[role.name] = [fakeAllUserEntity()];
    }
  });

  return groupedRoles;
};

export default groupRoles;
