import React from 'react';

import { TrainingSessionCostStats } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Box, Column, Columns, Loading, Text } from 'components';

type MoneyAttributes =
  | 'totalCost'
  | 'costPerParticipant'
  | 'administeredTotalCost';

type Props = { sessionId: string; shouldRefetchStats: number };
type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & { stats: TrainingSessionCostStats };

type CardProps = {
  title: string;
  mainRender: () => string;
  additionalRender?: () => string | null;
};

const Stats = ({ stats, isFetching, hasError }: AfterDataLoaderProps) => {
  const moneyAttribute = (key: MoneyAttributes) => {
    const cents = stats[`${key}Cents`];

    if (!cents) return '-';

    return formatMoney(cents, stats[`${key}Currency`], {
      hideEmptyDecimal: true,
    });
  };

  const Card = ({ title, mainRender, additionalRender }: CardProps) => {
    const renderContent = () => (
      <div className="flex flex-col">
        <Text preset="16bs5.5">{mainRender()}</Text>
        {additionalRender && (
          <div>
            <Text preset="14s6" transformation="italic" color="light">
              {additionalRender()}
            </Text>
          </div>
        )}
      </div>
    );

    return (
      <Column size={3} additionalClassName="flex">
        <Box additionalClassName="flex flex-col grow">
          <Text preset="16bs5.5">{title}</Text>
          {isFetching || hasError ? <Loading /> : renderContent()}
        </Box>
      </Column>
    );
  };

  return (
    <Columns>
      <Card
        title={__('Total cost')}
        mainRender={() => moneyAttribute('totalCost')}
        additionalRender={() =>
          stats.administeredTotalCostCents
            ? __(
                'including %1 in your scope',
                moneyAttribute('administeredTotalCost')
              )
            : null
        }
      />

      <Card
        title={__('Cost per participant')}
        mainRender={() => moneyAttribute('costPerParticipant')}
      />
      <Card
        title={__('Number of participants')}
        mainRender={() => stats.participantCount.toString()}
        additionalRender={() =>
          stats.administeredParticipantCount
            ? __(
                'including %1 in your scope',
                (stats.administeredParticipantCount as number).toString()
              )
            : null
        }
      />
    </Columns>
  );
};

export default newDataLoader({
  fetch: ({ sessionId }: Props) =>
    get(`training/sessions/${sessionId}/cost_stats`),
  hydrate: { stats: {} },
  cacheKey: ({ sessionId, shouldRefetchStats }: Props) =>
    compositeKey({ sessionId, shouldRefetchStats }),
})(Stats);
