import React from 'react';

import type { OneOnOneUserReview, ReviewCycle } from 'models';

import can from 'helpers/can';
import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToReviewOrEvaluation } from 'helpers/paths';

import { BoxListItem, Column, Columns, PullRight, Text } from 'components';

import { AvatarWithStatus } from 'scenes/components/AvatarWithStatus';
import OneOnOneActionsHamburgerMenu from 'scenes/components/userReview/oneOnOne/actions/OneOnOneActionsHamburgerMenu';

import IconColumn from './IconColumn';

type Props = {
  reviewCycle: ReviewCycle;
  userReview: OneOnOneUserReview;
  additionalReviewerEnabled: boolean;
  refetchData: () => Promise<void>;
};

const StandardUserReviewItemContent = ({
  reviewCycle,
  userReview,
  additionalReviewerEnabled,
  refetchData,
}: Props) => {
  const activeUser = useActiveUser();
  const { user, reviewer, additionalReviewer } = userReview;
  const userReviewPath = pathToReviewOrEvaluation(userReview, activeUser);

  return (
    <BoxListItem
      isSmall
      testClassName="test-user-review-item"
      dataTestValue={userReview.id}
    >
      <Columns>
        <IconColumn reviewCycle={reviewCycle} userReview={userReview} />
        <Column size={10}>
          <Columns>
            <Column isVerticallyCentered ellipsisOnLineOverflow>
              <AvatarWithStatus
                user={user}
                status={userReview.revieweeStatus}
                statusFallback={__('No self evaluation')}
                link={
                  can({ perform: 'show_review', on: userReview })
                    ? userReviewPath
                    : undefined
                }
                testClassName="test-reviewee-with-status"
              />
            </Column>
            <Column isVerticallyCentered ellipsisOnLineOverflow>
              <AvatarWithStatus
                user={reviewer}
                status={userReview.reviewerStatus}
                testClassName="test-reviewer-with-status"
              />
            </Column>
            {additionalReviewerEnabled && (
              <Column isVerticallyCentered ellipsisOnLineOverflow>
                {additionalReviewer ? (
                  <AvatarWithStatus
                    user={additionalReviewer}
                    status={userReview.additionalReviewerStatus}
                    testClassName="test-additional-reviewer-with-status"
                  />
                ) : (
                  <Text transformation="italic" weight="light" color="grey">
                    {__('No additional reviewer')}
                  </Text>
                )}
              </Column>
            )}
          </Columns>
        </Column>

        <Column isVerticallyCentered>
          <PullRight>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <OneOnOneActionsHamburgerMenu
                userReview={userReview}
                reviewCycle={reviewCycle}
                refetchData={refetchData}
              />
            </div>
          </PullRight>
        </Column>
      </Columns>
    </BoxListItem>
  );
};

export default StandardUserReviewItemContent;
