import React from 'react';

import type { ModalProps } from './';

import SkillAreaCreationModal from 'scenes/skills/components/SkillAreaCreationModal';

const AreaCreationFromMatrixModal = ({
  matrix,
  setModalParams,
  modalParams,
}: ModalProps) => (
  <SkillAreaCreationModal
    initialExpectationTitles={matrix.levels.map(level => level.title)}
    onClose={() => setModalParams(null)}
    creationEndpoint={`/skills/matrices/${matrix.id}/add_area`}
    afterCreate={() => setModalParams(null)}
    positionInMatrix={modalParams?.position}
  />
);

export default AreaCreationFromMatrixModal;
