import type { Customization } from './types';

export default function datepickerCustomization({
  primaryColor,
}: Customization) {
  return {
    '.react-datepicker__day--keyboard-selected': {
      backgroundColor: primaryColor,
    },
    '.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range':
      {
        backgroundColor: primaryColor,
      },
    '.react-datepicker__day--selected:hover': {
      backgroundColor: primaryColor,
    },
    '.react-datepicker__day--selected:hover, .react-datepicker__day--keyboard-selected:hover':
      {
        backgroundColor: primaryColor,
      },
  };
}
