import { omit } from 'lodash';
import React from 'react';
import { compose } from 'redux';

import type { PaginationProps } from 'lib/dataLoader/pagination/types';
import type { User, UserCollection } from 'models';

import { __ } from 'helpers/i18n';
import { computePageAndSearchDisplayValues } from 'helpers/pagination';

import { StatePaginationFactory } from 'lib/dataLoader';
import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import {
  Level,
  LevelLeft,
  LevelRight,
  Pagination,
  SearchBar,
} from 'components';

import IndirectReportList from './ReportList';

type Props = {
  activeUser: User;
};

type AfterPaginateProps = Props & PaginationProps;

type AfterConnectProps = AfterPaginateProps & {
  indirectReportsCollection: UserCollection | undefined | null;
  isFetching: boolean;
};

const IndirectReports = ({
  search: optimisticSearch,
  page: optimisticPage,
  indirectReportsCollection,
  onSearchChange,
  getPreviousPage,
  getNextPage,
  isFetching,
}: AfterConnectProps) => {
  const collectionInfo = indirectReportsCollection
    ? omit(indirectReportsCollection, 'users')
    : null;
  const users = indirectReportsCollection
    ? indirectReportsCollection.users
    : [];

  const { search, page } = computePageAndSearchDisplayValues(
    {
      search: optimisticSearch,
      page: optimisticPage,
    },
    collectionInfo,
    isFetching
  );

  const shouldDisplayIndirectReports =
    collectionInfo &&
    (collectionInfo.search || collectionInfo.totalPageCount > 0);

  if (!shouldDisplayIndirectReports) return null;

  const shouldDisplayPagination =
    collectionInfo &&
    (collectionInfo.search || collectionInfo.totalPageCount > 1);

  return (
    <div>
      <Level style={{ marginBottom: 11 }}>
        <LevelLeft>
          <h2 className="subtitle">{__('Indirect team')}</h2>
        </LevelLeft>
        {shouldDisplayPagination && (
          <LevelRight>
            <SearchBar
              value={search}
              placeholder={__('Search')}
              onChange={value => onSearchChange(value || '')}
              style={{ marginTop: 12, marginBottom: 12 }}
            />
          </LevelRight>
        )}
      </Level>

      <IndirectReportList
        indirectReports={users}
        isFetching={isFetching}
        displayReportPlaceholder={!!shouldDisplayPagination}
      />

      {shouldDisplayPagination && (
        <Pagination
          collectionInfo={collectionInfo}
          page={page}
          getPreviousPage={getPreviousPage}
          getNextPage={getNextPage}
          isFetching={isFetching}
          style={{ marginTop: '20px' }}
          isPrimary
        />
      )}
    </div>
  );
};

export default compose<React.ComponentType<Props>>(
  StatePaginationFactory({
    defaultPaginationParams: () => ({
      countPerPage: 9,
    }),
  }),
  newDataLoader({
    fetch: ({ page, countPerPage, search, activeUser }: AfterPaginateProps) =>
      get(`users/${activeUser.id}/indirect_reports`, {
        page: page,
        countPerPage: countPerPage,
        search: search,
      }),
    hydrate: {
      indirectReportsCollection: {
        users: {
          manager: {},
        },
      },
    },
    cacheKey: ({ page, countPerPage, search }: AfterPaginateProps) =>
      page + countPerPage + search,
  })
)(IndirectReports);
