import React from 'react';

import { __ } from 'helpers/i18n';

import FeaturePromptModal from 'scenes/components/FeaturePromptModal';

import threeSixtySvg from './svg/threeSixtySvg';

type Props = {
  isActive: boolean;
  onClose: () => void;
};

const ThreeSixtyFeaturePromptModal = ({ isActive, onClose }: Props) => {
  return (
    <FeaturePromptModal
      title={__('360 Feedback')}
      description={__(
        'With 360 feedback you can create a campaign where each participant will receive feedback from several others persons in your organization.' +
          '360 feedback can be used in preparation of a review to provide more info to the reviewer or outside of a review period to provide development pointer to the participant. ' +
          'It is also possible to conduct manager surveys with a 360 feedback.'
      )}
      svg={threeSixtySvg}
      prefilledSubjectToSupport={__('Learn more about 360 templates')}
      mainButtonText={__('Contact an Elevo expert to access this feature')}
      isActive={isActive}
      onClose={onClose}
    />
  );
};

export default ThreeSixtyFeaturePromptModal;
