import React from 'react';

import type { PeerSolicitation } from 'models';

import { __ } from 'helpers/i18n';

import { BoxListItem, Column, Columns } from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

type Props = {
  peerSolicitation: PeerSolicitation;
};

const PeerSolicitationListItem = ({ peerSolicitation }: Props) => {
  return (
    <BoxListItem>
      <Columns contentVerticallyAligned isMobile>
        <Column>
          <UserAvatar user={peerSolicitation.user} withJobTitle />
        </Column>

        <Column isNarrow>
          {__('Requested %1 times', peerSolicitation.solicitationCount)}
        </Column>
      </Columns>
    </BoxListItem>
  );
};

export default PeerSolicitationListItem;
