import React, { type CSSProperties } from 'react';

import type { Props as TextProps } from 'components/text/Text';

import classNames from 'helpers/classNames';

import { Text } from 'components';

type Props = {
  children: React.ReactNode;
  additionalClassName?: string;
  style?: CSSProperties;
  textProps?: TextProps;
};

export default function AvatarSubTitle({
  children,
  additionalClassName,
  style,
  textProps,
}: Props) {
  return (
    <Text
      additionalClassName={classNames('avatar-sub-title', additionalClassName)}
      style={style}
      overflowEllipsis
      {...textProps}
    >
      {children}
    </Text>
  );
}
