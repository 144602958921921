import React, { ReactNode } from 'react';

type Props = {
  render: (uid: string) => ReactNode;
};

type State = {
  uid: string;
};

let nextId = 0;
function getNextId() {
  return `component-uid-${nextId++}`;
}

export default class ComponentUIDProvider extends React.Component<
  Props,
  State
> {
  componentWillMount() {
    this.setState({ uid: getNextId() });
  }

  render() {
    return this.props.render(this.state.uid);
  }
}
