import React from 'react';

import type { SimpleStatuses } from 'models';

import {
  Box,
  BoxList,
  BoxListItem,
  Flex,
  Number,
  Text,
  Title,
} from 'components';

type Props = {
  statuses: SimpleStatuses;
  title: string;
  overallProgressPercentage: number;
  subtitle: string;
};

export default function SimpleStatusesBlock({
  statuses,
  title,
  overallProgressPercentage,
  subtitle,
}: Props) {
  return (
    <Box additionalClassName="status-container-box">
      <Title size={5}>
        {title}
        <Text color="pelo-light-grey" style={{ marginLeft: '1rem' }}>
          {subtitle}
        </Text>
      </Title>

      <div className="progress-bar-container">
        <div
          style={{
            width: `${overallProgressPercentage}%`,
          }}
          className="progress-bar-gauge"
        />
      </div>

      <BoxList>
        {statuses.map((status, index) => (
          <BoxListItem key={index}>
            <Flex style={{ justifyContent: 'space-between' }}>
              <div>
                <Flex style={{ justifyContent: 'flex-start' }}>
                  <div
                    className="circle"
                    style={{
                      backgroundColor: status.color,
                    }}
                  />
                  <Text color="dark-grey">{status.label}</Text>
                </Flex>
              </div>

              <div>
                <Text color="pelo-light-grey">
                  <Number value={status.value} />
                </Text>{' '}
                <Text color="pelo-light-grey">
                  {' ('}
                  <Number value={status.percentage} isPercentage />
                  {')'}
                </Text>
              </div>
            </Flex>
          </BoxListItem>
        ))}
      </BoxList>
    </Box>
  );
}
