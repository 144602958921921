import React from 'react';

import type { Props as BulmaInputProps } from '../bulmaElements/Input';

import Input from './Input';

type Props = Omit<BulmaInputProps<number>, 'onChange'> & {
  min?: number | null;
  max?: number | null;
  onChange: (value: number) => any;
};

export default function NumberInput(props: Props) {
  const { value, onChange, ...otherProps } = props;

  return (
    <Input
      {...otherProps}
      value={value || ''}
      onChange={value => onChange(Number(value))}
      type="number"
    />
  );
}
