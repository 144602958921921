import React from 'react';

import { __ } from 'helpers/i18n';

import { InformationModal } from 'components';

type Props = {
  isActive: boolean;
  onClose: () => any;
};

const ExportModal = ({ onClose, isActive }: Props) => {
  return (
    <InformationModal
      refreshContentOnOpening={false}
      onOK={onClose}
      isActive={isActive}
    >
      <p>
        <b>{__('Your export is being prepared.')}</b>
      </p>
      <p>{__('You will receive it by e-mail in a few minutes.')}</p>
    </InformationModal>
  );
};

export default ExportModal;
