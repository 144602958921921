import React from 'react';

import type { ReviewCycle } from 'models';

import { __, n__ } from 'helpers/i18n';

import { Date } from 'components';

import { Props as SummaryItemProps } from 'scenes/components/Stepper/SummaryStep/SummaryItem';

export default function getDeadlinesItems(
  reviewCycle: ReviewCycle
): Array<SummaryItemProps> {
  const revieweeDeadlineDisplayed =
    reviewCycle.defaultTemplate?.hasSelfEvaluationEnabled !== false;

  if (reviewCycle.autoAddParticipantsEnabled) {
    return [
      {
        label: __('Reviewee deadline:'),
        hidden: !revieweeDeadlineDisplayed,
        children:
          !!reviewCycle.revieweeDeadlineAfterDays &&
          n__(
            '%1 calendar day after addition of the employee in the cycle',
            '%1 calendar days after addition of the employee in the cycle',
            reviewCycle.revieweeDeadlineAfterDays
          ),
      },
      {
        label: __('Reviewer deadline:'),
        children:
          !!reviewCycle.reviewerDeadlineAfterDays &&
          n__(
            '%1 calendar day after addition of the employee in the cycle',
            '%1 calendar days after addition of the employee in the cycle',
            reviewCycle.reviewerDeadlineAfterDays
          ),
      },
    ];
  }

  if (reviewCycle.interactionType === '360') {
    return [
      {
        label: __('Final deadline:'),
        children: <Date value={reviewCycle.dateReviewRevieweeEnd} />,
      },
    ];
  }

  return [
    {
      label: __('Reviewee deadline:'),
      hidden: !revieweeDeadlineDisplayed,
      children: <Date value={reviewCycle.dateReviewRevieweeEnd} />,
    },
    {
      label: __('Reviewer deadline:'),
      children: <Date value={reviewCycle.dateReviewReviewerEnd} />,
    },
  ];
}
