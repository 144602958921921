import { parse, serialize } from 'cookie';

export function clear() {
  for (let key in get()) {
    document.cookie = serialize(key, '', {
      maxAge: -1,
      path: '/',
    });
  }
}

export function get() {
  return parse(document.cookie);
}
