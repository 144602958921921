import React from 'react';

import type { ReviewCycle, UserReview } from 'models';

import { __ } from 'helpers/i18n';
import { withActiveUser } from 'helpers/withSessionProps';

import { Button, GroupedDropdown, Icon } from 'components';

import { getAvailableActions } from 'scenes/components/userReview/actions';

type Props = {
  review: UserReview;
  reviewCycle: ReviewCycle;
  refreshReview: () => Promise<void>;
};

function MoreOptionsButton({ review, reviewCycle, refreshReview }: Props) {
  let actions = {};

  actions = getAvailableActions({
    userReview: review,
    reviewCycle,
    refreshReviewContent: refreshReview,
  });

  return (
    <GroupedDropdown
      renderTrigger={dropdownIsOpened => (
        <Button color="secondary" isActive={dropdownIsOpened}>
          <Icon style={{ margin: '-1px 4px 0 0' }} name="add_circle" />
          {__('More options')}
        </Button>
      )}
      menuItems={actions}
    />
  );
}

export default withActiveUser(MoreOptionsButton) as React.ComponentType<Props>;
