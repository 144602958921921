import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Route, Switch } from 'components';

import ReviewCycleIndex from './Index';
import ReviewCycleManage from './Manage';
import Edit from './Setup/Edit';
import New from './Setup/New';

const Routes = () => {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${path}/new`} component={New} />
        <Route path={`${path}/:reviewCycleId/edit`} component={Edit} />
        <Route path={`${path}/:id`} component={ReviewCycleManage} />
        <Route>
          <ReviewCycleIndex />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
