import React from 'react';

import {
  ChoiceScore,
  ChoiceSpecificStats,
  SurveyChoiceQuestion,
} from 'models/surveys';

import { __ } from 'helpers/i18n';

import {
  Box,
  BoxVerticalSeparator,
  Flex,
  HorizontalBarChart,
  Text,
} from 'components';

import ComingSoon from '../../components/ComingSoon';
import QuestionScore from '../../components/QuestionScore';

type Props = {
  stats: ChoiceSpecificStats;
  score: ChoiceScore;
  question: SurveyChoiceQuestion;
  testClassName?: string;
};

const ChoiceStats = ({ stats, score, question, testClassName }: Props) => {
  const title =
    question.questionType === 'single_choice'
      ? __('Single choice answer')
      : __('Multiple choice answer');
  return (
    <Box additionalClassName="mt-4" testClassName={testClassName}>
      <Flex>
        <div className="w-2/3">
          <Text preset="18bs5">{__(title)}</Text>
          <Flex additionalClassName="mt-4">
            <QuestionScore score={score} question={question} />
            <div className="ml-16 grow">
              <HorizontalBarChart data={stats} />
            </div>
          </Flex>
        </div>

        <BoxVerticalSeparator />

        <div>
          <Text preset="18bs5">{__('Completion')}</Text>
          <ComingSoon additionalClassName="mt-4" />
        </div>
      </Flex>
    </Box>
  );
};

export default ChoiceStats;
