import React from 'react';

import { __ } from 'helpers/i18n';

import { Flex, Icon } from 'components';

const NoFilterEmptyState = () => {
  return (
    <div className="mt-5">
      <Flex horizontalAlign direction="column">
        <Icon
          additionalClassName="w-12 h-12 mb-4 bg-gray-200 rounded-full flex justify-center items-center"
          name="equalizer"
        />
        <p>
          <b>{__('Discover the development needs of your employees')}</b>
        </p>
        <p>
          {__(
            'Select a skills matrix to discover the priority development needs.'
          )}
        </p>
      </Flex>
    </div>
  );
};

export default NoFilterEmptyState;
