import React from 'react';

import { AdminRole } from 'models';

import { __ } from 'helpers/i18n';

import { Select } from 'components';

import Option from './Option';
import { RoleOptionProps } from './Option';

type Props = {
  value: AdminRole['name'] | null;
  onChange: (role: AdminRole['name'] | null) => void;
  options: Array<RoleOptionProps['data']>;
};

const RoleNameSelect = ({ value, onChange, options }: Props) => {
  return (
    <div className="basis-1/2">
      <Select
        value={options.find(option => option.value === value)}
        placeholder={__('Select a role...')}
        options={options}
        onChange={option =>
          !!option ? onChange(option.value) : onChange(null)
        }
        components={{ Option: Option }}
        inModal
      />
    </div>
  );
};

export default RoleNameSelect;
