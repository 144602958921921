import React from 'react';

import type { OneOnOneUserReview } from 'models';

import { __ } from 'helpers/i18n';
import {
  pathToCurrentUserEvaluation,
  pathToReviewResults,
} from 'helpers/navigation';

import { Button, Flex, Testable } from 'components';

type Props = {
  userReview: OneOnOneUserReview;
};

const Actions = ({ userReview }: Props) => {
  const {
    isReleased,
    revieweeStatus,
    reviewerStatus,
    hasSelfEvaluationEnabled,
    signatureModuleEnabled,
  } = userReview;
  const reviewerHasShared = ['shared', 'signed'].includes(reviewerStatus);
  const reviewPath = pathToReviewResults(userReview);

  let path = reviewPath;
  let buttonLabel = '';
  let testClass = '';
  let isPrimary = true;

  if (hasSelfEvaluationEnabled) {
    const evaluationPath = pathToCurrentUserEvaluation(userReview);

    switch (revieweeStatus) {
      case 'not_started':
        testClass = 'test-start-self-assessment-button';
        buttonLabel = __('Start self-assessment');
        path = evaluationPath;
        break;
      case 'in_progress':
        testClass = 'test-continue-assessment-button';
        buttonLabel = __('Continue self-assessment');
        path = evaluationPath;
        break;
      case 'shared':
        if (signatureModuleEnabled && reviewerHasShared) {
          testClass = 'test-sign-button';
          buttonLabel = __('Sign');
        } else if (isReleased) {
          testClass = 'test-view-results-button';
          buttonLabel = __('View');
          isPrimary = false;
        } else {
          testClass = 'test-view-self-assessment-button';
          buttonLabel = __('View self-assessment');
          path = evaluationPath;
          isPrimary = false;
        }
        break;
      case 'signed':
        testClass = 'test-view-results-button';
        buttonLabel = __('View results');
        isPrimary = false;
        break;
      case 'blocked':
      case 'outdated':
        testClass = 'test-view-button';
        buttonLabel = __('View');
        isPrimary = false;
        break;
      case 'pending_peer_validation':
        throw new Error(
          `UserReview#${userReview.id} with revieweeStatus ${revieweeStatus} (should not happen)`
        );
      default:
        buttonLabel = revieweeStatus as unknown as string;
    }
  }

  if (!hasSelfEvaluationEnabled) {
    if (isReleased) {
      testClass = 'test-view-results-button';
      buttonLabel = __('View results');
      isPrimary = false;
    } else {
      // If there's no self eval and review is not released yet, nothing to do
      return null;
    }
  }

  return (
    <Flex verticalAlign>
      <Button color={isPrimary ? 'primary' : 'secondary'} to={path}>
        <Testable name={testClass}>{buttonLabel || null}</Testable>
      </Button>
    </Flex>
  );
};

export default Actions;
