import { clamp } from 'lodash';
import React from 'react';

import { ReviewCycle } from 'models';

import { __ } from 'helpers/i18n';

import { Column, Columns, NumberInput } from 'components';

import { UpdateReviewCycle } from './index';

type Props = {
  reviewCycle: ReviewCycle;
  updateReviewCycle: UpdateReviewCycle;
};

export default function StaticConfiguration({
  reviewCycle,
  updateReviewCycle,
}: Props) {
  const [currentValue, setCurrentValue] = React.useState(
    reviewCycle.addParticipantsBasedOnWorkStartDateAfterDays
  );
  const onChange = (value: number | null) =>
    updateReviewCycle({
      addParticipantsBasedOnWorkStartDateAfterDays: value,
    });

  return (
    <Columns isGapLess>
      <Column size={1} style={{ marginRight: 8 }}>
        <NumberInput
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          value={currentValue}
          onChange={value => setCurrentValue(value)}
          min={1}
          max={365}
          onBlur={async () => {
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            const clampedValue = clamp(currentValue, 1, 365);

            onChange(clampedValue);

            if (clampedValue !== currentValue) {
              setCurrentValue(clampedValue);
            }
          }}
          style={{ textAlign: 'center', MozAppearance: 'textfield' }}
          testClassName="test-add-participants-input"
        />
      </Column>
      <Column isVerticallyCentered>
        {__('calendar days after addition of the employee in the cycle')}
      </Column>
    </Columns>
  );
}
