import React from 'react';

import type { User } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { post } from 'redux/actions/api';

import { ConfirmationModal } from 'components';

type Props = {
  isActive: boolean;
  onDismiss: () => void;
  user: User | undefined | null;
};

const ReactivateUserModal = ({ isActive, onDismiss, user }: Props) => {
  const dispatch = useAppDispatch();

  const confirm = async () => {
    invariant(user, 'User should be defined');
    await dispatch(post(`users/${user.id}/reactivate`));
    window.location.reload();
  };
  const displayName = user ? user.fullName : '';
  return (
    <ConfirmationModal
      isActive={isActive}
      onCancel={onDismiss}
      onConfirm={confirm}
      title={__('Reactivate %1', displayName)}
      cancelLabel={__('Cancel')}
      confirmLabel={__('Reactivate %1', displayName)}
      refreshContentOnOpening={false}
    >
      <p>{__('Once reactivated, the user will be able to:')}</p>
      <ul className="bullet-list">
        <li>{__('login to Elevo with their existing credentials')}</li>
        <li>{__('access existing reviews and objectives')}</li>
        <li>{__('be added in a campaign')}</li>
      </ul>
      <p>{__('Do you still want to proceed?')}</p>
    </ConfirmationModal>
  );
};

export default ReactivateUserModal;
