import React from 'react';

import type { Answer } from 'models';

import { __ } from 'helpers/i18n';

import { Flex, Text } from 'components';

import AnswerListItem from './AnswerListItem';

type Props = {
  answers: Array<Answer>;
  isFetching: boolean;
};

export default class AnswersList extends React.Component<Props> {
  render() {
    const { answers, isFetching } = this.props;

    if (answers.length === 0) {
      if (isFetching) {
        return null;
      }

      return (
        <Flex direction="column">
          <Text
            size={6}
            style={{
              display: 'flex',
              flex: '1',
              justifyContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            {__('No comment shared yet')}
          </Text>
        </Flex>
      );
    }

    return (
      <Flex direction="column">
        {answers.map(answer => (
          <AnswerListItem answer={answer} key={answer.id} />
        ))}
      </Flex>
    );
  }
}
