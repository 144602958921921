import * as React from 'react';

// These types have no formal use except enhanced legibility
type ComponentType<T> = React.ComponentType<T>;
type WrappedComponentType<T> = ComponentType<T>;
type WrappingComponentType<T> = ComponentType<T>;

// General definition of a HOC
// A function that takes params, that returns a function that will wrap a component into another one
type HOC<THOCArgs, TWrappedComponentProps, TWrappingComponentProps> = (
  hocArgs: THOCArgs
) => (
  arg1: WrappedComponentType<TWrappedComponentProps>
) => WrappingComponentType<TWrappingComponentProps>;

type TransformPropsFunction<TWrappedComponentProps, TWrappingComponentProps> = (
  props: TWrappingComponentProps
) => TWrappedComponentProps;

type TransformPropsHOC<TWrappedComponentProps, TWrappingComponentProps> = HOC<
  TransformPropsFunction<TWrappedComponentProps, TWrappingComponentProps>,
  TWrappedComponentProps,
  TWrappingComponentProps
>;

const transformProps: TransformPropsHOC<any, any> =
  transformProps => WrappedComponentType => props =>
    <WrappedComponentType {...props} {...transformProps(props)} />;

export default transformProps;
