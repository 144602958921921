import React, { Fragment, ReactNode } from 'react';

import { Text, Tooltip } from 'components';

export type Data = {
  value: number | null;
  color: string;
  label: string;
  tooltipContent?: ReactNode;
  name?: string;
};

type LabelItemProps = {
  data: Data;
  withoutPercentage?: boolean;
};

const LabelItem = ({ data, withoutPercentage = false }: LabelItemProps) => {
  const { value, color, label, name } = data;

  return (
    <Fragment>
      {withoutPercentage ? null : (
        <Text preset="24bs2" testClassName={`test-flat-pie-chart-${name}`}>
          {value === null ? '-' : value}%
        </Text>
      )}
      <div>
        <span
          className="h-2 w-2 inline-block rounded-sm mr-2"
          style={{ backgroundColor: color }}
        />
        <Text preset="14s6">{label}</Text>
      </div>
    </Fragment>
  );
};

type Props = {
  data: Data[];
  withoutPercentage?: boolean;
  height?: number;
};

const FlatPieChart = ({
  data,
  height = 24,
  withoutPercentage = false,
}: Props) => {
  return (
    <Fragment>
      <div
        className="flex"
        style={{
          height,
          width: '100%',
          overflow: 'hidden',
          borderRadius: 100,
          backgroundColor: '#eee',
        }}
      >
        {data.map((data, i) => {
          const { value, color, tooltipContent } = data;
          const style = { flex: value || 0, backgroundColor: color };

          if (tooltipContent) {
            return (
              <Tooltip key={i} triggerStyle={style} content={tooltipContent}>
                <div />
              </Tooltip>
            );
          } else {
            return <div key={i} style={style} />;
          }
        })}
      </div>

      <div className="flex mt-4">
        {data.map((data, i) => (
          <div key={i} style={{ flex: 1 }}>
            <LabelItem data={data} withoutPercentage={withoutPercentage} />
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default FlatPieChart;
