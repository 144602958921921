import React from 'react';

import type { ReviewCycle } from 'models';

import can from 'helpers/can';

import { Can, Control, Field } from 'components';

import ExportMenuButton from './ExportMenuButton';
import OneOnOneBulkRemindersMenuButton from './OneOnOneReviewCycle/BulkRemindersMenuButton';
import OtherActionsMenuButton from './OtherActionsMenuButton';
import ThreeSixtyBulkRemindersMenuButton from './ThreeSixtyReviewCycle/BulkRemindersMenuButton';

type Props = {
  reviewCycle: ReviewCycle;
};

export default function Actions({ reviewCycle }: Props) {
  const showOtherActionsMenuButton = reviewCycle.status !== 'ended';

  return (
    <Field isGrouped>
      {showOtherActionsMenuButton && (
        <Control>
          <Can perform="update" on={reviewCycle}>
            <OtherActionsMenuButton reviewCycle={reviewCycle} />
          </Can>
        </Control>
      )}
      <Control key="bulk_reminders">
        <Can perform="send_bulk_reminder" on={reviewCycle}>
          {reviewCycle.interactionType === '360' ? (
            <ThreeSixtyBulkRemindersMenuButton reviewCycle={reviewCycle} />
          ) : (
            <OneOnOneBulkRemindersMenuButton reviewCycle={reviewCycle} />
          )}
        </Can>
      </Control>

      {can({ perform: 'show_export_button', on: reviewCycle }) && (
        <Control key="export">
          <ExportMenuButton reviewCycle={reviewCycle} formats={['xlsx']} />
        </Control>
      )}
    </Field>
  );
}
