import React from 'react';

import { __, n__ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { Text } from 'components';
import { Content as TooltipContent } from 'components/Tooltip';

type DataPoint = {
  label: string;
  percentage: number;
  value: number;
};

type Props = {
  active?: boolean | undefined;
  payload?: Array<{
    payload?: DataPoint;
  }>;
  withCount?: boolean;
};

const Tooltip = ({ active, payload, withCount }: Props) => {
  if (!!active && payload && payload.length) {
    invariant(payload[0].payload, 'Payload is missing');
    const { label, percentage, value } = payload[0].payload;

    if (percentage === null) {
      return null;
    }

    return (
      <TooltipContent>
        <Text>{label === null ? __('No answer') : label}</Text>
        <br />
        <Text weight="bold">
          {!!withCount
            ? n__('%1 person (%2%)', '%1 persons (%2%)', value, percentage)
            : null}
        </Text>
      </TooltipContent>
    );
  }

  return null;
};

export default Tooltip;
