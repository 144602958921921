import { OrganizationSync } from 'models';

import { __ } from 'helpers/i18n';

type DisplayedStatus =
  | 'success'
  | 'failure'
  | 'partial_success'
  | 'prepared'
  | 'in_progress'
  | 'preparing'
  | 'cancelled';

const displayedStatuses = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  PARTIAL_SUCCESS: 'partial_success',
  PREPARED: 'prepared',
  IN_PROGRESS: 'in_progress',
  PREPARING: 'preparing',
  CANCELLED: 'cancelled',
} as const;

export function displayedStatus(sync: OrganizationSync): DisplayedStatus {
  if (sync.status === 'prepared') return displayedStatuses.PREPARED;
  if (sync.status === 'in_progress') return displayedStatuses.IN_PROGRESS;
  if (sync.status === 'failed') return displayedStatuses.FAILURE;
  if (sync.status === 'preparing') return displayedStatuses.PREPARING;
  if (sync.status === 'cancelled') return displayedStatuses.CANCELLED;

  return displayedStatuses.SUCCESS;
}

export function statusColor(displayedStatus: DisplayedStatus) {
  const displayedColors = {
    [displayedStatuses.SUCCESS]: 'success',
    [displayedStatuses.FAILURE]: 'danger',
    [displayedStatuses.PARTIAL_SUCCESS]: 'warning',
    [displayedStatuses.PREPARED]: 'success',
    [displayedStatuses.IN_PROGRESS]: 'warning',
    [displayedStatuses.PREPARING]: 'warning',
    [displayedStatuses.CANCELLED]: 'warning',
  } as const;

  return displayedColors[displayedStatus];
}

export function statusLabel(displayedStatus: DisplayedStatus) {
  const displayedTexts = {
    [displayedStatuses.SUCCESS]: __('Success'),
    [displayedStatuses.FAILURE]: __('Failure'),
    [displayedStatuses.PARTIAL_SUCCESS]: __('Partial success'),
    [displayedStatuses.PREPARED]: __('Prepared'),
    [displayedStatuses.IN_PROGRESS]: __('In progress'),
    [displayedStatuses.PREPARING]: __('Preparing'),
    [displayedStatuses.CANCELLED]: __('Canceled'),
  } as const;

  return displayedTexts[displayedStatus];
}
