import React, { useContext } from 'react';

import { __ } from 'helpers/i18n';

import UserPicker from 'scenes/components/UserPicker';

import { DataContext } from '../..';
import { EditableFieldProps } from '../EditableField';
import SyncedField from '../SyncedField';

const ManagerField = ({
  value,
  onChange,
  fieldSynced,
  syncedFieldOptions,
}: EditableFieldProps) => {
  const { user } = useContext(DataContext);

  const textValue = !!value ? value.fullName : __('No manager set');
  const managerPicker = (
    <UserPicker
      value={value}
      onChange={onChange}
      disabledUserIds={[user.id]}
      placeholder={__('No manager set')}
      isClearable
    />
  );

  return fieldSynced ? (
    <SyncedField
      lockedFieldValue={textValue}
      unlockedField={managerPicker}
      {...syncedFieldOptions}
    />
  ) : (
    managerPicker
  );
};

export default ManagerField;
