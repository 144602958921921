import type { Customization } from './types';

export default function checkboxesCustomization({
  primaryColor,
}: Customization) {
  return {
    '.is-checkradio[type="checkbox"] + label::after, .is-checkradio[type="checkbox"] + label:after':
      {
        borderColor: primaryColor,
      },
    '.is-checkradio[type="radio"]:hover:not([disabled]) + label::before, .is-checkradio[type="radio"]:hover:not([disabled]) + label:before, .is-checkradio[type="checkbox"]:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"]:hover:not([disabled]) + label:before':
      {
        borderColor: `${primaryColor} !important`,
      },
    '.is-checkradio[type="checkbox"]:focus:not([disabled]) + label::before, .is-checkradio[type="checkbox"]:focus:not([disabled]) + label:before':
      {
        borderColor: `${primaryColor} !important`,
      },
  };
}
