import React from 'react';

import { TrainingPeriod, User } from 'models';

import { __ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Button, Centered, Icon, Loading, Text } from 'components/index';

import TrainingRequestModal from 'scenes/components/TrainingRequestModal';

type Props = DataLoaderProvidedProps & {
  reviewee: User;
  currentTrainingPeriod: TrainingPeriod;
};

function PromptTrainingRequestCreationTableFooter({
  reviewee,
  hasError,
  isFetching,
  currentTrainingPeriod,
}: Props) {
  const [modalIsActive, setModalIsActive] = React.useState(false);

  if (isFetching) {
    return <Loading />;
  }
  if (hasError) {
    return (
      <div>{__('An error occurred while loading the training requests.')}</div>
    );
  }

  return (
    <div className="mt-4 mb-2">
      <Centered>
        <Text preset="13s7" align="centered">
          {__(
            'Create the training requests for the needs you identify directly from this review.'
          )}
        </Text>
        <Text preset="13s7" align="centered">
          {__(
            "Trigger the discussion with the relevant people according to your organization's training policy."
          )}
        </Text>
        <div className="mt-4">
          <Button color="secondary" onClick={() => setModalIsActive(true)}>
            <Icon name="add" />
            {__('Create a training request')}
          </Button>
        </div>
      </Centered>

      {modalIsActive && (
        <TrainingRequestModal
          currentTrainingPeriodSlug={currentTrainingPeriod.slug}
          concernedUser={reviewee}
          isActive
          createdFromReview
          onClose={() => setModalIsActive(false)}
          onConfirmationClose={() => {
            setModalIsActive(false);
          }}
        />
      )}
    </div>
  );
}
export default newDataLoader({
  fetch: () => get('training/periods/current'),
  hydrate: {
    currentTrainingPeriod: {},
  },
})(PromptTrainingRequestCreationTableFooter);
