import React from 'react';

import type { ThreeSixtyReviewCycle, ThreeSixtyUserReview } from 'models';

import { __ } from 'helpers/i18n';
import {
  getPeersEvaluationsNotShared,
  getRevieweeEvaluation,
} from 'helpers/models/evaluation';

import { Column, Columns } from 'components';

import { AvatarWithStatus } from 'scenes/components/AvatarWithStatus';

import Actions from './Actions';
import PeerStatuses from './PeerStatuses';

type Props = {
  userReview: ThreeSixtyUserReview;
  reviewCycle: ThreeSixtyReviewCycle;
  onAfterDelegate: () => Promise<void>;
};

const ThreeSixtyUserReviewItem = ({
  userReview,
  reviewCycle,
  onAfterDelegate,
}: Props) => {
  const revieweeEvaluation = getRevieweeEvaluation(userReview);
  const peersEvaluationsNotShared = getPeersEvaluationsNotShared(userReview);

  return (
    <Columns contentVerticallyAligned>
      <Column size={4}>
        <AvatarWithStatus
          user={userReview.user}
          status={revieweeEvaluation && revieweeEvaluation.status}
          statusFallback={__('No self evaluation')}
        />
      </Column>

      <Column size={4}>
        <PeerStatuses maxInlinePeers={2} evaluations={userReview.evaluations} />
      </Column>

      <Column contentIsPulledRight size={4}>
        <Actions
          userReview={userReview}
          reviewCycle={reviewCycle}
          revieweeEvaluation={revieweeEvaluation}
          peersEvaluationsNotShared={peersEvaluationsNotShared}
          onAfterDelegate={onAfterDelegate}
        />
      </Column>
    </Columns>
  );
};

export default ThreeSixtyUserReviewItem;
