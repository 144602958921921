import invariant from 'helpers/invariant';

import { getCurrentLocale } from './i18n';

const ELEVO_TO_ZENDESK_LOCALE_MAPPING = { fr: 'fr', en: 'en-us' } as const;
type SupportedZendeskLocale = keyof typeof ELEVO_TO_ZENDESK_LOCALE_MAPPING;

export const zendeskURL = (
  articleIds: Record<SupportedZendeskLocale, string>,
  hash?: string
) => {
  const currentLocale = getCurrentLocale();
  const zendeskLocale =
    ELEVO_TO_ZENDESK_LOCALE_MAPPING[currentLocale] || 'en-us';

  const articleId = articleIds[currentLocale] || articleIds['en'];
  invariant(articleId, "The article's id must be defined");

  if (!hash) hash = '';
  else if (hash[0] !== '#') hash = `#${hash}`;

  return `https://docs.elevo.fr/hc/${zendeskLocale}/articles/${articleId}${hash}`;
};
