import React from 'react';

import type { PersonalObjective, UserObjectivePeriod } from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { put } from 'redux/actions/api';

import { Can, DragAndDropContainer, DragAndDropItem } from 'components';

import EmptyState from 'scenes/components/objectives/EmptyState';
import ObjectiveCardPlaceholder from 'scenes/components/objectives/ObjectiveCard/Placeholder';

import ObjectiveCreatorWithSvg from '../ObjectiveCreatorWithSvg';
import PersonalObjectiveCard from '../PersonalObjectiveCard';

export const PersonalObjectivesListPlaceholder = () => {
  return (
    <div>
      <ObjectiveCardPlaceholder style={{ marginBottom: 16 }} />
      <ObjectiveCardPlaceholder />
    </div>
  );
};

type Props = {
  objectives: PersonalObjective[];
  userObjectivePeriod: UserObjectivePeriod;
  onCreate: (userObjectivePeriod: UserObjectivePeriod) => Promise<void>;
  revieweeFullName?: string;
};

const PersonalObjectivesList = ({
  objectives,
  userObjectivePeriod,
  onCreate,
  revieweeFullName,
}: Props) => {
  const dispatch = useAppDispatch();

  const { objectiveCollection } = userObjectivePeriod;
  const canUpdateObjective = can({
    perform: 'update_objective',
    on: objectiveCollection,
  });

  const { collectionPath } = objectiveCollection;
  const onChangePosition = (objectiveId: string, position: number) =>
    dispatch(put(`${collectionPath}/${objectiveId}`, { position }));

  if (objectives.length === 0) {
    return (
      <Can
        perform="create_objective"
        on={userObjectivePeriod}
        alternativeChildren={
          <EmptyState title={__('There is no objectives in this period')} />
        }
      >
        <ObjectiveCreatorWithSvg
          onCreate={() => onCreate(userObjectivePeriod)}
          style={{ padding: 24, backgroundColor: '#FBFCFE' }}
          description={__(
            'Add a new objective and improve the way you track your advancement'
          )}
        />
      </Can>
    );
  }

  return (
    <DragAndDropContainer onChangePosition={onChangePosition}>
      {objectives.map(objective => (
        <DragAndDropItem
          key={objective.id}
          itemId={objective.id}
          position={objective.position}
          style={{ marginBottom: 16 }}
          disabled={!canUpdateObjective}
          handlePosition="top"
          alwaysDisplayHandle
        >
          <PersonalObjectiveCard
            key={objective.id}
            objective={objective}
            objectiveCollection={objectiveCollection}
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            revieweeFullName={revieweeFullName}
          />
        </DragAndDropItem>
      ))}
    </DragAndDropContainer>
  );
};

export default PersonalObjectivesList;
