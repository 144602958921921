// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import type { ReduxStore } from '../../redux/reducers/index';
import type { RequestStatus } from './reducer';

import { getRequestStatus } from './reducer';

export type RenderProps<TPropsAfterConnect> = TPropsAfterConnect &
  RequestStatus;

export type DataConnector<T> = (
  // $FlowFixMe: Migrate to TS
  state: ReduxStore,
  requestStatus: RequestStatus
) => T;
export type DataRenderer<T> = (props: RenderProps<T>) => React.Node;

type RequestConnectorProps<TProvidedProps> = {
  connect: DataConnector<TProvidedProps>,
  render: DataRenderer<TProvidedProps>,
  requestId: string,
  fetch: () => Promise<any>,
};

type RequestConnectorPropsAfterConnect<TProvidedProps> = {
  ...RequestConnectorProps<TProvidedProps>,
  connectedProps: TProvidedProps,
  requestStatus: RequestStatus,
};

type State<T> = {|
  previousConnectedProps: ?T,
|};

class _RequestConnector<T> extends React.Component<
  RequestConnectorPropsAfterConnect<T>,
  State<T>
> {
  state: State<T> = {
    previousConnectedProps: null,
  };

  componentWillReceiveProps(nextProps: RequestConnectorPropsAfterConnect<T>) {
    // We save the previous value for "overlay" effects
    if (
      nextProps.requestStatus.isFetching &&
      !this.props.requestStatus.isFetching
    ) {
      this.setState({ previousConnectedProps: this.props.connectedProps });
    }
  }

  render() {
    const { previousConnectedProps } = this.state;
    const { requestStatus, connectedProps, render, fetch } = this.props;

    const { requestId, isFetching, hasError, noContent } = requestStatus;

    const renderedConnectedProps = isFetching
      ? previousConnectedProps
      : connectedProps;

    return render({
      ...renderedConnectedProps,
      requestId,
      isFetching,
      hasError,
      noContent,
      refetchData: fetch,
    });
  }
}

function mapStateToProps<T>(
  // $FlowFixMe: Migrate to TS
  state: ReduxStore,
  { connect, requestId }: RequestConnectorProps<T>
): $Diff<RequestConnectorPropsAfterConnect<T>, RequestConnectorProps<T>> {
  const requestStatus = getRequestStatus(state, requestId);

  return {
    connectedProps: connect(state, requestStatus),
    requestStatus,
  };
}

export const RequestConnector = (connect(mapStateToProps)(
  _RequestConnector
): React.ComponentType<RequestConnectorProps<*>>);
