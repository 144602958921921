import type { PeerFeedback } from 'models';

type PeerFeedbacksGroupedByDates = {
  sortedDividerDates: Array<Date>;
  [key: number]: Array<PeerFeedback>;
};

function peerFeedbackDateSelector(
  sortedPeerFeedbacks: Array<PeerFeedback>
): Array<Date> {
  return sortedPeerFeedbacks
    .filter((peerFeedback, index, peerFeedbacks) => {
      if (index === 0) {
        return true;
      } else {
        const mostRecentFeedbackDate = new Date(
          peerFeedbacks[0].submittedAt || peerFeedbacks[0].dueDate
        );
        const previousFeedbackDate = new Date(
          peerFeedbacks[index - 1].submittedAt ||
            peerFeedback[index - 1].dueDate
        );
        const currentRecentFeedbackDate = new Date(
          peerFeedback.submittedAt || peerFeedback.dueDate
        );
        if (
          (currentRecentFeedbackDate.getFullYear() ===
            mostRecentFeedbackDate.getFullYear() &&
            previousFeedbackDate.getMonth() !==
              currentRecentFeedbackDate.getMonth()) ||
          previousFeedbackDate.getFullYear() !==
            currentRecentFeedbackDate.getFullYear()
        ) {
          return true;
        }
      }
      return false;
    })
    .map(
      peerFeedbackDivider =>
        new Date(peerFeedbackDivider.submittedAt || peerFeedbackDivider.dueDate)
    );
}

function groupPeerFeedbacksBy(
  sortedPeerFeedbacks: Array<PeerFeedback>,
  dividerDates: Array<Date>
): PeerFeedbacksGroupedByDates {
  let peerFeedbacksGroupedByDates = {
    sortedDividerDates: dividerDates,
  };

  dividerDates.forEach((_dividerDate, index, _dividerDates) => {
    peerFeedbacksGroupedByDates[index] = [];
  });

  let dividerIndex = 0;
  sortedPeerFeedbacks.forEach(peerFeedback => {
    const peerFeedbackDate = new Date(
      peerFeedback.submittedAt || peerFeedback.dueDate
    );
    if (
      dividerIndex !== dividerDates.length - 1 &&
      peerFeedbackDate <= dividerDates[dividerIndex + 1]
    )
      dividerIndex += 1;
    peerFeedbacksGroupedByDates[dividerIndex].push(peerFeedback);
  });

  return peerFeedbacksGroupedByDates;
}

export const groupPeerFeedbacksByDates = (
  sortedPeerFeedbacks: Array<PeerFeedback>
): PeerFeedbacksGroupedByDates => {
  if (sortedPeerFeedbacks.length === 0) return { sortedDividerDates: [] };

  return groupPeerFeedbacksBy(
    sortedPeerFeedbacks,
    peerFeedbackDateSelector(sortedPeerFeedbacks)
  );
};
