import React from 'react';

import type {
  NextObjectivesModuleBlock,
  ReviewBlockUpdateParams,
} from 'models/ReviewStructure';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import {
  Box,
  BoxSeparator,
  Flex,
  Text,
  Title6,
  WithSavingStatusRecorder,
} from 'components';

import BlockActions from '../components/BlockActions';
import BlockContent from '../components/BlockContent';
import ObjectivesDefinitionRightsPicker from './ObjectivesDefinitionRightsPicker';

type Props = {
  block: NextObjectivesModuleBlock;
  onChange: (params: ReviewBlockUpdateParams) => Promise<void>;
  onDelete?: () => Promise<void>;
  onMoveUp: () => Promise<void>;
  onMoveDown: () => Promise<void>;
};

export default function NextObjectivesModuleBlockComponent({
  block,
  onChange,
  ...actionProps
}: Props) {
  const { content, objectivesUpdatableByReviewee, richTextEnabled } = block;

  return (
    <Box testClassName="test-next-objectives-module-block">
      <Flex style={{ justifyContent: 'space-between' }} verticalAlign>
        <Title6 transformation="uppercase">
          {__('Define next objectives section')}
        </Title6>
        <BlockActions
          canMoveUp={block.canMoveUp}
          canMoveDown={block.canMoveDown}
          canDuplicate={block.canDuplicate}
          {...actionProps}
        />
      </Flex>

      <BoxSeparator style={{ margin: '16px 0' }} />

      <WithSavingStatusRecorder
        fieldUid={compositeKey({
          blockId: block.id,
          field: 'next_objectives_module_block_content',
        })}
        onChange={onChange}
        render={autoSavingOnchange => (
          <BlockContent
            onChange={autoSavingOnchange}
            content={content}
            withRichText={richTextEnabled}
          />
        )}
      />

      <BoxSeparator style={{ margin: '16px 0' }} />

      <Flex verticalAlign>
        <Text weight="semibold">{__('Allowed to define objectives:')}</Text>
        <div style={{ width: 250, marginLeft: 30 }}>
          <WithSavingStatusRecorder
            fieldUid={compositeKey({
              blockId: block.id,
              field: 'next_objectives_module_block_rights',
            })}
            onChange={onChange}
            render={autoSavingOnchange => (
              <ObjectivesDefinitionRightsPicker
                objectivesUpdatableByReviewee={objectivesUpdatableByReviewee}
                onChange={objectivesUpdatableByReviewee => {
                  return autoSavingOnchange({ objectivesUpdatableByReviewee });
                }}
              />
            )}
          />
        </div>
      </Flex>
    </Box>
  );
}
