import React from 'react';

type Props = {
  render: (isToggled: boolean, toggle: () => void) => React.ReactNode;
  initiallyToggled?: boolean;
  onToggle?: (isToggled: boolean) => void;
};

type State = {
  isToggled: boolean;
};

export default class Toggleable extends React.Component<Props, State> {
  state = {
    isToggled: !!this.props.initiallyToggled,
  };

  toggle = () => {
    this.setState(
      (prevState: State) => ({
        isToggled: !prevState.isToggled,
      }),
      () => this.props.onToggle && this.props.onToggle(this.state.isToggled)
    );
  };

  render() {
    return this.props.render(this.state.isToggled, this.toggle);
  }
}
