import React, { ReactNode } from 'react';

import type { Stage } from 'config/environment';
import type { Session } from 'models';

import { getStage, isProduction, isTest } from 'config/environment';

import { targetApi } from 'lib/api/constants';

type StageFlagProps = {
  stage: Stage;
};

const StageFlag = ({ stage }: StageFlagProps) => {
  const modifier =
    stage === 'development' || stage === 'test' ? 'is-warning' : 'is-danger';
  return (
    <div className={`tag ${modifier} flag`}>
      {stage}: {targetApi.hostname}
    </div>
  );
};

const ImpersonateFlag = () => <div className="tag flag">Impersonating</div>;

type TagsContainerProps = {
  session: Session | null;
};

export const FlagsContainer = ({ session }: TagsContainerProps) => {
  const flags: ReactNode[] = [];

  if (!isProduction() && !isTest()) {
    flags.push(<StageFlag key="stage" stage={getStage()} />);
  }
  if (session?.isImpersonated) {
    flags.push(<ImpersonateFlag key="impersonate" />);
  }

  if (flags.length > 0) {
    return <div className="flags-container">{flags}</div>;
  } else {
    return null;
  }
};
