import React, { CSSProperties } from 'react';
import Skeleton from 'react-loading-skeleton';

import Avatar, { AvatarSubTitle, AvatarTitle } from './Avatar';

type Props = {
  width?: string | number;
  height?: string | number;
  style?: CSSProperties;
};

export function Placeholder({ width, height, style }: Props) {
  return <Skeleton style={{ ...style, width, height }} />;
}

export function ButtonPlaceholder({ width, height, style }: Props) {
  return <Placeholder width={width} height={height || 31.5} style={style} />;
}

export function AvatarPlaceholder() {
  return (
    <Avatar url={null}>
      <AvatarTitle>
        <Placeholder width={100} />
      </AvatarTitle>
      <AvatarSubTitle>
        <Placeholder width={80} />
      </AvatarSubTitle>
    </Avatar>
  );
}
