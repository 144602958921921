import { useEffect, useState } from 'react';

export default function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const matchForQuery = window.matchMedia(query);
    if (!matchForQuery) return;

    setMatches(matchForQuery.matches);

    if (matchForQuery.addEventListener) {
      matchForQuery.addEventListener('change', handleMatchChange);
      return () =>
        matchForQuery.removeEventListener('change', handleMatchChange);
    } else {
      matchForQuery.addListener(handleMatchChange);
      return () => matchForQuery.removeListener(handleMatchChange);
    }
  }, [query]);

  function handleMatchChange(data: MediaQueryListEvent) {
    setMatches(data.matches);
  }

  return matches;
}
