import React from 'react';

import type { ObjectiveCompletionStats as ObjectiveCompletionStatsType } from 'models';

import { __ } from 'helpers/i18n';

import SimpleStatsBlock from '../components/SimpleStatsBlock';

type Props = {
  stats: ObjectiveCompletionStatsType;
};

export default function ObjectiveCompletionStats({ stats }: Props) {
  const formattedStats = [
    {
      label: __('Minimum'),
      value: stats.minimum,
      percentage: true,
    },
    {
      label: __('Average'),
      value: stats.mean,
      percentage: true,
    },
    {
      label: __('Maximum'),
      value: stats.maximum,
      percentage: true,
    },
  ];

  return (
    <SimpleStatsBlock
      title={__('Average completion of reviewed objectives')}
      description={__('Statistics for shared evaluations only.')}
      stats={formattedStats}
    />
  );
}
