import Color from 'color';

export function opacify(colorString: string, alpha: number): string {
  return Color(colorString).alpha(alpha).toString();
}

export function shadeColor(colorString: string, amount: number): string {
  return Color(colorString).darken(amount).rgb().toString();
}

// From Bulma
export function colorLuminance(colorString: string): number {
  const color = Color(colorString);
  const rgb = { red: color.red(), green: color.green(), blue: color.blue() };
  Object.keys(rgb).forEach(name => {
    let value = rgb[name] as number;
    value /= 255;
    if (value < 0.03928) {
      value /= 12.92;
    } else {
      value = Math.pow((value + 0.055) / 1.055, 2);
    }
    rgb[name] = value;
  });
  return rgb.red * 0.2126 + rgb.green * 0.7152 + rgb.blue * 0.0722;
}

// From Bulma
export function findColorInvert(colorString: string): string {
  if (colorLuminance(colorString) > 0.55) {
    return 'rgba(0, 0, 0, 0.7)';
  } else {
    return '#ffffff';
  }
}
