import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import classNames from 'helpers/classNames';

import { Icon } from 'components';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  style?: React.CSSProperties;
  additionalClassName?: string;
  itemId: string;
  position: number;
  handlePosition?: 'left' | 'top';
  alwaysDisplayHandle?: boolean;
};

export default function Item({
  children,
  disabled,
  style,
  additionalClassName,
  itemId,
  position,
  handlePosition = 'left',
  alwaysDisplayHandle,
}: Props) {
  if (disabled)
    return (
      <div
        className={classNames('drag-and-drop-item', additionalClassName)}
        style={style}
      >
        {children}
      </div>
    );

  return (
    <Draggable draggableId={itemId} index={position}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={classNames(
            'drag-and-drop-item',
            { 'is-dragging': snapshot.isDragging },
            additionalClassName
          )}
          style={{
            ...style,
            ...provided.draggableProps.style,
          }}
        >
          <div {...provided.dragHandleProps}>
            <Icon
              additionalClassName={classNames(
                'drag-and-drop-icon',
                { 'always-visible': alwaysDisplayHandle },
                handlePosition
              )}
              size="small"
              color="info"
              name={handlePosition === 'left' ? 'unfold_more' : 'drag_handle'}
            />
          </div>

          {children}
        </div>
      )}
    </Draggable>
  );
}
