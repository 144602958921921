import React from 'react';

import { __ } from 'helpers/i18n';

import {
  Box,
  Column,
  Columns,
  DeprecatedContainerElevo,
  DeprecatedTag,
  Icon,
  SupportLink,
  Text,
  Title,
  Title2,
} from 'components';

import AdvantageCard, { type Props as Advantage } from './AdvantageCard';
import trainingPeriodTable from './trainingPeriodTable.png';
import trainingPeriodView from './trainingPeriodView.png';
import trainingRequestApproved from './trainingRequestApproved.png';
import trainingRequestModal from './trainingRequestModal.png';
import trainingRequestsFilters from './trainingRequestsFilters.png';

const Landing = () => {
  const advantages = [
    {
      title: __('Collect relevant training requests from your managers'),
      description: __(
        'Collect more qualitative and targeted requests, thanks to the training wishes expressed by your managers.'
      ),
      imgSrc: trainingRequestModal,
    },
    {
      title: __("Centralize all your employees' needs on Elevo"),
      description: __(
        'On a single platform, collect the development needs of your employees, then find the history of training.'
      ),
      imgSrc: trainingPeriodTable,
    },
    {
      title: __('Give more visibility to your employees'),
      description: __(
        'Do away with manual reminders and multi-channel. Your employees will be informed in real time of the progress of their training request.'
      ),
      imgSrc: trainingRequestApproved,
    },
    {
      title: __('Facilitate the arbitration of training requests'),
      description: __(
        'Easily filter your requests by team, department or country to make better decisions.'
      ),
      imgSrc: trainingRequestsFilters,
    },
  ] as Advantage[];

  return (
    <DeprecatedContainerElevo style={{ marginTop: '20px' }}>
      <div className="training-period-landing">
        <Box
          additionalClassName="training-period-landing-header"
          style={{ position: 'relative' }}
        >
          <DeprecatedTag color="primary">{__('Upgrade')}</DeprecatedTag>

          <Columns>
            <Column size={5}>
              <Text
                color="primary"
                weight="semibold"
                additionalClassName="is-block"
                style={{ fontSize: 16, marginBottom: 24 }}
              >
                {__('"Training" product')}
              </Text>
              <Title size={1} style={{ fontSize: 24 }}>
                {__(
                  'Support your employees more effectively throughout their professional development'
                )}
              </Title>
              <Text
                additionalClassName="is-block"
                style={{ margin: '24px 0 24px' }}
              >
                {__(
                  'Simply collect the training requests of your employees by involving your managers and thus promote the development of skills in your organization.'
                )}
              </Text>
              <SupportLink
                prefilledSubjectToSupport={__(
                  'Learn more about our Training product'
                )}
                className="button is-primary"
              >
                <Icon style={{ marginRight: 8 }} name="mail" />
                {__('Contact an Elevo expert to enable this product')}
              </SupportLink>
            </Column>
            <Column size={7} additionalClassName="preview-container">
              <figure className="image">
                <img
                  src={trainingPeriodView}
                  alt="training period list example"
                />
              </figure>
            </Column>
          </Columns>
        </Box>

        <div>
          <Title2
            additionalClassName="has-text-centered has-text-weight-semibold"
            style={{ marginBottom: 56 }}
          >
            {__('The advantages of the "Training" product')}
          </Title2>

          <Columns
            isMultiline
            additionalClassName="training-period-landing-advantages-wrapper is-centered"
          >
            {advantages.map(advantage => (
              <Column size={5} key={advantage.title}>
                <AdvantageCard {...advantage} />
              </Column>
            ))}
          </Columns>
        </div>
      </div>
    </DeprecatedContainerElevo>
  );
};

export default Landing;
