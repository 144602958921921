import React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

const NotEnoughParticipantsEmptyState = () => {
  return (
    <EmptyStateWithIcon
      iconName="equalizer"
      title={__(
        'This period of the campaign does not have enough participants'
      )}
      description={__(
        'To preserve the anonymity of respondents, results are not displayed if there are fewer than 3 participants.'
      )}
      testClassName="test-survey-insufficient-participants-empty-state"
    />
  );
};

export default NotEnoughParticipantsEmptyState;
