import React from 'react';

import type { PeopleUserReview } from 'models';

import { __, n__ } from 'helpers/i18n';
import { pathToPeopleReviewCycleReviews } from 'helpers/paths';

import {
  BackButton,
  FullWidthSubmenuBar,
  SavingStatusLabel,
  Title,
} from 'components';

import Actions from './Actions';

type Props = {
  review: PeopleUserReview;
  refetchReview: () => void;
};

const Topbar = ({ review, refetchReview }: Props) => {
  const { peopleReviewCycleId, user } = review;

  return (
    <FullWidthSubmenuBar
      backButton={
        <BackButton
          fallbackTarget={pathToPeopleReviewCycleReviews(peopleReviewCycleId)}
        >
          {__('Back')}
        </BackButton>
      }
      statusInfo={
        <SavingStatusLabel
          failedText={(count: number) =>
            n__(
              'One comment could not be saved',
              '%1 comments could not be saved',
              count
            )
          }
        />
      }
      actions={<Actions review={review} refetchReview={refetchReview} />}
    >
      <Title size={5}>{__('People review of %1', user.fullName)}</Title>
    </FullWidthSubmenuBar>
  );
};

export default Topbar;
