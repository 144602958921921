import React from 'react';

import type { UserFilterChangeMethod } from 'lib/dataLoader/pagination/types';

import { SurveyCampaign, UserFilterSegment } from 'models';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

import QuestionPicker from './QuestionPicker';

type Props = {
  campaign: SurveyCampaign;
  selectedPeriodId: string;
  userFilter: UserFilterSegment | null;
  onUserFilterChange: UserFilterChangeMethod;
  questionId?: string | null;
  correlationSlug?: string | null;
};

const DetailedResults = ({
  campaign,
  selectedPeriodId,
  userFilter,
  onUserFilterChange,
  questionId,
  correlationSlug,
}: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const surveyFilteringDisabled = featureFlags.includes(
    'surveyFilteringDisabled'
  );
  const segment = surveyFilteringDisabled ? null : userFilter;

  if (!questionId && !selectedPeriodId && !correlationSlug) {
    const title =
      campaign.status === 'launching'
        ? __('This survey is being launched')
        : __('This survey has not been launched yet');
    const description =
      campaign.status === 'launching'
        ? __('This may take a couple of minutes. Come back soon 🙂.')
        : __(
            "Once launched, you'll find the detailed results of your survey here."
          );

    return (
      <EmptyStateWithIcon
        iconName="equalizer"
        title={title}
        description={description}
        testClassName={`test-survey-overview-${
          campaign.status === 'launching' ? 'launching' : 'empty-state'
        }`}
      />
    );
  } else {
    return (
      <QuestionPicker
        campaign={campaign}
        selectedPeriodId={selectedPeriodId}
        userFilter={segment}
        onUserFilterChange={onUserFilterChange}
        questionId={questionId}
        correlationSlug={correlationSlug}
      />
    );
  }
};

export default DetailedResults;
