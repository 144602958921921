import * as React from 'react';
import * as ReactDOM from 'react-dom';

import invariant from '../invariant';

export default function createBodyPortal(node: React.ReactNode) {
  invariant(document.body, '`document.body` should be defined');
  const { body } = document;
  return ReactDOM.createPortal(node, body);
}
