import React from 'react';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

type Props = {
  additionalClassName?: string;
};

const ComingSoon = ({ additionalClassName }: Props) => {
  return (
    <div
      className={classNames('flex items-center shrink-0', additionalClassName)}
    >
      <div className="bg-ui-highlight-gray rounded-lg px-4 py-1 font-bold">
        📈 {__('Coming soon')}
      </div>
    </div>
  );
};

export default ComingSoon;
