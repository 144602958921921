import React, { CSSProperties } from 'react';

import { __ } from 'helpers/i18n';

import {
  Button,
  Can,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Icon,
} from 'components';

import InviteUserModal from './InviteUserModal';

type Props = {
  style?: CSSProperties;
  withUsernameOption: boolean;
};

export default function CreateUserButton({ style, withUsernameOption }: Props) {
  const [modalIsShown, setModalIsShown] = React.useState(false);
  const [withUsername, setWithUsername] = React.useState(false);

  const showModal = (option: 'username' | 'email' = 'email') => {
    setWithUsername(option === 'username');
    setModalIsShown(true);
  };

  return (
    <Can perform="show_create_user_button" on="organization">
      {withUsernameOption ? (
        <Dropdown align="right">
          <DropdownTrigger
            testClassName="test-user-creation-menu"
            render={() => (
              <Button color="primary" style={style}>
                <Icon name="add" />
                <span>{__('Add user')}</span>
                <Icon name="keyboard_arrow_down" />
              </Button>
            )}
          />
          <DropdownMenu style={style}>
            <DropdownMenuItem
              testClassName="test-user-creation-with-email"
              onClick={() => showModal('email')}
            >
              {__('With email')}
            </DropdownMenuItem>
            <DropdownMenuItem
              testClassName="test-user-creation-without-email"
              onClick={() => showModal('username')}
            >
              {__('Without email')}
            </DropdownMenuItem>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <Button
          color="primary"
          onClick={() => showModal('email')}
          style={style}
        >
          <Icon name="add" />
          <span>{__('Add user')}</span>
        </Button>
      )}
      {modalIsShown && (
        <InviteUserModal
          withUsername={withUsername}
          onClose={() => setModalIsShown(false)}
        />
      )}
    </Can>
  );
}
