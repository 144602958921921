import React from 'react';

import { Categorization } from 'models';

import { __, n__ } from 'helpers/i18n';

import { Box, Icon, Text } from 'components';

import AILogo from 'scenes/components/AILogo';

type Props = {
  categorizations: Array<Categorization> | undefined;
};
const ReviewCategorization = ({ categorizations }: Props) => {
  if (!categorizations) {
    return null;
  }

  const formattedCategory = category => {
    switch (category) {
      case 'unease':
        return __('Unease');
      case 'violence':
        return __('Violence');
      case 'harassment':
        return __('Harassment');
      case 'discrimination':
        return __('Discrimination');
      case 'health':
        return __('Health');
      case 'demotivation':
        return __('Demotivation');
    }
  };
  return (
    <Box borderColor="orange" additionalClassName="form-content">
      <div className="flex justify-between">
        <div className="flex items-center text-orange mb-2">
          <Icon name="warning" additionalClassName="mr-2"></Icon>
          <Text preset="16bs5.5">
            {__('Potential risks were found in this review')}
          </Text>
        </div>
        <AILogo />
      </div>
      <div className="flex flex-col gap-2">
        {categorizations.map(categorization => (
          <div className="flex" key={categorization.id}>
            <span className="mr-2">•</span>
            <div>
              <Text preset="14bs6" weight="bold">
                {formattedCategory(categorization.category)}
              </Text>{' '}
              {n__(
                'in the following passage:',
                'in the following passages:',
                categorization.highlights.length
              )}
              <div>
                {categorization.highlights.map(highlight => (
                  <div>"{highlight}"</div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default ReviewCategorization;
