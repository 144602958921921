import React from 'react';

import type { User } from 'models';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

type Props = {
  user: User;
};

const EmptyState = ({ user }: Props) => {
  const activeUser = useActiveUser();
  const isActiveUser = user.id === activeUser.id;

  const title = isActiveUser
    ? __('You are not attached to a skills matrix')
    : __('The employee is not associated with a skills matrix.');
  const description = isActiveUser ? null : (
    <p>
      {__(
        'It appears that %1 is not associated with a skills matrix yet. You can link the employee to a skills matrix by specifying their current level.',
        user.fullName
      )}
    </p>
  );

  return (
    <EmptyStateWithIcon
      iconName="hotel_class"
      title={title}
      description={description}
      inBox={false}
    />
  );
};

export default EmptyState;
