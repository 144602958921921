import React, { useEffect, useState } from 'react';

import { useAppDispatch } from 'helpers/hooks';

import { get } from 'redux/actions/api';

import { Select } from 'components';

import { EditableFieldProps } from '../EditableField';
import SyncedField from '../SyncedField';

const ProfileSelectField = ({
  slug,
  value,
  fieldSynced,
  onChange,
  syncedFieldOptions,
}: EditableFieldProps) => {
  const [values, setValues] = useState<Array<string> | null>(null);
  const [clicked, setClicked] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchValues = async () => {
      const { response } = await dispatch(
        get(`users/aggregated_values`, { field: slug })
      );
      setValues(response.body.data.attributes.values);
    };
    if (clicked && !values) fetchValues();
  }, [dispatch, slug, clicked, values]);

  return fieldSynced ? (
    <SyncedField lockedFieldValue={value} {...syncedFieldOptions} />
  ) : (
    <Select
      onFocus={() => setClicked(true)}
      onChange={value => onChange(value?.value || '')}
      isSearchable
      isClearable
      isCreatable
      options={(values || [])
        .sort((a, b) => a.localeCompare(b))
        .map(value => ({ value, label: value }))}
      value={value ? { label: value, value } : null}
    />
  );
};

export default ProfileSelectField;
