import React from 'react';

import {
  BoxWithSvgBackground,
  Button,
  Column,
  Columns,
  Testable,
  Text,
} from 'components';

type Action = {
  onTrigger: () => void;
  text: string;
  testableName?: string;
};

type Props = {
  svg: string;
  action?: Action;
  description: string;
  style?: React.CSSProperties;
  isDisabled?: boolean;
};

function HorizontalEmptyStateWithSvg({
  svg,
  action,
  description,
  style,
  isDisabled,
}: Props) {
  return (
    <BoxWithSvgBackground svg={svg} style={style}>
      <Columns>
        <Column size="half">
          <p style={{ marginBottom: 10 }}>
            <Text color="grey">{description}</Text>
          </p>
          {!!action && (
            <Testable name={action.testableName || 'test-emptystate-action'}>
              <Button
                color="primary"
                onClick={action.onTrigger}
                disabled={isDisabled}
              >
                {action.text}
              </Button>
            </Testable>
          )}
        </Column>
      </Columns>
    </BoxWithSvgBackground>
  );
}

export default HorizontalEmptyStateWithSvg;
