import invariant from 'helpers/invariant';

type Ability<TAbilityIdType> = {
  id: TAbilityIdType;
};

type AbilityBearer<TAbilityIdType> = {
  abilities?: Array<Ability<TAbilityIdType>>;
};

export function getAbilityIds<T>(model: AbilityBearer<T>): Array<T> {
  invariant(model.abilities, 'abilities must be defined on model');

  return model.abilities.map(ability => ability.id);
}
