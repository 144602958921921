import React, { type CSSProperties } from 'react';
import posed, { PoseGroup } from 'react-pose';

const Item = posed.div({
  enter: {
    opacity: 1,
    height: 'auto',
    transition: { duration: 300 },
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: { duration: 300 },
    applyAtStart: { overflow: 'hidden' },
    applyAtEnd: { overflow: 'inherit' },
  },
});

type Props = {
  items: Array<React.ReactNode>;
  keys: Array<string>;
  itemStyle: CSSProperties;
};

export default function CollapseoutList({ items, keys, itemStyle }: Props) {
  return (
    <PoseGroup flipMove={false}>
      {items.map((item, i) => (
        <Item key={keys[i]} style={itemStyle}>
          {item}
        </Item>
      ))}
    </PoseGroup>
  );
}
