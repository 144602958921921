import React from 'react';

import classNames from 'helpers/classNames';

type Props = {
  direction?: 'column' | 'row';
  verticalAlign?: boolean;
  horizontalAlign?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
  additionalClassName?: string;
  testClassName?: string;
};

export default function Flex({
  direction,
  verticalAlign,
  horizontalAlign,
  style = {},
  children,
  additionalClassName,
  testClassName,
}: Props) {
  direction = direction || 'row';

  const isRow = direction === 'row';

  const flexStyle = {
    flexDirection: direction,
  };

  if (verticalAlign) {
    flexStyle[isRow ? 'alignItems' : 'justifyItems'] = 'center';
  }

  if (horizontalAlign) {
    flexStyle[isRow ? 'justifyItems' : 'alignItems'] = 'center';
  }

  return (
    <div
      className={classNames(additionalClassName, testClassName)}
      style={{
        display: 'flex',
        ...flexStyle,
        ...style,
      }}
    >
      {children}
    </div>
  );
}
