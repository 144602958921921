import React, { type CSSProperties } from 'react';
import posed from 'react-pose';

type Props = {
  expanded: boolean;
  children: React.ReactNode;
  duration: number;
  initialWidth: number;
  expandedWidth: number;
  style?: CSSProperties;
};

const Expandable = posed.div({
  expanded: {
    width: ({ expandedWidth }) => expandedWidth,
    transition: ({ duration }) => ({
      duration,
    }),
  },
  default: {
    width: ({ initialWidth }) => initialWidth,
    transition: ({ duration }) => ({
      duration,
    }),
  },
});

function WithWidthExtension({
  expanded,
  children,
  duration,
  initialWidth,
  expandedWidth,
  style,
}: Props) {
  return (
    <Expandable
      pose={expanded ? 'expanded' : 'default'}
      initialWidth={initialWidth}
      expandedWidth={expandedWidth}
      duration={duration}
      style={style}
    >
      {React.Children.only(children)}
    </Expandable>
  );
}

WithWidthExtension.defaultProps = {
  duration: 200,
};

export default WithWidthExtension;
