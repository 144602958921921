import * as Sentry from '@sentry/react';
import { isEmpty } from 'lodash';

import type { AppDispatch } from '../types';
import type { APIActionError } from 'lib/api/types';
import type { Session } from 'models';

import { getStage } from 'config/environment';

import { __ } from 'helpers/i18n';
import { navigate, pathToSignin } from 'helpers/navigation';
import { identifyUser, resetUserIdentification } from 'helpers/tracking';

import { clearCredentials, getCredentials } from 'lib/api/credentials';

import { hasShape, numberType } from 'types/predicates/WithShape';

import { del, get, post, put } from '../api';
import { errorNotice, successNotice } from '../application';

export const signInFromLocalStorage = () =>
  async function (dispatch: AppDispatch): Promise<unknown> {
    if (!isEmpty(getCredentials())) {
      try {
        return await dispatch(get('users/validate_token'));
      } catch (e) {
        const apiError = e as APIActionError<unknown>;
        if (apiError?.response?.status) {
          if (apiError.response.status === 401) return;
        }

        throw e;
      }
    }
  };

export type LoginPasswordPair = {
  login: string;
  password: string;
};

export type LoginAccessCodePair = {
  login: string;
  accessCode: string;
};

export const signIn =
  (credentials: LoginPasswordPair) => async (dispatch: AppDispatch) => {
    let result = await dispatch(
      post(
        'users/sign_in',
        { user: credentials },
        { withDefaultErrorHandling: false }
      )
    );

    const token = result.response.body.data.attributes.value;
    window.location.replace(`/auth/callback?token=${token}`);
  };

export const stopImpersonation = () => () => {
  clearUserIdentityAndRedirectToHome();
};

export const signOut = () => async (dispatch: AppDispatch) => {
  try {
    await dispatch(del('users/sign_out'));
  } catch (e) {
    if (hasShape(e, { response: { status: numberType } })) {
      if (e.response.status === 401) {
        clearUserIdentityAndRedirectToHome();
      }
    }

    throw e;
  }
  clearUserIdentityAndRedirectToHome();
};

export function clearUserIdentityAndRedirectToHome() {
  clearUserIdentityForExternalServices();
  clearCredentials();
  sessionStorage.clear();
  // reload altogether since people sometimes log-out instead of refreshing the app
  // expecting the usual "have you tried logging out and in again?" fix
  window.location.href = '/signin?force_redirect=false';
}

export type EditPasswordParams = {
  password: string;
  passwordConfirmation: string;
  resetPasswordToken: string;
  email: string;
};

export const editPassword =
  ({
    password,
    passwordConfirmation,
    resetPasswordToken,
    email,
  }: EditPasswordParams) =>
  async (dispatch: AppDispatch) => {
    try {
      await dispatch(
        put(
          'users/password',
          {
            user: {
              password,
              passwordConfirmation,
              resetPasswordToken,
            },
          },
          {
            withDefaultErrorHandling: false,
          }
        )
      );
      onSuccessEditPassword(dispatch, email);
    } catch (e) {
      onFailEditPassword(e, dispatch);
    }
  };

const onFailEditPassword = (error, dispatch) => {
  const errors = error.response && error.response.body.errors;
  if (errors && errors.resetPasswordToken) {
    dispatch(errorNotice(__('Reset link invalid. Redirecting to Sign In...')));
    setTimeout(() => {
      navigate(pathToSignin());
    }, 2000);
  } else {
    throw error;
  }
};

const onSuccessEditPassword = (dispatch, email) => {
  dispatch(
    successNotice(
      __('Your new password is now active. Redirecting to Sign In page...')
    )
  );
  setTimeout(() => {
    navigate(pathToSignin(email));
  }, 2000);
};

export const setUserIdentityForExternalServices = (session: Session) => {
  const { user, organization, isImpersonated } = session;

  if (Sentry) {
    Sentry.configureScope(function (scope) {
      scope.setTag('organization', organization.shortName);
      scope.setTag('impersonating', isImpersonated);
      scope.setUser({
        id: user.id,
        email: user.email,
      });
    });
  }

  identifyUser(user, {
    environment: getStage(),
    organizationShortName: organization.shortName,
    organizationName: organization.name,
    organizationIsDemo: organization.isDemo,
    organizationCreatedAt: organization.createdAt,
    planName: organization.plan.name,
    planLegacy: organization.plan.legacy,
    planSupportLevel: organization.plan.supportLevel,
    planIs360Enabled: organization.plan.is360Enabled,
    planDynamicTemplateEnabled: organization.plan.dynamicTemplateEnabled,
    planMultiLevelAdminEnabled: organization.plan.multiLevelAdminEnabled,
    planAutoAddParticipantsEnabled:
      organization.plan.autoAddParticipantsEnabled,
    planTrainingModuleEnabled: organization.plan.trainingModuleEnabled,
    planPeopleReviewEnabled: organization.plan.peopleReviewEnabled,
    planEnpsEnabled: organization.plan.enpsEnabled,
    isImpersonated: session.isImpersonated,
    organizationStripeId: organization.stripeId,
  });
};

const clearUserIdentityForExternalServices = () => {
  if (Sentry) {
    Sentry.configureScope(function (scope) {
      // @ts-ignore Probably creates a tag with key `null` and value `undefined`
      scope.setTag(null);
      scope.setUser(null);
    });
  }
  resetUserIdentification();
};

export type InvitationParams = {
  invitationToken: string;
  firstName: string;
  lastName: string;
  password: string;
};

export const acceptInvitation =
  (invitationParams: InvitationParams) => async (dispatch: AppDispatch) => {
    return dispatch(
      put(
        'users/invitation',
        {
          user: invitationParams,
        },
        { withDefaultErrorHandling: false }
      )
    );
  };
