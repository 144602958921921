import React, { CSSProperties } from 'react';

import classNames from 'helpers/classNames';

import { Flex, Link, Title5 } from 'components';

export const boxPaddingValue = 1.25;
const boxPadding = `${boxPaddingValue}rem`;

type BoxSize = 'small' | 'default' | 'large';

type Props = {
  children: React.ReactNode;
  size: BoxSize;
  style?: CSSProperties;
  onClick?: () => void;
  additionalClassName?: string;
  testClassName?: string;
  isInset?: boolean;
  borderColor?: string;
};

function Box({
  children,
  size,
  onClick,
  style,
  additionalClassName,
  testClassName,
  isInset,
  borderColor,
}: Props) {
  const backgroundClass = 'with-background-white';
  const borderColorStyle = borderColor
    ? { '--borderBackgroundColor': borderColor }
    : null;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={classNames(
        'box',
        backgroundClass,
        additionalClassName,
        testClassName,
        {
          'box--with-border-color': !!borderColor,
          [`is-${size}`]: size !== 'default',
          'is-inset': isInset,
        }
      )}
      style={{
        ...style,
        ...borderColorStyle,
        cursor: onClick && 'pointer',
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

Box.defaultProps = {
  backgroundColor: 'white',
  size: 'default',
};

type BoxTitleProps = {
  children: React.ReactNode;
};

const BoxTitle = ({ children }: BoxTitleProps) => <Title5>{children}</Title5>;

type BoxSeparatorProps = {
  style?: CSSProperties;
  additionalClassName?: string;
};

const BoxSeparator = ({
  style = {},
  additionalClassName,
}: BoxSeparatorProps) => {
  const finalStyle = {
    margin: `${boxPadding} -${boxPadding}`,
    backgroundColor: 'rgba(219,230,232, 0.7)',
    height: 1,
    ...style,
  };
  return (
    <hr
      className={classNames(additionalClassName, 'box-separator')}
      style={finalStyle}
    />
  );
};

type BoxVerticalSeparatorProps = {
  additionalClassName?: string;
};

const BoxVerticalSeparator = ({
  additionalClassName,
}: BoxVerticalSeparatorProps) => {
  return (
    <div
      className={classNames(
        'border-solid',
        'border-0',
        'border-l-[1px]',
        'border-l-ui-separator-on-background',
        'mx-16',
        additionalClassName
      )}
    />
  );
};

type BoxListProps = {
  children: React.ReactNode;
  additionalClassName?: string;
  style?: CSSProperties;
};

const BoxList = ({ children, additionalClassName, style }: BoxListProps) => (
  <div style={style} className={classNames('box-list', additionalClassName)}>
    {children}
  </div>
);

type BoxListItemProps = {
  children: React.ReactNode;
  isSmall?: boolean;
  style?: CSSProperties;
  additionalClassName?: string;
  testClassName?: string;
  dataTestValue?: string;
};

function BoxListItem({
  additionalClassName,
  testClassName,
  isSmall,
  children,
  style,
  dataTestValue,
}: BoxListItemProps) {
  return (
    <div
      data-test-value={dataTestValue}
      className={classNames(
        'box-list-item',
        { 'is-small': isSmall },
        additionalClassName,
        testClassName
      )}
      style={style}
    >
      {children}
    </div>
  );
}

type BoxFooterProps = {
  children: React.ReactNode;
  onClick?: () => void;
  to?: string;
  pagination?: string;
};

function BoxFooter({ children, onClick, to, pagination }: BoxFooterProps) {
  return (
    <Flex additionalClassName="box-footer" verticalAlign>
      {pagination && <nav className="box-footer-element">{pagination}</nav>}
      <Link additionalClassName="box-footer-element" onClick={onClick} to={to}>
        {children}
      </Link>
      {pagination && <div className="box-footer-element" />}
    </Flex>
  );
}

export {
  Box,
  BoxTitle,
  BoxList,
  BoxListItem,
  BoxSeparator,
  BoxVerticalSeparator,
  BoxFooter,
};
