import React, { ReactNode } from 'react';

type Props = {
  active: boolean;
  children?: ReactNode;
};

export default function DisabledLayer({ active, children }: Props) {
  if (!active) return null;

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        opacity: 0.5,
        zIndex: 1,
      }}
    >
      {children}
    </div>
  );
}
