import { wait } from 'helpers/time';

export const poll = async (
  callable: () => Promise<any>,
  options?: {
    interval: number;
    timeout: number;
  }
) => {
  const defaultOptions = { interval: 500, timeout: 15000 } as const;
  options = { ...defaultOptions, ...(options || {}) };

  const now = new Date().getTime();
  const { timeout, interval } = options;
  const endTime = now + timeout;

  let ready = false;
  while (!ready && now < endTime) {
    ready = await callable();
    await wait(interval);
  }

  if (!ready && !(new Date().getTime() < endTime)) {
    throw new Error('Timeout during polling');
  }
};

export const handleFormErrors = async (
  callable: () => Promise<any>,
  callback: (errors: FormErrors) => any,
  throwException?: boolean
) => {
  try {
    await callable();
  } catch (exception: any) {
    const errors = exception.response?.body?.errors;
    callback(errors || {});
    if (throwException) throw exception;
  }
};

export type FormErrors = {
  [key: string]: string;
};
