import React from 'react';

import { __ } from 'helpers/i18n';

import { BoxListItem, Column, Columns, Text } from 'components';

const AuditReportsTableHeader = () => (
  <BoxListItem>
    <Columns isMobile>
      <Column size={5}>
        <Text preset="14s6" color="light">
          {__('Report name')}
        </Text>
      </Column>
      <Column size={4}>
        <Text preset="14s6" color="light">
          {__('Tags')}
        </Text>
      </Column>
      <Column size={2}>
        <Text preset="14s6" color="light">
          {__('Frequency')}
        </Text>
      </Column>
    </Columns>
  </BoxListItem>
);

export default AuditReportsTableHeader;
