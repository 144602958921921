import type { TrainingSession } from 'models/TrainingSession';

import { __, n__ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

const messageWhenSameFundingPerParticipant = ({
  fundingItems,
  costComputationType,
  totalBudgetCents,
  totalBudgetCurrency,
  costPerParticipantCents,
  costPerParticipantCurrency,
}: TrainingSession) => {
  if (
    !fundingItems ||
    fundingItems.length === 0 ||
    fundingItems.every(item => !item.amountCents)
  )
    return null;

  const totalFundingCents = fundingItems.reduce(
    (total, item) => total + (item.amountCents || 0),
    0
  );
  const expectedCents =
    costComputationType === 'per_session'
      ? totalBudgetCents
      : costPerParticipantCents;
  const expectedCurrency =
    costComputationType === 'per_session'
      ? totalBudgetCurrency
      : costPerParticipantCurrency;

  if (!expectedCents) return null;
  if (expectedCents === totalFundingCents) return null;

  const formattedTotalFunding = formatMoney(
    totalFundingCents,
    fundingItems[0].amountCurrency
  );
  const difference = expectedCents - totalFundingCents;

  let baseMessage =
    costComputationType === 'per_session'
      ? __(
          'Please note that the sum of the configured funding <b>does not equal the total session cost</b>, which is <b>%1</b>.',
          formatMoney(expectedCents, expectedCurrency)
        )
      : __(
          'Please note that the sum of the configured funding <b>does not equal the participant cost</b>, which is <b>%1</b>.',
          formatMoney(expectedCents, expectedCurrency)
        );

  return (
    baseMessage +
    '<br/>' +
    (difference > 0
      ? __(
          'The sum of the funding amounts to <b>%1</b> (<b>%2</b> missing).',
          formattedTotalFunding,
          formatMoney(difference, totalBudgetCurrency)
        )
      : __(
          'The sum of the funding amounts to <b>%1</b>.',
          formattedTotalFunding
        ))
  );
};

const messageWhenDifferentFundingPerParticipant = ({
  costComputationType,
  totalBudgetCents,
  totalBudgetCurrency,
  participantFundingItemsTotalCostCents,
  participantFundingItemsTotalCostCurrency,
  incompleteParticipantFundingCount,
}: TrainingSession) => {
  if (costComputationType === 'per_participant') {
    if (!incompleteParticipantFundingCount) return null;

    return n__(
      'Please note that the funding of <b>%1</b> participant does not equal the participant cost',
      'Please note that the funding of <b>%1</b> participants does not equal the participant cost',
      incompleteParticipantFundingCount
    );
  }

  if (
    !!participantFundingItemsTotalCostCents &&
    !!participantFundingItemsTotalCostCurrency &&
    !!totalBudgetCents &&
    totalBudgetCents !== participantFundingItemsTotalCostCents
  ) {
    const difference = totalBudgetCents - participantFundingItemsTotalCostCents;
    const formattedTotalFunding = formatMoney(
      participantFundingItemsTotalCostCents,
      participantFundingItemsTotalCostCurrency
    );

    return (
      __(
        'Please note that the sum of the configured funding <b>does not equal the total session cost</b>, which is <b>%1</b>.',
        formatMoney(totalBudgetCents, totalBudgetCurrency)
      ) +
      '<br/>' +
      (difference > 0
        ? __(
            'The sum of the funding amounts to <b>%1</b> (<b>%2</b> missing).',
            formattedTotalFunding,
            formatMoney(difference, totalBudgetCurrency)
          )
        : __(
            'The sum of the funding amounts to <b>%1</b>.',
            formattedTotalFunding
          ))
    );
  }
};

const incompleteFundingsWarningMessage = (session: TrainingSession) =>
  session.sameFundingPerParticipant
    ? messageWhenSameFundingPerParticipant(session)
    : messageWhenDifferentFundingPerParticipant(session);

export default incompleteFundingsWarningMessage;
