import { sortBy } from 'lodash';
import React, { useContext } from 'react';

import { CustomFieldValue, CustomFieldValues } from 'models/User';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import {
  Column,
  Columns,
  Control,
  Field,
  Input,
  Label,
  Title,
} from 'components';

import { DataContext } from '..';
import SyncedField from './SyncedField';

const EditableCustomFieldValues = () => {
  const { attributes, onAttributeChange, isFieldSynced } =
    useContext(DataContext);
  const { customFieldValues } = attributes;

  const updateCustomField = (
    customField: CustomFieldValue,
    value: string | null
  ) => {
    let item = (customFieldValues as CustomFieldValues).find(
      item => item.slug === customField.slug
    );

    invariant(item, 'Custom field not found');

    item.value = value as string;

    onAttributeChange('customFieldValues', customFieldValues);
  };

  const sortedActiveCustomFieldValues = React.useMemo(
    () => sortBy(customFieldValues, field => field.label.toLowerCase()),
    [customFieldValues]
  );

  return (
    <React.Fragment>
      <hr />
      <Title size={6} transformation="uppercase" additionalClassName="mb-4">
        {__('Custom Fields')}
      </Title>
      <Columns additionalClassName="field-section flex-wrap">
        {sortedActiveCustomFieldValues.map(customField => (
          <Column size={6} key={customField.slug} additionalClassName="pb-0">
            <Field>
              <Label color="info">{__('%1:', customField.label)}</Label>

              <Control>
                {isFieldSynced(customField.slug) ? (
                  <SyncedField
                    lockedFieldValue={customField.value}
                    fieldLabel={customField.label}
                    fieldSlug={customField.slug}
                  />
                ) : (
                  <Input
                    testClassName={`test-profile-field-${customField.slug}`}
                    value={customField.value || ''}
                    onChange={value => updateCustomField(customField, value)}
                  />
                )}
              </Control>
            </Field>
          </Column>
        ))}
      </Columns>
    </React.Fragment>
  );
};

export default EditableCustomFieldValues;
