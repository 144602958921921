import React from 'react';

import { __ } from 'helpers/i18n';

import {
  Box,
  DesignSystem,
  EmptyState as EmptyStateComponent,
  SupportLink,
} from 'components';

import cycleLaunchFailedSvg from 'scenes/admin/components/svg/cycle-lunch-failed.svg';

type Props = {
  reviewCycleId?: string;
};

export default function FailedEmptyState({ reviewCycleId }: Props) {
  const supportLink = (
    <SupportLink
      prefilledSubjectToSupport={__(
        'Problem with my campaign launch %1',
        reviewCycleId
      )}
    >
      {__('our Support team')}
    </SupportLink>
  );
  return (
    <DesignSystem version={2}>
      <Box style={{ marginTop: 40 }}>
        <EmptyStateComponent
          title={__('Oops, the campaign launch has failed')}
          description={__(
            'A problem has occurred during the campaign launch. Please contact %1 for more information.',
            supportLink
          )}
          src={cycleLaunchFailedSvg}
        />
      </Box>
    </DesignSystem>
  );
}
