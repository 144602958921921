import { omit } from 'lodash';

import generateLockedModalTexts from './locked';
import generateUnlockedModalTexts from './unlocked';

type Props = {
  fieldLabel: string;
  userFullName: string;
  integrationName: string;
  lastSyncedFormattedValue: string | undefined;
};

const generateText = (locked: boolean, props: Props) => {
  return locked
    ? generateLockedModalTexts(omit(props, 'lastSyncedFormattedValue'))
    : generateUnlockedModalTexts(props);
};

export default generateText;
