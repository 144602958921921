import React from 'react';

import classNames from 'helpers/classNames';
import invariant from 'helpers/invariant';

import { Icon, Link, Text } from 'components';

import { StepInfo } from '.';

type Props<TStepName extends string> = {
  currentStepIndex: number;
  onChange?: (stepIndex: number) => void;
  stepInfoList: Array<StepInfo<TStepName>>;
};

const SetupSideStepList = <TStepName extends string>({
  currentStepIndex,
  onChange,
  stepInfoList,
}: Props<TStepName>) => {
  const firstIncompleteStepIndex = stepInfoList.findIndex(
    step => !step.isCompleted
  );

  invariant(firstIncompleteStepIndex > -1, 'Steps are all well defined');

  return (
    <ol className="side-step-list" type="1">
      {stepInfoList.map((step, index) => {
        const key = step.name;

        const disabled = index > firstIncompleteStepIndex;
        const active = currentStepIndex === index;
        const checked = currentStepIndex > index;

        return (
          <li
            key={key}
            className={classNames({
              disabled,
              active,
              checked,
            })}
          >
            <Link
              onClick={() => !disabled && !!onChange && onChange(index)}
              className={null}
            >
              <Text preset="16s5.5">{step['title']}</Text>

              <span className="step-icon">
                {active ? (
                  <Icon name="keyboard_arrow_right" />
                ) : (
                  step.isCompleted && <Icon name="done" />
                )}
              </span>
            </Link>
          </li>
        );
      })}
    </ol>
  );
};

export default SetupSideStepList;
