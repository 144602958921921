import React from 'react';

import type { TrainingSession } from 'models';

import { __, n__ } from 'helpers/i18n';
import { assertDefined } from 'helpers/invariant';
import confirmAsync from 'helpers/react/confirmAsync';

import { Icon } from 'components';

import SessionSummary from 'scenes/admin/Trainings/components/SessionSummary';

const cancelInvitationsModal = (
  session: TrainingSession,
  cancelCalendarInvitations: () => Promise<void>
) => {
  const participants = assertDefined(
    session.participants,
    'Participants should be defined here'
  );

  return confirmAsync(
    __('Cancel calendar invitation'),
    <React.Fragment>
      {n__(
        'Are you sure you want to cancel this calendar invitation for the participant of the session ?',
        'Are you sure you want to cancel this calendar invitation for %1 participants of the session ?',
        participants.length
      )}
      <br />
      <b>
        {n__(
          'The participant will be notified by email that the session has been cancelled.',
          'Participants will be notified by email that the session has been cancelled.',
          participants.length
        )}
      </b>
      <br />
      <br />
      <SessionSummary session={session} />
    </React.Fragment>,
    {
      isDanger: true,
      inlineButtonIcon: <Icon style={{ marginRight: 5 }} name="send" />,
      confirmLabel: __('Cancel invitation'),
      onConfirm: cancelCalendarInvitations,
    }
  );
};

export default cancelInvitationsModal;
