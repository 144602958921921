import React from 'react';

import { handleFormErrors } from 'helpers/api';
import classNames from 'helpers/classNames';
import * as commonPhrases from 'helpers/commonPhrases';
import { useAppDispatch } from 'helpers/hooks';

import { errorNotice } from 'redux/actions';

import { Flex, Icon, Placeholder, Text, Title, Tooltip } from 'components';

export type Props = {
  completion: number | null | undefined;
  completionMismatchWarning: string | null | undefined;
  disabled: boolean;
  onChange: (value: number | null | undefined) => Promise<unknown>;
  testClassName?: string;
};

export default function ObjectiveCompletion({
  completion,
  completionMismatchWarning,
  disabled,
  onChange,
  testClassName,
}: Props) {
  const dispatch = useAppDispatch();
  const handleOnChange = value =>
    handleFormErrors(
      () => onChange(value),
      errors => {
        const errorMessage = errors.completion
          ? errors.completion[0]
          : commonPhrases.somethingWentWrong();

        return dispatch(errorNotice(errorMessage));
      }
    );

  return (
    <Flex
      verticalAlign
      additionalClassName={classNames('objective-completion', {
        'has-mismatch-icon': !!completionMismatchWarning,
      })}
      testClassName={testClassName}
    >
      {!!completionMismatchWarning && (
        <div style={{ marginRight: 5 }}>
          <Tooltip content={completionMismatchWarning}>
            <Text color="warning" style={{ marginRight: '4px' }}>
              <Icon style={{ verticalAlign: 'text-bottom' }} name="info" />
            </Text>
          </Tooltip>
        </div>
      )}

      <Title
        size={4}
        isEditable={!disabled}
        weight="bold"
        color="primary"
        editableOptions={{
          type: 'percentage',
          value: completion,
          onChange: handleOnChange,
          withEditIcon: false,
        }}
      />
    </Flex>
  );
}

export function CompletionPlaceholder() {
  return (
    <Flex
      verticalAlign
      additionalClassName={classNames('objective-completion')}
    >
      <Title size={2}>
        <Placeholder width={50} />
      </Title>
    </Flex>
  );
}
