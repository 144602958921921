import moment from 'moment';
import React from 'react';

import type { HistorySectionItem } from '../components/HistorySectionItem';
import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { User, UserReview } from 'models';
import type { ComponentType, ReactNode } from 'react';

import can from 'helpers/can';
import invariant from 'helpers/invariant';
import { pathToReviewResults, pathToUserReviews } from 'helpers/paths';

import { newDataLoader } from 'lib/dataLoader';
import { fetchUserReviewsForUser } from 'redux/actions/resources';

import HistorySection, {
  HISTORY_ITEM_LIMIT,
} from '../components/HistorySection';

const mapReviewToHistoryItem = (review: UserReview): HistorySectionItem => {
  invariant(review.reviewCycle, 'reviewCycle must be defined');

  return {
    id: review.id,
    title: review.reviewCycle.name,
    date: moment(review.createdAt).format('MMMM YYYY'),
    linkTo: can({ perform: 'view_results', on: review })
      ? pathToReviewResults(review)
      : undefined,
  };
};

type Props = {
  user: User;
  icon: ReactNode;
  title: string;
  emptyState: string;
  seeMoreLabel: string;
  type: 'one_on_one' | '360';
};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    userReviews: Array<UserReview>;
  };

function ReviewHistory({
  user,
  icon,
  title,
  emptyState,
  seeMoreLabel,
  userReviews,
  isFetching,
  hasError,
}: AfterConnectProps) {
  const historySectionItems = userReviews?.map(review =>
    mapReviewToHistoryItem(review)
  );

  return (
    <HistorySection
      icon={icon}
      title={title}
      historySectionItems={historySectionItems}
      emptyState={emptyState}
      seeMorePath={pathToUserReviews(user.id)}
      seeMoreLabel={seeMoreLabel}
      isFetching={isFetching}
      hasError={hasError}
    />
  );
}

export default newDataLoader({
  fetch: ({ user, type }) =>
    fetchUserReviewsForUser(user, { type, limit: HISTORY_ITEM_LIMIT + 1 }),
  hydrate: {
    userReviews: {
      abilities: {},
      reviewCycle: {},
    },
  },
})(ReviewHistory) as ComponentType<Props>;
