import React from 'react';
import posed from 'react-pose';
import { connect } from 'react-redux';

import type { LoginPasswordPair } from 'redux/actions/resources';
import type { AppDispatch } from 'redux/actions/types';

import { largeMargin } from 'styles/uiConstants';

import { __ } from 'helpers/i18n';
import queryString from 'helpers/queryString';

import { get } from 'redux/actions/api';
import { errorNotice } from 'redux/actions/application';
import { signIn } from 'redux/actions/resources/user';

import { Button, Control, Field, FieldError, Form, Input } from 'components';

import EditEmail from 'scenes/authentication/EditEmail';

const ExtendableField = posed.div({
  visible: {
    opacity: 1,
    height: 'auto',
    applyAtStart: { display: 'hidden' },
    applyAtEnd: { display: 'block' },
  },
  hidden: {
    opacity: 0,
    height: 0,
  },
});

type Props = {
  onLoginChange: (login: string) => void;
};

type AfterConnectProps = Props & {
  notifyError: (message: string) => void;
  getAuthenticationStrategy: (login: string) => Promise<{
    response: {
      body: {
        data: {
          attributes: { strategy: 'password' | 'saml'; redirect: string };
        };
      };
    };
  }>;
  signIn: (credentials: LoginPasswordPair) => void;
};

type State = {
  errors: LoginPasswordPair;
  credentials: LoginPasswordPair;
  isSubmitting: boolean;
  passwordStrategyFetched: boolean;
};

class EmailPasswordLoginForm extends React.Component<AfterConnectProps, State> {
  state = {
    errors: { login: '', password: '' },
    credentials: { login: '', password: '' },
    isSubmitting: false,
    passwordStrategyFetched: false,
  };

  componentWillMount() {
    const preFilledLogin = queryString.parse(window.location.search)
      .login as string;
    if (!!preFilledLogin) {
      this.setState({
        credentials: {
          login: preFilledLogin,
          password: '',
        },
      });
    }
  }

  handleInputChange = (name: string, value: string | null | undefined) => {
    this.setState(prevState => ({
      credentials: { ...prevState.credentials, [name]: value || '' },
      errors: { ...prevState.errors, [name]: null },
    }));
  };

  handleAuthenticationStrategy = async () => {
    this.setState({ isSubmitting: true });
    try {
      let result = await this.props.getAuthenticationStrategy(
        this.state.credentials.login
      );

      if (result.response.body.data.attributes.strategy === 'password') {
        this.setState({
          isSubmitting: false,
          passwordStrategyFetched: true,
        });
      } else {
        // Redirect to authentication_strategy.redirect
        window.location.assign(result.response.body.data.attributes.redirect);
      }
    } catch (error) {
      this.handleError(error);
      this.setState({ isSubmitting: false });
    }
  };

  handleLogin = async () => {
    const { signIn } = this.props;
    this.setState({ isSubmitting: true });
    try {
      await signIn(this.state.credentials);
    } catch (error) {
      this.handleError(error);
      this.setState({ isSubmitting: false });
    }
  };

  handleError(error) {
    this.setState({ isSubmitting: false });
    const { notifyError } = this.props;

    if (error.response && error.response.statusCode === 401) {
      const message = error.response.body && error.response.body.error;
      this.setState({
        errors: {
          password: message,
          login: ' ',
        },
      });
    } else if (!error.isHandled) {
      window.logException(error);
      notifyError(__('Unknown error: %1', error));
    }
  }

  resetStrategy = () => {
    this.setState(prevState => ({
      passwordStrategyFetched: false,
      credentials: { login: prevState.credentials.login, password: '' },
    }));
  };

  handleSubmit = () => {
    if (this.state.credentials.login === '') return Promise.resolve();

    return this.state.passwordStrategyFetched
      ? this.handleLogin()
      : this.handleAuthenticationStrategy();
  };

  render() {
    const { errors, credentials } = this.state;
    const isCustomDomain = window.location.host.split('.')[0] !== 'app';
    const useEmailAsLogin = !isCustomDomain;
    const loginInputName = useEmailAsLogin ? 'elevo-email' : 'elevo-login'; // For browser autocomplete
    const loginInputType = useEmailAsLogin ? 'email' : 'text';
    const loginIcon = useEmailAsLogin ? 'mail' : 'person';

    return (
      <Form
        onSubmit={this.handleSubmit}
        style={{ marginTop: largeMargin, width: '100%' }}
      >
        {!this.state.passwordStrategyFetched ? (
          <React.Fragment>
            <Field>
              <Control leftIcon={loginIcon}>
                <Input
                  name={loginInputName}
                  type={loginInputType}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  value={credentials.login}
                  onChange={value => {
                    this.handleInputChange('login', value);
                    this.props.onLoginChange(value);
                    if (this.state.passwordStrategyFetched)
                      this.resetStrategy();
                  }}
                  disabled={this.state.isSubmitting}
                  placeholder={useEmailAsLogin ? __('Email') : __('Login')}
                />
              </Control>
              {!!errors.login && <FieldError>{errors.login}</FieldError>}
            </Field>

            <Field style={{ display: 'none' }}>
              <Control>
                {/* This hidden field is only to have good behavior from login auto-completes (Firefox specifically) */}
                <Input
                  type="password"
                  name="password"
                  value={credentials.password}
                  onChange={value => this.handleInputChange('password', value)}
                />
              </Control>
            </Field>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <EditEmail
              resetStrategy={this.resetStrategy}
              email={credentials.login}
              leftIcon={loginIcon}
            />
            {/* This hidden field is only to have good behavior from login auto-completes (Firefox specifically) */}
            <input
              id={loginInputName}
              type={loginInputType}
              name={loginInputName}
              value={credentials.login}
              style={{ display: 'none' }}
              readOnly
            />
          </React.Fragment>
        )}
        <Field
          style={
            this.state.passwordStrategyFetched ? undefined : { marginBottom: 0 }
          }
        >
          <ExtendableField
            pose={this.state.passwordStrategyFetched ? 'visible' : 'hidden'}
          >
            <Control leftIcon="lock">
              <Input
                name="password"
                type="password"
                value={credentials.password}
                onChange={value => this.handleInputChange('password', value)}
                disabled={
                  this.state.isSubmitting || !this.state.passwordStrategyFetched
                }
                placeholder={__('Password')}
                ref={textFieldRef => textFieldRef && textFieldRef.focus()}
              />
            </Control>
            {!!errors.password && <FieldError>{errors.password}</FieldError>}
          </ExtendableField>
        </Field>

        <Button
          color="primary"
          isLoading={this.state.isSubmitting}
          onClick={this.handleSubmit}
          disabled={credentials.login === ''}
          isExpanded
        >
          {this.state.passwordStrategyFetched ? __('Sign in') : __('Continue')}
        </Button>
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  notifyError: (message: string) => dispatch(errorNotice(message)),
  getAuthenticationStrategy: (login: string) =>
    dispatch(get('authentication_strategies', { login })),
  signIn: (credentials: LoginPasswordPair) => dispatch(signIn(credentials)),
});

export default connect(null, mapDispatchToProps)(EmailPasswordLoginForm);
