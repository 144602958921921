import invariant from 'helpers/invariant';
import queryString from 'helpers/queryString';

import { getCredentials } from 'lib/api/credentials';

function isAbsoluteHref(href: string) {
  return (
    href.indexOf('https://') === 0 ||
    href.indexOf('http://') === 0 ||
    href.indexOf('//') === 0
  );
}

export function getPathForAbsoluteHref(href: string): string {
  if (!isAbsoluteHref(href)) return href;

  return new URL(href).pathname;
}

export function addToken(url: URL | string): string {
  if (typeof url === 'string') {
    url = new URL(url);
  }

  const credentials = getCredentials();

  invariant(
    credentials && credentials.token,
    'No credentials found for redirect'
  );
  const { token } = credentials;

  const searchParams = queryString.parse(url.search);

  searchParams.token = token;

  url.search = queryString.stringify(searchParams);

  return url.toString();
}
