import React from 'react';

import store from 'config/store';

import { __ } from 'helpers/i18n';
import {
  pathToArea,
  pathToAreaList,
  pathToAreaMatrixList,
} from 'helpers/paths';

import { hydrateFromStore } from 'lib/dataLoader';

import { PageHeader } from 'components';

type Props = {
  areaId: string;
};

export default function AreaHeader({ areaId }: Props) {
  const area = hydrateFromStore(
    store.getState().data,
    { resourceType: 'skillsArea', id: areaId },
    {}
  );

  return (
    <PageHeader
      title={__(area?.title || '')}
      withBackButton
      backButtonProps={{
        target: pathToAreaList(),
        children: __('Back to skills'),
      }}
      tabItems={[
        {
          to: pathToArea(areaId),
          label: __('Expectations'),
          labelPreset: '16bs5.5',
        },
        {
          to: pathToAreaMatrixList(areaId),
          label: __(
            'Associated matrices (%1)',
            area?.associatedMatrixCount || '-'
          ),
          labelPreset: '16bs5.5',
        },
      ]}
    />
  );
}
