import React from 'react';

import { __ } from 'helpers/i18n/__';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';

type Props = {
  domainTitle: string;
  levelTitle: string | undefined;
  skillTitle: string;
  expectationContent: string;
  onClose: () => void;
};

const ExpectationModal = ({
  domainTitle,
  levelTitle,
  skillTitle,
  expectationContent,
  onClose,
}: Props) => (
  <ModalCard onClose={onClose} isActive isLarge>
    <ModalCardHead>
      <ModalCardTitle>{domainTitle}</ModalCardTitle>
    </ModalCardHead>
    <ModalCardBody>
      <p className="mb-4">
        <Text preset="13buppers7" color="light">
          {__('Expectations for the %1 level', levelTitle)}
        </Text>
      </p>
      <p>
        <Text preset="14bs6">{skillTitle}</Text>
      </p>
      <Text additionalClassName="whitespace-pre-wrap">
        {expectationContent}
      </Text>
    </ModalCardBody>
    <ModalCardFooter>
      <Button color="secondary" onClick={onClose}>
        {__('Close')}
      </Button>
    </ModalCardFooter>
  </ModalCard>
);

export default ExpectationModal;
