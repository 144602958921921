import classNames from 'classnames';
import React, { Fragment } from 'react';

import { __ } from 'helpers/i18n';

import { Text } from 'components';

type Props = {
  ratingOptions: string[];
  additionalClassName?: string;
};

const RatingSliderHeader = ({ ratingOptions, additionalClassName }: Props) => (
  <Fragment>
    <div
      className={classNames(
        'flex flex-row justify-between col-span-10 gap-2 mr-2',
        additionalClassName
      )}
    >
      {ratingOptions.map(ratingOption => (
        <Text
          key={ratingOption}
          preset="11s8"
          color="soften"
          additionalClassName="text-center flex-1"
        >
          {ratingOption}
        </Text>
      ))}
    </div>
    <Text
      preset="11s8"
      color="soften"
      additionalClassName={classNames(
        'col-span-2 pl-2 text-center',
        additionalClassName
      )}
    >
      {__("I don't know / can't evaluate")}
    </Text>
  </Fragment>
);

export default RatingSliderHeader;
