import React from 'react';

import type { ExportAction } from 'models';

import { useExportAction } from 'helpers/hooks/useExportAction';
import { __ } from 'helpers/i18n';

import { Button, Tooltip } from 'components';

type Props = {
  lastPendingExportAction: ExportAction | null | undefined;
  exportPeopleReviewCycle: () => Promise<void>;
  additionalClassName?: string;
  disabled: boolean;
};

const ExportButton = ({
  lastPendingExportAction,
  exportPeopleReviewCycle,
  additionalClassName,
  disabled,
}: Props) => {
  const { ongoingExportAction, exportTooltipText, pollerComponent } =
    useExportAction(lastPendingExportAction);
  const disabledTooltipText = __(
    'Export impossible, the campaign cannot be empty.'
  );

  return (
    <Tooltip content={!!disabled ? disabledTooltipText : exportTooltipText}>
      {pollerComponent}
      <Button
        color="secondary"
        onClick={exportPeopleReviewCycle}
        disabled={disabled || ongoingExportAction}
        additionalClassName={additionalClassName}
      >
        {__('Export')}
      </Button>
    </Tooltip>
  );
};

export default ExportButton;
