import React from 'react';

import {
  DraftSurveyCampaign,
  SurveyCampaignUpdatableAttributes,
  SurveyCampaignUpdateParams,
} from 'models';

import can from 'helpers/can';

import getSetupStepInfoList, { StepInfo } from '../helpers/steps';
import StepForm from './steps/StepForm';
import StepFrequency from './steps/StepFrequency';
import StepParticipants from './steps/StepParticipants';
import StepSettings from './steps/StepSettings';
import StepSummary from './steps/StepSummary';

type Props = {
  surveyCampaign: DraftSurveyCampaign;
  onUpdate: (
    params: SurveyCampaignUpdateParams,
    options?: { withDefaultErrorHandling: boolean }
  ) => Promise<void>;
  currentStepInfo: StepInfo;
  goToNextStep: (params?: { requestAlreadySent?: boolean }) => void;
};

const CurrentStep = ({
  surveyCampaign,
  onUpdate,
  currentStepInfo,
  goToNextStep,
}: Props) => {
  const updateCampaign = (
    params: SurveyCampaignUpdatableAttributes,
    options?: { withDefaultErrorHandling: boolean }
  ) => {
    return onUpdate(
      {
        ...params,
        currentSetupStepName: currentStepInfo.name,
      },
      options
    );
  };

  if (currentStepInfo.name === 'settings')
    return (
      <StepSettings
        campaignName={surveyCampaign.name}
        campaignAnonymous={surveyCampaign.anonymous}
        onContinueClick={async params => {
          await updateCampaign(params);
          goToNextStep();
        }}
      />
    );
  else if (currentStepInfo.name === 'questions')
    return (
      <StepForm
        campaignId={surveyCampaign.id}
        formId={surveyCampaign.formId}
        onUpdate={updateCampaign}
        goToNextStep={goToNextStep}
      />
    );
  else if (currentStepInfo.name === 'participants') {
    return (
      <StepParticipants
        campaignId={surveyCampaign.id}
        isAnonymous={surveyCampaign.anonymous}
        participantAdditionMode={surveyCampaign.participantAdditionMode}
        includedParticipantsCount={surveyCampaign.includedParticipantsCount}
        excludeParticipantModeEnabled={
          surveyCampaign.excludeParticipantModeEnabled
        }
        onUpdate={updateCampaign}
        goToNextStep={goToNextStep}
      />
    );
  } else if (currentStepInfo.name === 'frequency')
    return (
      <StepFrequency
        onUpdate={updateCampaign}
        goToNextStep={goToNextStep}
        recurrenceType={surveyCampaign.recurrenceType}
        recurrenceValue={surveyCampaign.recurrenceValue}
        recurrenceOffset={surveyCampaign.recurrenceOffset}
        daysToComplete={surveyCampaign.daysToComplete}
        nextPeriodAt={surveyCampaign.nextPeriodAt}
        canLaunchToday={can({
          perform: 'launch',
          on: surveyCampaign,
        })}
      />
    );
  else if (currentStepInfo.name === 'summary') {
    const selectedUsersCount =
      surveyCampaign.participantAdditionMode === 'excluded'
        ? surveyCampaign.excludedParticipantsCount
        : surveyCampaign.includedParticipantsCount;
    const allStepCompleted = getSetupStepInfoList(surveyCampaign)
      .slice(0, 4)
      .every(step => step.isCompleted);
    return (
      <StepSummary
        campaignId={surveyCampaign.id}
        name={surveyCampaign.name}
        nextPeriodAt={surveyCampaign.nextPeriodAt}
        participantAdditionMode={surveyCampaign.participantAdditionMode}
        selectedUsersCount={selectedUsersCount}
        recurrenceType={surveyCampaign.recurrenceType}
        recurrenceValue={surveyCampaign.recurrenceValue}
        anonymous={surveyCampaign.anonymous}
        allStepCompleted={allStepCompleted}
      />
    );
  }
  return null;
};

export default CurrentStep;
