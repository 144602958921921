import React from 'react';

import type { TrainingRequest } from 'models';

import { __ } from 'helpers/i18n';

import { Field, Text, Textarea, Title } from 'components';

type Props = {
  trainingRequest: TrainingRequest;
  onChange: (attributes: Partial<TrainingRequest>) => void;
};

const RefusedRequestAdditionalDetails = ({
  trainingRequest,
  onChange,
}: Props) => {
  return (
    <Field>
      <Title size={7} weight="bold" transformation="uppercase">
        {__('Reason for refusal')}
      </Title>
      <div style={{ marginBottom: 8, lineHeight: '16px' }}>
        <Text color="light" size={7}>
          {__(
            'The reason for refusal will be by emailed to the employee if you add it when saving the “Refused” status.'
          )}
        </Text>
      </div>
      <Textarea
        onChange={refusalReason => onChange({ refusalReason })}
        value={trainingRequest.refusalReason}
        placeholder={__('Explain why the training request has been refused')}
        rows={3}
        maxRows={7}
      />
    </Field>
  );
};

export default RefusedRequestAdditionalDetails;
