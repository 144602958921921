import React from 'react';

import type { ENPSPeriod, ENPSStats } from 'models';

import tailwindTheme from 'config/tailwindTheme';

import { __, n__ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  FetchContainer,
  FlatPieChart,
  SimpleRadialChart,
  Text,
} from 'components';

type Props = {
  enpsPeriod: ENPSPeriod;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    enpsStats: ENPSStats;
  };

const Stats = ({
  enpsPeriod,
  isFetching,
  hasError,
  enpsStats,
}: AfterDataLoaderProps) => {
  return (
    <Box>
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => {
          const {
            currentScore,
            expectedParticipantsCount,
            answersCount,
            commentsCount,
            completionPercentage,
            promotersPercentage,
            passivesPercentage,
            detractorsPercentage,
          } = enpsStats;

          const categoriesData = [
            {
              label: __('Promoters'),
              value: promotersPercentage,
              color: tailwindTheme.colors['green-success'],
            },
            {
              label: __('Passives'),
              value: passivesPercentage,
              color: tailwindTheme.colors['orange-warning'],
            },
            {
              label: __('Detractors'),
              value: detractorsPercentage,
              color: tailwindTheme.colors['red-fuckup'],
            },
          ];

          return (
            <React.Fragment>
              <div className="flex w-full">
                <div className="enps-score">
                  <Text preset="18bs5" additionalClassName="block mb-6">
                    {__('eNPS %1', enpsPeriod.name)}
                  </Text>
                  <div className="flex justify-between">
                    <div className="enps-score-current">
                      {currentScore === null ? '-' : currentScore}
                    </div>
                    <div style={{ flexBasis: 400, marginLeft: 24 }}>
                      <FlatPieChart data={categoriesData} />
                    </div>
                  </div>
                </div>

                <div className="enps-completion">
                  <Text preset="18bs5" additionalClassName="block mb-6">
                    {__('Completion of %1', enpsPeriod.name)}
                  </Text>
                  <div className="flex items-center gap-6">
                    <div style={{ height: 100, width: 100 }}>
                      <SimpleRadialChart
                        value={completionPercentage}
                        color={tailwindTheme.colors['text-main']}
                      />
                    </div>
                    <ul
                      style={{ listStyle: 'disc', paddingLeft: 24 }}
                      className="has-text-light"
                    >
                      <li>
                        <Text preset="14s6">
                          {n__(
                            '%1 participant',
                            '%1 participants',
                            expectedParticipantsCount
                          )}
                        </Text>
                      </li>
                      <li>
                        <Text preset="14s6">
                          {n__('%1 answer', '%1 answers', answersCount)}
                        </Text>
                      </li>
                      <li>
                        <Text preset="14s6">
                          {n__('%1 comment', '%1 comments', commentsCount)}
                        </Text>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        }}
      />
    </Box>
  );
};

export default newDataLoader({
  fetch: ({ enpsPeriod }: Props) => get(`enps/periods/${enpsPeriod.id}/stats`),
  hydrate: {
    enpsStats: {},
  },
})(Stats);
