import isEqual from 'react-fast-compare';

import { lerp, random, randomElement, twoPi } from './utils';

type HTMLOrSVGImageElement = HTMLImageElement | SVGImageElement;
type CanvasImageSource =
  | HTMLOrSVGImageElement
  | HTMLVideoElement
  | HTMLCanvasElement
  | ImageBitmap
  | OffscreenCanvas;

export interface ItemProps {
  color: string;
  radius: [number, number];
  speed: [number, number];
  wind: [number, number];
  changeFrequency: number;
  images?: CanvasImageSource[];
  rotationSpeed: [number, number];
}

export type ItemConfig = Partial<ItemProps>;

export const defaultConfig: ItemProps = {
  color: '#dee4fd',
  radius: [0.5, 3.0],
  speed: [1.0, 3.0],
  wind: [-0.5, 2.0],
  changeFrequency: 200,
  rotationSpeed: [-1.0, 1.0],
};

interface ItemParams {
  x: number;
  y: number;
  radius: number;
  rotation: number;
  rotationSpeed: number;
  speed: number;
  wind: number;
  nextSpeed: number;
  nextWind: number;
  nextRotationSpeed: number;
}
class Item {
  private static offscreenCanvases = new WeakMap<
    CanvasImageSource,
    Record<number, HTMLCanvasElement>
  >();

  static createItems(
    canvas: HTMLCanvasElement | null,
    amount: number,
    config: ItemConfig
  ): Item[] {
    if (!canvas) return [];

    const items: Item[] = [];

    for (let i = 0; i < amount; i++) {
      items.push(new Item(canvas, config));
    }

    return items;
  }

  private config!: ItemProps;
  private params: ItemParams;
  private framesSinceLastUpdate: number;
  private image?: CanvasImageSource;

  public constructor(canvas: HTMLCanvasElement, config: ItemConfig = {}) {
    this.updateConfig(config);

    const { radius, wind, speed, rotationSpeed } = this.config;

    this.params = {
      x: random(0, canvas.offsetWidth),
      y: random(-canvas.offsetHeight, 0),
      rotation: random(0, 360),
      radius: random(...radius),
      speed: random(...speed),
      wind: random(...wind),
      rotationSpeed: random(...rotationSpeed),
      nextSpeed: random(...wind),
      nextWind: random(...speed),
      nextRotationSpeed: random(...rotationSpeed),
    };

    this.framesSinceLastUpdate = 0;
  }

  private selectImage() {
    if (this.config.images && this.config.images.length > 0) {
      this.image = randomElement(this.config.images);
    } else {
      this.image = undefined;
    }
  }

  public updateConfig(config: ItemConfig): void {
    const previousConfig = this.config;
    this.config = { ...defaultConfig, ...config };
    this.config.changeFrequency = random(
      this.config.changeFrequency,
      this.config.changeFrequency * 1.5
    );

    if (this.params && !isEqual(this.config.radius, previousConfig?.radius)) {
      this.params.radius = random(...this.config.radius);
    }

    if (!isEqual(this.config.images, previousConfig?.images)) {
      this.selectImage();
    }
  }

  private updateTargetParams(): void {
    this.params.nextSpeed = random(...this.config.speed);
    this.params.nextWind = random(...this.config.wind);
    if (this.image) {
      this.params.nextRotationSpeed = random(...this.config.rotationSpeed);
    }
  }

  public update(
    offsetWidth: number,
    offsetHeight: number,
    framesPassed = 1
  ): void {
    const {
      x,
      y,
      rotation,
      rotationSpeed,
      nextRotationSpeed,
      wind,
      speed,
      nextWind,
      nextSpeed,
      radius,
    } = this.params;

    // Update current location, wrapping around if going off the canvas
    this.params.x = (x + wind * framesPassed) % (offsetWidth + radius * 2);
    if (this.params.x > offsetWidth + radius) this.params.x = -radius;
    this.params.y = (y + speed * framesPassed) % (offsetHeight + radius * 2);
    if (this.params.y > offsetHeight + radius) this.params.y = -radius;

    // Apply rotation
    if (this.image) {
      this.params.rotation = (rotation + rotationSpeed) % 360;
    }

    // Update the wind, speed and rotation towards the desired values
    this.params.speed = lerp(speed, nextSpeed, 0.01);
    this.params.wind = lerp(wind, nextWind, 0.01);
    this.params.rotationSpeed = lerp(rotationSpeed, nextRotationSpeed, 0.01);

    if (this.framesSinceLastUpdate++ > this.config.changeFrequency) {
      this.updateTargetParams();
      this.framesSinceLastUpdate = 0;
    }
  }

  private getImageOffscreenCanvas(
    image: CanvasImageSource,
    size: number
  ): CanvasImageSource {
    if (image instanceof HTMLImageElement && image.loading) return image;
    let sizes = Item.offscreenCanvases.get(image);

    if (!sizes) {
      sizes = {};
      Item.offscreenCanvases.set(image, sizes);
    }

    if (!(size in sizes)) {
      const canvas = document.createElement('canvas');
      canvas.width = size;
      canvas.height = size;
      canvas.getContext('2d')?.drawImage(image, 0, 0, size, size);
      sizes[size] = canvas;
    }

    return sizes[size] || image;
  }

  public drawCircle(ctx: CanvasRenderingContext2D): void {
    ctx.moveTo(this.params.x, this.params.y);
    ctx.arc(this.params.x, this.params.y, this.params.radius, 0, twoPi);
  }

  public drawImage(ctx: CanvasRenderingContext2D): void {
    const { x, y, rotation, radius } = this.params;

    const radian = (rotation * Math.PI) / 180;
    const cos = Math.cos(radian);
    const sin = Math.sin(radian);

    ctx.setTransform(cos, sin, -sin, cos, x, y);

    const image = this.getImageOffscreenCanvas(this.image!, radius);
    ctx.drawImage(image, -(radius / 2), -(radius / 2), radius, radius);
  }
}

export default Item;
