import type { ReviewCycle } from 'models';

import { __ } from 'helpers/i18n';

import { StepInfo as BaseStepInfo } from 'scenes/components/Stepper';

import { isParticipantsStepCompleted } from '../Edit/steps/Step3Participants';
import { isOptionsStepCompleted } from '../Edit/steps/Step4Options';

export type StepName =
  | 'name'
  | 'template'
  | 'participants'
  | 'options'
  | 'emails'
  | 'finalize';

export type StepInfo = BaseStepInfo<StepName>;

const getSetupStepInfoList = (
  reviewCycle: ReviewCycle | undefined | null
): Array<StepInfo> => [
  {
    name: 'name',
    title: __('Name'),
    isCompleted: !!reviewCycle?.actualName && !!reviewCycle?.interactionType,
  },
  {
    name: 'participants',
    title: __('Participants'),
    isCompleted: !!reviewCycle
      ? isParticipantsStepCompleted(reviewCycle)
      : false,
  },
  {
    name: 'template',
    title: __('Template'),
    isCompleted: !!reviewCycle?.defaultTemplate,
  },
  {
    name: 'options',
    title: __('Options'),
    isCompleted: !!reviewCycle ? isOptionsStepCompleted(reviewCycle) : false,
  },
  {
    name: 'emails',
    title: __('Emails'),
    isCompleted: !!reviewCycle ? reviewCycle.setupStepIndex > 4 : false,
  },
  {
    name: 'finalize',
    title: __('Finalize'),
    isCompleted: false,
  },
];

export default getSetupStepInfoList;
