import React from 'react';
import { components } from 'react-select';

import type { OptionProps } from 'react-select';

import currencies from 'helpers/currencies';

import { Select } from 'components';

type Props = {
  selectedCurrency: string;
  onCurrencyChange: (currency: string) => void;
};

const CustomOption = (props: OptionProps) => (
  <components.Option {...props} className="pl-2 py-2 text-sm">
    {props.label}
  </components.Option>
);

const CurrencySelect = ({ selectedCurrency, onCurrencyChange }: Props) => {
  const options = currencies.map(currency => ({
    value: currency,
    label: currency,
  }));
  const selectedOption = options.find(
    option => option.value === selectedCurrency
  );

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={option => option && onCurrencyChange(option.value)}
      components={{
        Option: CustomOption,
      }}
    />
  );
};

export default CurrencySelect;
