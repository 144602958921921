import React from 'react';

import { Field, Label, Text } from 'components';

type Props = { label: string; value: string | null };

const ReadOnlyField = ({ label, value }: Props) => {
  if (!value) return null;

  return (
    <Field>
      <Label color="info">{label}</Label>
      <Text>{value}</Text>
    </Field>
  );
};

export default ReadOnlyField;
