import type { User, UserFilterSegment, UserFilterType } from 'models';

import { __ } from 'helpers/i18n';

export type QuickFilter = UserFilterSegment & {
  enabled: boolean;
};

export default function availableQuickFiltersForUser(
  user: User
): Array<QuickFilter> {
  const availableQuickFilters = [
    {
      filterLabel: __('My direct reports'),
      filterType: 'direct_reports' as UserFilterType,
      label: null,
      value: user.id,
      enabled: user.isManager,
    },
    {
      filterLabel: __('My reports'),
      filterType: 'reports' as UserFilterType,
      label: null,
      value: user.id,
      enabled: user.isManager,
    },
  ];

  // @ts-ignore TSFIXME: Fix strictNullChecks error
  return availableQuickFilters.filter(item => item.enabled);
}
