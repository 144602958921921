import { useEffect, useRef, useState } from 'react';

const DEFAULT_RECHARTS_X_AXIS_HEIGHT = 28;

const useResponsiveChartBottom = <RefElement extends HTMLElement>(
  dependencies: unknown[]
) => {
  const containerRef = useRef<RefElement>(null);

  const [xAxisHeight, setXAxisHeight] = useState(0);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (!containerRef.current) return;

      const xAxisElement =
        containerRef.current.querySelector('.recharts-xAxis');
      if (!xAxisElement) return;

      const height = xAxisElement?.getBoundingClientRect().height;
      setXAxisHeight(height);
    });
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    containerRef,
    chartMarginBottom: xAxisHeight - DEFAULT_RECHARTS_X_AXIS_HEIGHT,
  };
};

export default useResponsiveChartBottom;
