import React from 'react';

import type { KeyResult, TeamObjective } from 'models';
import type { CSSProperties } from 'react';
import type { UpdatableObjectiveFields } from 'redux/actions/resources';

import { useAppDispatch } from 'helpers/hooks';

import { del, post, put } from 'redux/actions/api';

import { default as ObjectiveCard } from 'scenes/components/objectives/ObjectiveCard';

type Props = {
  objective: TeamObjective;
  style?: CSSProperties;
};

const TeamObjectiveCard = ({ objective, style }: Props) => {
  const dispatch = useAppDispatch();
  const onUpdate = (attributes: Partial<UpdatableObjectiveFields>) =>
    dispatch(
      put(`team_objectives/${objective.id}`, { teamObjective: attributes })
    );
  const onDestroy = () => dispatch(del(`team_objectives/${objective.id}`));
  const onAddKeyResult = () =>
    dispatch(post(`team_objectives/${objective.id}/key_results`));
  const onUpdateKeyResult = (
    keyResultId: string,
    attributes: Partial<KeyResult>
  ) =>
    dispatch(
      put(`team_objectives/${objective.id}/key_results/${keyResultId}`, {
        keyResult: attributes,
      })
    );
  const onDestroyKeyResult = (keyResultId: string) =>
    dispatch(del(`team_objectives/${objective.id}/key_results/${keyResultId}`));

  return (
    <ObjectiveCard
      objective={objective}
      onUpdate={onUpdate}
      onDestroy={onDestroy}
      onAddKeyResult={onAddKeyResult}
      onUpdateKeyResult={onUpdateKeyResult}
      onDestroyKeyResult={onDestroyKeyResult}
      canManageDraftObjectives={false}
      style={style}
    />
  );
};

export default TeamObjectiveCard;
