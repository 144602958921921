import React from 'react';

import { __ } from 'helpers/i18n/__';
import { navigate, pathToSurveysToComplete } from 'helpers/navigation';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  Text,
} from 'components';

import clapSvg from 'scenes/components/svg/clapSvg';

const ThanksModal = () => (
  <ModalCard onClose={() => navigate(pathToSurveysToComplete())} isActive>
    <ModalCardHead />
    <ModalCardBody>
      <div className="flex flex-col text-center items-center">
        <img
          alt="clap"
          className="h-40 w-40 rounded-full mb-4"
          src={`data:image/svg+xml;base64,${window.btoa(clapSvg)}`}
        />
        <Text preset="24bs2" additionalClassName="mt-8 mb-4">
          {__('It is done!')}
        </Text>
        <Text>{__('Thank you for completing this survey.')}</Text>
      </div>
    </ModalCardBody>
    <ModalCardFooter>
      <Button color="secondary" to={pathToSurveysToComplete()}>
        {__('Close')}
      </Button>
    </ModalCardFooter>
  </ModalCard>
);

export default ThanksModal;
