import React, { ComponentProps, Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import { RouteComponentProps } from 'react-router-dom';

import { TrainingCourse } from 'models/TrainingCourse';

import { __ } from 'helpers/i18n';
import { Match, pathToTrainingsCatalog } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  ContentContainer,
  FetchContainer,
  PageHeader,
  PageTitle,
} from 'components';

import TrainingRequestModaleButton from '../components/TrainingRequestModalButton';
import CourseContent from './CourseContent';
import CourseMetadata from './CourseMetadata';

type RouterProps = RouteComponentProps & {
  match: Match<{
    courseId: string;
  }>;
};
type Props = RouterProps;
type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    trainingCourse: TrainingCourse;
  };

const Course = ({
  trainingCourse,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  const action = (
    <TrainingRequestModaleButton trainingCourse={trainingCourse} />
  );

  const backButton: Partial<ComponentProps<typeof PageHeader>> = {
    withBackButton: true,
    backButtonProps: {
      fallbackTarget: pathToTrainingsCatalog(),
      children: __('Back to training catalog'),
    },
  };

  return (
    <div>
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        loadingStyle="alone"
        renderFetching={() => (
          <PageHeader title={<Skeleton width={200} />} {...backButton} />
        )}
        render={() => (
          <Fragment>
            <PageTitle title={[__('Trainings'), trainingCourse.name]} />
            <PageHeader
              title={trainingCourse.name}
              actions={action}
              {...backButton}
            />
            <ContentContainer>
              <div className="max-w-[1128px] m-auto flex gap-4 flex-wrap flex-row-reverse items-start">
                <Box
                  additionalClassName="w-[240px] flex-grow-[1] m-0"
                  size="small"
                >
                  <CourseMetadata {...trainingCourse} />
                </Box>
                <Box
                  additionalClassName="w-[360px] flex-grow-[3] m-0"
                  size="small"
                >
                  <CourseContent {...trainingCourse} />
                </Box>
              </div>
            </ContentContainer>
          </Fragment>
        )}
      />
    </div>
  );
};

export default newDataLoader({
  fetch: ({ match }: Props) => get(`training/courses/${match.params.courseId}`),
  hydrate: {
    trainingCourse: {
      organism: {},
    },
  },
})(Course) as React.ComponentType<Props>;
