import React from 'react';
import { compose } from 'redux';

import { AuditedUser, AuditedUserStatus } from 'models/AuditedUser';
import { PaginatedCollection } from 'models/Collection';

import compositeKey from 'helpers/compositeKey';
import { __, date, n__ } from 'helpers/i18n';
import { pathToNewReviewResults, pathToUser } from 'helpers/paths';

import { newDataLoader } from 'lib/dataLoader';
import { DataLoaderProvidedProps } from 'lib/dataLoader/types';
import { WithPaginationProps } from 'lib/pagination/types';
import withPagination from 'lib/pagination/withPagination';
import { get } from 'redux/actions/api';

import {
  BoxList,
  BoxListItem,
  Column,
  Columns,
  DatatableWrapper,
  FetchContainer,
  Indicator,
  Link,
  Text,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';

import UserAvatar from 'scenes/components/UserAvatar';

type StatusModifiers = {
  isDanger?: boolean;
  isSuccess?: boolean;
  isWarning?: boolean;
};

const statusText = (status: AuditedUserStatus) => {
  switch (status) {
    case 'late':
      return <Text>{__('Late')}</Text>;
    case 'up_to_date':
      return <Text>{__('Up to date')}</Text>;
    case 'nearing_deadline':
      return <Text>{__('Nearing deadline')}</Text>;
    case 'unknown':
    default:
      return <Text>{__('Unknown')}</Text>;
  }
};

const getStatusColor = (status: AuditedUserStatus): StatusModifiers => {
  switch (status) {
    case 'late':
      return { isDanger: true };
    case 'nearing_deadline':
      return { isWarning: true };
    case 'up_to_date':
      return { isSuccess: true };
    default:
      // $FlowIgnore
      return {};
  }
};

const AuditReportTableHeader = () => (
  <BoxListItem>
    <Columns isMobile>
      <Column size={4}>
        <Text preset="14s6" color="light">
          {__('Employee')}
        </Text>
      </Column>
      <Column size={2}>
        <Text preset="14s6" color="light">
          {__('Status')}
        </Text>
      </Column>
      <Column size={3}>
        <Text preset="14s6" color="light">
          {__('Compliance deadline')}
        </Text>
      </Column>
      <Column size={3}>
        <Text preset="14s6" color="light">
          {__('Last review')}
        </Text>
      </Column>
    </Columns>
  </BoxListItem>
);

const AuditReportListItem = ({ item }: { item: AuditedUser }) => {
  return (
    <BoxListItem>
      <Columns
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        isMobile
      >
        <Column size={4}>
          <UserAvatar
            user={item.user}
            link={pathToUser(item.user.id)}
            withJobTitle
          />
        </Column>
        <Column
          size={2}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Indicator isSmall {...getStatusColor(item.status)} />
          <Text preset="14s6">{statusText(item.status)}</Text>
        </Column>
        <Column size={3}>
          {item.complianceDeadline
            ? date(item.complianceDeadline)
            : __('No reference data')}{' '}
          {item.status === 'late' && (
            <Text color="danger">
              {n__('(%1 day late)', '(%1 days late)', item.lateDays)}
            </Text>
          )}
          {item.status === 'nearing_deadline' && (
            <Text>
              {n__(
                '(%1 day remaining)',
                '(%1 days remaining)',
                item.remainingDays
              )}
            </Text>
          )}
        </Column>
        <Column size={3}>
          <Link
            to={item.userReviewId && pathToNewReviewResults(item.userReviewId)}
            openInNewTab
          >
            {!!item.reviewCycleName ? item.reviewCycleName : '-'}
          </Link>
          {!!item.userReviewId && (
            <div>
              <Text color={item.isReleased ? 'success' : 'danger'}>
                {item.isReleased ? __('Shared') : __('Not shared')}
              </Text>
            </div>
          )}
          {!item.userReviewId && !!item.reviewCycleName && (
            <div>
              <Text>{__('Imported review')}</Text>
            </div>
          )}
        </Column>
      </Columns>
    </BoxListItem>
  );
};

type Props = {
  auditReportId: string;
  lastUpdate: string;
  additionalQueryParams: ActiveFilters | {};
  defaultFilter?: { [key: string]: boolean };
};

type AfterPaginateProps = Props & WithPaginationProps;

type AfterConnectProps = AfterPaginateProps & {
  auditedUsersCollection: PaginatedCollection<AuditedUser>;
};

type AfterDataloaderProps = DataLoaderProvidedProps & AfterConnectProps;

const AuditedUsersTable = ({
  auditedUsersCollection,
  queryParams: { search, filter, userFilters },
  page,
  countPerPage,
  setNextPageParams,
  setPreviousPageParams,
  setQueryParams,
  isFetching,
  hasError,
}: AfterDataloaderProps) => {
  const auditedUsers = auditedUsersCollection
    ? auditedUsersCollection.items
    : [];
  return (
    <DatatableWrapper
      collectionInfo={auditedUsersCollection}
      search={search}
      page={page}
      countPerPage={countPerPage}
      getPreviousPage={setPreviousPageParams}
      getNextPage={setNextPageParams}
      onQueryParamsChange={setQueryParams}
      withSearch
      userFilters={userFilters}
      isFetching={isFetching}
      hasError={hasError}
      filters={[
        { param: 'late', label: __('Late') },
        { param: 'nearing_deadline', label: __('Nearing deadline') },
        { param: 'up_to_date', label: __('Up to date') },
        { param: 'unknown', label: __('Unknown') },
        { param: 'all', label: __('All') },
      ]}
      filter={filter}
    >
      <BoxList>
        <AuditReportTableHeader />
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <div>
              {auditedUsers.map(item => (
                <AuditReportListItem key={item.id} item={item} />
              ))}
            </div>
          )}
        />
      </BoxList>
    </DatatableWrapper>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      auditReportId,
      queryParams: { search, filter, userFilters },
    }: AfterPaginateProps) =>
      get(`audit_reports/${auditReportId}/audited_users`, {
        search,
        filter,
        page,
        countPerPage,
        userFilters,
      }),
    hydrate: { auditedUsersCollection: { items: { user: {} } } },
    cacheKey: ({
      page,
      countPerPage,
      queryParams: { search, filter, userFilters },
      lastUpdate,
    }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
        search,
        filter,
        userFilters,
        lastUpdate,
      }),
  })
)(AuditedUsersTable);
