const cylceLaunchedSvg = `<svg width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M60.5 0C72.3669 0 83.9673 3.51894 93.8342 10.1118C103.701 16.7047 111.392 26.0754 115.933 37.039C120.474 48.0026 121.662 60.0666 119.347 71.7054C117.032 83.3443 111.318 94.0353 102.926 102.426C94.5353 110.818 83.8443 116.532 72.2054 118.847C60.5666 121.162 48.5026 119.974 37.539 115.433C26.5754 110.892 17.2047 103.201 10.6118 93.3342C4.01894 83.4673 0.5 71.8669 0.5 60C0.5 44.087 6.82141 28.8258 18.0736 17.5736C29.3258 6.32141 44.587 0 60.5 0V0Z" fill="#F2F5FA"/>
<path d="M69.5 99.75C77.7843 99.75 84.5 98.7426 84.5 97.5C84.5 96.2574 77.7843 95.25 69.5 95.25C61.2157 95.25 54.5 96.2574 54.5 97.5C54.5 98.7426 61.2157 99.75 69.5 99.75Z" fill="#B2BBCB"/>
<path d="M77.4289 31.926C76.4151 32.5833 75.3437 33.1471 74.2279 33.6105C60.3064 39.393 56.7199 27.2385 42.7984 33.0225C40.2491 34.1149 37.9194 35.661 35.9224 37.5855" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M80.5343 39.4079C79.521 40.0638 78.4506 40.6271 77.3363 41.091C63.4133 46.8735 59.8268 34.719 45.9053 40.5015C43.3563 41.5941 41.027 43.1408 39.0308 45.066" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M83.6422 46.8884C82.6286 47.5446 81.5577 48.1079 80.4427 48.5715C66.5212 54.354 62.9347 42.1995 49.0132 47.982C46.4643 49.075 44.1347 50.6211 42.1372 52.545" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M40.3818 27.204L52.1208 55.4625" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M48.5522 25.7595L59.9447 53.187" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M58.4497 28.47L69.8422 55.8975" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M67.6567 29.5185L79.0492 56.946" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M75.668 27.69C75.5874 27.4948 75.4665 27.3188 75.3132 27.1736C75.1599 27.0284 74.9777 26.9173 74.7785 26.8473C74.5793 26.7774 74.3676 26.7503 74.1572 26.7678C73.9468 26.7853 73.7424 26.847 73.5575 26.949C72.9917 27.2623 72.4088 27.5437 71.8115 27.792C57.89 33.5745 54.3035 21.4214 40.382 27.2039C37.8307 28.2957 35.4988 29.8418 33.5 31.7669L45.2375 60.0255C47.235 58.1015 49.5646 56.5555 52.1135 55.4624C66.035 49.6799 69.6215 61.8345 83.5445 56.0505C84.2521 55.7555 84.9401 55.4153 85.604 55.0319C85.9196 54.85 86.1583 54.5593 86.2753 54.2143C86.3923 53.8693 86.3798 53.4934 86.24 53.1569L75.668 27.69Z" fill="white" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M40.3823 27.204L42.7988 33.0225C40.2473 34.1118 37.9176 35.6604 35.9258 37.5915L33.5093 31.773C35.5011 29.8419 37.8308 28.2932 40.3823 27.204Z" fill="#B2BBCB" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M58.3805 28.3035L60.797 34.122C57.5945 33.072 54.614 31.623 50.9135 31.4445L48.5 25.626C52.196 25.8 55.178 27.2565 58.3805 28.3035Z" fill="#B2BBCB" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M73.5499 26.9476C73.7347 26.8456 73.939 26.7837 74.1493 26.7661C74.3597 26.7485 74.5714 26.7755 74.7706 26.8453C74.9698 26.9151 75.152 27.0261 75.3053 27.1711C75.4587 27.3162 75.5797 27.492 75.6604 27.687L77.4229 31.9305C76.4093 32.5868 75.3379 33.1491 74.2219 33.6105C72.8304 34.2007 71.3796 34.6402 69.8944 34.9215L67.4824 29.1C68.9681 28.8188 70.4193 28.3792 71.8114 27.789C72.4059 27.5406 72.9863 27.2598 73.5499 26.9476Z" fill="#B2BBCB" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M50.9133 31.4445L54.0213 38.9235C51.231 38.8235 48.4548 39.3628 45.9048 40.5L42.7998 33.0225C45.3482 31.8835 48.1238 31.3437 50.9133 31.4445Z" fill="#B2BBCB" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M69.9003 34.9215L73.0068 42.4005C69.9556 42.9552 66.8115 42.6795 63.9033 41.6025L60.7998 34.122C63.707 35.1984 66.8499 35.4745 69.9003 34.9215Z" fill="#B2BBCB" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.9052 40.5L49.0132 47.9805C46.462 49.0708 44.1324 50.6199 42.1402 52.551L39.0322 45.0705C41.0249 43.1401 43.3543 41.591 45.9052 40.5Z" fill="#B2BBCB" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M63.9035 41.6025L67.0115 49.0815C63.809 48.0315 60.827 46.584 57.128 46.404L54.0215 38.9235C57.7205 39.1035 60.701 40.554 63.9035 41.6025Z" fill="#B2BBCB" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M80.5353 39.411L83.6433 46.8915C82.6295 47.5475 81.5581 48.1098 80.4423 48.5715C79.0502 49.1612 77.5989 49.6003 76.1133 49.881L73.0068 42.4005C74.4924 42.1198 75.9437 41.6807 77.3358 41.091C78.4511 40.6293 79.5221 40.067 80.5353 39.411Z" fill="#B2BBCB" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M57.1282 46.4039L60.2362 53.8844C57.4457 53.7843 54.6694 54.3241 52.1197 55.4624L49.0132 47.9819C51.5623 46.8435 54.3382 46.3037 57.1282 46.4039Z" fill="#B2BBCB" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M76.1137 49.881L79.2217 57.3615C76.1704 57.9158 73.0262 57.6396 70.1182 56.562L67.0117 49.0815C69.9192 50.1592 73.0629 50.4354 76.1137 49.881Z" fill="#B2BBCB" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M55.859 85.5825C56.0769 86.1313 56.0702 86.7438 55.8405 87.2878C55.6108 87.8318 55.1764 88.2637 54.6311 88.4903C54.0858 88.7168 53.4733 88.72 52.9257 88.499C52.3781 88.278 51.9393 87.8506 51.704 87.309L28.5725 31.6215C28.3436 31.0703 28.343 30.4507 28.5708 29.8991C28.7987 29.3474 29.2364 28.9089 29.7875 28.68C30.3387 28.451 30.9583 28.4504 31.5099 28.6782C32.0616 28.9061 32.5001 29.3438 32.729 29.895L55.859 85.5825Z" fill="white" stroke="#1E2938" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

export default cylceLaunchedSvg;
