import React, { useState } from 'react';

import type { SkillsArea } from 'models';

import store from 'config/store';

import { useAppDispatch } from 'helpers/hooks';
import { __, date } from 'helpers/i18n';
import { pathToArea, pathToAreaMatrixList } from 'helpers/paths';
import confirmAsync from 'helpers/react/confirmAsync';

import { htmlSuccessNotice } from 'redux/actions';
import { del } from 'redux/actions/api';
import { getOrganization } from 'redux/reducers';

import {
  BoxListItem,
  Button,
  Column,
  Columns,
  FeatureFlagged,
  Icon,
  Link,
  PullRight,
  Text,
  Tooltip,
} from 'components';

import SkillDomainManagementModal from './SkillDomainManagementModal';

type Props = {
  area: SkillsArea;
  refetchData: () => Promise<void>;
};

const AreaListItem = ({ area, refetchData }: Props) => {
  const dispatch = useAppDispatch();
  const organization = getOrganization(store.getState());

  const [domainManagementModalIsActive, setDomainManagementModalIsActive] =
    useState(false);

  const skillsDomainsEnabled =
    organization.featureFlags.includes('skillsDomains');

  const removeArea = async (areaId: string) => {
    await dispatch(del(`/skills/areas/${areaId}`));
    refetchData();
    dispatch(
      htmlSuccessNotice(
        __('The skill <b>%1</b> has been successfully deleted', area.title)
      )
    );
  };

  const confirmRemoveArea = (area: SkillsArea) => {
    return confirmAsync(
      __('Delete the skill %1', area.title),
      <React.Fragment>
        <p>
          {__('This action will delete the skill %1.', <b>{area.title}</b>)}
        </p>
        <p>
          {__(
            'The skill domain will no longer be visible in the associated matrices and will no longer be used to assess employees.'
          )}
        </p>
        <br />
      </React.Fragment>,

      {
        confirmLabel: __('Delete the skill'),
        isDanger: true,
        sideEffectsLabel: __('Are you sure you want to delete this skill?'),
        sideEffects: [
          __('Remove it from the associated skill matrices', area.title),
        ],
        onConfirm: () => removeArea(area.id),
      }
    );
  };

  return (
    <React.Fragment>
      <FeatureFlagged flag="skillsDomains">
        {domainManagementModalIsActive && !!area.domain && (
          <SkillDomainManagementModal
            domain={area.domain}
            onClose={() => {
              setDomainManagementModalIsActive(false);
            }}
          />
        )}
      </FeatureFlagged>

      <BoxListItem>
        <Columns>
          <Column size={3}>
            <Text linkTo={pathToArea(area.id)}>{area.title}</Text>
          </Column>

          {skillsDomainsEnabled && (
            <Column size={3}>
              {!!area.domain ? (
                <Link onClick={() => setDomainManagementModalIsActive(true)}>
                  {area.domain.title}
                </Link>
              ) : (
                <Text>{'-'}</Text>
              )}
            </Column>
          )}

          <Column size={skillsDomainsEnabled ? 2 : 3}>
            <Text linkTo={pathToAreaMatrixList(area.id)}>
              {area.associatedMatrixCount}
            </Text>
          </Column>

          <Column size={3}>
            <Text>
              {area.updatedByFullName !== null
                ? __(
                    '%1 by %2',
                    date(area.updatedAt, 'LL'),
                    area.updatedByFullName
                  )
                : date(area.updatedAt, 'LL')}
            </Text>
          </Column>

          <Column>
            <PullRight>
              <Tooltip content={__('Remove the skill')}>
                <Button
                  hasIconOnly
                  additionalClassName="border-none bg-transparent"
                  onClick={() => confirmRemoveArea(area)}
                >
                  <Icon size="large" name="delete" />
                </Button>
              </Tooltip>
            </PullRight>
          </Column>
        </Columns>
      </BoxListItem>
    </React.Fragment>
  );
};

export default AreaListItem;
