import React from 'react';

import { Flex } from 'components';

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  additionalClassName?: string;
};

export default function Centered({
  children,
  style,
  additionalClassName,
}: Props) {
  return (
    <Flex
      direction="column"
      verticalAlign
      horizontalAlign
      style={style}
      additionalClassName={additionalClassName}
    >
      {children}
    </Flex>
  );
}
