// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useDispatch, useSelector, useStore } from 'react-redux';

import type { TypedUseSelectorHook } from 'react-redux';
import type { AppDispatch } from 'redux/actions/types';
import type { ReduxStore } from 'redux/reducers';

import store from 'config/store';

// https://redux.js.org/usage/usage-with-typescript#define-typed-hooks
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReduxStore> = useSelector;
export const useAppStore: () => typeof store = useStore;
