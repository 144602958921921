import React from 'react';

import type { RouteComponentProps } from 'react-router-dom';

import queryString from 'helpers/queryString';

import { Splash } from 'components';

import BadRequestErrorMessage from 'scenes/authentication/AcceptInvitation/BadRequestErrorMessage';
import Form from 'scenes/authentication/AcceptInvitation/Form';

type Props = RouteComponentProps;

export default class AcceptInvitation extends React.Component<Props, {}> {
  parseQuery() {
    const query = queryString.parse(window.location.search);

    return {
      params: {
        login: (query.email || query.login) as string,
        invitationToken: query.invitation_token as string,
        firstName: query.first_name as string,
        lastName: query.last_name as string,
      },
      errors: {
        login: !query.login && !query.email,
        invitationToken: !query.invitation_token,
      },
    };
  }

  render() {
    const parsedQuery = this.parseQuery();
    const canAcceptInvitation =
      !parsedQuery.errors.login && !parsedQuery.errors.invitationToken;

    return (
      <Splash>
        {canAcceptInvitation ? (
          <Form {...parsedQuery.params} />
        ) : (
          <BadRequestErrorMessage
            hasLoginError={parsedQuery.errors.login}
            hasInvitationTokenError={parsedQuery.errors.invitationToken}
          />
        )}
      </Splash>
    );
  }
}
