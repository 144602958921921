import React from 'react';

import type { EditableFieldProps } from '../EditableField';

import { date } from 'helpers/i18n';

import { DatePicker } from 'components';

import SyncedField from '../SyncedField';

const DateField = ({
  value,
  fieldSynced,
  onChange,
  syncedFieldOptions,
}: EditableFieldProps) => {
  return fieldSynced ? (
    <SyncedField
      lockedFieldValue={!!value ? date(value, 'L') : null}
      {...syncedFieldOptions}
    />
  ) : (
    <DatePicker value={value} onChange={value => onChange(value)} isFullWidth />
  );
};

export default DateField;
