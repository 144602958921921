import React, { ReactNode, useState } from 'react';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { htmlSuccessNotice } from 'redux/actions';

import { Icon } from '.';

type Props = {
  text: string | undefined;
  children: ReactNode;
};

const CopyOnClick = ({ text, children }: Props) => {
  const dispatch = useAppDispatch();
  const [showIcon, setShowIcon] = useState(false);

  return (
    <div
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
      className="w-full justify-between flex items-center"
    >
      {children}
      {showIcon && text !== undefined && (
        <Icon
          onClick={async () => {
            await navigator.clipboard.writeText(text);
            dispatch(htmlSuccessNotice(__('Text copied to clipboard')));
          }}
          name="content_copy"
          additionalClassName="cursor-pointer"
        />
      )}
    </div>
  );
};

export default CopyOnClick;
