import type { AppDispatch } from 'redux/actions/index';

import { withGenericErrorHandling } from 'lib/api/errorHandler';
import * as api from 'lib/api/genericFetch';

export const getAvailablePeriods = () => (dispatch: AppDispatch) =>
  withGenericErrorHandling(
    api.genericFetch({
      method: 'GET',
      url: 'organization_objective_periods/available',
    })
  )(dispatch).then(response => ({ response }));
