import React, { useEffect, useState } from 'react';

import type { ReviewCycle, Tag as TagType } from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { Button, ConfirmationModal, Flex, Icon, Tag } from 'components';

import TagPicker from 'scenes/components/TagPicker';

type Props = {
  reviewCycle: ReviewCycle;
  onFieldChange: (field: { tagLabels: Array<string> }) => void;
  readOnly: boolean;
};

const TagsBlock = ({ reviewCycle, onFieldChange, readOnly }: Props) => {
  const tags = reviewCycle.tags;
  invariant(tags, 'Review cycle tags should be defined');
  const [isTagsModalActive, setIsTagsModalActive] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Array<TagType>>([]);

  useEffect(() => {
    setSelectedTags(tags);
  }, [tags]);

  const removeTag = (tagLabel: string) => {
    onFieldChange({
      tagLabels: tags.map(tag => tag.label).filter(tag => tag !== tagLabel),
    });
  };
  const addTags = () => {
    onFieldChange({
      tagLabels: selectedTags.map(tag => tag.label),
    });
    setIsTagsModalActive(false);
  };
  return (
    <Flex
      style={{ flexDirection: 'column', alignItems: 'start' }}
      additionalClassName="gap-1"
    >
      {tags.map((tag, index) => (
        <Tag
          key={`tag-${index}`}
          rightIcon={
            readOnly
              ? undefined
              : {
                  name: 'close',
                  onClick: () => removeTag(tag.label),
                }
          }
        >
          {tag.label}
        </Tag>
      ))}
      {!readOnly && (
        <Button
          onClick={() => setIsTagsModalActive(true)}
          size="small"
          color="secondary"
          additionalClassName="no-underline"
        >
          <Icon additionalClassName="mr-1" name="add_circle" />
          {__('Add')}
        </Button>
      )}

      {isTagsModalActive && !readOnly && (
        <ConfirmationModal
          isActive={isTagsModalActive}
          onConfirm={addTags}
          onCancel={() => setIsTagsModalActive(false)}
          confirmLabel={__('Add')}
          title={__('Add tags to this campaign')}
          refreshContentOnOpening
          isLarge
          overflowVisible
        >
          <p>{__('Choose one or many tags to add to this campaign')}</p>
          <div style={{ margin: '1rem 0' }}>
            <TagPicker
              context={
                reviewCycle.interactionType === 'one_on_one'
                  ? 'review_cycle'
                  : 'three_sixty_review_cycle'
              }
              selectedTags={selectedTags}
              onTagsChange={setSelectedTags}
            />
          </div>
        </ConfirmationModal>
      )}
    </Flex>
  );
};

export default TagsBlock;
