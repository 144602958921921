import React, { Fragment, useState } from 'react';

import type {
  UserFilterSegment,
  UserFilterableFieldType,
  UserFilterableSource,
} from 'models';

import can from 'helpers/can';
import classNames from 'helpers/classNames';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useActiveUser, useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { BoxSeparator, DesignSystem, Popover, Text } from 'components';

import FilterTypeList from './FilterTypeList';
import QuickFilterList from './QuickFilterList';
import SelectedFilter from './SelectedFilter';
import Trigger from './Trigger';
import availableQuickFiltersForUser from './availableQuickFiltersForUser';

type Props = {
  segment: UserFilterSegment | undefined | null;
  filteredSource?: UserFilterableSource;
  onChange: (userFilter: UserFilterSegment | null) => void;
  disabled?: boolean;
  additionalClassName?: string;
};

const UserFilter = ({
  segment,
  filteredSource,
  onChange,
  disabled,
  additionalClassName,
}: Props) => {
  const activeUser = useActiveUser();
  const [selectedFilterType, setSelectedFilterType] =
    useState<UserFilterableFieldType | null>(null);
  const organization = useOrganization();
  if (!can({ perform: 'show_user_filters', on: organization })) return null;

  const source =
    !!filteredSource && ['users', 'survey_users'].includes(filteredSource)
      ? filteredSource
      : 'users';
  const availableFilters =
    source === 'survey_users'
      ? organization.surveyUserFilterableFields
      : organization.userFilterableFields;

  const availableFilterTypes = availableFilters.map(field => field.slug);

  const availableQuickFilters = availableQuickFiltersForUser(activeUser).filter(
    item => availableFilterTypes.includes(item.filterType)
  );

  return (
    <DesignSystem version={2}>
      {disabled ? (
        <div
          className={classNames(
            'user-filter disabled py-4 px-0',
            additionalClassName
          )}
        >
          <Trigger
            segment={segment}
            onClick={() => {}}
            onClear={() => {}}
            disabled
          />
        </div>
      ) : (
        <Popover
          additionalClassName={classNames('user-filter', additionalClassName)}
          renderTrigger={onTrigger => {
            return (
              <Trigger
                segment={segment}
                onClick={onTrigger}
                onClear={() => {
                  onChange(null);
                  setSelectedFilterType(null);
                }}
              />
            );
          }}
          render={(closePopover, updatePopover) => (
            <Fragment>
              <Text weight="bold">{__('Select the filter')}</Text>

              {!!selectedFilterType ? (
                <SelectedFilter
                  filterType={selectedFilterType}
                  filteredSource={filteredSource}
                  segment={segment}
                  onSubmit={userFilter => {
                    onChange(userFilter || null);
                    closePopover();
                  }}
                  onBack={() => {
                    setSelectedFilterType(null);
                    updatePopover();
                  }}
                  style={{ marginTop: 16 }}
                />
              ) : (
                <Fragment>
                  {availableQuickFilters.length > 0 && (
                    <Fragment>
                      <QuickFilterList
                        availableQuickFilters={availableQuickFilters}
                        onChange={onChange}
                        closePopover={closePopover}
                      />
                      <BoxSeparator additionalClassName="mx-0.5" />
                    </Fragment>
                  )}
                  <FilterTypeList
                    fields={availableFilters}
                    onChange={filterType => {
                      setSelectedFilterType(filterType);
                      updatePopover();
                    }}
                    style={{ marginTop: 16 }}
                    displaySectionTitle={availableQuickFilters.length > 0}
                  />
                </Fragment>
              )}
            </Fragment>
          )}
        />
      )}
    </DesignSystem>
  );
};

export default UserFilter;
