import { sortBy } from 'lodash';
import React, { useMemo } from 'react';

import type { EditorHandlingProps } from '../../helpers/useParentAndChildrenHandlingWithErrors';
import type { AreaErrorsType } from './index';

import { type SkillsArea, SkillsExpectation } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import { pathToAreaMatrixList } from 'helpers/paths';
import confirmAsync from 'helpers/react/confirmAsync';

import { htmlSuccessNotice } from 'redux/actions';
import { del } from 'redux/actions/api';

import {
  Box,
  BoxList,
  BoxListItem,
  Button,
  Column,
  Columns,
  Flex,
  Icon,
  Text,
  Tooltip,
} from 'components';
import { WithSavingStatusRecorder } from 'components/withSavingStatus';

import BuilderCreateButton from 'scenes/skills/components/BuilderCreateButton';
import { fieldUidForItem } from 'scenes/skills/helpers/useFieldStatusResetWhenLoaded';

type Props = EditorHandlingProps<SkillsArea, SkillsExpectation> & {
  refetchData: () => Promise<void>;
};
export default function AreaVisualizer({
  refetchData,
  parent: area,
  fieldErrorsFor,
  updateErrors,
  onChildUpdate: onExpectationUpdate,
  onChildCreate: onExpectationCreate,
  newCreatedChildId: expectationIdToAutofocus,
}: Props) {
  const resetExpectationError = (
    errors: AreaErrorsType,
    expectation: SkillsExpectation
  ) => ({
    ...errors,
    expectations: {
      ...errors?.expectations,
      [expectation.id]: {
        ...errors?.expectations?.[expectation.id],
        title: null,
        content: null,
      },
    },
  });

  const sortedExpectations = useMemo(
    () => sortBy(area.expectations, 'position'),
    [area.expectations]
  );

  const successMessage = (expectationTitle: string) => {
    return __(
      'The level <b>%1</b> has been successfully deleted from the skill',
      expectationTitle
    );
  };

  const dispatch = useAppDispatch();
  const removeExpectation = async (expectation: SkillsExpectation) => {
    await dispatch(del(`/skills/expectations/${expectation.id}`));
    refetchData();
    dispatch(htmlSuccessNotice(successMessage(expectation.title)));
  };

  const confirmRemoveExpectation = (
    areaTitle: string,
    expectation: SkillsExpectation,
    expectationUsedInMatrices: boolean
  ) => {
    return confirmAsync(
      __('Delete expectations for the level?'),
      <React.Fragment>
        <p>
          {__(
            'This action will remove the %1 level from the skill %2.',
            <b>{expectation.title}</b>,
            <b>{areaTitle}</b>
          )}
        </p>
        {expectationUsedInMatrices ? (
          <p>
            {__(
              'This action will remove all expectations associated with this level in skills matrices where it has been used to evaluate team members.'
            )}
          </p>
        ) : (
          <React.Fragment>
            <p>
              {__(
                'As a result, all expectations associated with this level in skills matrices where %1 is used will be removed.',
                <b>{areaTitle}</b>
              )}
            </p>
            <br />
            {__('Are you sure you want to delete this level?')}
          </React.Fragment>
        )}
        <br />
      </React.Fragment>,

      {
        confirmLabel: __('Delete the level'),
        isDanger: true,
        sideEffectsLabel: __('Are you sure you want to delete this level?'),
        // @ts-ignore TSFIXME: Fix strictNullChecks error
        sideEffects: expectationUsedInMatrices
          ? [
              __(
                'Remove expectations for this level from associated skills matrices'
              ),
            ]
          : null,
        onConfirm: () => removeExpectation(expectation),
      }
    );
  };

  return (
    <Box>
      <Flex
        additionalClassName="skills-editor-visualizer"
        style={{
          overflow: 'auto',
          paddingLeft: '16px', // FIXME: 16px is half the size of an add button
        }}
      >
        <BoxList additionalClassName="my-4">
          <BoxListItem>
            <Columns>
              {sortedExpectations.map((expectation, index) => (
                <Column key={expectation.id}>
                  {index === 0 && (
                    <BuilderCreateButton
                      additionalClassName="first-create-button"
                      // @ts-ignore TSFIXME: Fix strictNullChecks error
                      disabled={area.expectations.length === 10}
                      position={0}
                      disabledExplanation={__(
                        'A skill cannot have more than 10 expectations'
                      )}
                      onCreate={onExpectationCreate}
                    />
                  )}
                  <BuilderCreateButton
                    additionalClassName="create-button"
                    // @ts-ignore TSFIXME: Fix strictNullChecks error
                    disabled={area.expectations.length === 10}
                    position={expectation.position + 1}
                    disabledExplanation={__(
                      'A skill cannot have more than 10 expectations'
                    )}
                    onCreate={onExpectationCreate}
                  />
                  <Flex
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <WithSavingStatusRecorder
                      fieldUid={fieldUidForItem(
                        'expectation',
                        expectation.position,
                        'title'
                      )}
                      onChange={(title: string) =>
                        onExpectationUpdate({ ...expectation, title })
                      }
                      render={onChange => (
                        <Text
                          preset="14s6"
                          additionalClassName="flex-1"
                          weight="bold"
                          isEditable
                          editableOptions={{
                            type: 'singlelineText',
                            value: expectation.title,
                            placeholder: __('Expectation name'),
                            autoFocus:
                              expectation.id === expectationIdToAutofocus,
                            onChange,
                            onFocus: () =>
                              updateErrors(errors =>
                                resetExpectationError(errors, expectation)
                              ),
                            displayErrorMessage: false,
                          }}
                        />
                      )}
                    />
                    {sortedExpectations.length !== 1 && (
                      <Tooltip content={__('Delete the level')}>
                        <Button
                          hasIconOnly
                          style={{ position: 'relative' }}
                          additionalClassName="border-none bg-transparent pr-0"
                          onClick={() =>
                            confirmRemoveExpectation(
                              area.title,
                              expectation,
                              !!area.associatedMatrixCount
                            )
                          }
                        >
                          <Icon color="info" size="large" name="delete" />
                        </Button>
                      </Tooltip>
                    )}
                  </Flex>
                  {fieldErrorsFor('expectations', expectation.id, 'title')}
                  {!!area.matrixCountPerExpectationId &&
                    area.matrixCountPerExpectationId[expectation.id] && (
                      <Flex direction={'column'}>
                        <Text color="soften" preset="13s7">
                          {__(
                            'This expectation is shared across %1',
                            <Text
                              color="primary"
                              linkTo={pathToAreaMatrixList(area.id)}
                            >
                              {n__(
                                '%1 matrix.',
                                '%1 matrices.',
                                area.matrixCountPerExpectationId[expectation.id]
                              )}
                            </Text>
                          )}
                        </Text>

                        <Text color="soften" preset="13s7">
                          {n__(
                            'Any modification made will be reflected in the skills matrix.',
                            'Any modification made will be reflected in these skills matrices.',
                            area.matrixCountPerExpectationId[expectation.id]
                          )}
                        </Text>
                      </Flex>
                    )}
                </Column>
              ))}
            </Columns>
          </BoxListItem>
          <BoxListItem>
            <Columns>
              {sortedExpectations.map(expectation => (
                <Column key={expectation.id}>
                  <WithSavingStatusRecorder
                    fieldUid={fieldUidForItem(
                      'expectation',
                      expectation.position,
                      'content'
                    )}
                    onChange={(content: string) =>
                      onExpectationUpdate({ ...expectation, content })
                    }
                    render={onChange => (
                      <Text
                        preset="13s7"
                        isEditable
                        editableOptions={{
                          type: 'multilineText',
                          value: expectation.content,
                          placeholder: __('Add content'),
                          onChange,
                          displayErrorMessage: false,
                          onFocus: () =>
                            updateErrors(errors =>
                              resetExpectationError(errors, expectation)
                            ),
                        }}
                      />
                    )}
                  />
                  {fieldErrorsFor('expectations', expectation.id, 'content')}
                </Column>
              ))}
            </Columns>
          </BoxListItem>
        </BoxList>
      </Flex>
    </Box>
  );
}
