import React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

const PeopleUserReviewEmptyState = () => {
  return (
    <EmptyStateWithIcon
      inBox
      iconName="equalizer"
      title={__('Start your people review by adding participants.')}
      description={__(
        'Click on the Add participants button in the top right-hand corner.'
      )}
    />
  );
};

export default PeopleUserReviewEmptyState;
