import React, { MouseEvent } from 'react';

import classNames from 'helpers/classNames';
import createBodyPortal from 'helpers/react/createBodyPortal';

import {
  ClickAwayListener,
  DesignSystem,
  Testable,
  WithFadeinFadeoutAnimation,
} from 'components';

export type Props = {
  children: React.ReactNode;
  onClose?: (e: MouseEvent<HTMLDivElement>) => void;
  isNarrow?: boolean;
  dropdownIsOpened?: boolean;
  style?: React.CSSProperties;
  popperRef?: React.Ref<HTMLDivElement>;
  uid?: string;
  additionalClassName?: string;
  contentClassName?: string;
};

export default function DropdownMenu({
  children,
  onClose,
  isNarrow,
  dropdownIsOpened,
  style,
  uid,
  popperRef,
  additionalClassName,
  contentClassName,
}: Props) {
  const handleClickAway = () => {
    if (onClose) {
      window.setTimeout(onClose);
    }
  };

  const className = classNames('dropdown-menu', additionalClassName, {
    'is-narrow': isNarrow,
    'is-opened': dropdownIsOpened,
    'pointer-events-none': !dropdownIsOpened,
  });

  return createBodyPortal(
    <ClickAwayListener enabled={dropdownIsOpened} onClickAway={handleClickAway}>
      <DesignSystem version={2}>
        <Testable name="test-dropdown-menu" uid={uid}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
          <div
            ref={popperRef}
            style={style}
            onClick={e => onClose && onClose(e)}
            className={className}
            role="menu"
          >
            <WithFadeinFadeoutAnimation
              duration={100}
              visible={!!dropdownIsOpened}
            >
              <div className={classNames('dropdown-content', contentClassName)}>
                {children}
              </div>
            </WithFadeinFadeoutAnimation>
          </div>
        </Testable>
      </DesignSystem>
    </ClickAwayListener>
  );
}
