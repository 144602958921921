import React from 'react';
import { Text } from 'recharts';

import type { DataPoint } from './index';

import colors from 'styles/colors';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

type Props = {
  x: number;
  y: number;
  payload: {
    value: number;
  };
  ratings: Array<string>;
  data: Array<DataPoint>;
  peerCount: number;
};

export default function CustomizedAxisTick({
  x,
  y,
  payload,
  ratings,
  data,
  peerCount,
}: Props) {
  const ratingIndex = payload.value;
  const rating = ratings[ratingIndex];
  const dataPoint = data.find(datum => datum.rating === rating);

  invariant(dataPoint, 'A datapoint for this value should exist');

  return (
    <React.Fragment>
      <Text
        x={x}
        y={y}
        textAnchor="middle"
        verticalAnchor="start"
        fontWeight="bold"
        style={{ fill: colors.text }}
      >
        {__('%1/%2 peers', dataPoint.count, peerCount)}
      </Text>
      <Text
        x={x}
        y={y + 20}
        textAnchor="middle"
        verticalAnchor="start"
        style={{ fill: colors.text }}
        width={110}
      >
        {rating}
      </Text>
    </React.Fragment>
  );
}
