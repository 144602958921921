import { merge, omit } from 'lodash';
import React from 'react';
import { compose } from 'redux';

import type { PaginationProps } from 'lib/dataLoader/pagination/types';
import type {
  AnswerCollection,
  DropdownQuestionBlock,
  LegacyQuestionBlock,
  MultipleScaleQuestionBlock,
  TextQuestionBlock,
  UserFilterSegment,
} from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import transformProps from 'helpers/transformProps';
import withLoadingOnViewportVisibility from 'helpers/withLoadingOnViewportVisibility';

import { newDataLoader } from 'lib/dataLoader';
import withStatePagination from 'lib/dataLoader/pagination/StatePaginationFactory';
import { get } from 'redux/actions/api';

import { BoxList, FetchContainer, FilterBar, PullRight } from 'components';
import DatatableWrapper from 'components/datatable/DatatableWrapper';

import AnswersList from './AnswersList';

type Props = {
  reviewCycleId: string;
  block:
    | LegacyQuestionBlock
    | MultipleScaleQuestionBlock
    | TextQuestionBlock
    | DropdownQuestionBlock;
  userFilter: UserFilterSegment | undefined | null;
  option?: string | null;
  criterion?: string;
  defaultFilter?: {
    [key: string]: boolean;
  };
};

type AfterTransformProps = Props & {
  filters: Array<{
    param: string;
    label: string;
  }>;
  activeFilter: {
    [key: string]: boolean;
  };
  optionFilter: {
    [key: string]: string;
  };
};

type AfterPaginateProps = AfterTransformProps & PaginationProps;

type AfterConnectProps = AfterPaginateProps & {
  answerCollection: AnswerCollection | undefined | null;
  isFetching: boolean;
  hasError: boolean;
};

function MockRenderer(originalProps: AfterPaginateProps) {
  return (
    <AnswersExplorer
      {...originalProps}
      isFetching={true}
      hasError={false}
      answerCollection={null}
    />
  );
}

function AnswersExplorer(props: AfterConnectProps) {
  const {
    isFetching,
    page,
    userFilter,
    countPerPage,
    getPreviousPage,
    getNextPage,
    answerCollection,
    hasError,
    onFilterChange,
    filter,
    filters,
  } = props;

  const collectionInfo = answerCollection
    ? omit(answerCollection, 'answers')
    : null;

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      loadingStyle="overlay"
      render={() => (
        <div className="answers-explorer">
          <DatatableWrapper
            collectionInfo={collectionInfo}
            page={page}
            countPerPage={countPerPage}
            getPreviousPage={getPreviousPage}
            getNextPage={getNextPage}
            userFilter={userFilter}
            isFetching={isFetching}
            hasError={hasError}
            onSearchChange={() => undefined}
            search=""
            filters={filters}
            filter={filter}
            onFilterChange={onFilterChange}
            renderHeader={({ filters, activeFilter, onFilterChange }) => {
              return (
                <div className="answers-header">
                  <PullRight style={{ textAlign: 'end' }}>
                    {!!filters && !!onFilterChange && (
                      <FilterBar
                        display="link"
                        leftText={__('Show')}
                        filters={filters}
                        activeFilter={activeFilter}
                        onFilterChange={onFilterChange}
                      />
                    )}
                  </PullRight>
                </div>
              );
            }}
          >
            <BoxList>
              <AnswersList
                answers={answerCollection ? answerCollection.items : []}
                isFetching={isFetching}
              />
            </BoxList>
          </DatatableWrapper>
        </div>
      )}
    />
  );
}

function propsTransformer({ block, option, criterion, defaultFilter }: Props) {
  const revieweeFeedbackEnabled =
    !!block.revieweeFeedbackOptions &&
    (option !== undefined
      ? block.revieweeFeedbackOptions.ratingEnabled
      : block.revieweeFeedbackOptions.textEnabled);
  const reviewerFeedbackEnabled =
    !!block.reviewerFeedbackOptions &&
    (option !== undefined
      ? block.reviewerFeedbackOptions.ratingEnabled
      : block.reviewerFeedbackOptions.textEnabled);

  let tabFilters =
    revieweeFeedbackEnabled && reviewerFeedbackEnabled
      ? [{ param: 'all', label: __('Everyone') }]
      : [];

  if (revieweeFeedbackEnabled) {
    tabFilters.push({ param: 'reviewee', label: __('Reviewee') });
  }

  if (reviewerFeedbackEnabled) {
    tabFilters.push({ param: 'reviewer', label: __('Reviewer') });
  }

  const firstTabFilterParam: string = tabFilters[0].param;
  const optionFilter: {
    rating_option?: string | null;
    rating_criterion?: string;
  } = {};
  if (!!option || option === null) optionFilter.rating_option = option;
  if (!!criterion) optionFilter.rating_criterion = criterion;

  return {
    optionFilter,
    filters: tabFilters,
    activeFilter: defaultFilter || { [firstTabFilterParam]: true },
  };
}

export default compose<React.ComponentType<Props>>(
  transformProps(propsTransformer),
  withStatePagination({
    defaultPaginationParams: ({ activeFilter }: AfterConnectProps) => ({
      countPerPage: 5,
      filter: activeFilter,
    }),
  }),
  withLoadingOnViewportVisibility<AfterPaginateProps>({
    delay: 500,
    mockClass: MockRenderer,
  }),
  newDataLoader({
    fetch: ({
      reviewCycleId,
      block,
      userFilter,
      page,
      countPerPage,
      filter,
      optionFilter,
    }: AfterConnectProps) =>
      get(`review_cycles/${reviewCycleId}/review_blocks/${block.id}/answers`, {
        page,
        countPerPage,
        userFilter,
        filter: merge(filter, optionFilter),
      }),
    hydrate: {
      answerCollection: {
        items: {
          author: {},
          reviewee: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      userFilter,
      block,
      filter,
      optionFilter,
    }: AfterConnectProps) =>
      compositeKey({
        page,
        countPerPage,
        userFilter,
        block,
        filter,
        optionFilter,
        view: 'answersExplorer',
      }),
  })
)(AnswersExplorer);
