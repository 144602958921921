import React, { UIEvent, useEffect, useLayoutEffect, useState } from 'react';
import { usePopper } from 'react-popper';

import type { ReactElement } from 'react';

import classNames from 'helpers/classNames';
import createBodyPortal from 'helpers/react/createBodyPortal';

import { ComponentUIDProvider, Testable } from 'components';

import { Props as DropdownMenuProps } from './DropdownMenu';
import { Props as DropdownTriggerProps } from './DropdownTrigger';

type Props = {
  children: [
    ReactElement<DropdownTriggerProps>,
    ReactElement<DropdownMenuProps> | undefined | null
  ];
  onClose?: (e: KeyboardEvent | UIEvent | undefined) => void;
  isOpened?: boolean;
  align?: 'right' | 'left';
  isHoverable?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  additionalClassName?: string;
};

export default function Dropdown({
  isOpened: isOpenedFromProps,
  additionalClassName,
  align,
  isHoverable,
  disabled,
  style,
  children,
  onClose,
}: Props): ReactElement {
  const [isOpened, setIsOpened] = useState(isOpenedFromProps || false);
  const [trigger, menu] = children;

  const openDropdown = () => {
    if (!isOpened && !disabled) {
      setIsOpened(true);
    }
  };

  const closeDropdown = (e: KeyboardEvent | UIEvent | undefined) => {
    if (isOpened) setIsOpened(false);
    onClose && onClose(e);
  };

  useEffect(() => {
    const onKeyPress = (event: KeyboardEvent) => {
      if (event.keyCode === 27 && isOpened) closeDropdown(event);
    };

    document.addEventListener('keydown', onKeyPress, false);

    return () => document.removeEventListener('keydown', onKeyPress, false);
  });

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const placement = align === 'left' ? 'bottom-start' : 'bottom-end';
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
    }
  );

  useLayoutEffect(() => {
    if (isOpened) {
      // We have to manually ask popper to update to correctly place
      // the popper element
      !!update && update();
    }
  }, [isOpened, update]);

  const renderTrigger = (uid: string) => {
    return React.cloneElement(trigger, {
      uid,
      popperRef: setReferenceElement,
      onClick: openDropdown,
      dropdownIsOpened: isOpened,
    });
  };

  const renderMenu = (uid: string) => {
    if (!menu) return null;

    const menuStyle = menu.props.style;
    const ownStyle = style;
    const appliedStyle = {
      ...(menuStyle || {}),
      ...(ownStyle || {}),
      ...styles.popper,
    };

    return createBodyPortal(
      React.cloneElement(menu, {
        ...attributes.popper,
        uid,
        popperRef: setPopperElement,
        onClose: closeDropdown,
        dropdownIsOpened: isOpened,
        style: appliedStyle,
      })
    );
  };

  const renderContent = (uid: string) => {
    if (disabled) {
      return renderTrigger(uid);
    } else {
      return (
        <React.Fragment>
          {renderTrigger(uid)}
          {renderMenu(uid)}
        </React.Fragment>
      );
    }
  };

  const className = classNames(
    'dropdown',
    additionalClassName,
    align && `is-${align}`,
    {
      'is-active': (!disabled && isOpenedFromProps) || isOpened,
      'is-hoverable': !disabled && isHoverable,
      'is-disabled': disabled,
    }
  );

  return (
    <ComponentUIDProvider
      render={uid => (
        <Testable name="test-dropdown" uid={uid}>
          <div className={className} style={style}>
            {renderContent(uid)}
          </div>
        </Testable>
      )}
    />
  );
}
