import React, { Fragment } from 'react';

import { __ } from 'helpers/i18n';

import { ContentContainer, PageHeader, PageTitle } from 'components/index';

import SurveyList from './SurveyList';

const ToComplete = () => (
  <Fragment>
    <PageTitle title={[__('Surveys'), __('To complete')]} />
    <PageHeader title={__('Surveys to complete')} />

    <ContentContainer>
      <SurveyList />
    </ContentContainer>
  </Fragment>
);

export default ToComplete;
