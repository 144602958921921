import React, { Fragment } from 'react';

import type { ThreeSixtyReviewTemplate } from 'models';

import { BoxSeparator } from 'components';

import AnonymousResults from './components/AnonymousResults';
import BlockReleaseStep from './components/BlockReleaseStep';
import EnableSelfEvaluation from './components/EnableSelfEvaluation';
import NominatePeers from './components/NominatePeers';
import TemplateName from './components/TemplateName';

type Props = {
  template: ThreeSixtyReviewTemplate;
  onFieldChange: (
    attributes: Partial<ThreeSixtyReviewTemplate>
  ) => Promise<void>;
  settingsDisabled: boolean;
};

const ThreeSixtyTemplateSettings = ({
  template,
  onFieldChange,
  settingsDisabled,
}: Props) => {
  return (
    <Fragment>
      <TemplateName
        name={template.actualName}
        onChange={name => onFieldChange({ name })}
        templateId={template.id}
      />

      <BoxSeparator style={{ margin: '8px 0' }} />

      <NominatePeers
        disabled={settingsDisabled}
        hasNominatePeersStepEnabled={template.hasNominatePeersStepEnabled}
        onChange={hasNominatePeersStepEnabled =>
          onFieldChange({ hasNominatePeersStepEnabled })
        }
        templateId={template.id}
      />

      <BoxSeparator style={{ margin: '8px 0' }} />

      <EnableSelfEvaluation
        disabled={settingsDisabled}
        hasSelfEvaluationEnabled={template.hasSelfEvaluationEnabled}
        showRevieweeEvaluationToReviewers={
          template.showRevieweeEvaluationToReviewers
        }
        onChange={onFieldChange}
        interactionType="360"
        templateId={template.id}
      />

      <BoxSeparator style={{ margin: '8px 0' }} />

      <AnonymousResults
        disabled={settingsDisabled}
        hasAnonymizedResults={template.hasAnonymizedResults}
        onChange={hasAnonymizedResults =>
          onFieldChange({ hasAnonymizedResults })
        }
        templateId={template.id}
      />

      <BoxSeparator style={{ margin: '8px 0' }} />

      <BlockReleaseStep
        disabled={settingsDisabled}
        hasReleaseStepEnabled={template.hasReleaseStepEnabled}
        onChange={onFieldChange}
        templateId={template.id}
      />
    </Fragment>
  );
};
export default ThreeSixtyTemplateSettings;
