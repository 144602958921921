// @flow
import * as React from 'react';

import type { PaginationType } from './types';
import type { PaginationSearchParams } from 'lib/dataLoader/pagination/types';

import { getDisplayName } from 'helpers/hoc';
import invariant from 'helpers/invariant';

import StatePaginationFactory from './StatePaginationFactory';
import UrlPaginationFactory from './UrlPaginationFactory';

export type WithPaginationProps = {|
  paginationType: PaginationType,
  defaultPaginationParams?: $Shape<PaginationSearchParams>,
|};

type Props = {|
  ...any,
  ...WithPaginationProps,
|};

type State = {|
  isInitialized: boolean,
|};

/**
 * @deprecated This HOC is deprecated and will be removed in future releases.
 * Please use the new pagination HOC instead.
 */

export default function paginationFactory(
  WrappedComponent: React.ComponentType<*>
) {
  class Paginator extends React.Component<Props, State> {
    PaginatedComponent = null;

    state = {
      isInitialized: false,
    };

    componentDidMount() {
      const { paginationType, defaultPaginationParams }: WithPaginationProps =
        this.props;

      invariant(
        paginationType === 'state' || paginationType === 'url',
        "Pagination type should be 'state' or 'url'"
      );

      switch (paginationType) {
        case 'state':
          this.PaginatedComponent = StatePaginationFactory({
            defaultPaginationParams: () => defaultPaginationParams,
          })(WrappedComponent);
          break;
        case 'url':
          this.PaginatedComponent = UrlPaginationFactory({
            defaultPaginationParams: () => defaultPaginationParams,
          })(WrappedComponent);
          break;
        default:
          throw new Error("Pagination type should be 'state' or 'url'");
      }

      this.setState({ isInitialized: true });
    }

    render() {
      const { paginationType, defaultPaginationParams, ...otherProps } =
        this.props;
      const PaginatedComponent = this.PaginatedComponent;

      if (!this.state.isInitialized) return null;

      invariant(
        PaginatedComponent,
        'PaginatedComponent is defined once initialized'
      );

      return <PaginatedComponent {...otherProps} />;
    }
  }

  Paginator.displayName = `withPagination(${getDisplayName(WrappedComponent)})`;
  return Paginator;
}
