const teamListEmptyStateSvg = `
<svg width="459px" height="196px" viewBox="0 0 459 196" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-447.000000, -334.000000)" fill="#E5E9EA">
            <g id="27825" transform="translate(540.000000, 349.000000)">
                <g id="Group" transform="translate(136.500000, 83.500000) scale(-1, 1) rotate(-180.000000) translate(-136.500000, -83.500000) ">
                    <path d="M129.073255,166.53417 C119.320059,164.970313 110.59587,159.779637 106.121927,152.958557 C103.303343,148.566447 102.721731,146.769675 102.721731,141.911735 C102.721731,137.552899 103.258604,135.556485 105.316618,131.929667 C107.150934,128.702132 109.432645,126.339709 113.369715,123.578004 C124.196657,115.891811 140.034415,114.494322 153.456244,119.984459 C155.3353,120.783025 157.885447,122.313608 160.480334,124.243475 C163.97001,126.872086 164.820059,127.737199 166.833333,130.765093 C171.173058,137.353258 171.620452,144.141064 168.175516,150.795776 C164.104228,158.714883 154.619469,164.837219 143.792527,166.53417 C140.034415,167.133094 132.741888,167.133094 129.073255,166.53417 Z" id="Path"></path>
                    <path d="M68.0486726,132.761506 C57.0427729,131.630205 48.7212389,124.509663 48.6317601,116.124726 C48.5422812,111.266786 50.7345133,107.307233 55.2531957,103.979876 C59.9060964,100.519426 64.6484759,99.0553895 71.1804326,99.022116 C77.7571288,98.9555688 83.0811209,100.585973 87.5998033,104.01315 C92.252704,107.540147 94.2212389,111.200239 94.1317601,116.124726 C94.0422812,120.982666 92.0737463,124.542937 87.5550639,127.903566 C82.4100295,131.730026 75.2964602,133.493525 68.0486726,132.761506 Z" id="Path"></path>
                    <path d="M198.240413,132.761506 C193.363815,132.229129 189.158309,130.631998 185.444936,127.903566 C180.926254,124.542937 178.957719,120.982666 178.86824,116.124726 C178.778761,111.166966 180.836775,107.340506 185.444936,103.979876 C190.187316,100.452879 194.884956,99.022116 201.59587,99.022116 C208.306785,99.022116 213.004425,100.452879 217.746804,103.979876 C222.265487,107.307233 224.3235,111.067145 224.36824,115.958358 C224.412979,126.339709 212.154376,134.225543 198.240413,132.761506 Z" id="Path"></path>
                    <path d="M22.1907571,111.832437 C17.179941,110.867503 12.8849558,108.139072 11.2295969,104.911536 C10.1111111,102.715481 9.88741396,99.4546722 10.7374631,97.2586173 C11.8559489,94.2307233 16.5088496,90.6371787 20.4011799,89.7387926 C21.3854474,89.5391512 23.8013766,89.3395099 25.7699115,89.3395099 C28.8121927,89.3395099 29.7517207,89.4726041 31.9886922,90.3044431 C37.3126844,92.2343096 40.399705,95.7280335 40.6681416,100.153417 C40.9813176,104.911536 37.5811209,109.004184 31.7202557,110.967324 C28.8121927,111.932257 24.6514258,112.33154 22.1907571,111.832437 Z" id="Path"></path>
                    <path d="M243.650934,111.699342 C238.550639,110.434947 234.837266,107.906157 233.181908,104.612074 C232.376598,103.048217 232.19764,102.149831 232.331858,100.153417 C232.600295,95.7280335 235.732055,92.2343096 241.100787,90.2711696 C243.248279,89.4726041 244.232547,89.3395099 247.230088,89.3395099 C249.198623,89.3395099 251.614553,89.5391512 252.59882,89.7387926 C256.49115,90.6371787 261.144051,94.2307233 262.262537,97.2586173 C263.783677,101.184897 262.307276,105.676828 258.593904,108.505081 C254.791052,111.39988 248.214356,112.830644 243.650934,111.699342 Z" id="Path"></path>
                    <path d="M128.6706,109.43674 C121.467552,108.471807 115.248771,106.841403 109.880039,104.512253 C96.1897738,98.6228332 86.4813176,88.1749352 83.8864307,76.5291891 C83.394297,74.2333134 83.2600787,66.9464037 83.2600787,36.867105 L83.2600787,0 L136.5,0 L189.739921,0 L189.739921,36.7007372 C189.739921,76.3960948 189.739921,75.9968121 187.279253,82.0526001 C183.207965,92.0346683 172.873156,100.951982 159.853982,105.776649 C155.916912,107.240685 149.966568,108.671448 145.224189,109.270373 C140.884464,109.836023 132.339233,109.90257 128.6706,109.43674 Z" id="Path"></path>
                    <path d="M65.1853491,91.8350269 C56.7743363,90.7037258 48.2291052,86.9438135 42.8603736,82.0193266 C39.8628319,79.257621 36.5073746,74.033672 35.5231072,70.5399482 C34.8072763,67.9446105 34.7177974,65.482367 34.7177974,41.7583184 L34.7177974,15.8049412 L54.7163225,15.7051205 L74.7148476,15.6385734 L74.8938053,45.8176928 C75.072763,78.658697 74.9832842,77.294481 77.66765,83.4500897 C78.3387414,84.9806734 79.4572271,87.210002 80.173058,88.3745766 C80.8888889,89.5391512 81.4705015,90.6039052 81.4705015,90.770273 C81.4705015,91.6353855 69.5250737,92.433951 65.1853491,91.8350269 Z" id="Path"></path>
                    <path d="M197.793019,91.9681211 C194.348083,91.5688384 191.529499,91.0364614 191.529499,90.770273 C191.529499,90.6371787 192.111111,89.5058777 192.826942,88.341303 C194.258604,86.0121538 195.958702,82.0858737 197.166667,78.3259613 C197.837758,76.2962742 197.971976,72.6361825 198.106195,45.8176928 L198.285152,15.6385734 L218.283677,15.7051205 L238.282203,15.8049412 L238.282203,41.7583184 C238.282203,65.2827256 238.192724,67.977884 237.476893,70.5399482 C236.537365,74.0003985 233.181908,79.2243475 230.139626,82.0193266 C224.636676,87.0436342 216.046706,90.8035465 207.546214,91.8350269 C204.817109,92.1344889 200.119469,92.2010361 197.793019,91.9681211 Z" id="Path"></path>
                    <path d="M21.7433628,82.3187886 C15.3456244,81.3205818 11.7217306,79.9230922 7.60570305,76.8619247 C4.65290069,74.6991433 2.99754179,72.7360032 1.2079646,69.2090058 C0.313176008,67.4455071 0.268436578,66.7134887 0.134218289,48.146842 L0,28.9479976 L13.019174,29.0145447 L26.0383481,29.1143654 L25.9936087,46.2502491 C25.9936087,55.6666667 26.1725664,64.9167165 26.4410029,66.8133094 C27.0226155,71.3385136 28.2753196,74.9653317 30.4675516,78.425782 C31.4518191,79.9896394 32.2571288,81.3871289 32.2571288,81.5534967 C32.2571288,82.2189679 24.6961652,82.7513449 21.7433628,82.3187886 Z" id="Path"></path>
                    <path d="M242.666667,82.1524208 C241.59292,81.9527794 240.742871,81.686591 240.742871,81.5534967 C240.742871,81.3871289 241.548181,79.9896394 242.532448,78.425782 C244.72468,74.9653317 245.977384,71.3385136 246.558997,66.8133094 C246.827434,64.9167165 247.006391,55.6666667 247.006391,46.2502491 L246.961652,29.1143654 L259.980826,29.0145447 L273,28.9479976 L272.865782,48.146842 C272.731563,66.7134887 272.686824,67.4455071 271.792035,69.2090058 C270.002458,72.7360032 268.347099,74.6991433 265.394297,76.8619247 C260.42822,80.5552899 255.372665,82.1856944 248.348574,82.3853357 C246.245821,82.4518828 243.695674,82.3520622 242.666667,82.1524208 Z" id="Path"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
`;

export default teamListEmptyStateSvg;
