import * as React from 'react';

import type { RouteComponentProps } from 'react-router-dom';

import { Objective } from 'models';

import { __ } from 'helpers/i18n';
import { pathToTeamObjectives } from 'helpers/paths';

import {
  ContentContainer,
  DesignSystem,
  PageHeader,
  Redirect,
  Route,
  Switch,
} from 'components';
import RightSidebar from 'components/RightSidebar';

import ObjectiveVersions from '../ObjectiveVersions';
import { ObjectiveContext } from '../objectiveContext';
import ObjectivePeriodRoutes from './ObjectivePeriodRoutes';
import OrganizationPeriodCreator from './OrganizationPeriodCreator';

export default function TeamRoutes(props: RouteComponentProps) {
  const { path } = props.match;
  const [objective, setObjective] = React.useState<Objective | null>(null);

  return (
    <DesignSystem version={2}>
      <PageHeader title={__('Team objectives')} />

      <ObjectiveContext.Provider value={{ objective, setObjective }}>
        <ContentContainer>
          <Switch>
            <Route
              exact
              path={`${path}`}
              component={OrganizationPeriodCreator}
            />
            <Route
              path={`${path}/:objectivePeriodSlug`}
              component={ObjectivePeriodRoutes}
            />
            <Redirect to={pathToTeamObjectives()} />
          </Switch>
        </ContentContainer>

        <RightSidebar
          title={objective?.title || ''}
          isOpen={!!objective}
          onClose={() => setObjective(null)}
        >
          {!!objective && <ObjectiveVersions objective={objective} />}
        </RightSidebar>
      </ObjectiveContext.Provider>
    </DesignSystem>
  );
}
