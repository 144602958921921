import React, { useState } from 'react';

import { FormErrors, handleFormErrors } from 'helpers/api';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { navigate, pathToMatrixEditor } from 'helpers/navigation';

import { post } from 'redux/actions/api';

import {
  Button,
  Field,
  FieldError,
  Input,
  Label,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import useManageableTextList from 'scenes/skills/helpers/useManageableTextList';

type Props = {
  onClose: () => void;
};

const MAX_LEVELS_COUNT = 10;

const CreationModal = ({ onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<FormErrors>();

  const [matrixTitle, setMatrixTitle] = useState('');

  const { items, areItemsUnique, renderItems } = useManageableTextList({
    initialPlaceholders: [
      __('ex: Junior'),
      __('ex: Intermediate'),
      __('ex: Senior'),
    ],
  });

  const createMatrix = () =>
    handleFormErrors(async () => {
      const { response } = await dispatch(
        post('/skills/matrices', {
          matrix: {
            title: matrixTitle,
            levelsAttributes: items.filter(item => item.title !== ''),
          },
        })
      );

      navigate(pathToMatrixEditor(response.body.data.id));
    }, setErrors);

  return (
    <ModalCard
      isActive
      onClose={onClose}
      isLarge
      refreshContentOnOpening
      additionalClassName="skills-form-creation-modal"
    >
      <ModalCardHead>
        <ModalCardTitle>{__('Create a skills matrix')}</ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody>
        <Field>
          <Label>{__('Skills matrix title')}</Label>
          <Input
            value={matrixTitle}
            placeholder={__('ex: Product Manager')}
            onChange={setMatrixTitle}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          <FieldError>{errors?.title}</FieldError>
        </Field>

        <Field>
          <Label>{__('Skills matrix levels')}</Label>

          <div className="mb-4">
            {__(
              "Levels allow you to assess team members' skills in each skill you add to the skills matrix. To learn more about skills matrices, %1.",
              <a href="https://docs.elevo.fr/">{__('visit our help center')}</a>
            )}
          </div>

          {renderItems({
            placeholder: __('ex: Senior'),
            newItemPlaceholder: __('Add a level to the matrix...'),
            maxItems: MAX_LEVELS_COUNT,
          })}
          <FieldError>{errors?.levelsTitle}</FieldError>
          <FieldError>{errors?.levels}</FieldError>
          {!areItemsUnique() && (
            <FieldError>{__('Levels must be unique')}</FieldError>
          )}
        </Field>
      </ModalCardBody>

      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Cancel')}
        </Button>
        <Button
          color="primary"
          disabled={!areItemsUnique()}
          onClick={createMatrix}
        >
          {__('Create the skills matrix')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default CreationModal;
