import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { UserObjectivePeriod } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import compositeKey from 'helpers/compositeKey';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, put } from 'redux/actions/api';

import { Column, Columns, FetchContainer } from 'components';

import PersonalObjectiveSections from '../PersonalObjectiveSections';
import {
  default as PersonalObjectivesList,
  PersonalObjectivesListPlaceholder,
} from './PersonalObjectivesList';
import {
  default as PersonalObjectivesSummary,
  PersonalObjectivesSummaryPlaceholder,
} from './PersonalObjectivesSummary';

type Props = {
  userObjectivePeriodId: string;
  onCreate: (userObjectivePeriod: UserObjectivePeriod) => Promise<void>;
  afterUpdate?: () => Promise<void>;
  footer?: ReactNode;
  revieweeFullName?: string;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    userObjectivePeriod: UserObjectivePeriod;
    publishObjectives: () => Promise<void>;
  };

function PersonalObjectivesDetails({
  isFetching,
  hasError,
  userObjectivePeriod,
  onCreate,
  publishObjectives,
  footer,
  revieweeFullName,
}: AfterDataLoaderProps) {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      loadingStyle="none"
      render={() => {
        if (isFetching) {
          return (
            <Columns>
              <Column size={8}>
                <PersonalObjectivesListPlaceholder />
                {footer}
              </Column>

              <Column hideOn="mobile" size={4}>
                <PersonalObjectivesSummaryPlaceholder />
              </Column>
            </Columns>
          );
        }

        const { publishedObjectives, draftObjectives } =
          userObjectivePeriod.objectiveCollection;

        return (
          <Columns>
            <Column size={8}>
              <PersonalObjectiveSections
                publishedItems={publishedObjectives}
                draftItems={draftObjectives}
                objectiveCollection={userObjectivePeriod.objectiveCollection}
                onPublishClick={() => publishObjectives()}
                // @ts-ignore TSFIXME: Fix strictNullChecks error
                revieweeFullName={revieweeFullName}
                renderItems={objectives => (
                  <PersonalObjectivesList
                    objectives={objectives}
                    userObjectivePeriod={userObjectivePeriod}
                    onCreate={onCreate}
                    revieweeFullName={revieweeFullName}
                  />
                )}
              />
              {footer}
            </Column>

            <Column hideOn="mobile" size={4}>
              <PersonalObjectivesSummary
                userObjectivePeriod={userObjectivePeriod}
              />
            </Column>
          </Columns>
        );
      }}
    />
  );
}

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { userObjectivePeriodId }: Props
) => {
  return {
    publishObjectives: () =>
      dispatch(
        put(
          `user_objective_periods/${userObjectivePeriodId}/objectives/bulk_publish_objectives`,
          { userObjectivePeriodId }
        )
      ),
  };
};

export default compose(
  newDataLoader({
    fetch: ({ userObjectivePeriodId }: Props) =>
      get(`user_objective_periods/${userObjectivePeriodId}`),
    hydrate: {
      userObjectivePeriod: {
        abilities: {},
        userReviews: {
          abilities: {},
          evaluations: {},
        },
        objectiveCollection: {
          abilities: {},
          publishedObjectives: {
            keyResults: {},
            abilities: {},
          },
          draftObjectives: {
            keyResults: {},
            abilities: {},
          },
        },
      },
    },
    cacheKey: ({ userObjectivePeriodId }) =>
      compositeKey({ userObjectivePeriodId }),
  }),
  connect(null, mapDispatchToProps)
)(PersonalObjectivesDetails) as React.ComponentType<Props>;
