import React from 'react';

import { __ } from 'helpers/i18n';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

type Props = {
  onClose: () => void;
  children: React.ReactNode;
  isActive?: boolean;
};

export default function AnswerModal({ onClose, children, isActive }: Props) {
  return (
    <ModalCard
      isActive={isActive}
      onClose={onClose}
      isLarge
      refreshContentOnOpening={false}
    >
      <ModalCardHead>
        <ModalCardTitle>{__('Comment details')}</ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody>{children}</ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Close')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
}
