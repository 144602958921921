import React from 'react';
import Sticky from 'react-stickynode';

import type {
  Organization,
  ReviewCycle,
  ReviewTemplate,
  UserFilterSegment,
} from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { withOrganization } from 'helpers/withSessionProps';

import {
  Box,
  DesignSystem,
  Divider,
  Flex,
  Level,
  LevelLeft,
  LevelRight,
  PageTitle,
  RawHtml,
  Text,
} from 'components';

import ReviewTemplatePicker from 'scenes/components/ReviewTemplatePicker';
import UserFilter from 'scenes/components/UserFilter';

import FilterText from './FilterText';
import TemplateResults from './TemplateResults';

type Props = {
  reviewCycle: ReviewCycle;
  defaultUserFilter?: UserFilterSegment;
};

type AfterConnectProps = Props & {
  organization: Organization;
};

const ReviewCycleResults = ({
  reviewCycle,
  organization,
  defaultUserFilter,
}: AfterConnectProps) => {
  const [template, setTemplate] = React.useState<ReviewTemplate>(
    reviewCycle.defaultTemplate
  );
  const [userFilter, setUserFilter] = React.useState<
    UserFilterSegment | undefined | null
  >(defaultUserFilter);

  if (organization.featureFlags.includes('fakeResultView')) {
    return (
      <div style={{ textAlign: 'center' }}>
        <PageTitle title={[reviewCycle.name, __('Results')]} />

        <RawHtml // FIXME: RawHtml should not be used, this is a legacy case so we let it
          componentType="div"
          html={process.env.REACT_APP_RESULT_VIEW_HTML}
        />
      </div>
    );
  }

  const { templates } = reviewCycle;

  invariant(templates, 'Templates must be passed here');

  return (
    <DesignSystem version={2}>
      <div className="review-cycle-results">
        <React.Fragment>
          <Sticky enabled={true} top={0} innerZ={100}>
            <div className="results-filters">
              <Box>
                {templates.length > 1 && (
                  <React.Fragment>
                    <Flex style={{ justifyContent: 'flex-end' }} verticalAlign>
                      <Text preset="14s6">
                        {__('Template of questions:')}
                        &nbsp;
                      </Text>

                      <div style={{ width: 405 }}>
                        <ReviewTemplatePicker
                          reviewTemplate={template}
                          reviewTemplates={templates}
                          onChange={template =>
                            !!template && setTemplate(template)
                          }
                          placeholder={__('Default template')}
                        />
                      </div>
                    </Flex>
                    <Divider style={{ margin: '16px 0' }} />
                  </React.Fragment>
                )}

                <Level>
                  <LevelLeft additionalClassName="filter-text">
                    <FilterText
                      reviewCycleId={reviewCycle.id}
                      userFilter={userFilter}
                      templateId={template.id}
                    />
                  </LevelLeft>
                  <LevelRight>
                    <UserFilter segment={userFilter} onChange={setUserFilter} />
                  </LevelRight>
                </Level>
              </Box>
            </div>
          </Sticky>

          <div className="review-content" style={{ height: 'auto' }}>
            <TemplateResults
              templateId={template.id}
              reviewCycleId={reviewCycle.id}
              userFilter={userFilter}
            />
          </div>
        </React.Fragment>
      </div>
    </DesignSystem>
  );
};

export default withOrganization(ReviewCycleResults);
