import React from 'react';

import { EmptyState } from 'components';

import emptyStateImg from 'scenes/admin/EntityIndex/emptystate.png';

type Props = { title: string };

const IntegrationEmptyState = ({ title }: Props) => (
  <EmptyState src={emptyStateImg} title={title} />
);

export default IntegrationEmptyState;
