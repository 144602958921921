import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import {
  CheckboxSwitch,
  Column,
  Columns,
  Flex,
  PageTitle,
  SupportLink,
  Text,
  Title,
} from 'components';

import threeSixtySvg from 'scenes/admin/components/svg/threeSixtySvg';

const ThreeSixtySettings = () => {
  const organization = useOrganization();
  const title = __('360 Feedback');

  return (
    <div className="bg-white rounded p-5 max-w-[1000px] mx-auto mt-8 md:mt-0">
      <PageTitle title={[title, __('Settings')]} />

      {organization.plan.is360Enabled ? (
        <Columns contentVerticallyAligned>
          <Column>
            <Flex verticalAlign additionalClassName="mb-2">
              <Title size={5} weight="semibold" additionalClassName="mr-5">
                {title}
              </Title>
              <Text color="success">{__('Enabled_masculine')}</Text>
            </Flex>
            <Text>
              {__('The product “360 Feedback” is enabled on your platform.')}
            </Text>
          </Column>
          <Column isNarrow>
            <CheckboxSwitch disabled value onChange={() => {}} size="small" />
          </Column>
        </Columns>
      ) : (
        <React.Fragment>
          <Flex direction="column" verticalAlign horizontalAlign>
            <img
              style={{ flexShrink: 0, height: 160, width: 160 }}
              src={`data:image/svg+xml;base64,${window.btoa(threeSixtySvg)}`}
              alt={title}
            />

            <Title size={4} additionalClassName="!my-4">
              {title}
            </Title>
            <Text align="left">
              {__(
                'With 360 feedback you can create a campaign where each participant will receive feedback from several others persons in your organization.' +
                  '360 feedback can be used in preparation of a review to provide more info to the reviewer or outside of a review period to provide development pointer to the participant. ' +
                  'It is also possible to conduct manager surveys with a 360 feedback.'
              )}
            </Text>
          </Flex>

          <Flex horizontalAlign direction="column" additionalClassName="mt-4">
            <SupportLink
              className="button is-primary"
              prefilledSubjectToSupport={__('Learn more about 360 templates')}
            >
              {__('Contact an Elevo expert to enable this product')}
            </SupportLink>
          </Flex>
        </React.Fragment>
      )}
    </div>
  );
};

export default ThreeSixtySettings;
