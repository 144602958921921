import React, { type CSSProperties } from 'react';

import type { NavigationItem } from 'components/navigation/Tabs/NavigationList';

import { viewPortAware } from 'components';

import { default as MobileVersion } from './Mobile';
import { default as TabletVersion } from './Tablet';

export type Props = {
  tabItems?: Array<NavigationItem>;
  backButton?: React.ReactNode;
  statusInfo?: React.ReactNode;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  style?: CSSProperties;
  hasCenteredContent?: boolean;
};

export default viewPortAware({
  mobile: MobileVersion,
  tablet: TabletVersion,
});
