import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'helpers/classNames';
import invariant from 'helpers/invariant';

import { Icon } from 'components';

type Props = {
  previousPageLink?: string;
  getPreviousPage?: () => void;
  isFetching: boolean;
  hasPrevious: boolean;
  isPrimary: boolean;
};

export default function PreviousPageButton({
  previousPageLink,
  getPreviousPage,
  isFetching,
  hasPrevious,
  isPrimary,
}: Props) {
  invariant(
    previousPageLink || getPreviousPage,
    'Either getPreviousPage or previousPageLink props should be passed down to component'
  );

  if (previousPageLink) {
    return (
      <Link
        className={classNames('button is-small is-outlined', {
          'is-primary': isPrimary,
        })}
        // TSFIXME: Link (and anchor) doesn't have a disabled prop but we have
        // a CSS rule .button[disabled] that does the trick.
        // Ideally, we could use only use the CSS rule '.button.disabled' or
        // use our Link or Button component here
        // @ts-ignore
        disabled={isFetching || !hasPrevious}
        to={previousPageLink}
        onClick={e => (isFetching || !hasPrevious) && e.preventDefault()}
      >
        <Icon size="small" name="arrow_back" />
      </Link>
    );
  } else if (getPreviousPage) {
    return (
      <button
        className={classNames('button is-small is-outlined', {
          'btn-default': !isPrimary,
          'is-primary': isPrimary,
        })}
        disabled={isFetching || !hasPrevious}
        onClick={e => {
          e.preventDefault();
          if (isFetching || !hasPrevious) return;
          getPreviousPage && getPreviousPage();
        }}
      >
        <Icon size="small" name="arrow_back" />
      </button>
    );
  } else {
    return null;
  }
}
