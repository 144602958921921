import {
  BreadcrumbAnchorName,
  default as breadcrumb,
} from 'helpers/breadcrumb';

type Props = {
  name: BreadcrumbAnchorName;
  defaultTo?: string;
};

export default function BreadcrumbAnchor({ name, defaultTo }: Props) {
  if (defaultTo) {
    breadcrumb.setDefaultAnchor(name, defaultTo);
    return null;
  }

  breadcrumb.setAnchor(name, window.location.href);
  return null;
}
