import React from 'react';

import type { BudgetItem } from 'models';

import { __ } from 'helpers/i18n';

import { Button, WithSavingStatusRecorder } from 'components';

import MoneyInput from 'scenes/admin/components/MoneyInput';

type Props = {
  budgetItem: BudgetItem | undefined | null;
  defaultCurrency: string;
  onUpdate: (budgetItem: Partial<BudgetItem>) => Promise<void>;
};

const BudgetItemAmountInput = ({
  budgetItem,
  defaultCurrency,
  onUpdate,
}: Props) => {
  const [budgetInputIsActive, setBudgetInputIsActive] = React.useState(
    budgetItem?.provisionedAmountCents ? true : false
  );

  const amount = budgetItem?.provisionedAmountCents;
  const currency = budgetItem?.provisionedAmountCurrency || defaultCurrency;

  const handleUpdate = (provisionedAmountCents: number | null) =>
    onUpdate({ provisionedAmountCents });

  if (budgetInputIsActive)
    return (
      <WithSavingStatusRecorder
        onChange={handleUpdate}
        render={autoSavingOnchange => (
          <MoneyInput
            testClassName="test-budget-item-money-input"
            placeholder={__('Amount')}
            amount={amount}
            currency={currency}
            onAmountChange={provisionedAmountCents => {
              autoSavingOnchange(provisionedAmountCents);
            }}
            saveOnlyOnBlur
          />
        )}
      />
    );

  return (
    <Button
      testClassName="test-define-budget-item-button"
      color="secondary"
      onClick={() => {
        setBudgetInputIsActive(!budgetInputIsActive);
      }}
    >
      <span>{__('Define a budget')}</span>
    </Button>
  );
};

export default BudgetItemAmountInput;
