import { keys, values } from 'lodash';
import React from 'react';

import type { ViewPortConstraint } from 'components/layout';

import { getDisplayName } from 'helpers/hoc';

import { constraintToViewPortMapping } from 'components';

type ComponentPerViewPortConditions<T> = {
  // eslint-disable-next-line no-unused-vars
  [k in ViewPortConstraint]?: React.ComponentType<T>;
};

export default function viewPortAware<T>(
  componentPerViewPortConditions: ComponentPerViewPortConditions<T>
) {
  class ViewPortAwareWrapper extends React.Component<T> {
    render() {
      const viewPortConditions = keys(
        componentPerViewPortConditions
      ) as Array<ViewPortConstraint>;

      return (
        <React.Fragment>
          {viewPortConditions.map((viewPortConstraint: ViewPortConstraint) => {
            const ViewPortComponent =
              constraintToViewPortMapping[viewPortConstraint];
            const MatchingComponent =
              componentPerViewPortConditions[viewPortConstraint];

            return (
              <ViewPortComponent key={viewPortConstraint}>
                {/** @ts-ignore TSFIXME: Fix strictNullChecks error **/}
                <MatchingComponent {...this.props} />
              </ViewPortComponent>
            );
          })}
        </React.Fragment>
      );
    }

    static displayName = `ViewPortAware(${getDisplayName(
      values(componentPerViewPortConditions)[0]
    )})`;
  }

  return ViewPortAwareWrapper;
}
