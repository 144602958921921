import React from 'react';

import { Button, Dropdown, DropdownTrigger, Icon, MenuList } from 'components';

type Props = {
  align?: 'right' | 'left';
  children?: React.ReactElement<React.ComponentProps<typeof MenuList>>;
  disabled?: boolean;
  text: string;
};

export default function ButtonMenu({ align, children, disabled, text }: Props) {
  const child = !!children ? React.Children.only(children) : children;

  return (
    <Dropdown align={align || 'right'} disabled={disabled}>
      <DropdownTrigger
        render={() => (
          <Button color="secondary" disabled={!!disabled}>
            <span>{text}</span>
            <Icon name="keyboard_arrow_down" />
          </Button>
        )}
      />
      {child}
    </Dropdown>
  );
}
