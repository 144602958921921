import React, { Fragment, useContext } from 'react';

import { DataContext } from 'scenes/skills/Index/MatrixVisualizer';

import { type BlockType } from '..';
import AreaMappingBoxListItem from './AreaMappingBoxListItem';
import BlockSeparator from './BlockSeparator';
import DomainSectionWrapper from './DomainSectionWrapper';

type Props = {
  block: BlockType;
  nextBlock: BlockType | null;
  renderDropdownButton: (params: { position: number }) => React.ReactNode;
};

const BlockRenderer = ({ block, nextBlock, renderDropdownButton }: Props) => {
  const { editionMode } = useContext(DataContext);
  const shouldRenderBlockSeparator =
    !!nextBlock && !!block.domain && !nextBlock.domain;

  const renderAreaMappings = () => (
    <Fragment>
      {block.mappings.map(mapping => (
        <Fragment key={`skills-matrix-area-${mapping.id}`}>
          <AreaMappingBoxListItem areaMapping={mapping} />

          {editionMode &&
            !block.domain &&
            renderDropdownButton({ position: mapping.position + 1 })}
        </Fragment>
      ))}
      {shouldRenderBlockSeparator && <BlockSeparator />}
    </Fragment>
  );

  if (!block.domain) {
    return renderAreaMappings();
  }

  return (
    <DomainSectionWrapper
      renderLastDropdownButton={() =>
        editionMode
          ? renderDropdownButton({
              position: (block.mappings.at(-1)?.position || 0) + 1,
            })
          : null
      }
      domain={block.domain}
    >
      {renderAreaMappings()}
    </DomainSectionWrapper>
  );
};

export default BlockRenderer;
