import React, { useState } from 'react';

import type { PeopleReviewRatingOption } from 'models';

import { __ } from 'helpers/i18n';

import { AutoSave, Textarea } from 'components';

import ClosedAnswer from 'scenes/peopleReview/PeopleUserReview/components/FeedbackZone/ClosedAnswer';

import RatingOptionSelect from './RatingOptionSelect';

type Props = {
  ratingValue?: string | null | undefined;
  ratingOptions?: Array<PeopleReviewRatingOption>;
  comment: string | null | undefined;
  readOnly: boolean;
  persistAction: (
    rating: string | null | undefined,
    comment: string | null | undefined
  ) => Promise<void>;
};

const FeedbackZone = ({
  ratingValue: ratingValueFromProps,
  ratingOptions,
  comment: commentFromProps,
  readOnly,
  persistAction,
}: Props) => {
  const [ratingValue, setRatingValue] = useState<string | null | undefined>(
    ratingValueFromProps
  );
  const [comment, setComment] = useState<string | null | undefined>(
    commentFromProps
  );
  const onPersist = () => persistAction(ratingValue, comment);

  if (readOnly) {
    const selectedRatingOption =
      ratingOptions?.find(option => option.value === ratingValue) || null;

    return (
      <ClosedAnswer
        ratingLabel={selectedRatingOption?.label}
        comment={comment}
      />
    );
  }

  return (
    <div className="feedback-zone">
      <div className="answer is-opened">
        {ratingOptions && (
          <div className="answer-rating-input test-people-user-review-rating-select">
            <AutoSave
              fieldUid="ratingValue"
              onChange={setRatingValue}
              doPersist={onPersist}
              render={autoSavingOnChange => (
                <RatingOptionSelect
                  value={ratingValue || null}
                  options={ratingOptions}
                  onChange={autoSavingOnChange}
                />
              )}
            />
          </div>
        )}

        <div className="answer-text-input">
          <AutoSave
            fieldUid="comment"
            onChange={setComment}
            doPersist={onPersist}
            render={autoSavingOnChange => (
              <Textarea
                value={comment || ''}
                placeholder={__('Leave a comment here…')}
                onChange={autoSavingOnChange}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default FeedbackZone;
