import React, { CSSProperties } from 'react';

import { ComponentUIDProvider } from 'components';

import BulmaCheckboxSwitch, {
  type Props as BaseProps,
} from '../bulmaElements/CheckboxSwitch';

type Props = BaseProps & {
  style?: CSSProperties;
};

type State = {
  isSubmitting: boolean;
};

export default class CheckboxSwitch extends React.Component<Props, State> {
  state = {
    isSubmitting: false,
  };

  onChange = async (value: boolean) => {
    this.setState({ isSubmitting: true });
    try {
      await this.props.onChange(value);
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    const { style, ...otherProps } = this.props;

    return (
      <ComponentUIDProvider
        render={uid => (
          <div style={style} className="switch-container">
            <BulmaCheckboxSwitch
              {...otherProps}
              id={uid}
              onChange={this.onChange}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={uid} className="switch-label" />
          </div>
        )}
      />
    );
  }
}
