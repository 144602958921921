import React from 'react';
import { connect } from 'react-redux';

import type { Notice as NoticeType } from '../redux/actions/application';
import type { AppDispatch } from '../redux/actions/types';
import type { ReduxStore } from '../redux/reducers';

import { DesignSystem, StrictlySanitizedHtml } from 'components';

import { unsetNotice } from '../redux/actions/application';
import Notification from './Notification';
import { ClickAwayListener } from './behaviors';

type AfterConnectProps = {
  notice: NoticeType | undefined;
  dispatch: AppDispatch;
};

type State = {
  isMounted: boolean;
};

class Notice extends React.Component<AfterConnectProps, State> {
  state = {
    isMounted: false,
  };

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  dismiss = () => this.props.dispatch(unsetNotice());

  render() {
    if (this.props.notice && this.props.notice.message) {
      const { kind, message } = this.props.notice;
      const innerContent =
        typeof message === 'string' ? (
          message
        ) : (
          <StrictlySanitizedHtml html={message.__html} />
        );
      const visibility = this.state.isMounted ? 'visible' : 'hidden';
      return (
        <DesignSystem version={2}>
          <ClickAwayListener onClickAway={this.dismiss}>
            <Notification
              onDismiss={this.dismiss}
              additionalClassName="notice"
              style={{ visibility }}
              kind={kind === 'error' ? 'danger' : kind}
            >
              <p>{innerContent}</p>
            </Notification>
          </ClickAwayListener>
        </DesignSystem>
      );
    } else {
      return null;
    }
  }
}

export default connect((state: ReduxStore) => ({
  notice: state.application.notice,
}))(Notice);
