import React from 'react';

import { isDevelopment, isStaging, isTest } from '../config/environment';

type Props = {
  title: string | (string | null | undefined)[];
};

export default class PageTitle extends React.Component<Props> {
  getTitle() {
    const title = this.props.title || 'Elevo';

    const path =
      typeof title === 'string' ? [title] : title.filter(value => !!value);
    let pageTitle = path.join(' - ');

    if (isDevelopment()) {
      pageTitle = '🛠 ' + pageTitle;
    } else if (isStaging()) {
      pageTitle = '🔍 ' + pageTitle;
    } else if (isTest()) {
      pageTitle = '🧪 ' + pageTitle;
    }

    return pageTitle;
  }

  componentDidMount() {
    document.title = this.getTitle();
  }

  render() {
    return null;
  }
}
