import React from 'react';

import type { OrganizationSyncWithStats } from 'models';

import { __ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { DesignSystem, FetchContainer, PageTitle } from 'components';

import OrganizationSyncDetails from './OrganizationSyncDetails';

type Props = {
  match: {
    params: {
      id: string;
    };
  };
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    organizationSync: OrganizationSyncWithStats;
  };

function SyncDetails({
  organizationSync,
  isFetching,
  hasError,
}: AfterDataLoaderProps) {
  return (
    <DesignSystem version={2}>
      <PageTitle title={[__('Synchronization'), __('Admin')]} />
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => (
          <OrganizationSyncDetails organizationSync={organizationSync} />
        )}
      />
    </DesignSystem>
  );
}

export default newDataLoader({
  fetch: (ownProps: Props) =>
    get(`organization_syncs/${ownProps.match.params.id}`),
  hydrate: {
    organizationSync: {
      userSyncsWithErrors: {},
      userSyncsWithWarnings: {},
      lastPendingExportAction: {},
    },
  },
})(SyncDetails) as React.ComponentType<Props>;
