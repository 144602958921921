import React from 'react';

import { BoxList, Centered, Text } from 'components';

const textStyle = {
  display: 'block',
  fontSize: '1.3em',
  fontWeight: '600',
};

type Props = {
  src?: string;
  title?: string;
  description?: React.ReactNode;
  action?: React.ReactNode;
  inBoxList?: boolean;
};

export default function EmptyState({
  src,
  title,
  action,
  description,
  inBoxList,
}: Props) {
  const content = (
    <Centered additionalClassName="empty-state">
      {src && (
        <img className="empty-state-image" alt="presentation" src={src} />
      )}

      {title && (
        <Text
          additionalClassName="empty-state-title"
          color="info"
          style={textStyle}
        >
          {title}
        </Text>
      )}

      {description && (
        <Text additionalClassName="empty-state-description" color="info">
          {description}
        </Text>
      )}

      {!!action && (
        <div className="empty-state-action" style={{ marginTop: 20 }}>
          {action}
        </div>
      )}
    </Centered>
  );

  return inBoxList ? <BoxList>{content}</BoxList> : content;
}
