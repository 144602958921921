import React from 'react';

import type { CSSProperties, ReactNode } from 'react';

import classNames from 'helpers/classNames';

import { ComponentUIDProvider, DesignSystem } from 'components';

import BulmaCheckbox, {
  type Props as BaseProps,
} from '../bulmaElements/Checkbox';

type Props = BaseProps & {
  onChange?: (isChecked: boolean) => any;
  label?: ReactNode;
  subLabel?: ReactNode;
  testClassName?: string;
  style?: CSSProperties;
};

type State = {
  isSubmitting: boolean;
};

export default class Checkbox extends React.Component<Props, State> {
  state = {
    isSubmitting: false,
  };

  handleChange = async (newValue: boolean) => {
    const { onChange, disabled } = this.props;

    if (!onChange || disabled) return;

    let returnValue = onChange(newValue);

    if (
      typeof returnValue !== 'object' ||
      typeof returnValue.then === 'undefined'
    ) {
      return returnValue;
    }

    try {
      this.setState({ isSubmitting: true });

      returnValue = await returnValue;
    } finally {
      this.setState({ isSubmitting: false });
    }

    return returnValue;
  };

  render() {
    const {
      style,
      label,
      subLabel,
      disabled,
      onChange,
      additionalClassName,
      testClassName,
      ...otherProps
    } = this.props;

    return (
      <DesignSystem version={2} style={{ display: 'contents' }}>
        <ComponentUIDProvider
          render={uid => (
            <div
              style={style}
              className={classNames(
                'checkbox',
                { 'is-standalone': !label },
                'test-checkbox',
                testClassName,
                additionalClassName
              )}
            >
              <BulmaCheckbox
                {...otherProps}
                disabled={disabled || this.state.isSubmitting}
              />
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
              <label
                htmlFor={uid}
                className="checkbox-label"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleChange(!otherProps.isChecked);
                }}
              >
                <div>{label}</div>
                {subLabel && (
                  <div className="checkbox-sub-label">{subLabel}</div>
                )}
              </label>
            </div>
          )}
        />
      </DesignSystem>
    );
  }
}
