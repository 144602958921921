import React, { ReactElement } from 'react';

import AnimatedBlowItems from './AnimatedBlowItems';

type Props = {
  displaySeasonalTheme: boolean;
  seasonalTheme: string | undefined;
  animatedItem: ReactElement<typeof React.Component>;
};

const BlowItems = ({
  displaySeasonalTheme,
  seasonalTheme,
  animatedItem,
}: Props) => {
  const [hovered, setHovered] = React.useState<boolean>(false);

  if (displaySeasonalTheme) {
    return (
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className="relative"
      >
        {animatedItem}
        <AnimatedBlowItems blow={hovered} seasonalTheme={seasonalTheme} />
      </div>
    );
  } else {
    return animatedItem;
  }
};

export default BlowItems;
