import React from 'react';

import { dateToDefaultDateStringFormat } from 'helpers/date';
import { __ } from 'helpers/i18n';

import { Button, Icon, Label, Testable, Text } from 'components';

type Props = {
  dueDate: string | null | undefined;
  onChange: (value: string) => Promise<void>;
  disabled: boolean;
};

export default function ObjectiveDueDate({
  dueDate,
  disabled,
  onChange,
}: Props) {
  const [editing, setEditing] = React.useState<boolean>(false);

  if (editing || dueDate) {
    return (
      <div className="objective-due-date">
        <Icon color="info" name="date_range" />

        <Label textSize={7} color="info" weight="semibold">
          {__('Due date')}
        </Label>

        <Testable name="test-objective-due-date">
          <Text
            isEditable={!disabled}
            size={6}
            editableOptions={{
              type: 'date',
              onChange: value =>
                // @ts-ignore TSFIXME: Fix strictNullChecks error
                onChange(dateToDefaultDateStringFormat(value) || ''),
              value: !!dueDate ? new Date(dueDate) : null,
              placeholder: __('Due date'),
              withEditIcon: false,
              isClearable: true,
              autoFocus: editing,
              onBlur: () => setEditing(false),
            }}
          />
        </Testable>
      </div>
    );
  }

  return (
    <Button
      disabled={disabled}
      onClick={() => setEditing(true)}
      size="small"
      color="white"
    >
      <Text color="info" weight="normal">
        <Icon style={{ marginRight: 8 }} name="event" />
        {__('Add a due date')}
      </Text>
    </Button>
  );
}
