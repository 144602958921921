import React from 'react';
import measure from 'remeasure';

export type Position = {
  top: number;
  left: number;
};

export type Size = {
  width: number;
  height: number;
};

type Props = {
  onSizeChange?: (size: Size) => void;
  onPositionChange?: (position: Position) => void;
  children: React.ReactNode;
};

type AfterMeasureProps = {
  onSizeChange?: (size: Size) => void;
  onPositionChange?: (position: Position) => void;
  children: React.ReactNode;
  position: {
    bottom: number;
    clientLeft: number;
    clientTop: number;
    offsetLeft: number;
    offsetTop: number;
    left: number;
    right: number;
    scrollLeft: number;
    scrollTop: number;
    top: number;
  };
  size: {
    clientHeight: number;
    clientWidth: number;
    height: number;
    naturalHeight: number;
    naturalWidth: number;
    offsetHeight: number;
    offsetWidth: number;
    scrollHeight: number;
    scrollWidth: number;
    width: number;
  };
};

class Measurable extends React.Component<AfterMeasureProps> {
  componentDidMount() {
    const { onPositionChange, onSizeChange, position, size } = this.props;

    const { top, left } = position;
    const { height, width } = size;

    onPositionChange && onPositionChange({ top, left });

    onSizeChange && onSizeChange({ width, height });
  }

  componentWillReceiveProps(nextProps: AfterMeasureProps) {
    const { onPositionChange, onSizeChange } = this.props;
    const { offsetTop: top, offsetLeft: left } = nextProps.position;
    const { offsetTop: previousTop, offsetLeft: previousLeft } =
      this.props.position;
    const { height, width } = nextProps.size;
    const { height: previousHeight, width: previousWidth } = this.props.size;

    if ((previousTop !== top || previousLeft !== left) && !!onPositionChange) {
      onPositionChange({ top, left });
    }

    if (
      (previousHeight !== height || previousWidth !== width) &&
      !!onSizeChange
    ) {
      onSizeChange({ width, height });
    }
  }

  render() {
    return this.props.children;
  }
}

export default measure()(Measurable) as React.ComponentType<Props>;
