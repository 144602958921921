import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type {
  ReviewTemplateCreationType as CreationType,
  InteractionType,
  ReviewTemplateCreationType,
} from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n';
import { navigate, pathToTemplate } from 'helpers/navigation';
import transformProps from 'helpers/transformProps';
import { withOrganization } from 'helpers/withSessionProps';

import { post } from 'redux/actions/api';

import { Button, Icon } from 'components';

import ThreeSixtyFeaturePromptModal from 'scenes/admin/components/ThreeSixtyFeaturePromptModal';

type Props = {
  interactionType: string;
};

type AfterConnectProps = Props & {
  shouldPromptThreeSixtyUpgrade: boolean;
  createTemplate: (type: CreationType) => Promise<void>;
};

type State = {
  displayedModal: null | 'interactionTypePicker' | 'featurePrompt';
};

class Index extends React.Component<AfterConnectProps, State> {
  state = {
    displayedModal: null,
  };

  handleButtonClick = () => {
    const { interactionType, shouldPromptThreeSixtyUpgrade } = this.props;

    if (interactionType === '360' && shouldPromptThreeSixtyUpgrade) {
      this.setState({ displayedModal: 'featurePrompt' });
      return;
    }

    this.props.createTemplate(interactionType as ReviewTemplateCreationType);
  };

  handleInteractionTypePickerClose = () => {
    this.setState({ displayedModal: null });
  };

  handleInteractionTypeClick = (interactionType: InteractionType) => {
    const { shouldPromptThreeSixtyUpgrade, createTemplate } = this.props;

    if (interactionType === '360' && shouldPromptThreeSixtyUpgrade) {
      this.setState({ displayedModal: 'featurePrompt' });
      return;
    }

    return createTemplate(interactionType);
  };

  handleFeaturePromptClose = () => {
    this.setState({ displayedModal: 'interactionTypePicker' });
  };

  render() {
    const { interactionType } = this.props;
    const { displayedModal } = this.state;

    const buttonText =
      interactionType === '360'
        ? __('Add a 360 Feedback template')
        : __('Add a review template');

    return (
      <React.Fragment>
        <Button color="primary" onClick={this.handleButtonClick}>
          <Icon name="add" />
          <span>{buttonText}</span>
        </Button>
        {displayedModal === 'featurePrompt' && (
          <ThreeSixtyFeaturePromptModal
            isActive
            onClose={this.handleFeaturePromptClose}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createTemplate: async (type: CreationType) => {
    const response = await dispatch(
      post(
        `review_templates`,
        {
          reviewTemplate: { type },
        },
        {
          errorMessage: __('The template could not be created.'),
        }
      )
    );

    navigate(pathToTemplate(response.response.body.data.id));
  },
});

function propsTransformer({ organization }) {
  return {
    shouldPromptThreeSixtyUpgrade: !organization.plan.is360Enabled,
  };
}

export default compose(
  withOrganization,
  transformProps(propsTransformer),
  connect(null, mapDispatchToProps)
)(Index) as React.ComponentType<Props>;
