// @flow
import { memoize } from 'lodash';

import type { DataState } from 'redux/reducers/DataStateType';

import compositeKey from 'helpers/compositeKey';
import resolveRelationships from 'helpers/data/resolveRelationships';

import { getResourceById } from 'redux/reducers/index';

function _hydrate(
  // $FlowFixMe: Migrate to TS
  dataState: DataState,
  data: Resource | Array<Resource>,
  relationshipsTree: {},
  memoizeKey: string
): Resource | Array<Resource> | null {
  if (Array.isArray(data)) {
    return data.map(({ type, id }) => {
      let resource = getResourceById(dataState, type, id);
      return resolveRelationships(dataState, resource, relationshipsTree);
    });
  } else {
    const resource = getResourceById(dataState, data.type, data.id);
    return resolveRelationships(dataState, resource, relationshipsTree);
  }
}

const memoizedHydrate = memoize(
  _hydrate,
  (dataState, data, relationshipsTree, memoizeKey) =>
    compositeKey({
      updatedAt: dataState.updatedAt,
      memoizeKey,
    })
);

export default function hydrate(
  // $FlowFixMe: Migrate to TS
  dataState: DataState,
  data: null | Resource | Array<Resource>,
  relationshipsTree: {},
  memoizeKey?: string
) {
  if (!data) return null;

  if (memoizeKey) return memoizedHydrate.apply(null, arguments);

  return _hydrate.apply(null, arguments);
}
