import React from 'react';

import type { PeopleUserReview } from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';

import { Box, Helper } from 'components';

import Actions from '../Actions';

type Props = {
  review: PeopleUserReview;
  refetchReview: () => void;
};

export default function StatusUpdateBlock({ review, refetchReview }: Props) {
  const canUpdate = can({ perform: 'update', on: review });

  return (
    <Box
      additionalClassName="block-content-wrapper"
      testClassName="test-people-user-review-action-dropdown"
    >
      {review.status !== 'validated' && canUpdate && (
        <Helper
          additionalClassName="review-message"
          style={{ marginBottom: 16 }}
        >
          {__(
            'Once the people review is validated, it will no longer be possible for managers to edit it. However, you will be able to re-open it if needed.'
          )}
        </Helper>
      )}

      <div style={{ textAlign: 'center' }}>
        <Actions review={review} refetchReview={refetchReview} />
      </div>
    </Box>
  );
}
