import React from 'react';

import type { ParticipantEligibility } from 'models';

import { Box, BoxList, FetchContainer } from 'components';

import ParticipantListItem from './ParticipantListItem';

type Props = {
  eligibilities: Array<ParticipantEligibility>;
  onRemoveParticipant: (id: string) => Promise<void>;
  onAddParticipant: (id: string) => Promise<void>;
  isFetching: boolean;
  hasError: boolean;
};

export default function ParticipantList({
  eligibilities,
  onRemoveParticipant,
  onAddParticipant,
  isFetching,
  hasError,
}: Props) {
  return (
    <Box isInset>
      <BoxList additionalClassName="participant-list">
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => {
            return (
              <div>
                {eligibilities.map(eligibility => (
                  <ParticipantListItem
                    key={eligibility.id}
                    eligibility={eligibility}
                    onRemove={onRemoveParticipant}
                    onAdd={onAddParticipant}
                  />
                ))}
              </div>
            );
          }}
        />
      </BoxList>
    </Box>
  );
}
