import * as React from 'react';

import { __ } from './i18n';

const MIN_SUPPORTED_EDGE_VERSION = 15;

// Inspired by https://makandracards.com/makandra/53475-minimal-javascript-function-to-detect-version-of-internet-explorer-or-edge
function getInternetExplorerOrEdgeVersion(): number | null {
  const match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(navigator.userAgent);

  if (match) return parseInt(match[2], 10);

  return null;
}

function getUnsupportedBrowserDisplayName() {
  const version = getInternetExplorerOrEdgeVersion();

  if (version === null) return null;

  if (version < 12) return `Internet Explorer ${version}`;

  return `Microsoft Edge ${version}`;
}

export function isUnsupportedBrowser() {
  const version = getInternetExplorerOrEdgeVersion();

  if (version === null) return false;

  return version < MIN_SUPPORTED_EDGE_VERSION;
}

export function UnsupportedBrowserMessage() {
  const browserName = getUnsupportedBrowserDisplayName();

  return (
    <div style={{ margin: 24 }}>
      <p>
        Elevo ne supporte pas {browserName}, merci d’utiliser{' '}
        <a href="https://browser-update.org/update.html">
          un navigateur plus récent.
        </a>
      </p>
      <p>
        Elevo does not support {browserName}, please{' '}
        <a href="https://browser-update.org/update.html">
          use a more recent browser.
        </a>
      </p>
    </div>
  );
}

export function isUnsupportedBrowserSettings() {
  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');
    return false;
  } catch (e: any) {
    return true;
  }
}

export function UnsupportedBrowserSettingsMessage() {
  return (
    <div style={{ margin: 24 }}>
      <p>{__('Elevo is not compatible with your current browser settings.')}</p>
    </div>
  );
}

export function getBrowserAndVersion() {
  const userAgent = navigator.userAgent;
  const match =
    userAgent.match(
      /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
    ) || [];

  if (/trident/i.test(match[1])) {
    const version = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    return { browser: 'IE', version: version[1] || '' };
  }

  if (match[1] === 'Chrome') {
    const operaBrowser = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
    if (!!operaBrowser) {
      return { browser: 'Opera', version: operaBrowser[1] };
    }
  }

  const browser = match[1] || navigator.appName;
  const version = match[2] || navigator.appVersion;

  return { browser, version };
}

export function isNewNavCompatibleWith(browser: string, version: string) {
  // we use this library to display Accordions component https://github.com/radix-ui/primitives
  // it doesn't support all browsers
  const compatibleBrowsers = ['chrome', 'safari', 'firefox', 'edge', 'opera'];
  if (!compatibleBrowsers.includes(browser.toLowerCase())) return false;

  const minimumVersionNeeded = {
    chrome: 74,
    safari: 13.1,
    firefox: 78,
    edge: 79,
    opera: 60,
  } as const;

  if (parseFloat(version) < minimumVersionNeeded[browser.toLowerCase()])
    return false;
  return true;
}
