import React from 'react';

import { Answer } from 'models';

import useIsLargeScreen from 'helpers/hooks/useIsLargeScreen';
import { __ } from 'helpers/i18n';

import RatingSliderHeader from '../../SkillsCareerLevelBlockContent/components/RatingSliderHeader';
import RatingSliderWithCheckBox from '../../SkillsCareerLevelBlockContent/components/RatingSliderWithCheckbox';
import RatingSelect from './RatingSelect';

type Props = {
  rating: string | null;
  ratingOptions: Array<string>;
  ratingMandatory: boolean;
  onRatingChange: (value: string | null) => unknown;
  closedAnswers?: Array<Answer>;
};

export default function DiscreteSliderAnswer({
  rating,
  ratingOptions,
  ratingMandatory,
  onRatingChange,
  closedAnswers,
}: Props) {
  const isLargeScreen = useIsLargeScreen();

  if (!isLargeScreen)
    return (
      <div className="mb-4">
        <RatingSelect
          ratings={[...ratingOptions, 'none']}
          ratingLabels={{
            none: __("I don't know / can't evaluate this skill"),
          }}
          selectedRating={rating}
          onChange={onRatingChange}
          isDanger={ratingMandatory && !rating}
        />
      </div>
    );

  return (
    <div className="grid grid-cols-12">
      <RatingSliderHeader ratingOptions={ratingOptions} />

      <RatingSliderWithCheckBox
        ratingOptions={ratingOptions}
        rating={rating}
        setRating={onRatingChange}
        closedAnswers={!!closedAnswers ? closedAnswers : []}
      />
    </div>
  );
}
