import React from 'react';

import { __ } from 'helpers/i18n';

import { Box, EmptyState as EmptyStateComponent, Link } from 'components';

import rocketSvg from './rocketSvg';

type Props = {
  onTextClick: () => void;
};

const CreationInformationBox = ({ onTextClick }: Props) => {
  return (
    <Box style={{ marginTop: 24 }}>
      <EmptyStateComponent
        title={__('To begin, create your first period!')}
        description={__(
          'To collect your training requests, %1.\n' +
            'Once created, it will appear in the request creation window.',
          <Link onClick={onTextClick}>
            {__('you have to create your first period')}
          </Link>
        )}
        src={`data:image/svg+xml;base64,${window.btoa(rocketSvg)}`}
      />
    </Box>
  );
};

export default CreationInformationBox;
