import moment from 'moment';
import React from 'react';

import { __ } from 'helpers/i18n';

import { Label, TimePicker } from 'components';

function dateTimeWithoutOffset(date: string) {
  return new Date(date.replace('Z', ''));
}

type Props = {
  value?: string;
  onChange: (value: string) => void;
};

export const IntegrationSyncTime = ({ onChange, value }: Props) => {
  const [syncTime, setSyncTime] = React.useState(
    value ? dateTimeWithoutOffset(value) : null
  );

  const onTimeChange = (time: Date | undefined | null) => {
    setSyncTime(time || null);
    onChange(moment(time).format('HH:mm'));
  };

  return (
    <div>
      <Label weight="bold">{__('Synchronization time in UTC')}</Label>

      <TimePicker
        value={syncTime}
        onChange={onTimeChange}
        withPortal
        placeholder="05:23"
        name="sync_time"
      />
    </div>
  );
};
