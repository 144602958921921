import React, { ReactNode } from 'react';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import {
  OldPathToTrainingSessionIndex,
  pathToForecastTrainingPlan,
  pathToTrainingPeriodRequests,
  pathToTrainings,
  pathToValidatedTrainingPlan,
} from 'helpers/paths';

import { PageHeader as DefaultPageHeader } from 'components';

type Props = {
  periodSlug?: string;
  actions?: ReactNode;
};

const PageHeader = ({ periodSlug, actions }: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const plansEnabled = featureFlags.includes('trainingPlans');

  const tabItems = [
    {
      label: ' ' + __('Training requests'),
      to: periodSlug
        ? pathToTrainingPeriodRequests(periodSlug)
        : pathToTrainings(),
    },
  ];
  if (plansEnabled) {
    tabItems.push({
      label: ' ' + __('Forecast plan'),
      to: periodSlug
        ? pathToForecastTrainingPlan(periodSlug)
        : pathToTrainings(),
    });
    tabItems.push({
      label: ' ' + __('Validated plan'),
      to: periodSlug
        ? pathToValidatedTrainingPlan(periodSlug)
        : pathToTrainings(),
    });
  } else {
    tabItems.push({
      label: ' ' + __('Training sessions'),
      to: periodSlug
        ? OldPathToTrainingSessionIndex(periodSlug)
        : pathToTrainings(),
    });
  }

  return (
    <DefaultPageHeader
      title={__('Trainings')}
      actions={actions}
      tabItems={tabItems}
    />
  );
};

export default PageHeader;
