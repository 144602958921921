import React from 'react';

import type { PaginationChangeMethods } from 'lib/dataLoader/pagination/types';
import type { CollectionInfo } from 'models';

import classNames from 'helpers/classNames';
import { __, n__ } from 'helpers/i18n';

import NextPageButton from './NextPageButton';
import PreviousPageButton from './PreviousPageButton';

type Props = PaginationChangeMethods & {
  collectionInfo: CollectionInfo | undefined | null;
  totalCountRenderer: (totalCount: number | null | undefined) => string;
  page?: number;
  isFetching: boolean;
  style?: React.CSSProperties;
  isPrimary?: boolean;
  showTotalRecordCount: boolean;
};

export default class Pagination extends React.Component<Props> {
  static defaultProps = {
    showTotalRecordCount: true,
    totalCountRenderer: (totalCount: number | null | undefined) => {
      return n__('%1 user', '%1 users', totalCount || 0);
    },
  };

  render() {
    const {
      collectionInfo,
      page,
      previousPageLink,
      nextPageLink,
      getNextPage,
      getPreviousPage,
      isFetching,
      style,
      isPrimary,
      totalCountRenderer,
      showTotalRecordCount,
    } = this.props;

    const { hasNext, hasPrevious, totalRecordCount, totalPageCount } =
      collectionInfo || {
        hasNext: false,
        hasPrevious: false,
        totalRecordCount: undefined,
        totalPageCount: 1,
      };

    return (
      <nav
        className={classNames('pagination', {
          'has-text-primary': !!isPrimary,
        })}
        aria-label="pagination"
        style={style}
      >
        {showTotalRecordCount && Number.isInteger(totalRecordCount) ? (
          <div className="pagination-total-count">
            {totalCountRenderer(totalRecordCount)}
          </div>
        ) : (
          <div />
        )}

        {totalPageCount > 1 && (
          <div className="pagination-controls">
            <PreviousPageButton
              getPreviousPage={getPreviousPage}
              previousPageLink={previousPageLink}
              hasPrevious={hasPrevious}
              isFetching={isFetching}
              isPrimary={!!isPrimary}
            />

            <span className="pagination-progression-text">
              {__(
                'Page %1 of %2',
                <span className="has-text-weight-bold">
                  {page ? Math.min(page, totalPageCount) : '-'}
                </span>,
                <span className="has-text-weight-bold">{totalPageCount}</span>
              )}
            </span>

            <NextPageButton
              getNextPage={getNextPage}
              nextPageLink={nextPageLink}
              hasNext={hasNext}
              isFetching={isFetching}
              isPrimary={!!isPrimary}
            />
          </div>
        )}
      </nav>
    );
  }
}
