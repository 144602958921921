import { omit } from 'lodash';
import React, { ComponentProps } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import type { GraphFormattedTimeSeriesDataPoint } from 'models';
import type { CurveType } from 'recharts/types/shape/Curve';

import { useResponsiveChartBottom } from 'helpers/hooks';

import { ChartTooltip } from 'components';

type LineChartData<SeriesName extends string> =
  GraphFormattedTimeSeriesDataPoint<SeriesName>[];

type LineMetadata<SeriesName extends string> = {
  dataKey: SeriesName;
  name: string;
  color: string;
  curveType: CurveType;
};

type Props<SeriesName extends string> = {
  data: LineChartData<SeriesName>;
  linesMetadata: LineMetadata<SeriesName>[];
  yAxisProps: ComponentProps<typeof YAxis>;
  tooltipFormatter?: ComponentProps<typeof ChartTooltip>['valueFormatter'];
};

/**
 * @param props.data
 * ```
 * [
 *   {
 *     series1Name: 30,
 *     series2Name: 24,
 *     date: "Mardi 12/03/2024",
 *   }
 * ]
 * ```
 */
const CustomLineChart = <SeriesName extends string>({
  data,
  linesMetadata,
  yAxisProps,
  tooltipFormatter,
}: Props<SeriesName>) => {
  const availableSeriesNames = Object.keys(omit(data[0], 'date'));
  const availableLines = linesMetadata.filter(line =>
    availableSeriesNames.includes(line.dataKey)
  );

  const { containerRef, chartMarginBottom } =
    useResponsiveChartBottom<HTMLDivElement>([data]);

  return (
    <div ref={containerRef}>
      <ResponsiveContainer height={240 + chartMarginBottom} width="100%">
        <LineChart data={data} margin={{ top: 20, bottom: chartMarginBottom }}>
          <CartesianGrid strokeDasharray="2 2" vertical={false} />
          <XAxis
            dataKey="date"
            tick={{ fontSize: 'small' }}
            padding={{ left: 30, right: 50 }}
            angle={-45}
            textAnchor="end"
          />
          <YAxis interval={0} tickLine={false} {...yAxisProps} />
          <Tooltip
            content={({ active, payload, label }) => {
              if (!active || !payload) return null;

              return (
                <ChartTooltip
                  title={label}
                  valueFormatter={tooltipFormatter}
                  values={availableLines.map((line, index) => {
                    return {
                      color: line.color,
                      label: line.name,
                      value: payload[index] ? payload[index].value : null,
                    };
                  })}
                />
              );
            }}
          />

          {availableLines.map(line => (
            <Line
              type={line.curveType}
              dot={{ strokeWidth: 5, r: 2 }}
              dataKey={line.dataKey}
              name={line.name}
              stroke={line.color}
              strokeWidth={3}
              key={line.dataKey}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomLineChart;
