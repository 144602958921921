import React from 'react';

import type { RenderEditedParams } from '../InlineEditableWrapper.js';
import type { AvailableInputProps } from './TextInputRenderer';

import { __ } from 'helpers/i18n';

import { ClickAwayListener, Control } from 'components';
import DatePicker from 'components/formElements/advancedElements/DatePicker';

export type AvailableDatePickerProps = AvailableInputProps & {
  minDate?: Date;
  maxDate?: Date;
};

type Props = RenderEditedParams<Date> & AvailableDatePickerProps;

export default function DatePickerRenderer({
  submitIfChanged,
  isSubmitting,
  hasError,
  hasWarning,
  toggle,
  onChange,
  value,
  targetPosition,
  minDate,
  maxDate,
  isClearable,
  displayErrorMessage,
  ...inputProps
}: Props) {
  return (
    <div>
      <ClickAwayListener onClickAway={toggle}>
        <Control
          isClearable={!!value && isClearable}
          onClear={() => {
            onChange(null);
            submitIfChanged(null);
          }}
        >
          <DatePicker
            placeholder={__('Select date')}
            value={value}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            minDate={minDate}
            maxDate={maxDate}
            onChange={value => {
              onChange(value);
              submitIfChanged(value);
            }}
            {...inputProps}
          />
        </Control>

        {hasError && displayErrorMessage && (
          <p className="help is-danger">
            {__(
              'Oops, it looks like we had trouble saving your change. Please try again (or contact support@elevo.fr if it persists)'
            )}
          </p>
        )}
      </ClickAwayListener>
    </div>
  );
}
