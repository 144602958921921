import React from 'react';

import type { UpdateReviewCycle } from '../../';
import type { ReviewCycle } from 'models';

import transformProps from 'helpers/transformProps';

import TemplateSelector from './TemplateSelector';

export type EditableOverrides = {
  nominatePeersInstruction: string;
};

type Props = {
  reviewCycle: ReviewCycle;
  updateReviewCycle: UpdateReviewCycle;
  goToNextStep: () => void;
};

type AfterTransformProps = Props & {
  onContinueClick: (data: {
    overrideNominatePeersInstruction: string;
  }) => void | Promise<void>;
  onTemplateUpdate: (defaultTemplateId: string) => Promise<void>;
};

type State = {
  nominatePeersInstruction: string;
  defaultTemplateId: string | undefined | null;
};

class Step2Template extends React.Component<AfterTransformProps, State> {
  state = {
    defaultTemplateId: this.props.reviewCycle.defaultTemplateId || null,
    nominatePeersInstruction:
      // @ts-ignore TSFIXME
      this.props.reviewCycle.overrideNominatePeersInstruction || '',
  };

  static getDerivedStateFromProps(
    nextProps: AfterTransformProps,
    prevState: State
  ) {
    const nextReviewCycle = nextProps.reviewCycle;

    if (nextReviewCycle.defaultTemplateId !== prevState.defaultTemplateId) {
      return {
        defaultTemplateId: nextReviewCycle.defaultTemplateId,
        nominatePeersInstruction:
          nextReviewCycle.interactionType === '360'
            ? nextReviewCycle.overrideNominatePeersInstruction
            : '',
      };
    }

    // Added to fix a warning: "getDerivedStateFromProps(): A valid state object (or null) must be returned. You have returned undefined."
    return null;
  }

  setOverrides = (overrides: Partial<EditableOverrides>) =>
    // @ts-ignore TSFIXME
    this.setState(overrides);

  onContinueClick = () => {
    const { nominatePeersInstruction } = this.state;

    return this.props.onContinueClick({
      overrideNominatePeersInstruction: nominatePeersInstruction,
    });
  };

  render() {
    const { reviewCycle, onTemplateUpdate } = this.props;
    const { nominatePeersInstruction } = this.state;

    return (
      <TemplateSelector
        reviewCycle={reviewCycle}
        // @ts-ignore TSFIXME
        nominatePeersInstruction={nominatePeersInstruction}
        onTemplateUpdate={onTemplateUpdate}
        setOption={this.setOverrides}
        onContinueClick={this.onContinueClick}
      />
    );
  }
}

function propsTransformer({
  updateReviewCycle,
  reviewCycle,
  goToNextStep,
}: Props) {
  return {
    onContinueClick: async ({ overrideNominatePeersInstruction }) => {
      if (reviewCycle.interactionType === '360') {
        await updateReviewCycle({
          overrideNominatePeersInstruction,
        });
      }
      return goToNextStep();
    },
    onTemplateUpdate: defaultTemplateId =>
      updateReviewCycle({
        defaultTemplateId,
      }),
  };
}

export default transformProps(propsTransformer)(
  // @ts-ignore TSFIXME
  Step2Template
) as React.ComponentType<Props>;
