import React from 'react';

import { __ } from 'helpers/i18n';

import FeaturePromptModal from 'scenes/components/FeaturePromptModal';

import dynamicSvg from '../../../components/svg/dynamicSvg';

type Props = {
  isActive: boolean;
  onClose: () => void;
  mainButtonText: string;
};

export default function DynamicTemplateModal({
  isActive,
  onClose,
  mainButtonText,
}: Props) {
  return (
    <FeaturePromptModal
      title={__('Dynamic template')}
      description={__(
        "With dynamic templates you can attribute review templates depending on the participants' profile." +
          'For instance, you can one template per job, per service, per region or even depending on the participant being a manager or not.'
      )}
      svg={dynamicSvg}
      prefilledSubjectToSupport={__('Learn more about dynamic templates')}
      mainButtonText={mainButtonText}
      isActive={isActive}
      onClose={onClose}
    />
  );
}
