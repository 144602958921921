const piechartSvg = `
  <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="80" cy="80" r="80" fill="#EFF1F6"/>
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="160" height="160">
    <circle cx="80" cy="80" r="80" fill="#EFF1F6"/>
    </mask>
    <g mask="url(#mask0)">
    <path opacity="0.15" d="M111.304 137.348C111.304 139.078 97.2904 140.478 80 140.478C62.7096 140.478 48.6956 139.078 48.6956 137.348C48.6956 135.618 62.7096 134.217 80 134.217C97.2904 134.217 111.304 135.62 111.304 137.348Z" fill="#45413C"/>
    <path d="M128 81H108.174C108.174 73.5278 105.206 66.3618 99.922 61.0781C94.6384 55.7945 87.4722 52.8261 80 52.8261V33C92.7304 33 104.939 38.0572 113.941 47.0589C122.943 56.0607 128 68.2696 128 81Z" fill="#00B8F0" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M128 81C128.006 87.3039 126.766 93.547 124.353 99.3704C121.939 105.194 118.398 110.483 113.934 114.934L99.9304 100.931C102.547 98.3137 104.623 95.2063 106.037 91.7863C107.452 88.3664 108.178 84.7009 108.174 81H128Z" fill="#FFE500" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M113.934 114.934C109.483 119.398 104.194 122.939 98.3704 125.353C92.547 127.766 86.3039 129.006 80 129V109.174C83.7009 109.178 87.3663 108.452 90.7862 107.037C94.2062 105.623 97.3135 103.547 99.9304 100.93L113.934 114.934Z" fill="#6DD627" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M51.8261 81C51.8261 88.4722 54.7944 95.6382 60.078 100.922C65.3616 106.205 72.5278 109.174 80 109.174V129C67.2696 129 55.0607 123.943 46.0589 114.941C37.0572 105.939 32 93.7304 32 81C32 68.2696 37.0572 56.0607 46.0589 47.0589C55.0607 38.0572 67.2696 33 80 33V52.8261C72.5278 52.8261 65.3616 55.7945 60.078 61.0781C54.7944 66.3618 51.8261 73.5278 51.8261 81Z" fill="#FF6242" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M128 81H126.831C124.464 70.3444 118.534 60.8146 110.019 53.9847C101.505 47.1548 90.9153 43.4334 80 43.4348V33C92.7304 33 104.939 38.0572 113.941 47.0589C122.943 56.0607 128 68.2696 128 81Z" fill="#4ACFFF"/>
    <path d="M79.9991 33V43.4348C68.1735 43.4367 56.7647 47.804 47.9606 55.6992C39.1565 63.5944 33.5768 74.4618 32.2914 86.2174C31.5584 79.5144 32.246 72.7321 34.3093 66.3126C36.3725 59.893 39.7651 53.9802 44.2661 48.9594C48.7671 43.9386 54.2755 39.9225 60.4323 37.1726C66.5891 34.4228 73.2562 33.0011 79.9991 33Z" fill="#FF866E"/>
    <path d="M128 81H108.174C108.174 73.5278 105.206 66.3618 99.922 61.0781C94.6384 55.7945 87.4722 52.8261 80 52.8261V33C92.7304 33 104.939 38.0572 113.941 47.0589C122.943 56.0607 128 68.2696 128 81Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M51.8261 81C51.8261 88.4722 54.7944 95.6382 60.078 100.922C65.3616 106.205 72.5278 109.174 80 109.174V129C67.2696 129 55.0607 123.943 46.0589 114.941C37.0572 105.939 32 93.7304 32 81C32 68.2696 37.0572 56.0607 46.0589 47.0589C55.0607 38.0572 67.2696 33 80 33V52.8261C72.5278 52.8261 65.3616 55.7945 60.078 61.0781C54.7944 66.3618 51.8261 73.5278 51.8261 81Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
`;

export default piechartSvg;
