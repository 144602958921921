import React, { Fragment } from 'react';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import { Text, Title5 } from 'components';
import { WithSavingStatusRecorder } from 'components';
import Testable from 'components/behaviors/Testable';

type Props = {
  name: string | undefined | null;
  onChange: (temlateName: string) => Promise<void>;
  templateId: string;
};

const TemplateName = ({ name, onChange, templateId }: Props) => {
  return (
    <Fragment>
      <Testable name="test-template-name-field">
        <WithSavingStatusRecorder
          fieldUid={compositeKey({
            templateId: templateId,
            field: 'name',
          })}
          onChange={(name: string) => onChange(name || '')}
          render={autoSavingOnchange => (
            <Title5
              isEditable
              editableOptions={{
                type: 'singlelineText',
                value: name,
                onChange: autoSavingOnchange,
                placeholder: __('Template title'),
              }}
            />
          )}
        />
      </Testable>
      <div style={{ marginTop: 8 }}>
        <Text color="grey">
          {__('The name of the model is only visible to the admins')}
        </Text>
      </div>
    </Fragment>
  );
};

export default TemplateName;
