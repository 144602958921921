import React, { useEffect, useState } from 'react';

import type {
  AuditReport as AuditReportModel,
  UserFilterSegment,
} from 'models';

import { useAppDispatch, useCurrentOrganization } from 'helpers/hooks';
import useUrlQueryParams from 'helpers/hooks/useUrlQueryParams';
import { __ } from 'helpers/i18n';
import { Match } from 'helpers/paths';

import { newDataLoader } from 'lib/dataLoader';
import { DataLoaderProvidedProps } from 'lib/dataLoader/types';
import { get, put } from 'redux/actions/api';

import { Box, Icon, Text } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import UserFilter from 'scenes/components/UserFilter';
import UserMultiFilters from 'scenes/components/UserMultiFilters';

import AuditReportPageHeader from './AuditReportPageHeader';
import AuditStats from './AuditStats';
import AuditedUsersTable from './AuditedUsersTable';
import OldAuditedUsersTable from './OldAuditedUsersTable';

type RouterProps = {
  match: Match<{
    id: string;
  }>;
};

type Props = RouterProps & {
  auditReport: AuditReportModel;
};

type AfterLoadProps = DataLoaderProvidedProps & Props;

const AuditReport = ({ auditReport, match }: AfterLoadProps) => {
  const { urlQueryParams, replaceHistory } = useUrlQueryParams();
  const [userFilter, setUserFilter] = useState<UserFilterSegment | null>(
    urlQueryParams().userFilter || null
  );

  const [isDataUpdating, setIsDataUpdating] = useState(false);
  const dispatch = useAppDispatch();
  // TODO: multifilters: remove these 2 lines when FF is enabled for everyone
  const { featureFlags } = useCurrentOrganization();
  const withUserMultiFilters = featureFlags.includes('userMultifilters');

  const [userFilters, setUserFilters] = useState<ActiveFilters | {}>(
    urlQueryParams().queryParams?.userFilters || {}
  );

  // TODO: multifilters: remove this useEffect when FF is enabled for everyone
  useEffect(() => {
    const params = {
      ...urlQueryParams(),
      userFilter: userFilter,
      page: 1,
    };
    replaceHistory(params);
  }, [urlQueryParams, replaceHistory, userFilter]);

  const refreshData = async () => {
    setIsDataUpdating(true);
    await dispatch(put(`audit_reports/${auditReport.id}`));
    setIsDataUpdating(false);
  };

  if (!auditReport) return null;
  return (
    <div>
      {!!auditReport && (
        <AuditReportPageHeader
          auditReport={auditReport}
          refreshData={refreshData}
          isDataUpdating={isDataUpdating}
        />
      )}
      <div className="m-4  md:m-6">
        {isDataUpdating && (
          <Text>
            <Icon name="sync" /> {__('Report data are being updated')}
          </Text>
        )}
      </div>
      <div className="m-4  md:m-6">
        {withUserMultiFilters ? (
          <UserMultiFilters
            onChange={({ userFilters }) => setUserFilters(userFilters)}
            userFilters={userFilters}
          />
        ) : (
          // TODO: multifilters: remove this condition when FF is enabled for everyone
          <UserFilter segment={userFilter} onChange={setUserFilter} />
        )}
      </div>
      <div className="m-4 md:m-6">
        <AuditStats
          auditReportId={match.params.id}
          userFilter={withUserMultiFilters ? undefined : userFilter}
          userFilters={userFilters}
          lastUpdate={auditReport.dataUpdatedAt}
        />
        <Box>
          {withUserMultiFilters ? (
            <AuditedUsersTable
              auditReportId={match.params.id}
              lastUpdate={auditReport.dataUpdatedAt}
              additionalQueryParams={{ userFilters }}
              defaultFilter={{ late: true }}
            />
          ) : (
            // TODO: multifilters: remove this condition and component when FF is enabled for everyone
            <OldAuditedUsersTable
              auditReportId={match.params.id}
              paginationType="url"
              defaultPaginationParams={{ filter: { late: true } }}
              // @ts-ignore TSFIXME: Fix strictNullChecks error
              userFilter={userFilter}
              lastUpdate={auditReport.dataUpdatedAt}
            />
          )}
        </Box>
      </div>
    </div>
  );
};

export default newDataLoader({
  fetch: ({ match }) => get(`audit_reports/${match.params.id}`),
  hydrate: {
    auditReport: {
      tags: {},
    },
  },
})(AuditReport);
