import { AutoAddRuleSegmentEligibility, UserFilterSegment } from 'models';

import { OptionType } from './';

export const formatSegmentToOption = (
  segment: UserFilterSegment
): OptionType => ({
  value: segment.value,
  // @ts-ignore TSFIXME: Fix strictNullChecks error
  label: segment.label,
  segment: segment,
  disabled: false,
  disabledReason: null,
});

export const formatSegmentEligibilityToOption = (
  eligibility: AutoAddRuleSegmentEligibility
): OptionType => ({
  ...formatSegmentToOption(eligibility.segment),
  disabled: eligibility.disabled,
  disabledReason: eligibility.disabledReason,
});

export const formatOptionToSegmentValue = (
  option: OptionType
): UserFilterSegment => option.segment;
