import React, { useState } from 'react';

import type { ENPSAnswer, ENPSPeriod, User } from 'models';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useAppSelector, useOrganization } from 'helpers/hooks';
import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import { navigate, pathToHome } from 'helpers/navigation';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, post, put } from 'redux/actions/api';
import { getActiveUser } from 'redux/reducers';

import {
  DeprecatedPageHeader,
  DeprecatedPageHeaderTitle,
  Divider,
  FetchContainer,
  Icon,
  PageTitle,
  StrictlySanitizedHtml,
  Text,
} from 'components';

import Form from './Form';
import SuccessModal from './SuccessModal';

type Props = {
  enpsPeriod: ENPSPeriod;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    enpsAnswer: ENPSAnswer;
  };

const ActivePeriod = ({
  enpsPeriod,
  enpsAnswer,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  const [modalIsActive, setModalIsActive] = useState(false);
  const { firstName: activeUserFirstName } = useAppSelector(
    getActiveUser
  ) as User;
  const { name: organizationName } = useOrganization();
  const dispatch = useAppDispatch();

  const createAnswer = (enpsAnswer: Partial<ENPSAnswer>) =>
    dispatch(
      post(`enps/periods/${enpsPeriod.id}/answers`, {
        enpsAnswer,
      })
    );

  const updateAnswer = (enpsAnswer: Partial<ENPSAnswer>) =>
    dispatch(
      put(`enps/periods/${enpsPeriod.id}/answers/mine`, {
        enpsAnswer,
      })
    );

  return (
    <div className="m-4 md:m-8">
      <div className="max-w-[960px] mx-auto">
        <PageTitle title={__('eNPS %1', enpsPeriod.name)} />
        <DeprecatedPageHeader style={{ margin: 0, marginBottom: 24 }}>
          <DeprecatedPageHeaderTitle style={{ padding: 0 }}>
            {__('eNPS %1', enpsPeriod.name)}
          </DeprecatedPageHeaderTitle>
        </DeprecatedPageHeader>

        <Text preset="18bs5">
          {__(
            "Hello %1 👋🏻 it's time to give your opinion",
            activeUserFirstName
          )}
        </Text>
        <p>
          <Text preset="14s6">
            {__(
              'The eNPS is a key indicator that aims to evaluate the commitment of employees within their company. It allows you to give your opinion in an anonymous and regular way.'
            )}
            <br />
            {__(
              'Thank you for taking the time to respond, we value your opinion.'
            )}
          </Text>
        </p>

        <Divider style={{ backgroundColor: '#B2BBCB' }} />

        <div className="flex justify-between mb-2">
          <div>
            <Icon
              size="small"
              style={{ marginRight: 8, marginBottom: 2 }}
              name="visibility_off"
            />
            <Text preset="13s7">
              <StrictlySanitizedHtml
                html={__('Your answer is <b>anonymous</b>')}
              />
            </Text>
          </div>
          <div>
            <Icon
              size="small"
              style={{ marginRight: 8, marginBottom: 2 }}
              name="timer"
            />
            <Text preset="13s7">
              <StrictlySanitizedHtml
                html={n__(
                  'You have until <b>%2</b> (%1 day left) to send your response',
                  'You have until <b>%2</b> (%1 days left) to send your response',
                  enpsPeriod.daysLeftToComplete,
                  enpsPeriod.endDate
                )}
              />
            </Text>
          </div>
        </div>

        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          render={() => (
            <Form
              organizationName={organizationName}
              enpsAnswer={enpsAnswer}
              onCreateAnswer={createAnswer}
              onUpdateAnswer={updateAnswer}
              onAfterSubmit={() => setModalIsActive(true)}
            />
          )}
        />

        {modalIsActive && (
          <SuccessModal
            onConfirm={() => navigate(pathToHome())}
            onClose={() => setModalIsActive(false)}
          />
        )}
      </div>
    </div>
  );
};

export default newDataLoader({
  fetch: ({ enpsPeriod }: Props) =>
    get(`enps/periods/${enpsPeriod.id}/answers/mine`),
  hydrate: {
    enpsAnswer: {},
  },
})(ActivePeriod);
