import React, { useState } from 'react';

import can from 'helpers/can';
import { useCurrentOrganization, useOrganizationSettings } from 'helpers/hooks';
import { __, date } from 'helpers/i18n';
import { navigate, pathToNotificationsSettings } from 'helpers/navigation';

import { ConfirmationModal, StrictlySanitizedHtml } from 'components';

const EmailNotificationsDisabledModal = () => {
  const location = window.location;
  const settings = useOrganizationSettings();
  const organization = useCurrentOrganization();
  const [showModal, setShowModal] = useState(
    sessionStorage.getItem('emailsDisabledNoticeTriggered') !== 'true' &&
      !location.pathname.includes('/auth/callback') &&
      !settings.emailsEnabled &&
      can({
        perform: 'manage_notifications_settings',
        on: organization,
      })
  );

  const disableNotice = () => {
    setShowModal(false);
    sessionStorage.setItem('emailsDisabledNoticeTriggered', 'true');
  };

  if (!showModal) return null;
  return (
    <ConfirmationModal
      isActive
      refreshContentOnOpening
      title={__('Email notifications are disabled')}
      onConfirm={() => {
        disableNotice();
        navigate(pathToNotificationsSettings());
      }}
      onCancel={() => {
        disableNotice();
      }}
      confirmLabel={__('Go to settings')}
      cancelLabel={__('Close')}
    >
      <div>
        <StrictlySanitizedHtml
          html={__(
            'Email notifications have been disabled on your platform on <b>%1 at %2</b> by %3.',
            date(settings.emailsEnabledUpdatedAt, 'LL'),
            date(settings.emailsEnabledUpdatedAt, 'LT'),
            settings.emailsEnabledUpdatedByFullName ||
              __('an Elevo administrator')
          )}
        />
        <br />
        <br />
        <p>
          <StrictlySanitizedHtml
            html={__(
              'If you want to <b>enable the email notifications</b>, you can go to Settings > Notifications directly by <b>clicking on the button below</b>.'
            )}
          />
        </p>
      </div>
    </ConfirmationModal>
  );
};

export default EmailNotificationsDisabledModal;
