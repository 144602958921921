import React from 'react';

import type { Objective } from 'models';

import { __ } from 'helpers/i18n';
import { pathToObjective } from 'helpers/navigation';

import { Link, Testable, Text, Title } from 'components';

type Props = {
  objective: Objective;
  disabled?: boolean;
  onSubmit?: (newTitle: string) => Promise<void>;
  target?: string;
  autoFocus?: boolean;
};

const ObjectiveTitle = ({
  objective,
  onSubmit,
  disabled,
  target,
  autoFocus,
}: Props) => {
  const titleLink = pathToObjective(objective.kind, objective.id);

  if (onSubmit) {
    return (
      <Testable name="test-objective-title">
        <Title
          size={5}
          weight="semibold"
          isEditable={!disabled}
          editableOptions={{
            type: 'singlelineText',
            onChange: onSubmit,
            value: objective.actualTitle,
            autoFocus: autoFocus,
            withEditIcon: false,
            placeholder: __('Add a title to this objective…'),
          }}
        />
      </Testable>
    );
  }

  return (
    <Testable name="test-objective-title">
      <div className="has-text-overflow-ellipsis">
        <Text
          color={objective.active ? undefined : 'info'}
          size={6}
          overflowEllipsis
        >
          <Link to={target || titleLink}>{objective.title}</Link>
        </Text>
      </div>
    </Testable>
  );
};

export default ObjectiveTitle;
