import React from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { UserFilterSegment } from 'models';

import compositeKey from 'helpers/compositeKey';
import { n__ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer, StrictlySanitizedHtml, Text } from 'components';

type Props = {
  reviewCycleId: string;
  userFilter: UserFilterSegment | undefined | null;
  templateId: string;
};

type FilterStats = {
  userReviewsWithSharedEvaluationsCount: number;
  totalMatchingUserReviewsCount: number;
};

type AfterDataloaderProps = Props &
  DataLoaderProvidedProps & {
    stats: FilterStats;
  };

const FilterText = ({
  stats,
  isFetching,
  hasError,
  userFilter,
}: AfterDataloaderProps) => {
  const withUserFilter = userFilter !== null;

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        const nbMatchingReviewsText = n__(
          'no review',
          '%2 reviews',
          stats.totalMatchingUserReviewsCount,
          stats.totalMatchingUserReviewsCount
        );

        return (
          <Text size={6}>
            <StrictlySanitizedHtml
              componentType="div"
              html={
                withUserFilter
                  ? n__(
                      'No reviews with shared comments from %2 matching conditions',
                      'Results from <b>%1 reviews</b> with shared comments from %2 matching conditions',
                      stats.userReviewsWithSharedEvaluationsCount,
                      nbMatchingReviewsText
                    ).toString()
                  : n__(
                      'No reviews with shared comments from %2 in this campaign',
                      'Results from <b>%1 reviews</b> with shared comments from %2 in this campaign',
                      stats.userReviewsWithSharedEvaluationsCount,
                      nbMatchingReviewsText
                    ).toString()
              }
            />
          </Text>
        );
      }}
    />
  );
};

export default newDataLoader({
  fetch: ({ reviewCycleId, userFilter, templateId }) =>
    get(`review_cycles/${reviewCycleId}/filter_stats`, {
      userFilter,
      templateId,
    }),
  hydrate: {
    stats: {},
  },
  cacheKey: ({ userFilter, templateId }: Props) =>
    compositeKey({
      userFilter,
      templateId,
      view: 'filterText',
    }),
})(FilterText) as React.ComponentType<Props>;
