import { useState } from 'react';

type PaginationInfo = {
  page: number;
  hasNext: boolean;
  totalRecordCount: number;
};

interface IdString {
  id: string;
}

type Props = {
  // $TSFixme: don't use any
  loadItems: (page: number) => Promise<any>;
  collectionItemName?: string;
};

const useShowMore = ({
  loadItems,
  collectionItemName = 'items',
}: Props): [
  boolean,
  PaginationInfo,
  string[],
  (page: number) => Promise<void>
] => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
    page: 1,
    hasNext: false,
    totalRecordCount: 0,
  });
  const [itemIds, setItemIds] = useState<string[]>([]);

  const fetchItems = async (page: number) => {
    setIsFetching(true);
    const { [collectionItemName]: fetchedItems, ...pagination } =
      await loadItems(page);

    setIsFetching(false);

    // $TSFixme: don't use any
    setItemIds([...itemIds, ...fetchedItems.map((item: IdString) => item.id)]);
    setPaginationInfo(pagination);
  };

  return [isFetching, paginationInfo, itemIds, fetchItems];
};

export default useShowMore;
