import React from 'react';
import { Link } from 'react-router-dom';

import type { Team, TeamObjective, TeamObjectivePeriod } from 'models';

import { __, n__ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToTeamObjectivesForObjectivePeriod } from 'helpers/paths';

import {
  Hoverable,
  Icon,
  Level,
  LevelLeft,
  LevelRight,
  MobileViewPort,
  SubTitle,
  TabletViewPort,
  Testable,
  Text,
  Title,
  WithFadeinFadeoutAnimation,
} from 'components';

import { ObjectivesList } from 'scenes/components/objectives';

type TeamInfosProps = {
  team: Team;
  target: string;
};

const TeamTitle = ({ team, target }: TeamInfosProps) => {
  invariant(target, 'Target must be passed');

  return (
    <Title
      size={3.5}
      weight="light"
      style={{ width: '100%' }}
      additionalClassName="is-spaced"
      title={team.name}
      overflowEllipsis
      color="dark-grey"
      overrideLinkColor
    >
      <Link to={target}>
        <Testable name="test-team-title">{team.name}</Testable>
      </Link>
    </Title>
  );
};

const TeamSubtitle = ({ objectives }: { objectives: Array<TeamObjective> }) => {
  invariant(objectives, 'Objectives must be passed');

  return (
    <SubTitle
      size={7}
      color="light"
      transformation="uppercase"
      weight="semibold"
    >
      {n__('%1 objective', '%1 objectives', objectives.length)}
    </SubTitle>
  );
};

const TeamDetails = ({ target }: { target: string }) => {
  invariant(target, 'Target must be passed');

  return (
    <Link to={target}>
      <Text
        weight="semibold"
        color="pelo-grey"
        size={6.5}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <span>{__('See details')}</span>
        <Icon
          name="arrow_forward"
          style={{
            marginLeft: 10,
          }}
          size="small"
        />
      </Text>
    </Link>
  );
};

type Props = {
  teamObjectivePeriod: TeamObjectivePeriod;
};

const TeamObjectiveList = ({ teamObjectivePeriod }: Props) => {
  const { team, objectives } = teamObjectivePeriod;

  const linkTarget = pathToTeamObjectivesForObjectivePeriod(
    teamObjectivePeriod.slug,
    team.slug
  );

  return (
    <Hoverable
      render={isHovered => (
        <div>
          <TabletViewPort>
            <Level>
              <LevelLeft
                style={{
                  width: 'calc(100% - 200px)',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <TeamTitle team={team} target={linkTarget} />
                <TeamSubtitle objectives={objectives} />
              </LevelLeft>
              <LevelRight>
                <WithFadeinFadeoutAnimation visible={isHovered} duration={150}>
                  <TeamDetails target={linkTarget} />
                </WithFadeinFadeoutAnimation>
              </LevelRight>
            </Level>
          </TabletViewPort>

          <MobileViewPort>
            <TeamTitle team={team} target={linkTarget} />
            <TeamSubtitle objectives={objectives} />
          </MobileViewPort>

          <div style={{ marginTop: 15 }}>
            {objectives.length > 0 ? (
              <ObjectivesList objectives={objectives} target={linkTarget} />
            ) : null}
          </div>
        </div>
      )}
    />
  );
};
export default TeamObjectiveList;
