import React from 'react';

import { __, n__ } from 'helpers/i18n';

import { Helper, Text } from 'components';

type Props = {
  usersCount: number;
};

const AutoInvitationHelper = ({ usersCount }: Props) => (
  <Helper>
    <Text preset="13bs7">{__('Automatic invitation to the session')}</Text>
    <br />
    <Text preset="13s7">
      {n__(
        'The selected participant will be automatically invited to the session as this session has been planned:',
        'The %1 selected participants will be automatically invited to the session as this session has been planned:',
        usersCount
      )}
    </Text>
  </Helper>
);

export default AutoInvitationHelper;
