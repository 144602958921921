import * as React from 'react';

import ReviewCyclesList from './ReviewCyclesList';

type Props = {
  type: string;
};

function MyTeamReviews({ type }: Props) {
  return <ReviewCyclesList type={type} />;
}

export default MyTeamReviews;
