import React from 'react';

import type { ThreeSixtyReviewCycle } from 'models';

import { __ } from 'helpers/i18n';

import BulkRemindersMenuButton from '../BulkRemindersMenuButton';

type Props = {
  reviewCycle: ThreeSixtyReviewCycle;
};

export default function ThreeSixtyBulkRemindersMenuButton({
  reviewCycle,
}: Props) {
  return (
    <BulkRemindersMenuButton
      reviewCycle={reviewCycle}
      reminders={{
        reviewee: [
          {
            type: 'three_sixty_peer_nomination_due',
            confirmation: __(
              'Send a reminder to all participants that do not have nominated any peer?'
            ),
            label: __('Peer nomination due'),
            condition: (reviewCycle: ThreeSixtyReviewCycle) =>
              reviewCycle.nominatePeersStepEnabled,
          },
          {
            type: 'three_sixty_peer_feedback_due',
            confirmation: __(
              'Send a reminder to all peers that have not yet shared their feedback?'
            ),
            label: __('Peer feedback due'),
          },
          {
            type: 'three_sixty_self_assessment_due',
            confirmation: __(
              'Send a reminder to all reviewees that have not yet shared their self assessment?'
            ),
            label: __('Self assessment due'),
            condition: (reviewCycle: ThreeSixtyReviewCycle) =>
              reviewCycle.hasSelfEvaluationEnabled,
          },
        ],
        manager: [
          {
            type: 'three_sixty_peer_validation_due',
            confirmation: __(
              'Send a reminder to all managers that need to validate peers for their team?'
            ),
            label: __('Peer validation due'),
          },
          {
            type: 'three_sixty_manager_releasing_due',
            confirmation: __(
              'Send a reminder to all managers that have to share feedback with their team?'
            ),
            label: __('Share 360 due'),
            condition: (reviewCycle: ThreeSixtyReviewCycle) =>
              reviewCycle.releaseStepEnabled,
          },
        ],
      }}
    />
  );
}
