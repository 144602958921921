import React from 'react';

type SubmitIfChanged<T> = (value: T) => Promise<void>;

type Props<T> = {
  render: (
    submitIfChanged: SubmitIfChanged<T>,
    isSubmitting: boolean,
    hasError: boolean
  ) => React.ReactNode;
  doSubmit: (value: T) => Promise<void>;
  doCancel: () => void;
  originalValue?: T;
};

type State = {
  isSubmitting: boolean;
  hasError: boolean;
};

export default class Submittable<T> extends React.Component<Props<T>, State> {
  state = {
    isSubmitting: false,
    hasError: false,
  };

  submitIfChanged: SubmitIfChanged<T> = async value => {
    const originalValue = this.props.originalValue;
    const isDefined = (val: T | undefined) => typeof val !== 'undefined';
    if (
      (!isDefined(originalValue) && !isDefined(value)) ||
      (isDefined(originalValue) && originalValue === value)
    ) {
      this.props.doCancel();
    } else {
      this.setState({ isSubmitting: true });
      try {
        await this.props.doSubmit(value);
        this.setState({ isSubmitting: false, hasError: false });
      } catch (e) {
        this.setState({ isSubmitting: false, hasError: true });
      }
    }
  };

  render() {
    const { isSubmitting, hasError } = this.state;

    return this.props.render(this.submitIfChanged, isSubmitting, hasError);
  }
}
