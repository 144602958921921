import React from 'react';

import type { UserFilterSegment } from 'models';

import compositeKey from 'helpers/compositeKey';

import { newDataLoader } from 'lib/dataLoader';
import { DataLoaderProvidedProps } from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import { Level } from 'components';

import PaginatedList from './PaginatedList';
import PlannedActionsStats from './PlannedActionsStats';

type Props = {
  userFilter: UserFilterSegment;
  tagLabels: Array<string>;
  refetchTags: () => Promise<void>;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    plannedActionsStatsData: PlannedActionsStatsData;
  };

type PlannedActionsStatsData = {
  soonOverdueCount: number;
  overdueCount: number;
  totalCount: number;
};

const PlannedActionsWithStats = ({
  plannedActionsStatsData,
  isFetching,
  hasError,
  refetchData,
  refetchTags,
  userFilter,
  tagLabels,
}: AfterDataLoaderProps) => (
  <React.Fragment>
    <Level>
      <PlannedActionsStats
        plannedActionsStatsData={plannedActionsStatsData}
        isFetching={isFetching}
        hasError={hasError}
      />
    </Level>

    <PaginatedList
      paginationType="url"
      segment={userFilter}
      tagLabels={tagLabels}
      parentRefetchData={() => {
        refetchTags();
        return refetchData();
      }}
    />
  </React.Fragment>
);

export default newDataLoader({
  fetch: ({ userFilter, tagLabels }: Props) =>
    get('planned/people_review_actions/stats', {
      userFilter,
      'tagLabels[]': tagLabels,
    }),
  hydrate: {
    plannedActionsStatsData: {},
  },
  cacheKey: ({ userFilter, tagLabels }: Props) =>
    compositeKey({ userFilter, tagLabels }),
})(PlannedActionsWithStats);
