import React, { Fragment, useEffect } from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/dataLoader/pagination/types';
import type { PaginatedCollection, TrainingParticipant } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import { newDataLoader, withDeprecatedPagination } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  DatatableWrapper,
  EmptyState,
  EmptyStateWithIcon,
  FetchContainer,
  SimpleSelectableTable,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

import RequestCell from '../../components/RequestCell';
import BulkActions from './BulkActions';
import FundingCell from './FundingCell';

type Props = {
  sessionId: string;
  sessionName: string;
  periodSlug: string;
  shouldRefetchData: number;
} & WithPaginationProps;

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = DataLoaderProvidedProps &
  AfterPaginateProps & {
    participantCollection: PaginatedCollection<TrainingParticipant>;
  };

const ParticipantFundingItems = ({
  sessionId,
  sessionName,
  periodSlug,
  shouldRefetchData,
  participantCollection,
  isFetching,
  hasError,
  filter,
  refetchData,
  sort,
  onSortChange,
  ...otherDatatableProps
}: AfterDataLoaderProps) => {
  useEffect(() => {
    if (!!filter && !filter['all']) {
      refetchData();
    }
    // We only want to refetch when the session attributes change not when filter changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetchData, refetchData]);

  return (
    <Fragment>
      <DatatableWrapper
        isFetching={isFetching}
        hasError={hasError}
        collectionInfo={participantCollection}
        {...otherDatatableProps}
        totalCountRenderer={(count?: number | null) =>
          n__('%1 participant', '%1 participants', count || 0)
        }
        renderNoRecord={() => (
          <EmptyStateWithIcon
            inBox={false}
            iconName="school"
            title={__('This training session does not have any participant')}
          />
        )}
        renderNoResult={() => (
          <EmptyState title={__('No participants match your search')} />
        )}
        filters={[
          { param: 'all', label: __('All') },
          { param: 'with_incomplete_funding', label: __('Incomplete funding') },
        ]}
        filter={filter}
        searchPlaceholder={__('Search participants')}
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <SimpleSelectableTable
              useSmallerYPadding
              rows={!!participantCollection ? participantCollection.items : []}
              keyFn={participant => participant.id}
              rowClassName="test-training-participant-row"
              columns={[
                {
                  header: __('Participant name'),
                  cell: participant => (
                    <UserAvatar user={participant.user} withJobTitle />
                  ),
                  activeSort: sort && sort['participant_name'],
                  onSort: () =>
                    onSortChange && onSortChange('participant_name'),
                },
                {
                  header: __('Funding'),
                  cell: participant => (
                    <FundingCell participant={participant} />
                  ),
                },
                {
                  header: __('Associated training request'),
                  cell: participant => (
                    <RequestCell
                      participant={participant}
                      sessionName={sessionName}
                      refetchParticipants={refetchData}
                    />
                  ),
                },
                // We don't want to align the last visible column to the right
                { header: '', cell: () => null },
              ]}
              additionalClassName="mb-2"
              renderBulkActions={(
                selectedParticipantIds,
                resetSelectedParticipantIds
              ) => (
                <BulkActions
                  sessionId={sessionId}
                  periodSlug={periodSlug}
                  selectedParticipantIds={selectedParticipantIds}
                  resetSelectedParticipantIds={resetSelectedParticipantIds}
                  refetchParticipants={refetchData}
                />
              )}
            />
          )}
        />
      </DatatableWrapper>
    </Fragment>
  );
};

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      search,
      userFilter,
      filter,
      sessionId,
      sort,
    }: AfterPaginateProps) =>
      get(`training/sessions/${sessionId}/participants`, {
        page,
        countPerPage,
        search,
        userFilter,
        filter,
        sort,
      }),
    hydrate: {
      participantCollection: {
        items: {
          user: {},
          fundingItems: {
            fundingSource: {},
          },
          request: { creator: {}, trainingPeriod: {}, trainee: {} },
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      userFilter,
      filter,
      sessionId,
      sort,
    }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
        search,
        userFilter,
        filter,
        sessionId,
        sort,
      }),
  })
)(ParticipantFundingItems);
