import React from 'react';

import classNames from 'helpers/classNames';

type Props = {
  count: number;
  className?: string;
};

const Badge = ({ count, className }: Props) => (
  <span
    className={classNames(
      className,
      'inline-flex items-center justify-center py-0.5 px-3 bg-gray-200 rounded-full text-xs text-indigo-800 !font-semibold'
    )}
  >
    {count}
  </span>
);

export default Badge;
