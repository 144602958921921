import React from 'react';

import type { AvailableDatePickerProps } from 'components/formElements/inlineEditables/editedRenderers/DatePickerRenderer';
import type {
  AvailableInputProps,
  AvailableNumberInputProps,
} from 'components/formElements/inlineEditables/editedRenderers/TextInputRenderer';
import type { AvailableTextareaProps } from 'components/formElements/inlineEditables/editedRenderers/TextareaRenderer';

import {
  InlineEditableDatePicker,
  InlineEditableNumberInput,
  InlineEditablePercentageInput,
  InlineEditableTextInput,
  InlineEditableTextarea,
} from 'components';

import NotEdited from './NotEdited';

type CommonProps = {
  placeholder?: string;
  withEditIcon?: boolean;
  autoFocus?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  isDisabled?: boolean;
  isClearable?: boolean;
  displayErrorMessage?: boolean;
};

type InputTypeProps =
  | ({
      type: 'multilineText';
      value: string | undefined | null;
      disabledValue?: string | null;
      onChange: (text: string) => Promise<void> | void;
      fieldProps?: AvailableTextareaProps;
      onMaxLengthReached?: () => void;
    } & CommonProps)
  | ({
      type: 'singlelineText';
      value: string | undefined | null;
      disabledValue?: string | null;
      onChange: (text: string) => Promise<void> | void;
      fieldProps?: AvailableInputProps;
    } & CommonProps)
  | ({
      type: 'number';
      value: number | undefined | null;
      disabledValue?: number | null;
      onChange: (value?: number | null) => Promise<void> | void;
      fieldProps?: AvailableNumberInputProps;
    } & CommonProps)
  | ({
      type: 'date';
      value: Date | undefined | null;
      disabledValue?: Date | null;
      onChange: (date?: Date | null) => Promise<void> | void;
      fieldProps?: AvailableDatePickerProps;
    } & CommonProps)
  | ({
      type: 'percentage';
      value: number | undefined | null;
      disabledValue?: number | null;
      onChange: (percent?: number | null) => Promise<void> | void;
      fieldProps?: AvailableInputProps;
    } & CommonProps);

export type Props = InputTypeProps;

export default function Editable({
  withEditIcon = false,
  autoFocus,
  placeholder,
  hasError,
  hasWarning,
  isClearable = false,
  isDisabled,
  onBlur,
  onFocus,
  displayErrorMessage = true,
  ...typedProps
}: Props) {
  let content;

  const renderNotEdited = () => (
    <NotEdited
      type={typedProps.type}
      // @ts-ignore TSFIXME: Fix this
      value={
        isDisabled && typedProps.disabledValue
          ? typedProps.disabledValue
          : typedProps.value
      }
      placeholder={placeholder}
      fieldProps={typedProps.fieldProps}
    />
  );

  switch (typedProps.type) {
    case 'multilineText':
      content = (
        <InlineEditableTextarea
          value={typedProps.value}
          isDisabled={isDisabled}
          onSubmit={value => typedProps.onChange(value || '')}
          renderNotEdited={renderNotEdited}
          textareaProps={typedProps.fieldProps}
          withEditIcon={withEditIcon}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          hasError={hasError}
          hasWarning={hasWarning}
          placeholder={placeholder}
          isClearable={isClearable}
          onBlur={onBlur}
          onFocus={onFocus}
          displayErrorMessage={displayErrorMessage}
        />
      );
      break;
    case 'singlelineText':
      content = (
        <InlineEditableTextInput
          value={typedProps.value}
          isDisabled={isDisabled}
          onSubmit={value => typedProps.onChange(value || '')}
          renderNotEdited={renderNotEdited}
          inputProps={typedProps.fieldProps}
          withEditIcon={withEditIcon}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          placeholder={placeholder}
          hasError={hasError}
          hasWarning={hasWarning}
          isClearable={isClearable}
          onBlur={onBlur}
          onFocus={onFocus}
          displayErrorMessage={displayErrorMessage}
        />
      );
      break;
    case 'number':
      content = (
        <InlineEditableNumberInput
          value={typedProps.value}
          isDisabled={isDisabled}
          onSubmit={value => typedProps.onChange(value)}
          renderNotEdited={renderNotEdited}
          inputProps={typedProps.fieldProps}
          withEditIcon={withEditIcon}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          hasError={hasError}
          hasWarning={hasWarning}
          isClearable={isClearable}
          onBlur={onBlur}
          onFocus={onFocus}
          displayErrorMessage={displayErrorMessage}
        />
      );
      break;
    case 'date':
      content = (
        <InlineEditableDatePicker
          value={typedProps.value}
          isDisabled={isDisabled}
          onSubmit={value => typedProps.onChange(value)}
          renderNotEdited={renderNotEdited}
          inputProps={typedProps.fieldProps}
          withEditIcon={withEditIcon}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          hasError={hasError}
          hasWarning={hasWarning}
          isClearable={isClearable}
          onBlur={onBlur}
          onFocus={onFocus}
          displayErrorMessage={displayErrorMessage}
        />
      );
      break;
    case 'percentage':
      content = (
        <InlineEditablePercentageInput
          value={typedProps.value}
          isDisabled={isDisabled}
          onSubmit={value => typedProps.onChange(value)}
          renderNotEdited={renderNotEdited}
          inputProps={typedProps.fieldProps}
          withEditIcon={withEditIcon}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          hasError={hasError}
          hasWarning={hasWarning}
          isClearable={isClearable}
          onBlur={onBlur}
          onFocus={onFocus}
          displayErrorMessage={displayErrorMessage}
        />
      );
      break;
    default:
      throw new Error('Unknown type');
  }

  return <div className="text-inherit-input">{content}</div>;
}
