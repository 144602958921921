export default function invariant(
  assertion: unknown,
  failureMessage: string
): asserts assertion {
  if (!assertion) {
    throw new Error(`Failed assertion: ${failureMessage}`);
  }
}

export function assert<T>(o: T | null | undefined, message: string): T {
  invariant(o, message);
  return o;
}

export function assertDefined<T>(o: T | null | undefined, message: string): T {
  invariant(typeof o !== 'undefined', message);
  // @ts-ignore TSFIXME: Fix strictNullChecks error
  return o;
}
