import { useCallback } from 'react';

import { history } from 'config/history';

import queryString from 'helpers/queryString';

import { parsePaginationParams } from 'lib/dataLoader/pagination/paginationParams';

export default function useUrlQueryParams() {
  const urlQueryParams = useCallback(
    () => parsePaginationParams(queryString.parse(window.location.search)),
    []
  );
  const replaceHistory = useCallback(
    params =>
      history.replace(
        history.location.pathname + '?' + queryString.stringify(params)
      ),
    []
  );

  return {
    urlQueryParams,
    replaceHistory,
  };
}
