import React from 'react';

import type { SkillsExpectation } from 'models/skills';

import { __ } from 'helpers/i18n';
import { pathToArea } from 'helpers/paths';

import { Button, Flex, Link, Text } from 'components';

type Props = {
  areaId: string;
  expectation: SkillsExpectation;
  showFullText: boolean;
  toggleShowFullText: () => void;
  editionMode?: boolean;
};
export default function ExpectationBox({
  areaId,
  expectation,
  showFullText,
  toggleShowFullText,
  editionMode,
}: Props) {
  const emptyExpectation = !!editionMode ? (
    <Link to={pathToArea(areaId)}>
      {__('Click here to define the content of this expectation')}
    </Link>
  ) : (
    __('The expectation does not have content for the level.')
  );

  const EXPECTATION_TRUNCATE_THRESHOLD = 100;

  const showToggleButton =
    editionMode &&
    !!expectation.content &&
    expectation.content.length > EXPECTATION_TRUNCATE_THRESHOLD;

  return (
    <Flex direction="column" verticalAlign additionalClassName="flex-grow">
      {!!expectation.content ? (
        <Flex
          direction="column"
          additionalClassName="justify-between flex-grow"
        >
          <Text
            preserveLinebreaks
            testClassName="test-skills-matrix-expectation-content"
            additionalClassName="w-full flex-grow"
          >
            {showFullText
              ? expectation.content
              : expectation.content.slice(0, EXPECTATION_TRUNCATE_THRESHOLD)}
            {expectation.content.length > EXPECTATION_TRUNCATE_THRESHOLD &&
              !showFullText &&
              '...'}
          </Text>
          {showToggleButton && (
            <Button
              onClick={toggleShowFullText}
              additionalClassName="text-[var(--primary)] justify-end p-0 mt-2 bg-transparent no-underline"
              isText
            >
              {showFullText ? __('Hide expectation') : __('Show expectation')}
            </Button>
          )}
        </Flex>
      ) : (
        <Text
          color="soften"
          transformation="italic"
          additionalClassName="w-full"
        >
          {emptyExpectation}
        </Text>
      )}
    </Flex>
  );
}
