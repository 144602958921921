import React from 'react';

import { AdminRoleName } from 'models/Role';

import { __ } from 'helpers/i18n';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import displayableRoleName from '../../helpers/displayableRoleName';
import EntityList from './EntityList';

type Props = {
  roleName: AdminRoleName;
  onClose: () => void;
  adminId: string;
  adminFullName: string;
};

const AdministeredEntitiesModal = ({
  roleName,
  onClose,
  adminId,
  adminFullName,
}: Props) => {
  return (
    <ModalCard isActive onClose={onClose} isLarge>
      <ModalCardHead>
        <ModalCardTitle>{__('Administered entities')}</ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody>
        <p className="mb-4">
          {__(
            '%1 manages the following entities with the %2 role.',
            adminFullName,
            <b>{displayableRoleName(roleName)}</b>
          )}
        </p>
        <EntityList
          roleName={roleName}
          adminId={adminId}
          paginationType="state"
          defaultPaginationParams={{ countPerPage: 8 }}
        />
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Close')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default AdministeredEntitiesModal;
