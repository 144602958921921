import React, { Fragment } from 'react';

import type { OneOnOneUserReview, User } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n/index';

import { put } from 'redux/actions/api';

import { MenuItem, Toggleable } from 'components';

import UpdateModal from './UpdateModal';

type Props = {
  userReview: OneOnOneUserReview;
  onAfterAction: () => Promise<void>;
};

const UpdateAdditionalReviewerAction = ({
  userReview,
  onAfterAction,
}: Props) => {
  const dispatch = useAppDispatch();
  const updateAdditionalReviewer = async (additionalReviewer?: User | null) => {
    await dispatch(
      put(`user_reviews/${userReview.id}/additional_reviewer`, {
        additional_reviewer_id: additionalReviewer?.id,
      })
    );
    return onAfterAction();
  };

  return (
    <Toggleable
      render={(isToggled, toggle) => (
        <Fragment>
          <MenuItem onClick={toggle}>
            {userReview.additionalReviewer
              ? __('Edit or delete additional reviewer')
              : __('Add an additional reviewer')}
          </MenuItem>

          {isToggled && (
            <UpdateModal
              isActive
              userReview={userReview}
              onSubmit={async additionalReviewer => {
                await updateAdditionalReviewer(additionalReviewer);

                toggle();
              }}
              onClose={toggle}
            />
          )}
        </Fragment>
      )}
    />
  );
};

export default UpdateAdditionalReviewerAction;
