import React, { ReactNode } from 'react';

import invariant from 'helpers/invariant';

import * as i18n from './vendor/gettext';

if (process.env.REACT_APP_DEBUG_LOC) {
  i18n.setPostTransformation((translation: string | ReactNode[]) => {
    return (
      <span>
        __
        {translation}
        __
      </span>
    );
  });
}

type I18nArgWithElements = ReactNode;
type I18nArgWithoutElements = string | number | null | undefined;

// We use `__` as the i18n function, as other dependencies (maybe Raven?) use the `_` alias for Lodash
export const __: {
  (arg1: string, ...args: I18nArgWithoutElements[]): string;
  (arg1: string, ...args: I18nArgWithElements[]): ReactNode[];
} = i18n.__.bind(i18n);

export const n__: {
  (
    arg1: string,
    arg2: string,
    arg3: number,
    ...args: I18nArgWithoutElements[]
  ): string;
  (
    arg1: string,
    arg2: string,
    arg3: number,
    ...args: I18nArgWithElements[]
  ): ReactNode[];
} = i18n._n.bind(i18n);

export const toSentence = (itemNames: Array<string>): string => {
  invariant(
    itemNames.length > 0,
    'This method should not be used for empty arrays'
  );

  if (itemNames.length === 1) return itemNames[0];

  const firstItemNames = itemNames.slice(0, -1);
  const lastItemName = itemNames[itemNames.length - 1];

  return [firstItemNames.join(', '), lastItemName].join(__(' and '));
};
