import { kebabCase } from 'lodash';
import React, { Fragment } from 'react';

import { SurveyQuestionStats } from 'models';

import { navigate } from 'helpers/navigation';
import { pathToSurveyCampaignResults } from 'helpers/paths';

import { Box, Button, Icon, Text } from 'components';

import { getStepContentStyles } from '../helpers/steps';

type Props = {
  questionStats: SurveyQuestionStats;
  campaignId: string;
  selectedPeriodId: string;
};

const QuestionSlider = ({
  questionStats,
  campaignId,
  selectedPeriodId,
}: Props) => {
  if (!questionStats) {
    return <Fragment />;
  }

  const {
    question: { questionType },
  } = questionStats;
  const { iconName } = getStepContentStyles()[questionType];
  const { questionNumber, questionIds } = questionStats;
  const previousQuestionId = questionIds[questionNumber - 2] || null;
  const nextQuestionId = questionIds[questionNumber] || null;

  return (
    <Box
      additionalClassName={`survey-question-stats survey-box-question survey-box-question--${kebabCase(
        questionType
      )}`}
    >
      <div className="flex">
        <Button
          hasIconOnly
          onClick={() =>
            navigate(
              pathToSurveyCampaignResults(
                campaignId,
                previousQuestionId,
                selectedPeriodId
              )
            )
          }
          disabled={!previousQuestionId}
          isText
          size="small"
          additionalClassName="border-none bg-transparent"
          style={{ textDecoration: 'none' }}
        >
          <Icon
            color="primary"
            name="west"
            additionalClassName={`survey-question-icon text-xl ml-4`}
          />
        </Button>
        <Icon
          name={iconName}
          additionalClassName={`survey-question-icon survey-box-question-icon text-xl mr-4`}
        />
        <div className="flex items-center flex-1">
          <Text preset="16bs5.5">
            {questionStats.questionNumber} - {questionStats.question.title}
          </Text>
        </div>
        <Button
          hasIconOnly
          onClick={() =>
            navigate(
              pathToSurveyCampaignResults(
                campaignId,
                nextQuestionId,
                selectedPeriodId
              )
            )
          }
          disabled={!nextQuestionId}
          isText
          size="small"
          additionalClassName="border-none bg-transparent"
          testClassName="test-survey-next-question-button"
          style={{ textDecoration: 'none' }}
        >
          <Icon
            color="primary"
            name="east"
            additionalClassName={`survey-question-icon text-xl ml-4`}
          />
        </Button>
      </div>
    </Box>
  );
};

export default QuestionSlider;
