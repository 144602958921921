import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { InteractionType } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n/index';
import 'helpers/navigation';
import {
  navigate,
  pathToReviewCycleEdit,
  pathToReviewCycles,
} from 'helpers/navigation';
import { useQuery } from 'helpers/navigation';
import { trackAction } from 'helpers/tracking';

import { post } from 'redux/actions/api';

import { Button, DesignSystem, PageHeader, PageTitle } from 'components';

import Stepper from 'scenes/components/Stepper';

import getSetupStepInfoList from '../helpers/steps';
import Step1Name from './Step1Name';

type Props = {};

type AfterConnectProps = Props & {
  createReviewCycleAndRedirect: (params: any) => Promise<void>;
};

function New({ createReviewCycleAndRedirect }: AfterConnectProps) {
  const query = useQuery();
  const type = query.get('type') as InteractionType;

  return (
    <DesignSystem version={2}>
      <div className="review-cycle-setup">
        <PageHeader
          title={__('New campaign')}
          actions={
            <Button color="danger" isOutlined disabled>
              {__('Delete campaign')}
            </Button>
          }
          withBackButton
          backButtonProps={{
            target: type ? pathToReviewCycles(type) : null,
            children: __('Back to campaigns'),
          }}
        />
        <PageTitle title={[__('New campaign'), __('Admin')]} />

        <Stepper
          currentStepIndexFromElement={0}
          stepInfoList={getSetupStepInfoList(null)}
          renderChildren={() => (
            <Step1Name
              cycleType={type}
              createReviewCycleAndRedirect={createReviewCycleAndRedirect}
            />
          )}
        />
      </div>
    </DesignSystem>
  );
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createReviewCycleAndRedirect: async params => {
    const response = await dispatch(post('review_cycles', params));
    const reviewCycleId = response.response.body.data.id;
    trackAction('Review cycle draft created', {
      reviewCycleId: reviewCycleId,
    });
    navigate(pathToReviewCycleEdit(reviewCycleId));
  },
});

export default compose(connect(null, mapDispatchToProps))(
  New
) as React.ComponentType<Props>;
