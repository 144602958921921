import React from 'react';

import { ReviewCycle } from 'models/ReviewCycle';

import { __ } from 'helpers/i18n';

import { BoxListItem, Column, Columns, Text } from 'components';

import IconColumn from './IconColumn';

type Props = {
  additionalReviewerEnabled: boolean;
  reviewCycle: ReviewCycle;
};

export default function StandardCycleUserReviewListHeader({
  additionalReviewerEnabled,
  reviewCycle,
}: Props) {
  const columnSize = additionalReviewerEnabled ? 4 : 6;

  return (
    <BoxListItem isSmall>
      <Columns>
        <IconColumn reviewCycle={reviewCycle} />
        <Column size={10}>
          <Columns>
            <Column size={columnSize}>
              <Text preset="14bs6" color="light">
                {__('Reviewee')}
              </Text>
            </Column>
            <Column size={columnSize}>
              <Text preset="14bs6" color="light">
                {__('Reviewer')}
              </Text>
            </Column>
            {additionalReviewerEnabled && (
              <Column size={4}>
                <Text preset="14bs6" color="light">
                  {__('Additional reviewer')}
                </Text>
              </Column>
            )}
          </Columns>
        </Column>
      </Columns>
    </BoxListItem>
  );
}
