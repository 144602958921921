import React, { useState } from 'react';

import { ReviewCycle, TargetedCampaignRole, User } from 'models';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useAppDispatch, useOrganization } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import { zendeskURL } from 'helpers/zendesk';

import { post } from 'redux/actions/api';

import {
  BlueInfoBox,
  Button,
  Link,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';

import UserPicker from 'scenes/components/UserPicker';

import RoleNameSelect from './RoleNameSelect';

type Props = {
  reviewCycleId: string;
  reviewCycleName: string;
  reviewCycleType: ReviewCycle['interactionType'];
  onClose: () => void;
};

const RolesManagementModal = ({
  reviewCycleId,
  reviewCycleName,
  reviewCycleType,
  onClose,
}: Props) => {
  const organization = useOrganization();
  const multiLevelAdminEnabled = organization.plan.multiLevelAdminEnabled;

  const dispatch = useAppDispatch();
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [selectedRoleName, setSelectedRoleName] = useState<
    TargetedCampaignRole['name']
  >('targeted_campaign_supervisor');

  const assignRoles = async () => {
    await dispatch(
      post(
        `roles/bulk_assign`,
        {
          resource: { type: 'ReviewCycle', id: reviewCycleId },
          userIds: selectedUsers.map(user => user.id),
          roleName: selectedRoleName,
        },
        {
          successMessage: __(
            'Administrator permissions on the campaign have been successfully granted to the selected users.'
          ),
        }
      )
    );
    onClose();
  };

  const documentationURL =
    reviewCycleType === 'one_on_one'
      ? zendeskURL({
          en: '28311627437585-Give-admin-rights-to-a-1-1-review-campaign',
          fr: '28311627437585-Donner-des-droits-admin-sur-une-campagne-d-entretien-1-1',
        })
      : zendeskURL({
          en: '28311710525969-Give-admin-rights-to-a-360-feedback-campaign',
          fr: '28311710525969-Donner-des-droits-admin-sur-une-campagne-de-feedback-360',
        });

  return (
    <ModalCard onClose={onClose} isActive isBig>
      <ModalCardHead>
        <ModalCardTitle>
          {__('Grant admin permissions on the campaign')}
        </ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <div className="mb-2">
          <Text additionalClassName="block mb-4">
            {__(
              'Grant admin permissions on the "%1" campaign by assigning a role to users. For more information, visit our %2.',
              reviewCycleName,
              <Link
                to={documentationURL}
                additionalClassName="text-primary"
                openInNewTab
              >
                {__('help center')}
              </Link>
            )}
          </Text>
          <div className="mb-4">
            <Text preset="14bs6">{__('Select one or more users')}</Text>
            <Text additionalClassName="block mb-2">
              {__(
                'Users will be notified by email when they are granted permissions on the campaign'
              )}
            </Text>
            <UserPicker
              value={selectedUsers}
              isMulti
              inModal
              isClearable
              placeholder={__('Select one or more users')}
              onChange={users =>
                Array.isArray(users) && setSelectedUsers(users)
              }
              additionalClassName="w-full"
              fetchParams={{
                without_admin_rights_on_the_review_campaign: reviewCycleId,
              }}
            />
          </div>
          <Text preset="14bs6" additionalClassName="block mb-2">
            {__('Select a role')}
          </Text>
          <RoleNameSelect
            selectedRoleName={selectedRoleName}
            onChange={roleName => setSelectedRoleName(roleName)}
          />
          {multiLevelAdminEnabled && (
            <BlueInfoBox
              text={n__(
                'The selected user will see every users in the campaign regardless of their entity',
                'The selected users will see every users in the campaign regardless of their entity',
                selectedUsers.length
              )}
              additionalClassName="mt-4"
            />
          )}
        </div>
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Cancel')}
        </Button>
        <Button
          color="primary"
          onClick={assignRoles}
          disabled={selectedUsers.length === 0}
        >
          {__('Grant permissions')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default RolesManagementModal;
