import React from 'react';
import { components } from 'react-select';

import type { Option as UserOption } from './index';
import type { OptionProps } from 'react-select';

import UserAvatar from 'scenes/components/UserAvatar';

type Option = OptionProps & { data: UserOption };

export function UserPickerOption(props: Option) {
  const { data, isDisabled } = props;

  return (
    <components.Option {...props}>
      <UserAvatar user={data.user} isDisabled={isDisabled} withJobTitle />
    </components.Option>
  );
}
