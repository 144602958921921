import React from 'react';

import type { BlockFeedbackOptions, InteractionType } from 'models';

import { handleFormErrors } from 'helpers/api';
import { somethingWentWrong } from 'helpers/commonPhrases';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { htmlErrorNotice, htmlWarningNotice } from 'redux/actions';
import { put } from 'redux/actions/api';

import { Title } from 'components';

import FeedbackOption from '../components/FeedbackOptionsSection/FeedbackOption';

type Props = {
  revieweeFeedbackOptions: BlockFeedbackOptions | undefined;
  reviewerFeedbackOptions: BlockFeedbackOptions;
  hasSelfEvaluationEnabled: boolean;
  interactionType: InteractionType;
};

const FeedbackOptionsSection = ({
  revieweeFeedbackOptions,
  reviewerFeedbackOptions,
  hasSelfEvaluationEnabled,
  interactionType,
}: Props) => {
  const dispatch = useAppDispatch();

  const onChange = async (
    feedbackOptionId: string,
    attributes: Partial<BlockFeedbackOptions>
  ) =>
    handleFormErrors(
      () =>
        dispatch(
          put(
            `feedback_options/${feedbackOptionId}`,
            { feedbackOption: attributes },
            { withDefaultErrorHandling: false }
          )
        ),
      errors => {
        if (errors.invalidFeedbackOptions) {
          dispatch(
            htmlWarningNotice(
              __(
                '<b>Oops, this configuration is not possible</b><br />For the question to be visible either the reviewee or the reviewer must be able to answer it 🙂'
              )
            )
          );
        } else {
          dispatch(htmlErrorNotice(somethingWentWrong()));
        }
      }
    );

  const revieweeLabel = () =>
    __('The reviewee') +
    (!hasSelfEvaluationEnabled ? __(' (Self evaluation is disabled)') : '');

  const reviewerLabel = () => {
    if (interactionType === '360' && hasSelfEvaluationEnabled)
      return __('The reviewee and the peers');

    if (interactionType === '360' && !hasSelfEvaluationEnabled)
      return __('The peers');

    return __('The reviewer');
  };

  return (
    <div className="feedback-options">
      <Title size={7} transformation="uppercase" style={{ marginBottom: 8 }}>
        {__('Who can answer the question?')}
      </Title>

      {interactionType === 'one_on_one' && (
        <FeedbackOption
          testClassName="test-reviewee-text-enabled-toggle"
          mandatoryDisabled={!hasSelfEvaluationEnabled}
          feedbackOptions={revieweeFeedbackOptions}
          isDisabled={!hasSelfEvaluationEnabled}
          label={revieweeLabel()}
          onChange={onChange}
          feedbackType="text"
          withMandatory
        />
      )}

      <FeedbackOption
        feedbackOptions={reviewerFeedbackOptions}
        label={reviewerLabel()}
        isDisabled={interactionType === '360'}
        testClassName="test-reviewer-text-enabled-toggle"
        onChange={onChange}
        feedbackType="text"
        withMandatory
      />
    </div>
  );
};

export default FeedbackOptionsSection;
