import React, { Fragment } from 'react';

import { AdminUser } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { useActiveUser } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { post } from 'redux/actions/api';

import { StrictlySanitizedHtml } from 'components';

import uniqueRolesCount from '../../../helpers/uniqueRolesCount';
import humanizeRoleNamesList from '../helpers/humanizeRoleNamesList';

const useRevokeRolesAction = (user: AdminUser, refetchData: () => void) => {
  const activeUser = useActiveUser();
  const dispatch = useAppDispatch();

  const removeRoles = async () => {
    await dispatch(
      post(
        `users/${user.id}/admin_roles/revoke`,
        {},
        {
          successMessage: n__(
            "%2's %3 has been successfully revoked.",
            "%2's %3 have been successfully revoked.",
            uniqueRolesCount(user.roles),
            user.fullName,
            humanizeRoleNamesList(user.roles, { capitalize: true })
          ),
        }
      )
    ).then(() =>
      user.id === activeUser.id ? window.location.reload() : refetchData()
    );
  };

  return {
    revokeRolesAction: async () =>
      confirmAsync(
        n__(
          'Revoke the role?',
          'Revoke all roles?',
          uniqueRolesCount(user.roles),
          user.fullName
        ),
        <Fragment>
          <p>
            <StrictlySanitizedHtml
              html={__(
                'By revoking %1 from %2, <b>all associated permissions will be removed</b>.',
                humanizeRoleNamesList(user.roles, { capitalize: false }),
                user.fullName
              )}
            />
          </p>
        </Fragment>,
        {
          confirmLabel: n__(
            'Revoke the role',
            'Revoke all roles',
            user.roles.length
          ),
          isDanger: true,
          onConfirm: removeRoles,
        }
      ),
  };
};

export default useRevokeRolesAction;
