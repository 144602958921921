import React from 'react';

import { __ } from 'helpers/i18n';
import { pathToAuditReportDetails } from 'helpers/navigation';

import { BoxListItem, Column, Columns, Link, Tag } from 'components';

const AuditReportListItem = ({ auditReport }) => (
  <BoxListItem>
    <Columns
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
      isMobile
    >
      <Column size={5}>
        <Link hasInheritedColor to={pathToAuditReportDetails(auditReport.id)}>
          {auditReport.title}
        </Link>
      </Column>
      <Column size={4} additionalClassName="flex gap-2 flex-wrap">
        {auditReport.tags.map(tag => (
          <Tag key={`tag-${tag.id}`}>{tag.label}</Tag>
        ))}
      </Column>
      <Column size={2}>
        {auditReport.delayInMonths} {__('months')}
      </Column>
    </Columns>
  </BoxListItem>
);

export default AuditReportListItem;
