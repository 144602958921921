const dynamicSvg = `<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="40" cy="40" r="40" fill="#E8F4FA"/>
<g clip-path="url(#clip05097D6C-B377-4D06-A21D-36F6B5008CA7)">
<path d="M40.0121 23V32" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M40.0121 34.5C41.1167 34.5 42.0121 33.6046 42.0121 32.5C42.0121 31.3954 41.1167 30.5 40.0121 30.5C38.9075 30.5 38.0121 31.3954 38.0121 32.5C38.0121 33.6046 38.9075 34.5 40.0121 34.5Z" fill="white" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M47.0121 49.5C47.0096 49.2153 46.9087 48.9402 46.7264 48.7214C46.5441 48.5027 46.2917 48.3538 46.0121 48.3V47.5C46.0121 45.9087 45.38 44.3826 44.2548 43.2574C43.1295 42.1322 41.6034 41.5 40.0121 41.5C38.4208 41.5 36.8947 42.1322 35.7695 43.2574C34.6443 44.3826 34.0121 45.9087 34.0121 47.5V48.3C33.7205 48.3546 33.4586 48.5133 33.2751 48.7464C33.0916 48.9795 32.999 49.2713 33.0143 49.5676C33.0297 49.8639 33.1521 50.1446 33.3588 50.3574C33.5655 50.5703 33.8424 50.7009 34.1381 50.725C34.4192 52.0749 35.1567 53.2869 36.2266 54.1569C37.2964 55.0269 38.6332 55.5018 40.0121 55.5018C41.391 55.5018 42.7279 55.0269 43.7977 54.1569C44.8675 53.2869 45.605 52.0749 45.8861 50.725C46.1924 50.6972 46.4775 50.5563 46.6856 50.3299C46.8938 50.1034 47.0101 49.8076 47.0121 49.5Z" fill="#FFCEBF" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M36.7621 51C37.1763 51 37.5121 50.6642 37.5121 50.25C37.5121 49.8358 37.1763 49.5 36.7621 49.5C36.3479 49.5 36.0121 49.8358 36.0121 50.25C36.0121 50.6642 36.3479 51 36.7621 51Z" fill="#FFB59E"/>
<path d="M43.2621 51C43.6763 51 44.0121 50.6642 44.0121 50.25C44.0121 49.8358 43.6763 49.5 43.2621 49.5C42.8479 49.5 42.5121 49.8358 42.5121 50.25C42.5121 50.6642 42.8479 51 43.2621 51Z" fill="#FFB59E"/>
<path d="M38.5121 52.046C38.9564 52.3419 39.4783 52.4998 40.0121 52.4998C40.5459 52.4998 41.0678 52.3419 41.5121 52.046" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M46.5121 46C46.5121 44.2761 45.8273 42.6228 44.6083 41.4038C43.3893 40.1848 41.736 39.5 40.0121 39.5C38.2882 39.5 36.6349 40.1848 35.4159 41.4038C34.1969 42.6228 33.5121 44.2761 33.5121 46V47.5C33.5121 47.6326 33.5648 47.7598 33.6585 47.8536C33.7523 47.9473 33.8795 48 34.0121 48H35.0121C35.1447 48 35.2719 47.9473 35.3656 47.8536C35.4594 47.7598 35.5121 47.6326 35.5121 47.5C35.5127 46.9047 35.6329 46.3156 35.8655 45.7676C36.0981 45.2197 36.4383 44.724 36.8661 44.31C36.9754 44.2026 37.1079 44.1218 37.2533 44.0738C37.3988 44.0258 37.5534 44.0118 37.7051 44.033C38.3344 44.1134 38.9188 44.4021 39.3651 44.853C39.9564 45.4631 40.7364 45.8557 41.5787 45.9673C42.421 46.079 43.2762 45.903 44.0061 45.468C44.2584 45.9549 44.4207 46.4834 44.4851 47.028C44.4998 47.1576 44.5616 47.2773 44.6588 47.3643C44.7559 47.4513 44.8817 47.4996 45.0121 47.5H46.0121C46.1447 47.5 46.2719 47.4473 46.3656 47.3536C46.4594 47.2598 46.5121 47.1326 46.5121 47V46Z" fill="#FFDF3D" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M40.0122 41.5C41.3124 41.4998 42.5903 41.8376 43.7205 42.4803C44.8508 43.123 45.7945 44.0485 46.4592 45.166C46.2578 43.5985 45.4921 42.158 44.3055 41.1141C43.1189 40.0702 41.5926 39.4944 40.0122 39.4944C38.4317 39.4944 36.9055 40.0702 35.7188 41.1141C34.5322 42.158 33.7666 43.5985 33.5652 45.166C34.2298 44.0485 35.1736 43.123 36.3038 42.4803C37.4341 41.8376 38.712 41.4998 40.0122 41.5Z" fill="#FFEF9E"/>
<path d="M46.5121 46C46.5121 44.2761 45.8273 42.6228 44.6083 41.4038C43.3893 40.1848 41.736 39.5 40.0121 39.5C38.2882 39.5 36.6349 40.1848 35.4159 41.4038C34.1969 42.6228 33.5121 44.2761 33.5121 46V47.5C33.5121 47.6326 33.5648 47.7598 33.6585 47.8536C33.7523 47.9473 33.8795 48 34.0121 48H35.0121C35.1447 48 35.2719 47.9473 35.3656 47.8536C35.4594 47.7598 35.5121 47.6326 35.5121 47.5C35.5127 46.9047 35.6329 46.3156 35.8655 45.7676C36.0981 45.2197 36.4383 44.724 36.8661 44.31C36.9754 44.2026 37.1079 44.1218 37.2533 44.0738C37.3988 44.0258 37.5534 44.0118 37.7051 44.033C38.3344 44.1134 38.9188 44.4021 39.3651 44.853C39.9564 45.4631 40.7364 45.8557 41.5787 45.9673C42.421 46.079 43.2762 45.903 44.0061 45.468C44.2584 45.9549 44.4207 46.4834 44.4851 47.028C44.4998 47.1576 44.5616 47.2773 44.6588 47.3643C44.7559 47.4513 44.8817 47.4996 45.0121 47.5H46.0121C46.1447 47.5 46.2719 47.4473 46.3656 47.3536C46.4594 47.2598 46.5121 47.1326 46.5121 47V46Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M44.0061 45.468C44.0585 45.4364 44.1005 45.3901 44.127 45.3348C44.1534 45.2796 44.1632 45.2179 44.155 45.1572C44.1468 45.0966 44.121 45.0396 44.0809 44.9933C44.0408 44.9471 43.988 44.9136 43.9291 44.897C43.1431 44.6765 42.4627 44.1807 42.0121 43.5" fill="#FFDF3D"/>
<path d="M44.0061 45.468C44.0585 45.4364 44.1005 45.3901 44.127 45.3348C44.1534 45.2796 44.1632 45.2179 44.155 45.1572C44.1468 45.0966 44.121 45.0396 44.0809 44.9933C44.0408 44.9471 43.988 44.9136 43.9291 44.897C43.1431 44.6765 42.4627 44.1807 42.0121 43.5" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M37.7621 48.5C37.9002 48.5 38.0121 48.3881 38.0121 48.25C38.0121 48.1119 37.9002 48 37.7621 48C37.624 48 37.5121 48.1119 37.5121 48.25C37.5121 48.3881 37.624 48.5 37.7621 48.5Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M42.2621 48.5C42.4002 48.5 42.5121 48.3881 42.5121 48.25C42.5121 48.1119 42.4002 48 42.2621 48C42.124 48 42.0121 48.1119 42.0121 48.25C42.0121 48.3881 42.124 48.5 42.2621 48.5Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M40.0121 39.5C40.864 39.4996 41.7077 39.6673 42.4948 39.9933C43.2819 40.3193 43.9969 40.7973 44.5991 41.4C44.866 40.9816 45.0092 40.4963 45.0121 40C45.0121 38.067 42.7731 36.5 40.0121 36.5C37.2511 36.5 35.0121 38.067 35.0121 40C35.0145 40.4963 35.1577 40.9818 35.4251 41.4C36.0272 40.7973 36.7423 40.3193 37.5294 39.9933C38.3165 39.6673 39.1601 39.4996 40.0121 39.5Z" fill="#FFDF3D" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M70.9121 56C70.9121 57.9096 70.1535 59.7409 68.8033 61.0912C67.453 62.4414 65.6217 63.2 63.7121 63.2C61.8025 63.2 59.9712 62.4414 58.6209 61.0912C57.2706 59.7409 56.5121 57.9096 56.5121 56C56.5121 52.019 58.5451 48.9 60.6121 48.3C60.7844 48.2505 60.9668 48.2483 61.1403 48.2935C61.3137 48.3387 61.4719 48.4297 61.5981 48.557C63.8361 50.809 70.9121 48 70.9121 56Z" fill="#FFDF3D"/>
<path d="M61.3121 50.6C63.0341 53.329 70.0061 50.417 70.8311 57.027C70.8825 56.687 70.9095 56.3439 70.9121 56C70.9121 48 63.8351 50.8091 61.5981 48.5591C61.4719 48.4318 61.3137 48.3407 61.1403 48.2955C60.9668 48.2503 60.7844 48.2526 60.6121 48.302C58.5451 48.896 56.5121 52.019 56.5121 56.002C56.5148 56.354 56.5436 56.7053 56.5981 57.053C57.0431 53.3 59.2711 50.6 61.3121 50.6Z" fill="#FFEF9E"/>
<path d="M70.9121 56C70.9121 57.9096 70.1535 59.7409 68.8033 61.0912C67.453 62.4414 65.6217 63.2 63.7121 63.2C61.8025 63.2 59.9712 62.4414 58.6209 61.0912C57.2706 59.7409 56.5121 57.9096 56.5121 56C56.5121 52.019 58.5451 48.9 60.6121 48.3C60.7844 48.2505 60.9668 48.2483 61.1403 48.2935C61.3137 48.3387 61.4719 48.4297 61.5981 48.557C63.8361 50.809 70.9121 48 70.9121 56Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M69.7201 57.8L68.5111 58.4L67.6391 55.348C67.5575 55.0553 67.367 54.8048 67.1068 54.6479C66.8465 54.491 66.5361 54.4395 66.2391 54.504C64.5775 54.8986 62.8467 54.8986 61.1851 54.504C60.8881 54.4395 60.5777 54.491 60.3174 54.6479C60.0572 54.8048 59.8667 55.0553 59.7851 55.348L58.9121 58.4L57.7211 57.8C56.0091 57.8 56.1211 60.8 58.0211 60.8H58.0261C58.4023 62.0165 59.1581 63.0807 60.1829 63.8365C61.2078 64.5924 62.4477 65.0002 63.7211 65.0002C64.9945 65.0002 66.2344 64.5924 67.2593 63.8365C68.2841 63.0807 69.0399 62.0165 69.4161 60.8C71.3121 60.8 71.4321 57.8 69.7201 57.8Z" fill="#FFCEBF" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M61.7761 58.2C61.9142 58.2 62.0261 58.0881 62.0261 57.95C62.0261 57.8119 61.9142 57.7 61.7761 57.7C61.6381 57.7 61.5261 57.8119 61.5261 57.95C61.5261 58.0881 61.6381 58.2 61.7761 58.2Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M65.676 58.2C65.8141 58.2 65.926 58.0881 65.926 57.95C65.926 57.8119 65.8141 57.7 65.676 57.7C65.538 57.7 65.426 57.8119 65.426 57.95C65.426 58.0881 65.538 58.2 65.676 58.2Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M60.7261 59.6C60.7261 59.7186 60.6909 59.8347 60.625 59.9333C60.5591 60.032 60.4653 60.1089 60.3557 60.1544C60.2461 60.1998 60.1255 60.2116 60.0091 60.1885C59.8927 60.1653 59.7858 60.1082 59.7018 60.0243C59.6179 59.9404 59.5608 59.8334 59.5377 59.717C59.5145 59.6007 59.5264 59.48 59.5718 59.3704C59.6172 59.2607 59.6941 59.1671 59.7928 59.1011C59.8915 59.0352 60.0075 59 60.1261 59C60.2049 59 60.2829 59.0155 60.3557 59.0457C60.4285 59.0758 60.4947 59.12 60.5504 59.1757C60.6061 59.2314 60.6503 59.2976 60.6805 59.3704C60.7106 59.4432 60.7261 59.5212 60.7261 59.6Z" fill="#FFB59E"/>
<path d="M67.9261 59.6C67.9261 59.7186 67.8909 59.8347 67.825 59.9333C67.759 60.032 67.6653 60.1089 67.5557 60.1544C67.4461 60.1998 67.3254 60.2116 67.209 60.1885C67.0926 60.1653 66.9857 60.1082 66.9018 60.0243C66.8179 59.9404 66.7608 59.8334 66.7376 59.717C66.7145 59.6007 66.7263 59.48 66.7718 59.3704C66.8172 59.2607 66.8941 59.1671 66.9927 59.1011C67.0914 59.0352 67.2074 59 67.3261 59C67.4049 59 67.4829 59.0155 67.5557 59.0457C67.6285 59.0758 67.6946 59.12 67.7503 59.1757C67.806 59.2314 67.8502 59.2976 67.8804 59.3704C67.9105 59.4432 67.9261 59.5212 67.9261 59.6Z" fill="#FFB59E"/>
<path d="M62.228 61.546C62.672 61.8427 63.194 62.001 63.728 62.001C64.262 62.001 64.784 61.8427 65.228 61.546" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.012 60C24.0097 59.7152 23.9088 59.44 23.7265 59.2213C23.5442 59.0025 23.2917 58.8536 23.012 58.8V58C23.012 56.4087 22.3799 54.8826 21.2547 53.7574C20.1295 52.6322 18.6033 52 17.012 52C15.4207 52 13.8946 52.6322 12.7694 53.7574C11.6442 54.8826 11.012 56.4087 11.012 58V58.8C10.7198 58.8538 10.4572 59.0121 10.2731 59.2452C10.089 59.4784 9.99599 59.7706 10.0114 60.0673C10.0268 60.364 10.1496 60.645 10.3569 60.8578C10.5642 61.0706 10.8418 61.2008 11.138 61.224C11.4191 62.574 12.1567 63.7859 13.2265 64.6559C14.2963 65.5259 15.6331 66.0009 17.012 66.0009C18.3909 66.0009 19.7278 65.5259 20.7976 64.6559C21.8674 63.7859 22.6049 62.574 22.886 61.224C23.1922 61.1962 23.4771 61.0555 23.6852 60.8292C23.8933 60.603 24.0098 60.3074 24.012 60Z" fill="#D18660" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.0121 54.5C18.1847 54.4743 19.3454 54.7411 20.3891 55.2764C21.4328 55.8117 22.3267 56.5986 22.9901 57.566C22.8829 56.0537 22.2065 54.6382 21.0972 53.6048C19.9879 52.5714 18.5281 51.9968 17.0121 51.9968C15.496 51.9968 14.0362 52.5714 12.9269 53.6048C11.8177 54.6382 11.1413 56.0537 11.0341 57.566C11.6974 56.5986 12.5913 55.8117 13.635 55.2764C14.6787 54.7411 15.8394 54.4743 17.0121 54.5Z" fill="#FFE8E0"/>
<path d="M24.012 60C24.0097 59.7152 23.9088 59.44 23.7265 59.2213C23.5442 59.0025 23.2917 58.8536 23.012 58.8V58C23.012 56.4087 22.3799 54.8826 21.2547 53.7574C20.1295 52.6322 18.6033 52 17.012 52C15.4207 52 13.8946 52.6322 12.7694 53.7574C11.6442 54.8826 11.012 56.4087 11.012 58V58.8C10.7198 58.8538 10.4572 59.0121 10.2731 59.2452C10.089 59.4784 9.99599 59.7706 10.0114 60.0673C10.0268 60.364 10.1496 60.645 10.3569 60.8578C10.5642 61.0706 10.8418 61.2008 11.138 61.224C11.4191 62.574 12.1567 63.7859 13.2265 64.6559C14.2963 65.5259 15.6331 66.0009 17.012 66.0009C18.3909 66.0009 19.7278 65.5259 20.7976 64.6559C21.8674 63.7859 22.6049 62.574 22.886 61.224C23.1922 61.1962 23.4771 61.0555 23.6852 60.8292C23.8933 60.603 24.0098 60.3074 24.012 60V60Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.7621 59C14.9002 59 15.0121 58.8881 15.0121 58.75C15.0121 58.6119 14.9002 58.5 14.7621 58.5C14.624 58.5 14.5121 58.6119 14.5121 58.75C14.5121 58.8881 14.624 59 14.7621 59Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.2621 59C19.4002 59 19.5121 58.8881 19.5121 58.75C19.5121 58.6119 19.4002 58.5 19.2621 58.5C19.124 58.5 19.0121 58.6119 19.0121 58.75C19.0121 58.8881 19.124 59 19.2621 59Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.7621 61.5C14.1763 61.5 14.5121 61.1642 14.5121 60.75C14.5121 60.3358 14.1763 60 13.7621 60C13.3479 60 13.0121 60.3358 13.0121 60.75C13.0121 61.1642 13.3479 61.5 13.7621 61.5Z" fill="#BD704A"/>
<path d="M20.2621 61.5C20.6763 61.5 21.0121 61.1642 21.0121 60.75C21.0121 60.3358 20.6763 60 20.2621 60C19.8479 60 19.5121 60.3358 19.5121 60.75C19.5121 61.1642 19.8479 61.5 20.2621 61.5Z" fill="#BD704A"/>
<path d="M15.5121 62.546C15.9564 62.8419 16.4783 62.9998 17.0121 62.9998C17.5459 62.9998 18.0678 62.8419 18.5121 62.546" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.5121 53.5V58C10.5121 58.1326 10.5648 58.2598 10.6585 58.3536C10.7523 58.4473 10.8795 58.5 11.0121 58.5H11.5121L13.5121 53.5H10.5121Z" fill="#614B44" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M23.512 54C23.512 52.9391 23.0906 51.9217 22.3405 51.1716C21.5903 50.4214 20.5729 50 19.512 50H15.012C14.8211 50.0001 14.6342 49.9455 14.4733 49.8428C14.3124 49.74 14.1842 49.5932 14.1041 49.42C14.0516 49.3037 13.9691 49.2035 13.865 49.1297C13.761 49.0559 13.6391 49.0111 13.512 49H12.662C11.8961 48.9849 11.1517 49.254 10.5724 49.7554C9.99317 50.2567 9.62004 50.9548 9.52504 51.715C9.45018 52.4997 9.68717 53.2823 10.1847 53.8937C10.6823 54.505 11.4005 54.8959 12.184 54.982C12.3255 55.5127 12.6231 55.9888 13.0381 56.3486C13.4531 56.7083 13.9666 56.9353 14.512 57H15.512C15.5519 57.0003 15.5907 56.9874 15.6224 56.9634C15.6542 56.9394 15.6771 56.9055 15.6877 56.8671C15.6983 56.8287 15.696 56.7879 15.681 56.751C15.6661 56.7141 15.6394 56.6832 15.605 56.663C15.1509 56.392 14.8202 55.9547 14.683 55.444C14.6672 55.3926 14.6637 55.3382 14.6727 55.2851C14.6818 55.2321 14.7032 55.182 14.7352 55.1388C14.7673 55.0956 14.809 55.0605 14.8571 55.0364C14.9052 55.0123 14.9583 54.9998 15.012 55H19.098C19.3632 55 19.6175 55.1055 19.805 55.293L22.512 58H23.012C23.1447 58 23.2718 57.9473 23.3656 57.8536C23.4594 57.7598 23.512 57.6326 23.512 57.5V54Z" fill="#614B44"/>
<path d="M19.5121 50H15.0121C14.8212 50.0001 14.6342 49.9455 14.4733 49.8428C14.3124 49.74 14.1843 49.5932 14.1041 49.42C14.0517 49.3037 13.9692 49.2035 13.8651 49.1297C13.7611 49.0559 13.6392 49.0111 13.5121 49H12.6621C11.8962 48.9849 11.1518 49.254 10.5725 49.7554C9.99323 50.2567 9.62011 50.9548 9.52511 51.715C9.49174 52.0767 9.52322 52.4414 9.6181 52.792C11.5539 52.3683 13.5305 52.1587 15.5121 52.167C18.2093 52.1381 20.8936 52.5433 23.4621 53.367C23.3116 52.4278 22.8313 51.5729 22.1074 50.9559C21.3834 50.3388 20.4633 49.9999 19.5121 50Z" fill="#947063"/>
<path d="M23.512 54C23.512 52.9391 23.0906 51.9217 22.3405 51.1716C21.5903 50.4214 20.5729 50 19.512 50H15.012C14.8211 50.0001 14.6342 49.9455 14.4733 49.8428C14.3124 49.74 14.1842 49.5932 14.1041 49.42C14.0516 49.3037 13.9691 49.2035 13.865 49.1297C13.761 49.0559 13.6391 49.0111 13.512 49H12.662C11.8961 48.9849 11.1517 49.254 10.5724 49.7554C9.99317 50.2567 9.62004 50.9548 9.52504 51.715C9.45018 52.4997 9.68717 53.2823 10.1847 53.8937C10.6823 54.505 11.4005 54.8959 12.184 54.982C12.3255 55.5127 12.6231 55.9888 13.0381 56.3486C13.4531 56.7083 13.9666 56.9353 14.512 57H15.512C15.5519 57.0003 15.5907 56.9874 15.6224 56.9634C15.6542 56.9394 15.6771 56.9055 15.6877 56.8671C15.6983 56.8287 15.696 56.7879 15.681 56.751C15.6661 56.7141 15.6394 56.6832 15.605 56.663C15.1509 56.392 14.8202 55.9547 14.683 55.444C14.6672 55.3926 14.6637 55.3382 14.6727 55.2851C14.6818 55.2321 14.7032 55.182 14.7352 55.1388C14.7673 55.0956 14.809 55.0605 14.8571 55.0364C14.9052 55.0123 14.9583 54.9998 15.012 55H19.098C19.3632 55 19.6175 55.1055 19.805 55.293L22.512 58H23.012C23.1447 58 23.2718 57.9473 23.3656 57.8536C23.4594 57.7598 23.512 57.6326 23.512 57.5V54Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.0121 19.4C45.0121 19.4919 44.994 19.5829 44.9588 19.6679C44.9236 19.7528 44.8721 19.83 44.8071 19.895C44.7421 19.96 44.6649 20.0115 44.58 20.0467C44.495 20.0819 44.404 20.1 44.3121 20.1H35.2121C35.1202 20.1 35.0291 20.0819 34.9442 20.0467C34.8593 20.0115 34.7821 19.96 34.7171 19.895C34.6521 19.83 34.6005 19.7528 34.5654 19.6679C34.5302 19.5829 34.5121 19.4919 34.5121 19.4V8.20001C34.5121 8.01436 34.5858 7.83629 34.7171 7.70502C34.8484 7.57374 35.0264 7.5 35.2121 7.5H41.7121L45.0121 10.8V19.4Z" fill="white" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M41.7122 7.5V10.8H45.0121L41.7122 7.5Z" fill="#E8F4FA" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M36.312 11.1H39.912" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M36.312 13.2H43.212" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M36.312 15.3H43.212" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M36.312 17.4H43.212" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.0121 31.4C22.0121 31.4919 21.994 31.5829 21.9588 31.6679C21.9236 31.7528 21.8721 31.83 21.8071 31.895C21.7421 31.96 21.6649 32.0115 21.58 32.0467C21.495 32.0819 21.404 32.1 21.3121 32.1H12.2121C12.1202 32.1 12.0291 32.0819 11.9442 32.0467C11.8593 32.0115 11.7821 31.96 11.7171 31.895C11.6521 31.83 11.6005 31.7528 11.5654 31.6679C11.5302 31.5829 11.5121 31.4919 11.5121 31.4V20.2C11.5121 20.0144 11.5858 19.8363 11.7171 19.705C11.8484 19.5737 12.0264 19.5 12.2121 19.5H18.7121L22.0121 22.8V31.4Z" fill="white" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.7122 19.5V22.8H22.0121L18.7122 19.5Z" fill="#E8F4FA" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.312 23.1H16.912" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.312 25.2H20.212" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.312 27.3H20.212" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.312 29.4H20.212" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M69.0121 31.4C69.0121 31.4919 68.994 31.5829 68.9588 31.6679C68.9236 31.7528 68.8721 31.83 68.8071 31.895C68.7421 31.96 68.6649 32.0115 68.58 32.0467C68.495 32.0819 68.404 32.1 68.3121 32.1H59.2121C59.1202 32.1 59.0291 32.0819 58.9442 32.0467C58.8593 32.0115 58.7821 31.96 58.7171 31.895C58.6521 31.83 58.6005 31.7528 58.5654 31.6679C58.5302 31.5829 58.5121 31.4919 58.5121 31.4V20.2C58.5121 20.0144 58.5858 19.8363 58.7171 19.705C58.8484 19.5737 59.0264 19.5 59.2121 19.5H65.7121L69.0121 22.8V31.4Z" fill="white" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M65.7122 19.5V22.8H69.0121L65.7122 19.5Z" fill="#E8F4FA" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M60.312 23.1H63.912" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M60.312 25.2H67.212" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M60.312 27.3H67.212" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M60.312 29.4H67.212" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.0121 35V44" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.0121 46.5C17.1167 46.5 18.0121 45.6046 18.0121 44.5C18.0121 43.3954 17.1167 42.5 16.0121 42.5C14.9075 42.5 14.0121 43.3954 14.0121 44.5C14.0121 45.6046 14.9075 46.5 16.0121 46.5Z" fill="white" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M64.0121 35V44" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M64.0121 46.5C65.1167 46.5 66.0121 45.6046 66.0121 44.5C66.0121 43.3954 65.1167 42.5 64.0121 42.5C62.9075 42.5 62.0121 43.3954 62.0121 44.5C62.0121 45.6046 62.9075 46.5 64.0121 46.5Z" fill="white" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip05097D6C-B377-4D06-A21D-36F6B5008CA7">
<rect width="62.428" height="59.5" fill="white" transform="translate(9 7)"/>
</clipPath>
</defs>
</svg>
`;

export default dynamicSvg;
