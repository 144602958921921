import React from 'react';

import { SurveyQuestionChoice } from 'models/surveys';

import { AutoSave, VerticalRadioSelect } from 'components';

export type Props = {
  selectedChoiceId: string | null;
  choices: SurveyQuestionChoice[];
  persistChanges: (choiceId: string | null) => Promise<void>;
  inputName: string;
  isDisabled?: boolean;
};

const SingleQuestionContent = ({
  selectedChoiceId: selectedChoiceIdFromProps,
  choices,
  persistChanges,
  inputName,
  isDisabled,
}: Props) => {
  const [selectedChoiceId, setSelectedChoiceId] = React.useState<string | null>(
    selectedChoiceIdFromProps
  );

  const options = choices.map(choice => ({
    value: choice.id,
    label: choice.label,
  }));
  const selectedOption = options.find(
    option => option.value === selectedChoiceId
  );

  return (
    <AutoSave
      fieldUid={inputName}
      onChange={(choiceId: string | null) => setSelectedChoiceId(choiceId)}
      doPersist={() => persistChanges(selectedChoiceId)}
      render={autoSavingOnChange => (
        <VerticalRadioSelect
          onChange={option =>
            autoSavingOnChange(!!option ? option.value : null)
          }
          selectedOption={selectedOption || null}
          options={options}
          additionalClassName="mt-2 md:mt-8"
          isDisabled={isDisabled}
          testClassName="test-survey-single-choice-select"
        />
      )}
    />
  );
};

export default SingleQuestionContent;
