import React from 'react';

import { SurveyCampaignUpdatableAttributes } from 'models';

import { __ } from 'helpers/i18n';

import { BigSelectableButton, Input, Label, Text } from 'components';

import StepContainer from 'scenes/components/Stepper/StepContainer';

type State = {
  name: string;
  anonymous: boolean;
};

type Props = {
  onContinueClick: (params: SurveyCampaignUpdatableAttributes) => Promise<void>;
  campaignName?: string;
  campaignAnonymous?: boolean;
};

const StepSettings = ({
  campaignName,
  campaignAnonymous,
  onContinueClick,
}: Props) => {
  const [state, setState] = React.useState<State>({
    name: campaignName || '',
    anonymous: campaignAnonymous || false,
  });

  return (
    <StepContainer
      stepName="settings"
      onContinueClick={() => onContinueClick({ ...state, setupStepIndex: 1 })}
      canPassStep={!!state.name && state.anonymous !== undefined}
    >
      <Text preset="18bs5">{__('Settings')}</Text>

      <Label additionalClassName="mt-3 block">{__('Campaign title')}</Label>
      <Input
        name="survey_campaign_name"
        value={state.name}
        onChange={value =>
          setState(prevState => ({ ...prevState, name: value || '' }))
        }
        testClassName="test-survey-edition-name"
      />

      <div className="flex flex-row gap-4 mt-4 mb-4">
        <BigSelectableButton
          emoji="👤"
          title={__('Anonymous survey')}
          subtitle={__('You will not be able to identify who answered')}
          style={{ minHeight: '100%', flex: 1 }}
          onClick={() =>
            setState(prevState => ({
              ...prevState,
              anonymous: true,
            }))
          }
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          isActive={state.anonymous}
          testClassName="test-survey-edition-anonymous"
        />
        <BigSelectableButton
          emoji="🙋"
          title={__('Non-anonymous survey')}
          subtitle={__('You will see who answered the survey')}
          style={{ minHeight: '100%', flex: 1 }}
          onClick={() =>
            setState(prevState => ({
              ...prevState,
              anonymous: false,
            }))
          }
          isActive={state.anonymous !== undefined && !state.anonymous}
          testClassName="test-survey-edition-non-anonymous"
        />
      </div>
    </StepContainer>
  );
};

export default StepSettings;
