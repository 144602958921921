import React, { CSSProperties, Fragment } from 'react';

import { PeopleReviewDimensionShow } from 'models';
import { PeopleGroupRule } from 'models/PeopleGroup';

import { __ } from 'helpers/i18n';

import { Button, Icon, Select, Tooltip } from 'components';

import AndOperators from '../components/AndOperators';

type Props = {
  peopleGroupRules: PeopleGroupRule[];
  peopleReviewDimensions: PeopleReviewDimensionShow[];
  handleInputChange: (args: {
    value: PeopleGroupRule[];
    name: 'peopleGroupRules';
  }) => void;
};

const PeopleGroupModalRules = ({
  peopleGroupRules,
  peopleReviewDimensions,
  handleInputChange,
}: Props) => {
  const updateDimension = (dimension: string, idx: number) =>
    handleInputChange({
      value: peopleGroupRules.map((r, i) =>
        idx === i
          ? {
              ...r,
              peopleReviewDimension: peopleReviewDimensions.find(
                d => d.id === dimension
              )!,
            }
          : r
      ),
      name: 'peopleGroupRules',
    });

  const availableDimensions =
    peopleGroupRules.length === 0
      ? peopleReviewDimensions
      : peopleReviewDimensions.filter(dimension => {
          return !peopleGroupRules.find(
            rule => rule.peopleReviewDimension.id === dimension.id
          );
        });

  const updateCondition = (condition: string, idx: number) =>
    handleInputChange({
      value: peopleGroupRules.map((r, i) =>
        idx === i
          ? {
              ...r,
              condition,
            }
          : r
      ),
      name: 'peopleGroupRules',
    });

  const updateRating = (rating: string, idx: number) =>
    handleInputChange({
      value: peopleGroupRules.map((r, i) =>
        idx === i
          ? {
              ...r,
              value: rating,
            }
          : r
      ),
      name: 'peopleGroupRules',
    });

  const deleteRule = (idx: number) =>
    handleInputChange({
      value: peopleGroupRules.filter((_r, i) => idx !== i),
      name: 'peopleGroupRules',
    });

  const addRule = () =>
    handleInputChange({
      value: [
        ...peopleGroupRules,
        {
          id: '',
          peopleReviewDimension: availableDimensions[0],
          condition: '',
          value: '',
        },
      ],
      name: 'peopleGroupRules',
    });

  const dimensionOptions = availableDimensions.map(dimension => ({
    value: dimension.id,
    label: dimension.label,
  }));

  const conditionsToLabels = {
    '==': __('is equal to'),
    '!=': __('is not equal to'),
    '<=': __('is lower or equal to'),
    '>=': __('is greater or equal to'),
    '<': __('is lower than'),
    '>': __('is greater than'),
  };

  const conditionOptions = Object.keys(conditionsToLabels).map(condition => ({
    value: condition,
    label: conditionsToLabels[condition],
  }));

  const ratingOptions = (dimensionId: string) => {
    const dimension = peopleReviewDimensions.find(d => d.id === dimensionId);
    return dimension?.peopleReviewRatingOptions.map(option => ({
      value: option.value,
      label: `${option.value} - ${option.label}`,
    }));
  };

  const customRatingStyles = {
    menu: (provided: CSSProperties) => ({
      ...provided,
      width: '276px',
      right: 0,
      left: 'auto',
    }),
    control: (provided: CSSProperties) => ({
      ...provided,
      width: '75px',
    }),
  };

  const disableDeleteRule = peopleGroupRules.length === 1;

  return (
    <Fragment>
      <div
        className={`${peopleGroupRules.length > 1 ? 'flex items-center' : ''}`}
      >
        <AndOperators peopleGroupRules={peopleGroupRules} />
        <div className="flex-1">
          {peopleGroupRules.map((rule, idx) => {
            return (
              <Fragment>
                <div className="flex mt-2 items-center" key={idx}>
                  <Select
                    value={{
                      value: rule.peopleReviewDimension.id,
                      label: rule.peopleReviewDimension.label,
                    }}
                    options={dimensionOptions}
                    additionalClassName="flex-1 mr-2"
                    onChange={dimension =>
                      !!dimension && updateDimension(dimension.value, idx)
                    }
                  />
                  <Select
                    value={{
                      value: rule.condition,
                      label: conditionsToLabels[rule.condition],
                    }}
                    additionalClassName="flex-1 mr-2"
                    options={conditionOptions}
                    onChange={condition =>
                      !!condition && updateCondition(condition.value, idx)
                    }
                  />
                  <Select
                    styles={customRatingStyles}
                    value={{
                      value: rule.value,
                      label: rule.value,
                    }}
                    options={ratingOptions(rule.peopleReviewDimension.id)}
                    onChange={rating =>
                      !!rating && updateRating(rating.value, idx)
                    }
                  />

                  <Tooltip
                    enabled={disableDeleteRule}
                    content={__('A group must have at least one rule.')}
                  >
                    <Button
                      additionalClassName="border-none bg-transparent"
                      disabled={disableDeleteRule}
                      onClick={() => deleteRule(idx)}
                    >
                      <Icon name="delete" />
                    </Button>
                  </Tooltip>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
      <Button
        additionalClassName="mt-4"
        size="small"
        color="secondary"
        disabled={availableDimensions.length === 0}
        onClick={addRule}
      >
        {__('Add a rule')}
      </Button>
    </Fragment>
  );
};

export default PeopleGroupModalRules;
