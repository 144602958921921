import React from 'react';

import {
  AvatarPlaceholder,
  Box,
  ButtonPlaceholder,
  Column,
  Columns,
  Placeholder,
  PullRight,
  Text,
  Title,
} from 'components';

type Props = {
  variant: 'one_one_self' | '360_self' | 'team' | 'team_2';
};

const CardPlaceholder = ({ variant }: Props) => {
  if (variant === 'one_one_self') {
    return (
      <Box>
        <Title style={{ marginBottom: 16 }} size={5}>
          <Placeholder width={100} />
        </Title>

        <Columns isMobile isGapLess style={{ marginBottom: 16 }}>
          <Column size="half">
            <Text color="soften">
              <Placeholder width={250} />
            </Text>
          </Column>
          <Column size="half">
            <Text color="soften">
              <Placeholder width={250} />
            </Text>
          </Column>
        </Columns>
        <Columns isMobile isGapLess style={{ marginBottom: 16 }}>
          <Column size="half">
            <AvatarPlaceholder />
          </Column>
          <Column size="half">
            <AvatarPlaceholder />
          </Column>
        </Columns>
        <PullRight>
          <ButtonPlaceholder width={80} />
          <ButtonPlaceholder width={34} style={{ marginLeft: 10 }} />
        </PullRight>
      </Box>
    );
  }

  if (variant === '360_self') {
    return (
      <Box>
        <Title style={{ marginBottom: 16 }} size={5}>
          <Placeholder width={100} />
        </Title>

        <div style={{ marginBottom: 16 }}>
          <Text color="soften">
            <Placeholder width={150} />
          </Text>
        </div>
        <div style={{ marginBottom: 16 }}>
          <Placeholder width={130} height={40} style={{ marginRight: 16 }} />
          <Placeholder width={100} height={40} style={{ marginRight: 16 }} />
        </div>
        <PullRight>
          <ButtonPlaceholder width={140} style={{ marginRight: 10 }} />
          <ButtonPlaceholder width={110} />
          <ButtonPlaceholder width={34} style={{ marginLeft: 10 }} />
        </PullRight>
      </Box>
    );
  }

  if (variant === 'team') {
    return (
      <Box>
        <Title style={{ marginBottom: 16 }} size={5}>
          <Placeholder width={120} />
        </Title>

        <div style={{ marginBottom: 16 }}>
          <Text color="soften">
            <Placeholder width={150} />
          </Text>
        </div>
        <Columns contentVerticallyAligned>
          <Column>
            <AvatarPlaceholder />
          </Column>
          <Column>
            <PullRight>
              <ButtonPlaceholder width={130} style={{ marginRight: 10 }} />
              <ButtonPlaceholder width={34} style={{ marginRight: 10 }} />
              <ButtonPlaceholder width={34} />
            </PullRight>
          </Column>
        </Columns>
      </Box>
    );
  }

  if (variant === 'team_2') {
    return (
      <Box>
        <Title style={{ marginBottom: 16 }} size={5}>
          <Placeholder width={100} />
        </Title>

        <div style={{ marginBottom: 16 }}>
          <Text color="soften">
            <Placeholder width={150} />
          </Text>
        </div>
        <Columns contentVerticallyAligned style={{ marginBottom: 16 }}>
          <Column>
            <AvatarPlaceholder />
          </Column>
          <Column>
            <PullRight>
              <ButtonPlaceholder width={120} />
            </PullRight>
          </Column>
        </Columns>
        <Columns contentVerticallyAligned>
          <Column>
            <AvatarPlaceholder />
          </Column>
          <Column>
            <PullRight>
              <ButtonPlaceholder width={140} style={{ marginRight: 10 }} />
              <ButtonPlaceholder width={34} style={{ marginRight: 10 }} />
              <ButtonPlaceholder width={34} />
            </PullRight>
          </Column>
        </Columns>
      </Box>
    );
  }

  return null;
};

export default CardPlaceholder;
