import React from 'react';

import invariant from 'helpers/invariant';

import { Select } from 'components';

type Props<T extends string> = {
  value: T | null;
  values: Record<T, string>;
  placeholder?: string;
  onChange: (newValue: T) => void;
  isDisabled?: boolean;
};

const SimpleSelect = <T extends string>({
  value,
  values,
  placeholder,
  onChange,
  isDisabled,
}: Props<T>) => {
  const options = (Object.keys(values) as Array<T>).map(key => ({
    label: values[key],
    value: key,
  }));

  return (
    <Select
      value={options.find(option => option.value === value) || null}
      options={options}
      onChange={option => {
        invariant(
          option && !Array.isArray(option),
          'Option must exist and cannot be an array'
        );
        onChange(option.value);
      }}
      placeholder={placeholder}
      inModal
      isDisabled={isDisabled}
    />
  );
};

export default SimpleSelect;
