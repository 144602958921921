import React from 'react';

import { Input } from 'components';

import { EditableFieldProps } from '../EditableField';
import SyncedField from '../SyncedField';

const StandardField = ({
  field,
  value,
  fieldSynced,
  onChange,
  syncedFieldOptions,
}: EditableFieldProps) => {
  return fieldSynced ? (
    <SyncedField lockedFieldValue={value} {...syncedFieldOptions} />
  ) : (
    <Input
      testClassName={`test-profile-field-${field}`}
      value={value || ''}
      onChange={value => onChange(value || '')}
    />
  );
};

export default StandardField;
