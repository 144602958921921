import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { useActiveUser } from 'helpers/hooks';
import { useQuery } from 'helpers/navigation';
import { pathToMyReviews } from 'helpers/paths';

import { DesignSystem } from 'components';

import DisplayReview from '../review/Review';
import FeedbackRequests from './FeedbackRequests';
import MyReviews from './MyReviews';
import MyTeamReviews from './MyTeamReviews';
import OtherActions from './OtherActions';

const Routes = () => {
  const activeUser = useActiveUser();
  const { path } = useRouteMatch();
  const query = useQuery();
  const type = query.get('type') || 'one_on_one';

  return (
    <DesignSystem version={2}>
      <Switch>
        <Route path={`${path}/my-reviews`}>
          <MyReviews type={type} />
        </Route>
        {activeUser.isManager && (
          <Route path={`${path}/my-team-reviews`}>
            <MyTeamReviews type={type} />
          </Route>
        )}
        <Route path={`${path}/delegated-reviews`}>
          <OtherActions type={type} />
        </Route>
        <Route path={`${path}/feedback-requests`}>
          <FeedbackRequests />
        </Route>
        <Route path={`${path}/:userReviewId`} component={DisplayReview} />
        <Redirect to={pathToMyReviews('one_on_one')} />
      </Switch>
    </DesignSystem>
  );
};

export default Routes;
