import React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyState } from 'components';

import emptyStatePng from './emptyState.png';

type Props = {
  title?: string;
};

const TrainingRequestEmptyState = ({ title }: Props) => (
  <EmptyState
    src={emptyStatePng}
    title={
      title || __('No training request has been created for this employee')
    }
  />
);

export default TrainingRequestEmptyState;
