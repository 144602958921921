import type { NavColors } from 'models';

import { assert } from 'helpers/invariant';

import {
  shadeColor as darken,
  findColorInvert,
  opacify as rgba,
} from './functions';

/**
 * This class is responsible of forwarding custom theming to CSS variables
 *
 * @note Colors definitions are deprecated (Design System V1)
 */
class Colors {
  // @ts-ignore TSFIXME: Fix strictNullChecks error
  primaryColor: string;

  // Grey scale
  darkestGrey = '#414447';
  darkGrey = '#81919D';
  mediumGrey = '#B8C6D0';
  lightGrey = '#DBE6E8';
  lightestGrey = '#EFF1F6';
  // Other colors used
  greenSuccess = '#60CC4F';
  orangeWarning = '#FCCB44';
  redFuckup = '#F96F45';
  redDanger = this.redFuckup;
  roseFlamingo = '#F22488';
  blueElevo = '#4CA7FB';
  text = this.darkestGrey;
  // Design System V2 only
  feedbackBg = '#F3F5FA';
  highlightGray = '#F2F5FA';

  pieChart = {
    yellow: '#F7C139',
    green: '#52C53E',
    grey: '#D3D3D3',
    outdatedGrey: this.mediumGrey,
    red: this.redDanger,
  };

  lineChart = {
    color1: '#70B3FD',
    color2: '#FF94DE',
    color3: '#FFA168',
    color4: '#FF8EA6',
    color5: '#F7C139',
    color6: '#C1A6FE',
  };

  radarChart = {
    reviewee: '#81C1FD',
    reviewer: '#FEA58E',
    additionalReviewer: '#F5C78E',
  };

  reviewStatuses = {
    notStarted: this.redDanger,
    inProgress: this.orangeWarning,
    done: '#A3DF39',
    signed: this.greenSuccess,
    blocked: this.redDanger,
    outdated: this.mediumGrey,
  };

  objectiveStatus = {
    off_track: this.redDanger,
    progressing: this.orangeWarning,
    on_track: this.greenSuccess,
    default: this.lightGrey,
  };

  constructor() {
    this.setDefaultColors();
  }

  setDefaultColors = () => {
    this.setPrimaryColor(this.blueElevo);
  };

  setCSSVariables(variables: { [variable: string]: string }) {
    for (let variable in variables) {
      const color = variables[variable];
      assert(document.documentElement, 'document must exist').style.setProperty(
        variable,
        color
      );
    }
  }

  setPrimaryColor = (primary: string) => {
    this.primaryColor = primary;
    const primary10 = rgba(primary, 0.1);
    const primary20 = rgba(primary, 0.2);
    const primary25 = rgba(primary, 0.25);
    const primary40 = rgba(primary, 0.4);
    const primary50 = rgba(primary, 0.5);

    this.setCSSVariables({
      '--primary': primary,
      '--primary--10--rgba': primary10,
      '--primary--10--rgba--1000--darken': darken(primary10, 0.1),
      '--primary--10--rgba--25--rgba': rgba(primary10, 0.25),
      '--primary--10--rgba--250--darken': darken(primary10, 0.025),
      '--primary--10--rgba--500--darken': darken(primary10, 0.05),
      '--primary--1000--darken': darken(primary, 0.1),
      '--primary--1000--lighten': darken(primary, -0.1),
      '--primary--20--rgba': primary20,
      '--primary--25--rgba': primary25,
      '--primary--40--rgba': primary40,
      '--primary--50--rgba': primary50,
      '--primary--250--darken': darken(primary, 0.025),
      '--primary--500--darken': darken(primary, 0.05),
      '--primary--2500--darken': darken(primary, 0.25),
      '--primary--color-invert': findColorInvert(primary),
    });
  };

  setNavColors = (colors: NavColors) => {
    this.setCSSVariables({
      '--nav-bg': colors.background,
      '--nav-border_default': colors.border,
      '--nav-names_default': colors.namesText,

      '--nav-icon-default': colors.icon,
      '--nav-icon-active': colors.iconActive,
      '--nav-icon-focus': colors.iconFocus,
      '--nav-icon-hover': colors.iconHover,

      '--nav-text-default': colors.itemText,
      '--nav-text-active': colors.itemTextActive,
      '--nav-text-focus': colors.itemTextFocus,
      '--nav-text-hover': colors.itemTextHover,

      '--nav-badge-text-default': colors.badgeText,
      '--nav-badge-text-active': colors.badgeTextActive,
      '--nav-badge-text-focus': colors.badgeTextFocus,
      '--nav-badge-text-hover': colors.badgeTextHover,

      '--nav-item-bg-default': colors.itemBackground,
      '--nav-item-bg-active': colors.itemBackgroundActive,
      '--nav-item-bg-focus': colors.itemBackgroundFocus,
      '--nav-item-bg-hover': colors.itemBackgroundHover,

      '--nav-badge-bg-default': colors.badgeBackground,
      '--nav-badge-bg-active': colors.badgeBackgroundActive,
      '--nav-badge-bg-focus': colors.badgeBackgroundFocus,
      '--nav-badge-bg-hover': colors.badgeBackgroundHover,

      '--nav-avatar-stroke': colors.avatarStroke,

      '--nav_group_caret-hover-bg': colors.groupCarretHoverBackground,
    });
  };
}

const colors = new Colors();

export default colors;
