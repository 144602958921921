import React from 'react';

import type { OneOnOneUserReview, ReviewCycle } from 'models';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { HamburgerMenu, MenuList } from 'components';

import getAvailableMenuItems from './getAvailableMenuItems';

type Props = {
  userReview: OneOnOneUserReview;
  reviewCycle: ReviewCycle;
  refetchData: () => Promise<any>;
  hasAdminActions?: boolean;
};

const OneOnOneActionsHamburgerMenu = ({
  userReview,
  reviewCycle,
  refetchData,
}: Props) => {
  const activeUser = useActiveUser();
  const availableMenuItems = getAvailableMenuItems(
    reviewCycle,
    userReview,
    refetchData
  );

  const activeUserEvaluation = userReview.evaluations.find(
    evaluation => evaluation.reviewerId === activeUser.id
  );

  return (
    <HamburgerMenu
      align="right"
      disabled={availableMenuItems.length === 0}
      disabledMessage={
        activeUserEvaluation?.status === 'shared'
          ? __(
              'The main reviewer has already shared their answers, contact them for any changes.'
            )
          : __('You must be the main reviewer to have access to the actions')
      }
    >
      <MenuList>{availableMenuItems}</MenuList>
    </HamburgerMenu>
  );
};

export default OneOnOneActionsHamburgerMenu;
