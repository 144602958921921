import React from 'react';

import type {
  FeedbackableItem,
  FeedbackableItemItem,
  LegacyQuestionBlock,
} from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n';

import RatingGraph from '../RatingGraph';
import ClosedAnswer from './ClosedAnswer';
import DiscreteSliderAnswerResults from './DiscreteSliderAnswerResults';
import NotSharedAnswersHelper from './NotSharedAnswersHelper';
import OpenAnswer from './OpenAnswer';

type Props = {
  feedbackableItem: FeedbackableItem<FeedbackableItemItem>;
  persistAction: (
    evaluationId: string | null,
    feedbackableId: string,
    feedbackableType: string,
    text: string,
    rating: string | undefined | null
  ) => (dispatch: AppDispatch) => Promise<void>;
};

export default function FeedbackZone({
  feedbackableItem,
  persistAction,
}: Props) {
  const { answers, item, blockId } = feedbackableItem;

  if (answers.length === 0) return null;

  const notSharedAnswers = answers.filter(
    answer => answer.status === 'not_shared'
  );
  let closedAnswers = answers.filter(answer => answer.status === 'shared');
  let openedAnswer = answers.find(answer => answer.status === 'opened');

  if (
    feedbackableItem.item?.type === 'skillsCareerLevelExpectationBlock' ||
    feedbackableItem.item?.type === 'feedbackableSkillsDomain'
  ) {
    closedAnswers = closedAnswers.map(answer => {
      if (answer.rating === 'none') {
        answer.translatedRating =
          feedbackableItem.item?.type === 'feedbackableSkillsDomain'
            ? __(
                '%1 did not rate the domain overall assessment',
                answer.author.fullName
              )
            : __('%1 did not evaluate this skill', answer.author.fullName);
      }

      return answer;
    });
  }

  const showSkillsResponsesOnUserReviewOnSlider =
    !openedAnswer &&
    feedbackableItem.shouldDisplayAnswerList &&
    feedbackableItem.item.type === 'skillsCareerLevelExpectationBlock' &&
    closedAnswers.length > 0;

  return (
    <div className="feedback-zone">
      {showSkillsResponsesOnUserReviewOnSlider && (
        <DiscreteSliderAnswerResults closedAnswers={closedAnswers} />
      )}
      {feedbackableItem.shouldDisplayRatingGraph && (
        <RatingGraph
          feedbackableItem={
            feedbackableItem as FeedbackableItem<LegacyQuestionBlock>
          }
        />
      )}

      {!!notSharedAnswers && (
        <NotSharedAnswersHelper answers={notSharedAnswers} />
      )}

      {feedbackableItem.shouldDisplayAnswerList &&
        closedAnswers.map(answer => (
          <ClosedAnswer answer={answer} key={answer.id} />
        ))}

      {!!openedAnswer && (
        <OpenAnswer
          itemId={item.id}
          itemType={item.type}
          answer={openedAnswer}
          blockId={blockId}
          persistAction={persistAction}
          withMandatoryTag
          closedAnswers={closedAnswers}
        />
      )}
    </div>
  );
}
