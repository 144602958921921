import React from 'react';

import type { InteractionType } from 'models';
import type {
  LegacyQuestionBlock,
  ReviewBlockUpdateParams,
} from 'models/ReviewStructure';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import {
  Box,
  BoxSeparator,
  Flex,
  Icon,
  Title6,
  WithSavingStatusRecorder,
} from 'components';

import BlockActions from './components/BlockActions';
import BlockContent from './components/BlockContent';
import FeedbackOptionsSection from './components/FeedbackOptionsSection';
import LegacyRatingsSection from './components/LegacyRatingsSection';

type Props = {
  block: LegacyQuestionBlock;
  onChange: (params: ReviewBlockUpdateParams) => Promise<void>;
  justEnteredInList: boolean;
  hasSelfEvaluationEnabled: boolean;
  interactionType: InteractionType;
  onDelete?: () => Promise<void>;
  onMoveUp: () => Promise<void>;
  onMoveDown: () => Promise<void>;
  onDuplicate: () => Promise<void>;
};

const LegacyQuestionBlockComponent = ({
  block,
  onChange,
  justEnteredInList,
  hasSelfEvaluationEnabled,
  interactionType,
  ...actionProps
}: Props) => {
  const {
    revieweeFeedbackOptions,
    reviewerFeedbackOptions,
    revieweeRatingOptions,
    reviewerRatingOptions,
    content,
    richTextEnabled,
  } = block;

  return (
    <Box testClassName="test-legacy-question-block">
      <Flex style={{ justifyContent: 'space-between' }} verticalAlign>
        <Flex>
          {/* line height is 22px to be aligned with text */}
          <Icon
            style={{ lineHeight: '22px', marginRight: 8 }}
            name="arrow_drop_down_circle"
          />
          <Title6 transformation="uppercase">{__('Question')}</Title6>
        </Flex>
        <BlockActions
          canMoveUp={block.canMoveUp}
          canMoveDown={block.canMoveDown}
          canDuplicate={block.canDuplicate}
          {...actionProps}
        />
      </Flex>

      <BoxSeparator style={{ margin: '16px 0' }} />

      <WithSavingStatusRecorder
        fieldUid={compositeKey({
          blockId: block.id,
          field: 'legacy_question_block_content',
        })}
        onChange={onChange}
        render={autoSavingOnchange => (
          <BlockContent
            onChange={autoSavingOnchange}
            content={content}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={justEnteredInList}
            withRichText={richTextEnabled}
          />
        )}
      />

      <LegacyRatingsSection
        reviewBlockId={block.id}
        canDeleteRevieweeRatingOption
        canDeleteReviewerRatingOption
        canUpdateWithEmptyLabel
        revieweeRatingOptionsEnabled={
          !!revieweeFeedbackOptions && revieweeFeedbackOptions.ratingEnabled
        }
        revieweeRatingOptions={revieweeRatingOptions}
        reviewerRatingOptionsEnabled={reviewerFeedbackOptions.ratingEnabled}
        reviewerRatingOptions={reviewerRatingOptions}
        hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
        interactionType={interactionType}
      />

      <BoxSeparator style={{ margin: '16px 0' }} />

      <FeedbackOptionsSection
        revieweeFeedbackOptions={revieweeFeedbackOptions}
        reviewerFeedbackOptions={reviewerFeedbackOptions}
        hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
        interactionType={interactionType}
        blockType={block.blockType}
      />
    </Box>
  );
};

export default LegacyQuestionBlockComponent;
