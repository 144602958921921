import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Match } from 'helpers/paths';

import PreviewComponent from 'scenes/review/Preview';

type RouteContext = Match<{
  id: string;
  role: 'reviewee' | 'reviewer';
}>;

const TemplatePreview = () => {
  const { params } = useRouteMatch() as RouteContext;

  return <PreviewComponent role={params.role} templateId={params.id} />;
};

export default TemplatePreview;
