type PrefillOption = {
  name?: string;
  email?: string;
  subject?: string;
  text?: string;
};

export function open(prefillOption: PrefillOption) {
  if (!!prefillOption) {
    // Cf doc: https://developer.helpscout.com/beacon-2/web/javascript-api/#beaconprefill-formobject
    window.Beacon('prefill', prefillOption);
  }

  window.Beacon('open');
}
