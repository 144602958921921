import { sortBy } from 'lodash';
import React from 'react';

import type { DataPoint } from './index';
import type { User } from 'models';

import { __, n__ } from 'helpers/i18n';

import { Text } from 'components';
import { Content as TooltipContent } from 'components/Tooltip';

type Props = {
  active: boolean;
  showAuthorDetails: boolean;
  payload:
    | Array<{
        payload?: DataPoint;
      }>
    | undefined;
};

export default function Tooltip({ active, payload, showAuthorDetails }: Props) {
  if (active && payload && payload.length && payload[0].payload) {
    const { authors, count, authorTags, type } = payload[0].payload;

    if (type === 'peer') {
      const authorWithTags: Array<[User, string | undefined | null]> = [];

      authors.forEach((author, i) => {
        authorWithTags.push([author, authorTags[i]]);
      });

      const peerFullNames = sortBy(
        authorWithTags,
        authorWithTag => authorWithTag[0].fullName
      ).map(([author, tag]) => {
        if (tag === 'manager') return `${author.fullName} (${__('Manager')})`;
        if (tag === 'reviewee') return `${author.fullName} (${__('Reviewee')})`;

        return author.fullName;
      });

      return (
        <TooltipContent>
          <div className="peers">
            {count > 0 ? (
              <React.Fragment>
                {showAuthorDetails ? (
                  <React.Fragment>
                    <Text weight="semibold">
                      {n__('%1 peer:', '%1 peers:', count)}
                    </Text>
                    <br />
                    <ul>
                      {peerFullNames.map((peerFullName, i) => (
                        <li key={i}>{peerFullName}</li>
                      ))}
                    </ul>
                  </React.Fragment>
                ) : (
                  <Text weight="semibold">
                    {n__('%1 peer', '%1 peers', count)}
                  </Text>
                )}
              </React.Fragment>
            ) : (
              <Text weight="semibold">{__('No peer')}</Text>
            )}
          </div>
        </TooltipContent>
      );
    }

    return <TooltipContent>{__("Reviewee's choice")}</TooltipContent>;
  }

  return null;
}
