import React from 'react';

import type {
  FeedbackableItem,
  MultipleScaleQuestionBlock,
  RadarChart,
} from 'models';

import MultipleScaleQuestionRadarChart from 'scenes/components/MultipleScaleQuestionRadarChart';

import ClosedAnswer from '../../components/FeedbackZone/ClosedAnswer';
import NotSharedAnswersHelper from '../../components/FeedbackZone/NotSharedAnswersHelper';

type Props = {
  feedbackableBlock: FeedbackableItem<MultipleScaleQuestionBlock>;
  radarChart: RadarChart | undefined | null;
};

export default function ClosedAnswersSection({
  feedbackableBlock,
  radarChart,
}: Props) {
  const notSharedAnswers = feedbackableBlock.answers.filter(
    answer => answer.status === 'not_shared'
  );
  const sharedAnswers = feedbackableBlock.answers.filter(
    answer => answer.status === 'shared'
  );

  return (
    <div className="closed-answers-section">
      {radarChart && (
        <MultipleScaleQuestionRadarChart radarChart={radarChart} />
      )}

      {!!notSharedAnswers && (
        <NotSharedAnswersHelper answers={notSharedAnswers} />
      )}

      {feedbackableBlock.shouldDisplayAnswerList &&
        sharedAnswers.map(answer => (
          <div className="feedback-zone" key={answer.id}>
            <ClosedAnswer answer={answer} />
          </div>
        ))}
    </div>
  );
}
