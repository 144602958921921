import React, { Fragment, ReactNode } from 'react';

import type { ThreeSixtyUserReview } from 'models/index';

import { __ } from 'helpers/i18n/index';

import { MenuItem, Toggleable } from 'components/index';

import ReleaseReviewModal from './ReleaseReviewModal';

type Props = {
  userReview: ThreeSixtyUserReview;
  renderAction: (onClick: () => void) => ReactNode;
  onAfterAction?: () => Promise<void>;
};

const ReleaseAction = ({ renderAction, userReview, onAfterAction }: Props) => {
  return (
    <Toggleable
      render={(isToggled, toggle) => (
        <Fragment>
          {renderAction(toggle)}
          <ReleaseReviewModal
            isActive={isToggled}
            onCancel={toggle}
            userReview={userReview}
            reviewee={userReview.user}
            onAfterSubmit={onAfterAction}
            isForcedRelease
          />
        </Fragment>
      )}
    />
  );
};

ReleaseAction.defaultProps = {
  renderAction: onClick => (
    <MenuItem onClick={onClick}>{__('Share feedback with reviewee')}</MenuItem>
  ),
};

export default ReleaseAction;
