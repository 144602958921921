import {
  AttributesOnlyOrganization,
  UserFilterableFieldType,
} from 'models/index';

import { useAppSelector } from 'helpers/hooks';
import invariant from 'helpers/invariant';

import {
  getCurrentOrganization,
  getSurveyUserFilterableFields,
  getUserFilterableFields,
} from 'redux/reducers';

export const useCurrentOrganization = (): AttributesOnlyOrganization => {
  return useAppSelector(state => {
    const organization = getCurrentOrganization(state);

    invariant(organization, 'Organization should be defined.');

    return organization;
  });
};

export const useUserFilterableFields = (): Array<UserFilterableFieldType> => {
  return useAppSelector(state => {
    const organizationUserFilterableFields = getUserFilterableFields(
      state
    ) as Array<UserFilterableFieldType>;

    invariant(
      organizationUserFilterableFields,
      'Organization user filterbale fields should be defined.'
    );

    return organizationUserFilterableFields;
  });
};

export const useSurveyUserFilterableFields =
  (): Array<UserFilterableFieldType> => {
    return useAppSelector(state => {
      const organizationSurveyUserFilterableFields =
        getSurveyUserFilterableFields(state) as Array<UserFilterableFieldType>;

      invariant(
        organizationSurveyUserFilterableFields,
        'Organization survey user filterable fields should be defined.'
      );

      return organizationSurveyUserFilterableFields;
    });
  };
