import React from 'react';

import { __ } from '../../helpers/i18n';
import { Button } from '../buttons';
import {
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from './Default';

type Props = {
  onOK: () => void;
  title?: string;
  children: React.ReactNode;
  okLabel?: string;
  refreshContentOnOpening: boolean;
  isLarge?: boolean;
  isActive?: boolean;
};

export default function InformationModal({
  onOK,
  title,
  children,
  okLabel,
  refreshContentOnOpening,
  isActive,
  isLarge,
}: Props) {
  return (
    <ModalCard
      isActive={isActive}
      onClose={onOK}
      isLarge={isLarge}
      refreshContentOnOpening={refreshContentOnOpening}
    >
      <ModalCardHead>
        {title && <ModalCardTitle>{title}</ModalCardTitle>}
      </ModalCardHead>

      <ModalCardBody>{children}</ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onOK}>
          {okLabel || __('OK')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
}
