import React from 'react';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';

import classNames from 'helpers/classNames';

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  onChangePosition: (itemId: string, newIndex: number) => void;
};

export default function Container({
  children,
  style,
  onChangePosition,
}: Props) {
  const onDragEnd: OnDragEndResponder = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.index !== destination.index) {
      onChangePosition(result.draggableId, destination.index);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classNames('drag-and-drop-container', {
              'is-dragging': snapshot.isDraggingOver,
            })}
            style={style}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
