import { AttributesOnlySession } from 'models';

import { useAppSelector } from 'helpers/hooks';
import invariant from 'helpers/invariant';

import { getSession } from 'redux/reducers';

const useSession = (): AttributesOnlySession => {
  return useAppSelector(state => {
    const session = getSession(state);

    invariant(session, 'Session should be defined.');

    return session;
  });
};

export default useSession;
