import type { SupportedLocale } from 'locales';

import { supportedLocales } from 'locales';

import setLocale from './setLocale';

function getShortLocaleName(language: string) {
  return language.split('-')[0].toLowerCase();
}

export default function guessLocaleFromNavigator() {
  if (process.env.NODE_ENV === 'test') return;

  const navigatorLanguages = navigator.languages || [navigator.language];

  const supportedLocalesFromNavigator = navigatorLanguages
    .map(language => getShortLocaleName(language))
    .filter(locale =>
      supportedLocales.includes(locale as SupportedLocale)
    ) as SupportedLocale[];

  if (supportedLocalesFromNavigator.length > 0) {
    setLocale(supportedLocalesFromNavigator[0]);
    return;
  }

  setLocale('en');
}
