import React, { Fragment } from 'react';

import type { TrainingPeriod } from 'models';

import can from 'helpers/can';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { PeriodPicker } from 'components';

import TrainingPeriodCreationModal from 'scenes/components/TrainingPeriodCreationModal';

type Props = {
  currentPeriod: TrainingPeriod | undefined | null;
  periods: Array<TrainingPeriod> | undefined | null;
  onChange: (period: TrainingPeriod) => void;
  isFetching: boolean;
};

const PopoverPicker = ({
  currentPeriod,
  periods,
  onChange,
  isFetching,
}: Props) => {
  const organization = useOrganization();
  const [createPeriodModalIsActive, setCreatePeriodModalIsActive] =
    React.useState(false);

  if (isFetching || !periods) {
    return (
      <PeriodPicker
        label={__('Loading…')}
        period={null}
        periods={[]}
        isLoading
        onChange={() => {}}
      />
    );
  }

  const canCreatePeriod = can({
    perform: 'create_training_period',
    on: organization,
  });

  return (
    <Fragment>
      <PeriodPicker
        period={
          !!currentPeriod
            ? {
                id: currentPeriod.id,
                name: currentPeriod.name,
              }
            : null
        }
        periods={periods.map(period => ({
          id: period.id,
          name: period.name,
        }))}
        onChange={periodId => {
          const period = periods.find(period => period.id === periodId);

          invariant(period, 'Training period must exist at this point');

          onChange(period);
        }}
        onPeriodCreationLinkClick={
          canCreatePeriod ? () => setCreatePeriodModalIsActive(true) : null
        }
      />

      {createPeriodModalIsActive && (
        <TrainingPeriodCreationModal
          onClose={() => setCreatePeriodModalIsActive(false)}
        />
      )}
    </Fragment>
  );
};

export default PopoverPicker;
