// @flow
import * as React from 'react';
import { withRouter } from 'react-router-dom';

import type { Location } from 'react-router-dom';

import queryString from 'helpers/queryString';
import { addToken } from 'helpers/url';

import { Icon, Overlay, Title5 } from 'components';

import { __ } from '../helpers/i18n';
import invariant from '../helpers/invariant';
import { targetApi } from '../lib/api/constants';

type Props = {|
  location: Location,
|};

class RedirectToDownload extends React.Component<Props> {
  validateNextUrl(nextUrl: URL) {
    invariant(
      nextUrl.hostname === targetApi.hostname,
      `Invalid redirect URI: ${this.getNextUrl()}`
    );
  }

  getNextUrl() {
    return queryString.parse(this.props.location.search).url;
  }

  getNextUrlWithToken() {
    const nextUrl = this.getNextUrl();
    return addToken(nextUrl);
  }

  componentDidMount() {
    const nextUrl = new URL(this.getNextUrl());
    this.validateNextUrl(nextUrl);
    window.location.replace(this.getNextUrlWithToken());
  }

  render() {
    return (
      <Overlay>
        <div>
          <div style={{ marginTop: '20px', display: 'flex' }}>
            <Icon
              size="large"
              style={{ marginRight: '10px' }}
              name="arrow_downward"
            />
            <div>
              <Title5>{__('Your download should start shortly')}</Title5>
              <p>
                {__(
                  '%1 to download again if that is not the case',
                  <a
                    href={this.getNextUrlWithToken()}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {__('Click here')}
                  </a>
                )}
              </p>
            </div>
          </div>
        </div>
      </Overlay>
    );
  }
}

export default withRouter(RedirectToDownload);
