import React, { ReactNode } from 'react';

import type { NavigationItem } from 'components/navigation/Tabs/NavigationList';

import { DesignSystem, FullWidthSubmenuBar, Text, Title } from 'components';

type Props = {
  title: string;
  backButton?: ReactNode;
  statusInfo?: ReactNode;
  actions?: ReactNode;
  tabItems?: Array<NavigationItem>;
};

const Topbar = ({
  title,
  backButton,
  statusInfo,
  actions,
  tabItems,
}: Props) => (
  <DesignSystem version={2}>
    <FullWidthSubmenuBar
      backButton={backButton}
      actions={actions}
      tabItems={tabItems}
    >
      <Title size={4} style={{ marginBottom: !!statusInfo ? 16 : 0 }}>
        {title}
      </Title>
      <Text>{statusInfo}</Text>
    </FullWidthSubmenuBar>
  </DesignSystem>
);

export default Topbar;
