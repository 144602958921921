type Values = {
  search: string;
  page: number;
};

export function computePageAndSearchDisplayValues(
  optimisticValues: Values,
  realValues: Values | null | undefined,
  isFetching: boolean
) {
  const { search: optimisticSearch, page: optimisticPage } = optimisticValues;
  const { search, page } = realValues || {};

  return isFetching
    ? {
        search: optimisticSearch,
        page: optimisticPage,
      }
    : {
        search,
        page,
      };
}
