import { createContext } from 'react';

import { Objective } from 'models';

type ObjectiveContextType = {
  objective: Objective | null;
  setObjective: ((objective: Objective | null) => void) | null;
};

export const ObjectiveContext = createContext<ObjectiveContextType>({
  objective: null,
  setObjective: null,
});
