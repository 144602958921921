import moment from 'moment';
import React from 'react';

import { __ } from 'helpers/i18n';

import { Text } from 'components';

const VersionDate = ({ date }: { date: string }) => (
  <Text preset="14s6" color="info">
    {__('On %1 at %2', moment(date).format('LL'), moment(date).format('LT'))}
  </Text>
);

export default VersionDate;
