import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type {
  FundingSourceStats,
  TrainingOverallBudgetStats,
  User,
} from 'models';

import { __ } from 'helpers/i18n';
import { withActiveUser } from 'helpers/withSessionProps';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Button,
  FetchContainer,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import FundingSourceDetails from './FundingSourceDetails';
import OverallStats from './OverallStats';

type Props = {
  periodSlug: string;
  isActive: boolean;
  canManageBudget: boolean;
  overallStats: TrainingOverallBudgetStats;
  onClose: () => void;
  onOpenBudgetManagementModal: () => void;
};

type AfterWithActiveUserProps = Props & {
  activeUser: User;
};

type AfterDataLoaderProps = AfterWithActiveUserProps &
  DataLoaderProvidedProps & {
    fundingSourcesStats: Array<FundingSourceStats>;
  };

const BudgetDetailsModal = ({
  isActive,
  canManageBudget,
  overallStats,
  onClose,
  onOpenBudgetManagementModal,
  fundingSourcesStats,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <ModalCard
      isActive={isActive}
      refreshContentOnOpening
      onClose={onClose}
      additionalClassName="budget-details-modal"
    >
      <ModalCardHead onClose={onClose}>
        <ModalCardTitle>{__('My budget')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="alone"
          render={() => {
            return (
              <div>
                {!!overallStats.provisionedBudgetCents &&
                  +overallStats.provisionedBudgetCents > 0 && (
                    <OverallStats overallStats={overallStats} />
                  )}

                {fundingSourcesStats.map(fundingSourcesStat => (
                  <FundingSourceDetails
                    key={fundingSourcesStat.id}
                    fundingSourcesStat={fundingSourcesStat}
                  />
                ))}
              </div>
            );
          }}
        />
      </ModalCardBody>

      <ModalCardFooter>
        {canManageBudget && (
          <Button
            onClick={() => {
              onClose();
              onOpenBudgetManagementModal();
            }}
            color="secondary"
          >
            {__('Edit')}
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          {__('Close')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default compose<React.ComponentType<Props>>(
  withActiveUser,
  newDataLoader({
    fetch: ({ periodSlug }: Props) =>
      get(`training/periods/${periodSlug}/funding_source_stats`),
    hydrate: {
      fundingSourcesStats: {
        budgetItem: {
          abilities: {},
        },
      },
    },
  })
)(BudgetDetailsModal);
