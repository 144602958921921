import React from 'react';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import { Button, Icon } from 'components';

type Props = {
  label?: string;
  isDisabled?: boolean;
  isOpen: boolean;
  isLoading?: boolean;
  onClick: () => void;
};

export default function Trigger({
  label,
  isDisabled,
  isLoading,
  isOpen,
  onClick,
}: Props) {
  if (isLoading) {
    return (
      <Button disabled>
        <Icon style={{ marginRight: 8 }} name="date_range" />
        <span>{__('Loading…')}</span>
        <Icon style={{ marginLeft: 8 }} name="keyboard_arrow_down" />
      </Button>
    );
  }

  return (
    <Button
      additionalClassName={classNames('has-text-overflow-ellipsis', {
        'is-open': isOpen,
      })}
      disabled={isDisabled}
      onClick={onClick}
      title={label}
    >
      <Icon style={{ marginRight: 8 }} name="date_range" />
      <span className="is-text-overflow-ellipsis">{label}</span>
      <Icon style={{ marginLeft: 8 }} name="keyboard_arrow_down" />
    </Button>
  );
}
