import React from 'react';

import { Button, Icon } from 'components';

export default function BrowserPrintActionButton() {
  return (
    <Button
      color="secondary"
      isRounded
      hasIconOnly
      onClick={triggerBrowserPrint}
    >
      <Icon name="print" />
    </Button>
  );
}

function triggerBrowserPrint() {
  window.print();
}
