import React, { type CSSProperties } from 'react';

import classNames from 'helpers/classNames';

import { Text } from 'components';

type Props = {
  children: React.ReactNode;
  additionalClassName?: string;
  testClassName?: string;
  style?: CSSProperties;
};

export default function AvatarTitle({
  children,
  additionalClassName,
  testClassName,
  style,
}: Props) {
  return (
    <Text
      additionalClassName={classNames('avatar-title', additionalClassName)}
      testClassName={testClassName}
      style={style}
      overflowEllipsis
    >
      {children}
    </Text>
  );
}
