import React from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { ENPSPeriod, TimeSeries } from 'models';

import { __ } from 'helpers/i18n';
import { zipSeries } from 'helpers/timeSeries';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Box, FetchContainer, Text } from 'components';

import LineChart from './LineChart';

type Props = {
  enpsPeriod: ENPSPeriod;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    lineChart: { global: TimeSeries };
  };

const Evolution = ({
  isFetching,
  hasError,
  lineChart,
}: AfterDataLoaderProps) => {
  const linesMetadata = [
    {
      dataKey: 'global',
      name: __('Global'),
      color: '#32325D',
      curveType: 'monotone' as const,
    },
  ];

  return (
    <Box>
      <Text preset="18bs5" additionalClassName="block mb-4">
        {__('eNPS Evolution')}
      </Text>

      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => {
          const data = zipSeries(lineChart).data;

          return <LineChart data={data} linesMetadata={linesMetadata} />;
        }}
      />
    </Box>
  );
};

export default newDataLoader({
  fetch: ({ enpsPeriod }: Props) =>
    get(`enps/periods/${enpsPeriod.id}/evolution`),
  hydrate: {
    lineChart: { global: {} },
  },
})(Evolution);
