import React, { Fragment } from 'react';

import { __ } from 'helpers/i18n';

import IntegrationSettingField from './IntegrationSettingField';

type Props = {
  settings: {
    username: string;
  };
  onChange: (key: string, value: string) => void;
};

export function SFTPIntegrationSettings({ settings, onChange }: Props) {
  return (
    <Fragment>
      <IntegrationSettingField
        name="username"
        label={__('SFTP|Username:')}
        value={settings.username}
        onUpdate={value => onChange('username', value)}
      />
    </Fragment>
  );
}

export const SFTPIntegrationDefaultSettings = {
  username: '',
};
