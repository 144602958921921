import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { RadarChart as RadarChartType, UserFilterSegment } from 'models';

import compositeKey from 'helpers/compositeKey';
import withLoadingOnViewportVisibility from 'helpers/withLoadingOnViewportVisibility';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FeatureFlagged, FetchContainer } from 'components';

import MultipleScaleQuestionRadarChart from 'scenes/components/MultipleScaleQuestionRadarChart';

type Props = {
  reviewCycleId: string;
  blockId: string;
  userFilter: UserFilterSegment | undefined | null;
  onCriterionClick: (criterion: string) => void;
};

type MultipleScaleStats = {
  radarChart: RadarChartType;
};

type AfterDataloaderProps = Props &
  DataLoaderProvidedProps & {
    stats?: MultipleScaleStats;
  };

const MultipleScaleRadarChart = ({
  stats,
  isFetching,
  hasError,
  onCriterionClick,
}: AfterDataloaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      loadingStyle="overlay"
      render={() => {
        if (!stats) return null;

        return (
          <FeatureFlagged
            flag="listFeedbackResultsPerOption"
            alternativeChildren={
              <MultipleScaleQuestionRadarChart radarChart={stats.radarChart} />
            }
          >
            <MultipleScaleQuestionRadarChart
              radarChart={stats.radarChart}
              onCriterionClick={onCriterionClick}
            />
          </FeatureFlagged>
        );
      }}
    />
  );
};

const MockRenderer = (originalProps: Props) => {
  return (
    <MultipleScaleRadarChart
      isFetching
      hasError={false}
      refetchData={() => Promise.resolve()}
      noContent
      {...originalProps}
    />
  );
};

export default compose<React.ComponentType<Props>>(
  withLoadingOnViewportVisibility<Props>({
    delay: 500,
    mockClass: MockRenderer,
  }),
  newDataLoader({
    fetch: ({ reviewCycleId, blockId, userFilter }) =>
      get(`review_cycles/${reviewCycleId}/review_blocks/${blockId}/stats`, {
        userFilter,
      }),
    hydrate: {
      stats: {
        radarChart: {},
      },
    },
    cacheKey: ({ userFilter, blockId }: Props) =>
      compositeKey({
        userFilter,
        blockId,
        view: 'radarChart',
      }),
  })
)(MultipleScaleRadarChart);
