import React from 'react';

import type { SurveyCampaign } from 'models';

import { Match } from 'helpers/navigation';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import SurveyContent from './SurveyContent';

type Props = {
  match: Match<{
    campaignId: string;
  }>;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    campaign: SurveyCampaign;
  };

const Survey = ({ isFetching, hasError, campaign }: AfterDataLoaderProps) => {
  return (
    <SurveyContent
      isFetching={isFetching}
      hasError={hasError}
      campaign={campaign}
    />
  );
};

export default newDataLoader({
  fetch: ({ match }: Props) =>
    get(`survey/campaigns/${match.params.campaignId}/my_ongoing_survey`),
  hydrate: {
    campaign: {
      currentPeriod: {
        currentUserParticipation: { answers: { choices: {} } },
        form: { steps: { question: {}, instruction: {} } },
      },
    },
  },
})(Survey) as React.ComponentType<Props>;
