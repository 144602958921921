import { omit, uniqBy } from 'lodash';
import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import type {
  GraphFormattedTimeSeriesDataPoint,
  TimeSeriesAnnotation,
} from 'models';

import { Box, ChartTooltip, Title } from 'components';

type LineChartData = Array<GraphFormattedTimeSeriesDataPoint>;

type AxisParams = {
  unit?: string;
  domain?: Array<number>;
  ticks?: Array<number>;
};

export type LineParams = {
  dataKey: string;
  name: string;
  color: string;
};

type Props = {
  title: string;
  data: LineChartData;
  ordinateParams?: AxisParams;
  abscissaParams?: AxisParams;
  lines: Array<LineParams>;
  annotations: Array<TimeSeriesAnnotation>;
};

export default function ProgressChartBlock({
  title,
  data,
  ordinateParams = {},
  abscissaParams,
  lines,
  annotations,
}: Props) {
  const availableSeriesNames = Object.keys(omit(data[0], 'date'));
  const availableLines = lines.filter(line =>
    availableSeriesNames.includes(line.dataKey)
  );

  return (
    <Box>
      <Title size={5}>{title}</Title>
      <ResponsiveContainer height={200} width="100%">
        <LineChart data={data} margin={{ top: 20, left: -20, right: -15 }}>
          <CartesianGrid strokeDasharray="2 2" vertical={false} />
          <XAxis
            dataKey="date"
            tick={{ fontSize: 'small' }}
            padding={{ left: 20 }}
            {...abscissaParams}
          />
          <YAxis
            interval={0}
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 'small' }}
            {...ordinateParams}
          />
          <Tooltip
            content={({ active, payload, label }) => {
              if (!active) return null;

              const oParams = { ...ordinateParams };

              return (
                <ChartTooltip
                  title={label}
                  values={availableLines.map((line, index) => {
                    return {
                      color: line.color,
                      label: line.name,
                      value: payload?.[index] ? payload[index].value : null,
                      unit: oParams.unit,
                    };
                  })}
                />
              );
            }}
          />

          {availableLines.length > 0 &&
            availableLines.map(
              line =>
                !!line && (
                  <Line
                    dot={false}
                    type="linear"
                    dataKey={line.dataKey}
                    name={line.name}
                    stroke={line.color}
                    key={line.dataKey}
                  />
                )
            )}

          {annotations.length > 0 &&
            uniqBy(annotations, annotation => annotation.date).map(
              annotation =>
                !!annotation && (
                  <ReferenceLine
                    key={annotation.date}
                    x={annotation.date}
                    strokeDasharray="2 2"
                  />
                )
            )}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}
