import React, { CSSProperties } from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { TrainingStats } from 'models';
import type { AppDispatch } from 'redux/actions';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { requestStarted, requestSucceeded } from 'lib/dataLoader/actions';
import { get } from 'redux/actions/api';

import {
  Box,
  Column,
  Columns,
  FetchContainer,
  Number,
  Text,
  Title,
} from 'components';

type Props = {
  currentPeriodSlug: string;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    trainingStats: TrainingStats;
  };

const boxStyle: CSSProperties = {
  textAlign: 'center',
  padding: '20px',
};

const TrainingRequestStatsComponent = ({
  trainingStats,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      loadingStyle="overlay"
      render={() => (
        <div>
          {!!trainingStats && (
            <Columns style={{ marginBottom: 0, marginTop: 0 }}>
              <Column size={3}>
                <Box style={boxStyle}>
                  <Title size={5} weight="semibold">
                    {trainingStats.waitingForApprovalCount}
                  </Title>
                  <Text transformation="lowercase">
                    {__('Waiting for approval')}
                  </Text>
                </Box>
              </Column>
              <Column size={3}>
                <Box style={boxStyle}>
                  <Title size={5} weight="semibold">
                    <Number
                      value={trainingStats.percentageApproved}
                      isPercentage
                    />
                  </Title>
                  <Text transformation="lowercase">
                    {__('Training approved')}
                  </Text>
                </Box>
              </Column>
            </Columns>
          )}
        </div>
      )}
    />
  );
};

const cacheKeyId = (slug: string) =>
  compositeKey({ slug, viewId: 'trainingRequestStats' });
const urlToTrainingPeriodStats = (slug: string) =>
  `training/periods/${slug}/request_stats`;

export function refetchTrainingPeriodStats(currentPeriodSlug: string) {
  const requestId = cacheKeyId(currentPeriodSlug);

  return async function (dispatch: AppDispatch) {
    await dispatch(requestStarted(requestId));
    const response = await dispatch(
      get(urlToTrainingPeriodStats(currentPeriodSlug))
    );
    await dispatch(requestSucceeded(requestId, response.response.body));
  };
}

export default compose(
  newDataLoader({
    fetch: ({ currentPeriodSlug }) =>
      get(urlToTrainingPeriodStats(currentPeriodSlug)),
    hydrate: {
      trainingStats: {},
    },
    cacheKey: ({ currentPeriodSlug }) => cacheKeyId(currentPeriodSlug),
  })
)(TrainingRequestStatsComponent) as React.ComponentType<Props>;
