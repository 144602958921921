import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { history } from '../config/history';

export * from './paths';

let historyChanged = false;

const unlistenHistoryChanges = history.listen((_location, _action) => {
  historyChanged = true;
  unlistenHistoryChanges();
});

export const canGoBack = () => historyChanged;

export const navigateBack = () => {
  history.goBack();
};

export const navigate = (path: string) => {
  if (!/^\//.test(path)) {
    throw new Error("module navigate: Expecting path to start with '/'");
  }
  history.push(path);
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const isActiveUrl = (
  refPathname: string,
  refSearch: string,
  path: string | null | undefined,
  matchFullPath: boolean = false
) => {
  if (!path) {
    return false;
  }

  const iUrl = new URL(
    window.location.protocol + '//' + window.location.host + path
  );

  let match = !matchFullPath
    ? iUrl.pathname === refPathname
    : refPathname.startsWith(iUrl.pathname);

  if (!match) {
    return false;
  }

  const searchParams = new URLSearchParams(refSearch);
  return iUrl.searchParams.get('type') === searchParams.get('type');
};

const PATHS_WITHOUT_NAVBAR = [
  '/o/:organizationShortName/evaluations/:id',
  '/o/:organizationShortName/reviews/:reviewId(\\d+)', // regex to only remove navbar on a reviews show (with id)
  '/o/:organizationShortName/admin/templates/:id/preview/v2/:role',
];

export const isPageWithoutNavbar = (currentPathname: string) =>
  PATHS_WITHOUT_NAVBAR.some(path =>
    matchPath(currentPathname, {
      path: path,
      exact: true,
      strict: true,
    })
  );
