import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { DesignSystem, Redirect, Route, Switch } from 'components';

import PeopleReviewDimensions from './Dimensions';
import PeopleReviewDimensionEdit from './Dimensions/Edit';
import PeopleReviewsToComplete from './PeopleReviewsToComplete';
import PeopleUserReview from './PeopleUserReview';
import PeopleReviewCycleIndex from './cycles/Index';
import PeopleReviewCycleManage from './cycles/Manage';
import PeopleReviewActionIndex from './plannedActions';

export default function Routes() {
  const { path } = useRouteMatch();

  return (
    <DesignSystem version={2}>
      <Switch>
        <Route
          exact
          path={`${path}/cycles`}
          component={PeopleReviewCycleIndex}
        />
        <Route
          path={`${path}/cycles/:cycleId`}
          component={PeopleReviewCycleManage}
        />

        <Redirect
          from={`${path}/my-people-reviews`}
          to={`${path}/to-complete`}
        />
        <Route
          path={`${path}/to-complete`}
          component={PeopleReviewsToComplete}
        />

        <Route
          path={`${path}/dimensions`}
          exact
          component={PeopleReviewDimensions}
        />
        <Route
          path={`${path}/dimensions/:dimensionId`}
          exact
          component={PeopleReviewDimensionEdit}
        />

        <Route path={`${path}/actions`} component={PeopleReviewActionIndex} />

        <Route
          path={`${path}/reviews/:reviewId`}
          component={PeopleUserReview}
        />

        <Redirect to={`${path}/cycles`} />
      </Switch>
    </DesignSystem>
  );
}
