import { clamp } from 'lodash';
import React from 'react';

import type { OneOnOneReviewCycle } from 'models';

import { Control, Text } from 'components';

type Props = {
  text: string;
  name: string;
  value: number | undefined | null;
  min: number;
  max: number;
  onFieldChange: (attributes: Partial<OneOnOneReviewCycle>) => Promise<any>;
};

export default function AutoAddDeadlineInput({
  text,
  name,
  value,
  min,
  max,
  onFieldChange,
}: Props) {
  return (
    <Control
      additionalClassName="deadline-control"
      style={{ display: 'inline-flex' }}
    >
      <Text
        style={{ marginRight: 10 }}
        isEditable
        editableOptions={{
          type: 'number',
          value: value,
          onChange: (input: number | null | undefined) => {
            if (!!input) input = clamp(input, min, max);

            return onFieldChange({
              [name]: input,
            });
          },
          placeholder: '-',
          fieldProps: {
            min,
            max,
          },
        }}
      />
      {text}
    </Control>
  );
}
