import React from 'react';

import type { ThreeSixtyReviewCycle, ThreeSixtyUserReview } from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import { pathToReviewResults } from 'helpers/navigation';

import {
  BoxListItem,
  Column,
  Columns,
  Control,
  Loading,
  PullRight,
  Testable,
  Text,
} from 'components';

import { AvatarWithStatus } from 'scenes/components/AvatarWithStatus';
import ReviewCycleIcon from 'scenes/components/reviewCycle/ListItem/ReviewCycleIcon';
import ThreeSixtyPeerStatuses from 'scenes/reviews/components/UserReviewList/ThreeSixtyUserReviewListItem/PeerStatuses';

import ManageReviewActionMenu from './ManageReviewActionMenu';
import StatusLabel from './StatusLabel';

type Props = {
  userReview: ThreeSixtyUserReview;
  reviewCycle: ThreeSixtyReviewCycle;
  refetchData: () => Promise<void>;
};

export default class UserReviewItem extends React.Component<Props> {
  render() {
    const { userReview, reviewCycle, refetchData } = this.props;
    const { evaluations, user: reviewee, responsible } = userReview;

    if (!reviewCycle || !userReview || !evaluations || !reviewee)
      return <Loading containerStyle={{ padding: 15 }} />;

    const revieweeEvaluation = evaluations.find(
      e => e.reviewerRole === 'reviewee'
    );

    return (
      <BoxListItem
        isSmall
        testClassName="test-user-review-item"
        dataTestValue={userReview.id}
      >
        <Columns>
          <Column isNarrow isVerticallyCentered>
            <ReviewCycleIcon reviewCycle={reviewCycle} />{' '}
          </Column>
          <Column isVerticallyCentered size={3}>
            <Testable name="test-reviewee-fullname">
              <AvatarWithStatus
                link={
                  can({ perform: 'view_results', on: userReview })
                    ? pathToReviewResults(userReview)
                    : undefined
                }
                user={reviewee}
                status={revieweeEvaluation && revieweeEvaluation.status}
                statusFallback={__('No self evaluation')}
              />
            </Testable>
          </Column>
          <Column isVerticallyCentered size={3}>
            <ThreeSixtyPeerStatuses
              maxInlinePeers={4}
              evaluations={evaluations}
            />
          </Column>
          <Column isVerticallyCentered size={2}>
            <Text>{(responsible && responsible.fullName) || '-'}</Text>
          </Column>
          <Column isVerticallyCentered size={2}>
            <StatusLabel
              evaluations={evaluations}
              userReview={userReview}
              reviewCycle={reviewCycle}
            />
          </Column>
          <Column isVerticallyCentered>
            <PullRight>
              <Control>
                <ManageReviewActionMenu
                  userReview={userReview}
                  reviewCycle={reviewCycle}
                  refetchData={refetchData}
                />
              </Control>
            </PullRight>
          </Column>
        </Columns>
      </BoxListItem>
    );
  }
}
