import React from 'react';

import classNames from 'helpers/classNames';

type Props = {
  isGapLess?: boolean;
  isMultiline?: boolean;
  isMobile?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
  isjustifiedCenterContent?: boolean;
  additionalClassName?: string;
  testClassName?: string;
  contentVerticallyAligned?: boolean;
};

export default function Columns({
  isGapLess,
  isMultiline,
  isMobile,
  style = {},
  children,
  isjustifiedCenterContent,
  contentVerticallyAligned,
  additionalClassName,
  testClassName,
}: Props) {
  let className = classNames('columns', additionalClassName, testClassName, {
    'is-gapless': isGapLess,
    'is-multiline': isMultiline,
    'is-mobile': isMobile,
  });

  let mergedStyle = {};
  if (isjustifiedCenterContent)
    mergedStyle = { ...mergedStyle, justifyContent: 'center' };
  if (contentVerticallyAligned)
    mergedStyle = { ...mergedStyle, alignItems: 'center' };
  mergedStyle = { ...mergedStyle, ...style };

  return (
    <div style={mergedStyle} className={className}>
      {children}
    </div>
  );
}
