import React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

const NoParticipantEmptyState = () => {
  return (
    <EmptyStateWithIcon
      iconName="equalizer"
      title={__('There are no participants in this period of the campaign')}
      description={__(
        'Check the list of participants by contacting Elevo support.'
      )}
    />
  );
};

export default NoParticipantEmptyState;
