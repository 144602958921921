import type { ApplicationAction } from '../actions';
import type { ApplicationState } from './index';

export default function application(
  state: ApplicationState = {},
  action: ApplicationAction
): ApplicationState {
  switch (action.type) {
    case 'BLOCKING_MESSAGE':
      return { ...state, blockingMessage: action.message };
    case 'SET_NOTICE':
      return { ...state, notice: action.notice };
    case 'UNSET_NOTICE':
      return { ...state, notice: undefined };
    default:
      return state;
  }
}
