import React from 'react';

import { Field, Input, Label } from 'components';

type Props = {
  name: string;
  label: string;
  value: string | undefined;
  placeholder?: string;
  cleanOnFocus?: boolean;
  onUpdate: (value: string) => void;
};

const IntegrationSettingField = ({
  name,
  label,
  value,
  placeholder,
  cleanOnFocus,
  onUpdate,
}: Props) => {
  return (
    <Field additionalClassName="integration-modal-field">
      <Label weight="bold">{label}</Label>
      <Input
        onFocus={cleanOnFocus ? () => onUpdate('') : undefined}
        value={value}
        onChange={onUpdate}
        placeholder={placeholder || label}
        name={name}
      />
    </Field>
  );
};

export default IntegrationSettingField;
