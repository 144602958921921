import { clamp } from 'lodash';
import React from 'react';

import { __ } from 'helpers/i18n';

import { Column, Columns, NumberInput } from 'components';

type Props = {
  value: number | undefined | null;
  min: number;
  max: number;
  testClassName?: string;
  onChange: (value: number | null | undefined) => Promise<void>;
};

export default function AutoAddDeadlineInput({
  value,
  min,
  max,
  testClassName,
  onChange,
}: Props) {
  const [currentValue, setCurrentValue] = React.useState(value);

  return (
    <React.Fragment>
      <Columns isGapLess>
        <Column size={1} style={{ marginRight: 8 }}>
          <NumberInput
            // @ts-ignore TSFIXME
            value={currentValue}
            min={min}
            max={max}
            onChange={(value: number | null | undefined) => {
              setCurrentValue(value);
            }}
            onBlur={async () => {
              // @ts-ignore TSFIXME
              const clampedValue = clamp(currentValue, min, max);

              onChange(clampedValue);

              if (clampedValue !== currentValue) {
                setCurrentValue(clampedValue);
              }
            }}
            style={{ textAlign: 'center' }}
            additionalClassName={'deadline-input'}
            testClassName={testClassName}
          />
        </Column>
        <Column isVerticallyCentered>
          {__('calendar days after addition of the employee in the cycle')}
        </Column>
      </Columns>
    </React.Fragment>
  );
}
