import React from 'react';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import {
  ContentContainer,
  DesignSystem,
  PageHeader,
  PageTitle,
} from 'components';

import OldUserListhWithPagination from 'scenes/components/user/OldUserListWithPagination';
import UserListWithPagination from 'scenes/components/user/UserListWithPagination';

import Actions from './Actions';

const UserList = () => {
  // TODO: multifilters: remove these 2 lines when FF is enabled for everyone
  const { featureFlags } = useCurrentOrganization();
  const withUserMultiFilters = featureFlags.includes('userMultifilters');

  return (
    <DesignSystem version={2}>
      <PageTitle title={__('Directory')} />
      <PageHeader title={__('Directory')} actions={<Actions />} />
      <ContentContainer>
        {withUserMultiFilters ? (
          <UserListWithPagination
            fetchPath="users"
            fetchParams={{ permission: 'list_in_directory?' }}
            defaultFilter={{ active: true }}
          />
        ) : (
          // TODO: multifilters: remove this condition when FF is enabled for everyone
          <OldUserListhWithPagination
            defaultPaginationParams={{ filter: { active: true } }}
            fetchPath="users"
            fetchParams={{ permission: 'list_in_directory?' }}
            paginationType="url"
          />
        )}
      </ContentContainer>
    </DesignSystem>
  );
};

export default UserList;
