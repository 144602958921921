import React from 'react';
import Responsive from 'react-responsive';

/*
  We follow Bulma viewports lexicon:
  https://bulma.io/documentation/overview/responsiveness/#breakpoints
*/

const mobileTabletBreakpoint = 768;
const tabletDesktopBreakpoint = 1024;

const mobileMax = mobileTabletBreakpoint;
const tabletMax = tabletDesktopBreakpoint;

export type ViewPortConstraint = 'mobile' | 'tablet' | 'desktop' | 'tabletOnly';

export const MobileViewPort = (props: any) => (
  <Responsive {...props} maxWidth={mobileMax} />
);

export const TabletViewPort = (props: any) => (
  <Responsive {...props} minWidth={mobileMax + 1} />
);

export const DesktopViewPort = (props: any) => (
  <Responsive {...props} minWidth={tabletMax + 1} />
);

export const TabletOnlyViewPort = (props: any) => (
  <Responsive {...props} minWidth={mobileMax + 1} maxWidth={tabletMax} />
);

type ConstraintToViewPortMapping = {
  // eslint-disable-next-line no-unused-vars
  [k in ViewPortConstraint]: React.ComponentType<any>;
};

export const constraintToViewPortMapping: ConstraintToViewPortMapping = {
  mobile: MobileViewPort,
  tablet: TabletViewPort,
  desktop: DesktopViewPort,
  tabletOnly: TabletOnlyViewPort,
};
