import { debounce, sortBy } from 'lodash';
import React, { CSSProperties, useState } from 'react';
import { components } from 'react-select';

import type { User } from 'models/User';
import type { DropdownIndicatorProps } from 'react-select';
import type { AppDispatch } from 'redux/actions';

import classNames from 'helpers/classNames';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n/index';

import { hydrateFromResponse } from 'lib/dataLoader';
import { get } from 'redux/actions/api';
import { ReduxStore } from 'redux/reducers';

import { FieldError, Icon, Select } from 'components';

import { UserPickerOption } from 'scenes/components/UserPicker/Option';

export type Option = {
  value: string;
  label: string;
  managerId: string | null;
  user: User;
};

type FetchParams = {
  permission?: string;
  not_admin?: true;
  without_admin_rights_on_the_review_campaign?: string;
};

type Props = {
  onChange: (user: User | Array<User> | null) => any;
  fetchParams?: FetchParams;
  value?: User | Array<User> | null;
  disabledUserIds?: Array<string>;
  disabledReasons?: {};
  disabled?: boolean | ((users: Array<User>) => boolean);
  placeholder?: string;
  isMulti?: boolean;
  error?: string;
  displayDisabledReason?: boolean;
  isClearable?: boolean;
  inModal?: boolean;
  style?: CSSProperties;
  additionalClassName?: string;
  fetchEndpoint?: string;
};

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <Icon name="search" />
  </components.DropdownIndicator>
);

const formatUserToOption = (user: User): Option => ({
  value: user.id,
  label: user.fullName,
  // @ts-ignore TSFIXME: Fix strictNullChecks error
  managerId: user.managerId,
  user: user,
});

const formatOptionToUser = (option: Option): User => option.user;

const UserPicker = ({
  onChange,
  fetchParams,
  value,
  disabledUserIds,
  disabledReasons,
  disabled,
  placeholder,
  isMulti,
  error,
  displayDisabledReason,
  isClearable,
  inModal,
  style,
  additionalClassName,
  fetchEndpoint = 'users',
}: Props) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(
    typeof disabled === 'boolean' ? disabled : false
  );

  const dispatch = useAppDispatch();

  const getDisabledReason = (userId: string) => {
    return disabledReasons ? disabledReasons[userId] : '';
  };

  const formatOptions = (options: Option[]) => {
    return options.map(option => {
      let isDisabled =
        disabledUserIds && disabledUserIds.includes(option.value);
      return {
        ...option,
        isDisabled,
        label:
          isDisabled && displayDisabledReason
            ? option.label + ` (${getDisabledReason(option.value)})`
            : option.label,
      };
    });
  };

  const fetchUsers = async (search: string): Promise<User[]> =>
    dispatch(async (dispatch: AppDispatch, getState: () => ReduxStore) => {
      const { response } = await dispatch(
        get(fetchEndpoint, {
          countPerPage: 10,
          search,
          ...fetchParams,
        })
      );
      const { users } = hydrateFromResponse(
        getState().data,
        response.body,
        {
          userCollection: { users: {} },
        },
        response.body.data.id
      );

      if (typeof disabled == 'function') setIsDisabled(disabled(users));

      return users;
    });

  const loadUserOptions = (
    search: string,
    callback: (options: Option[]) => void
  ) => {
    fetchUsers(search).then(users =>
      callback(
        formatOptions(
          sortBy(users.map(formatUserToOption), o => o.user.fullName)
        )
      )
    );
  };

  const debouncedSearch = debounce(loadUserOptions, 300);

  const handleChange = (option: Option | Array<Option> | null | void) => {
    if (!option) return onChange(null);

    onChange(
      Array.isArray(option)
        ? option.map(formatOptionToUser)
        : formatOptionToUser(option)
    );
  };

  let computedValue: Option | Option[] | null = null;
  if (value) {
    computedValue = Array.isArray(value)
      ? value.map(formatUserToOption)
      : formatUserToOption(value);
  }

  return (
    <div
      style={style}
      className={classNames('test-user-picker', additionalClassName)}
    >
      <Select
        loadOptions={debouncedSearch}
        value={computedValue}
        onChange={handleChange}
        isMulti={isMulti}
        placeholder={placeholder || __('Search a user')}
        loadingMessage={() => __('Loading users…')}
        isAsync
        maxMenuHeight={200}
        captureMenuScroll
        isClearable={isClearable}
        inModal={inModal}
        isDisabled={isDisabled}
        cacheOptions
        defaultOptions
        components={{
          Option: UserPickerOption,
          DropdownIndicator,
        }}
      />

      {error && <FieldError>{error}</FieldError>}
    </div>
  );
};

export default UserPicker;
