const uniqueSvg = `<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="40" cy="40" r="40" fill="#E8F4FA"/>
<g clip-path="url(#clip252C5E50-4725-4A9B-A7BE-9E98095528F8)">
<path d="M40.0121 38V47" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M36.0121 38L29.0121 45" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M29.0121 47C30.1167 47 31.0121 46.1046 31.0121 45C31.0121 43.8954 30.1167 43 29.0121 43C27.9075 43 27.0121 43.8954 27.0121 45C27.0121 46.1046 27.9075 47 29.0121 47Z" fill="white" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M44.0121 38L51.0121 45" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M51.5121 47C52.6167 47 53.5121 46.1046 53.5121 45C53.5121 43.8954 52.6167 43 51.5121 43C50.4075 43 49.5121 43.8954 49.5121 45C49.5121 46.1046 50.4075 47 51.5121 47Z" fill="white" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M40.0121 49.5C41.1167 49.5 42.0121 48.6046 42.0121 47.5C42.0121 46.3954 41.1167 45.5 40.0121 45.5C38.9075 45.5 38.0121 46.3954 38.0121 47.5C38.0121 48.6046 38.9075 49.5 40.0121 49.5Z" fill="white" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M47.0121 64.5C47.0096 64.2153 46.9087 63.9402 46.7264 63.7214C46.5441 63.5027 46.2917 63.3538 46.0121 63.3V62.5C46.0121 60.9087 45.38 59.3826 44.2548 58.2574C43.1295 57.1322 41.6034 56.5 40.0121 56.5C38.4208 56.5 36.8947 57.1322 35.7695 58.2574C34.6443 59.3826 34.0121 60.9087 34.0121 62.5V63.3C33.7205 63.3546 33.4586 63.5133 33.2751 63.7464C33.0916 63.9795 32.999 64.2713 33.0143 64.5676C33.0297 64.8639 33.1521 65.1446 33.3588 65.3574C33.5655 65.5703 33.8424 65.7009 34.1381 65.725C34.4192 67.0749 35.1567 68.2869 36.2266 69.1569C37.2964 70.0269 38.6332 70.5018 40.0121 70.5018C41.391 70.5018 42.7279 70.0269 43.7977 69.1569C44.8675 68.2869 45.605 67.0749 45.8861 65.725C46.1924 65.6972 46.4775 65.5563 46.6856 65.3299C46.8938 65.1034 47.0101 64.8076 47.0121 64.5Z" fill="#FFCEBF" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M36.7621 66C37.1763 66 37.5121 65.6642 37.5121 65.25C37.5121 64.8358 37.1763 64.5 36.7621 64.5C36.3479 64.5 36.0121 64.8358 36.0121 65.25C36.0121 65.6642 36.3479 66 36.7621 66Z" fill="#FFB59E"/>
<path d="M43.2621 66C43.6763 66 44.0121 65.6642 44.0121 65.25C44.0121 64.8358 43.6763 64.5 43.2621 64.5C42.8479 64.5 42.5121 64.8358 42.5121 65.25C42.5121 65.6642 42.8479 66 43.2621 66Z" fill="#FFB59E"/>
<path d="M38.5121 67.046C38.9564 67.3419 39.4783 67.4998 40.0121 67.4998C40.5459 67.4998 41.0678 67.3419 41.5121 67.046" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M46.5121 61C46.5121 59.2761 45.8273 57.6228 44.6083 56.4038C43.3893 55.1848 41.736 54.5 40.0121 54.5C38.2882 54.5 36.6349 55.1848 35.4159 56.4038C34.1969 57.6228 33.5121 59.2761 33.5121 61V62.5C33.5121 62.6326 33.5648 62.7598 33.6585 62.8536C33.7523 62.9473 33.8795 63 34.0121 63H35.0121C35.1447 63 35.2719 62.9473 35.3656 62.8536C35.4594 62.7598 35.5121 62.6326 35.5121 62.5C35.5127 61.9047 35.6329 61.3156 35.8655 60.7676C36.0981 60.2197 36.4383 59.724 36.8661 59.31C36.9754 59.2026 37.1079 59.1218 37.2533 59.0738C37.3988 59.0258 37.5534 59.0118 37.7051 59.033C38.3344 59.1134 38.9188 59.4021 39.3651 59.853C39.9564 60.4631 40.7364 60.8557 41.5787 60.9673C42.421 61.079 43.2762 60.903 44.0061 60.468C44.2584 60.9549 44.4207 61.4834 44.4851 62.028C44.4998 62.1576 44.5616 62.2773 44.6588 62.3643C44.7559 62.4513 44.8817 62.4996 45.0121 62.5H46.0121C46.1447 62.5 46.2719 62.4473 46.3656 62.3536C46.4594 62.2598 46.5121 62.1326 46.5121 62V61Z" fill="#FFDF3D" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M40.0121 56.5C41.3123 56.4998 42.5902 56.8376 43.7204 57.4803C44.8506 58.123 45.7944 59.0485 46.459 60.166C46.2576 58.5985 45.492 57.158 44.3054 56.1141C43.1188 55.0702 41.5925 54.4944 40.0121 54.4944C38.4316 54.4944 36.9053 55.0702 35.7187 56.1141C34.5321 57.158 33.7665 58.5985 33.5651 60.166C34.2297 59.0485 35.1735 58.123 36.3037 57.4803C37.434 56.8376 38.7119 56.4998 40.0121 56.5Z" fill="#FFEF9E"/>
<path d="M46.5121 61C46.5121 59.2761 45.8273 57.6228 44.6083 56.4038C43.3893 55.1848 41.736 54.5 40.0121 54.5C38.2882 54.5 36.6349 55.1848 35.4159 56.4038C34.1969 57.6228 33.5121 59.2761 33.5121 61V62.5C33.5121 62.6326 33.5648 62.7598 33.6585 62.8536C33.7523 62.9473 33.8795 63 34.0121 63H35.0121C35.1447 63 35.2719 62.9473 35.3656 62.8536C35.4594 62.7598 35.5121 62.6326 35.5121 62.5C35.5127 61.9047 35.6329 61.3156 35.8655 60.7676C36.0981 60.2197 36.4383 59.724 36.8661 59.31C36.9754 59.2026 37.1079 59.1218 37.2533 59.0738C37.3988 59.0258 37.5534 59.0118 37.7051 59.033C38.3344 59.1134 38.9188 59.4021 39.3651 59.853C39.9564 60.4631 40.7364 60.8557 41.5787 60.9673C42.421 61.079 43.2762 60.903 44.0061 60.468C44.2584 60.9549 44.4207 61.4834 44.4851 62.028C44.4998 62.1576 44.5616 62.2773 44.6588 62.3643C44.7559 62.4513 44.8817 62.4996 45.0121 62.5H46.0121C46.1447 62.5 46.2719 62.4473 46.3656 62.3536C46.4594 62.2598 46.5121 62.1326 46.5121 62V61Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M44.0061 60.468C44.0585 60.4364 44.1005 60.3901 44.127 60.3348C44.1534 60.2796 44.1632 60.2179 44.155 60.1572C44.1468 60.0966 44.121 60.0396 44.0809 59.9933C44.0408 59.9471 43.988 59.9136 43.9291 59.897C43.1431 59.6765 42.4627 59.1807 42.0121 58.5" fill="#FFDF3D"/>
<path d="M44.0061 60.468C44.0585 60.4364 44.1005 60.3901 44.127 60.3348C44.1534 60.2796 44.1632 60.2179 44.155 60.1572C44.1468 60.0966 44.121 60.0396 44.0809 59.9933C44.0408 59.9471 43.988 59.9136 43.9291 59.897C43.1431 59.6765 42.4627 59.1807 42.0121 58.5" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M37.7621 63.5C37.9002 63.5 38.0121 63.3881 38.0121 63.25C38.0121 63.1119 37.9002 63 37.7621 63C37.624 63 37.5121 63.1119 37.5121 63.25C37.5121 63.3881 37.624 63.5 37.7621 63.5Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M42.2621 63.5C42.4002 63.5 42.5121 63.3881 42.5121 63.25C42.5121 63.1119 42.4002 63 42.2621 63C42.124 63 42.0121 63.1119 42.0121 63.25C42.0121 63.3881 42.124 63.5 42.2621 63.5Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M40.0121 54.5C40.864 54.4996 41.7077 54.6673 42.4948 54.9933C43.2819 55.3193 43.9969 55.7973 44.5991 56.4C44.866 55.9816 45.0092 55.4963 45.0121 55C45.0121 53.067 42.7731 51.5 40.0121 51.5C37.2511 51.5 35.0121 53.067 35.0121 55C35.0145 55.4963 35.1577 55.9818 35.4251 56.4C36.0272 55.7973 36.7423 55.3193 37.5294 54.9933C38.3165 54.6673 39.1601 54.4996 40.0121 54.5Z" fill="#FFDF3D" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M72.9121 43C72.9121 44.9096 72.1535 46.7409 70.8033 48.0912C69.453 49.4414 67.6217 50.2 65.7121 50.2C63.8025 50.2 61.9712 49.4414 60.6209 48.0912C59.2706 46.7409 58.5121 44.9096 58.5121 43C58.5121 39.019 60.5451 35.9 62.6121 35.3C62.7844 35.2505 62.9668 35.2483 63.1403 35.2935C63.3137 35.3387 63.4719 35.4297 63.5981 35.557C65.8361 37.809 72.9121 35 72.9121 43Z" fill="#FFDF3D"/>
<path d="M63.3121 37.6C65.0341 40.329 72.0061 37.417 72.8311 44.027C72.8825 43.687 72.9095 43.3439 72.9121 43C72.9121 35 65.8351 37.8091 63.5981 35.5591C63.4719 35.4318 63.3137 35.3407 63.1403 35.2955C62.9668 35.2503 62.7844 35.2526 62.6121 35.302C60.5451 35.896 58.5121 39.019 58.5121 43.002C58.5148 43.354 58.5436 43.7053 58.5981 44.053C59.0431 40.3 61.2711 37.6 63.3121 37.6Z" fill="#FFEF9E"/>
<path d="M72.9121 43C72.9121 44.9096 72.1535 46.7409 70.8033 48.0912C69.453 49.4414 67.6217 50.2 65.7121 50.2C63.8025 50.2 61.9712 49.4414 60.6209 48.0912C59.2706 46.7409 58.5121 44.9096 58.5121 43C58.5121 39.019 60.5451 35.9 62.6121 35.3C62.7844 35.2505 62.9668 35.2483 63.1403 35.2935C63.3137 35.3387 63.4719 35.4297 63.5981 35.557C65.8361 37.809 72.9121 35 72.9121 43Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M71.7201 44.8L70.5111 45.4L69.6391 42.348C69.5575 42.0553 69.367 41.8048 69.1068 41.6479C68.8465 41.491 68.5361 41.4395 68.2391 41.504C66.5775 41.8986 64.8467 41.8986 63.1851 41.504C62.8881 41.4395 62.5777 41.491 62.3174 41.6479C62.0572 41.8048 61.8667 42.0553 61.7851 42.348L60.9121 45.4L59.7211 44.8C58.0091 44.8 58.1211 47.8 60.0211 47.8H60.0261C60.4023 49.0165 61.1581 50.0807 62.1829 50.8365C63.2078 51.5924 64.4477 52.0002 65.7211 52.0002C66.9945 52.0002 68.2344 51.5924 69.2593 50.8365C70.2841 50.0807 71.0399 49.0165 71.4161 47.8C73.3121 47.8 73.4321 44.8 71.7201 44.8Z" fill="#FFCEBF" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M63.7761 45.2C63.9142 45.2 64.0261 45.0881 64.0261 44.95C64.0261 44.8119 63.9142 44.7 63.7761 44.7C63.6381 44.7 63.5261 44.8119 63.5261 44.95C63.5261 45.0881 63.6381 45.2 63.7761 45.2Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M67.676 45.2C67.8141 45.2 67.926 45.0881 67.926 44.95C67.926 44.8119 67.8141 44.7 67.676 44.7C67.538 44.7 67.426 44.8119 67.426 44.95C67.426 45.0881 67.538 45.2 67.676 45.2Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M62.7261 46.6C62.7261 46.7186 62.6909 46.8347 62.625 46.9333C62.5591 47.032 62.4653 47.1089 62.3557 47.1544C62.2461 47.1998 62.1255 47.2116 62.0091 47.1885C61.8927 47.1653 61.7858 47.1082 61.7018 47.0243C61.6179 46.9404 61.5608 46.8334 61.5377 46.717C61.5145 46.6007 61.5264 46.48 61.5718 46.3704C61.6172 46.2607 61.6941 46.1671 61.7928 46.1011C61.8915 46.0352 62.0075 46 62.1261 46C62.2049 46 62.2829 46.0155 62.3557 46.0457C62.4285 46.0758 62.4947 46.12 62.5504 46.1757C62.6061 46.2314 62.6503 46.2976 62.6805 46.3704C62.7106 46.4432 62.7261 46.5212 62.7261 46.6Z" fill="#FFB59E"/>
<path d="M69.9261 46.6C69.9261 46.7186 69.8909 46.8347 69.825 46.9333C69.759 47.032 69.6653 47.1089 69.5557 47.1544C69.4461 47.1998 69.3254 47.2116 69.209 47.1885C69.0926 47.1653 68.9857 47.1082 68.9018 47.0243C68.8179 46.9404 68.7608 46.8334 68.7376 46.717C68.7145 46.6007 68.7263 46.48 68.7718 46.3704C68.8172 46.2607 68.8941 46.1671 68.9927 46.1011C69.0914 46.0352 69.2074 46 69.3261 46C69.4049 46 69.4829 46.0155 69.5557 46.0457C69.6285 46.0758 69.6946 46.12 69.7503 46.1757C69.806 46.2314 69.8502 46.2976 69.8804 46.3704C69.9105 46.4432 69.9261 46.5212 69.9261 46.6Z" fill="#FFB59E"/>
<path d="M64.228 48.546C64.672 48.8427 65.194 49.001 65.728 49.001C66.262 49.001 66.784 48.8427 67.228 48.546" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.012 46C22.0097 45.7152 21.9088 45.44 21.7265 45.2213C21.5442 45.0025 21.2917 44.8536 21.012 44.8V44C21.012 42.4087 20.3799 40.8826 19.2547 39.7574C18.1295 38.6322 16.6033 38 15.012 38C13.4207 38 11.8946 38.6322 10.7694 39.7574C9.64416 40.8826 9.01203 42.4087 9.01203 44V44.8C8.71985 44.8538 8.45721 45.0121 8.27311 45.2452C8.089 45.4784 7.99599 45.7706 8.01142 46.0673C8.02684 46.364 8.14965 46.645 8.35694 46.8578C8.56423 47.0706 8.84185 47.2008 9.13803 47.224C9.41914 48.574 10.1567 49.7859 11.2265 50.6559C12.2963 51.5259 13.6331 52.0009 15.012 52.0009C16.3909 52.0009 17.7278 51.5259 18.7976 50.6559C19.8674 49.7859 20.6049 48.574 20.886 47.224C21.1922 47.1962 21.4771 47.0555 21.6852 46.8292C21.8933 46.603 22.0098 46.3074 22.012 46Z" fill="#D18660" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.0121 40.5C16.1847 40.4743 17.3454 40.7411 18.3891 41.2764C19.4328 41.8117 20.3267 42.5986 20.9901 43.566C20.8829 42.0537 20.2065 40.6382 19.0972 39.6048C17.9879 38.5714 16.5281 37.9968 15.0121 37.9968C13.496 37.9968 12.0362 38.5714 10.9269 39.6048C9.81765 40.6382 9.14126 42.0537 9.03406 43.566C9.69743 42.5986 10.5913 41.8117 11.635 41.2764C12.6787 40.7411 13.8394 40.4743 15.0121 40.5Z" fill="#FFE8E0"/>
<path d="M22.012 46C22.0097 45.7152 21.9088 45.44 21.7265 45.2213C21.5442 45.0025 21.2917 44.8536 21.012 44.8V44C21.012 42.4087 20.3799 40.8826 19.2547 39.7574C18.1295 38.6322 16.6033 38 15.012 38C13.4207 38 11.8946 38.6322 10.7694 39.7574C9.64416 40.8826 9.01203 42.4087 9.01203 44V44.8C8.71985 44.8538 8.45721 45.0121 8.27311 45.2452C8.089 45.4784 7.99599 45.7706 8.01142 46.0673C8.02684 46.364 8.14965 46.645 8.35694 46.8578C8.56423 47.0706 8.84185 47.2008 9.13803 47.224C9.41914 48.574 10.1567 49.7859 11.2265 50.6559C12.2963 51.5259 13.6331 52.0009 15.012 52.0009C16.3909 52.0009 17.7278 51.5259 18.7976 50.6559C19.8674 49.7859 20.6049 48.574 20.886 47.224C21.1922 47.1962 21.4771 47.0555 21.6852 46.8292C21.8933 46.603 22.0098 46.3074 22.012 46V46Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.7621 45C12.9002 45 13.0121 44.8881 13.0121 44.75C13.0121 44.6119 12.9002 44.5 12.7621 44.5C12.624 44.5 12.5121 44.6119 12.5121 44.75C12.5121 44.8881 12.624 45 12.7621 45Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.2621 45C17.4002 45 17.5121 44.8881 17.5121 44.75C17.5121 44.6119 17.4002 44.5 17.2621 44.5C17.124 44.5 17.0121 44.6119 17.0121 44.75C17.0121 44.8881 17.124 45 17.2621 45Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.7621 47.5C12.1763 47.5 12.5121 47.1642 12.5121 46.75C12.5121 46.3358 12.1763 46 11.7621 46C11.3479 46 11.0121 46.3358 11.0121 46.75C11.0121 47.1642 11.3479 47.5 11.7621 47.5Z" fill="#BD704A"/>
<path d="M18.2621 47.5C18.6763 47.5 19.0121 47.1642 19.0121 46.75C19.0121 46.3358 18.6763 46 18.2621 46C17.8479 46 17.5121 46.3358 17.5121 46.75C17.5121 47.1642 17.8479 47.5 18.2621 47.5Z" fill="#BD704A"/>
<path d="M13.5121 48.546C13.9564 48.8419 14.4783 48.9998 15.0121 48.9998C15.5459 48.9998 16.0678 48.8419 16.5121 48.546" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.51208 39.5V44C8.51208 44.1326 8.56477 44.2598 8.65854 44.3536C8.75231 44.4473 8.87948 44.5 9.01208 44.5H9.51208L11.5121 39.5H8.51208Z" fill="#614B44" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.512 40C21.512 38.9391 21.0906 37.9217 20.3405 37.1716C19.5903 36.4214 18.5729 36 17.512 36H13.012C12.8211 36.0001 12.6342 35.9455 12.4733 35.8428C12.3124 35.74 12.1842 35.5932 12.1041 35.42C12.0516 35.3037 11.9691 35.2035 11.865 35.1297C11.761 35.0559 11.6391 35.0111 11.512 35H10.662C9.89609 34.9849 9.15171 35.254 8.57244 35.7554C7.99317 36.2567 7.62004 36.9548 7.52504 37.715C7.45018 38.4997 7.68717 39.2823 8.18474 39.8937C8.68232 40.505 9.4005 40.8959 10.184 40.982C10.3255 41.5127 10.6231 41.9888 11.0381 42.3486C11.4531 42.7083 11.9666 42.9353 12.512 43H13.512C13.5519 43.0003 13.5907 42.9874 13.6224 42.9634C13.6542 42.9394 13.6771 42.9055 13.6877 42.8671C13.6983 42.8287 13.696 42.7879 13.681 42.751C13.6661 42.7141 13.6394 42.6832 13.605 42.663C13.1509 42.392 12.8202 41.9547 12.683 41.444C12.6672 41.3926 12.6637 41.3382 12.6727 41.2851C12.6818 41.2321 12.7032 41.182 12.7352 41.1388C12.7673 41.0956 12.809 41.0605 12.8571 41.0364C12.9052 41.0123 12.9583 40.9998 13.012 41H17.098C17.3632 41 17.6175 41.1055 17.805 41.293L20.512 44H21.012C21.1447 44 21.2718 43.9473 21.3656 43.8536C21.4594 43.7598 21.512 43.6326 21.512 43.5V40Z" fill="#614B44"/>
<path d="M17.5121 36H13.0121C12.8212 36.0001 12.6342 35.9455 12.4733 35.8428C12.3124 35.74 12.1843 35.5932 12.1041 35.42C12.0517 35.3037 11.9692 35.2035 11.8651 35.1297C11.7611 35.0559 11.6392 35.0111 11.5121 35H10.6621C9.89616 34.9849 9.15178 35.254 8.57251 35.7554C7.99323 36.2567 7.62011 36.9548 7.52511 37.715C7.49174 38.0767 7.52322 38.4414 7.6181 38.792C9.55393 38.3683 11.5305 38.1587 13.5121 38.167C16.2093 38.1381 18.8936 38.5433 21.4621 39.367C21.3116 38.4278 20.8313 37.5729 20.1074 36.9559C19.3834 36.3388 18.4633 35.9999 17.5121 36Z" fill="#947063"/>
<path d="M21.512 40C21.512 38.9391 21.0906 37.9217 20.3405 37.1716C19.5903 36.4214 18.5729 36 17.512 36H13.012C12.8211 36.0001 12.6342 35.9455 12.4733 35.8428C12.3124 35.74 12.1842 35.5932 12.1041 35.42C12.0516 35.3037 11.9691 35.2035 11.865 35.1297C11.761 35.0559 11.6391 35.0111 11.512 35H10.662C9.89609 34.9849 9.15171 35.254 8.57244 35.7554C7.99317 36.2567 7.62004 36.9548 7.52504 37.715C7.45018 38.4997 7.68717 39.2823 8.18474 39.8937C8.68232 40.505 9.4005 40.8959 10.184 40.982C10.3255 41.5127 10.6231 41.9888 11.0381 42.3486C11.4531 42.7083 11.9666 42.9353 12.512 43H13.512C13.5519 43.0003 13.5907 42.9874 13.6224 42.9634C13.6542 42.9394 13.6771 42.9055 13.6877 42.8671C13.6983 42.8287 13.696 42.7879 13.681 42.751C13.6661 42.7141 13.6394 42.6832 13.605 42.663C13.1509 42.392 12.8202 41.9547 12.683 41.444C12.6672 41.3926 12.6637 41.3382 12.6727 41.2851C12.6818 41.2321 12.7032 41.182 12.7352 41.1388C12.7673 41.0956 12.809 41.0605 12.8571 41.0364C12.9052 41.0123 12.9583 40.9998 13.012 41H17.098C17.3632 41 17.6175 41.1055 17.805 41.293L20.512 44H21.012C21.1447 44 21.2718 43.9473 21.3656 43.8536C21.4594 43.7598 21.512 43.6326 21.512 43.5V40Z" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M49.0121 30.333C49.0122 30.4863 48.9821 30.6381 48.9235 30.7797C48.8649 30.9214 48.779 31.0501 48.6706 31.1585C48.5622 31.2669 48.4335 31.3528 48.2918 31.4114C48.1502 31.47 47.9984 31.5001 47.8451 31.5H32.6791C32.5258 31.5001 32.374 31.47 32.2324 31.4114C32.0907 31.3528 31.962 31.2669 31.8536 31.1585C31.7452 31.0501 31.6592 30.9214 31.6006 30.7797C31.542 30.6381 31.512 30.4863 31.5121 30.333V11.667C31.512 11.5137 31.542 11.3619 31.6006 11.2202C31.6592 11.0786 31.7452 10.9499 31.8536 10.8415C31.962 10.7331 32.0907 10.6472 32.2324 10.5886C32.374 10.53 32.5258 10.4999 32.6791 10.5H43.5121L49.0121 16V30.333Z" fill="white" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M43.5121 10.5V16H49.0121L43.5121 10.5Z" fill="#E8F4FA" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.5121 16.5H40.5121" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.5121 20H46.0121" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.5121 23.5H46.0121" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.5121 27H46.0121" stroke="#45413C" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip252C5E50-4725-4A9B-A7BE-9E98095528F8">
<rect width="66.428" height="61" fill="white" transform="translate(7 10)"/>
</clipPath>
</defs>
</svg>
`;

export default uniqueSvg;
