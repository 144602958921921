import React from 'react';

import type { User } from 'models';

import { Box, WithShadowAnimation } from 'components';

import Actions from './Actions';
import UserDetails from './UserDetails';

type Props = {
  report: User;
};

const DirectReportCard = ({ report }: Props) => {
  return (
    <WithShadowAnimation style={{ borderRadius: 4 }}>
      <Box
        style={{
          paddingTop: 30,
          paddingLeft: 8,
          paddingRight: 8,
          paddingBottom: 12,
        }}
      >
        <UserDetails report={report} style={{ marginBottom: 22 }} />
        <Actions report={report} />
      </Box>
    </WithShadowAnimation>
  );
};

export default DirectReportCard;
