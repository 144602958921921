import React, { Fragment } from 'react';

import { __ } from 'helpers/i18n';

import { Text } from 'components';

import ParticipantsChooser from 'scenes/components/ParticipantsChooser';

type Props = {
  campaignId: string;
  includedParticipantsCount: number;
};

const IncludeParticipants = ({
  campaignId,
  includedParticipantsCount,
}: Props) => {
  return (
    <Fragment>
      <Text additionalClassName="my-4 block">
        {__('The participants you select will be added to this campaign.')}
      </Text>
      <ParticipantsChooser
        fetchUrl={`survey/campaigns/${campaignId}/included_participants`}
        updateUrl={`survey/campaigns/${campaignId}/included_participants`}
        additionalUpdateParams={{ setupStepIndex: 2 }}
        participantsCount={includedParticipantsCount}
        showBulkActions
      />
    </Fragment>
  );
};

export default IncludeParticipants;
