import React from 'react';

import { Icon, Text } from 'components';

export type Props = {
  label: string;
  isActive: boolean;
  onLinkClick: () => void;
  onRemoveClick: () => void;
  activeFilterLabel?: string;
};

const FilterText = ({
  label,
  isActive,
  onLinkClick,
  onRemoveClick,
  activeFilterLabel,
}: Props) => {
  return (
    <div className="flex items-center flex-shrink-0">
      <Text
        onLinkClick={onLinkClick}
        additionalClassName={`whitespace-nowrap mr-1 ${
          isActive ? 'font-bold' : ''
        }`}
      >
        {label}
      </Text>
      {!!activeFilterLabel && (
        <Text additionalClassName="truncate grow">
          {`: ${activeFilterLabel}`}
        </Text>
      )}

      {isActive && (
        <Icon
          name="close"
          additionalClassName="shrink-0 cursor-pointer"
          onClick={onRemoveClick}
        />
      )}
    </div>
  );
};

export default FilterText;
