import { omit } from 'lodash';
import React from 'react';
import { compose } from 'redux';

import type { UserObjectivePeriodsCollection } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n/index';

import newDataLoader from 'lib/dataLoader/newDataLoader';
import { WithPaginationProps } from 'lib/pagination/types';
import withPagination from 'lib/pagination/withPagination';
import { get } from 'redux/actions/api';

import { Box, DatatableWrapper, EmptyState } from 'components';

import UsersList from './UsersList';

type Props = {
  additionalQueryParams?: Record<string, any>;
};

type AfterPaginatedProps = WithPaginationProps & Props;

type AfterDataLoaderProps = AfterPaginatedProps & {
  userObjectivePeriodsCollection: UserObjectivePeriodsCollection;
  isFetching: boolean;
  hasError: boolean;
};

function UserObjectivesListWithPagination({
  queryParams: { search, filter, userFilters },
  page,
  countPerPage,
  userObjectivePeriodsCollection,
  isFetching,
  hasError,
  setNextPageParams,
  setPreviousPageParams,
  setQueryParams,
}: AfterDataLoaderProps) {
  const userObjectivePeriods = userObjectivePeriodsCollection
    ? userObjectivePeriodsCollection.items
    : [];

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={
          userObjectivePeriodsCollection
            ? omit(userObjectivePeriodsCollection, 'items')
            : null
        }
        search={search}
        page={page}
        countPerPage={countPerPage}
        getPreviousPage={setPreviousPageParams}
        getNextPage={setNextPageParams}
        onQueryParamsChange={setQueryParams}
        withSearch
        userFilters={userFilters}
        searchPlaceholder={__('Search employees')}
        totalCountRenderer={count =>
          n__('%1 employee', '%1 employees', count || 0)
        }
        isFetching={isFetching}
        hasError={hasError}
        filters={[
          { param: 'all', label: __('All') },
          { param: 'with_objectives', label: __('With objectives') },
          { param: 'without_objectives', label: __('Without objectives') },
        ]}
        filter={filter}
        renderNoResult={() => (
          <EmptyState
            title={__('No employee matches your search.')}
            inBoxList
          />
        )}
      >
        <UsersList userObjectivePeriods={userObjectivePeriods} />
      </DatatableWrapper>
    </Box>
  );
}

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      queryParams: { search, filter, userFilters },
    }: AfterPaginatedProps) =>
      get('user_objective_periods/dashboard', {
        page,
        countPerPage,
        search,
        filter,
        userFilters,
      }),
    hydrate: {
      userObjectivePeriodsCollection: {
        items: {
          user: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      queryParams: { search, filter, userFilters },
    }: AfterPaginatedProps) =>
      compositeKey({
        page,
        countPerPage,
        search,
        filter,
        userFilters,
      }),
  })
)(UserObjectivesListWithPagination);
