import React from 'react';

import type {
  Answer,
  FeedbackableItem,
  FeedbackableRatingCriterion,
} from 'models';
import type { AppDispatch } from 'redux/actions/types';

import invariant from 'helpers/invariant';

import { Column, Columns, Flex } from 'components';

import OpenAnswer from '../../components/FeedbackZone/OpenAnswer';

type FeedbackableItemWithOpenAnswer = FeedbackableItem<any> & {
  openAnswer: Answer;
};

function mapFeedbackableItemWithOpenAnswer(
  feedbackableItems
): Array<FeedbackableItemWithOpenAnswer> {
  let result: Array<FeedbackableItemWithOpenAnswer> = [];

  feedbackableItems.map(feedbackableItem => {
    const openAnswer = feedbackableItem.answers.find(
      answer => answer.status === 'opened'
    );

    if (!openAnswer) return null;

    return result.push({ ...feedbackableItem, openAnswer });
  });

  return result;
}

type Props = {
  feedbackableCriteria: Array<FeedbackableItem<FeedbackableRatingCriterion>>;
  persistAction: (
    evaluationId: string | undefined | null,
    feedbackableId: string | undefined | null,
    feedbackableType: string | undefined | null,
    text: string,
    rating: string | undefined | null
  ) => (dispatch: AppDispatch) => Promise<void>;
};

export default function RatingCriteria({
  feedbackableCriteria,
  persistAction,
}: Props) {
  const feedbackableCriteriaWithOpenAnswer =
    mapFeedbackableItemWithOpenAnswer(feedbackableCriteria);

  if (feedbackableCriteriaWithOpenAnswer?.length === 0) return null;

  const ratings = feedbackableCriteriaWithOpenAnswer[0].openAnswer.ratings;

  invariant(ratings, 'ratings must be defined at this point');

  return (
    <div className="rating-criteria">
      <Columns additionalClassName="rating-criteria-header" isMobile>
        <Column size={8} offset={4} isVerticallyCentered>
          <Flex
            style={{ justifyContent: 'space-around', textAlign: 'center' }}
            verticalAlign
          >
            {ratings.map(rating => (
              <span
                className="rating-criteria-content"
                key={rating}
                style={{ width: `${100 / ratings.length}%` }}
              >
                {rating}
              </span>
            ))}
          </Flex>
        </Column>
      </Columns>

      {feedbackableCriteriaWithOpenAnswer.map(
        ({ id, item, openAnswer, blockId }) => (
          <div
            className="feedback-zone rating-criterion test-rating-criterion"
            key={id}
          >
            <OpenAnswer
              itemId={item.id}
              itemType={item.type}
              itemLabel={item.label}
              answer={openAnswer}
              blockId={blockId}
              persistAction={persistAction}
            />
          </div>
        )
      )}
    </div>
  );
}
