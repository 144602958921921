import React from 'react';

import type { TrainingOverallBudgetStats } from 'models';

import { __ } from 'helpers/i18n';

import { Link } from 'components';

import CardContentWithCompletion from './CardContentWithCompletion';
import CardContentWithoutCompletion from './CardContentWithoutCompletion';

type Props = {
  stats: TrainingOverallBudgetStats;
  onClick: () => void;
};

const BudgetCardWithStats = ({ stats, onClick }: Props) => {
  const budgetCardLink = (
    <Link
      additionalClassName="budget-card-link"
      onClick={onClick}
      testClassName="test-budget-details-link"
    >
      {__('Details')}
    </Link>
  );

  const displayBudgetCompletion = stats.provisionedBudgetCents;

  const CardContentComponent = displayBudgetCompletion
    ? CardContentWithCompletion
    : CardContentWithoutCompletion;

  return <CardContentComponent stats={stats} link={budgetCardLink} />;
};

export default BudgetCardWithStats;
