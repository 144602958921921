import React from 'react';

type Props = {
  shouldBlock: boolean;
};

export default class OnCloseBlockingConfirm extends React.Component<Props> {
  preventClosing = (e: any) => {
    if (this.props.shouldBlock) {
      // Cancel the event
      e.preventDefault();

      // Chrome requires returnValue to be set
      e.returnValue = '';
    }
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.preventClosing);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.preventClosing);
  }

  render() {
    return null;
  }
}
