import React from 'react';

import type { RenderEditedParams } from '../InlineEditableWrapper.js';
import type { AvailableInputProps } from './TextInputRenderer';

import { __ } from 'helpers/i18n';

import { Control, PercentageInput } from 'components';

type Props = RenderEditedParams<number> & AvailableInputProps;

export default function PercentageInputRenderer({
  submitIfChanged,
  isSubmitting,
  hasError,
  hasWarning,
  toggle,
  onChange,
  value,
  targetPosition,
  isClearable,
  displayErrorMessage,
  ...inputProps
}: Props) {
  return (
    <div>
      <Control
        isClearable={(!!value || value === 0) && isClearable}
        onClear={() => onChange(null)}
      >
        <PercentageInput
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onBlur={() => submitIfChanged(value)}
          disabled={isSubmitting}
          onEnterKeyPress={() => submitIfChanged(value)}
          onEscapeKeyPress={toggle}
          value={value === null ? undefined : value}
          onChange={onChange}
          {...inputProps}
        />
      </Control>

      {hasError && displayErrorMessage && (
        <p className="help is-danger">
          {__(
            'Oops, it looks like we had trouble saving your change. Please try again (or contact support@elevo.fr if it persists)'
          )}
        </p>
      )}
    </div>
  );
}
