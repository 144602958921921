import moment from 'moment';
import React from 'react';

import type { HistorySectionItem } from '../components/HistorySectionItem';
import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { TrainingRequest, User } from 'models';
import type { ComponentType } from 'react';

import { __ } from 'helpers/i18n';
import { approvalStatusLabel } from 'helpers/models/trainingRequest';
import { pathToUserTrainings } from 'helpers/paths';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Icon } from 'components';

import HistorySection, {
  HISTORY_ITEM_LIMIT,
} from '../components/HistorySection';

const mapTrainingRequestToHistoryItem = (
  trainingRequest: TrainingRequest
): HistorySectionItem => {
  return {
    id: trainingRequest.id,
    title: trainingRequest.title,
    subTitle: approvalStatusLabel(trainingRequest.approvalStatus),
    date: moment(trainingRequest.requestedAt).format('MMMM YYYY'),
  };
};

type Props = {
  user: User;
};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    trainingRequests: Array<TrainingRequest>;
  };

function TrainingRequestHistory({
  user,
  trainingRequests,
  isFetching,
  hasError,
}: AfterConnectProps) {
  const historySectionItems = trainingRequests?.map(trainingRequest =>
    mapTrainingRequestToHistoryItem(trainingRequest)
  );

  return (
    <HistorySection
      icon={<Icon name="school" />}
      title={__('Training requests')}
      historySectionItems={historySectionItems}
      emptyState={__('No training request has been done')}
      seeMorePath={pathToUserTrainings(user.id)}
      seeMoreLabel={__('See all trainings')}
      isFetching={isFetching}
      hasError={hasError}
    />
  );
}

export default newDataLoader({
  fetch: ({ user }) =>
    get(`users/${user.id}/training_requests`, {
      limit: HISTORY_ITEM_LIMIT + 1,
    }),
  hydrate: {
    trainingRequests: {
      session: {},
    },
  },
})(TrainingRequestHistory) as ComponentType<Props>;
