import React, { Fragment } from 'react';

import type { BlockFeedbackOptions } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { put } from 'redux/actions/api';

import { Title } from 'components';

import FeedbackOption from '../components/FeedbackOptionsSection/FeedbackOption';

type Props = {
  feedbackOption: BlockFeedbackOptions;
  hasSelfEvaluationEnabled: boolean;
};

const FeedbackOptionsSection = ({
  feedbackOption,
  hasSelfEvaluationEnabled,
}: Props) => {
  const dispatch = useAppDispatch();

  const onChange = async (
    feedbackOptionId: string,
    attributes: Partial<BlockFeedbackOptions>
  ) =>
    dispatch(
      put(`feedback_options/${feedbackOptionId}`, {
        feedbackOption: attributes,
      })
    );

  const optionLabel = (hasSelfEvaluationEnabled: boolean) => {
    if (hasSelfEvaluationEnabled) {
      return __('The reviewee and the reviewer can leave a final comment');
    }

    return __('The reviewer can leave a final comment');
  };

  return (
    <Fragment>
      <Title size={7} transformation="uppercase">
        {__('Final comment')}
      </Title>
      <FeedbackOption
        label={optionLabel(hasSelfEvaluationEnabled)}
        testClassName="test-final-comment-enabled-toggle"
        feedbackType="text"
        feedbackOptions={feedbackOption}
        onChange={onChange}
      />
    </Fragment>
  );
};

export default FeedbackOptionsSection;
