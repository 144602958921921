import React, { Fragment, useState } from 'react';

import type { TrainingParticipant } from 'models';

import { __ } from 'helpers/i18n';

import { HamburgerMenu, MenuItem, MenuList } from 'components';

import RemoveFromSessionModal from './RemoveFromSessionModal';

type Props = {
  participant: TrainingParticipant;
  sessionName: string;
  onDelete: () => Promise<void>;
};

const ParticipantsTableRowActions = ({
  participant,
  sessionName,
  onDelete,
}: Props) => {
  const [isDeletionModalActive, setIsDeletionModalActive] = useState(false);

  return (
    <Fragment>
      <HamburgerMenu>
        <MenuList>
          <MenuItem isDanger onClick={() => setIsDeletionModalActive(true)}>
            {__('Remove from session')}
          </MenuItem>
        </MenuList>
      </HamburgerMenu>
      {isDeletionModalActive && (
        <RemoveFromSessionModal
          participant={participant}
          setIsRemoveModalActive={setIsDeletionModalActive}
          sessionName={sessionName}
          onAfterRemove={onDelete}
        />
      )}
    </Fragment>
  );
};

export default ParticipantsTableRowActions;
