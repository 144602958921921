import React from 'react';

import { __ } from 'helpers/i18n';

import { Testable, Text } from 'components';

type Props = {
  description: string | null | undefined;
  disabled: boolean;
  autoFocus?: boolean;
  onChange: (value: string) => Promise<void>;
  onBlur: () => any;
};

export default function ObjectiveDescriptionSection({
  description,
  disabled,
  autoFocus,
  onChange,
  onBlur,
}: Props) {
  return (
    <Testable name="test-objective-description-field">
      <div className="objective-section-content">
        <Text
          size={6}
          isEditable={!disabled}
          editableOptions={{
            type: 'multilineText',
            value: description,
            placeholder: __('Add a description'),
            withEditIcon: false,
            fieldProps: {
              rows: 3,
              lineBreakHelperStyle: 'float',
            },
            onChange,
            autoFocus,
            onBlur,
          }}
        />
      </div>
    </Testable>
  );
}
