import React from 'react';

import {
  Box,
  BoxVerticalSeparator,
  Icon,
  Text,
  TooltipOnEllipsis,
} from 'components';
import { CopyOnClick } from 'components';

type Props = {
  beforeChange: any;
  afterChange: any;
};

const ComputedChanges = ({ beforeChange, afterChange }: Props) => {
  const displayChange = (change: any) =>
    change === null ? '-' : change.toString();

  return (
    <div className="flex mt-4 pr-4">
      <BoxVerticalSeparator additionalClassName="mx-4" />
      <Box additionalClassName="bg-ui-body-bg py-2 px-4 ml-4 flex-grow">
        <div className="flex items-center">
          <CopyOnClick text={beforeChange?.toString()}>
            <Text additionalClassName="text-text-light line-through line-clamp-6">
              <TooltipOnEllipsis style={{ maxWidth: '400px' }} lineClamp={6}>
                {displayChange(beforeChange)}
              </TooltipOnEllipsis>
            </Text>
          </CopyOnClick>
          <Icon name="arrow_forward" additionalClassName="mr-2" />
          <CopyOnClick text={afterChange?.toString()}>
            <Text additionalClassName="line-clamp-6">
              <TooltipOnEllipsis style={{ maxWidth: '400px' }} lineClamp={6}>
                {displayChange(afterChange)}
              </TooltipOnEllipsis>
            </Text>
          </CopyOnClick>
        </div>
      </Box>
    </div>
  );
};

export default ComputedChanges;
