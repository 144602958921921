import { sortBy as _sortBy } from 'lodash';
import React from 'react';

import type {
  OneOnOneReviewCycle,
  OneOnOneUserReview,
  Organization,
} from 'models';

import { withOrganization } from 'helpers/withSessionProps';

import { BoxList, FetchContainer } from 'components';

import UserReviewItem from './UserReviewItem';
import AutoAddUserReviewListHeader from './UserReviewListHeader/AutoAddUserReviewListHeader';
import StandardUserReviewListHeader from './UserReviewListHeader/StandardUserReviewListHeader';

type Props = {
  userReviews: Array<OneOnOneUserReview>;
  reviewCycle: OneOnOneReviewCycle;
  refetchData: () => Promise<void>;
  searchTerm?: string;
  isFetching: boolean;
  hasError: boolean;
};

type AfterConnectProps = Props & {
  organization: Organization;
};

function UserReviewList({
  userReviews,
  reviewCycle,
  refetchData,
  isFetching,
  hasError,
  organization,
}: AfterConnectProps) {
  const HeaderClass = reviewCycle.autoAddParticipantsEnabled
    ? AutoAddUserReviewListHeader
    : StandardUserReviewListHeader;
  const { additionalReviewerEnabled } = organization.plan;

  return (
    <BoxList>
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        loadingStyle="overlay"
        render={() => {
          return (
            <React.Fragment>
              <HeaderClass
                additionalReviewerEnabled={additionalReviewerEnabled}
                reviewCycle={reviewCycle}
              />
              {_sortBy(userReviews, userReview => userReview.user.fullName).map(
                userReview => {
                  return (
                    <UserReviewItem
                      key={userReview.id}
                      userReview={userReview}
                      reviewCycle={reviewCycle}
                      additionalReviewerEnabled={additionalReviewerEnabled}
                      refetchData={refetchData}
                    />
                  );
                }
              )}
            </React.Fragment>
          );
        }}
      />
    </BoxList>
  );
}

export default withOrganization(UserReviewList);
