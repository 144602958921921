import React, { Fragment, useState } from 'react';

import { __ } from 'helpers/i18n';

import { ContentContainer, PageHeader, PageTitle, SearchBar } from 'components';

import TrainingRequestModalButton from '../components/TrainingRequestModalButton';
import CoursesList from './CoursesList';

const Catalog = () => {
  const [search, setSearch] = useState('');

  const action = <TrainingRequestModalButton trainingCourse={null} />;

  return (
    <Fragment>
      <PageTitle title={[__('Trainings'), __('Catalog')]} />
      <PageHeader title={__('Explore the training catalog')} actions={action} />
      <ContentContainer>
        <div className="flex flex-col gap-8">
          <SearchBar
            value={search}
            onChange={setSearch}
            placeholder={__('Search')}
          />
          <CoursesList search={search} />
        </div>
      </ContentContainer>
    </Fragment>
  );
};

export default Catalog;
