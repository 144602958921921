import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import type { InteractionType } from 'models';

import { useQuery } from 'helpers/navigation';
import {
  pathToMyReviews,
  pathToMyTeamReviews,
  pathToOtherActions,
} from 'helpers/paths';

import { Redirect, RouteAlias, Switch } from 'components';

const DeprecatedHomeRoutes = () => {
  const { path } = useRouteMatch();
  const query = useQuery();
  const type: InteractionType = (query.get('type') ||
    'one_on_one') as InteractionType;

  if (!type) return <Redirect to={pathToMyReviews('one_on_one')} />;

  return (
    <Switch>
      <RouteAlias from={`${path}/my-reviews`} to={pathToMyReviews(type)} />
      <RouteAlias
        from={`${path}/my-team-reviews`}
        to={pathToMyTeamReviews(type)}
      />
      <RouteAlias
        from={`${path}/delegated-reviews`}
        to={pathToOtherActions(type)}
      />
    </Switch>
  );
};

export default DeprecatedHomeRoutes;
