import React from 'react';

import type { PeerSolicitation, User } from 'models';

import compositeKey from 'helpers/compositeKey';
import { n__ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Box, BoxList, FetchContainer, Helper } from 'components';

import PeerSolicitationListItem from './PeerSolicitationListItem';

type Props = {
  userReviewId: string;
  selectedUsers: Array<User>;
};

type AfterDataLoaderProps = {
  peerSolicitations: Array<PeerSolicitation>;
} & Props &
  DataLoaderProvidedProps;

const PeerSolicitationList = ({
  peerSolicitations,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      loadingStyle="overlay"
      render={() => {
        if (!peerSolicitations?.length) return null;

        return (
          <div className="peer-solicitation-list">
            <Helper>
              {n__(
                'The peer displayed below has already been in great demand',
                'The peers displayed below have already been in great demand',
                peerSolicitations.length
              )}
            </Helper>
            <Box isInset>
              <BoxList>
                {peerSolicitations.map(peerSolicitation => (
                  <PeerSolicitationListItem
                    key={peerSolicitation.id}
                    peerSolicitation={peerSolicitation}
                  />
                ))}
              </BoxList>
            </Box>
          </div>
        );
      }}
    />
  );
};

export default newDataLoader({
  fetch: ({ userReviewId, selectedUsers }: Props) =>
    get(`user_reviews/${userReviewId}/peer_solicitations`, {
      'peer_ids[]': selectedUsers.map(u => u.id),
    }),
  hydrate: { peerSolicitations: { user: {} } },
  cacheKey: ({ userReviewId, selectedUsers }: Props) =>
    compositeKey({
      userReviewId,
      selectedUserIds: selectedUsers.map(u => u.id),
      view: 'peerSolicitations',
    }),
})(PeerSolicitationList) as React.ComponentType<Props>;
