import type { AppDispatch } from 'redux/actions/types';

import { useAppDispatch } from 'helpers/hooks';
import invariant from 'helpers/invariant';

import { post } from 'redux/actions/api';

type PersistAction = (
  evaluationId: string | null,
  feedbackableId: string,
  feedbackableType: string,
  text: string | undefined,
  rating?: string | null | undefined
) => (dispatch: AppDispatch) => Promise<void>;

const usePersistAction = (
  refreshShareability: () => Promise<void>,
  isPreview?: boolean
): PersistAction => {
  const dispatch = useAppDispatch();

  return (evaluationId, feedbackableId, feedbackableType, text, rating) => {
    invariant(evaluationId, 'evaluationId must be defined');

    if (isPreview) {
      return () => Promise.resolve();
    }

    return async () => {
      await dispatch(
        post('feedbacks', {
          feedback: {
            evaluationId,
            feedbackableId,
            feedbackableType,
            text,
            rating,
          },
        })
      );

      await refreshShareability();
    };
  };
};

export default usePersistAction;
