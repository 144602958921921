import React from 'react';

import type { Props as BulmaInputProps } from '../bulmaElements/Input';

import Input from './Input';

type Props = Omit<BulmaInputProps<number>, 'onChange'> & {
  onChange: (value: number | null) => void;
};

export default class PercentageInput extends React.Component<Props> {
  inputRef: HTMLInputElement | null | undefined = null;

  formatValue(value: number | null | undefined) {
    if (!value && value !== 0) return '';
    return `${value}%`;
  }

  setInputRef(inputRef: HTMLInputElement | null | undefined) {
    if (!inputRef || this.inputRef != null) return;
    this.inputRef = inputRef;

    // All those events can change the caret position
    for (let event of ['focus', 'click', 'keyup', 'select']) {
      inputRef.addEventListener(event, this.updateSelectionRange);
    }

    // Prevent going right altogether if it will end up after the percentage sign
    inputRef.addEventListener('keydown', (e: KeyboardEvent) => {
      if (
        e.keyCode === 39 &&
        inputRef.selectionEnd === inputRef.selectionStart &&
        inputRef.selectionEnd === inputRef.value.length - 1
      ) {
        e.preventDefault();
      }
    });
  }

  componentDidUpdate() {
    this.updateSelectionRange();
  }

  componentDidMount() {
    this.updateSelectionRange();
  }

  updateSelectionRange = () => {
    if (!this.inputRef) return;

    const { selectionStart, selectionEnd } = this.inputRef;

    if (
      selectionStart !== null &&
      selectionStart === this.inputRef.value.length &&
      this.inputRef.value.endsWith('%')
    ) {
      this.inputRef.selectionStart = selectionStart - 1;
    }

    if (
      selectionEnd !== null &&
      selectionEnd === this.inputRef.value.length &&
      this.inputRef.value.endsWith('%')
    ) {
      this.inputRef.selectionEnd = selectionEnd - 1;
    }
  };

  handleValueChange = (newValue: string) => {
    const { onChange } = this.props;
    if (!onChange) return null;

    if (!newValue) return onChange(null);

    const parsedValue = parseInt(newValue.replace(/%/g, ''), 10);

    return onChange(isNaN(parsedValue) ? null : parsedValue);
  };

  render() {
    const { value, onChange, ...otherProps } = this.props;
    return (
      <Input
        ref={ref => this.setInputRef(ref)}
        value={this.formatValue(value)}
        onChange={this.handleValueChange}
        {...otherProps}
        type="text"
      />
    );
  }
}
