import { Style } from 'radium';
import * as React from 'react';

import type { Organization } from 'models';

import colors from 'styles/colors';

import { withOrganization } from 'helpers/withSessionProps';

import customization from 'components/customization';

type AfterConnectProps = {
  organization: Organization;
};

class CustomStyleManager extends React.Component<AfterConnectProps> {
  componentDidMount() {
    const { organization } = this.props;
    if (organization) this.setCustomStyle(organization);
  }

  componentWillReceiveProps(nextProps: AfterConnectProps) {
    if (!this.props.organization && nextProps.organization) {
      this.setCustomStyle(nextProps.organization);
    }
  }

  componentWillUnmount() {
    colors.setDefaultColors();
  }

  setCustomStyle(organization: Organization) {
    if (organization.theme) {
      colors.setPrimaryColor(organization.theme.primaryColor);

      if (organization.theme.navColors) {
        colors.setNavColors(organization.theme.navColors);
      }
    }
  }

  render() {
    return (
      <Style rules={customization({ primaryColor: colors.primaryColor })} />
    );
  }
}

export default withOrganization(CustomStyleManager);
