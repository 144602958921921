import React from 'react';

import { __ } from 'helpers/i18n';

import { Link, Text } from 'components';

import { type QuickFilter } from './availableQuickFiltersForUser';

type Props = {
  availableQuickFilters: Array<QuickFilter>;
  onChange: (quickFilter: QuickFilter) => void;
  closePopover: () => void;
};
const QuickFilterList = ({
  availableQuickFilters,
  onChange,
  closePopover,
}: Props) => {
  return (
    <div className="mt-4">
      <Text weight="bold">{__('Quick filters')}</Text>
      {availableQuickFilters.map(quickFilter => (
        <div key={quickFilter.filterType}>
          <Link
            className="filter-type-list-item filter-type-list-item-quick-filter"
            onClick={() => {
              onChange(quickFilter);
              closePopover();
            }}
          >
            {quickFilter.filterLabel}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default QuickFilterList;
